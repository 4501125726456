import React from 'react';
import { Row } from 'react-table';

import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  useSortable,
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { RowProps, RowsProps, DnDProps } from '../types';
import { TableRow } from './Rows';

import * as S from '../styles';

const DnDRow = ({ row, ...props }: RowProps & { row: Row<any> }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: row.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition || undefined,
    zIndex: isDragging ? 100 : undefined,
    cursor: isDragging ? 'grabbing' : 'grab',
  };

  return (
    <S.TableRow
      key={row.id}
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      {...props}
    >
      <TableRow row={row} />
    </S.TableRow>
  );
};

export const DnDRows = ({
  rows,
  prepareRow,
  updateSort,
  ...props
}: RowsProps & RowProps & DnDProps) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  function handleDragEnd(event: any) {
    const { active, over } = event;

    if (updateSort && active.id !== over.id) {
      const oldIndex = rows.findIndex((r) => r.id === active.id);
      const newIndex = rows.findIndex((r) => r.id === over.id);

      const sortedRows = arrayMove(rows, oldIndex, newIndex);
      updateSort(sortedRows.map((r) => r.original.id));
    }
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={rows}>
        {rows.map((row) => {
          prepareRow(row);

          return <DnDRow key={row.id} row={row} {...props} />;
        })}
      </SortableContext>
    </DndContext>
  );
};
