import React from 'react';
import Loader from 'react-loader-spinner';

import styled from 'styled-components';

import { theme } from 'common/theme';
import { marginX } from 'common/utils/styleHelpers';

const LoaderWrapper = styled(Loader)(
  () => `
  display: table;
  ${marginX('auto')}
`,
);

interface Props {
  type?:
    | 'Audio'
    | 'BallTriangle'
    | 'Bars'
    | 'Circles'
    | 'Grid'
    | 'Hearts'
    | 'MutatingDots'
    | 'None'
    | 'NotSpecified'
    | 'Oval'
    | 'Plane'
    | 'Puff'
    | 'RevolvingDot'
    | 'Rings'
    | 'TailSpin'
    | 'ThreeDots'
    | 'Triangle'
    | 'Watch';
  height?: string;
}

export const LoaderSimple = ({
  type = 'TailSpin',
  height = '100vh',
}: Props) => (
  <LoaderWrapper type={type} color={theme.color.black} height={height} />
);
