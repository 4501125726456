import React from 'react';
import { createPortal } from 'react-dom';

import { usePortal } from 'common/utils/portal';

interface Props {
  id: string;
}

export const Portal: React.FC<Props> = ({ id, children }) => {
  const target = usePortal(id);

  return createPortal(children, target);
};
