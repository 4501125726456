import React, { useState } from 'react';

import { message } from 'antd';

import { isProduction, isDemo } from 'utils';

import { ErrorModal } from '../containers/ErrorModal';

interface Props {
  error: string;
  setError: (error: string, originalError: string) => void;
}
export const ErrorContext = React.createContext<Props>({
  error: '',
  setError: () => {},
});

export const ErrorContextProvider: React.FunctionComponent = ({ children }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const setError = (error: string, originalError: string) => {
    let e = error;

    if (!isProduction())
      e += originalError !== '' ? ` : ${originalError}` : originalError;
    else if (isDemo()) message.error(originalError);

    setErrorMessage(e);
  };

  return (
    <ErrorContext.Provider value={{ error: errorMessage, setError }}>
      <>
        {children}
        <ErrorModal />
      </>
    </ErrorContext.Provider>
  );
};
