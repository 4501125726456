import React from 'react';
import ReactTooltip from 'react-tooltip';

import { themeColors } from 'common/theme/constants';

import { Icon } from '../Icon';
import { Text } from '../Text';

import * as S from './styles';

interface Props {
  id?: string;
  text: string;
  disable?: boolean;
}

export const Tooltip = ({ id, text, disable }: Props) => {
  return (
    <>
      <S.Wrapper>
        <div data-tip data-for={id || 'tooltip'}>
          <Icon
            iconColor={disable ? 'smokeWhite' : themeColors.primaryText}
            margin="md"
            name="info_outline"
            size={20}
          />
        </div>
      </S.Wrapper>
      <ReactTooltip
        id={id || 'tooltip'}
        className="tooltip"
        textColor="#000"
        backgroundColor="#fff"
        effect="solid"
        disable={disable}
      >
        <S.Content>
          <Text fontSize="sm" fontWeight="thin">
            {text}
          </Text>
        </S.Content>
      </ReactTooltip>
    </>
  );
};
