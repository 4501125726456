import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';

import { message, Radio } from 'antd';
import { useFormik } from 'formik';

import { useAuthContext } from 'authContext';
import {
  useUpsertKitchenMutation,
  Kitchen,
  useRestaurantOwnersLazyQuery,
  KitchenStatus,
  KitchenQuery,
  useDeleteKitchenMutation,
  KitchenListDocument,
} from 'codegen/generated/graphql';
import { Divider, Loader } from 'common/components';
import { Button } from 'common/components/Button';
import { Icon, IconSize } from 'common/components/Icon';
import { Input } from 'common/components/Input';
import { Col, Row, Grid } from 'common/components/Layout';
import { Text } from 'common/components/Text';
import { Textarea } from 'common/components/Textarea';
import { Tooltip } from 'common/components/Tooltip';
import { UploadImage, upload } from 'common/components/UploadImage';
import { theme } from 'common/theme';
import { ConfirmModal } from 'manager/components/ConfirmModal';
import { FieldError } from 'manager/components/FieldError';
import { FormModal } from 'manager/components/FormModal';
import { MultiSelect } from 'manager/components/MultiSelect/MultiSelect';
import { enumValueToReadable, formatPhoneNumber } from 'manager/utils/helpers';
import { Action, Resource } from 'router/AccessControlConfig';
import { Routes } from 'router/routes';
import {
  isUserAdmin,
  isUserRestaurantOwner,
  isUserCustomerSupport,
  CHOWLY_FLAG_ON,
} from 'utils';
import { LogEvent } from 'utils/analytics';

import { AccessControl } from '../../../../../common/components/AccessControl/AccessControl';
import { SUPPORTED_FILE_TYPES } from '../../utils';
import {
  validationSchema,
  initialValues,
  submit,
  InitialValues,
  useCopyToClipBoard,
} from './helpers';

import { Container } from './styles';

type Props = {
  kitchen?: KitchenQuery['adminKitchen'];
};

const { color } = theme;

const inputCommonProps = { fluid: true, autoComplete: 'off' };

export const KitchenForm = ({ kitchen }: Props) => {
  const { push } = useHistory();
  const { user } = useAuthContext();
  const copyToClipboard = useCopyToClipBoard();
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [logoTouched, setLogoTouched] = useState(false);
  const [headerImgTouched, setHeaderImgTouched] = useState(false);
  const [logoFile, setLogoFile] = useState<File | undefined>();
  const [headerImgFile, setHeaderImgFile] = useState<File | undefined>();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState('');

  const [upsertKitchen, { loading }] = useUpsertKitchenMutation();
  const [deleteKitchen, { loading: deleteLoading }] =
    useDeleteKitchenMutation();

  const isAdmin = isUserAdmin(user?.roles);
  const isRestaurantOwner = isUserRestaurantOwner(user?.roles);
  const isCustomerSupport = isUserCustomerSupport(user?.roles);
  const hideDeleteButton = isRestaurantOwner || isCustomerSupport;

  const [getRestaurantOwners, { data: ownersData, loading: ownersLoading }] =
    useRestaurantOwnersLazyQuery();
  useEffect(() => {
    if (isAdmin && !ownersData && !ownersLoading) {
      getRestaurantOwners();
    }
  }, [ownersData, getRestaurantOwners, ownersLoading, isAdmin]);

  const restaurantOwners =
    ownersData?.adminRestaurantOwners.map(({ id, name }) => {
      return { name: name || '', id };
    }) ?? [];

  const { id, logoUrl, headerImgUrl, brandWebUrl } = kitchen || {};

  const initialForm: InitialValues = useMemo(() => {
    if (!kitchen) return initialValues;
    // removing __typename for values to passing formik
    const { ...values } = kitchen ?? ({} as Kitchen);

    return {
      name: values.name ?? '',
      originalLocation: values.originalLocation ?? '',
      story: values.story ?? '',
      description: values.description ?? '',
      cuisine: values.cuisine ?? '',
      phone: values.phone ?? '',
      email: values.email ?? '',
      cookTime: values?.cookTime ?? 0,
      status: values.status,
      headerImgUrl: values.headerImgUrl,
      logoUrl: values.logoUrl,
      owners:
        values.owners?.map(({ id, name }) => ({ id, name: name || '' })) ?? [],
      enableChowly: values.enableChowly,
    };
  }, [kitchen]);

  const successModal = useCallback((message: string) => {
    setModalMessage(message);
    setShowSuccessModal(true);
  }, []);

  const discard = useCallback(() => {
    push(Routes.KITCHENS.path);
  }, [push]);

  const { values, handleChange, handleSubmit, touched, errors, setFieldValue } =
    useFormik<InitialValues>({
      enableReinitialize: true,
      initialValues: initialForm,
      onSubmit: async (values) => {
        let logoUrl = kitchen?.logoUrl;
        if (logoTouched && !logoFile) {
          logoUrl = '';
        }
        if (logoFile) {
          logoUrl = await upload({ type: 'kitchen', file: logoFile as File });
        }

        let headerImgUrl = kitchen?.headerImgUrl;
        if (headerImgTouched && !headerImgFile) {
          headerImgUrl = '';
        }
        if (headerImgFile) {
          headerImgUrl = await upload({
            type: 'kitchen',
            file: headerImgFile as File,
          });
        }
        await submit({
          id,
          values: {
            ...values,
            logoUrl,
            headerImgUrl,
          },
          upsertKitchen,
          successModal,
        });
      },
      validationSchema,
    });

  const setRestaurantOwners = (selectedList: any) => {
    setFieldValue('owners', selectedList);
  };

  const handleDeleteButton = async (value: string) => {
    setDeleteId(value);
    await LogEvent('RESTAURANT_MANAGEMENT', {
      message: `kitchen list: clicked "delete"`,
      id,
    });
    setModalMessage(`Do you really want to delete ${values.name.trim()}?`);
    setShowDeleteModal(true);
  };

  const onBrandDelete = async () => {
    try {
      await deleteKitchen({
        variables: { id: deleteId },
        refetchQueries: [
          {
            query: KitchenListDocument,
          },
        ],
      });
      setShowDeleteModal(false);
      push(Routes.KITCHENS.path);
    } catch {
      message.error('There was an error deleting this brand please try again');
    }
  };

  return (
    <AccessControl resource={Resource.BrandsProfile} action={Action.READ}>
      <Container>
        <form onSubmit={handleSubmit}>
          <UploadImage
            label="Upload brand logo"
            onImageSelect={setLogoFile}
            imageUrl={logoUrl || undefined}
            onTouched={() => setLogoTouched(true)}
            subText="Supported format: .png, .jpeg. Size: up to 500 KB"
            supportedTypes={SUPPORTED_FILE_TYPES}
            maxSize={500}
          />

          <UploadImage
            label="Upload background image"
            onImageSelect={setHeaderImgFile}
            imageUrl={headerImgUrl || undefined}
            onTouched={() => setHeaderImgTouched(true)}
            subText={
              <>
                Max file size: 1 MB
                <br />
                Recommended dimensions: 375x280 pixels
                <br />
                Supported file types: .png & .jpeg
              </>
            }
            supportedTypes={SUPPORTED_FILE_TYPES}
            maxSize={1000}
          />

          <Row direction="column">
            <Row marginB="xl" direction="column">
              <Text fontWeight="bold" spaceAfter="md">
                What is the name of the brand?
              </Text>
              <Col maxWidth="380px">
                <Input
                  placeholder="Enter brand's name"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  data-cy="admin-Restaurant-name-field"
                  {...inputCommonProps}
                />
              </Col>
              {touched.name && errors.name && (
                <FieldError message={errors.name} />
              )}
            </Row>
            {brandWebUrl && (
              <Row marginB="xl">
                <Text as="span" color="blueNavy" marginRight="sm">
                  <a href={brandWebUrl} style={{ color: color.blueNavy }}>
                    {brandWebUrl}
                  </a>
                </Text>
                <Icon
                  name="content_copy"
                  size={IconSize.SMALL}
                  iconColor="blueDenim"
                  onClick={() => copyToClipboard(brandWebUrl)}
                />
                <Link to={{ pathname: brandWebUrl }} target="_blank">
                  <Icon
                    name="open_in_new"
                    size={IconSize.SMALL}
                    iconColor="blueDenim"
                  />
                </Link>
              </Row>
            )}
            <Row marginB="xl" direction="column">
              <Text fontWeight="bold" spaceAfter="md">
                What is the original location of the brand?
              </Text>
              <Col maxWidth="380px">
                <Input
                  placeholder="Enter original location of brand"
                  name="originalLocation"
                  value={values.originalLocation}
                  onChange={handleChange}
                  data-cy="admin-Restaurant-location-field"
                  {...inputCommonProps}
                />
              </Col>
              {touched.originalLocation && errors.originalLocation && (
                <FieldError message={errors.originalLocation} />
              )}
            </Row>
            <Row marginB="xl" direction="column">
              <Text fontWeight="bold" spaceAfter="md">
                What is the title of the brand?
              </Text>
              <Col maxWidth="380px">
                <Input
                  placeholder="Enter brand's title"
                  name="description"
                  value={values.description}
                  onChange={handleChange}
                  data-cy="admin-Restaurant-title-field"
                  {...inputCommonProps}
                />
              </Col>
              {touched.description && errors.description && (
                <FieldError message={errors.description} />
              )}
            </Row>
            <Row marginB="xl" direction="column">
              <Row marginB="md" alignItems="center">
                <Text marginRight={10} fontWeight="bold">
                  What is the cuisine type?
                </Text>
                <Tooltip text="This tag will be displayed next to your brand logo on the homescreen." />
              </Row>
              <Col maxWidth="380px">
                <Input
                  placeholder="Eg: 'Pizza' (max 12 characters)"
                  name="cuisine"
                  value={values.cuisine}
                  onChange={handleChange}
                  data-cy="admin-Restaurant-cuisinetype-field"
                  {...inputCommonProps}
                />
              </Col>
              {touched.cuisine && errors.cuisine && (
                <FieldError message={errors.cuisine} />
              )}
            </Row>
            <Row marginB="xl" direction="column">
              <Text fontWeight="bold" spaceAfter="md">
                Assign Brand Owner?
              </Text>
              <Col maxWidth="380px">
                <MultiSelect
                  options={restaurantOwners}
                  selectedValues={values.owners}
                  onSelect={setRestaurantOwners}
                  onRemove={setRestaurantOwners}
                  placeholder="Select user"
                  data-cy="admin-Restaurant-owner-select"
                  disabled={!isAdmin}
                  fluid
                />
              </Col>
            </Row>
            <Row marginB="xl" direction="column">
              <Text fontWeight="bold" spaceAfter="md">
                Cook time
              </Text>
              <Col alignItems="center">
                <Col maxWidth="80px" marginR="xs">
                  <Input
                    name="cookTime"
                    type="number"
                    value={values.cookTime}
                    onChange={handleChange}
                    {...inputCommonProps}
                  />
                </Col>
                <Text>mins</Text>
              </Col>
              {touched.cookTime && errors.cookTime && (
                <FieldError message={errors.cookTime} />
              )}
            </Row>
            <Row marginB="xl" direction="column">
              <Text fontWeight="bold" spaceAfter="md">
                Phone Number
              </Text>
              <Col maxWidth="380px">
                <Input
                  placeholder="(xxx) xxx-xxxx"
                  name="phone"
                  value={formatPhoneNumber(values.phone)}
                  onChange={handleChange}
                  data-cy="admin-Restaurant-phonenumber-field"
                  {...inputCommonProps}
                />
              </Col>
              {touched.phone && errors.phone && (
                <FieldError message={errors.phone} />
              )}
            </Row>
            <Row marginB="xl" direction="column">
              <Text fontWeight="bold" spaceAfter="md">
                Email
              </Text>
              <Col maxWidth="380px">
                <Input
                  placeholder="Brand's email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  data-cy="admin-Restaurant-email-field"
                  {...inputCommonProps}
                />
              </Col>
              {touched.email && errors.email && (
                <FieldError message={errors.email} />
              )}
            </Row>
            <Row direction="column" marginB="xl">
              <Text fontWeight="bold" spaceAfter="md">
                Set brand status
              </Text>
              <Row alignItems="center" marginB="md">
                <Col maxWidth="650px">
                  <Text color="smokeText">
                    {`If the brand is set to inactive, menu schedules will also be set to inactive.`}
                  </Text>
                </Col>
              </Row>
              <Col>
                <Radio.Group
                  onChange={(e) => {
                    setFieldValue('status', e.target.value);
                  }}
                  value={values.status}
                >
                  <Radio value={KitchenStatus.Active}>
                    {enumValueToReadable(KitchenStatus.Active)}
                  </Radio>
                  <Radio value={KitchenStatus.Inactive}>
                    {enumValueToReadable(KitchenStatus.Inactive)}
                  </Radio>
                </Radio.Group>
              </Col>
              {touched.status && errors.status && (
                <FieldError message={errors.status} />
              )}
            </Row>
            {CHOWLY_FLAG_ON && (
              <Row direction="column">
                <Text marginRight={10} fontWeight="bold" spaceAfter="sm">
                  Would you like to enable integration with 3rd party
                  marketplaces?
                </Text>
                <Row alignItems="center" marginB="md">
                  <Col maxWidth="650px">
                    <Text color="smokeText">
                      {`This integration sends your menu(s) to multiple
                marketplaces such as UberEats and DoorDash.`}
                    </Text>
                  </Col>
                </Row>
                <Text color="smokeText" spaceAfter="sm">
                  {`The following items won't show on 3rd party marketplaces:`}
                </Text>
                <ul>
                  <li>
                    <Text color="smokeText" spaceAfter="sm">
                      {`- Alcoholic beverages`}
                    </Text>
                  </li>
                  <li>
                    <Text color="smokeText" spaceAfter="sm">
                      {`- Meal Packs `}
                    </Text>
                  </li>
                </ul>
                <Col>
                  <Radio.Group
                    onChange={(e) =>
                      setFieldValue('enableChowly', e.target.value)
                    }
                    value={values.enableChowly}
                  >
                    <Radio value={true}>Active</Radio>
                    <Radio value={false}>
                      Inactive (turn off menu availability on 3rd party apps)
                    </Radio>
                  </Radio.Group>
                </Col>
                {touched.status && errors.status && (
                  <FieldError message={errors.status} />
                )}
              </Row>
            )}
            <Divider width="720px" marginT={40} marginB={40} />
            <Row direction="column" marginB="xl">
              <Text fontWeight="bold" spaceAfter="lg">
                Describe the brand
              </Text>
              <Col maxWidth="720px">
                <Textarea
                  placeholder="Tell the brand's story"
                  fluid
                  rows={5}
                  name="story"
                  value={values.story}
                  onChange={handleChange}
                  data-cy="admin-Restaurant-description-field"
                />
              </Col>
              {touched.story && errors.story && (
                <FieldError message={errors.story} />
              )}
            </Row>
          </Row>
          <Grid gridColumns="1fr 2fr">
            <Col>
              <Button
                type="button"
                ghost
                text="Cancel"
                disabled={loading}
                onClick={discard}
                data-cy="admin-Restaurant-cancel-button"
              />
              <Col marginL={24} marginR={12} width="140px">
                <Button
                  fluid
                  primary
                  text="Save changes"
                  disabled={loading}
                  type="submit"
                  data-cy="admin-Restaurant-submit-button"
                />
              </Col>
              {loading && (
                <Col alignItems="center">
                  <Loader />
                </Col>
              )}
            </Col>
            <Col justifyContent="flex-end">
              {!hideDeleteButton && id && (
                <Button
                  ghost
                  text="Delete Brand"
                  disabled={loading}
                  textColor="primaryRed"
                  type="button"
                  data-cy="admin-Restaurant-delete-button"
                  onClick={handleDeleteButton}
                />
              )}
            </Col>
          </Grid>
        </form>

        <ConfirmModal
          loading={deleteLoading}
          visible={showDeleteModal}
          message={modalMessage}
          onCancel={() => setShowDeleteModal(false)}
          onConfirm={onBrandDelete}
        />

        <FormModal
          visible={showSuccessModal}
          message={modalMessage}
          onClose={() => push(Routes.KITCHENS.path)}
        />
      </Container>
    </AccessControl>
  );
};
