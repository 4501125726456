import React from 'react';

import { Icon } from 'common/components/Icon';
import { IconName } from 'common/components/Icon/types';
import { Text } from 'common/components/Text';
import { themeColors } from 'common/theme/constants';

import {
  FacilitySwitcherWrapper,
  LocationIconContainer,
  FacilitySwitcherText,
} from './styles';

interface MenuAction {
  id?: string;
  iconName?: IconName;
  label: string;
  onClick?: () => void;
}

interface Props {
  action: MenuAction;
  selectedFacility: string;
}

export const ActionMenuList: React.FC<Props> = ({
  action,
  selectedFacility,
}) => {
  const isSelected = action.id === selectedFacility;

  return (
    <FacilitySwitcherWrapper>
      <LocationIconContainer isSelected={isSelected}>
        <Icon
          name={'room'}
          iconColor={isSelected ? themeColors.secondaryText : 'grey'}
          size={20}
        />
      </LocationIconContainer>
      <FacilitySwitcherText>
        <Text
          fontSize="h4"
          lineHeight="small"
          fontWeight={isSelected ? 'semiBold' : 'normal'}
        >
          {action.label}
        </Text>
      </FacilitySwitcherText>
    </FacilitySwitcherWrapper>
  );
};
