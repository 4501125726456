import React, { useContext, useState } from 'react';
import { Detector } from 'react-detect-offline';
import { useParams } from 'react-router-dom';

import { Divider, Drawer } from 'antd';

import { useAuthContext } from 'authContext';
import { KdsType } from 'codegen/generated/graphql';
import { Icon, IconSize } from 'common/components/Icon';
import { Row } from 'common/components/Layout';
import { Switch } from 'common/components/Switch';
import { Text } from 'common/components/Text';
import { themeColors } from 'common/theme/constants';
import { KDSSettingsContext } from 'contexts/kdsSettingsContext';
import { SubscriptionsContext } from 'contexts/subscriptionsContext';
import { playSound } from 'facility/utils/playSound';
import {
  ConnectionStatus,
  getScreenId,
  getSubscriptionConnectionInfo,
} from 'utils/screenSetup';

import { PollingSwitch } from '../PollingSwitch';
import { NetworkInfoControls } from './NetworkInfoControl';
import { NetworkInfoRow } from './NetworkInfoRow';

import * as S from './styles';

export const NetworkInfo = () => {
  const [visible, setVisible] = useState(false);
  const { isSubscriptionsOn } = useContext(SubscriptionsContext);
  // TODO: with auto-print functionality after it's tested
  // const { isAutoPrintOn, setIsAutoPrintOn, setIsOrderSoundOn, isOrderSoundOn } =
  //   useContext(KDSSettingsContext);
  const {
    setIsOrderSoundOn,
    isOrderSoundOn,
    isShowBrandNameOn,
    setIsShowBrandNameOn,
  } = useContext(KDSSettingsContext);
  const { logout, selectedFacility } = useAuthContext();
  const { id } = useParams<{ id: string }>();

  const { status, lastConnection, facilityId } =
    getSubscriptionConnectionInfo();
  const hasSubscription = status === ConnectionStatus.Connected;

  // const handleToggleAutoPrint = () => setIsAutoPrintOn(!isAutoPrintOn);
  const handleToggleOrderChime = () => setIsOrderSoundOn(!isOrderSoundOn);
  const handleToggleShowBrandNameOn = () =>
    setIsShowBrandNameOn(!isShowBrandNameOn);

  const displayStationSettings =
    !!id && selectedFacility?.kdsType === KdsType.Station;

  return (
    <>
      <NetworkInfoControls
        iconName="settings"
        text="Options"
        onClick={() => setVisible(true)}
      />
      <Drawer
        contentWrapperStyle={{
          height: 'calc(95vh - 70px)',
          marginTop: 'calc(5vh + 50px)',
          marginRight: 'calc(20px)',
          borderRadius: '12px',
          overflow: 'hidden',
        }}
        closeIcon={
          <Icon
            size={IconSize.LARGE}
            iconColor={themeColors.black}
            name="close"
          />
        }
        title={
          <Text fontWeight={'bold'} fontSize={'h1'}>
            Options
          </Text>
        }
        visible={visible}
        onClose={() => setVisible(false)}
        getContainer={false}
        width={'33%'}
        footer={
          <Row
            justifyContent="flex-end"
            alignItems="center"
            padding={20}
            width="100%"
          >
            <NetworkInfoControls
              iconName="logout"
              text="Log Out"
              onClick={async () => {
                setVisible(false);
                await logout();
              }}
            />
          </Row>
        }
      >
        <S.OptionsWrap>
          <S.SectionContent>
            <Text fontSize="md" color={themeColors.black} fontWeight="bold">
              KDS Settings
            </Text>
            <div>
              <NetworkInfoRow
                label={
                  <>
                    <span>New Order Chime</span>
                    <S.HiddenButton type="text" onClick={() => playSound()}>
                      <S.SubText fontSize="md">Test sound</S.SubText>
                    </S.HiddenButton>
                  </>
                }
              >
                <Switch
                  isActive={isOrderSoundOn}
                  onClick={handleToggleOrderChime}
                />
              </NetworkInfoRow>
              {/* <NetworkInfoRow label="Auto-print new orders">
                <Switch
                  isActive={isAutoPrintOn}
                  onClick={handleToggleAutoPrint}
                />
              </NetworkInfoRow> */}
              {displayStationSettings && (
                <NetworkInfoRow label="Show brand name with Items">
                  <Switch
                    negativeColor="smokeWhite"
                    isActive={isShowBrandNameOn}
                    onClick={handleToggleShowBrandNameOn}
                  />
                </NetworkInfoRow>
              )}
            </div>
            <Divider />
            <div>
              <Row marginB={10}>
                <Text fontSize="md" color={themeColors.black} fontWeight="bold">
                  Network Settings
                </Text>
              </Row>
              <NetworkInfoRow label="Screen ID">{getScreenId()}</NetworkInfoRow>
              <NetworkInfoRow label="Last connection">
                {lastConnection}
              </NetworkInfoRow>
              <NetworkInfoRow label="Facility">{facilityId}</NetworkInfoRow>
              <NetworkInfoRow label="Network status">
                <Detector
                  render={({ online }: { online: boolean }) => (
                    <>
                      <S.Dot online={online} />
                      <span>{online ? 'Active' : 'Offline'}</span>
                    </>
                  )}
                />
              </NetworkInfoRow>

              <NetworkInfoRow label="Subscriptions status">
                <S.Dot online={hasSubscription} />
                {hasSubscription ? 'Unable to fetch date' : 'No subscription'}
              </NetworkInfoRow>

              <NetworkInfoRow label="Retrieval Method">
                {isSubscriptionsOn ? 'Subscription' : 'Polling'}
              </NetworkInfoRow>
              <Row>
                <PollingSwitch />
              </Row>
            </div>
          </S.SectionContent>
        </S.OptionsWrap>
      </Drawer>
    </>
  );
};
