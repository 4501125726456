import React, {
  Component,
  ComponentType,
  createElement,
  ErrorInfo,
} from 'react';

import * as Sentry from '@sentry/browser';

import { AppError, FallbackComponentProps } from './Containers/AppError';

interface ErrorBoundaryProps {
  fallbackComponent: ComponentType<FallbackComponentProps>;
  onError: (error: Error, info: ErrorInfo) => void;
}

interface ErrorBoundaryState {
  error: Error | null;
  info: ErrorInfo | null;
}

export class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  static defaultProps: ErrorBoundaryProps = {
    onError: () => undefined,
    fallbackComponent: AppError,
  };

  state: ErrorBoundaryState = {
    error: null,
    info: null,
  };

  componentDidCatch(error: Error, info: ErrorInfo) {
    this.setState({
      error,
      info,
    });

    Sentry.withScope((scope) => {
      Sentry.captureException(error);
    });

    this.props.onError(error, info);
  }

  render() {
    const { error, info } = this.state;

    if (error && info) {
      return createElement(this.props.fallbackComponent, { error, info });
    }

    return this.props.children;
  }
}

export const withErrorBoundary = (WrappedComponent: React.FC) => (props: any) =>
  (
    <ErrorBoundary>
      <WrappedComponent {...props} />
    </ErrorBoundary>
  );
