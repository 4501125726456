import React from 'react';

import { Icon, IconSize } from 'common/components/Icon';
import { Portal } from 'common/components/Portal';
import { Text } from 'common/components/Text';
import { themeColors } from 'common/theme/constants';
import { ModalSizeType } from 'common/utils/styleHelpers';

import {
  Modal as ModalElement,
  Shadow,
  Wrapper,
  CloseIcon,
  Header,
} from './styles';

interface Props {
  visible: boolean;
  onCloseClick: () => void;
  header?: string;
  closeIcon?: boolean;
  backdropClose?: boolean;
  size?: ModalSizeType;
}

export const Modal: React.FC<Props> = ({
  children,
  visible,
  header,
  closeIcon = true,
  size = 'normal',
  backdropClose = true,
  onCloseClick,
  ...rest
}) => {
  return !visible ? (
    <Portal id="modal" />
  ) : (
    <Portal id="modal">
      <ModalElement size={size} {...rest}>
        <Wrapper>
          <Header justifyContent="space-between" alignItems="center">
            {header && (
              <Text
                truncateText
                color={themeColors.secondaryText}
                fontSize="bodyHeader"
                fontWeight="bold"
              >
                {header}
              </Text>
            )}

            {closeIcon && (
              <CloseIcon onClick={onCloseClick}>
                <Icon
                  name="close"
                  iconColor={themeColors.primaryText}
                  size={IconSize.SMALL}
                />
              </CloseIcon>
            )}
          </Header>
          {children}
        </Wrapper>
      </ModalElement>
      <Shadow onClick={backdropClose ? onCloseClick : undefined} />
    </Portal>
  );
};
