import React, { useState, useRef, useCallback } from 'react';

import { Badge } from 'common/components/Badge';
import { Icon, IconSize } from 'common/components/Icon';
import { IconName } from 'common/components/Icon/types';
import { Text } from 'common/components/Text';
import { themeColors } from 'common/theme/constants';
import { useOutsideClick } from 'common/utils/hooks';
import { Home } from 'manager/components/Sidebar/SidebarIcon';

import { ActionMenuList } from './ActionMenuList';

import {
  IconButtonWrapper,
  Menu,
  List,
  ListItem,
  IconWrapper,
  Button,
  ButtonIcon,
} from './styles';

interface MenuAction {
  id?: string;
  iconName?: IconName;
  label: string;
  onClick?: () => void;
}

interface Props {
  actions: MenuAction[];
  inline?: boolean;
  badge?: string;
  isInfo?: boolean;
  leftAlign?: boolean;
  locationToggle?: boolean;
  selectedFacilityId?: string;
  reverseList?: boolean;
  isLocked?: boolean;
  'data-cy'?: string;
}
export const ActionMenu: React.FC<Props> = ({
  actions,
  inline,
  badge,
  isInfo,
  leftAlign,
  locationToggle,
  selectedFacilityId,
  reverseList,
  isLocked,
  'data-cy': datacypress,
}) => {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useOutsideClick(ref, () => {
    if (isOpen) {
      setIsOpen(false);
    }
  });
  const onclick = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  let triggerIcon;

  if (badge) {
    triggerIcon = (
      <Badge
        text={`${badge}`}
        textColor={themeColors.black}
        backgroundColor="gray"
      />
    );
  } else if (locationToggle) {
    triggerIcon = (
      <Button
        aria-label="action menu dropdown"
        data-cy="admin-facility-dropdown"
      >
        <IconWrapper>
          <Home color={themeColors.black}></Home>
        </IconWrapper>
        <ButtonIcon name="arrow_drop_down" />
      </Button>
    );
  } else if (isLocked) {
    triggerIcon = (
      <Icon iconColor="grey" type="sharp" name="lock" size={IconSize.NORMAL} />
    );
  } else {
    triggerIcon = (
      <Icon
        iconColor={
          inline
            ? isInfo
              ? themeColors.primaryRed
              : 'grayDark'
            : themeColors.secondaryText
        }
        type="outlined"
        name={isInfo ? 'info' : isOpen ? 'close' : 'more_horiz'}
        size={IconSize.NORMAL}
      />
    );
  }

  return (
    <IconButtonWrapper
      onClick={onclick}
      ref={ref}
      inline={inline}
      locationToggle={locationToggle}
      reverseList={reverseList}
    >
      {triggerIcon}
      <Menu
        inline={inline}
        reverseList={reverseList}
        isOpen={isOpen}
        leftAlign={leftAlign}
      >
        <List
          aria-label="Item Dropdown Menu"
          data-cy="admin-facility-switcherItem"
        >
          {actions.map((action, index) => {
            return (
              <ListItem key={index} onClick={action.onClick}>
                {action.iconName ? (
                  <Icon
                    type="sharp"
                    name={action.iconName}
                    iconColor={themeColors.primaryText}
                    size={IconSize.SMALL}
                    data-cy={datacypress}
                  />
                ) : null}

                {selectedFacilityId ? (
                  <ActionMenuList
                    action={action}
                    selectedFacility={selectedFacilityId}
                  />
                ) : (
                  <Text fontSize="body" lineHeight="small">
                    {action.label}
                  </Text>
                )}
              </ListItem>
            );
          })}
        </List>
      </Menu>
    </IconButtonWrapper>
  );
};
