import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  display: inline-block;
`;

export const Content = styled.div`
  max-width: 235px;
`;
