import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  Json: any;
};

export type Address = {
  __typename?: 'Address';
  deliveryEligibilities: Array<DeliveryEligibility>;
  formattedAddress?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isDefault: Scalars['Boolean'];
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  locality?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  placeId: Scalars['String'];
  postalCode?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
};

export type AddressCreateInput = {
  nickname?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  placeId: Scalars['String'];
};

export type AddressUpdateInput = {
  id: Scalars['ID'];
  nickname?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  placeId: Scalars['String'];
};

export type AdminAddressUpdateInput = {
  id: Scalars['ID'];
  isDefault?: Maybe<Scalars['Boolean']>;
  placeId: Scalars['String'];
};

export type AdminPortalUserOrderByArg = {
  column: AdminPortalUserOrderByEnum;
  order: SortOrderArg;
};

export enum AdminPortalUserOrderByEnum {
  Name = 'name',
  Roles = 'roles'
}

export type AdminReorderKitchenInput = {
  /** ID of the kitchen */
  id: Scalars['String'];
  /** New position of the kitchen */
  newSortId: Scalars['Int'];
};

export type AdminUserUpdateInput = {
  customerNotes?: Maybe<Array<Scalars['String']>>;
  id: Scalars['ID'];
  internalNotes?: Maybe<Array<Scalars['String']>>;
  isEmployee: Scalars['Boolean'];
  vip: Scalars['Boolean'];
};

export enum Allergy {
  DairyFree = 'DAIRY_FREE',
  GlutenFree = 'GLUTEN_FREE',
  PeanutFree = 'PEANUT_FREE',
  ShellfishFree = 'SHELLFISH_FREE',
  TreeNutFree = 'TREE_NUT_FREE'
}

export enum ApiError {
  BadUserInput = 'BAD_USER_INPUT',
  BraintreeAuthentication = 'BRAINTREE_AUTHENTICATION',
  BraintreeAuthorization = 'BRAINTREE_AUTHORIZATION',
  BraintreeError = 'BRAINTREE_ERROR',
  BraintreeGatewayUnreachable = 'BRAINTREE_GATEWAY_UNREACHABLE',
  BraintreeInternal = 'BRAINTREE_INTERNAL',
  BraintreeNotFound = 'BRAINTREE_NOT_FOUND',
  BraintreeNotImplemented = 'BRAINTREE_NOT_IMPLEMENTED',
  BraintreePaymentAuthorizationExpired = 'BRAINTREE_PAYMENT_AUTHORIZATION_EXPIRED',
  BraintreePaymentAuthorizing = 'BRAINTREE_PAYMENT_AUTHORIZING',
  BraintreePaymentFailed = 'BRAINTREE_PAYMENT_FAILED',
  BraintreePaymentGatewayRejected = 'BRAINTREE_PAYMENT_GATEWAY_REJECTED',
  BraintreePaymentProcessorDeclined = 'BRAINTREE_PAYMENT_PROCESSOR_DECLINED',
  BraintreePaymentSettlementDeclined = 'BRAINTREE_PAYMENT_SETTLEMENT_DECLINED',
  BraintreePaymentUnknownStatus = 'BRAINTREE_PAYMENT_UNKNOWN_STATUS',
  BraintreePaymentVoided = 'BRAINTREE_PAYMENT_VOIDED',
  BraintreeResourceLimit = 'BRAINTREE_RESOURCE_LIMIT',
  BraintreeServiceAvailability = 'BRAINTREE_SERVICE_AVAILABILITY',
  BraintreeUnsupportedClient = 'BRAINTREE_UNSUPPORTED_CLIENT',
  BraintreeValidation = 'BRAINTREE_VALIDATION',
  Forbidden = 'FORBIDDEN',
  GraphqlParseFailed = 'GRAPHQL_PARSE_FAILED',
  GraphqlValidationFailed = 'GRAPHQL_VALIDATION_FAILED',
  InternalServerError = 'INTERNAL_SERVER_ERROR',
  PersistedQueryNotFound = 'PERSISTED_QUERY_NOT_FOUND',
  PersistedQueryNotSupported = 'PERSISTED_QUERY_NOT_SUPPORTED',
  SecurityError = 'SECURITY_ERROR',
  Unauthenticated = 'UNAUTHENTICATED'
}

export type AvailablePowerBiReport = {
  __typename?: 'AvailablePowerBiReport';
  datasetId: Scalars['String'];
  embedUrl: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
};

export type AvailablePowerBiWorkspace = {
  __typename?: 'AvailablePowerBiWorkspace';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type BoolFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['Boolean']>;
};

export type BoolFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolFilter>;
};

export type BoolNullableFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolNullableFilter>;
};

export enum BraintreePaymentMethod {
  CreditCard = 'CREDIT_CARD',
  Paypal = 'PAYPAL',
  Venmo = 'VENMO'
}

export enum BraintreePaymentSuccessStatus {
  Authorized = 'AUTHORIZED',
  Settled = 'SETTLED',
  SettlementPending = 'SETTLEMENT_PENDING',
  Settling = 'SETTLING',
  SubmittedForSettlement = 'SUBMITTED_FOR_SETTLEMENT'
}

export type BraintreeTransaction = {
  __typename?: 'BraintreeTransaction';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  order: Order;
  orderId: Scalars['String'];
  paymentMethod: BraintreePaymentMethod;
  paymentMethodId: Scalars['String'];
  paymentMethodInfo: Scalars['String'];
  status: BraintreePaymentSuccessStatus;
  terminal: Scalars['Boolean'];
};

export type BraintreeTransactionCreateManyOrderInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  paymentMethod: BraintreePaymentMethod;
  paymentMethodId: Scalars['String'];
  paymentMethodInfo: Scalars['String'];
  status: BraintreePaymentSuccessStatus;
  terminal: Scalars['Boolean'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BraintreeTransactionCreateManyOrderInputEnvelope = {
  data?: Maybe<Array<BraintreeTransactionCreateManyOrderInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BraintreeTransactionCreateNestedManyWithoutOrderInput = {
  connect?: Maybe<Array<BraintreeTransactionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BraintreeTransactionCreateOrConnectWithoutOrderInput>>;
  create?: Maybe<Array<BraintreeTransactionCreateWithoutOrderInput>>;
  createMany?: Maybe<BraintreeTransactionCreateManyOrderInputEnvelope>;
};

export type BraintreeTransactionCreateOrConnectWithoutOrderInput = {
  create: BraintreeTransactionCreateWithoutOrderInput;
  where: BraintreeTransactionWhereUniqueInput;
};

export type BraintreeTransactionCreateWithoutOrderInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  paymentMethod: BraintreePaymentMethod;
  paymentMethodId: Scalars['String'];
  paymentMethodInfo: Scalars['String'];
  status: BraintreePaymentSuccessStatus;
  terminal: Scalars['Boolean'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BraintreeTransactionListRelationFilter = {
  every?: Maybe<BraintreeTransactionWhereInput>;
  none?: Maybe<BraintreeTransactionWhereInput>;
  some?: Maybe<BraintreeTransactionWhereInput>;
};

export type BraintreeTransactionScalarWhereInput = {
  AND?: Maybe<Array<BraintreeTransactionScalarWhereInput>>;
  NOT?: Maybe<Array<BraintreeTransactionScalarWhereInput>>;
  OR?: Maybe<Array<BraintreeTransactionScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  orderId?: Maybe<StringFilter>;
  paymentMethod?: Maybe<EnumBraintreePaymentMethodFilter>;
  paymentMethodId?: Maybe<StringFilter>;
  paymentMethodInfo?: Maybe<StringFilter>;
  status?: Maybe<EnumBraintreePaymentSuccessStatusFilter>;
  terminal?: Maybe<BoolFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type BraintreeTransactionUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  paymentMethod?: Maybe<EnumBraintreePaymentMethodFieldUpdateOperationsInput>;
  paymentMethodId?: Maybe<StringFieldUpdateOperationsInput>;
  paymentMethodInfo?: Maybe<StringFieldUpdateOperationsInput>;
  status?: Maybe<EnumBraintreePaymentSuccessStatusFieldUpdateOperationsInput>;
  terminal?: Maybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type BraintreeTransactionUpdateManyWithWhereWithoutOrderInput = {
  data: BraintreeTransactionUpdateManyMutationInput;
  where: BraintreeTransactionScalarWhereInput;
};

export type BraintreeTransactionUpdateManyWithoutOrderInput = {
  connect?: Maybe<Array<BraintreeTransactionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BraintreeTransactionCreateOrConnectWithoutOrderInput>>;
  create?: Maybe<Array<BraintreeTransactionCreateWithoutOrderInput>>;
  createMany?: Maybe<BraintreeTransactionCreateManyOrderInputEnvelope>;
  delete?: Maybe<Array<BraintreeTransactionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<BraintreeTransactionScalarWhereInput>>;
  disconnect?: Maybe<Array<BraintreeTransactionWhereUniqueInput>>;
  set?: Maybe<Array<BraintreeTransactionWhereUniqueInput>>;
  update?: Maybe<Array<BraintreeTransactionUpdateWithWhereUniqueWithoutOrderInput>>;
  updateMany?: Maybe<Array<BraintreeTransactionUpdateManyWithWhereWithoutOrderInput>>;
  upsert?: Maybe<Array<BraintreeTransactionUpsertWithWhereUniqueWithoutOrderInput>>;
};

export type BraintreeTransactionUpdateWithWhereUniqueWithoutOrderInput = {
  data: BraintreeTransactionUpdateWithoutOrderInput;
  where: BraintreeTransactionWhereUniqueInput;
};

export type BraintreeTransactionUpdateWithoutOrderInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  paymentMethod?: Maybe<EnumBraintreePaymentMethodFieldUpdateOperationsInput>;
  paymentMethodId?: Maybe<StringFieldUpdateOperationsInput>;
  paymentMethodInfo?: Maybe<StringFieldUpdateOperationsInput>;
  status?: Maybe<EnumBraintreePaymentSuccessStatusFieldUpdateOperationsInput>;
  terminal?: Maybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type BraintreeTransactionUpsertWithWhereUniqueWithoutOrderInput = {
  create: BraintreeTransactionCreateWithoutOrderInput;
  update: BraintreeTransactionUpdateWithoutOrderInput;
  where: BraintreeTransactionWhereUniqueInput;
};

export type BraintreeTransactionWhereInput = {
  AND?: Maybe<Array<BraintreeTransactionWhereInput>>;
  NOT?: Maybe<Array<BraintreeTransactionWhereInput>>;
  OR?: Maybe<Array<BraintreeTransactionWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  order?: Maybe<OrderWhereInput>;
  orderId?: Maybe<StringFilter>;
  paymentMethod?: Maybe<EnumBraintreePaymentMethodFilter>;
  paymentMethodId?: Maybe<StringFilter>;
  paymentMethodInfo?: Maybe<StringFilter>;
  status?: Maybe<EnumBraintreePaymentSuccessStatusFilter>;
  terminal?: Maybe<BoolFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type BraintreeTransactionWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum BusinessType {
  Accounting = 'ACCOUNTING',
  Beauty = 'BEAUTY',
  Construction = 'CONSTRUCTION',
  Education = 'EDUCATION',
  Events = 'EVENTS',
  Finance = 'FINANCE',
  Government = 'GOVERNMENT',
  Legal = 'LEGAL',
  NonProfits = 'NON_PROFITS',
  Other = 'OTHER',
  RealEstate = 'REAL_ESTATE',
  Retail = 'RETAIL',
  Technology = 'TECHNOLOGY',
  Telecommunication = 'TELECOMMUNICATION',
  Transportation = 'TRANSPORTATION'
}

export type Cart = {
  __typename?: 'Cart';
  id: Scalars['String'];
  items: Array<CartItem>;
  promoCodes: Array<Scalars['String']>;
  promoErrors: Array<PromoValidationErrorWithCode>;
  tip?: Maybe<Scalars['Float']>;
  tipPercent?: Maybe<Scalars['Float']>;
  utensilsCount: Scalars['Int'];
};


export type CartItemsArgs = {
  after?: Maybe<CartItemWhereUniqueInput>;
  before?: Maybe<CartItemWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CartCosts = {
  __typename?: 'CartCosts';
  delivery: Scalars['Float'];
  fee: Scalars['Float'];
  giftCardsDiscount: Scalars['Float'];
  promoDiscount: Scalars['Float'];
  subtotal: Scalars['Float'];
  tax: Scalars['Float'];
  taxFees: Scalars['Float'];
  tip: Scalars['Float'];
  total: Scalars['Float'];
};

export type CartCreateNestedOneWithoutItemsInput = {
  connect?: Maybe<CartWhereUniqueInput>;
  connectOrCreate?: Maybe<CartCreateOrConnectWithoutItemsInput>;
  create?: Maybe<CartCreateWithoutItemsInput>;
};

export type CartCreateNestedOneWithoutPaymentInput = {
  connect?: Maybe<CartWhereUniqueInput>;
  connectOrCreate?: Maybe<CartCreateOrConnectWithoutPaymentInput>;
  create?: Maybe<CartCreateWithoutPaymentInput>;
};

export type CartCreateNestedOneWithoutUserCustomerInfoInput = {
  connect?: Maybe<CartWhereUniqueInput>;
  connectOrCreate?: Maybe<CartCreateOrConnectWithoutUserCustomerInfoInput>;
  create?: Maybe<CartCreateWithoutUserCustomerInfoInput>;
};

export type CartCreateOrConnectWithoutItemsInput = {
  create: CartCreateWithoutItemsInput;
  where: CartWhereUniqueInput;
};

export type CartCreateOrConnectWithoutPaymentInput = {
  create: CartCreateWithoutPaymentInput;
  where: CartWhereUniqueInput;
};

export type CartCreateOrConnectWithoutUserCustomerInfoInput = {
  create: CartCreateWithoutUserCustomerInfoInput;
  where: CartWhereUniqueInput;
};

export type CartCreateWithoutItemsInput = {
  Payment?: Maybe<PaymentCreateNestedManyWithoutCartInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  promoCodes?: Maybe<CartCreatepromoCodesInput>;
  promoErrors?: Maybe<CartCreatepromoErrorsInput>;
  promoIds?: Maybe<CartCreatepromoIdsInput>;
  tip?: Maybe<Scalars['Float']>;
  tipPercent?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userCustomerInfo?: Maybe<UserCustomerInfoCreateNestedManyWithoutCartInput>;
  utensilsCount?: Maybe<Scalars['Int']>;
};

export type CartCreateWithoutPaymentInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  items?: Maybe<CartItemCreateNestedManyWithoutCartInput>;
  promoCodes?: Maybe<CartCreatepromoCodesInput>;
  promoErrors?: Maybe<CartCreatepromoErrorsInput>;
  promoIds?: Maybe<CartCreatepromoIdsInput>;
  tip?: Maybe<Scalars['Float']>;
  tipPercent?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userCustomerInfo?: Maybe<UserCustomerInfoCreateNestedManyWithoutCartInput>;
  utensilsCount?: Maybe<Scalars['Int']>;
};

export type CartCreateWithoutUserCustomerInfoInput = {
  Payment?: Maybe<PaymentCreateNestedManyWithoutCartInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  items?: Maybe<CartItemCreateNestedManyWithoutCartInput>;
  promoCodes?: Maybe<CartCreatepromoCodesInput>;
  promoErrors?: Maybe<CartCreatepromoErrorsInput>;
  promoIds?: Maybe<CartCreatepromoIdsInput>;
  tip?: Maybe<Scalars['Float']>;
  tipPercent?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  utensilsCount?: Maybe<Scalars['Int']>;
};

export type CartCreatepromoCodesInput = {
  set?: Maybe<Array<Scalars['String']>>;
};

export type CartCreatepromoErrorsInput = {
  set?: Maybe<Array<Scalars['Json']>>;
};

export type CartCreatepromoIdsInput = {
  set?: Maybe<Array<Scalars['String']>>;
};

export type CartExtraItem = {
  __typename?: 'CartExtraItem';
  extraItem: ExtraItem;
  id: Scalars['String'];
  mealPackItemId?: Maybe<Scalars['String']>;
  quantity: Scalars['Int'];
};

export type CartExtraItemCreateManyCartItemInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  extraItemId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  mealPackItemId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CartExtraItemCreateManyCartItemInputEnvelope = {
  data?: Maybe<Array<CartExtraItemCreateManyCartItemInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CartExtraItemCreateManyExtraItemInput = {
  cartItemId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  mealPackItemId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CartExtraItemCreateManyExtraItemInputEnvelope = {
  data?: Maybe<Array<CartExtraItemCreateManyExtraItemInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CartExtraItemCreateNestedManyWithoutCartItemInput = {
  connect?: Maybe<Array<CartExtraItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CartExtraItemCreateOrConnectWithoutCartItemInput>>;
  create?: Maybe<Array<CartExtraItemCreateWithoutCartItemInput>>;
  createMany?: Maybe<CartExtraItemCreateManyCartItemInputEnvelope>;
};

export type CartExtraItemCreateNestedManyWithoutExtraItemInput = {
  connect?: Maybe<Array<CartExtraItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CartExtraItemCreateOrConnectWithoutExtraItemInput>>;
  create?: Maybe<Array<CartExtraItemCreateWithoutExtraItemInput>>;
  createMany?: Maybe<CartExtraItemCreateManyExtraItemInputEnvelope>;
};

export type CartExtraItemCreateOrConnectWithoutCartItemInput = {
  create: CartExtraItemCreateWithoutCartItemInput;
  where: CartExtraItemWhereUniqueInput;
};

export type CartExtraItemCreateOrConnectWithoutExtraItemInput = {
  create: CartExtraItemCreateWithoutExtraItemInput;
  where: CartExtraItemWhereUniqueInput;
};

export type CartExtraItemCreateWithoutCartItemInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  extraItem: ExtraItemCreateNestedOneWithoutCartExtraItemsInput;
  id?: Maybe<Scalars['String']>;
  mealPackItemId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CartExtraItemCreateWithoutExtraItemInput = {
  cartItem: CartItemCreateNestedOneWithoutCartExtraItemsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  mealPackItemId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CartExtraItemInput = {
  cartExtraItemId?: Maybe<Scalars['ID']>;
  extraItemId: Scalars['ID'];
  mealPackItemId?: Maybe<Scalars['ID']>;
  quantity?: Maybe<Scalars['Int']>;
};

export type CartExtraItemListRelationFilter = {
  every?: Maybe<CartExtraItemWhereInput>;
  none?: Maybe<CartExtraItemWhereInput>;
  some?: Maybe<CartExtraItemWhereInput>;
};

export type CartExtraItemScalarWhereInput = {
  AND?: Maybe<Array<CartExtraItemScalarWhereInput>>;
  NOT?: Maybe<Array<CartExtraItemScalarWhereInput>>;
  OR?: Maybe<Array<CartExtraItemScalarWhereInput>>;
  cartItemId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  extraItemId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  mealPackItemId?: Maybe<StringNullableFilter>;
  quantity?: Maybe<IntFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type CartExtraItemUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  mealPackItemId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  quantity?: Maybe<IntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type CartExtraItemUpdateManyWithWhereWithoutCartItemInput = {
  data: CartExtraItemUpdateManyMutationInput;
  where: CartExtraItemScalarWhereInput;
};

export type CartExtraItemUpdateManyWithWhereWithoutExtraItemInput = {
  data: CartExtraItemUpdateManyMutationInput;
  where: CartExtraItemScalarWhereInput;
};

export type CartExtraItemUpdateManyWithoutCartItemInput = {
  connect?: Maybe<Array<CartExtraItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CartExtraItemCreateOrConnectWithoutCartItemInput>>;
  create?: Maybe<Array<CartExtraItemCreateWithoutCartItemInput>>;
  createMany?: Maybe<CartExtraItemCreateManyCartItemInputEnvelope>;
  delete?: Maybe<Array<CartExtraItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CartExtraItemScalarWhereInput>>;
  disconnect?: Maybe<Array<CartExtraItemWhereUniqueInput>>;
  set?: Maybe<Array<CartExtraItemWhereUniqueInput>>;
  update?: Maybe<Array<CartExtraItemUpdateWithWhereUniqueWithoutCartItemInput>>;
  updateMany?: Maybe<Array<CartExtraItemUpdateManyWithWhereWithoutCartItemInput>>;
  upsert?: Maybe<Array<CartExtraItemUpsertWithWhereUniqueWithoutCartItemInput>>;
};

export type CartExtraItemUpdateManyWithoutExtraItemInput = {
  connect?: Maybe<Array<CartExtraItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CartExtraItemCreateOrConnectWithoutExtraItemInput>>;
  create?: Maybe<Array<CartExtraItemCreateWithoutExtraItemInput>>;
  createMany?: Maybe<CartExtraItemCreateManyExtraItemInputEnvelope>;
  delete?: Maybe<Array<CartExtraItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CartExtraItemScalarWhereInput>>;
  disconnect?: Maybe<Array<CartExtraItemWhereUniqueInput>>;
  set?: Maybe<Array<CartExtraItemWhereUniqueInput>>;
  update?: Maybe<Array<CartExtraItemUpdateWithWhereUniqueWithoutExtraItemInput>>;
  updateMany?: Maybe<Array<CartExtraItemUpdateManyWithWhereWithoutExtraItemInput>>;
  upsert?: Maybe<Array<CartExtraItemUpsertWithWhereUniqueWithoutExtraItemInput>>;
};

export type CartExtraItemUpdateWithWhereUniqueWithoutCartItemInput = {
  data: CartExtraItemUpdateWithoutCartItemInput;
  where: CartExtraItemWhereUniqueInput;
};

export type CartExtraItemUpdateWithWhereUniqueWithoutExtraItemInput = {
  data: CartExtraItemUpdateWithoutExtraItemInput;
  where: CartExtraItemWhereUniqueInput;
};

export type CartExtraItemUpdateWithoutCartItemInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  extraItem?: Maybe<ExtraItemUpdateOneRequiredWithoutCartExtraItemsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  mealPackItemId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  quantity?: Maybe<IntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type CartExtraItemUpdateWithoutExtraItemInput = {
  cartItem?: Maybe<CartItemUpdateOneRequiredWithoutCartExtraItemsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  mealPackItemId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  quantity?: Maybe<IntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type CartExtraItemUpsertWithWhereUniqueWithoutCartItemInput = {
  create: CartExtraItemCreateWithoutCartItemInput;
  update: CartExtraItemUpdateWithoutCartItemInput;
  where: CartExtraItemWhereUniqueInput;
};

export type CartExtraItemUpsertWithWhereUniqueWithoutExtraItemInput = {
  create: CartExtraItemCreateWithoutExtraItemInput;
  update: CartExtraItemUpdateWithoutExtraItemInput;
  where: CartExtraItemWhereUniqueInput;
};

export type CartExtraItemWhereInput = {
  AND?: Maybe<Array<CartExtraItemWhereInput>>;
  NOT?: Maybe<Array<CartExtraItemWhereInput>>;
  OR?: Maybe<Array<CartExtraItemWhereInput>>;
  cartItem?: Maybe<CartItemWhereInput>;
  cartItemId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  extraItem?: Maybe<ExtraItemWhereInput>;
  extraItemId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  mealPackItemId?: Maybe<StringNullableFilter>;
  quantity?: Maybe<IntFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type CartExtraItemWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type CartInput = {
  cartItems: Array<CartItemInput>;
  promoCodes?: Maybe<Array<Scalars['String']>>;
  utensilsCount?: Maybe<Scalars['Int']>;
};

export type CartItem = {
  __typename?: 'CartItem';
  cartExtraItems: Array<CartExtraItem>;
  id: Scalars['String'];
  kitchenMenuItem: KitchenMenuItem;
  note?: Maybe<Scalars['String']>;
  quantity: Scalars['Int'];
};


export type CartItemCartExtraItemsArgs = {
  after?: Maybe<CartExtraItemWhereUniqueInput>;
  before?: Maybe<CartExtraItemWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CartItemCreateManyCartInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  kitchenMenuItemId: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CartItemCreateManyCartInputEnvelope = {
  data?: Maybe<Array<CartItemCreateManyCartInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CartItemCreateManyKitchenMenuItemInput = {
  cartId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CartItemCreateManyKitchenMenuItemInputEnvelope = {
  data?: Maybe<Array<CartItemCreateManyKitchenMenuItemInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CartItemCreateNestedManyWithoutCartInput = {
  connect?: Maybe<Array<CartItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CartItemCreateOrConnectWithoutCartInput>>;
  create?: Maybe<Array<CartItemCreateWithoutCartInput>>;
  createMany?: Maybe<CartItemCreateManyCartInputEnvelope>;
};

export type CartItemCreateNestedManyWithoutKitchenMenuItemInput = {
  connect?: Maybe<Array<CartItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CartItemCreateOrConnectWithoutKitchenMenuItemInput>>;
  create?: Maybe<Array<CartItemCreateWithoutKitchenMenuItemInput>>;
  createMany?: Maybe<CartItemCreateManyKitchenMenuItemInputEnvelope>;
};

export type CartItemCreateNestedOneWithoutCartExtraItemsInput = {
  connect?: Maybe<CartItemWhereUniqueInput>;
  connectOrCreate?: Maybe<CartItemCreateOrConnectWithoutCartExtraItemsInput>;
  create?: Maybe<CartItemCreateWithoutCartExtraItemsInput>;
};

export type CartItemCreateOrConnectWithoutCartExtraItemsInput = {
  create: CartItemCreateWithoutCartExtraItemsInput;
  where: CartItemWhereUniqueInput;
};

export type CartItemCreateOrConnectWithoutCartInput = {
  create: CartItemCreateWithoutCartInput;
  where: CartItemWhereUniqueInput;
};

export type CartItemCreateOrConnectWithoutKitchenMenuItemInput = {
  create: CartItemCreateWithoutKitchenMenuItemInput;
  where: CartItemWhereUniqueInput;
};

export type CartItemCreateWithoutCartExtraItemsInput = {
  cart: CartCreateNestedOneWithoutItemsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  kitchenMenuItem: KitchenMenuItemCreateNestedOneWithoutCartItemsInput;
  note?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CartItemCreateWithoutCartInput = {
  cartExtraItems?: Maybe<CartExtraItemCreateNestedManyWithoutCartItemInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  kitchenMenuItem: KitchenMenuItemCreateNestedOneWithoutCartItemsInput;
  note?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CartItemCreateWithoutKitchenMenuItemInput = {
  cart: CartCreateNestedOneWithoutItemsInput;
  cartExtraItems?: Maybe<CartExtraItemCreateNestedManyWithoutCartItemInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CartItemInput = {
  extraItems: Array<CartExtraItemInput>;
  id: Scalars['ID'];
  note?: Maybe<Scalars['String']>;
  quantity: Scalars['Int'];
};

export type CartItemListRelationFilter = {
  every?: Maybe<CartItemWhereInput>;
  none?: Maybe<CartItemWhereInput>;
  some?: Maybe<CartItemWhereInput>;
};

export type CartItemScalarWhereInput = {
  AND?: Maybe<Array<CartItemScalarWhereInput>>;
  NOT?: Maybe<Array<CartItemScalarWhereInput>>;
  OR?: Maybe<Array<CartItemScalarWhereInput>>;
  cartId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  kitchenMenuItemId?: Maybe<StringFilter>;
  note?: Maybe<StringNullableFilter>;
  quantity?: Maybe<IntFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type CartItemUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  note?: Maybe<NullableStringFieldUpdateOperationsInput>;
  quantity?: Maybe<IntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type CartItemUpdateManyWithWhereWithoutCartInput = {
  data: CartItemUpdateManyMutationInput;
  where: CartItemScalarWhereInput;
};

export type CartItemUpdateManyWithWhereWithoutKitchenMenuItemInput = {
  data: CartItemUpdateManyMutationInput;
  where: CartItemScalarWhereInput;
};

export type CartItemUpdateManyWithoutCartInput = {
  connect?: Maybe<Array<CartItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CartItemCreateOrConnectWithoutCartInput>>;
  create?: Maybe<Array<CartItemCreateWithoutCartInput>>;
  createMany?: Maybe<CartItemCreateManyCartInputEnvelope>;
  delete?: Maybe<Array<CartItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CartItemScalarWhereInput>>;
  disconnect?: Maybe<Array<CartItemWhereUniqueInput>>;
  set?: Maybe<Array<CartItemWhereUniqueInput>>;
  update?: Maybe<Array<CartItemUpdateWithWhereUniqueWithoutCartInput>>;
  updateMany?: Maybe<Array<CartItemUpdateManyWithWhereWithoutCartInput>>;
  upsert?: Maybe<Array<CartItemUpsertWithWhereUniqueWithoutCartInput>>;
};

export type CartItemUpdateManyWithoutKitchenMenuItemInput = {
  connect?: Maybe<Array<CartItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CartItemCreateOrConnectWithoutKitchenMenuItemInput>>;
  create?: Maybe<Array<CartItemCreateWithoutKitchenMenuItemInput>>;
  createMany?: Maybe<CartItemCreateManyKitchenMenuItemInputEnvelope>;
  delete?: Maybe<Array<CartItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CartItemScalarWhereInput>>;
  disconnect?: Maybe<Array<CartItemWhereUniqueInput>>;
  set?: Maybe<Array<CartItemWhereUniqueInput>>;
  update?: Maybe<Array<CartItemUpdateWithWhereUniqueWithoutKitchenMenuItemInput>>;
  updateMany?: Maybe<Array<CartItemUpdateManyWithWhereWithoutKitchenMenuItemInput>>;
  upsert?: Maybe<Array<CartItemUpsertWithWhereUniqueWithoutKitchenMenuItemInput>>;
};

export type CartItemUpdateOneRequiredWithoutCartExtraItemsInput = {
  connect?: Maybe<CartItemWhereUniqueInput>;
  connectOrCreate?: Maybe<CartItemCreateOrConnectWithoutCartExtraItemsInput>;
  create?: Maybe<CartItemCreateWithoutCartExtraItemsInput>;
  update?: Maybe<CartItemUpdateWithoutCartExtraItemsInput>;
  upsert?: Maybe<CartItemUpsertWithoutCartExtraItemsInput>;
};

export type CartItemUpdateWithWhereUniqueWithoutCartInput = {
  data: CartItemUpdateWithoutCartInput;
  where: CartItemWhereUniqueInput;
};

export type CartItemUpdateWithWhereUniqueWithoutKitchenMenuItemInput = {
  data: CartItemUpdateWithoutKitchenMenuItemInput;
  where: CartItemWhereUniqueInput;
};

export type CartItemUpdateWithoutCartExtraItemsInput = {
  cart?: Maybe<CartUpdateOneRequiredWithoutItemsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  kitchenMenuItem?: Maybe<KitchenMenuItemUpdateOneRequiredWithoutCartItemsInput>;
  note?: Maybe<NullableStringFieldUpdateOperationsInput>;
  quantity?: Maybe<IntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type CartItemUpdateWithoutCartInput = {
  cartExtraItems?: Maybe<CartExtraItemUpdateManyWithoutCartItemInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  kitchenMenuItem?: Maybe<KitchenMenuItemUpdateOneRequiredWithoutCartItemsInput>;
  note?: Maybe<NullableStringFieldUpdateOperationsInput>;
  quantity?: Maybe<IntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type CartItemUpdateWithoutKitchenMenuItemInput = {
  cart?: Maybe<CartUpdateOneRequiredWithoutItemsInput>;
  cartExtraItems?: Maybe<CartExtraItemUpdateManyWithoutCartItemInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  note?: Maybe<NullableStringFieldUpdateOperationsInput>;
  quantity?: Maybe<IntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type CartItemUpsertWithWhereUniqueWithoutCartInput = {
  create: CartItemCreateWithoutCartInput;
  update: CartItemUpdateWithoutCartInput;
  where: CartItemWhereUniqueInput;
};

export type CartItemUpsertWithWhereUniqueWithoutKitchenMenuItemInput = {
  create: CartItemCreateWithoutKitchenMenuItemInput;
  update: CartItemUpdateWithoutKitchenMenuItemInput;
  where: CartItemWhereUniqueInput;
};

export type CartItemUpsertWithoutCartExtraItemsInput = {
  create: CartItemCreateWithoutCartExtraItemsInput;
  update: CartItemUpdateWithoutCartExtraItemsInput;
};

export type CartItemWhereInput = {
  AND?: Maybe<Array<CartItemWhereInput>>;
  NOT?: Maybe<Array<CartItemWhereInput>>;
  OR?: Maybe<Array<CartItemWhereInput>>;
  cart?: Maybe<CartWhereInput>;
  cartExtraItems?: Maybe<CartExtraItemListRelationFilter>;
  cartId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  kitchenMenuItem?: Maybe<KitchenMenuItemWhereInput>;
  kitchenMenuItemId?: Maybe<StringFilter>;
  note?: Maybe<StringNullableFilter>;
  quantity?: Maybe<IntFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type CartItemWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type CartPaymentUrl = {
  __typename?: 'CartPaymentUrl';
  url?: Maybe<Scalars['String']>;
  validation?: Maybe<OrderValidation>;
};

export type CartUpdateOneRequiredWithoutItemsInput = {
  connect?: Maybe<CartWhereUniqueInput>;
  connectOrCreate?: Maybe<CartCreateOrConnectWithoutItemsInput>;
  create?: Maybe<CartCreateWithoutItemsInput>;
  update?: Maybe<CartUpdateWithoutItemsInput>;
  upsert?: Maybe<CartUpsertWithoutItemsInput>;
};

export type CartUpdateOneRequiredWithoutUserCustomerInfoInput = {
  connect?: Maybe<CartWhereUniqueInput>;
  connectOrCreate?: Maybe<CartCreateOrConnectWithoutUserCustomerInfoInput>;
  create?: Maybe<CartCreateWithoutUserCustomerInfoInput>;
  update?: Maybe<CartUpdateWithoutUserCustomerInfoInput>;
  upsert?: Maybe<CartUpsertWithoutUserCustomerInfoInput>;
};

export type CartUpdateOneWithoutPaymentInput = {
  connect?: Maybe<CartWhereUniqueInput>;
  connectOrCreate?: Maybe<CartCreateOrConnectWithoutPaymentInput>;
  create?: Maybe<CartCreateWithoutPaymentInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<CartUpdateWithoutPaymentInput>;
  upsert?: Maybe<CartUpsertWithoutPaymentInput>;
};

export type CartUpdateWithoutItemsInput = {
  Payment?: Maybe<PaymentUpdateManyWithoutCartInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  promoCodes?: Maybe<CartUpdatepromoCodesInput>;
  promoErrors?: Maybe<CartUpdatepromoErrorsInput>;
  promoIds?: Maybe<CartUpdatepromoIdsInput>;
  tip?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  tipPercent?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  userCustomerInfo?: Maybe<UserCustomerInfoUpdateManyWithoutCartInput>;
  utensilsCount?: Maybe<IntFieldUpdateOperationsInput>;
};

export type CartUpdateWithoutPaymentInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  items?: Maybe<CartItemUpdateManyWithoutCartInput>;
  promoCodes?: Maybe<CartUpdatepromoCodesInput>;
  promoErrors?: Maybe<CartUpdatepromoErrorsInput>;
  promoIds?: Maybe<CartUpdatepromoIdsInput>;
  tip?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  tipPercent?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  userCustomerInfo?: Maybe<UserCustomerInfoUpdateManyWithoutCartInput>;
  utensilsCount?: Maybe<IntFieldUpdateOperationsInput>;
};

export type CartUpdateWithoutUserCustomerInfoInput = {
  Payment?: Maybe<PaymentUpdateManyWithoutCartInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  items?: Maybe<CartItemUpdateManyWithoutCartInput>;
  promoCodes?: Maybe<CartUpdatepromoCodesInput>;
  promoErrors?: Maybe<CartUpdatepromoErrorsInput>;
  promoIds?: Maybe<CartUpdatepromoIdsInput>;
  tip?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  tipPercent?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  utensilsCount?: Maybe<IntFieldUpdateOperationsInput>;
};

export type CartUpdatepromoCodesInput = {
  push?: Maybe<Scalars['String']>;
  set?: Maybe<Array<Scalars['String']>>;
};

export type CartUpdatepromoErrorsInput = {
  push?: Maybe<Scalars['Json']>;
  set?: Maybe<Array<Scalars['Json']>>;
};

export type CartUpdatepromoIdsInput = {
  push?: Maybe<Scalars['String']>;
  set?: Maybe<Array<Scalars['String']>>;
};

export type CartUpsellItem = {
  __typename?: 'CartUpsellItem';
  facilityId: Scalars['String'];
  id: Scalars['ID'];
  sortId: Scalars['Int'];
  upsellItem: KitchenMenuItem;
  upsellItemId: Scalars['String'];
};

export type CartUpsellItemCreateManyFacilityInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  platform: Platform;
  sortId: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  upsellItemId: Scalars['String'];
};

export type CartUpsellItemCreateManyFacilityInputEnvelope = {
  data?: Maybe<Array<CartUpsellItemCreateManyFacilityInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CartUpsellItemCreateManyUpsellItemInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  facilityId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  platform: Platform;
  sortId: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CartUpsellItemCreateManyUpsellItemInputEnvelope = {
  data?: Maybe<Array<CartUpsellItemCreateManyUpsellItemInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CartUpsellItemCreateNestedManyWithoutFacilityInput = {
  connect?: Maybe<Array<CartUpsellItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CartUpsellItemCreateOrConnectWithoutFacilityInput>>;
  create?: Maybe<Array<CartUpsellItemCreateWithoutFacilityInput>>;
  createMany?: Maybe<CartUpsellItemCreateManyFacilityInputEnvelope>;
};

export type CartUpsellItemCreateNestedManyWithoutUpsellItemInput = {
  connect?: Maybe<Array<CartUpsellItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CartUpsellItemCreateOrConnectWithoutUpsellItemInput>>;
  create?: Maybe<Array<CartUpsellItemCreateWithoutUpsellItemInput>>;
  createMany?: Maybe<CartUpsellItemCreateManyUpsellItemInputEnvelope>;
};

export type CartUpsellItemCreateOrConnectWithoutFacilityInput = {
  create: CartUpsellItemCreateWithoutFacilityInput;
  where: CartUpsellItemWhereUniqueInput;
};

export type CartUpsellItemCreateOrConnectWithoutUpsellItemInput = {
  create: CartUpsellItemCreateWithoutUpsellItemInput;
  where: CartUpsellItemWhereUniqueInput;
};

export type CartUpsellItemCreateWithoutFacilityInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  platform: Platform;
  sortId: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  upsellItem: KitchenMenuItemCreateNestedOneWithoutCartUpsellItemInput;
};

export type CartUpsellItemCreateWithoutUpsellItemInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  facility: FacilityCreateNestedOneWithoutCartUpsellItemInput;
  id?: Maybe<Scalars['String']>;
  platform: Platform;
  sortId: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CartUpsellItemInput = {
  data: Array<UpsellMenuItemCreateInput>;
  platform: ClientPlatformInput;
};

export type CartUpsellItemListRelationFilter = {
  every?: Maybe<CartUpsellItemWhereInput>;
  none?: Maybe<CartUpsellItemWhereInput>;
  some?: Maybe<CartUpsellItemWhereInput>;
};

export type CartUpsellItemScalarWhereInput = {
  AND?: Maybe<Array<CartUpsellItemScalarWhereInput>>;
  NOT?: Maybe<Array<CartUpsellItemScalarWhereInput>>;
  OR?: Maybe<Array<CartUpsellItemScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  facilityId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  platform?: Maybe<EnumPlatformFilter>;
  sortId?: Maybe<IntFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  upsellItemId?: Maybe<StringFilter>;
};

export type CartUpsellItemUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  platform?: Maybe<EnumPlatformFieldUpdateOperationsInput>;
  sortId?: Maybe<IntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type CartUpsellItemUpdateManyWithWhereWithoutFacilityInput = {
  data: CartUpsellItemUpdateManyMutationInput;
  where: CartUpsellItemScalarWhereInput;
};

export type CartUpsellItemUpdateManyWithWhereWithoutUpsellItemInput = {
  data: CartUpsellItemUpdateManyMutationInput;
  where: CartUpsellItemScalarWhereInput;
};

export type CartUpsellItemUpdateManyWithoutFacilityInput = {
  connect?: Maybe<Array<CartUpsellItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CartUpsellItemCreateOrConnectWithoutFacilityInput>>;
  create?: Maybe<Array<CartUpsellItemCreateWithoutFacilityInput>>;
  createMany?: Maybe<CartUpsellItemCreateManyFacilityInputEnvelope>;
  delete?: Maybe<Array<CartUpsellItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CartUpsellItemScalarWhereInput>>;
  disconnect?: Maybe<Array<CartUpsellItemWhereUniqueInput>>;
  set?: Maybe<Array<CartUpsellItemWhereUniqueInput>>;
  update?: Maybe<Array<CartUpsellItemUpdateWithWhereUniqueWithoutFacilityInput>>;
  updateMany?: Maybe<Array<CartUpsellItemUpdateManyWithWhereWithoutFacilityInput>>;
  upsert?: Maybe<Array<CartUpsellItemUpsertWithWhereUniqueWithoutFacilityInput>>;
};

export type CartUpsellItemUpdateManyWithoutUpsellItemInput = {
  connect?: Maybe<Array<CartUpsellItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CartUpsellItemCreateOrConnectWithoutUpsellItemInput>>;
  create?: Maybe<Array<CartUpsellItemCreateWithoutUpsellItemInput>>;
  createMany?: Maybe<CartUpsellItemCreateManyUpsellItemInputEnvelope>;
  delete?: Maybe<Array<CartUpsellItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CartUpsellItemScalarWhereInput>>;
  disconnect?: Maybe<Array<CartUpsellItemWhereUniqueInput>>;
  set?: Maybe<Array<CartUpsellItemWhereUniqueInput>>;
  update?: Maybe<Array<CartUpsellItemUpdateWithWhereUniqueWithoutUpsellItemInput>>;
  updateMany?: Maybe<Array<CartUpsellItemUpdateManyWithWhereWithoutUpsellItemInput>>;
  upsert?: Maybe<Array<CartUpsellItemUpsertWithWhereUniqueWithoutUpsellItemInput>>;
};

export type CartUpsellItemUpdateWithWhereUniqueWithoutFacilityInput = {
  data: CartUpsellItemUpdateWithoutFacilityInput;
  where: CartUpsellItemWhereUniqueInput;
};

export type CartUpsellItemUpdateWithWhereUniqueWithoutUpsellItemInput = {
  data: CartUpsellItemUpdateWithoutUpsellItemInput;
  where: CartUpsellItemWhereUniqueInput;
};

export type CartUpsellItemUpdateWithoutFacilityInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  platform?: Maybe<EnumPlatformFieldUpdateOperationsInput>;
  sortId?: Maybe<IntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  upsellItem?: Maybe<KitchenMenuItemUpdateOneRequiredWithoutCartUpsellItemInput>;
};

export type CartUpsellItemUpdateWithoutUpsellItemInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  facility?: Maybe<FacilityUpdateOneRequiredWithoutCartUpsellItemInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  platform?: Maybe<EnumPlatformFieldUpdateOperationsInput>;
  sortId?: Maybe<IntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type CartUpsellItemUpsertWithWhereUniqueWithoutFacilityInput = {
  create: CartUpsellItemCreateWithoutFacilityInput;
  update: CartUpsellItemUpdateWithoutFacilityInput;
  where: CartUpsellItemWhereUniqueInput;
};

export type CartUpsellItemUpsertWithWhereUniqueWithoutUpsellItemInput = {
  create: CartUpsellItemCreateWithoutUpsellItemInput;
  update: CartUpsellItemUpdateWithoutUpsellItemInput;
  where: CartUpsellItemWhereUniqueInput;
};

export type CartUpsellItemWhereInput = {
  AND?: Maybe<Array<CartUpsellItemWhereInput>>;
  NOT?: Maybe<Array<CartUpsellItemWhereInput>>;
  OR?: Maybe<Array<CartUpsellItemWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  facility?: Maybe<FacilityWhereInput>;
  facilityId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  platform?: Maybe<EnumPlatformFilter>;
  sortId?: Maybe<IntFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  upsellItem?: Maybe<KitchenMenuItemWhereInput>;
  upsellItemId?: Maybe<StringFilter>;
};

export type CartUpsellItemWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type CartUpsertWithoutItemsInput = {
  create: CartCreateWithoutItemsInput;
  update: CartUpdateWithoutItemsInput;
};

export type CartUpsertWithoutPaymentInput = {
  create: CartCreateWithoutPaymentInput;
  update: CartUpdateWithoutPaymentInput;
};

export type CartUpsertWithoutUserCustomerInfoInput = {
  create: CartCreateWithoutUserCustomerInfoInput;
  update: CartUpdateWithoutUserCustomerInfoInput;
};

export type CartWhereInput = {
  AND?: Maybe<Array<CartWhereInput>>;
  NOT?: Maybe<Array<CartWhereInput>>;
  OR?: Maybe<Array<CartWhereInput>>;
  Payment?: Maybe<PaymentListRelationFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  items?: Maybe<CartItemListRelationFilter>;
  promoCodes?: Maybe<StringNullableListFilter>;
  promoErrors?: Maybe<JsonNullableListFilter>;
  promoIds?: Maybe<StringNullableListFilter>;
  tip?: Maybe<FloatNullableFilter>;
  tipPercent?: Maybe<FloatNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  userCustomerInfo?: Maybe<UserCustomerInfoListRelationFilter>;
  utensilsCount?: Maybe<IntFilter>;
};

export type CartWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ChowlyOrderConfirmation = {
  __typename?: 'ChowlyOrderConfirmation';
  test: Scalars['Boolean'];
};

export enum ClientPlatformInput {
  Admin = 'admin',
  Catering = 'catering',
  Consumer = 'consumer',
  ConsumerWeb = 'consumer_web',
  Kiosk = 'kiosk'
}

export type ConfirmChowlyOrderInput = {
  test?: Maybe<Scalars['Boolean']>;
};

export type ConfirmKioskOrderInput = {
  cart: CartInput;
  order: KioskOrderInput;
};

export type ConfirmOrderInput = {
  order: OrderInput;
  paymentMethodId?: Maybe<Scalars['ID']>;
  paymentMethodNickName?: Maybe<Scalars['String']>;
  savePaymentMethod?: Maybe<Scalars['Boolean']>;
};

export type ConsumerAppSetup = {
  __typename?: 'ConsumerAppSetup';
  facilities: Array<Scalars['String']>;
  isOutdated: Scalars['Boolean'];
  updateUrl: Scalars['String'];
};

export type CustomerDeliveryAddressCreateManyUserInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  formattedAddress?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  locality?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  placeId: Scalars['String'];
  postalCode?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CustomerDeliveryAddressCreateManyUserInputEnvelope = {
  data?: Maybe<Array<CustomerDeliveryAddressCreateManyUserInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CustomerDeliveryAddressCreateNestedManyWithoutUserInput = {
  connect?: Maybe<Array<CustomerDeliveryAddressWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CustomerDeliveryAddressCreateOrConnectWithoutUserInput>>;
  create?: Maybe<Array<CustomerDeliveryAddressCreateWithoutUserInput>>;
  createMany?: Maybe<CustomerDeliveryAddressCreateManyUserInputEnvelope>;
};

export type CustomerDeliveryAddressCreateOrConnectWithoutUserInput = {
  create: CustomerDeliveryAddressCreateWithoutUserInput;
  where: CustomerDeliveryAddressWhereUniqueInput;
};

export type CustomerDeliveryAddressCreateWithoutUserInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  formattedAddress?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  locality?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  placeId: Scalars['String'];
  postalCode?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CustomerDeliveryAddressListRelationFilter = {
  every?: Maybe<CustomerDeliveryAddressWhereInput>;
  none?: Maybe<CustomerDeliveryAddressWhereInput>;
  some?: Maybe<CustomerDeliveryAddressWhereInput>;
};

export type CustomerDeliveryAddressScalarWhereInput = {
  AND?: Maybe<Array<CustomerDeliveryAddressScalarWhereInput>>;
  NOT?: Maybe<Array<CustomerDeliveryAddressScalarWhereInput>>;
  OR?: Maybe<Array<CustomerDeliveryAddressScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  formattedAddress?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  lat?: Maybe<FloatNullableFilter>;
  lng?: Maybe<FloatNullableFilter>;
  locality?: Maybe<StringNullableFilter>;
  nickname?: Maybe<StringNullableFilter>;
  note?: Maybe<StringNullableFilter>;
  placeId?: Maybe<StringFilter>;
  postalCode?: Maybe<StringNullableFilter>;
  region?: Maybe<StringNullableFilter>;
  street?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  userId?: Maybe<StringFilter>;
};

export type CustomerDeliveryAddressUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  formattedAddress?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  lat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  lng?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  locality?: Maybe<NullableStringFieldUpdateOperationsInput>;
  nickname?: Maybe<NullableStringFieldUpdateOperationsInput>;
  note?: Maybe<NullableStringFieldUpdateOperationsInput>;
  placeId?: Maybe<StringFieldUpdateOperationsInput>;
  postalCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  region?: Maybe<NullableStringFieldUpdateOperationsInput>;
  street?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type CustomerDeliveryAddressUpdateManyWithWhereWithoutUserInput = {
  data: CustomerDeliveryAddressUpdateManyMutationInput;
  where: CustomerDeliveryAddressScalarWhereInput;
};

export type CustomerDeliveryAddressUpdateManyWithoutUserInput = {
  connect?: Maybe<Array<CustomerDeliveryAddressWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CustomerDeliveryAddressCreateOrConnectWithoutUserInput>>;
  create?: Maybe<Array<CustomerDeliveryAddressCreateWithoutUserInput>>;
  createMany?: Maybe<CustomerDeliveryAddressCreateManyUserInputEnvelope>;
  delete?: Maybe<Array<CustomerDeliveryAddressWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CustomerDeliveryAddressScalarWhereInput>>;
  disconnect?: Maybe<Array<CustomerDeliveryAddressWhereUniqueInput>>;
  set?: Maybe<Array<CustomerDeliveryAddressWhereUniqueInput>>;
  update?: Maybe<Array<CustomerDeliveryAddressUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<CustomerDeliveryAddressUpdateManyWithWhereWithoutUserInput>>;
  upsert?: Maybe<Array<CustomerDeliveryAddressUpsertWithWhereUniqueWithoutUserInput>>;
};

export type CustomerDeliveryAddressUpdateWithWhereUniqueWithoutUserInput = {
  data: CustomerDeliveryAddressUpdateWithoutUserInput;
  where: CustomerDeliveryAddressWhereUniqueInput;
};

export type CustomerDeliveryAddressUpdateWithoutUserInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  formattedAddress?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  lat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  lng?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  locality?: Maybe<NullableStringFieldUpdateOperationsInput>;
  nickname?: Maybe<NullableStringFieldUpdateOperationsInput>;
  note?: Maybe<NullableStringFieldUpdateOperationsInput>;
  placeId?: Maybe<StringFieldUpdateOperationsInput>;
  postalCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  region?: Maybe<NullableStringFieldUpdateOperationsInput>;
  street?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type CustomerDeliveryAddressUpsertWithWhereUniqueWithoutUserInput = {
  create: CustomerDeliveryAddressCreateWithoutUserInput;
  update: CustomerDeliveryAddressUpdateWithoutUserInput;
  where: CustomerDeliveryAddressWhereUniqueInput;
};

export type CustomerDeliveryAddressWhereInput = {
  AND?: Maybe<Array<CustomerDeliveryAddressWhereInput>>;
  NOT?: Maybe<Array<CustomerDeliveryAddressWhereInput>>;
  OR?: Maybe<Array<CustomerDeliveryAddressWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  formattedAddress?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  lat?: Maybe<FloatNullableFilter>;
  lng?: Maybe<FloatNullableFilter>;
  locality?: Maybe<StringNullableFilter>;
  nickname?: Maybe<StringNullableFilter>;
  note?: Maybe<StringNullableFilter>;
  placeId?: Maybe<StringFilter>;
  postalCode?: Maybe<StringNullableFilter>;
  region?: Maybe<StringNullableFilter>;
  street?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  user?: Maybe<UserWhereInput>;
  userId?: Maybe<StringFilter>;
};

export type CustomerDeliveryAddressWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type CustomerKitchen = {
  __typename?: 'CustomerKitchen';
  cuisine?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  headerImgUrl?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isOpen?: Maybe<Scalars['Boolean']>;
  logoUrl?: Maybe<Scalars['String']>;
  menuCategories: Array<MenuCategory>;
  name: Scalars['String'];
  operationHours: Array<Scalars['Json']>;
  originalLocation?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  sortId: Scalars['Int'];
  status: KitchenStatus;
  story?: Maybe<Scalars['String']>;
  type: KitchenType;
  /** For consumer app only. If there are no menu items for selected dateTime, next upcoming menu is returned. This is helper field to let client know when the next menu will be available. */
  upcomingMenuStart?: Maybe<Scalars['DateTime']>;
};

export type CustomerKitchenByOpen = {
  __typename?: 'CustomerKitchenByOpen';
  cuisine?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  headerImgUrl?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isOpen: Scalars['Boolean'];
  logoUrl?: Maybe<Scalars['String']>;
  menuCategories: Array<MenuCategory>;
  name: Scalars['String'];
  operationHours: Array<Scalars['Json']>;
  originalLocation?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  status: KitchenStatus;
  story?: Maybe<Scalars['String']>;
  type: KitchenType;
  /**
   * If there are no menu items for selected dateTime, next upcoming menu is returned. This is helper field to let client know when the next menu will be available.
   *
   * If null is returned there is menu schedule for selected time.
   */
  upcomingMenuStart?: Maybe<Scalars['DateTime']>;
};

export type CustomerKitchenInList = {
  __typename?: 'CustomerKitchenInList';
  cuisine?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  headerImgUrl?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  logoUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  operationHours: Array<Scalars['Json']>;
  phone?: Maybe<Scalars['String']>;
  sortId: Scalars['Int'];
  story?: Maybe<Scalars['String']>;
};

export type CustomerLead = {
  __typename?: 'CustomerLead';
  email: Scalars['String'];
  id: Scalars['String'];
  location: Scalars['String'];
};

export type CustomerLeadCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  location: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CustomerRatingCreateManyCustomerInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  ratingType: CustomerRatingType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value: Scalars['Int'];
};

export type CustomerRatingCreateManyCustomerInputEnvelope = {
  data?: Maybe<Array<CustomerRatingCreateManyCustomerInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CustomerRatingCreateNestedManyWithoutCustomerInput = {
  connect?: Maybe<Array<CustomerRatingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CustomerRatingCreateOrConnectWithoutCustomerInput>>;
  create?: Maybe<Array<CustomerRatingCreateWithoutCustomerInput>>;
  createMany?: Maybe<CustomerRatingCreateManyCustomerInputEnvelope>;
};

export type CustomerRatingCreateOrConnectWithoutCustomerInput = {
  create: CustomerRatingCreateWithoutCustomerInput;
  where: CustomerRatingWhereUniqueInput;
};

export type CustomerRatingCreateWithoutCustomerInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  ratingType: CustomerRatingType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value: Scalars['Int'];
};

export type CustomerRatingListRelationFilter = {
  every?: Maybe<CustomerRatingWhereInput>;
  none?: Maybe<CustomerRatingWhereInput>;
  some?: Maybe<CustomerRatingWhereInput>;
};

export type CustomerRatingScalarWhereInput = {
  AND?: Maybe<Array<CustomerRatingScalarWhereInput>>;
  NOT?: Maybe<Array<CustomerRatingScalarWhereInput>>;
  OR?: Maybe<Array<CustomerRatingScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  customerId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  ratingType?: Maybe<EnumCustomerRatingTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  value?: Maybe<IntFilter>;
};

export enum CustomerRatingType {
  Delivery = 'DELIVERY',
  FoodQuality = 'FOOD_QUALITY',
  Overall = 'OVERALL'
}

export type CustomerRatingUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  ratingType?: Maybe<EnumCustomerRatingTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  value?: Maybe<IntFieldUpdateOperationsInput>;
};

export type CustomerRatingUpdateManyWithWhereWithoutCustomerInput = {
  data: CustomerRatingUpdateManyMutationInput;
  where: CustomerRatingScalarWhereInput;
};

export type CustomerRatingUpdateManyWithoutCustomerInput = {
  connect?: Maybe<Array<CustomerRatingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CustomerRatingCreateOrConnectWithoutCustomerInput>>;
  create?: Maybe<Array<CustomerRatingCreateWithoutCustomerInput>>;
  createMany?: Maybe<CustomerRatingCreateManyCustomerInputEnvelope>;
  delete?: Maybe<Array<CustomerRatingWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CustomerRatingScalarWhereInput>>;
  disconnect?: Maybe<Array<CustomerRatingWhereUniqueInput>>;
  set?: Maybe<Array<CustomerRatingWhereUniqueInput>>;
  update?: Maybe<Array<CustomerRatingUpdateWithWhereUniqueWithoutCustomerInput>>;
  updateMany?: Maybe<Array<CustomerRatingUpdateManyWithWhereWithoutCustomerInput>>;
  upsert?: Maybe<Array<CustomerRatingUpsertWithWhereUniqueWithoutCustomerInput>>;
};

export type CustomerRatingUpdateWithWhereUniqueWithoutCustomerInput = {
  data: CustomerRatingUpdateWithoutCustomerInput;
  where: CustomerRatingWhereUniqueInput;
};

export type CustomerRatingUpdateWithoutCustomerInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  ratingType?: Maybe<EnumCustomerRatingTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  value?: Maybe<IntFieldUpdateOperationsInput>;
};

export type CustomerRatingUpsertWithWhereUniqueWithoutCustomerInput = {
  create: CustomerRatingCreateWithoutCustomerInput;
  update: CustomerRatingUpdateWithoutCustomerInput;
  where: CustomerRatingWhereUniqueInput;
};

export type CustomerRatingWhereInput = {
  AND?: Maybe<Array<CustomerRatingWhereInput>>;
  NOT?: Maybe<Array<CustomerRatingWhereInput>>;
  OR?: Maybe<Array<CustomerRatingWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  customer?: Maybe<UserCustomerInfoWhereInput>;
  customerId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  ratingType?: Maybe<EnumCustomerRatingTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  value?: Maybe<IntFilter>;
};

export type CustomerRatingWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type CustomersReturnType = {
  __typename?: 'CustomersReturnType';
  total: Scalars['Int'];
  users: Array<User>;
};


export type DateTimeFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['DateTime']>;
};

export type DateTimeFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeFilter>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
};

export type DateTimeNullableFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeNullableFilter>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
};

export type DeleteCartItemInput = {
  cartItemId: Scalars['ID'];
};

export type DeleteCartItemsInput = {
  cartMenuItemIds?: Maybe<Array<Scalars['String']>>;
};

export type DeliveryEligibility = {
  __typename?: 'DeliveryEligibility';
  deliveryDistanceInMeters: Scalars['Int'];
  deliveryTimeInSeconds: Scalars['Int'];
  facility: Facility;
  id?: Maybe<Scalars['ID']>;
};

export type DeliveryInfo = {
  __typename?: 'DeliveryInfo';
  deliveryDistanceInMeters?: Maybe<Scalars['Int']>;
  deliveryTimeInSeconds?: Maybe<Scalars['Int']>;
  facilityId: Scalars['ID'];
  isDeliverable: Scalars['Boolean'];
};

export enum DeliveryOption {
  LeaveAtDoor = 'LEAVE_AT_DOOR',
  MeetAtDoor = 'MEET_AT_DOOR'
}

export type Device = {
  __typename?: 'Device';
  errorCount: Scalars['Int'];
  id: Scalars['String'];
  lastError?: Maybe<Scalars['String']>;
  lastErrorAt?: Maybe<Scalars['DateTime']>;
  lastSeenAt: Scalars['DateTime'];
  name: Scalars['String'];
  status: DeviceStatus;
  token: Scalars['String'];
  user: User;
};

export type DeviceCreateManyUserInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  errorCount?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  lastError?: Maybe<Scalars['String']>;
  lastErrorAt?: Maybe<Scalars['DateTime']>;
  lastSeenAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  status?: Maybe<DeviceStatus>;
  token: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DeviceCreateManyUserInputEnvelope = {
  data?: Maybe<Array<DeviceCreateManyUserInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type DeviceCreateNestedManyWithoutUserInput = {
  connect?: Maybe<Array<DeviceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<DeviceCreateOrConnectWithoutUserInput>>;
  create?: Maybe<Array<DeviceCreateWithoutUserInput>>;
  createMany?: Maybe<DeviceCreateManyUserInputEnvelope>;
};

export type DeviceCreateOrConnectWithoutUserInput = {
  create: DeviceCreateWithoutUserInput;
  where: DeviceWhereUniqueInput;
};

export type DeviceCreateWithoutUserInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  errorCount?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  lastError?: Maybe<Scalars['String']>;
  lastErrorAt?: Maybe<Scalars['DateTime']>;
  lastSeenAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  status?: Maybe<DeviceStatus>;
  token: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DeviceListRelationFilter = {
  every?: Maybe<DeviceWhereInput>;
  none?: Maybe<DeviceWhereInput>;
  some?: Maybe<DeviceWhereInput>;
};

export type DeviceRegisterInput = {
  name: Scalars['String'];
  token: Scalars['String'];
};

export type DeviceScalarWhereInput = {
  AND?: Maybe<Array<DeviceScalarWhereInput>>;
  NOT?: Maybe<Array<DeviceScalarWhereInput>>;
  OR?: Maybe<Array<DeviceScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  errorCount?: Maybe<IntFilter>;
  id?: Maybe<StringFilter>;
  lastError?: Maybe<StringNullableFilter>;
  lastErrorAt?: Maybe<DateTimeNullableFilter>;
  lastSeenAt?: Maybe<DateTimeFilter>;
  name?: Maybe<StringFilter>;
  status?: Maybe<EnumDeviceStatusFilter>;
  token?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  userId?: Maybe<StringFilter>;
};

export enum DeviceStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type DeviceUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  errorCount?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  lastError?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastErrorAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastSeenAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  status?: Maybe<EnumDeviceStatusFieldUpdateOperationsInput>;
  token?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type DeviceUpdateManyWithWhereWithoutUserInput = {
  data: DeviceUpdateManyMutationInput;
  where: DeviceScalarWhereInput;
};

export type DeviceUpdateManyWithoutUserInput = {
  connect?: Maybe<Array<DeviceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<DeviceCreateOrConnectWithoutUserInput>>;
  create?: Maybe<Array<DeviceCreateWithoutUserInput>>;
  createMany?: Maybe<DeviceCreateManyUserInputEnvelope>;
  delete?: Maybe<Array<DeviceWhereUniqueInput>>;
  deleteMany?: Maybe<Array<DeviceScalarWhereInput>>;
  disconnect?: Maybe<Array<DeviceWhereUniqueInput>>;
  set?: Maybe<Array<DeviceWhereUniqueInput>>;
  update?: Maybe<Array<DeviceUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<DeviceUpdateManyWithWhereWithoutUserInput>>;
  upsert?: Maybe<Array<DeviceUpsertWithWhereUniqueWithoutUserInput>>;
};

export type DeviceUpdateWithWhereUniqueWithoutUserInput = {
  data: DeviceUpdateWithoutUserInput;
  where: DeviceWhereUniqueInput;
};

export type DeviceUpdateWithoutUserInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  errorCount?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  lastError?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastErrorAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastSeenAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  status?: Maybe<EnumDeviceStatusFieldUpdateOperationsInput>;
  token?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type DeviceUpsertWithWhereUniqueWithoutUserInput = {
  create: DeviceCreateWithoutUserInput;
  update: DeviceUpdateWithoutUserInput;
  where: DeviceWhereUniqueInput;
};

export type DeviceWhereInput = {
  AND?: Maybe<Array<DeviceWhereInput>>;
  NOT?: Maybe<Array<DeviceWhereInput>>;
  OR?: Maybe<Array<DeviceWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  errorCount?: Maybe<IntFilter>;
  id?: Maybe<StringFilter>;
  lastError?: Maybe<StringNullableFilter>;
  lastErrorAt?: Maybe<DateTimeNullableFilter>;
  lastSeenAt?: Maybe<DateTimeFilter>;
  name?: Maybe<StringFilter>;
  status?: Maybe<EnumDeviceStatusFilter>;
  token?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  user?: Maybe<UserWhereInput>;
  userId?: Maybe<StringFilter>;
};

export type DeviceWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
};

export enum DishType {
  Alcohol = 'ALCOHOL',
  KidFriendly = 'KID_FRIENDLY',
  Vegan = 'VEGAN',
  Vegetarian = 'VEGETARIAN'
}

export type Driver = {
  __typename?: 'Driver';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type EmailUpdate = {
  __typename?: 'EmailUpdate';
  email: Scalars['String'];
};

export type EmailUpdateInput = {
  newEmail: Scalars['String'];
  oldEmail: Scalars['String'];
};

export type EnumAllergyNullableListFilter = {
  equals?: Maybe<Array<Allergy>>;
  has?: Maybe<Allergy>;
  hasEvery?: Maybe<Array<Allergy>>;
  hasSome?: Maybe<Array<Allergy>>;
  isEmpty?: Maybe<Scalars['Boolean']>;
};

export type EnumBraintreePaymentMethodFieldUpdateOperationsInput = {
  set?: Maybe<BraintreePaymentMethod>;
};

export type EnumBraintreePaymentMethodFilter = {
  equals?: Maybe<BraintreePaymentMethod>;
  in?: Maybe<Array<BraintreePaymentMethod>>;
  not?: Maybe<NestedEnumBraintreePaymentMethodFilter>;
  notIn?: Maybe<Array<BraintreePaymentMethod>>;
};

export type EnumBraintreePaymentSuccessStatusFieldUpdateOperationsInput = {
  set?: Maybe<BraintreePaymentSuccessStatus>;
};

export type EnumBraintreePaymentSuccessStatusFilter = {
  equals?: Maybe<BraintreePaymentSuccessStatus>;
  in?: Maybe<Array<BraintreePaymentSuccessStatus>>;
  not?: Maybe<NestedEnumBraintreePaymentSuccessStatusFilter>;
  notIn?: Maybe<Array<BraintreePaymentSuccessStatus>>;
};

export type EnumBusinessTypeNullableFilter = {
  equals?: Maybe<BusinessType>;
  in?: Maybe<Array<BusinessType>>;
  not?: Maybe<NestedEnumBusinessTypeNullableFilter>;
  notIn?: Maybe<Array<BusinessType>>;
};

export type EnumCustomerRatingTypeFieldUpdateOperationsInput = {
  set?: Maybe<CustomerRatingType>;
};

export type EnumCustomerRatingTypeFilter = {
  equals?: Maybe<CustomerRatingType>;
  in?: Maybe<Array<CustomerRatingType>>;
  not?: Maybe<NestedEnumCustomerRatingTypeFilter>;
  notIn?: Maybe<Array<CustomerRatingType>>;
};

export type EnumDeliveryOptionNullableFilter = {
  equals?: Maybe<DeliveryOption>;
  in?: Maybe<Array<DeliveryOption>>;
  not?: Maybe<NestedEnumDeliveryOptionNullableFilter>;
  notIn?: Maybe<Array<DeliveryOption>>;
};

export type EnumDeviceStatusFieldUpdateOperationsInput = {
  set?: Maybe<DeviceStatus>;
};

export type EnumDeviceStatusFilter = {
  equals?: Maybe<DeviceStatus>;
  in?: Maybe<Array<DeviceStatus>>;
  not?: Maybe<NestedEnumDeviceStatusFilter>;
  notIn?: Maybe<Array<DeviceStatus>>;
};

export type EnumDishTypeNullableListFilter = {
  equals?: Maybe<Array<DishType>>;
  has?: Maybe<DishType>;
  hasEvery?: Maybe<Array<DishType>>;
  hasSome?: Maybe<Array<DishType>>;
  isEmpty?: Maybe<Scalars['Boolean']>;
};

export type EnumFeeTypeFieldUpdateOperationsInput = {
  set?: Maybe<FeeType>;
};

export type EnumFeeTypeFilter = {
  equals?: Maybe<FeeType>;
  in?: Maybe<Array<FeeType>>;
  not?: Maybe<NestedEnumFeeTypeFilter>;
  notIn?: Maybe<Array<FeeType>>;
};

export type EnumFeeTypeNullableFilter = {
  equals?: Maybe<FeeType>;
  in?: Maybe<Array<FeeType>>;
  not?: Maybe<NestedEnumFeeTypeNullableFilter>;
  notIn?: Maybe<Array<FeeType>>;
};

export type EnumInventoryStatusFieldUpdateOperationsInput = {
  set?: Maybe<InventoryStatus>;
};

export type EnumInventoryStatusFilter = {
  equals?: Maybe<InventoryStatus>;
  in?: Maybe<Array<InventoryStatus>>;
  not?: Maybe<NestedEnumInventoryStatusFilter>;
  notIn?: Maybe<Array<InventoryStatus>>;
};

export type EnumKdsTypeFieldUpdateOperationsInput = {
  set?: Maybe<KdsType>;
};

export type EnumKdsTypeFilter = {
  equals?: Maybe<KdsType>;
  in?: Maybe<Array<KdsType>>;
  not?: Maybe<NestedEnumKdsTypeFilter>;
  notIn?: Maybe<Array<KdsType>>;
};

export type EnumKitchenMenuItemStatusFieldUpdateOperationsInput = {
  set?: Maybe<KitchenMenuItemStatus>;
};

export type EnumKitchenMenuItemStatusFilter = {
  equals?: Maybe<KitchenMenuItemStatus>;
  in?: Maybe<Array<KitchenMenuItemStatus>>;
  not?: Maybe<NestedEnumKitchenMenuItemStatusFilter>;
  notIn?: Maybe<Array<KitchenMenuItemStatus>>;
};

export type EnumKitchenStatusFieldUpdateOperationsInput = {
  set?: Maybe<KitchenStatus>;
};

export type EnumKitchenStatusFilter = {
  equals?: Maybe<KitchenStatus>;
  in?: Maybe<Array<KitchenStatus>>;
  not?: Maybe<NestedEnumKitchenStatusFilter>;
  notIn?: Maybe<Array<KitchenStatus>>;
};

export type EnumKitchenTicketDelayReasonNullableFilter = {
  equals?: Maybe<KitchenTicketDelayReason>;
  in?: Maybe<Array<KitchenTicketDelayReason>>;
  not?: Maybe<NestedEnumKitchenTicketDelayReasonNullableFilter>;
  notIn?: Maybe<Array<KitchenTicketDelayReason>>;
};

export type EnumKitchenTicketStatusFieldUpdateOperationsInput = {
  set?: Maybe<KitchenTicketStatus>;
};

export type EnumKitchenTicketStatusFilter = {
  equals?: Maybe<KitchenTicketStatus>;
  in?: Maybe<Array<KitchenTicketStatus>>;
  not?: Maybe<NestedEnumKitchenTicketStatusFilter>;
  notIn?: Maybe<Array<KitchenTicketStatus>>;
};

export type EnumKitchenTypeFieldUpdateOperationsInput = {
  set?: Maybe<KitchenType>;
};

export type EnumKitchenTypeFilter = {
  equals?: Maybe<KitchenType>;
  in?: Maybe<Array<KitchenType>>;
  not?: Maybe<NestedEnumKitchenTypeFilter>;
  notIn?: Maybe<Array<KitchenType>>;
};

export type EnumMenuCategoryTypeFieldUpdateOperationsInput = {
  set?: Maybe<MenuCategoryType>;
};

export type EnumMenuCategoryTypeFilter = {
  equals?: Maybe<MenuCategoryType>;
  in?: Maybe<Array<MenuCategoryType>>;
  not?: Maybe<NestedEnumMenuCategoryTypeFilter>;
  notIn?: Maybe<Array<MenuCategoryType>>;
};

export type EnumMenuItemAvailabilityNullableListFilter = {
  equals?: Maybe<Array<MenuItemAvailability>>;
  has?: Maybe<MenuItemAvailability>;
  hasEvery?: Maybe<Array<MenuItemAvailability>>;
  hasSome?: Maybe<Array<MenuItemAvailability>>;
  isEmpty?: Maybe<Scalars['Boolean']>;
};

export type EnumMenuItemLabelNullableListFilter = {
  equals?: Maybe<Array<MenuItemLabel>>;
  has?: Maybe<MenuItemLabel>;
  hasEvery?: Maybe<Array<MenuItemLabel>>;
  hasSome?: Maybe<Array<MenuItemLabel>>;
  isEmpty?: Maybe<Scalars['Boolean']>;
};

export type EnumMenuItemTypeFieldUpdateOperationsInput = {
  set?: Maybe<MenuItemType>;
};

export type EnumMenuItemTypeFilter = {
  equals?: Maybe<MenuItemType>;
  in?: Maybe<Array<MenuItemType>>;
  not?: Maybe<NestedEnumMenuItemTypeFilter>;
  notIn?: Maybe<Array<MenuItemType>>;
};

export type EnumMenuScheduleTypeFieldUpdateOperationsInput = {
  set?: Maybe<MenuScheduleType>;
};

export type EnumMenuScheduleTypeFilter = {
  equals?: Maybe<MenuScheduleType>;
  in?: Maybe<Array<MenuScheduleType>>;
  not?: Maybe<NestedEnumMenuScheduleTypeFilter>;
  notIn?: Maybe<Array<MenuScheduleType>>;
};

export type EnumOrderBundleStatusFieldUpdateOperationsInput = {
  set?: Maybe<OrderBundleStatus>;
};

export type EnumOrderBundleStatusFilter = {
  equals?: Maybe<OrderBundleStatus>;
  in?: Maybe<Array<OrderBundleStatus>>;
  not?: Maybe<NestedEnumOrderBundleStatusFilter>;
  notIn?: Maybe<Array<OrderBundleStatus>>;
};

export type EnumOrderEventTypeFieldUpdateOperationsInput = {
  set?: Maybe<OrderEventType>;
};

export type EnumOrderEventTypeFilter = {
  equals?: Maybe<OrderEventType>;
  in?: Maybe<Array<OrderEventType>>;
  not?: Maybe<NestedEnumOrderEventTypeFilter>;
  notIn?: Maybe<Array<OrderEventType>>;
};

export type EnumOrderItemTypeFieldUpdateOperationsInput = {
  set?: Maybe<OrderItemType>;
};

export type EnumOrderItemTypeFilter = {
  equals?: Maybe<OrderItemType>;
  in?: Maybe<Array<OrderItemType>>;
  not?: Maybe<NestedEnumOrderItemTypeFilter>;
  notIn?: Maybe<Array<OrderItemType>>;
};

export type EnumOrderMethodFieldUpdateOperationsInput = {
  set?: Maybe<OrderMethod>;
};

export type EnumOrderMethodFilter = {
  equals?: Maybe<OrderMethod>;
  in?: Maybe<Array<OrderMethod>>;
  not?: Maybe<NestedEnumOrderMethodFilter>;
  notIn?: Maybe<Array<OrderMethod>>;
};

export type EnumOrderMethodNullableListFilter = {
  equals?: Maybe<Array<OrderMethod>>;
  has?: Maybe<OrderMethod>;
  hasEvery?: Maybe<Array<OrderMethod>>;
  hasSome?: Maybe<Array<OrderMethod>>;
  isEmpty?: Maybe<Scalars['Boolean']>;
};

export type EnumOrderStatusFieldUpdateOperationsInput = {
  set?: Maybe<OrderStatus>;
};

export type EnumOrderStatusFilter = {
  equals?: Maybe<OrderStatus>;
  in?: Maybe<Array<OrderStatus>>;
  not?: Maybe<NestedEnumOrderStatusFilter>;
  notIn?: Maybe<Array<OrderStatus>>;
};

export type EnumPaymentProcessorFieldUpdateOperationsInput = {
  set?: Maybe<PaymentProcessor>;
};

export type EnumPaymentProcessorFilter = {
  equals?: Maybe<PaymentProcessor>;
  in?: Maybe<Array<PaymentProcessor>>;
  not?: Maybe<NestedEnumPaymentProcessorFilter>;
  notIn?: Maybe<Array<PaymentProcessor>>;
};

export type EnumPaymentStatusFieldUpdateOperationsInput = {
  set?: Maybe<PaymentStatus>;
};

export type EnumPaymentStatusFilter = {
  equals?: Maybe<PaymentStatus>;
  in?: Maybe<Array<PaymentStatus>>;
  not?: Maybe<NestedEnumPaymentStatusFilter>;
  notIn?: Maybe<Array<PaymentStatus>>;
};

export type EnumPaymentTypeFieldUpdateOperationsInput = {
  set?: Maybe<PaymentType>;
};

export type EnumPaymentTypeFilter = {
  equals?: Maybe<PaymentType>;
  in?: Maybe<Array<PaymentType>>;
  not?: Maybe<NestedEnumPaymentTypeFilter>;
  notIn?: Maybe<Array<PaymentType>>;
};

export type EnumPlatformFieldUpdateOperationsInput = {
  set?: Maybe<Platform>;
};

export type EnumPlatformFilter = {
  equals?: Maybe<Platform>;
  in?: Maybe<Array<Platform>>;
  not?: Maybe<NestedEnumPlatformFilter>;
  notIn?: Maybe<Array<Platform>>;
};

export type EnumPromoAppliesToFieldUpdateOperationsInput = {
  set?: Maybe<PromoAppliesTo>;
};

export type EnumPromoAppliesToFilter = {
  equals?: Maybe<PromoAppliesTo>;
  in?: Maybe<Array<PromoAppliesTo>>;
  not?: Maybe<NestedEnumPromoAppliesToFilter>;
  notIn?: Maybe<Array<PromoAppliesTo>>;
};

export type EnumPromoEligibilityFieldUpdateOperationsInput = {
  set?: Maybe<PromoEligibility>;
};

export type EnumPromoEligibilityFilter = {
  equals?: Maybe<PromoEligibility>;
  in?: Maybe<Array<PromoEligibility>>;
  not?: Maybe<NestedEnumPromoEligibilityFilter>;
  notIn?: Maybe<Array<PromoEligibility>>;
};

export type EnumPromoRequirementFieldUpdateOperationsInput = {
  set?: Maybe<PromoRequirement>;
};

export type EnumPromoRequirementFilter = {
  equals?: Maybe<PromoRequirement>;
  in?: Maybe<Array<PromoRequirement>>;
  not?: Maybe<NestedEnumPromoRequirementFilter>;
  notIn?: Maybe<Array<PromoRequirement>>;
};

export type EnumPromoStatusFieldUpdateOperationsInput = {
  set?: Maybe<PromoStatus>;
};

export type EnumPromoStatusFilter = {
  equals?: Maybe<PromoStatus>;
  in?: Maybe<Array<PromoStatus>>;
  not?: Maybe<NestedEnumPromoStatusFilter>;
  notIn?: Maybe<Array<PromoStatus>>;
};

export type EnumPromoTypeFieldUpdateOperationsInput = {
  set?: Maybe<PromoType>;
};

export type EnumPromoTypeFilter = {
  equals?: Maybe<PromoType>;
  in?: Maybe<Array<PromoType>>;
  not?: Maybe<NestedEnumPromoTypeFilter>;
  notIn?: Maybe<Array<PromoType>>;
};

export type EnumPromoUsageLimitsFieldUpdateOperationsInput = {
  set?: Maybe<PromoUsageLimits>;
};

export type EnumPromoUsageLimitsFilter = {
  equals?: Maybe<PromoUsageLimits>;
  in?: Maybe<Array<PromoUsageLimits>>;
  not?: Maybe<NestedEnumPromoUsageLimitsFilter>;
  notIn?: Maybe<Array<PromoUsageLimits>>;
};

export type EnumRefireReasonNullableFilter = {
  equals?: Maybe<RefireReason>;
  in?: Maybe<Array<RefireReason>>;
  not?: Maybe<NestedEnumRefireReasonNullableFilter>;
  notIn?: Maybe<Array<RefireReason>>;
};

export type EnumRefundReasonFieldUpdateOperationsInput = {
  set?: Maybe<RefundReason>;
};

export type EnumRefundReasonFilter = {
  equals?: Maybe<RefundReason>;
  in?: Maybe<Array<RefundReason>>;
  not?: Maybe<NestedEnumRefundReasonFilter>;
  notIn?: Maybe<Array<RefundReason>>;
};

export type EnumRefundTypeFieldUpdateOperationsInput = {
  set?: Maybe<RefundType>;
};

export type EnumRefundTypeFilter = {
  equals?: Maybe<RefundType>;
  in?: Maybe<Array<RefundType>>;
  not?: Maybe<NestedEnumRefundTypeFilter>;
  notIn?: Maybe<Array<RefundType>>;
};

export type EnumTilledCancellationReasonNullableFilter = {
  equals?: Maybe<TilledCancellationReason>;
  in?: Maybe<Array<TilledCancellationReason>>;
  not?: Maybe<NestedEnumTilledCancellationReasonNullableFilter>;
  notIn?: Maybe<Array<TilledCancellationReason>>;
};

export type EnumTilledCurrencyFieldUpdateOperationsInput = {
  set?: Maybe<TilledCurrency>;
};

export type EnumTilledCurrencyFilter = {
  equals?: Maybe<TilledCurrency>;
  in?: Maybe<Array<TilledCurrency>>;
  not?: Maybe<NestedEnumTilledCurrencyFilter>;
  notIn?: Maybe<Array<TilledCurrency>>;
};

export type EnumTilledPaymentErrorTypeNullableFilter = {
  equals?: Maybe<TilledPaymentErrorType>;
  in?: Maybe<Array<TilledPaymentErrorType>>;
  not?: Maybe<NestedEnumTilledPaymentErrorTypeNullableFilter>;
  notIn?: Maybe<Array<TilledPaymentErrorType>>;
};

export type EnumTilledPaymentMethodTypeFieldUpdateOperationsInput = {
  set?: Maybe<TilledPaymentMethodType>;
};

export type EnumTilledPaymentMethodTypeFilter = {
  equals?: Maybe<TilledPaymentMethodType>;
  in?: Maybe<Array<TilledPaymentMethodType>>;
  not?: Maybe<NestedEnumTilledPaymentMethodTypeFilter>;
  notIn?: Maybe<Array<TilledPaymentMethodType>>;
};

export type EnumTilledPaymentStatusFieldUpdateOperationsInput = {
  set?: Maybe<TilledPaymentStatus>;
};

export type EnumTilledPaymentStatusFilter = {
  equals?: Maybe<TilledPaymentStatus>;
  in?: Maybe<Array<TilledPaymentStatus>>;
  not?: Maybe<NestedEnumTilledPaymentStatusFilter>;
  notIn?: Maybe<Array<TilledPaymentStatus>>;
};

export type EnumUserRoleNullableListFilter = {
  equals?: Maybe<Array<UserRole>>;
  has?: Maybe<UserRole>;
  hasEvery?: Maybe<Array<UserRole>>;
  hasSome?: Maybe<Array<UserRole>>;
  isEmpty?: Maybe<Scalars['Boolean']>;
};

export type EnumWorkplaceTypeFieldUpdateOperationsInput = {
  set?: Maybe<WorkplaceType>;
};

export type EnumWorkplaceTypeFilter = {
  equals?: Maybe<WorkplaceType>;
  in?: Maybe<Array<WorkplaceType>>;
  not?: Maybe<NestedEnumWorkplaceTypeFilter>;
  notIn?: Maybe<Array<WorkplaceType>>;
};

export type Extra = {
  __typename?: 'Extra';
  createdAt: Scalars['DateTime'];
  defaultItemId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  items: Array<ExtraItem>;
  kitchenId?: Maybe<Scalars['String']>;
  kitchenMenuItems: Array<KitchenMenuItem>;
  maximum: Scalars['Int'];
  minimum: Scalars['Int'];
  name: Scalars['String'];
  required: Scalars['Boolean'];
  singleOption: Scalars['Boolean'];
  sortId: Scalars['Int'];
};


export type ExtraItemsArgs = {
  after?: Maybe<ExtraItemWhereUniqueInput>;
  before?: Maybe<ExtraItemWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ExtraKitchenMenuItemsArgs = {
  after?: Maybe<KitchenMenuItemWhereUniqueInput>;
  before?: Maybe<KitchenMenuItemWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type ExtraCreateManyKitchenInput = {
  chowlyId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultItemId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  maximum?: Maybe<Scalars['Int']>;
  minimum?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  sortId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ExtraCreateManyKitchenInputEnvelope = {
  data?: Maybe<Array<ExtraCreateManyKitchenInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ExtraCreateNestedManyWithoutKitchenInput = {
  connect?: Maybe<Array<ExtraWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ExtraCreateOrConnectWithoutKitchenInput>>;
  create?: Maybe<Array<ExtraCreateWithoutKitchenInput>>;
  createMany?: Maybe<ExtraCreateManyKitchenInputEnvelope>;
};

export type ExtraCreateNestedManyWithoutKitchenMenuItemsInput = {
  connect?: Maybe<Array<ExtraWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ExtraCreateOrConnectWithoutKitchenMenuItemsInput>>;
  create?: Maybe<Array<ExtraCreateWithoutKitchenMenuItemsInput>>;
};

export type ExtraCreateNestedOneWithoutItemsInput = {
  connect?: Maybe<ExtraWhereUniqueInput>;
  connectOrCreate?: Maybe<ExtraCreateOrConnectWithoutItemsInput>;
  create?: Maybe<ExtraCreateWithoutItemsInput>;
};

export type ExtraCreateOrConnectWithoutItemsInput = {
  create: ExtraCreateWithoutItemsInput;
  where: ExtraWhereUniqueInput;
};

export type ExtraCreateOrConnectWithoutKitchenInput = {
  create: ExtraCreateWithoutKitchenInput;
  where: ExtraWhereUniqueInput;
};

export type ExtraCreateOrConnectWithoutKitchenMenuItemsInput = {
  create: ExtraCreateWithoutKitchenMenuItemsInput;
  where: ExtraWhereUniqueInput;
};

export type ExtraCreateWithoutItemsInput = {
  chowlyId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultItemId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  kitchen?: Maybe<KitchenCreateNestedOneWithoutExtraInput>;
  kitchenMenuItems?: Maybe<KitchenMenuItemCreateNestedManyWithoutExtrasInput>;
  maximum?: Maybe<Scalars['Int']>;
  minimum?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  sortId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ExtraCreateWithoutKitchenInput = {
  chowlyId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultItemId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  items?: Maybe<ExtraItemCreateNestedManyWithoutExtraInput>;
  kitchenMenuItems?: Maybe<KitchenMenuItemCreateNestedManyWithoutExtrasInput>;
  maximum?: Maybe<Scalars['Int']>;
  minimum?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  sortId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ExtraCreateWithoutKitchenMenuItemsInput = {
  chowlyId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultItemId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  items?: Maybe<ExtraItemCreateNestedManyWithoutExtraInput>;
  kitchen?: Maybe<KitchenCreateNestedOneWithoutExtraInput>;
  maximum?: Maybe<Scalars['Int']>;
  minimum?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  sortId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ExtraItem = {
  __typename?: 'ExtraItem';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  price: Scalars['Float'];
  sortId: Scalars['Int'];
};

export type ExtraItemCreateManyExtraInput = {
  chowlyId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  price?: Maybe<Scalars['Float']>;
  sortId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ExtraItemCreateManyExtraInputEnvelope = {
  data?: Maybe<Array<ExtraItemCreateManyExtraInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ExtraItemCreateNestedManyWithoutExtraInput = {
  connect?: Maybe<Array<ExtraItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ExtraItemCreateOrConnectWithoutExtraInput>>;
  create?: Maybe<Array<ExtraItemCreateWithoutExtraInput>>;
  createMany?: Maybe<ExtraItemCreateManyExtraInputEnvelope>;
};

export type ExtraItemCreateNestedOneWithoutCartExtraItemsInput = {
  connect?: Maybe<ExtraItemWhereUniqueInput>;
  connectOrCreate?: Maybe<ExtraItemCreateOrConnectWithoutCartExtraItemsInput>;
  create?: Maybe<ExtraItemCreateWithoutCartExtraItemsInput>;
};

export type ExtraItemCreateNestedOneWithoutOrderItemExtrasInput = {
  connect?: Maybe<ExtraItemWhereUniqueInput>;
  connectOrCreate?: Maybe<ExtraItemCreateOrConnectWithoutOrderItemExtrasInput>;
  create?: Maybe<ExtraItemCreateWithoutOrderItemExtrasInput>;
};

export type ExtraItemCreateOrConnectWithoutCartExtraItemsInput = {
  create: ExtraItemCreateWithoutCartExtraItemsInput;
  where: ExtraItemWhereUniqueInput;
};

export type ExtraItemCreateOrConnectWithoutExtraInput = {
  create: ExtraItemCreateWithoutExtraInput;
  where: ExtraItemWhereUniqueInput;
};

export type ExtraItemCreateOrConnectWithoutOrderItemExtrasInput = {
  create: ExtraItemCreateWithoutOrderItemExtrasInput;
  where: ExtraItemWhereUniqueInput;
};

export type ExtraItemCreateWithoutCartExtraItemsInput = {
  chowlyId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  extra: ExtraCreateNestedOneWithoutItemsInput;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  orderItemExtras?: Maybe<OrderItemExtraCreateNestedManyWithoutExtraItemInput>;
  price?: Maybe<Scalars['Float']>;
  sortId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ExtraItemCreateWithoutExtraInput = {
  cartExtraItems?: Maybe<CartExtraItemCreateNestedManyWithoutExtraItemInput>;
  chowlyId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  orderItemExtras?: Maybe<OrderItemExtraCreateNestedManyWithoutExtraItemInput>;
  price?: Maybe<Scalars['Float']>;
  sortId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ExtraItemCreateWithoutOrderItemExtrasInput = {
  cartExtraItems?: Maybe<CartExtraItemCreateNestedManyWithoutExtraItemInput>;
  chowlyId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  extra: ExtraCreateNestedOneWithoutItemsInput;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  price?: Maybe<Scalars['Float']>;
  sortId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ExtraItemInput = {
  extraItemId: Scalars['ID'];
  quantity?: Maybe<Scalars['Int']>;
};

export type ExtraItemListRelationFilter = {
  every?: Maybe<ExtraItemWhereInput>;
  none?: Maybe<ExtraItemWhereInput>;
  some?: Maybe<ExtraItemWhereInput>;
};

export type ExtraItemScalarWhereInput = {
  AND?: Maybe<Array<ExtraItemScalarWhereInput>>;
  NOT?: Maybe<Array<ExtraItemScalarWhereInput>>;
  OR?: Maybe<Array<ExtraItemScalarWhereInput>>;
  chowlyId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  extraId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  isActive?: Maybe<BoolFilter>;
  name?: Maybe<StringFilter>;
  price?: Maybe<FloatFilter>;
  sortId?: Maybe<IntFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type ExtraItemUpdateManyMutationInput = {
  chowlyId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isActive?: Maybe<BoolFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  sortId?: Maybe<IntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type ExtraItemUpdateManyWithWhereWithoutExtraInput = {
  data: ExtraItemUpdateManyMutationInput;
  where: ExtraItemScalarWhereInput;
};

export type ExtraItemUpdateManyWithoutExtraInput = {
  connect?: Maybe<Array<ExtraItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ExtraItemCreateOrConnectWithoutExtraInput>>;
  create?: Maybe<Array<ExtraItemCreateWithoutExtraInput>>;
  createMany?: Maybe<ExtraItemCreateManyExtraInputEnvelope>;
  delete?: Maybe<Array<ExtraItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ExtraItemScalarWhereInput>>;
  disconnect?: Maybe<Array<ExtraItemWhereUniqueInput>>;
  set?: Maybe<Array<ExtraItemWhereUniqueInput>>;
  update?: Maybe<Array<ExtraItemUpdateWithWhereUniqueWithoutExtraInput>>;
  updateMany?: Maybe<Array<ExtraItemUpdateManyWithWhereWithoutExtraInput>>;
  upsert?: Maybe<Array<ExtraItemUpsertWithWhereUniqueWithoutExtraInput>>;
};

export type ExtraItemUpdateOneRequiredWithoutCartExtraItemsInput = {
  connect?: Maybe<ExtraItemWhereUniqueInput>;
  connectOrCreate?: Maybe<ExtraItemCreateOrConnectWithoutCartExtraItemsInput>;
  create?: Maybe<ExtraItemCreateWithoutCartExtraItemsInput>;
  update?: Maybe<ExtraItemUpdateWithoutCartExtraItemsInput>;
  upsert?: Maybe<ExtraItemUpsertWithoutCartExtraItemsInput>;
};

export type ExtraItemUpdateOneWithoutOrderItemExtrasInput = {
  connect?: Maybe<ExtraItemWhereUniqueInput>;
  connectOrCreate?: Maybe<ExtraItemCreateOrConnectWithoutOrderItemExtrasInput>;
  create?: Maybe<ExtraItemCreateWithoutOrderItemExtrasInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ExtraItemUpdateWithoutOrderItemExtrasInput>;
  upsert?: Maybe<ExtraItemUpsertWithoutOrderItemExtrasInput>;
};

export type ExtraItemUpdateWithWhereUniqueWithoutExtraInput = {
  data: ExtraItemUpdateWithoutExtraInput;
  where: ExtraItemWhereUniqueInput;
};

export type ExtraItemUpdateWithoutCartExtraItemsInput = {
  chowlyId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  extra?: Maybe<ExtraUpdateOneRequiredWithoutItemsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isActive?: Maybe<BoolFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  orderItemExtras?: Maybe<OrderItemExtraUpdateManyWithoutExtraItemInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  sortId?: Maybe<IntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type ExtraItemUpdateWithoutExtraInput = {
  cartExtraItems?: Maybe<CartExtraItemUpdateManyWithoutExtraItemInput>;
  chowlyId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isActive?: Maybe<BoolFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  orderItemExtras?: Maybe<OrderItemExtraUpdateManyWithoutExtraItemInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  sortId?: Maybe<IntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type ExtraItemUpdateWithoutOrderItemExtrasInput = {
  cartExtraItems?: Maybe<CartExtraItemUpdateManyWithoutExtraItemInput>;
  chowlyId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  extra?: Maybe<ExtraUpdateOneRequiredWithoutItemsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isActive?: Maybe<BoolFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  sortId?: Maybe<IntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type ExtraItemUpsertInput = {
  id?: Maybe<Scalars['ID']>;
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  price: Scalars['Float'];
  sortId: Scalars['Int'];
};

export type ExtraItemUpsertWithWhereUniqueWithoutExtraInput = {
  create: ExtraItemCreateWithoutExtraInput;
  update: ExtraItemUpdateWithoutExtraInput;
  where: ExtraItemWhereUniqueInput;
};

export type ExtraItemUpsertWithoutCartExtraItemsInput = {
  create: ExtraItemCreateWithoutCartExtraItemsInput;
  update: ExtraItemUpdateWithoutCartExtraItemsInput;
};

export type ExtraItemUpsertWithoutOrderItemExtrasInput = {
  create: ExtraItemCreateWithoutOrderItemExtrasInput;
  update: ExtraItemUpdateWithoutOrderItemExtrasInput;
};

export type ExtraItemWhereInput = {
  AND?: Maybe<Array<ExtraItemWhereInput>>;
  NOT?: Maybe<Array<ExtraItemWhereInput>>;
  OR?: Maybe<Array<ExtraItemWhereInput>>;
  cartExtraItems?: Maybe<CartExtraItemListRelationFilter>;
  chowlyId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  extra?: Maybe<ExtraWhereInput>;
  extraId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  isActive?: Maybe<BoolFilter>;
  name?: Maybe<StringFilter>;
  orderItemExtras?: Maybe<OrderItemExtraListRelationFilter>;
  price?: Maybe<FloatFilter>;
  sortId?: Maybe<IntFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type ExtraItemWhereUniqueInput = {
  chowlyId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type ExtraListRelationFilter = {
  every?: Maybe<ExtraWhereInput>;
  none?: Maybe<ExtraWhereInput>;
  some?: Maybe<ExtraWhereInput>;
};

export type ExtraScalarWhereInput = {
  AND?: Maybe<Array<ExtraScalarWhereInput>>;
  NOT?: Maybe<Array<ExtraScalarWhereInput>>;
  OR?: Maybe<Array<ExtraScalarWhereInput>>;
  chowlyId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  defaultItemId?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  kitchenId?: Maybe<StringNullableFilter>;
  maximum?: Maybe<IntFilter>;
  minimum?: Maybe<IntFilter>;
  name?: Maybe<StringFilter>;
  sortId?: Maybe<IntFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type ExtraUpdateManyMutationInput = {
  chowlyId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  defaultItemId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  maximum?: Maybe<IntFieldUpdateOperationsInput>;
  minimum?: Maybe<IntFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  sortId?: Maybe<IntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type ExtraUpdateManyWithWhereWithoutKitchenInput = {
  data: ExtraUpdateManyMutationInput;
  where: ExtraScalarWhereInput;
};

export type ExtraUpdateManyWithWhereWithoutKitchenMenuItemsInput = {
  data: ExtraUpdateManyMutationInput;
  where: ExtraScalarWhereInput;
};

export type ExtraUpdateManyWithoutKitchenInput = {
  connect?: Maybe<Array<ExtraWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ExtraCreateOrConnectWithoutKitchenInput>>;
  create?: Maybe<Array<ExtraCreateWithoutKitchenInput>>;
  createMany?: Maybe<ExtraCreateManyKitchenInputEnvelope>;
  delete?: Maybe<Array<ExtraWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ExtraScalarWhereInput>>;
  disconnect?: Maybe<Array<ExtraWhereUniqueInput>>;
  set?: Maybe<Array<ExtraWhereUniqueInput>>;
  update?: Maybe<Array<ExtraUpdateWithWhereUniqueWithoutKitchenInput>>;
  updateMany?: Maybe<Array<ExtraUpdateManyWithWhereWithoutKitchenInput>>;
  upsert?: Maybe<Array<ExtraUpsertWithWhereUniqueWithoutKitchenInput>>;
};

export type ExtraUpdateManyWithoutKitchenMenuItemsInput = {
  connect?: Maybe<Array<ExtraWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ExtraCreateOrConnectWithoutKitchenMenuItemsInput>>;
  create?: Maybe<Array<ExtraCreateWithoutKitchenMenuItemsInput>>;
  delete?: Maybe<Array<ExtraWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ExtraScalarWhereInput>>;
  disconnect?: Maybe<Array<ExtraWhereUniqueInput>>;
  set?: Maybe<Array<ExtraWhereUniqueInput>>;
  update?: Maybe<Array<ExtraUpdateWithWhereUniqueWithoutKitchenMenuItemsInput>>;
  updateMany?: Maybe<Array<ExtraUpdateManyWithWhereWithoutKitchenMenuItemsInput>>;
  upsert?: Maybe<Array<ExtraUpsertWithWhereUniqueWithoutKitchenMenuItemsInput>>;
};

export type ExtraUpdateOneRequiredWithoutItemsInput = {
  connect?: Maybe<ExtraWhereUniqueInput>;
  connectOrCreate?: Maybe<ExtraCreateOrConnectWithoutItemsInput>;
  create?: Maybe<ExtraCreateWithoutItemsInput>;
  update?: Maybe<ExtraUpdateWithoutItemsInput>;
  upsert?: Maybe<ExtraUpsertWithoutItemsInput>;
};

export type ExtraUpdateWithWhereUniqueWithoutKitchenInput = {
  data: ExtraUpdateWithoutKitchenInput;
  where: ExtraWhereUniqueInput;
};

export type ExtraUpdateWithWhereUniqueWithoutKitchenMenuItemsInput = {
  data: ExtraUpdateWithoutKitchenMenuItemsInput;
  where: ExtraWhereUniqueInput;
};

export type ExtraUpdateWithoutItemsInput = {
  chowlyId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  defaultItemId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  kitchen?: Maybe<KitchenUpdateOneWithoutExtraInput>;
  kitchenMenuItems?: Maybe<KitchenMenuItemUpdateManyWithoutExtrasInput>;
  maximum?: Maybe<IntFieldUpdateOperationsInput>;
  minimum?: Maybe<IntFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  sortId?: Maybe<IntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type ExtraUpdateWithoutKitchenInput = {
  chowlyId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  defaultItemId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  items?: Maybe<ExtraItemUpdateManyWithoutExtraInput>;
  kitchenMenuItems?: Maybe<KitchenMenuItemUpdateManyWithoutExtrasInput>;
  maximum?: Maybe<IntFieldUpdateOperationsInput>;
  minimum?: Maybe<IntFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  sortId?: Maybe<IntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type ExtraUpdateWithoutKitchenMenuItemsInput = {
  chowlyId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  defaultItemId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  items?: Maybe<ExtraItemUpdateManyWithoutExtraInput>;
  kitchen?: Maybe<KitchenUpdateOneWithoutExtraInput>;
  maximum?: Maybe<IntFieldUpdateOperationsInput>;
  minimum?: Maybe<IntFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  sortId?: Maybe<IntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type ExtraUpsertInput = {
  defaultItemId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  items: Array<ExtraItemUpsertInput>;
  kitchenId: Scalars['String'];
  maximum: Scalars['Int'];
  minimum: Scalars['Int'];
  name: Scalars['String'];
  sortId: Scalars['Int'];
};

export type ExtraUpsertWithWhereUniqueWithoutKitchenInput = {
  create: ExtraCreateWithoutKitchenInput;
  update: ExtraUpdateWithoutKitchenInput;
  where: ExtraWhereUniqueInput;
};

export type ExtraUpsertWithWhereUniqueWithoutKitchenMenuItemsInput = {
  create: ExtraCreateWithoutKitchenMenuItemsInput;
  update: ExtraUpdateWithoutKitchenMenuItemsInput;
  where: ExtraWhereUniqueInput;
};

export type ExtraUpsertWithoutItemsInput = {
  create: ExtraCreateWithoutItemsInput;
  update: ExtraUpdateWithoutItemsInput;
};

export type ExtraWhereInput = {
  AND?: Maybe<Array<ExtraWhereInput>>;
  NOT?: Maybe<Array<ExtraWhereInput>>;
  OR?: Maybe<Array<ExtraWhereInput>>;
  chowlyId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  defaultItemId?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  items?: Maybe<ExtraItemListRelationFilter>;
  kitchen?: Maybe<KitchenWhereInput>;
  kitchenId?: Maybe<StringNullableFilter>;
  kitchenMenuItems?: Maybe<KitchenMenuItemListRelationFilter>;
  maximum?: Maybe<IntFilter>;
  minimum?: Maybe<IntFilter>;
  name?: Maybe<StringFilter>;
  sortId?: Maybe<IntFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type ExtraWhereUniqueInput = {
  chowlyId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type Facility = {
  __typename?: 'Facility';
  cateringDeliveryFee: Scalars['Float'];
  cateringDeliveryFeeTaxRate: Scalars['Float'];
  cateringServiceFee: Scalars['Float'];
  cateringServiceFeeTaxRate: Scalars['Float'];
  cateringServiceFeeType: FeeTypeEnum;
  consumerDeliveryFee: Scalars['Float'];
  consumerDeliveryFeeTaxRate: Scalars['Float'];
  consumerServiceFee: Scalars['Float'];
  consumerServiceFeeTaxRate: Scalars['Float'];
  consumerServiceFeeType: FeeTypeEnum;
  createdAt: Scalars['DateTime'];
  deliveryLimitInSeconds: Scalars['Int'];
  /** Delivery time buffer in minutes */
  deliveryTimeBuffer: Scalars['Int'];
  email: Scalars['String'];
  enableCatering: Scalars['Boolean'];
  enableKiosk: Scalars['Boolean'];
  formattedAddress: Scalars['String'];
  heroImageUrl?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isCateringDeliveryFeeTaxable: Scalars['Boolean'];
  isCateringServiceFeeTaxable: Scalars['Boolean'];
  isCmsEnabled: Scalars['Boolean'];
  isConsumerDeliveryFeeTaxable: Scalars['Boolean'];
  isConsumerServiceFeeTaxable: Scalars['Boolean'];
  isFOHPrinterAvailable: Scalars['Boolean'];
  isGiftCardEnabled: Scalars['Boolean'];
  /** Does facility support promo codes */
  isPromoEnabled: Scalars['Boolean'];
  kdsType: KdsType;
  kioskLogoUrl?: Maybe<Scalars['String']>;
  /** Number of active facility kitchens  */
  kitchenCount: Scalars['Int'];
  /** List of kitchens logos for consumer app, only logos of ACTIVE kitchens are returned */
  kitchenLogos: Array<Scalars['String']>;
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  logoUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  operationHours: Array<Scalars['Json']>;
  orderMethods: Array<OrderMethod>;
  phone: Scalars['String'];
  /** Pickup time buffer in minutes */
  pickupTimeBuffer: Scalars['Int'];
  placeId: Scalars['String'];
  readonly: Scalars['Boolean'];
  slug?: Maybe<Scalars['String']>;
  stations: Array<Station>;
  taxRate: Scalars['Float'];
  timeZone: Scalars['String'];
  workplaces: Array<Workplace>;
};


export type FacilityStationsArgs = {
  after?: Maybe<StationWhereUniqueInput>;
  before?: Maybe<StationWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type FacilityWorkplacesArgs = {
  after?: Maybe<WorkplaceWhereUniqueInput>;
  before?: Maybe<WorkplaceWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type FacilityCreateManyTenantInput = {
  cateringDeliveryFee?: Maybe<Scalars['Float']>;
  cateringDeliveryFeeTaxRate?: Maybe<Scalars['Float']>;
  cateringServiceFee?: Maybe<Scalars['Float']>;
  cateringServiceFeeTaxRate?: Maybe<Scalars['Float']>;
  cateringServiceFeeType?: Maybe<FeeType>;
  consumerDeliveryFee?: Maybe<Scalars['Float']>;
  consumerDeliveryFeeTaxRate?: Maybe<Scalars['Float']>;
  consumerServiceFee?: Maybe<Scalars['Float']>;
  consumerServiceFeeTaxRate?: Maybe<Scalars['Float']>;
  consumerServiceFeeType?: Maybe<FeeType>;
  cravePlatforms?: Maybe<FacilityCreateManycravePlatformsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deliveryEligibleAreas: Scalars['Json'];
  deliveryLimitInSeconds: Scalars['Int'];
  deliveryTimeBuffer?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  enableCatering?: Maybe<Scalars['Boolean']>;
  enableKiosk?: Maybe<Scalars['Boolean']>;
  formattedAddress: Scalars['String'];
  heroImageUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isCateringDeliveryFeeTaxable?: Maybe<Scalars['Boolean']>;
  isCateringServiceFeeTaxable?: Maybe<Scalars['Boolean']>;
  isCmsEnabled?: Maybe<Scalars['Boolean']>;
  isConsumerDeliveryFeeTaxable?: Maybe<Scalars['Boolean']>;
  isConsumerServiceFeeTaxable?: Maybe<Scalars['Boolean']>;
  isFOHPrinterAvailable?: Maybe<Scalars['Boolean']>;
  isGiftCardEnabled?: Maybe<Scalars['Boolean']>;
  kdsType?: Maybe<KdsType>;
  kioskLogoUrl?: Maybe<Scalars['String']>;
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  logoUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  nashOptionsId?: Maybe<Scalars['String']>;
  operationHours?: Maybe<FacilityCreateManyoperationHoursInput>;
  orderMethods?: Maybe<FacilityCreateManyorderMethodsInput>;
  payrocTerminalId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pickupTimeBuffer?: Maybe<Scalars['Int']>;
  placeId: Scalars['String'];
  readonly?: Maybe<Scalars['Boolean']>;
  slug?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  timeZone?: Maybe<Scalars['String']>;
  tookanTeamId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FacilityCreateManyTenantInputEnvelope = {
  data?: Maybe<Array<FacilityCreateManyTenantInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type FacilityCreateManycravePlatformsInput = {
  set?: Maybe<Array<Scalars['String']>>;
};

export type FacilityCreateManyoperationHoursInput = {
  set?: Maybe<Array<Scalars['Json']>>;
};

export type FacilityCreateManyorderMethodsInput = {
  set?: Maybe<Array<OrderMethod>>;
};

export type FacilityCreateNestedManyWithoutPowerBiReportsInput = {
  connect?: Maybe<Array<FacilityWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FacilityCreateOrConnectWithoutPowerBiReportsInput>>;
  create?: Maybe<Array<FacilityCreateWithoutPowerBiReportsInput>>;
};

export type FacilityCreateNestedManyWithoutTenantInput = {
  connect?: Maybe<Array<FacilityWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FacilityCreateOrConnectWithoutTenantInput>>;
  create?: Maybe<Array<FacilityCreateWithoutTenantInput>>;
  createMany?: Maybe<FacilityCreateManyTenantInputEnvelope>;
};

export type FacilityCreateNestedManyWithoutUsersInput = {
  connect?: Maybe<Array<FacilityWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FacilityCreateOrConnectWithoutUsersInput>>;
  create?: Maybe<Array<FacilityCreateWithoutUsersInput>>;
};

export type FacilityCreateNestedOneWithoutCartUpsellItemInput = {
  connect?: Maybe<FacilityWhereUniqueInput>;
  connectOrCreate?: Maybe<FacilityCreateOrConnectWithoutCartUpsellItemInput>;
  create?: Maybe<FacilityCreateWithoutCartUpsellItemInput>;
};

export type FacilityCreateNestedOneWithoutKitchensInput = {
  connect?: Maybe<FacilityWhereUniqueInput>;
  connectOrCreate?: Maybe<FacilityCreateOrConnectWithoutKitchensInput>;
  create?: Maybe<FacilityCreateWithoutKitchensInput>;
};

export type FacilityCreateNestedOneWithoutMenuCategoriesInput = {
  connect?: Maybe<FacilityWhereUniqueInput>;
  connectOrCreate?: Maybe<FacilityCreateOrConnectWithoutMenuCategoriesInput>;
  create?: Maybe<FacilityCreateWithoutMenuCategoriesInput>;
};

export type FacilityCreateNestedOneWithoutMenuSchedulesInput = {
  connect?: Maybe<FacilityWhereUniqueInput>;
  connectOrCreate?: Maybe<FacilityCreateOrConnectWithoutMenuSchedulesInput>;
  create?: Maybe<FacilityCreateWithoutMenuSchedulesInput>;
};

export type FacilityCreateNestedOneWithoutOrdersInput = {
  connect?: Maybe<FacilityWhereUniqueInput>;
  connectOrCreate?: Maybe<FacilityCreateOrConnectWithoutOrdersInput>;
  create?: Maybe<FacilityCreateWithoutOrdersInput>;
};

export type FacilityCreateNestedOneWithoutPaymentInput = {
  connect?: Maybe<FacilityWhereUniqueInput>;
  connectOrCreate?: Maybe<FacilityCreateOrConnectWithoutPaymentInput>;
  create?: Maybe<FacilityCreateWithoutPaymentInput>;
};

export type FacilityCreateNestedOneWithoutPromoFacilityRulesInput = {
  connect?: Maybe<FacilityWhereUniqueInput>;
  connectOrCreate?: Maybe<FacilityCreateOrConnectWithoutPromoFacilityRulesInput>;
  create?: Maybe<FacilityCreateWithoutPromoFacilityRulesInput>;
};

export type FacilityCreateNestedOneWithoutStationsInput = {
  connect?: Maybe<FacilityWhereUniqueInput>;
  connectOrCreate?: Maybe<FacilityCreateOrConnectWithoutStationsInput>;
  create?: Maybe<FacilityCreateWithoutStationsInput>;
};

export type FacilityCreateNestedOneWithoutWorkplacesInput = {
  connect?: Maybe<FacilityWhereUniqueInput>;
  connectOrCreate?: Maybe<FacilityCreateOrConnectWithoutWorkplacesInput>;
  create?: Maybe<FacilityCreateWithoutWorkplacesInput>;
};

export type FacilityCreateOrConnectWithoutCartUpsellItemInput = {
  create: FacilityCreateWithoutCartUpsellItemInput;
  where: FacilityWhereUniqueInput;
};

export type FacilityCreateOrConnectWithoutKitchensInput = {
  create: FacilityCreateWithoutKitchensInput;
  where: FacilityWhereUniqueInput;
};

export type FacilityCreateOrConnectWithoutMenuCategoriesInput = {
  create: FacilityCreateWithoutMenuCategoriesInput;
  where: FacilityWhereUniqueInput;
};

export type FacilityCreateOrConnectWithoutMenuSchedulesInput = {
  create: FacilityCreateWithoutMenuSchedulesInput;
  where: FacilityWhereUniqueInput;
};

export type FacilityCreateOrConnectWithoutOrdersInput = {
  create: FacilityCreateWithoutOrdersInput;
  where: FacilityWhereUniqueInput;
};

export type FacilityCreateOrConnectWithoutPaymentInput = {
  create: FacilityCreateWithoutPaymentInput;
  where: FacilityWhereUniqueInput;
};

export type FacilityCreateOrConnectWithoutPowerBiReportsInput = {
  create: FacilityCreateWithoutPowerBiReportsInput;
  where: FacilityWhereUniqueInput;
};

export type FacilityCreateOrConnectWithoutPromoBannersInput = {
  create: FacilityCreateWithoutPromoBannersInput;
  where: FacilityWhereUniqueInput;
};

export type FacilityCreateOrConnectWithoutPromoFacilityRulesInput = {
  create: FacilityCreateWithoutPromoFacilityRulesInput;
  where: FacilityWhereUniqueInput;
};

export type FacilityCreateOrConnectWithoutStationsInput = {
  create: FacilityCreateWithoutStationsInput;
  where: FacilityWhereUniqueInput;
};

export type FacilityCreateOrConnectWithoutTenantInput = {
  create: FacilityCreateWithoutTenantInput;
  where: FacilityWhereUniqueInput;
};

export type FacilityCreateOrConnectWithoutUsersInput = {
  create: FacilityCreateWithoutUsersInput;
  where: FacilityWhereUniqueInput;
};

export type FacilityCreateOrConnectWithoutWorkplacesInput = {
  create: FacilityCreateWithoutWorkplacesInput;
  where: FacilityWhereUniqueInput;
};

export type FacilityCreateWithoutCartUpsellItemInput = {
  Payment?: Maybe<PaymentCreateNestedManyWithoutFacilityInput>;
  Tenant?: Maybe<TenantCreateNestedOneWithoutFacilityInput>;
  cateringDeliveryFee?: Maybe<Scalars['Float']>;
  cateringDeliveryFeeTaxRate?: Maybe<Scalars['Float']>;
  cateringServiceFee?: Maybe<Scalars['Float']>;
  cateringServiceFeeTaxRate?: Maybe<Scalars['Float']>;
  cateringServiceFeeType?: Maybe<FeeType>;
  consumerDeliveryFee?: Maybe<Scalars['Float']>;
  consumerDeliveryFeeTaxRate?: Maybe<Scalars['Float']>;
  consumerServiceFee?: Maybe<Scalars['Float']>;
  consumerServiceFeeTaxRate?: Maybe<Scalars['Float']>;
  consumerServiceFeeType?: Maybe<FeeType>;
  cravePlatforms?: Maybe<FacilityCreatecravePlatformsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deliveryEligibleAreas: Scalars['Json'];
  deliveryLimitInSeconds: Scalars['Int'];
  deliveryTimeBuffer?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  enableCatering?: Maybe<Scalars['Boolean']>;
  enableKiosk?: Maybe<Scalars['Boolean']>;
  formattedAddress: Scalars['String'];
  heroImageUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isCateringDeliveryFeeTaxable?: Maybe<Scalars['Boolean']>;
  isCateringServiceFeeTaxable?: Maybe<Scalars['Boolean']>;
  isCmsEnabled?: Maybe<Scalars['Boolean']>;
  isConsumerDeliveryFeeTaxable?: Maybe<Scalars['Boolean']>;
  isConsumerServiceFeeTaxable?: Maybe<Scalars['Boolean']>;
  isFOHPrinterAvailable?: Maybe<Scalars['Boolean']>;
  isGiftCardEnabled?: Maybe<Scalars['Boolean']>;
  kdsType?: Maybe<KdsType>;
  kioskLogoUrl?: Maybe<Scalars['String']>;
  kitchens?: Maybe<KitchenCreateNestedManyWithoutFacilityInput>;
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  logoUrl?: Maybe<Scalars['String']>;
  menuCategories?: Maybe<MenuCategoryCreateNestedManyWithoutFacilityInput>;
  menuSchedules?: Maybe<MenuScheduleCreateNestedManyWithoutFacilityInput>;
  methodOverrides?: Maybe<OrderMethodOverrideCreateNestedManyWithoutFacilityInput>;
  name: Scalars['String'];
  nashOptionsId?: Maybe<Scalars['String']>;
  operationHours?: Maybe<FacilityCreateoperationHoursInput>;
  orderMethods?: Maybe<FacilityCreateorderMethodsInput>;
  orders?: Maybe<OrderCreateNestedManyWithoutFacilityInput>;
  payrocTerminal?: Maybe<PayrocTerminalCreateNestedOneWithoutFacilityInput>;
  phone?: Maybe<Scalars['String']>;
  pickupTimeBuffer?: Maybe<Scalars['Int']>;
  placeId: Scalars['String'];
  powerBiReports?: Maybe<PowerBiReportCreateNestedManyWithoutAvailableToFacilitiesInput>;
  promoBanners?: Maybe<PromoBannerCreateNestedManyWithoutFacilityInput>;
  promoFacilityRules?: Maybe<PromoFacilityRuleCreateNestedManyWithoutFacilityInput>;
  readonly?: Maybe<Scalars['Boolean']>;
  slug?: Maybe<Scalars['String']>;
  stations?: Maybe<StationCreateNestedManyWithoutFacilityInput>;
  taxRate?: Maybe<Scalars['Float']>;
  timeZone?: Maybe<Scalars['String']>;
  tookanTeamId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserCreateNestedManyWithoutFacilitiesInput>;
  workplaces?: Maybe<WorkplaceCreateNestedManyWithoutFacilityInput>;
};

export type FacilityCreateWithoutKitchensInput = {
  CartUpsellItem?: Maybe<CartUpsellItemCreateNestedManyWithoutFacilityInput>;
  Payment?: Maybe<PaymentCreateNestedManyWithoutFacilityInput>;
  Tenant?: Maybe<TenantCreateNestedOneWithoutFacilityInput>;
  cateringDeliveryFee?: Maybe<Scalars['Float']>;
  cateringDeliveryFeeTaxRate?: Maybe<Scalars['Float']>;
  cateringServiceFee?: Maybe<Scalars['Float']>;
  cateringServiceFeeTaxRate?: Maybe<Scalars['Float']>;
  cateringServiceFeeType?: Maybe<FeeType>;
  consumerDeliveryFee?: Maybe<Scalars['Float']>;
  consumerDeliveryFeeTaxRate?: Maybe<Scalars['Float']>;
  consumerServiceFee?: Maybe<Scalars['Float']>;
  consumerServiceFeeTaxRate?: Maybe<Scalars['Float']>;
  consumerServiceFeeType?: Maybe<FeeType>;
  cravePlatforms?: Maybe<FacilityCreatecravePlatformsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deliveryEligibleAreas: Scalars['Json'];
  deliveryLimitInSeconds: Scalars['Int'];
  deliveryTimeBuffer?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  enableCatering?: Maybe<Scalars['Boolean']>;
  enableKiosk?: Maybe<Scalars['Boolean']>;
  formattedAddress: Scalars['String'];
  heroImageUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isCateringDeliveryFeeTaxable?: Maybe<Scalars['Boolean']>;
  isCateringServiceFeeTaxable?: Maybe<Scalars['Boolean']>;
  isCmsEnabled?: Maybe<Scalars['Boolean']>;
  isConsumerDeliveryFeeTaxable?: Maybe<Scalars['Boolean']>;
  isConsumerServiceFeeTaxable?: Maybe<Scalars['Boolean']>;
  isFOHPrinterAvailable?: Maybe<Scalars['Boolean']>;
  isGiftCardEnabled?: Maybe<Scalars['Boolean']>;
  kdsType?: Maybe<KdsType>;
  kioskLogoUrl?: Maybe<Scalars['String']>;
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  logoUrl?: Maybe<Scalars['String']>;
  menuCategories?: Maybe<MenuCategoryCreateNestedManyWithoutFacilityInput>;
  menuSchedules?: Maybe<MenuScheduleCreateNestedManyWithoutFacilityInput>;
  methodOverrides?: Maybe<OrderMethodOverrideCreateNestedManyWithoutFacilityInput>;
  name: Scalars['String'];
  nashOptionsId?: Maybe<Scalars['String']>;
  operationHours?: Maybe<FacilityCreateoperationHoursInput>;
  orderMethods?: Maybe<FacilityCreateorderMethodsInput>;
  orders?: Maybe<OrderCreateNestedManyWithoutFacilityInput>;
  payrocTerminal?: Maybe<PayrocTerminalCreateNestedOneWithoutFacilityInput>;
  phone?: Maybe<Scalars['String']>;
  pickupTimeBuffer?: Maybe<Scalars['Int']>;
  placeId: Scalars['String'];
  powerBiReports?: Maybe<PowerBiReportCreateNestedManyWithoutAvailableToFacilitiesInput>;
  promoBanners?: Maybe<PromoBannerCreateNestedManyWithoutFacilityInput>;
  promoFacilityRules?: Maybe<PromoFacilityRuleCreateNestedManyWithoutFacilityInput>;
  readonly?: Maybe<Scalars['Boolean']>;
  slug?: Maybe<Scalars['String']>;
  stations?: Maybe<StationCreateNestedManyWithoutFacilityInput>;
  taxRate?: Maybe<Scalars['Float']>;
  timeZone?: Maybe<Scalars['String']>;
  tookanTeamId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserCreateNestedManyWithoutFacilitiesInput>;
  workplaces?: Maybe<WorkplaceCreateNestedManyWithoutFacilityInput>;
};

export type FacilityCreateWithoutMenuCategoriesInput = {
  CartUpsellItem?: Maybe<CartUpsellItemCreateNestedManyWithoutFacilityInput>;
  Payment?: Maybe<PaymentCreateNestedManyWithoutFacilityInput>;
  Tenant?: Maybe<TenantCreateNestedOneWithoutFacilityInput>;
  cateringDeliveryFee?: Maybe<Scalars['Float']>;
  cateringDeliveryFeeTaxRate?: Maybe<Scalars['Float']>;
  cateringServiceFee?: Maybe<Scalars['Float']>;
  cateringServiceFeeTaxRate?: Maybe<Scalars['Float']>;
  cateringServiceFeeType?: Maybe<FeeType>;
  consumerDeliveryFee?: Maybe<Scalars['Float']>;
  consumerDeliveryFeeTaxRate?: Maybe<Scalars['Float']>;
  consumerServiceFee?: Maybe<Scalars['Float']>;
  consumerServiceFeeTaxRate?: Maybe<Scalars['Float']>;
  consumerServiceFeeType?: Maybe<FeeType>;
  cravePlatforms?: Maybe<FacilityCreatecravePlatformsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deliveryEligibleAreas: Scalars['Json'];
  deliveryLimitInSeconds: Scalars['Int'];
  deliveryTimeBuffer?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  enableCatering?: Maybe<Scalars['Boolean']>;
  enableKiosk?: Maybe<Scalars['Boolean']>;
  formattedAddress: Scalars['String'];
  heroImageUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isCateringDeliveryFeeTaxable?: Maybe<Scalars['Boolean']>;
  isCateringServiceFeeTaxable?: Maybe<Scalars['Boolean']>;
  isCmsEnabled?: Maybe<Scalars['Boolean']>;
  isConsumerDeliveryFeeTaxable?: Maybe<Scalars['Boolean']>;
  isConsumerServiceFeeTaxable?: Maybe<Scalars['Boolean']>;
  isFOHPrinterAvailable?: Maybe<Scalars['Boolean']>;
  isGiftCardEnabled?: Maybe<Scalars['Boolean']>;
  kdsType?: Maybe<KdsType>;
  kioskLogoUrl?: Maybe<Scalars['String']>;
  kitchens?: Maybe<KitchenCreateNestedManyWithoutFacilityInput>;
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  logoUrl?: Maybe<Scalars['String']>;
  menuSchedules?: Maybe<MenuScheduleCreateNestedManyWithoutFacilityInput>;
  methodOverrides?: Maybe<OrderMethodOverrideCreateNestedManyWithoutFacilityInput>;
  name: Scalars['String'];
  nashOptionsId?: Maybe<Scalars['String']>;
  operationHours?: Maybe<FacilityCreateoperationHoursInput>;
  orderMethods?: Maybe<FacilityCreateorderMethodsInput>;
  orders?: Maybe<OrderCreateNestedManyWithoutFacilityInput>;
  payrocTerminal?: Maybe<PayrocTerminalCreateNestedOneWithoutFacilityInput>;
  phone?: Maybe<Scalars['String']>;
  pickupTimeBuffer?: Maybe<Scalars['Int']>;
  placeId: Scalars['String'];
  powerBiReports?: Maybe<PowerBiReportCreateNestedManyWithoutAvailableToFacilitiesInput>;
  promoBanners?: Maybe<PromoBannerCreateNestedManyWithoutFacilityInput>;
  promoFacilityRules?: Maybe<PromoFacilityRuleCreateNestedManyWithoutFacilityInput>;
  readonly?: Maybe<Scalars['Boolean']>;
  slug?: Maybe<Scalars['String']>;
  stations?: Maybe<StationCreateNestedManyWithoutFacilityInput>;
  taxRate?: Maybe<Scalars['Float']>;
  timeZone?: Maybe<Scalars['String']>;
  tookanTeamId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserCreateNestedManyWithoutFacilitiesInput>;
  workplaces?: Maybe<WorkplaceCreateNestedManyWithoutFacilityInput>;
};

export type FacilityCreateWithoutMenuSchedulesInput = {
  CartUpsellItem?: Maybe<CartUpsellItemCreateNestedManyWithoutFacilityInput>;
  Payment?: Maybe<PaymentCreateNestedManyWithoutFacilityInput>;
  Tenant?: Maybe<TenantCreateNestedOneWithoutFacilityInput>;
  cateringDeliveryFee?: Maybe<Scalars['Float']>;
  cateringDeliveryFeeTaxRate?: Maybe<Scalars['Float']>;
  cateringServiceFee?: Maybe<Scalars['Float']>;
  cateringServiceFeeTaxRate?: Maybe<Scalars['Float']>;
  cateringServiceFeeType?: Maybe<FeeType>;
  consumerDeliveryFee?: Maybe<Scalars['Float']>;
  consumerDeliveryFeeTaxRate?: Maybe<Scalars['Float']>;
  consumerServiceFee?: Maybe<Scalars['Float']>;
  consumerServiceFeeTaxRate?: Maybe<Scalars['Float']>;
  consumerServiceFeeType?: Maybe<FeeType>;
  cravePlatforms?: Maybe<FacilityCreatecravePlatformsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deliveryEligibleAreas: Scalars['Json'];
  deliveryLimitInSeconds: Scalars['Int'];
  deliveryTimeBuffer?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  enableCatering?: Maybe<Scalars['Boolean']>;
  enableKiosk?: Maybe<Scalars['Boolean']>;
  formattedAddress: Scalars['String'];
  heroImageUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isCateringDeliveryFeeTaxable?: Maybe<Scalars['Boolean']>;
  isCateringServiceFeeTaxable?: Maybe<Scalars['Boolean']>;
  isCmsEnabled?: Maybe<Scalars['Boolean']>;
  isConsumerDeliveryFeeTaxable?: Maybe<Scalars['Boolean']>;
  isConsumerServiceFeeTaxable?: Maybe<Scalars['Boolean']>;
  isFOHPrinterAvailable?: Maybe<Scalars['Boolean']>;
  isGiftCardEnabled?: Maybe<Scalars['Boolean']>;
  kdsType?: Maybe<KdsType>;
  kioskLogoUrl?: Maybe<Scalars['String']>;
  kitchens?: Maybe<KitchenCreateNestedManyWithoutFacilityInput>;
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  logoUrl?: Maybe<Scalars['String']>;
  menuCategories?: Maybe<MenuCategoryCreateNestedManyWithoutFacilityInput>;
  methodOverrides?: Maybe<OrderMethodOverrideCreateNestedManyWithoutFacilityInput>;
  name: Scalars['String'];
  nashOptionsId?: Maybe<Scalars['String']>;
  operationHours?: Maybe<FacilityCreateoperationHoursInput>;
  orderMethods?: Maybe<FacilityCreateorderMethodsInput>;
  orders?: Maybe<OrderCreateNestedManyWithoutFacilityInput>;
  payrocTerminal?: Maybe<PayrocTerminalCreateNestedOneWithoutFacilityInput>;
  phone?: Maybe<Scalars['String']>;
  pickupTimeBuffer?: Maybe<Scalars['Int']>;
  placeId: Scalars['String'];
  powerBiReports?: Maybe<PowerBiReportCreateNestedManyWithoutAvailableToFacilitiesInput>;
  promoBanners?: Maybe<PromoBannerCreateNestedManyWithoutFacilityInput>;
  promoFacilityRules?: Maybe<PromoFacilityRuleCreateNestedManyWithoutFacilityInput>;
  readonly?: Maybe<Scalars['Boolean']>;
  slug?: Maybe<Scalars['String']>;
  stations?: Maybe<StationCreateNestedManyWithoutFacilityInput>;
  taxRate?: Maybe<Scalars['Float']>;
  timeZone?: Maybe<Scalars['String']>;
  tookanTeamId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserCreateNestedManyWithoutFacilitiesInput>;
  workplaces?: Maybe<WorkplaceCreateNestedManyWithoutFacilityInput>;
};

export type FacilityCreateWithoutOrdersInput = {
  CartUpsellItem?: Maybe<CartUpsellItemCreateNestedManyWithoutFacilityInput>;
  Payment?: Maybe<PaymentCreateNestedManyWithoutFacilityInput>;
  Tenant?: Maybe<TenantCreateNestedOneWithoutFacilityInput>;
  cateringDeliveryFee?: Maybe<Scalars['Float']>;
  cateringDeliveryFeeTaxRate?: Maybe<Scalars['Float']>;
  cateringServiceFee?: Maybe<Scalars['Float']>;
  cateringServiceFeeTaxRate?: Maybe<Scalars['Float']>;
  cateringServiceFeeType?: Maybe<FeeType>;
  consumerDeliveryFee?: Maybe<Scalars['Float']>;
  consumerDeliveryFeeTaxRate?: Maybe<Scalars['Float']>;
  consumerServiceFee?: Maybe<Scalars['Float']>;
  consumerServiceFeeTaxRate?: Maybe<Scalars['Float']>;
  consumerServiceFeeType?: Maybe<FeeType>;
  cravePlatforms?: Maybe<FacilityCreatecravePlatformsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deliveryEligibleAreas: Scalars['Json'];
  deliveryLimitInSeconds: Scalars['Int'];
  deliveryTimeBuffer?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  enableCatering?: Maybe<Scalars['Boolean']>;
  enableKiosk?: Maybe<Scalars['Boolean']>;
  formattedAddress: Scalars['String'];
  heroImageUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isCateringDeliveryFeeTaxable?: Maybe<Scalars['Boolean']>;
  isCateringServiceFeeTaxable?: Maybe<Scalars['Boolean']>;
  isCmsEnabled?: Maybe<Scalars['Boolean']>;
  isConsumerDeliveryFeeTaxable?: Maybe<Scalars['Boolean']>;
  isConsumerServiceFeeTaxable?: Maybe<Scalars['Boolean']>;
  isFOHPrinterAvailable?: Maybe<Scalars['Boolean']>;
  isGiftCardEnabled?: Maybe<Scalars['Boolean']>;
  kdsType?: Maybe<KdsType>;
  kioskLogoUrl?: Maybe<Scalars['String']>;
  kitchens?: Maybe<KitchenCreateNestedManyWithoutFacilityInput>;
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  logoUrl?: Maybe<Scalars['String']>;
  menuCategories?: Maybe<MenuCategoryCreateNestedManyWithoutFacilityInput>;
  menuSchedules?: Maybe<MenuScheduleCreateNestedManyWithoutFacilityInput>;
  methodOverrides?: Maybe<OrderMethodOverrideCreateNestedManyWithoutFacilityInput>;
  name: Scalars['String'];
  nashOptionsId?: Maybe<Scalars['String']>;
  operationHours?: Maybe<FacilityCreateoperationHoursInput>;
  orderMethods?: Maybe<FacilityCreateorderMethodsInput>;
  payrocTerminal?: Maybe<PayrocTerminalCreateNestedOneWithoutFacilityInput>;
  phone?: Maybe<Scalars['String']>;
  pickupTimeBuffer?: Maybe<Scalars['Int']>;
  placeId: Scalars['String'];
  powerBiReports?: Maybe<PowerBiReportCreateNestedManyWithoutAvailableToFacilitiesInput>;
  promoBanners?: Maybe<PromoBannerCreateNestedManyWithoutFacilityInput>;
  promoFacilityRules?: Maybe<PromoFacilityRuleCreateNestedManyWithoutFacilityInput>;
  readonly?: Maybe<Scalars['Boolean']>;
  slug?: Maybe<Scalars['String']>;
  stations?: Maybe<StationCreateNestedManyWithoutFacilityInput>;
  taxRate?: Maybe<Scalars['Float']>;
  timeZone?: Maybe<Scalars['String']>;
  tookanTeamId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserCreateNestedManyWithoutFacilitiesInput>;
  workplaces?: Maybe<WorkplaceCreateNestedManyWithoutFacilityInput>;
};

export type FacilityCreateWithoutPaymentInput = {
  CartUpsellItem?: Maybe<CartUpsellItemCreateNestedManyWithoutFacilityInput>;
  Tenant?: Maybe<TenantCreateNestedOneWithoutFacilityInput>;
  cateringDeliveryFee?: Maybe<Scalars['Float']>;
  cateringDeliveryFeeTaxRate?: Maybe<Scalars['Float']>;
  cateringServiceFee?: Maybe<Scalars['Float']>;
  cateringServiceFeeTaxRate?: Maybe<Scalars['Float']>;
  cateringServiceFeeType?: Maybe<FeeType>;
  consumerDeliveryFee?: Maybe<Scalars['Float']>;
  consumerDeliveryFeeTaxRate?: Maybe<Scalars['Float']>;
  consumerServiceFee?: Maybe<Scalars['Float']>;
  consumerServiceFeeTaxRate?: Maybe<Scalars['Float']>;
  consumerServiceFeeType?: Maybe<FeeType>;
  cravePlatforms?: Maybe<FacilityCreatecravePlatformsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deliveryEligibleAreas: Scalars['Json'];
  deliveryLimitInSeconds: Scalars['Int'];
  deliveryTimeBuffer?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  enableCatering?: Maybe<Scalars['Boolean']>;
  enableKiosk?: Maybe<Scalars['Boolean']>;
  formattedAddress: Scalars['String'];
  heroImageUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isCateringDeliveryFeeTaxable?: Maybe<Scalars['Boolean']>;
  isCateringServiceFeeTaxable?: Maybe<Scalars['Boolean']>;
  isCmsEnabled?: Maybe<Scalars['Boolean']>;
  isConsumerDeliveryFeeTaxable?: Maybe<Scalars['Boolean']>;
  isConsumerServiceFeeTaxable?: Maybe<Scalars['Boolean']>;
  isFOHPrinterAvailable?: Maybe<Scalars['Boolean']>;
  isGiftCardEnabled?: Maybe<Scalars['Boolean']>;
  kdsType?: Maybe<KdsType>;
  kioskLogoUrl?: Maybe<Scalars['String']>;
  kitchens?: Maybe<KitchenCreateNestedManyWithoutFacilityInput>;
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  logoUrl?: Maybe<Scalars['String']>;
  menuCategories?: Maybe<MenuCategoryCreateNestedManyWithoutFacilityInput>;
  menuSchedules?: Maybe<MenuScheduleCreateNestedManyWithoutFacilityInput>;
  methodOverrides?: Maybe<OrderMethodOverrideCreateNestedManyWithoutFacilityInput>;
  name: Scalars['String'];
  nashOptionsId?: Maybe<Scalars['String']>;
  operationHours?: Maybe<FacilityCreateoperationHoursInput>;
  orderMethods?: Maybe<FacilityCreateorderMethodsInput>;
  orders?: Maybe<OrderCreateNestedManyWithoutFacilityInput>;
  payrocTerminal?: Maybe<PayrocTerminalCreateNestedOneWithoutFacilityInput>;
  phone?: Maybe<Scalars['String']>;
  pickupTimeBuffer?: Maybe<Scalars['Int']>;
  placeId: Scalars['String'];
  powerBiReports?: Maybe<PowerBiReportCreateNestedManyWithoutAvailableToFacilitiesInput>;
  promoBanners?: Maybe<PromoBannerCreateNestedManyWithoutFacilityInput>;
  promoFacilityRules?: Maybe<PromoFacilityRuleCreateNestedManyWithoutFacilityInput>;
  readonly?: Maybe<Scalars['Boolean']>;
  slug?: Maybe<Scalars['String']>;
  stations?: Maybe<StationCreateNestedManyWithoutFacilityInput>;
  taxRate?: Maybe<Scalars['Float']>;
  timeZone?: Maybe<Scalars['String']>;
  tookanTeamId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserCreateNestedManyWithoutFacilitiesInput>;
  workplaces?: Maybe<WorkplaceCreateNestedManyWithoutFacilityInput>;
};

export type FacilityCreateWithoutPowerBiReportsInput = {
  CartUpsellItem?: Maybe<CartUpsellItemCreateNestedManyWithoutFacilityInput>;
  Payment?: Maybe<PaymentCreateNestedManyWithoutFacilityInput>;
  Tenant?: Maybe<TenantCreateNestedOneWithoutFacilityInput>;
  cateringDeliveryFee?: Maybe<Scalars['Float']>;
  cateringDeliveryFeeTaxRate?: Maybe<Scalars['Float']>;
  cateringServiceFee?: Maybe<Scalars['Float']>;
  cateringServiceFeeTaxRate?: Maybe<Scalars['Float']>;
  cateringServiceFeeType?: Maybe<FeeType>;
  consumerDeliveryFee?: Maybe<Scalars['Float']>;
  consumerDeliveryFeeTaxRate?: Maybe<Scalars['Float']>;
  consumerServiceFee?: Maybe<Scalars['Float']>;
  consumerServiceFeeTaxRate?: Maybe<Scalars['Float']>;
  consumerServiceFeeType?: Maybe<FeeType>;
  cravePlatforms?: Maybe<FacilityCreatecravePlatformsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deliveryEligibleAreas: Scalars['Json'];
  deliveryLimitInSeconds: Scalars['Int'];
  deliveryTimeBuffer?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  enableCatering?: Maybe<Scalars['Boolean']>;
  enableKiosk?: Maybe<Scalars['Boolean']>;
  formattedAddress: Scalars['String'];
  heroImageUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isCateringDeliveryFeeTaxable?: Maybe<Scalars['Boolean']>;
  isCateringServiceFeeTaxable?: Maybe<Scalars['Boolean']>;
  isCmsEnabled?: Maybe<Scalars['Boolean']>;
  isConsumerDeliveryFeeTaxable?: Maybe<Scalars['Boolean']>;
  isConsumerServiceFeeTaxable?: Maybe<Scalars['Boolean']>;
  isFOHPrinterAvailable?: Maybe<Scalars['Boolean']>;
  isGiftCardEnabled?: Maybe<Scalars['Boolean']>;
  kdsType?: Maybe<KdsType>;
  kioskLogoUrl?: Maybe<Scalars['String']>;
  kitchens?: Maybe<KitchenCreateNestedManyWithoutFacilityInput>;
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  logoUrl?: Maybe<Scalars['String']>;
  menuCategories?: Maybe<MenuCategoryCreateNestedManyWithoutFacilityInput>;
  menuSchedules?: Maybe<MenuScheduleCreateNestedManyWithoutFacilityInput>;
  methodOverrides?: Maybe<OrderMethodOverrideCreateNestedManyWithoutFacilityInput>;
  name: Scalars['String'];
  nashOptionsId?: Maybe<Scalars['String']>;
  operationHours?: Maybe<FacilityCreateoperationHoursInput>;
  orderMethods?: Maybe<FacilityCreateorderMethodsInput>;
  orders?: Maybe<OrderCreateNestedManyWithoutFacilityInput>;
  payrocTerminal?: Maybe<PayrocTerminalCreateNestedOneWithoutFacilityInput>;
  phone?: Maybe<Scalars['String']>;
  pickupTimeBuffer?: Maybe<Scalars['Int']>;
  placeId: Scalars['String'];
  promoBanners?: Maybe<PromoBannerCreateNestedManyWithoutFacilityInput>;
  promoFacilityRules?: Maybe<PromoFacilityRuleCreateNestedManyWithoutFacilityInput>;
  readonly?: Maybe<Scalars['Boolean']>;
  slug?: Maybe<Scalars['String']>;
  stations?: Maybe<StationCreateNestedManyWithoutFacilityInput>;
  taxRate?: Maybe<Scalars['Float']>;
  timeZone?: Maybe<Scalars['String']>;
  tookanTeamId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserCreateNestedManyWithoutFacilitiesInput>;
  workplaces?: Maybe<WorkplaceCreateNestedManyWithoutFacilityInput>;
};

export type FacilityCreateWithoutPromoBannersInput = {
  CartUpsellItem?: Maybe<CartUpsellItemCreateNestedManyWithoutFacilityInput>;
  Payment?: Maybe<PaymentCreateNestedManyWithoutFacilityInput>;
  Tenant?: Maybe<TenantCreateNestedOneWithoutFacilityInput>;
  cateringDeliveryFee?: Maybe<Scalars['Float']>;
  cateringDeliveryFeeTaxRate?: Maybe<Scalars['Float']>;
  cateringServiceFee?: Maybe<Scalars['Float']>;
  cateringServiceFeeTaxRate?: Maybe<Scalars['Float']>;
  cateringServiceFeeType?: Maybe<FeeType>;
  consumerDeliveryFee?: Maybe<Scalars['Float']>;
  consumerDeliveryFeeTaxRate?: Maybe<Scalars['Float']>;
  consumerServiceFee?: Maybe<Scalars['Float']>;
  consumerServiceFeeTaxRate?: Maybe<Scalars['Float']>;
  consumerServiceFeeType?: Maybe<FeeType>;
  cravePlatforms?: Maybe<FacilityCreatecravePlatformsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deliveryEligibleAreas: Scalars['Json'];
  deliveryLimitInSeconds: Scalars['Int'];
  deliveryTimeBuffer?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  enableCatering?: Maybe<Scalars['Boolean']>;
  enableKiosk?: Maybe<Scalars['Boolean']>;
  formattedAddress: Scalars['String'];
  heroImageUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isCateringDeliveryFeeTaxable?: Maybe<Scalars['Boolean']>;
  isCateringServiceFeeTaxable?: Maybe<Scalars['Boolean']>;
  isCmsEnabled?: Maybe<Scalars['Boolean']>;
  isConsumerDeliveryFeeTaxable?: Maybe<Scalars['Boolean']>;
  isConsumerServiceFeeTaxable?: Maybe<Scalars['Boolean']>;
  isFOHPrinterAvailable?: Maybe<Scalars['Boolean']>;
  isGiftCardEnabled?: Maybe<Scalars['Boolean']>;
  kdsType?: Maybe<KdsType>;
  kioskLogoUrl?: Maybe<Scalars['String']>;
  kitchens?: Maybe<KitchenCreateNestedManyWithoutFacilityInput>;
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  logoUrl?: Maybe<Scalars['String']>;
  menuCategories?: Maybe<MenuCategoryCreateNestedManyWithoutFacilityInput>;
  menuSchedules?: Maybe<MenuScheduleCreateNestedManyWithoutFacilityInput>;
  methodOverrides?: Maybe<OrderMethodOverrideCreateNestedManyWithoutFacilityInput>;
  name: Scalars['String'];
  nashOptionsId?: Maybe<Scalars['String']>;
  operationHours?: Maybe<FacilityCreateoperationHoursInput>;
  orderMethods?: Maybe<FacilityCreateorderMethodsInput>;
  orders?: Maybe<OrderCreateNestedManyWithoutFacilityInput>;
  payrocTerminal?: Maybe<PayrocTerminalCreateNestedOneWithoutFacilityInput>;
  phone?: Maybe<Scalars['String']>;
  pickupTimeBuffer?: Maybe<Scalars['Int']>;
  placeId: Scalars['String'];
  powerBiReports?: Maybe<PowerBiReportCreateNestedManyWithoutAvailableToFacilitiesInput>;
  promoFacilityRules?: Maybe<PromoFacilityRuleCreateNestedManyWithoutFacilityInput>;
  readonly?: Maybe<Scalars['Boolean']>;
  slug?: Maybe<Scalars['String']>;
  stations?: Maybe<StationCreateNestedManyWithoutFacilityInput>;
  taxRate?: Maybe<Scalars['Float']>;
  timeZone?: Maybe<Scalars['String']>;
  tookanTeamId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserCreateNestedManyWithoutFacilitiesInput>;
  workplaces?: Maybe<WorkplaceCreateNestedManyWithoutFacilityInput>;
};

export type FacilityCreateWithoutPromoFacilityRulesInput = {
  CartUpsellItem?: Maybe<CartUpsellItemCreateNestedManyWithoutFacilityInput>;
  Payment?: Maybe<PaymentCreateNestedManyWithoutFacilityInput>;
  Tenant?: Maybe<TenantCreateNestedOneWithoutFacilityInput>;
  cateringDeliveryFee?: Maybe<Scalars['Float']>;
  cateringDeliveryFeeTaxRate?: Maybe<Scalars['Float']>;
  cateringServiceFee?: Maybe<Scalars['Float']>;
  cateringServiceFeeTaxRate?: Maybe<Scalars['Float']>;
  cateringServiceFeeType?: Maybe<FeeType>;
  consumerDeliveryFee?: Maybe<Scalars['Float']>;
  consumerDeliveryFeeTaxRate?: Maybe<Scalars['Float']>;
  consumerServiceFee?: Maybe<Scalars['Float']>;
  consumerServiceFeeTaxRate?: Maybe<Scalars['Float']>;
  consumerServiceFeeType?: Maybe<FeeType>;
  cravePlatforms?: Maybe<FacilityCreatecravePlatformsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deliveryEligibleAreas: Scalars['Json'];
  deliveryLimitInSeconds: Scalars['Int'];
  deliveryTimeBuffer?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  enableCatering?: Maybe<Scalars['Boolean']>;
  enableKiosk?: Maybe<Scalars['Boolean']>;
  formattedAddress: Scalars['String'];
  heroImageUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isCateringDeliveryFeeTaxable?: Maybe<Scalars['Boolean']>;
  isCateringServiceFeeTaxable?: Maybe<Scalars['Boolean']>;
  isCmsEnabled?: Maybe<Scalars['Boolean']>;
  isConsumerDeliveryFeeTaxable?: Maybe<Scalars['Boolean']>;
  isConsumerServiceFeeTaxable?: Maybe<Scalars['Boolean']>;
  isFOHPrinterAvailable?: Maybe<Scalars['Boolean']>;
  isGiftCardEnabled?: Maybe<Scalars['Boolean']>;
  kdsType?: Maybe<KdsType>;
  kioskLogoUrl?: Maybe<Scalars['String']>;
  kitchens?: Maybe<KitchenCreateNestedManyWithoutFacilityInput>;
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  logoUrl?: Maybe<Scalars['String']>;
  menuCategories?: Maybe<MenuCategoryCreateNestedManyWithoutFacilityInput>;
  menuSchedules?: Maybe<MenuScheduleCreateNestedManyWithoutFacilityInput>;
  methodOverrides?: Maybe<OrderMethodOverrideCreateNestedManyWithoutFacilityInput>;
  name: Scalars['String'];
  nashOptionsId?: Maybe<Scalars['String']>;
  operationHours?: Maybe<FacilityCreateoperationHoursInput>;
  orderMethods?: Maybe<FacilityCreateorderMethodsInput>;
  orders?: Maybe<OrderCreateNestedManyWithoutFacilityInput>;
  payrocTerminal?: Maybe<PayrocTerminalCreateNestedOneWithoutFacilityInput>;
  phone?: Maybe<Scalars['String']>;
  pickupTimeBuffer?: Maybe<Scalars['Int']>;
  placeId: Scalars['String'];
  powerBiReports?: Maybe<PowerBiReportCreateNestedManyWithoutAvailableToFacilitiesInput>;
  promoBanners?: Maybe<PromoBannerCreateNestedManyWithoutFacilityInput>;
  readonly?: Maybe<Scalars['Boolean']>;
  slug?: Maybe<Scalars['String']>;
  stations?: Maybe<StationCreateNestedManyWithoutFacilityInput>;
  taxRate?: Maybe<Scalars['Float']>;
  timeZone?: Maybe<Scalars['String']>;
  tookanTeamId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserCreateNestedManyWithoutFacilitiesInput>;
  workplaces?: Maybe<WorkplaceCreateNestedManyWithoutFacilityInput>;
};

export type FacilityCreateWithoutStationsInput = {
  CartUpsellItem?: Maybe<CartUpsellItemCreateNestedManyWithoutFacilityInput>;
  Payment?: Maybe<PaymentCreateNestedManyWithoutFacilityInput>;
  Tenant?: Maybe<TenantCreateNestedOneWithoutFacilityInput>;
  cateringDeliveryFee?: Maybe<Scalars['Float']>;
  cateringDeliveryFeeTaxRate?: Maybe<Scalars['Float']>;
  cateringServiceFee?: Maybe<Scalars['Float']>;
  cateringServiceFeeTaxRate?: Maybe<Scalars['Float']>;
  cateringServiceFeeType?: Maybe<FeeType>;
  consumerDeliveryFee?: Maybe<Scalars['Float']>;
  consumerDeliveryFeeTaxRate?: Maybe<Scalars['Float']>;
  consumerServiceFee?: Maybe<Scalars['Float']>;
  consumerServiceFeeTaxRate?: Maybe<Scalars['Float']>;
  consumerServiceFeeType?: Maybe<FeeType>;
  cravePlatforms?: Maybe<FacilityCreatecravePlatformsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deliveryEligibleAreas: Scalars['Json'];
  deliveryLimitInSeconds: Scalars['Int'];
  deliveryTimeBuffer?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  enableCatering?: Maybe<Scalars['Boolean']>;
  enableKiosk?: Maybe<Scalars['Boolean']>;
  formattedAddress: Scalars['String'];
  heroImageUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isCateringDeliveryFeeTaxable?: Maybe<Scalars['Boolean']>;
  isCateringServiceFeeTaxable?: Maybe<Scalars['Boolean']>;
  isCmsEnabled?: Maybe<Scalars['Boolean']>;
  isConsumerDeliveryFeeTaxable?: Maybe<Scalars['Boolean']>;
  isConsumerServiceFeeTaxable?: Maybe<Scalars['Boolean']>;
  isFOHPrinterAvailable?: Maybe<Scalars['Boolean']>;
  isGiftCardEnabled?: Maybe<Scalars['Boolean']>;
  kdsType?: Maybe<KdsType>;
  kioskLogoUrl?: Maybe<Scalars['String']>;
  kitchens?: Maybe<KitchenCreateNestedManyWithoutFacilityInput>;
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  logoUrl?: Maybe<Scalars['String']>;
  menuCategories?: Maybe<MenuCategoryCreateNestedManyWithoutFacilityInput>;
  menuSchedules?: Maybe<MenuScheduleCreateNestedManyWithoutFacilityInput>;
  methodOverrides?: Maybe<OrderMethodOverrideCreateNestedManyWithoutFacilityInput>;
  name: Scalars['String'];
  nashOptionsId?: Maybe<Scalars['String']>;
  operationHours?: Maybe<FacilityCreateoperationHoursInput>;
  orderMethods?: Maybe<FacilityCreateorderMethodsInput>;
  orders?: Maybe<OrderCreateNestedManyWithoutFacilityInput>;
  payrocTerminal?: Maybe<PayrocTerminalCreateNestedOneWithoutFacilityInput>;
  phone?: Maybe<Scalars['String']>;
  pickupTimeBuffer?: Maybe<Scalars['Int']>;
  placeId: Scalars['String'];
  powerBiReports?: Maybe<PowerBiReportCreateNestedManyWithoutAvailableToFacilitiesInput>;
  promoBanners?: Maybe<PromoBannerCreateNestedManyWithoutFacilityInput>;
  promoFacilityRules?: Maybe<PromoFacilityRuleCreateNestedManyWithoutFacilityInput>;
  readonly?: Maybe<Scalars['Boolean']>;
  slug?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  timeZone?: Maybe<Scalars['String']>;
  tookanTeamId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserCreateNestedManyWithoutFacilitiesInput>;
  workplaces?: Maybe<WorkplaceCreateNestedManyWithoutFacilityInput>;
};

export type FacilityCreateWithoutTenantInput = {
  CartUpsellItem?: Maybe<CartUpsellItemCreateNestedManyWithoutFacilityInput>;
  Payment?: Maybe<PaymentCreateNestedManyWithoutFacilityInput>;
  cateringDeliveryFee?: Maybe<Scalars['Float']>;
  cateringDeliveryFeeTaxRate?: Maybe<Scalars['Float']>;
  cateringServiceFee?: Maybe<Scalars['Float']>;
  cateringServiceFeeTaxRate?: Maybe<Scalars['Float']>;
  cateringServiceFeeType?: Maybe<FeeType>;
  consumerDeliveryFee?: Maybe<Scalars['Float']>;
  consumerDeliveryFeeTaxRate?: Maybe<Scalars['Float']>;
  consumerServiceFee?: Maybe<Scalars['Float']>;
  consumerServiceFeeTaxRate?: Maybe<Scalars['Float']>;
  consumerServiceFeeType?: Maybe<FeeType>;
  cravePlatforms?: Maybe<FacilityCreatecravePlatformsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deliveryEligibleAreas: Scalars['Json'];
  deliveryLimitInSeconds: Scalars['Int'];
  deliveryTimeBuffer?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  enableCatering?: Maybe<Scalars['Boolean']>;
  enableKiosk?: Maybe<Scalars['Boolean']>;
  formattedAddress: Scalars['String'];
  heroImageUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isCateringDeliveryFeeTaxable?: Maybe<Scalars['Boolean']>;
  isCateringServiceFeeTaxable?: Maybe<Scalars['Boolean']>;
  isCmsEnabled?: Maybe<Scalars['Boolean']>;
  isConsumerDeliveryFeeTaxable?: Maybe<Scalars['Boolean']>;
  isConsumerServiceFeeTaxable?: Maybe<Scalars['Boolean']>;
  isFOHPrinterAvailable?: Maybe<Scalars['Boolean']>;
  isGiftCardEnabled?: Maybe<Scalars['Boolean']>;
  kdsType?: Maybe<KdsType>;
  kioskLogoUrl?: Maybe<Scalars['String']>;
  kitchens?: Maybe<KitchenCreateNestedManyWithoutFacilityInput>;
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  logoUrl?: Maybe<Scalars['String']>;
  menuCategories?: Maybe<MenuCategoryCreateNestedManyWithoutFacilityInput>;
  menuSchedules?: Maybe<MenuScheduleCreateNestedManyWithoutFacilityInput>;
  methodOverrides?: Maybe<OrderMethodOverrideCreateNestedManyWithoutFacilityInput>;
  name: Scalars['String'];
  nashOptionsId?: Maybe<Scalars['String']>;
  operationHours?: Maybe<FacilityCreateoperationHoursInput>;
  orderMethods?: Maybe<FacilityCreateorderMethodsInput>;
  orders?: Maybe<OrderCreateNestedManyWithoutFacilityInput>;
  payrocTerminal?: Maybe<PayrocTerminalCreateNestedOneWithoutFacilityInput>;
  phone?: Maybe<Scalars['String']>;
  pickupTimeBuffer?: Maybe<Scalars['Int']>;
  placeId: Scalars['String'];
  powerBiReports?: Maybe<PowerBiReportCreateNestedManyWithoutAvailableToFacilitiesInput>;
  promoBanners?: Maybe<PromoBannerCreateNestedManyWithoutFacilityInput>;
  promoFacilityRules?: Maybe<PromoFacilityRuleCreateNestedManyWithoutFacilityInput>;
  readonly?: Maybe<Scalars['Boolean']>;
  slug?: Maybe<Scalars['String']>;
  stations?: Maybe<StationCreateNestedManyWithoutFacilityInput>;
  taxRate?: Maybe<Scalars['Float']>;
  timeZone?: Maybe<Scalars['String']>;
  tookanTeamId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserCreateNestedManyWithoutFacilitiesInput>;
  workplaces?: Maybe<WorkplaceCreateNestedManyWithoutFacilityInput>;
};

export type FacilityCreateWithoutUsersInput = {
  CartUpsellItem?: Maybe<CartUpsellItemCreateNestedManyWithoutFacilityInput>;
  Payment?: Maybe<PaymentCreateNestedManyWithoutFacilityInput>;
  Tenant?: Maybe<TenantCreateNestedOneWithoutFacilityInput>;
  cateringDeliveryFee?: Maybe<Scalars['Float']>;
  cateringDeliveryFeeTaxRate?: Maybe<Scalars['Float']>;
  cateringServiceFee?: Maybe<Scalars['Float']>;
  cateringServiceFeeTaxRate?: Maybe<Scalars['Float']>;
  cateringServiceFeeType?: Maybe<FeeType>;
  consumerDeliveryFee?: Maybe<Scalars['Float']>;
  consumerDeliveryFeeTaxRate?: Maybe<Scalars['Float']>;
  consumerServiceFee?: Maybe<Scalars['Float']>;
  consumerServiceFeeTaxRate?: Maybe<Scalars['Float']>;
  consumerServiceFeeType?: Maybe<FeeType>;
  cravePlatforms?: Maybe<FacilityCreatecravePlatformsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deliveryEligibleAreas: Scalars['Json'];
  deliveryLimitInSeconds: Scalars['Int'];
  deliveryTimeBuffer?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  enableCatering?: Maybe<Scalars['Boolean']>;
  enableKiosk?: Maybe<Scalars['Boolean']>;
  formattedAddress: Scalars['String'];
  heroImageUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isCateringDeliveryFeeTaxable?: Maybe<Scalars['Boolean']>;
  isCateringServiceFeeTaxable?: Maybe<Scalars['Boolean']>;
  isCmsEnabled?: Maybe<Scalars['Boolean']>;
  isConsumerDeliveryFeeTaxable?: Maybe<Scalars['Boolean']>;
  isConsumerServiceFeeTaxable?: Maybe<Scalars['Boolean']>;
  isFOHPrinterAvailable?: Maybe<Scalars['Boolean']>;
  isGiftCardEnabled?: Maybe<Scalars['Boolean']>;
  kdsType?: Maybe<KdsType>;
  kioskLogoUrl?: Maybe<Scalars['String']>;
  kitchens?: Maybe<KitchenCreateNestedManyWithoutFacilityInput>;
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  logoUrl?: Maybe<Scalars['String']>;
  menuCategories?: Maybe<MenuCategoryCreateNestedManyWithoutFacilityInput>;
  menuSchedules?: Maybe<MenuScheduleCreateNestedManyWithoutFacilityInput>;
  methodOverrides?: Maybe<OrderMethodOverrideCreateNestedManyWithoutFacilityInput>;
  name: Scalars['String'];
  nashOptionsId?: Maybe<Scalars['String']>;
  operationHours?: Maybe<FacilityCreateoperationHoursInput>;
  orderMethods?: Maybe<FacilityCreateorderMethodsInput>;
  orders?: Maybe<OrderCreateNestedManyWithoutFacilityInput>;
  payrocTerminal?: Maybe<PayrocTerminalCreateNestedOneWithoutFacilityInput>;
  phone?: Maybe<Scalars['String']>;
  pickupTimeBuffer?: Maybe<Scalars['Int']>;
  placeId: Scalars['String'];
  powerBiReports?: Maybe<PowerBiReportCreateNestedManyWithoutAvailableToFacilitiesInput>;
  promoBanners?: Maybe<PromoBannerCreateNestedManyWithoutFacilityInput>;
  promoFacilityRules?: Maybe<PromoFacilityRuleCreateNestedManyWithoutFacilityInput>;
  readonly?: Maybe<Scalars['Boolean']>;
  slug?: Maybe<Scalars['String']>;
  stations?: Maybe<StationCreateNestedManyWithoutFacilityInput>;
  taxRate?: Maybe<Scalars['Float']>;
  timeZone?: Maybe<Scalars['String']>;
  tookanTeamId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workplaces?: Maybe<WorkplaceCreateNestedManyWithoutFacilityInput>;
};

export type FacilityCreateWithoutWorkplacesInput = {
  CartUpsellItem?: Maybe<CartUpsellItemCreateNestedManyWithoutFacilityInput>;
  Payment?: Maybe<PaymentCreateNestedManyWithoutFacilityInput>;
  Tenant?: Maybe<TenantCreateNestedOneWithoutFacilityInput>;
  cateringDeliveryFee?: Maybe<Scalars['Float']>;
  cateringDeliveryFeeTaxRate?: Maybe<Scalars['Float']>;
  cateringServiceFee?: Maybe<Scalars['Float']>;
  cateringServiceFeeTaxRate?: Maybe<Scalars['Float']>;
  cateringServiceFeeType?: Maybe<FeeType>;
  consumerDeliveryFee?: Maybe<Scalars['Float']>;
  consumerDeliveryFeeTaxRate?: Maybe<Scalars['Float']>;
  consumerServiceFee?: Maybe<Scalars['Float']>;
  consumerServiceFeeTaxRate?: Maybe<Scalars['Float']>;
  consumerServiceFeeType?: Maybe<FeeType>;
  cravePlatforms?: Maybe<FacilityCreatecravePlatformsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deliveryEligibleAreas: Scalars['Json'];
  deliveryLimitInSeconds: Scalars['Int'];
  deliveryTimeBuffer?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  enableCatering?: Maybe<Scalars['Boolean']>;
  enableKiosk?: Maybe<Scalars['Boolean']>;
  formattedAddress: Scalars['String'];
  heroImageUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isCateringDeliveryFeeTaxable?: Maybe<Scalars['Boolean']>;
  isCateringServiceFeeTaxable?: Maybe<Scalars['Boolean']>;
  isCmsEnabled?: Maybe<Scalars['Boolean']>;
  isConsumerDeliveryFeeTaxable?: Maybe<Scalars['Boolean']>;
  isConsumerServiceFeeTaxable?: Maybe<Scalars['Boolean']>;
  isFOHPrinterAvailable?: Maybe<Scalars['Boolean']>;
  isGiftCardEnabled?: Maybe<Scalars['Boolean']>;
  kdsType?: Maybe<KdsType>;
  kioskLogoUrl?: Maybe<Scalars['String']>;
  kitchens?: Maybe<KitchenCreateNestedManyWithoutFacilityInput>;
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  logoUrl?: Maybe<Scalars['String']>;
  menuCategories?: Maybe<MenuCategoryCreateNestedManyWithoutFacilityInput>;
  menuSchedules?: Maybe<MenuScheduleCreateNestedManyWithoutFacilityInput>;
  methodOverrides?: Maybe<OrderMethodOverrideCreateNestedManyWithoutFacilityInput>;
  name: Scalars['String'];
  nashOptionsId?: Maybe<Scalars['String']>;
  operationHours?: Maybe<FacilityCreateoperationHoursInput>;
  orderMethods?: Maybe<FacilityCreateorderMethodsInput>;
  orders?: Maybe<OrderCreateNestedManyWithoutFacilityInput>;
  payrocTerminal?: Maybe<PayrocTerminalCreateNestedOneWithoutFacilityInput>;
  phone?: Maybe<Scalars['String']>;
  pickupTimeBuffer?: Maybe<Scalars['Int']>;
  placeId: Scalars['String'];
  powerBiReports?: Maybe<PowerBiReportCreateNestedManyWithoutAvailableToFacilitiesInput>;
  promoBanners?: Maybe<PromoBannerCreateNestedManyWithoutFacilityInput>;
  promoFacilityRules?: Maybe<PromoFacilityRuleCreateNestedManyWithoutFacilityInput>;
  readonly?: Maybe<Scalars['Boolean']>;
  slug?: Maybe<Scalars['String']>;
  stations?: Maybe<StationCreateNestedManyWithoutFacilityInput>;
  taxRate?: Maybe<Scalars['Float']>;
  timeZone?: Maybe<Scalars['String']>;
  tookanTeamId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserCreateNestedManyWithoutFacilitiesInput>;
};

export type FacilityCreatecravePlatformsInput = {
  set?: Maybe<Array<Scalars['String']>>;
};

export type FacilityCreateoperationHoursInput = {
  set?: Maybe<Array<Scalars['Json']>>;
};

export type FacilityCreateorderMethodsInput = {
  set?: Maybe<Array<OrderMethod>>;
};

export type FacilityItem = {
  __typename?: 'FacilityItem';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  menuItem: KitchenMenuItem;
  orderItem: OrderItem;
  quantity: Scalars['Float'];
  refired?: Maybe<Scalars['Boolean']>;
  refiredFrom?: Maybe<FacilityItem>;
  refiredTo: Array<FacilityItem>;
  ticket: KitchenTicket;
};


export type FacilityItemRefiredToArgs = {
  after?: Maybe<FacilityItemWhereUniqueInput>;
  before?: Maybe<FacilityItemWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type FacilityItemCreateManyMenuItemInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  orderItemId: Scalars['String'];
  quantity?: Maybe<Scalars['Float']>;
  refired?: Maybe<Scalars['Boolean']>;
  refiredFromId?: Maybe<Scalars['String']>;
  ticketId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FacilityItemCreateManyMenuItemInputEnvelope = {
  data?: Maybe<Array<FacilityItemCreateManyMenuItemInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type FacilityItemCreateManyOrderItemInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  menuItemId: Scalars['String'];
  quantity?: Maybe<Scalars['Float']>;
  refired?: Maybe<Scalars['Boolean']>;
  refiredFromId?: Maybe<Scalars['String']>;
  ticketId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FacilityItemCreateManyOrderItemInputEnvelope = {
  data?: Maybe<Array<FacilityItemCreateManyOrderItemInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type FacilityItemCreateManyRefiredFromInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  menuItemId: Scalars['String'];
  orderItemId: Scalars['String'];
  quantity?: Maybe<Scalars['Float']>;
  refired?: Maybe<Scalars['Boolean']>;
  ticketId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FacilityItemCreateManyRefiredFromInputEnvelope = {
  data?: Maybe<Array<FacilityItemCreateManyRefiredFromInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type FacilityItemCreateManyTicketInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  menuItemId: Scalars['String'];
  orderItemId: Scalars['String'];
  quantity?: Maybe<Scalars['Float']>;
  refired?: Maybe<Scalars['Boolean']>;
  refiredFromId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FacilityItemCreateManyTicketInputEnvelope = {
  data?: Maybe<Array<FacilityItemCreateManyTicketInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type FacilityItemCreateNestedManyWithoutMenuItemInput = {
  connect?: Maybe<Array<FacilityItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FacilityItemCreateOrConnectWithoutMenuItemInput>>;
  create?: Maybe<Array<FacilityItemCreateWithoutMenuItemInput>>;
  createMany?: Maybe<FacilityItemCreateManyMenuItemInputEnvelope>;
};

export type FacilityItemCreateNestedManyWithoutOrderItemInput = {
  connect?: Maybe<Array<FacilityItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FacilityItemCreateOrConnectWithoutOrderItemInput>>;
  create?: Maybe<Array<FacilityItemCreateWithoutOrderItemInput>>;
  createMany?: Maybe<FacilityItemCreateManyOrderItemInputEnvelope>;
};

export type FacilityItemCreateNestedManyWithoutRefiredFromInput = {
  connect?: Maybe<Array<FacilityItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FacilityItemCreateOrConnectWithoutRefiredFromInput>>;
  create?: Maybe<Array<FacilityItemCreateWithoutRefiredFromInput>>;
  createMany?: Maybe<FacilityItemCreateManyRefiredFromInputEnvelope>;
};

export type FacilityItemCreateNestedManyWithoutTicketInput = {
  connect?: Maybe<Array<FacilityItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FacilityItemCreateOrConnectWithoutTicketInput>>;
  create?: Maybe<Array<FacilityItemCreateWithoutTicketInput>>;
  createMany?: Maybe<FacilityItemCreateManyTicketInputEnvelope>;
};

export type FacilityItemCreateNestedOneWithoutRefiredToInput = {
  connect?: Maybe<FacilityItemWhereUniqueInput>;
  connectOrCreate?: Maybe<FacilityItemCreateOrConnectWithoutRefiredToInput>;
  create?: Maybe<FacilityItemCreateWithoutRefiredToInput>;
};

export type FacilityItemCreateOrConnectWithoutMenuItemInput = {
  create: FacilityItemCreateWithoutMenuItemInput;
  where: FacilityItemWhereUniqueInput;
};

export type FacilityItemCreateOrConnectWithoutOrderItemInput = {
  create: FacilityItemCreateWithoutOrderItemInput;
  where: FacilityItemWhereUniqueInput;
};

export type FacilityItemCreateOrConnectWithoutRefiredFromInput = {
  create: FacilityItemCreateWithoutRefiredFromInput;
  where: FacilityItemWhereUniqueInput;
};

export type FacilityItemCreateOrConnectWithoutRefiredToInput = {
  create: FacilityItemCreateWithoutRefiredToInput;
  where: FacilityItemWhereUniqueInput;
};

export type FacilityItemCreateOrConnectWithoutTicketInput = {
  create: FacilityItemCreateWithoutTicketInput;
  where: FacilityItemWhereUniqueInput;
};

export type FacilityItemCreateWithoutMenuItemInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  orderItem: OrderItemCreateNestedOneWithoutFacilityItemInput;
  quantity?: Maybe<Scalars['Float']>;
  refired?: Maybe<Scalars['Boolean']>;
  refiredFrom?: Maybe<FacilityItemCreateNestedOneWithoutRefiredToInput>;
  refiredTo?: Maybe<FacilityItemCreateNestedManyWithoutRefiredFromInput>;
  ticket: KitchenTicketCreateNestedOneWithoutItemsInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FacilityItemCreateWithoutOrderItemInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  menuItem: KitchenMenuItemCreateNestedOneWithoutFacilityItemsInput;
  quantity?: Maybe<Scalars['Float']>;
  refired?: Maybe<Scalars['Boolean']>;
  refiredFrom?: Maybe<FacilityItemCreateNestedOneWithoutRefiredToInput>;
  refiredTo?: Maybe<FacilityItemCreateNestedManyWithoutRefiredFromInput>;
  ticket: KitchenTicketCreateNestedOneWithoutItemsInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FacilityItemCreateWithoutRefiredFromInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  menuItem: KitchenMenuItemCreateNestedOneWithoutFacilityItemsInput;
  orderItem: OrderItemCreateNestedOneWithoutFacilityItemInput;
  quantity?: Maybe<Scalars['Float']>;
  refired?: Maybe<Scalars['Boolean']>;
  refiredTo?: Maybe<FacilityItemCreateNestedManyWithoutRefiredFromInput>;
  ticket: KitchenTicketCreateNestedOneWithoutItemsInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FacilityItemCreateWithoutRefiredToInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  menuItem: KitchenMenuItemCreateNestedOneWithoutFacilityItemsInput;
  orderItem: OrderItemCreateNestedOneWithoutFacilityItemInput;
  quantity?: Maybe<Scalars['Float']>;
  refired?: Maybe<Scalars['Boolean']>;
  refiredFrom?: Maybe<FacilityItemCreateNestedOneWithoutRefiredToInput>;
  ticket: KitchenTicketCreateNestedOneWithoutItemsInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FacilityItemCreateWithoutTicketInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  menuItem: KitchenMenuItemCreateNestedOneWithoutFacilityItemsInput;
  orderItem: OrderItemCreateNestedOneWithoutFacilityItemInput;
  quantity?: Maybe<Scalars['Float']>;
  refired?: Maybe<Scalars['Boolean']>;
  refiredFrom?: Maybe<FacilityItemCreateNestedOneWithoutRefiredToInput>;
  refiredTo?: Maybe<FacilityItemCreateNestedManyWithoutRefiredFromInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FacilityItemListRelationFilter = {
  every?: Maybe<FacilityItemWhereInput>;
  none?: Maybe<FacilityItemWhereInput>;
  some?: Maybe<FacilityItemWhereInput>;
};

export type FacilityItemScalarWhereInput = {
  AND?: Maybe<Array<FacilityItemScalarWhereInput>>;
  NOT?: Maybe<Array<FacilityItemScalarWhereInput>>;
  OR?: Maybe<Array<FacilityItemScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  menuItemId?: Maybe<StringFilter>;
  orderItemId?: Maybe<StringFilter>;
  quantity?: Maybe<FloatFilter>;
  refired?: Maybe<BoolNullableFilter>;
  refiredFromId?: Maybe<StringNullableFilter>;
  ticketId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type FacilityItemUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  quantity?: Maybe<FloatFieldUpdateOperationsInput>;
  refired?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type FacilityItemUpdateManyWithWhereWithoutMenuItemInput = {
  data: FacilityItemUpdateManyMutationInput;
  where: FacilityItemScalarWhereInput;
};

export type FacilityItemUpdateManyWithWhereWithoutOrderItemInput = {
  data: FacilityItemUpdateManyMutationInput;
  where: FacilityItemScalarWhereInput;
};

export type FacilityItemUpdateManyWithWhereWithoutRefiredFromInput = {
  data: FacilityItemUpdateManyMutationInput;
  where: FacilityItemScalarWhereInput;
};

export type FacilityItemUpdateManyWithWhereWithoutTicketInput = {
  data: FacilityItemUpdateManyMutationInput;
  where: FacilityItemScalarWhereInput;
};

export type FacilityItemUpdateManyWithoutMenuItemInput = {
  connect?: Maybe<Array<FacilityItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FacilityItemCreateOrConnectWithoutMenuItemInput>>;
  create?: Maybe<Array<FacilityItemCreateWithoutMenuItemInput>>;
  createMany?: Maybe<FacilityItemCreateManyMenuItemInputEnvelope>;
  delete?: Maybe<Array<FacilityItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<FacilityItemScalarWhereInput>>;
  disconnect?: Maybe<Array<FacilityItemWhereUniqueInput>>;
  set?: Maybe<Array<FacilityItemWhereUniqueInput>>;
  update?: Maybe<Array<FacilityItemUpdateWithWhereUniqueWithoutMenuItemInput>>;
  updateMany?: Maybe<Array<FacilityItemUpdateManyWithWhereWithoutMenuItemInput>>;
  upsert?: Maybe<Array<FacilityItemUpsertWithWhereUniqueWithoutMenuItemInput>>;
};

export type FacilityItemUpdateManyWithoutOrderItemInput = {
  connect?: Maybe<Array<FacilityItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FacilityItemCreateOrConnectWithoutOrderItemInput>>;
  create?: Maybe<Array<FacilityItemCreateWithoutOrderItemInput>>;
  createMany?: Maybe<FacilityItemCreateManyOrderItemInputEnvelope>;
  delete?: Maybe<Array<FacilityItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<FacilityItemScalarWhereInput>>;
  disconnect?: Maybe<Array<FacilityItemWhereUniqueInput>>;
  set?: Maybe<Array<FacilityItemWhereUniqueInput>>;
  update?: Maybe<Array<FacilityItemUpdateWithWhereUniqueWithoutOrderItemInput>>;
  updateMany?: Maybe<Array<FacilityItemUpdateManyWithWhereWithoutOrderItemInput>>;
  upsert?: Maybe<Array<FacilityItemUpsertWithWhereUniqueWithoutOrderItemInput>>;
};

export type FacilityItemUpdateManyWithoutRefiredFromInput = {
  connect?: Maybe<Array<FacilityItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FacilityItemCreateOrConnectWithoutRefiredFromInput>>;
  create?: Maybe<Array<FacilityItemCreateWithoutRefiredFromInput>>;
  createMany?: Maybe<FacilityItemCreateManyRefiredFromInputEnvelope>;
  delete?: Maybe<Array<FacilityItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<FacilityItemScalarWhereInput>>;
  disconnect?: Maybe<Array<FacilityItemWhereUniqueInput>>;
  set?: Maybe<Array<FacilityItemWhereUniqueInput>>;
  update?: Maybe<Array<FacilityItemUpdateWithWhereUniqueWithoutRefiredFromInput>>;
  updateMany?: Maybe<Array<FacilityItemUpdateManyWithWhereWithoutRefiredFromInput>>;
  upsert?: Maybe<Array<FacilityItemUpsertWithWhereUniqueWithoutRefiredFromInput>>;
};

export type FacilityItemUpdateManyWithoutTicketInput = {
  connect?: Maybe<Array<FacilityItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FacilityItemCreateOrConnectWithoutTicketInput>>;
  create?: Maybe<Array<FacilityItemCreateWithoutTicketInput>>;
  createMany?: Maybe<FacilityItemCreateManyTicketInputEnvelope>;
  delete?: Maybe<Array<FacilityItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<FacilityItemScalarWhereInput>>;
  disconnect?: Maybe<Array<FacilityItemWhereUniqueInput>>;
  set?: Maybe<Array<FacilityItemWhereUniqueInput>>;
  update?: Maybe<Array<FacilityItemUpdateWithWhereUniqueWithoutTicketInput>>;
  updateMany?: Maybe<Array<FacilityItemUpdateManyWithWhereWithoutTicketInput>>;
  upsert?: Maybe<Array<FacilityItemUpsertWithWhereUniqueWithoutTicketInput>>;
};

export type FacilityItemUpdateOneWithoutRefiredToInput = {
  connect?: Maybe<FacilityItemWhereUniqueInput>;
  connectOrCreate?: Maybe<FacilityItemCreateOrConnectWithoutRefiredToInput>;
  create?: Maybe<FacilityItemCreateWithoutRefiredToInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<FacilityItemUpdateWithoutRefiredToInput>;
  upsert?: Maybe<FacilityItemUpsertWithoutRefiredToInput>;
};

export type FacilityItemUpdateWithWhereUniqueWithoutMenuItemInput = {
  data: FacilityItemUpdateWithoutMenuItemInput;
  where: FacilityItemWhereUniqueInput;
};

export type FacilityItemUpdateWithWhereUniqueWithoutOrderItemInput = {
  data: FacilityItemUpdateWithoutOrderItemInput;
  where: FacilityItemWhereUniqueInput;
};

export type FacilityItemUpdateWithWhereUniqueWithoutRefiredFromInput = {
  data: FacilityItemUpdateWithoutRefiredFromInput;
  where: FacilityItemWhereUniqueInput;
};

export type FacilityItemUpdateWithWhereUniqueWithoutTicketInput = {
  data: FacilityItemUpdateWithoutTicketInput;
  where: FacilityItemWhereUniqueInput;
};

export type FacilityItemUpdateWithoutMenuItemInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  orderItem?: Maybe<OrderItemUpdateOneRequiredWithoutFacilityItemInput>;
  quantity?: Maybe<FloatFieldUpdateOperationsInput>;
  refired?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  refiredFrom?: Maybe<FacilityItemUpdateOneWithoutRefiredToInput>;
  refiredTo?: Maybe<FacilityItemUpdateManyWithoutRefiredFromInput>;
  ticket?: Maybe<KitchenTicketUpdateOneRequiredWithoutItemsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type FacilityItemUpdateWithoutOrderItemInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  menuItem?: Maybe<KitchenMenuItemUpdateOneRequiredWithoutFacilityItemsInput>;
  quantity?: Maybe<FloatFieldUpdateOperationsInput>;
  refired?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  refiredFrom?: Maybe<FacilityItemUpdateOneWithoutRefiredToInput>;
  refiredTo?: Maybe<FacilityItemUpdateManyWithoutRefiredFromInput>;
  ticket?: Maybe<KitchenTicketUpdateOneRequiredWithoutItemsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type FacilityItemUpdateWithoutRefiredFromInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  menuItem?: Maybe<KitchenMenuItemUpdateOneRequiredWithoutFacilityItemsInput>;
  orderItem?: Maybe<OrderItemUpdateOneRequiredWithoutFacilityItemInput>;
  quantity?: Maybe<FloatFieldUpdateOperationsInput>;
  refired?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  refiredTo?: Maybe<FacilityItemUpdateManyWithoutRefiredFromInput>;
  ticket?: Maybe<KitchenTicketUpdateOneRequiredWithoutItemsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type FacilityItemUpdateWithoutRefiredToInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  menuItem?: Maybe<KitchenMenuItemUpdateOneRequiredWithoutFacilityItemsInput>;
  orderItem?: Maybe<OrderItemUpdateOneRequiredWithoutFacilityItemInput>;
  quantity?: Maybe<FloatFieldUpdateOperationsInput>;
  refired?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  refiredFrom?: Maybe<FacilityItemUpdateOneWithoutRefiredToInput>;
  ticket?: Maybe<KitchenTicketUpdateOneRequiredWithoutItemsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type FacilityItemUpdateWithoutTicketInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  menuItem?: Maybe<KitchenMenuItemUpdateOneRequiredWithoutFacilityItemsInput>;
  orderItem?: Maybe<OrderItemUpdateOneRequiredWithoutFacilityItemInput>;
  quantity?: Maybe<FloatFieldUpdateOperationsInput>;
  refired?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  refiredFrom?: Maybe<FacilityItemUpdateOneWithoutRefiredToInput>;
  refiredTo?: Maybe<FacilityItemUpdateManyWithoutRefiredFromInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type FacilityItemUpsertWithWhereUniqueWithoutMenuItemInput = {
  create: FacilityItemCreateWithoutMenuItemInput;
  update: FacilityItemUpdateWithoutMenuItemInput;
  where: FacilityItemWhereUniqueInput;
};

export type FacilityItemUpsertWithWhereUniqueWithoutOrderItemInput = {
  create: FacilityItemCreateWithoutOrderItemInput;
  update: FacilityItemUpdateWithoutOrderItemInput;
  where: FacilityItemWhereUniqueInput;
};

export type FacilityItemUpsertWithWhereUniqueWithoutRefiredFromInput = {
  create: FacilityItemCreateWithoutRefiredFromInput;
  update: FacilityItemUpdateWithoutRefiredFromInput;
  where: FacilityItemWhereUniqueInput;
};

export type FacilityItemUpsertWithWhereUniqueWithoutTicketInput = {
  create: FacilityItemCreateWithoutTicketInput;
  update: FacilityItemUpdateWithoutTicketInput;
  where: FacilityItemWhereUniqueInput;
};

export type FacilityItemUpsertWithoutRefiredToInput = {
  create: FacilityItemCreateWithoutRefiredToInput;
  update: FacilityItemUpdateWithoutRefiredToInput;
};

export type FacilityItemWhereInput = {
  AND?: Maybe<Array<FacilityItemWhereInput>>;
  NOT?: Maybe<Array<FacilityItemWhereInput>>;
  OR?: Maybe<Array<FacilityItemWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  menuItem?: Maybe<KitchenMenuItemWhereInput>;
  menuItemId?: Maybe<StringFilter>;
  orderItem?: Maybe<OrderItemWhereInput>;
  orderItemId?: Maybe<StringFilter>;
  quantity?: Maybe<FloatFilter>;
  refired?: Maybe<BoolNullableFilter>;
  refiredFrom?: Maybe<FacilityItemWhereInput>;
  refiredFromId?: Maybe<StringNullableFilter>;
  refiredTo?: Maybe<FacilityItemListRelationFilter>;
  ticket?: Maybe<KitchenTicketWhereInput>;
  ticketId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type FacilityItemWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type FacilityListRelationFilter = {
  every?: Maybe<FacilityWhereInput>;
  none?: Maybe<FacilityWhereInput>;
  some?: Maybe<FacilityWhereInput>;
};

export type FacilityScalarWhereInput = {
  AND?: Maybe<Array<FacilityScalarWhereInput>>;
  NOT?: Maybe<Array<FacilityScalarWhereInput>>;
  OR?: Maybe<Array<FacilityScalarWhereInput>>;
  cateringDeliveryFee?: Maybe<FloatFilter>;
  cateringDeliveryFeeTaxRate?: Maybe<FloatFilter>;
  cateringServiceFee?: Maybe<FloatFilter>;
  cateringServiceFeeTaxRate?: Maybe<FloatFilter>;
  cateringServiceFeeType?: Maybe<EnumFeeTypeFilter>;
  consumerDeliveryFee?: Maybe<FloatFilter>;
  consumerDeliveryFeeTaxRate?: Maybe<FloatFilter>;
  consumerServiceFee?: Maybe<FloatFilter>;
  consumerServiceFeeTaxRate?: Maybe<FloatFilter>;
  consumerServiceFeeType?: Maybe<EnumFeeTypeFilter>;
  cravePlatforms?: Maybe<StringNullableListFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  deliveryLimitInSeconds?: Maybe<IntFilter>;
  deliveryTimeBuffer?: Maybe<IntFilter>;
  email?: Maybe<StringFilter>;
  enableCatering?: Maybe<BoolFilter>;
  enableKiosk?: Maybe<BoolFilter>;
  formattedAddress?: Maybe<StringFilter>;
  heroImageUrl?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  isCateringDeliveryFeeTaxable?: Maybe<BoolFilter>;
  isCateringServiceFeeTaxable?: Maybe<BoolFilter>;
  isCmsEnabled?: Maybe<BoolFilter>;
  isConsumerDeliveryFeeTaxable?: Maybe<BoolFilter>;
  isConsumerServiceFeeTaxable?: Maybe<BoolFilter>;
  isFOHPrinterAvailable?: Maybe<BoolFilter>;
  isGiftCardEnabled?: Maybe<BoolFilter>;
  kdsType?: Maybe<EnumKdsTypeFilter>;
  kioskLogoUrl?: Maybe<StringNullableFilter>;
  lat?: Maybe<FloatFilter>;
  lng?: Maybe<FloatFilter>;
  logoUrl?: Maybe<StringNullableFilter>;
  name?: Maybe<StringFilter>;
  nashOptionsId?: Maybe<StringNullableFilter>;
  operationHours?: Maybe<JsonNullableListFilter>;
  orderMethods?: Maybe<EnumOrderMethodNullableListFilter>;
  payrocTerminalId?: Maybe<StringNullableFilter>;
  phone?: Maybe<StringFilter>;
  pickupTimeBuffer?: Maybe<IntFilter>;
  placeId?: Maybe<StringFilter>;
  readonly?: Maybe<BoolFilter>;
  slug?: Maybe<StringNullableFilter>;
  taxRate?: Maybe<FloatFilter>;
  tenantId?: Maybe<StringNullableFilter>;
  timeZone?: Maybe<StringFilter>;
  tookanTeamId?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type FacilitySlugTenantIdCompoundUniqueInput = {
  slug: Scalars['String'];
  tenantId: Scalars['String'];
};

export type FacilityUpdateInput = {
  cateringDeliveryFee: Scalars['Float'];
  cateringDeliveryFeeTaxRate: Scalars['Float'];
  cateringServiceFee: Scalars['Float'];
  cateringServiceFeeTaxRate: Scalars['Float'];
  cateringServiceFeeType: FeeTypeEnum;
  consumerDeliveryFee: Scalars['Float'];
  consumerDeliveryFeeTaxRate: Scalars['Float'];
  consumerServiceFee: Scalars['Float'];
  consumerServiceFeeTaxRate: Scalars['Float'];
  consumerServiceFeeType: FeeTypeEnum;
  id: Scalars['ID'];
  isCateringDeliveryFeeTaxable: Scalars['Boolean'];
  isCateringServiceFeeTaxable: Scalars['Boolean'];
  isConsumerDeliveryFeeTaxable: Scalars['Boolean'];
  isConsumerServiceFeeTaxable: Scalars['Boolean'];
  taxRate: Scalars['Float'];
};

export type FacilityUpdateManyMutationInput = {
  cateringDeliveryFee?: Maybe<FloatFieldUpdateOperationsInput>;
  cateringDeliveryFeeTaxRate?: Maybe<FloatFieldUpdateOperationsInput>;
  cateringServiceFee?: Maybe<FloatFieldUpdateOperationsInput>;
  cateringServiceFeeTaxRate?: Maybe<FloatFieldUpdateOperationsInput>;
  cateringServiceFeeType?: Maybe<EnumFeeTypeFieldUpdateOperationsInput>;
  consumerDeliveryFee?: Maybe<FloatFieldUpdateOperationsInput>;
  consumerDeliveryFeeTaxRate?: Maybe<FloatFieldUpdateOperationsInput>;
  consumerServiceFee?: Maybe<FloatFieldUpdateOperationsInput>;
  consumerServiceFeeTaxRate?: Maybe<FloatFieldUpdateOperationsInput>;
  consumerServiceFeeType?: Maybe<EnumFeeTypeFieldUpdateOperationsInput>;
  cravePlatforms?: Maybe<FacilityUpdatecravePlatformsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deliveryEligibleAreas?: Maybe<Scalars['Json']>;
  deliveryLimitInSeconds?: Maybe<IntFieldUpdateOperationsInput>;
  deliveryTimeBuffer?: Maybe<IntFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  enableCatering?: Maybe<BoolFieldUpdateOperationsInput>;
  enableKiosk?: Maybe<BoolFieldUpdateOperationsInput>;
  formattedAddress?: Maybe<StringFieldUpdateOperationsInput>;
  heroImageUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isCateringDeliveryFeeTaxable?: Maybe<BoolFieldUpdateOperationsInput>;
  isCateringServiceFeeTaxable?: Maybe<BoolFieldUpdateOperationsInput>;
  isCmsEnabled?: Maybe<BoolFieldUpdateOperationsInput>;
  isConsumerDeliveryFeeTaxable?: Maybe<BoolFieldUpdateOperationsInput>;
  isConsumerServiceFeeTaxable?: Maybe<BoolFieldUpdateOperationsInput>;
  isFOHPrinterAvailable?: Maybe<BoolFieldUpdateOperationsInput>;
  isGiftCardEnabled?: Maybe<BoolFieldUpdateOperationsInput>;
  kdsType?: Maybe<EnumKdsTypeFieldUpdateOperationsInput>;
  kioskLogoUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lat?: Maybe<FloatFieldUpdateOperationsInput>;
  lng?: Maybe<FloatFieldUpdateOperationsInput>;
  logoUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  nashOptionsId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  operationHours?: Maybe<FacilityUpdateoperationHoursInput>;
  orderMethods?: Maybe<FacilityUpdateorderMethodsInput>;
  phone?: Maybe<StringFieldUpdateOperationsInput>;
  pickupTimeBuffer?: Maybe<IntFieldUpdateOperationsInput>;
  placeId?: Maybe<StringFieldUpdateOperationsInput>;
  readonly?: Maybe<BoolFieldUpdateOperationsInput>;
  slug?: Maybe<NullableStringFieldUpdateOperationsInput>;
  taxRate?: Maybe<FloatFieldUpdateOperationsInput>;
  timeZone?: Maybe<StringFieldUpdateOperationsInput>;
  tookanTeamId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type FacilityUpdateManyWithWhereWithoutPowerBiReportsInput = {
  data: FacilityUpdateManyMutationInput;
  where: FacilityScalarWhereInput;
};

export type FacilityUpdateManyWithWhereWithoutTenantInput = {
  data: FacilityUpdateManyMutationInput;
  where: FacilityScalarWhereInput;
};

export type FacilityUpdateManyWithWhereWithoutUsersInput = {
  data: FacilityUpdateManyMutationInput;
  where: FacilityScalarWhereInput;
};

export type FacilityUpdateManyWithoutPowerBiReportsInput = {
  connect?: Maybe<Array<FacilityWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FacilityCreateOrConnectWithoutPowerBiReportsInput>>;
  create?: Maybe<Array<FacilityCreateWithoutPowerBiReportsInput>>;
  delete?: Maybe<Array<FacilityWhereUniqueInput>>;
  deleteMany?: Maybe<Array<FacilityScalarWhereInput>>;
  disconnect?: Maybe<Array<FacilityWhereUniqueInput>>;
  set?: Maybe<Array<FacilityWhereUniqueInput>>;
  update?: Maybe<Array<FacilityUpdateWithWhereUniqueWithoutPowerBiReportsInput>>;
  updateMany?: Maybe<Array<FacilityUpdateManyWithWhereWithoutPowerBiReportsInput>>;
  upsert?: Maybe<Array<FacilityUpsertWithWhereUniqueWithoutPowerBiReportsInput>>;
};

export type FacilityUpdateManyWithoutTenantInput = {
  connect?: Maybe<Array<FacilityWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FacilityCreateOrConnectWithoutTenantInput>>;
  create?: Maybe<Array<FacilityCreateWithoutTenantInput>>;
  createMany?: Maybe<FacilityCreateManyTenantInputEnvelope>;
  delete?: Maybe<Array<FacilityWhereUniqueInput>>;
  deleteMany?: Maybe<Array<FacilityScalarWhereInput>>;
  disconnect?: Maybe<Array<FacilityWhereUniqueInput>>;
  set?: Maybe<Array<FacilityWhereUniqueInput>>;
  update?: Maybe<Array<FacilityUpdateWithWhereUniqueWithoutTenantInput>>;
  updateMany?: Maybe<Array<FacilityUpdateManyWithWhereWithoutTenantInput>>;
  upsert?: Maybe<Array<FacilityUpsertWithWhereUniqueWithoutTenantInput>>;
};

export type FacilityUpdateManyWithoutUsersInput = {
  connect?: Maybe<Array<FacilityWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FacilityCreateOrConnectWithoutUsersInput>>;
  create?: Maybe<Array<FacilityCreateWithoutUsersInput>>;
  delete?: Maybe<Array<FacilityWhereUniqueInput>>;
  deleteMany?: Maybe<Array<FacilityScalarWhereInput>>;
  disconnect?: Maybe<Array<FacilityWhereUniqueInput>>;
  set?: Maybe<Array<FacilityWhereUniqueInput>>;
  update?: Maybe<Array<FacilityUpdateWithWhereUniqueWithoutUsersInput>>;
  updateMany?: Maybe<Array<FacilityUpdateManyWithWhereWithoutUsersInput>>;
  upsert?: Maybe<Array<FacilityUpsertWithWhereUniqueWithoutUsersInput>>;
};

export type FacilityUpdateOneRequiredWithoutCartUpsellItemInput = {
  connect?: Maybe<FacilityWhereUniqueInput>;
  connectOrCreate?: Maybe<FacilityCreateOrConnectWithoutCartUpsellItemInput>;
  create?: Maybe<FacilityCreateWithoutCartUpsellItemInput>;
  update?: Maybe<FacilityUpdateWithoutCartUpsellItemInput>;
  upsert?: Maybe<FacilityUpsertWithoutCartUpsellItemInput>;
};

export type FacilityUpdateOneRequiredWithoutKitchensInput = {
  connect?: Maybe<FacilityWhereUniqueInput>;
  connectOrCreate?: Maybe<FacilityCreateOrConnectWithoutKitchensInput>;
  create?: Maybe<FacilityCreateWithoutKitchensInput>;
  update?: Maybe<FacilityUpdateWithoutKitchensInput>;
  upsert?: Maybe<FacilityUpsertWithoutKitchensInput>;
};

export type FacilityUpdateOneRequiredWithoutMenuCategoriesInput = {
  connect?: Maybe<FacilityWhereUniqueInput>;
  connectOrCreate?: Maybe<FacilityCreateOrConnectWithoutMenuCategoriesInput>;
  create?: Maybe<FacilityCreateWithoutMenuCategoriesInput>;
  update?: Maybe<FacilityUpdateWithoutMenuCategoriesInput>;
  upsert?: Maybe<FacilityUpsertWithoutMenuCategoriesInput>;
};

export type FacilityUpdateOneRequiredWithoutMenuSchedulesInput = {
  connect?: Maybe<FacilityWhereUniqueInput>;
  connectOrCreate?: Maybe<FacilityCreateOrConnectWithoutMenuSchedulesInput>;
  create?: Maybe<FacilityCreateWithoutMenuSchedulesInput>;
  update?: Maybe<FacilityUpdateWithoutMenuSchedulesInput>;
  upsert?: Maybe<FacilityUpsertWithoutMenuSchedulesInput>;
};

export type FacilityUpdateOneRequiredWithoutOrdersInput = {
  connect?: Maybe<FacilityWhereUniqueInput>;
  connectOrCreate?: Maybe<FacilityCreateOrConnectWithoutOrdersInput>;
  create?: Maybe<FacilityCreateWithoutOrdersInput>;
  update?: Maybe<FacilityUpdateWithoutOrdersInput>;
  upsert?: Maybe<FacilityUpsertWithoutOrdersInput>;
};

export type FacilityUpdateOneRequiredWithoutPaymentInput = {
  connect?: Maybe<FacilityWhereUniqueInput>;
  connectOrCreate?: Maybe<FacilityCreateOrConnectWithoutPaymentInput>;
  create?: Maybe<FacilityCreateWithoutPaymentInput>;
  update?: Maybe<FacilityUpdateWithoutPaymentInput>;
  upsert?: Maybe<FacilityUpsertWithoutPaymentInput>;
};

export type FacilityUpdateOneRequiredWithoutPromoBannersInput = {
  connect?: Maybe<FacilityWhereUniqueInput>;
  connectOrCreate?: Maybe<FacilityCreateOrConnectWithoutPromoBannersInput>;
  create?: Maybe<FacilityCreateWithoutPromoBannersInput>;
  update?: Maybe<FacilityUpdateWithoutPromoBannersInput>;
  upsert?: Maybe<FacilityUpsertWithoutPromoBannersInput>;
};

export type FacilityUpdateOneRequiredWithoutPromoFacilityRulesInput = {
  connect?: Maybe<FacilityWhereUniqueInput>;
  connectOrCreate?: Maybe<FacilityCreateOrConnectWithoutPromoFacilityRulesInput>;
  create?: Maybe<FacilityCreateWithoutPromoFacilityRulesInput>;
  update?: Maybe<FacilityUpdateWithoutPromoFacilityRulesInput>;
  upsert?: Maybe<FacilityUpsertWithoutPromoFacilityRulesInput>;
};

export type FacilityUpdateOneRequiredWithoutStationsInput = {
  connect?: Maybe<FacilityWhereUniqueInput>;
  connectOrCreate?: Maybe<FacilityCreateOrConnectWithoutStationsInput>;
  create?: Maybe<FacilityCreateWithoutStationsInput>;
  update?: Maybe<FacilityUpdateWithoutStationsInput>;
  upsert?: Maybe<FacilityUpsertWithoutStationsInput>;
};

export type FacilityUpdateOneRequiredWithoutWorkplacesInput = {
  connect?: Maybe<FacilityWhereUniqueInput>;
  connectOrCreate?: Maybe<FacilityCreateOrConnectWithoutWorkplacesInput>;
  create?: Maybe<FacilityCreateWithoutWorkplacesInput>;
  update?: Maybe<FacilityUpdateWithoutWorkplacesInput>;
  upsert?: Maybe<FacilityUpsertWithoutWorkplacesInput>;
};

export type FacilityUpdateWithWhereUniqueWithoutPowerBiReportsInput = {
  data: FacilityUpdateWithoutPowerBiReportsInput;
  where: FacilityWhereUniqueInput;
};

export type FacilityUpdateWithWhereUniqueWithoutTenantInput = {
  data: FacilityUpdateWithoutTenantInput;
  where: FacilityWhereUniqueInput;
};

export type FacilityUpdateWithWhereUniqueWithoutUsersInput = {
  data: FacilityUpdateWithoutUsersInput;
  where: FacilityWhereUniqueInput;
};

export type FacilityUpdateWithoutCartUpsellItemInput = {
  Payment?: Maybe<PaymentUpdateManyWithoutFacilityInput>;
  Tenant?: Maybe<TenantUpdateOneWithoutFacilityInput>;
  cateringDeliveryFee?: Maybe<FloatFieldUpdateOperationsInput>;
  cateringDeliveryFeeTaxRate?: Maybe<FloatFieldUpdateOperationsInput>;
  cateringServiceFee?: Maybe<FloatFieldUpdateOperationsInput>;
  cateringServiceFeeTaxRate?: Maybe<FloatFieldUpdateOperationsInput>;
  cateringServiceFeeType?: Maybe<EnumFeeTypeFieldUpdateOperationsInput>;
  consumerDeliveryFee?: Maybe<FloatFieldUpdateOperationsInput>;
  consumerDeliveryFeeTaxRate?: Maybe<FloatFieldUpdateOperationsInput>;
  consumerServiceFee?: Maybe<FloatFieldUpdateOperationsInput>;
  consumerServiceFeeTaxRate?: Maybe<FloatFieldUpdateOperationsInput>;
  consumerServiceFeeType?: Maybe<EnumFeeTypeFieldUpdateOperationsInput>;
  cravePlatforms?: Maybe<FacilityUpdatecravePlatformsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deliveryEligibleAreas?: Maybe<Scalars['Json']>;
  deliveryLimitInSeconds?: Maybe<IntFieldUpdateOperationsInput>;
  deliveryTimeBuffer?: Maybe<IntFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  enableCatering?: Maybe<BoolFieldUpdateOperationsInput>;
  enableKiosk?: Maybe<BoolFieldUpdateOperationsInput>;
  formattedAddress?: Maybe<StringFieldUpdateOperationsInput>;
  heroImageUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isCateringDeliveryFeeTaxable?: Maybe<BoolFieldUpdateOperationsInput>;
  isCateringServiceFeeTaxable?: Maybe<BoolFieldUpdateOperationsInput>;
  isCmsEnabled?: Maybe<BoolFieldUpdateOperationsInput>;
  isConsumerDeliveryFeeTaxable?: Maybe<BoolFieldUpdateOperationsInput>;
  isConsumerServiceFeeTaxable?: Maybe<BoolFieldUpdateOperationsInput>;
  isFOHPrinterAvailable?: Maybe<BoolFieldUpdateOperationsInput>;
  isGiftCardEnabled?: Maybe<BoolFieldUpdateOperationsInput>;
  kdsType?: Maybe<EnumKdsTypeFieldUpdateOperationsInput>;
  kioskLogoUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  kitchens?: Maybe<KitchenUpdateManyWithoutFacilityInput>;
  lat?: Maybe<FloatFieldUpdateOperationsInput>;
  lng?: Maybe<FloatFieldUpdateOperationsInput>;
  logoUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  menuCategories?: Maybe<MenuCategoryUpdateManyWithoutFacilityInput>;
  menuSchedules?: Maybe<MenuScheduleUpdateManyWithoutFacilityInput>;
  methodOverrides?: Maybe<OrderMethodOverrideUpdateManyWithoutFacilityInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  nashOptionsId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  operationHours?: Maybe<FacilityUpdateoperationHoursInput>;
  orderMethods?: Maybe<FacilityUpdateorderMethodsInput>;
  orders?: Maybe<OrderUpdateManyWithoutFacilityInput>;
  payrocTerminal?: Maybe<PayrocTerminalUpdateOneWithoutFacilityInput>;
  phone?: Maybe<StringFieldUpdateOperationsInput>;
  pickupTimeBuffer?: Maybe<IntFieldUpdateOperationsInput>;
  placeId?: Maybe<StringFieldUpdateOperationsInput>;
  powerBiReports?: Maybe<PowerBiReportUpdateManyWithoutAvailableToFacilitiesInput>;
  promoBanners?: Maybe<PromoBannerUpdateManyWithoutFacilityInput>;
  promoFacilityRules?: Maybe<PromoFacilityRuleUpdateManyWithoutFacilityInput>;
  readonly?: Maybe<BoolFieldUpdateOperationsInput>;
  slug?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stations?: Maybe<StationUpdateManyWithoutFacilityInput>;
  taxRate?: Maybe<FloatFieldUpdateOperationsInput>;
  timeZone?: Maybe<StringFieldUpdateOperationsInput>;
  tookanTeamId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  users?: Maybe<UserUpdateManyWithoutFacilitiesInput>;
  workplaces?: Maybe<WorkplaceUpdateManyWithoutFacilityInput>;
};

export type FacilityUpdateWithoutKitchensInput = {
  CartUpsellItem?: Maybe<CartUpsellItemUpdateManyWithoutFacilityInput>;
  Payment?: Maybe<PaymentUpdateManyWithoutFacilityInput>;
  Tenant?: Maybe<TenantUpdateOneWithoutFacilityInput>;
  cateringDeliveryFee?: Maybe<FloatFieldUpdateOperationsInput>;
  cateringDeliveryFeeTaxRate?: Maybe<FloatFieldUpdateOperationsInput>;
  cateringServiceFee?: Maybe<FloatFieldUpdateOperationsInput>;
  cateringServiceFeeTaxRate?: Maybe<FloatFieldUpdateOperationsInput>;
  cateringServiceFeeType?: Maybe<EnumFeeTypeFieldUpdateOperationsInput>;
  consumerDeliveryFee?: Maybe<FloatFieldUpdateOperationsInput>;
  consumerDeliveryFeeTaxRate?: Maybe<FloatFieldUpdateOperationsInput>;
  consumerServiceFee?: Maybe<FloatFieldUpdateOperationsInput>;
  consumerServiceFeeTaxRate?: Maybe<FloatFieldUpdateOperationsInput>;
  consumerServiceFeeType?: Maybe<EnumFeeTypeFieldUpdateOperationsInput>;
  cravePlatforms?: Maybe<FacilityUpdatecravePlatformsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deliveryEligibleAreas?: Maybe<Scalars['Json']>;
  deliveryLimitInSeconds?: Maybe<IntFieldUpdateOperationsInput>;
  deliveryTimeBuffer?: Maybe<IntFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  enableCatering?: Maybe<BoolFieldUpdateOperationsInput>;
  enableKiosk?: Maybe<BoolFieldUpdateOperationsInput>;
  formattedAddress?: Maybe<StringFieldUpdateOperationsInput>;
  heroImageUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isCateringDeliveryFeeTaxable?: Maybe<BoolFieldUpdateOperationsInput>;
  isCateringServiceFeeTaxable?: Maybe<BoolFieldUpdateOperationsInput>;
  isCmsEnabled?: Maybe<BoolFieldUpdateOperationsInput>;
  isConsumerDeliveryFeeTaxable?: Maybe<BoolFieldUpdateOperationsInput>;
  isConsumerServiceFeeTaxable?: Maybe<BoolFieldUpdateOperationsInput>;
  isFOHPrinterAvailable?: Maybe<BoolFieldUpdateOperationsInput>;
  isGiftCardEnabled?: Maybe<BoolFieldUpdateOperationsInput>;
  kdsType?: Maybe<EnumKdsTypeFieldUpdateOperationsInput>;
  kioskLogoUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lat?: Maybe<FloatFieldUpdateOperationsInput>;
  lng?: Maybe<FloatFieldUpdateOperationsInput>;
  logoUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  menuCategories?: Maybe<MenuCategoryUpdateManyWithoutFacilityInput>;
  menuSchedules?: Maybe<MenuScheduleUpdateManyWithoutFacilityInput>;
  methodOverrides?: Maybe<OrderMethodOverrideUpdateManyWithoutFacilityInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  nashOptionsId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  operationHours?: Maybe<FacilityUpdateoperationHoursInput>;
  orderMethods?: Maybe<FacilityUpdateorderMethodsInput>;
  orders?: Maybe<OrderUpdateManyWithoutFacilityInput>;
  payrocTerminal?: Maybe<PayrocTerminalUpdateOneWithoutFacilityInput>;
  phone?: Maybe<StringFieldUpdateOperationsInput>;
  pickupTimeBuffer?: Maybe<IntFieldUpdateOperationsInput>;
  placeId?: Maybe<StringFieldUpdateOperationsInput>;
  powerBiReports?: Maybe<PowerBiReportUpdateManyWithoutAvailableToFacilitiesInput>;
  promoBanners?: Maybe<PromoBannerUpdateManyWithoutFacilityInput>;
  promoFacilityRules?: Maybe<PromoFacilityRuleUpdateManyWithoutFacilityInput>;
  readonly?: Maybe<BoolFieldUpdateOperationsInput>;
  slug?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stations?: Maybe<StationUpdateManyWithoutFacilityInput>;
  taxRate?: Maybe<FloatFieldUpdateOperationsInput>;
  timeZone?: Maybe<StringFieldUpdateOperationsInput>;
  tookanTeamId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  users?: Maybe<UserUpdateManyWithoutFacilitiesInput>;
  workplaces?: Maybe<WorkplaceUpdateManyWithoutFacilityInput>;
};

export type FacilityUpdateWithoutMenuCategoriesInput = {
  CartUpsellItem?: Maybe<CartUpsellItemUpdateManyWithoutFacilityInput>;
  Payment?: Maybe<PaymentUpdateManyWithoutFacilityInput>;
  Tenant?: Maybe<TenantUpdateOneWithoutFacilityInput>;
  cateringDeliveryFee?: Maybe<FloatFieldUpdateOperationsInput>;
  cateringDeliveryFeeTaxRate?: Maybe<FloatFieldUpdateOperationsInput>;
  cateringServiceFee?: Maybe<FloatFieldUpdateOperationsInput>;
  cateringServiceFeeTaxRate?: Maybe<FloatFieldUpdateOperationsInput>;
  cateringServiceFeeType?: Maybe<EnumFeeTypeFieldUpdateOperationsInput>;
  consumerDeliveryFee?: Maybe<FloatFieldUpdateOperationsInput>;
  consumerDeliveryFeeTaxRate?: Maybe<FloatFieldUpdateOperationsInput>;
  consumerServiceFee?: Maybe<FloatFieldUpdateOperationsInput>;
  consumerServiceFeeTaxRate?: Maybe<FloatFieldUpdateOperationsInput>;
  consumerServiceFeeType?: Maybe<EnumFeeTypeFieldUpdateOperationsInput>;
  cravePlatforms?: Maybe<FacilityUpdatecravePlatformsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deliveryEligibleAreas?: Maybe<Scalars['Json']>;
  deliveryLimitInSeconds?: Maybe<IntFieldUpdateOperationsInput>;
  deliveryTimeBuffer?: Maybe<IntFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  enableCatering?: Maybe<BoolFieldUpdateOperationsInput>;
  enableKiosk?: Maybe<BoolFieldUpdateOperationsInput>;
  formattedAddress?: Maybe<StringFieldUpdateOperationsInput>;
  heroImageUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isCateringDeliveryFeeTaxable?: Maybe<BoolFieldUpdateOperationsInput>;
  isCateringServiceFeeTaxable?: Maybe<BoolFieldUpdateOperationsInput>;
  isCmsEnabled?: Maybe<BoolFieldUpdateOperationsInput>;
  isConsumerDeliveryFeeTaxable?: Maybe<BoolFieldUpdateOperationsInput>;
  isConsumerServiceFeeTaxable?: Maybe<BoolFieldUpdateOperationsInput>;
  isFOHPrinterAvailable?: Maybe<BoolFieldUpdateOperationsInput>;
  isGiftCardEnabled?: Maybe<BoolFieldUpdateOperationsInput>;
  kdsType?: Maybe<EnumKdsTypeFieldUpdateOperationsInput>;
  kioskLogoUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  kitchens?: Maybe<KitchenUpdateManyWithoutFacilityInput>;
  lat?: Maybe<FloatFieldUpdateOperationsInput>;
  lng?: Maybe<FloatFieldUpdateOperationsInput>;
  logoUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  menuSchedules?: Maybe<MenuScheduleUpdateManyWithoutFacilityInput>;
  methodOverrides?: Maybe<OrderMethodOverrideUpdateManyWithoutFacilityInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  nashOptionsId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  operationHours?: Maybe<FacilityUpdateoperationHoursInput>;
  orderMethods?: Maybe<FacilityUpdateorderMethodsInput>;
  orders?: Maybe<OrderUpdateManyWithoutFacilityInput>;
  payrocTerminal?: Maybe<PayrocTerminalUpdateOneWithoutFacilityInput>;
  phone?: Maybe<StringFieldUpdateOperationsInput>;
  pickupTimeBuffer?: Maybe<IntFieldUpdateOperationsInput>;
  placeId?: Maybe<StringFieldUpdateOperationsInput>;
  powerBiReports?: Maybe<PowerBiReportUpdateManyWithoutAvailableToFacilitiesInput>;
  promoBanners?: Maybe<PromoBannerUpdateManyWithoutFacilityInput>;
  promoFacilityRules?: Maybe<PromoFacilityRuleUpdateManyWithoutFacilityInput>;
  readonly?: Maybe<BoolFieldUpdateOperationsInput>;
  slug?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stations?: Maybe<StationUpdateManyWithoutFacilityInput>;
  taxRate?: Maybe<FloatFieldUpdateOperationsInput>;
  timeZone?: Maybe<StringFieldUpdateOperationsInput>;
  tookanTeamId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  users?: Maybe<UserUpdateManyWithoutFacilitiesInput>;
  workplaces?: Maybe<WorkplaceUpdateManyWithoutFacilityInput>;
};

export type FacilityUpdateWithoutMenuSchedulesInput = {
  CartUpsellItem?: Maybe<CartUpsellItemUpdateManyWithoutFacilityInput>;
  Payment?: Maybe<PaymentUpdateManyWithoutFacilityInput>;
  Tenant?: Maybe<TenantUpdateOneWithoutFacilityInput>;
  cateringDeliveryFee?: Maybe<FloatFieldUpdateOperationsInput>;
  cateringDeliveryFeeTaxRate?: Maybe<FloatFieldUpdateOperationsInput>;
  cateringServiceFee?: Maybe<FloatFieldUpdateOperationsInput>;
  cateringServiceFeeTaxRate?: Maybe<FloatFieldUpdateOperationsInput>;
  cateringServiceFeeType?: Maybe<EnumFeeTypeFieldUpdateOperationsInput>;
  consumerDeliveryFee?: Maybe<FloatFieldUpdateOperationsInput>;
  consumerDeliveryFeeTaxRate?: Maybe<FloatFieldUpdateOperationsInput>;
  consumerServiceFee?: Maybe<FloatFieldUpdateOperationsInput>;
  consumerServiceFeeTaxRate?: Maybe<FloatFieldUpdateOperationsInput>;
  consumerServiceFeeType?: Maybe<EnumFeeTypeFieldUpdateOperationsInput>;
  cravePlatforms?: Maybe<FacilityUpdatecravePlatformsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deliveryEligibleAreas?: Maybe<Scalars['Json']>;
  deliveryLimitInSeconds?: Maybe<IntFieldUpdateOperationsInput>;
  deliveryTimeBuffer?: Maybe<IntFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  enableCatering?: Maybe<BoolFieldUpdateOperationsInput>;
  enableKiosk?: Maybe<BoolFieldUpdateOperationsInput>;
  formattedAddress?: Maybe<StringFieldUpdateOperationsInput>;
  heroImageUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isCateringDeliveryFeeTaxable?: Maybe<BoolFieldUpdateOperationsInput>;
  isCateringServiceFeeTaxable?: Maybe<BoolFieldUpdateOperationsInput>;
  isCmsEnabled?: Maybe<BoolFieldUpdateOperationsInput>;
  isConsumerDeliveryFeeTaxable?: Maybe<BoolFieldUpdateOperationsInput>;
  isConsumerServiceFeeTaxable?: Maybe<BoolFieldUpdateOperationsInput>;
  isFOHPrinterAvailable?: Maybe<BoolFieldUpdateOperationsInput>;
  isGiftCardEnabled?: Maybe<BoolFieldUpdateOperationsInput>;
  kdsType?: Maybe<EnumKdsTypeFieldUpdateOperationsInput>;
  kioskLogoUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  kitchens?: Maybe<KitchenUpdateManyWithoutFacilityInput>;
  lat?: Maybe<FloatFieldUpdateOperationsInput>;
  lng?: Maybe<FloatFieldUpdateOperationsInput>;
  logoUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  menuCategories?: Maybe<MenuCategoryUpdateManyWithoutFacilityInput>;
  methodOverrides?: Maybe<OrderMethodOverrideUpdateManyWithoutFacilityInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  nashOptionsId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  operationHours?: Maybe<FacilityUpdateoperationHoursInput>;
  orderMethods?: Maybe<FacilityUpdateorderMethodsInput>;
  orders?: Maybe<OrderUpdateManyWithoutFacilityInput>;
  payrocTerminal?: Maybe<PayrocTerminalUpdateOneWithoutFacilityInput>;
  phone?: Maybe<StringFieldUpdateOperationsInput>;
  pickupTimeBuffer?: Maybe<IntFieldUpdateOperationsInput>;
  placeId?: Maybe<StringFieldUpdateOperationsInput>;
  powerBiReports?: Maybe<PowerBiReportUpdateManyWithoutAvailableToFacilitiesInput>;
  promoBanners?: Maybe<PromoBannerUpdateManyWithoutFacilityInput>;
  promoFacilityRules?: Maybe<PromoFacilityRuleUpdateManyWithoutFacilityInput>;
  readonly?: Maybe<BoolFieldUpdateOperationsInput>;
  slug?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stations?: Maybe<StationUpdateManyWithoutFacilityInput>;
  taxRate?: Maybe<FloatFieldUpdateOperationsInput>;
  timeZone?: Maybe<StringFieldUpdateOperationsInput>;
  tookanTeamId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  users?: Maybe<UserUpdateManyWithoutFacilitiesInput>;
  workplaces?: Maybe<WorkplaceUpdateManyWithoutFacilityInput>;
};

export type FacilityUpdateWithoutOrdersInput = {
  CartUpsellItem?: Maybe<CartUpsellItemUpdateManyWithoutFacilityInput>;
  Payment?: Maybe<PaymentUpdateManyWithoutFacilityInput>;
  Tenant?: Maybe<TenantUpdateOneWithoutFacilityInput>;
  cateringDeliveryFee?: Maybe<FloatFieldUpdateOperationsInput>;
  cateringDeliveryFeeTaxRate?: Maybe<FloatFieldUpdateOperationsInput>;
  cateringServiceFee?: Maybe<FloatFieldUpdateOperationsInput>;
  cateringServiceFeeTaxRate?: Maybe<FloatFieldUpdateOperationsInput>;
  cateringServiceFeeType?: Maybe<EnumFeeTypeFieldUpdateOperationsInput>;
  consumerDeliveryFee?: Maybe<FloatFieldUpdateOperationsInput>;
  consumerDeliveryFeeTaxRate?: Maybe<FloatFieldUpdateOperationsInput>;
  consumerServiceFee?: Maybe<FloatFieldUpdateOperationsInput>;
  consumerServiceFeeTaxRate?: Maybe<FloatFieldUpdateOperationsInput>;
  consumerServiceFeeType?: Maybe<EnumFeeTypeFieldUpdateOperationsInput>;
  cravePlatforms?: Maybe<FacilityUpdatecravePlatformsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deliveryEligibleAreas?: Maybe<Scalars['Json']>;
  deliveryLimitInSeconds?: Maybe<IntFieldUpdateOperationsInput>;
  deliveryTimeBuffer?: Maybe<IntFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  enableCatering?: Maybe<BoolFieldUpdateOperationsInput>;
  enableKiosk?: Maybe<BoolFieldUpdateOperationsInput>;
  formattedAddress?: Maybe<StringFieldUpdateOperationsInput>;
  heroImageUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isCateringDeliveryFeeTaxable?: Maybe<BoolFieldUpdateOperationsInput>;
  isCateringServiceFeeTaxable?: Maybe<BoolFieldUpdateOperationsInput>;
  isCmsEnabled?: Maybe<BoolFieldUpdateOperationsInput>;
  isConsumerDeliveryFeeTaxable?: Maybe<BoolFieldUpdateOperationsInput>;
  isConsumerServiceFeeTaxable?: Maybe<BoolFieldUpdateOperationsInput>;
  isFOHPrinterAvailable?: Maybe<BoolFieldUpdateOperationsInput>;
  isGiftCardEnabled?: Maybe<BoolFieldUpdateOperationsInput>;
  kdsType?: Maybe<EnumKdsTypeFieldUpdateOperationsInput>;
  kioskLogoUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  kitchens?: Maybe<KitchenUpdateManyWithoutFacilityInput>;
  lat?: Maybe<FloatFieldUpdateOperationsInput>;
  lng?: Maybe<FloatFieldUpdateOperationsInput>;
  logoUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  menuCategories?: Maybe<MenuCategoryUpdateManyWithoutFacilityInput>;
  menuSchedules?: Maybe<MenuScheduleUpdateManyWithoutFacilityInput>;
  methodOverrides?: Maybe<OrderMethodOverrideUpdateManyWithoutFacilityInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  nashOptionsId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  operationHours?: Maybe<FacilityUpdateoperationHoursInput>;
  orderMethods?: Maybe<FacilityUpdateorderMethodsInput>;
  payrocTerminal?: Maybe<PayrocTerminalUpdateOneWithoutFacilityInput>;
  phone?: Maybe<StringFieldUpdateOperationsInput>;
  pickupTimeBuffer?: Maybe<IntFieldUpdateOperationsInput>;
  placeId?: Maybe<StringFieldUpdateOperationsInput>;
  powerBiReports?: Maybe<PowerBiReportUpdateManyWithoutAvailableToFacilitiesInput>;
  promoBanners?: Maybe<PromoBannerUpdateManyWithoutFacilityInput>;
  promoFacilityRules?: Maybe<PromoFacilityRuleUpdateManyWithoutFacilityInput>;
  readonly?: Maybe<BoolFieldUpdateOperationsInput>;
  slug?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stations?: Maybe<StationUpdateManyWithoutFacilityInput>;
  taxRate?: Maybe<FloatFieldUpdateOperationsInput>;
  timeZone?: Maybe<StringFieldUpdateOperationsInput>;
  tookanTeamId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  users?: Maybe<UserUpdateManyWithoutFacilitiesInput>;
  workplaces?: Maybe<WorkplaceUpdateManyWithoutFacilityInput>;
};

export type FacilityUpdateWithoutPaymentInput = {
  CartUpsellItem?: Maybe<CartUpsellItemUpdateManyWithoutFacilityInput>;
  Tenant?: Maybe<TenantUpdateOneWithoutFacilityInput>;
  cateringDeliveryFee?: Maybe<FloatFieldUpdateOperationsInput>;
  cateringDeliveryFeeTaxRate?: Maybe<FloatFieldUpdateOperationsInput>;
  cateringServiceFee?: Maybe<FloatFieldUpdateOperationsInput>;
  cateringServiceFeeTaxRate?: Maybe<FloatFieldUpdateOperationsInput>;
  cateringServiceFeeType?: Maybe<EnumFeeTypeFieldUpdateOperationsInput>;
  consumerDeliveryFee?: Maybe<FloatFieldUpdateOperationsInput>;
  consumerDeliveryFeeTaxRate?: Maybe<FloatFieldUpdateOperationsInput>;
  consumerServiceFee?: Maybe<FloatFieldUpdateOperationsInput>;
  consumerServiceFeeTaxRate?: Maybe<FloatFieldUpdateOperationsInput>;
  consumerServiceFeeType?: Maybe<EnumFeeTypeFieldUpdateOperationsInput>;
  cravePlatforms?: Maybe<FacilityUpdatecravePlatformsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deliveryEligibleAreas?: Maybe<Scalars['Json']>;
  deliveryLimitInSeconds?: Maybe<IntFieldUpdateOperationsInput>;
  deliveryTimeBuffer?: Maybe<IntFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  enableCatering?: Maybe<BoolFieldUpdateOperationsInput>;
  enableKiosk?: Maybe<BoolFieldUpdateOperationsInput>;
  formattedAddress?: Maybe<StringFieldUpdateOperationsInput>;
  heroImageUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isCateringDeliveryFeeTaxable?: Maybe<BoolFieldUpdateOperationsInput>;
  isCateringServiceFeeTaxable?: Maybe<BoolFieldUpdateOperationsInput>;
  isCmsEnabled?: Maybe<BoolFieldUpdateOperationsInput>;
  isConsumerDeliveryFeeTaxable?: Maybe<BoolFieldUpdateOperationsInput>;
  isConsumerServiceFeeTaxable?: Maybe<BoolFieldUpdateOperationsInput>;
  isFOHPrinterAvailable?: Maybe<BoolFieldUpdateOperationsInput>;
  isGiftCardEnabled?: Maybe<BoolFieldUpdateOperationsInput>;
  kdsType?: Maybe<EnumKdsTypeFieldUpdateOperationsInput>;
  kioskLogoUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  kitchens?: Maybe<KitchenUpdateManyWithoutFacilityInput>;
  lat?: Maybe<FloatFieldUpdateOperationsInput>;
  lng?: Maybe<FloatFieldUpdateOperationsInput>;
  logoUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  menuCategories?: Maybe<MenuCategoryUpdateManyWithoutFacilityInput>;
  menuSchedules?: Maybe<MenuScheduleUpdateManyWithoutFacilityInput>;
  methodOverrides?: Maybe<OrderMethodOverrideUpdateManyWithoutFacilityInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  nashOptionsId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  operationHours?: Maybe<FacilityUpdateoperationHoursInput>;
  orderMethods?: Maybe<FacilityUpdateorderMethodsInput>;
  orders?: Maybe<OrderUpdateManyWithoutFacilityInput>;
  payrocTerminal?: Maybe<PayrocTerminalUpdateOneWithoutFacilityInput>;
  phone?: Maybe<StringFieldUpdateOperationsInput>;
  pickupTimeBuffer?: Maybe<IntFieldUpdateOperationsInput>;
  placeId?: Maybe<StringFieldUpdateOperationsInput>;
  powerBiReports?: Maybe<PowerBiReportUpdateManyWithoutAvailableToFacilitiesInput>;
  promoBanners?: Maybe<PromoBannerUpdateManyWithoutFacilityInput>;
  promoFacilityRules?: Maybe<PromoFacilityRuleUpdateManyWithoutFacilityInput>;
  readonly?: Maybe<BoolFieldUpdateOperationsInput>;
  slug?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stations?: Maybe<StationUpdateManyWithoutFacilityInput>;
  taxRate?: Maybe<FloatFieldUpdateOperationsInput>;
  timeZone?: Maybe<StringFieldUpdateOperationsInput>;
  tookanTeamId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  users?: Maybe<UserUpdateManyWithoutFacilitiesInput>;
  workplaces?: Maybe<WorkplaceUpdateManyWithoutFacilityInput>;
};

export type FacilityUpdateWithoutPowerBiReportsInput = {
  CartUpsellItem?: Maybe<CartUpsellItemUpdateManyWithoutFacilityInput>;
  Payment?: Maybe<PaymentUpdateManyWithoutFacilityInput>;
  Tenant?: Maybe<TenantUpdateOneWithoutFacilityInput>;
  cateringDeliveryFee?: Maybe<FloatFieldUpdateOperationsInput>;
  cateringDeliveryFeeTaxRate?: Maybe<FloatFieldUpdateOperationsInput>;
  cateringServiceFee?: Maybe<FloatFieldUpdateOperationsInput>;
  cateringServiceFeeTaxRate?: Maybe<FloatFieldUpdateOperationsInput>;
  cateringServiceFeeType?: Maybe<EnumFeeTypeFieldUpdateOperationsInput>;
  consumerDeliveryFee?: Maybe<FloatFieldUpdateOperationsInput>;
  consumerDeliveryFeeTaxRate?: Maybe<FloatFieldUpdateOperationsInput>;
  consumerServiceFee?: Maybe<FloatFieldUpdateOperationsInput>;
  consumerServiceFeeTaxRate?: Maybe<FloatFieldUpdateOperationsInput>;
  consumerServiceFeeType?: Maybe<EnumFeeTypeFieldUpdateOperationsInput>;
  cravePlatforms?: Maybe<FacilityUpdatecravePlatformsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deliveryEligibleAreas?: Maybe<Scalars['Json']>;
  deliveryLimitInSeconds?: Maybe<IntFieldUpdateOperationsInput>;
  deliveryTimeBuffer?: Maybe<IntFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  enableCatering?: Maybe<BoolFieldUpdateOperationsInput>;
  enableKiosk?: Maybe<BoolFieldUpdateOperationsInput>;
  formattedAddress?: Maybe<StringFieldUpdateOperationsInput>;
  heroImageUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isCateringDeliveryFeeTaxable?: Maybe<BoolFieldUpdateOperationsInput>;
  isCateringServiceFeeTaxable?: Maybe<BoolFieldUpdateOperationsInput>;
  isCmsEnabled?: Maybe<BoolFieldUpdateOperationsInput>;
  isConsumerDeliveryFeeTaxable?: Maybe<BoolFieldUpdateOperationsInput>;
  isConsumerServiceFeeTaxable?: Maybe<BoolFieldUpdateOperationsInput>;
  isFOHPrinterAvailable?: Maybe<BoolFieldUpdateOperationsInput>;
  isGiftCardEnabled?: Maybe<BoolFieldUpdateOperationsInput>;
  kdsType?: Maybe<EnumKdsTypeFieldUpdateOperationsInput>;
  kioskLogoUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  kitchens?: Maybe<KitchenUpdateManyWithoutFacilityInput>;
  lat?: Maybe<FloatFieldUpdateOperationsInput>;
  lng?: Maybe<FloatFieldUpdateOperationsInput>;
  logoUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  menuCategories?: Maybe<MenuCategoryUpdateManyWithoutFacilityInput>;
  menuSchedules?: Maybe<MenuScheduleUpdateManyWithoutFacilityInput>;
  methodOverrides?: Maybe<OrderMethodOverrideUpdateManyWithoutFacilityInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  nashOptionsId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  operationHours?: Maybe<FacilityUpdateoperationHoursInput>;
  orderMethods?: Maybe<FacilityUpdateorderMethodsInput>;
  orders?: Maybe<OrderUpdateManyWithoutFacilityInput>;
  payrocTerminal?: Maybe<PayrocTerminalUpdateOneWithoutFacilityInput>;
  phone?: Maybe<StringFieldUpdateOperationsInput>;
  pickupTimeBuffer?: Maybe<IntFieldUpdateOperationsInput>;
  placeId?: Maybe<StringFieldUpdateOperationsInput>;
  promoBanners?: Maybe<PromoBannerUpdateManyWithoutFacilityInput>;
  promoFacilityRules?: Maybe<PromoFacilityRuleUpdateManyWithoutFacilityInput>;
  readonly?: Maybe<BoolFieldUpdateOperationsInput>;
  slug?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stations?: Maybe<StationUpdateManyWithoutFacilityInput>;
  taxRate?: Maybe<FloatFieldUpdateOperationsInput>;
  timeZone?: Maybe<StringFieldUpdateOperationsInput>;
  tookanTeamId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  users?: Maybe<UserUpdateManyWithoutFacilitiesInput>;
  workplaces?: Maybe<WorkplaceUpdateManyWithoutFacilityInput>;
};

export type FacilityUpdateWithoutPromoBannersInput = {
  CartUpsellItem?: Maybe<CartUpsellItemUpdateManyWithoutFacilityInput>;
  Payment?: Maybe<PaymentUpdateManyWithoutFacilityInput>;
  Tenant?: Maybe<TenantUpdateOneWithoutFacilityInput>;
  cateringDeliveryFee?: Maybe<FloatFieldUpdateOperationsInput>;
  cateringDeliveryFeeTaxRate?: Maybe<FloatFieldUpdateOperationsInput>;
  cateringServiceFee?: Maybe<FloatFieldUpdateOperationsInput>;
  cateringServiceFeeTaxRate?: Maybe<FloatFieldUpdateOperationsInput>;
  cateringServiceFeeType?: Maybe<EnumFeeTypeFieldUpdateOperationsInput>;
  consumerDeliveryFee?: Maybe<FloatFieldUpdateOperationsInput>;
  consumerDeliveryFeeTaxRate?: Maybe<FloatFieldUpdateOperationsInput>;
  consumerServiceFee?: Maybe<FloatFieldUpdateOperationsInput>;
  consumerServiceFeeTaxRate?: Maybe<FloatFieldUpdateOperationsInput>;
  consumerServiceFeeType?: Maybe<EnumFeeTypeFieldUpdateOperationsInput>;
  cravePlatforms?: Maybe<FacilityUpdatecravePlatformsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deliveryEligibleAreas?: Maybe<Scalars['Json']>;
  deliveryLimitInSeconds?: Maybe<IntFieldUpdateOperationsInput>;
  deliveryTimeBuffer?: Maybe<IntFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  enableCatering?: Maybe<BoolFieldUpdateOperationsInput>;
  enableKiosk?: Maybe<BoolFieldUpdateOperationsInput>;
  formattedAddress?: Maybe<StringFieldUpdateOperationsInput>;
  heroImageUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isCateringDeliveryFeeTaxable?: Maybe<BoolFieldUpdateOperationsInput>;
  isCateringServiceFeeTaxable?: Maybe<BoolFieldUpdateOperationsInput>;
  isCmsEnabled?: Maybe<BoolFieldUpdateOperationsInput>;
  isConsumerDeliveryFeeTaxable?: Maybe<BoolFieldUpdateOperationsInput>;
  isConsumerServiceFeeTaxable?: Maybe<BoolFieldUpdateOperationsInput>;
  isFOHPrinterAvailable?: Maybe<BoolFieldUpdateOperationsInput>;
  isGiftCardEnabled?: Maybe<BoolFieldUpdateOperationsInput>;
  kdsType?: Maybe<EnumKdsTypeFieldUpdateOperationsInput>;
  kioskLogoUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  kitchens?: Maybe<KitchenUpdateManyWithoutFacilityInput>;
  lat?: Maybe<FloatFieldUpdateOperationsInput>;
  lng?: Maybe<FloatFieldUpdateOperationsInput>;
  logoUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  menuCategories?: Maybe<MenuCategoryUpdateManyWithoutFacilityInput>;
  menuSchedules?: Maybe<MenuScheduleUpdateManyWithoutFacilityInput>;
  methodOverrides?: Maybe<OrderMethodOverrideUpdateManyWithoutFacilityInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  nashOptionsId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  operationHours?: Maybe<FacilityUpdateoperationHoursInput>;
  orderMethods?: Maybe<FacilityUpdateorderMethodsInput>;
  orders?: Maybe<OrderUpdateManyWithoutFacilityInput>;
  payrocTerminal?: Maybe<PayrocTerminalUpdateOneWithoutFacilityInput>;
  phone?: Maybe<StringFieldUpdateOperationsInput>;
  pickupTimeBuffer?: Maybe<IntFieldUpdateOperationsInput>;
  placeId?: Maybe<StringFieldUpdateOperationsInput>;
  powerBiReports?: Maybe<PowerBiReportUpdateManyWithoutAvailableToFacilitiesInput>;
  promoFacilityRules?: Maybe<PromoFacilityRuleUpdateManyWithoutFacilityInput>;
  readonly?: Maybe<BoolFieldUpdateOperationsInput>;
  slug?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stations?: Maybe<StationUpdateManyWithoutFacilityInput>;
  taxRate?: Maybe<FloatFieldUpdateOperationsInput>;
  timeZone?: Maybe<StringFieldUpdateOperationsInput>;
  tookanTeamId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  users?: Maybe<UserUpdateManyWithoutFacilitiesInput>;
  workplaces?: Maybe<WorkplaceUpdateManyWithoutFacilityInput>;
};

export type FacilityUpdateWithoutPromoFacilityRulesInput = {
  CartUpsellItem?: Maybe<CartUpsellItemUpdateManyWithoutFacilityInput>;
  Payment?: Maybe<PaymentUpdateManyWithoutFacilityInput>;
  Tenant?: Maybe<TenantUpdateOneWithoutFacilityInput>;
  cateringDeliveryFee?: Maybe<FloatFieldUpdateOperationsInput>;
  cateringDeliveryFeeTaxRate?: Maybe<FloatFieldUpdateOperationsInput>;
  cateringServiceFee?: Maybe<FloatFieldUpdateOperationsInput>;
  cateringServiceFeeTaxRate?: Maybe<FloatFieldUpdateOperationsInput>;
  cateringServiceFeeType?: Maybe<EnumFeeTypeFieldUpdateOperationsInput>;
  consumerDeliveryFee?: Maybe<FloatFieldUpdateOperationsInput>;
  consumerDeliveryFeeTaxRate?: Maybe<FloatFieldUpdateOperationsInput>;
  consumerServiceFee?: Maybe<FloatFieldUpdateOperationsInput>;
  consumerServiceFeeTaxRate?: Maybe<FloatFieldUpdateOperationsInput>;
  consumerServiceFeeType?: Maybe<EnumFeeTypeFieldUpdateOperationsInput>;
  cravePlatforms?: Maybe<FacilityUpdatecravePlatformsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deliveryEligibleAreas?: Maybe<Scalars['Json']>;
  deliveryLimitInSeconds?: Maybe<IntFieldUpdateOperationsInput>;
  deliveryTimeBuffer?: Maybe<IntFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  enableCatering?: Maybe<BoolFieldUpdateOperationsInput>;
  enableKiosk?: Maybe<BoolFieldUpdateOperationsInput>;
  formattedAddress?: Maybe<StringFieldUpdateOperationsInput>;
  heroImageUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isCateringDeliveryFeeTaxable?: Maybe<BoolFieldUpdateOperationsInput>;
  isCateringServiceFeeTaxable?: Maybe<BoolFieldUpdateOperationsInput>;
  isCmsEnabled?: Maybe<BoolFieldUpdateOperationsInput>;
  isConsumerDeliveryFeeTaxable?: Maybe<BoolFieldUpdateOperationsInput>;
  isConsumerServiceFeeTaxable?: Maybe<BoolFieldUpdateOperationsInput>;
  isFOHPrinterAvailable?: Maybe<BoolFieldUpdateOperationsInput>;
  isGiftCardEnabled?: Maybe<BoolFieldUpdateOperationsInput>;
  kdsType?: Maybe<EnumKdsTypeFieldUpdateOperationsInput>;
  kioskLogoUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  kitchens?: Maybe<KitchenUpdateManyWithoutFacilityInput>;
  lat?: Maybe<FloatFieldUpdateOperationsInput>;
  lng?: Maybe<FloatFieldUpdateOperationsInput>;
  logoUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  menuCategories?: Maybe<MenuCategoryUpdateManyWithoutFacilityInput>;
  menuSchedules?: Maybe<MenuScheduleUpdateManyWithoutFacilityInput>;
  methodOverrides?: Maybe<OrderMethodOverrideUpdateManyWithoutFacilityInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  nashOptionsId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  operationHours?: Maybe<FacilityUpdateoperationHoursInput>;
  orderMethods?: Maybe<FacilityUpdateorderMethodsInput>;
  orders?: Maybe<OrderUpdateManyWithoutFacilityInput>;
  payrocTerminal?: Maybe<PayrocTerminalUpdateOneWithoutFacilityInput>;
  phone?: Maybe<StringFieldUpdateOperationsInput>;
  pickupTimeBuffer?: Maybe<IntFieldUpdateOperationsInput>;
  placeId?: Maybe<StringFieldUpdateOperationsInput>;
  powerBiReports?: Maybe<PowerBiReportUpdateManyWithoutAvailableToFacilitiesInput>;
  promoBanners?: Maybe<PromoBannerUpdateManyWithoutFacilityInput>;
  readonly?: Maybe<BoolFieldUpdateOperationsInput>;
  slug?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stations?: Maybe<StationUpdateManyWithoutFacilityInput>;
  taxRate?: Maybe<FloatFieldUpdateOperationsInput>;
  timeZone?: Maybe<StringFieldUpdateOperationsInput>;
  tookanTeamId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  users?: Maybe<UserUpdateManyWithoutFacilitiesInput>;
  workplaces?: Maybe<WorkplaceUpdateManyWithoutFacilityInput>;
};

export type FacilityUpdateWithoutStationsInput = {
  CartUpsellItem?: Maybe<CartUpsellItemUpdateManyWithoutFacilityInput>;
  Payment?: Maybe<PaymentUpdateManyWithoutFacilityInput>;
  Tenant?: Maybe<TenantUpdateOneWithoutFacilityInput>;
  cateringDeliveryFee?: Maybe<FloatFieldUpdateOperationsInput>;
  cateringDeliveryFeeTaxRate?: Maybe<FloatFieldUpdateOperationsInput>;
  cateringServiceFee?: Maybe<FloatFieldUpdateOperationsInput>;
  cateringServiceFeeTaxRate?: Maybe<FloatFieldUpdateOperationsInput>;
  cateringServiceFeeType?: Maybe<EnumFeeTypeFieldUpdateOperationsInput>;
  consumerDeliveryFee?: Maybe<FloatFieldUpdateOperationsInput>;
  consumerDeliveryFeeTaxRate?: Maybe<FloatFieldUpdateOperationsInput>;
  consumerServiceFee?: Maybe<FloatFieldUpdateOperationsInput>;
  consumerServiceFeeTaxRate?: Maybe<FloatFieldUpdateOperationsInput>;
  consumerServiceFeeType?: Maybe<EnumFeeTypeFieldUpdateOperationsInput>;
  cravePlatforms?: Maybe<FacilityUpdatecravePlatformsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deliveryEligibleAreas?: Maybe<Scalars['Json']>;
  deliveryLimitInSeconds?: Maybe<IntFieldUpdateOperationsInput>;
  deliveryTimeBuffer?: Maybe<IntFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  enableCatering?: Maybe<BoolFieldUpdateOperationsInput>;
  enableKiosk?: Maybe<BoolFieldUpdateOperationsInput>;
  formattedAddress?: Maybe<StringFieldUpdateOperationsInput>;
  heroImageUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isCateringDeliveryFeeTaxable?: Maybe<BoolFieldUpdateOperationsInput>;
  isCateringServiceFeeTaxable?: Maybe<BoolFieldUpdateOperationsInput>;
  isCmsEnabled?: Maybe<BoolFieldUpdateOperationsInput>;
  isConsumerDeliveryFeeTaxable?: Maybe<BoolFieldUpdateOperationsInput>;
  isConsumerServiceFeeTaxable?: Maybe<BoolFieldUpdateOperationsInput>;
  isFOHPrinterAvailable?: Maybe<BoolFieldUpdateOperationsInput>;
  isGiftCardEnabled?: Maybe<BoolFieldUpdateOperationsInput>;
  kdsType?: Maybe<EnumKdsTypeFieldUpdateOperationsInput>;
  kioskLogoUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  kitchens?: Maybe<KitchenUpdateManyWithoutFacilityInput>;
  lat?: Maybe<FloatFieldUpdateOperationsInput>;
  lng?: Maybe<FloatFieldUpdateOperationsInput>;
  logoUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  menuCategories?: Maybe<MenuCategoryUpdateManyWithoutFacilityInput>;
  menuSchedules?: Maybe<MenuScheduleUpdateManyWithoutFacilityInput>;
  methodOverrides?: Maybe<OrderMethodOverrideUpdateManyWithoutFacilityInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  nashOptionsId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  operationHours?: Maybe<FacilityUpdateoperationHoursInput>;
  orderMethods?: Maybe<FacilityUpdateorderMethodsInput>;
  orders?: Maybe<OrderUpdateManyWithoutFacilityInput>;
  payrocTerminal?: Maybe<PayrocTerminalUpdateOneWithoutFacilityInput>;
  phone?: Maybe<StringFieldUpdateOperationsInput>;
  pickupTimeBuffer?: Maybe<IntFieldUpdateOperationsInput>;
  placeId?: Maybe<StringFieldUpdateOperationsInput>;
  powerBiReports?: Maybe<PowerBiReportUpdateManyWithoutAvailableToFacilitiesInput>;
  promoBanners?: Maybe<PromoBannerUpdateManyWithoutFacilityInput>;
  promoFacilityRules?: Maybe<PromoFacilityRuleUpdateManyWithoutFacilityInput>;
  readonly?: Maybe<BoolFieldUpdateOperationsInput>;
  slug?: Maybe<NullableStringFieldUpdateOperationsInput>;
  taxRate?: Maybe<FloatFieldUpdateOperationsInput>;
  timeZone?: Maybe<StringFieldUpdateOperationsInput>;
  tookanTeamId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  users?: Maybe<UserUpdateManyWithoutFacilitiesInput>;
  workplaces?: Maybe<WorkplaceUpdateManyWithoutFacilityInput>;
};

export type FacilityUpdateWithoutTenantInput = {
  CartUpsellItem?: Maybe<CartUpsellItemUpdateManyWithoutFacilityInput>;
  Payment?: Maybe<PaymentUpdateManyWithoutFacilityInput>;
  cateringDeliveryFee?: Maybe<FloatFieldUpdateOperationsInput>;
  cateringDeliveryFeeTaxRate?: Maybe<FloatFieldUpdateOperationsInput>;
  cateringServiceFee?: Maybe<FloatFieldUpdateOperationsInput>;
  cateringServiceFeeTaxRate?: Maybe<FloatFieldUpdateOperationsInput>;
  cateringServiceFeeType?: Maybe<EnumFeeTypeFieldUpdateOperationsInput>;
  consumerDeliveryFee?: Maybe<FloatFieldUpdateOperationsInput>;
  consumerDeliveryFeeTaxRate?: Maybe<FloatFieldUpdateOperationsInput>;
  consumerServiceFee?: Maybe<FloatFieldUpdateOperationsInput>;
  consumerServiceFeeTaxRate?: Maybe<FloatFieldUpdateOperationsInput>;
  consumerServiceFeeType?: Maybe<EnumFeeTypeFieldUpdateOperationsInput>;
  cravePlatforms?: Maybe<FacilityUpdatecravePlatformsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deliveryEligibleAreas?: Maybe<Scalars['Json']>;
  deliveryLimitInSeconds?: Maybe<IntFieldUpdateOperationsInput>;
  deliveryTimeBuffer?: Maybe<IntFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  enableCatering?: Maybe<BoolFieldUpdateOperationsInput>;
  enableKiosk?: Maybe<BoolFieldUpdateOperationsInput>;
  formattedAddress?: Maybe<StringFieldUpdateOperationsInput>;
  heroImageUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isCateringDeliveryFeeTaxable?: Maybe<BoolFieldUpdateOperationsInput>;
  isCateringServiceFeeTaxable?: Maybe<BoolFieldUpdateOperationsInput>;
  isCmsEnabled?: Maybe<BoolFieldUpdateOperationsInput>;
  isConsumerDeliveryFeeTaxable?: Maybe<BoolFieldUpdateOperationsInput>;
  isConsumerServiceFeeTaxable?: Maybe<BoolFieldUpdateOperationsInput>;
  isFOHPrinterAvailable?: Maybe<BoolFieldUpdateOperationsInput>;
  isGiftCardEnabled?: Maybe<BoolFieldUpdateOperationsInput>;
  kdsType?: Maybe<EnumKdsTypeFieldUpdateOperationsInput>;
  kioskLogoUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  kitchens?: Maybe<KitchenUpdateManyWithoutFacilityInput>;
  lat?: Maybe<FloatFieldUpdateOperationsInput>;
  lng?: Maybe<FloatFieldUpdateOperationsInput>;
  logoUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  menuCategories?: Maybe<MenuCategoryUpdateManyWithoutFacilityInput>;
  menuSchedules?: Maybe<MenuScheduleUpdateManyWithoutFacilityInput>;
  methodOverrides?: Maybe<OrderMethodOverrideUpdateManyWithoutFacilityInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  nashOptionsId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  operationHours?: Maybe<FacilityUpdateoperationHoursInput>;
  orderMethods?: Maybe<FacilityUpdateorderMethodsInput>;
  orders?: Maybe<OrderUpdateManyWithoutFacilityInput>;
  payrocTerminal?: Maybe<PayrocTerminalUpdateOneWithoutFacilityInput>;
  phone?: Maybe<StringFieldUpdateOperationsInput>;
  pickupTimeBuffer?: Maybe<IntFieldUpdateOperationsInput>;
  placeId?: Maybe<StringFieldUpdateOperationsInput>;
  powerBiReports?: Maybe<PowerBiReportUpdateManyWithoutAvailableToFacilitiesInput>;
  promoBanners?: Maybe<PromoBannerUpdateManyWithoutFacilityInput>;
  promoFacilityRules?: Maybe<PromoFacilityRuleUpdateManyWithoutFacilityInput>;
  readonly?: Maybe<BoolFieldUpdateOperationsInput>;
  slug?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stations?: Maybe<StationUpdateManyWithoutFacilityInput>;
  taxRate?: Maybe<FloatFieldUpdateOperationsInput>;
  timeZone?: Maybe<StringFieldUpdateOperationsInput>;
  tookanTeamId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  users?: Maybe<UserUpdateManyWithoutFacilitiesInput>;
  workplaces?: Maybe<WorkplaceUpdateManyWithoutFacilityInput>;
};

export type FacilityUpdateWithoutUsersInput = {
  CartUpsellItem?: Maybe<CartUpsellItemUpdateManyWithoutFacilityInput>;
  Payment?: Maybe<PaymentUpdateManyWithoutFacilityInput>;
  Tenant?: Maybe<TenantUpdateOneWithoutFacilityInput>;
  cateringDeliveryFee?: Maybe<FloatFieldUpdateOperationsInput>;
  cateringDeliveryFeeTaxRate?: Maybe<FloatFieldUpdateOperationsInput>;
  cateringServiceFee?: Maybe<FloatFieldUpdateOperationsInput>;
  cateringServiceFeeTaxRate?: Maybe<FloatFieldUpdateOperationsInput>;
  cateringServiceFeeType?: Maybe<EnumFeeTypeFieldUpdateOperationsInput>;
  consumerDeliveryFee?: Maybe<FloatFieldUpdateOperationsInput>;
  consumerDeliveryFeeTaxRate?: Maybe<FloatFieldUpdateOperationsInput>;
  consumerServiceFee?: Maybe<FloatFieldUpdateOperationsInput>;
  consumerServiceFeeTaxRate?: Maybe<FloatFieldUpdateOperationsInput>;
  consumerServiceFeeType?: Maybe<EnumFeeTypeFieldUpdateOperationsInput>;
  cravePlatforms?: Maybe<FacilityUpdatecravePlatformsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deliveryEligibleAreas?: Maybe<Scalars['Json']>;
  deliveryLimitInSeconds?: Maybe<IntFieldUpdateOperationsInput>;
  deliveryTimeBuffer?: Maybe<IntFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  enableCatering?: Maybe<BoolFieldUpdateOperationsInput>;
  enableKiosk?: Maybe<BoolFieldUpdateOperationsInput>;
  formattedAddress?: Maybe<StringFieldUpdateOperationsInput>;
  heroImageUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isCateringDeliveryFeeTaxable?: Maybe<BoolFieldUpdateOperationsInput>;
  isCateringServiceFeeTaxable?: Maybe<BoolFieldUpdateOperationsInput>;
  isCmsEnabled?: Maybe<BoolFieldUpdateOperationsInput>;
  isConsumerDeliveryFeeTaxable?: Maybe<BoolFieldUpdateOperationsInput>;
  isConsumerServiceFeeTaxable?: Maybe<BoolFieldUpdateOperationsInput>;
  isFOHPrinterAvailable?: Maybe<BoolFieldUpdateOperationsInput>;
  isGiftCardEnabled?: Maybe<BoolFieldUpdateOperationsInput>;
  kdsType?: Maybe<EnumKdsTypeFieldUpdateOperationsInput>;
  kioskLogoUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  kitchens?: Maybe<KitchenUpdateManyWithoutFacilityInput>;
  lat?: Maybe<FloatFieldUpdateOperationsInput>;
  lng?: Maybe<FloatFieldUpdateOperationsInput>;
  logoUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  menuCategories?: Maybe<MenuCategoryUpdateManyWithoutFacilityInput>;
  menuSchedules?: Maybe<MenuScheduleUpdateManyWithoutFacilityInput>;
  methodOverrides?: Maybe<OrderMethodOverrideUpdateManyWithoutFacilityInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  nashOptionsId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  operationHours?: Maybe<FacilityUpdateoperationHoursInput>;
  orderMethods?: Maybe<FacilityUpdateorderMethodsInput>;
  orders?: Maybe<OrderUpdateManyWithoutFacilityInput>;
  payrocTerminal?: Maybe<PayrocTerminalUpdateOneWithoutFacilityInput>;
  phone?: Maybe<StringFieldUpdateOperationsInput>;
  pickupTimeBuffer?: Maybe<IntFieldUpdateOperationsInput>;
  placeId?: Maybe<StringFieldUpdateOperationsInput>;
  powerBiReports?: Maybe<PowerBiReportUpdateManyWithoutAvailableToFacilitiesInput>;
  promoBanners?: Maybe<PromoBannerUpdateManyWithoutFacilityInput>;
  promoFacilityRules?: Maybe<PromoFacilityRuleUpdateManyWithoutFacilityInput>;
  readonly?: Maybe<BoolFieldUpdateOperationsInput>;
  slug?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stations?: Maybe<StationUpdateManyWithoutFacilityInput>;
  taxRate?: Maybe<FloatFieldUpdateOperationsInput>;
  timeZone?: Maybe<StringFieldUpdateOperationsInput>;
  tookanTeamId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  workplaces?: Maybe<WorkplaceUpdateManyWithoutFacilityInput>;
};

export type FacilityUpdateWithoutWorkplacesInput = {
  CartUpsellItem?: Maybe<CartUpsellItemUpdateManyWithoutFacilityInput>;
  Payment?: Maybe<PaymentUpdateManyWithoutFacilityInput>;
  Tenant?: Maybe<TenantUpdateOneWithoutFacilityInput>;
  cateringDeliveryFee?: Maybe<FloatFieldUpdateOperationsInput>;
  cateringDeliveryFeeTaxRate?: Maybe<FloatFieldUpdateOperationsInput>;
  cateringServiceFee?: Maybe<FloatFieldUpdateOperationsInput>;
  cateringServiceFeeTaxRate?: Maybe<FloatFieldUpdateOperationsInput>;
  cateringServiceFeeType?: Maybe<EnumFeeTypeFieldUpdateOperationsInput>;
  consumerDeliveryFee?: Maybe<FloatFieldUpdateOperationsInput>;
  consumerDeliveryFeeTaxRate?: Maybe<FloatFieldUpdateOperationsInput>;
  consumerServiceFee?: Maybe<FloatFieldUpdateOperationsInput>;
  consumerServiceFeeTaxRate?: Maybe<FloatFieldUpdateOperationsInput>;
  consumerServiceFeeType?: Maybe<EnumFeeTypeFieldUpdateOperationsInput>;
  cravePlatforms?: Maybe<FacilityUpdatecravePlatformsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deliveryEligibleAreas?: Maybe<Scalars['Json']>;
  deliveryLimitInSeconds?: Maybe<IntFieldUpdateOperationsInput>;
  deliveryTimeBuffer?: Maybe<IntFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  enableCatering?: Maybe<BoolFieldUpdateOperationsInput>;
  enableKiosk?: Maybe<BoolFieldUpdateOperationsInput>;
  formattedAddress?: Maybe<StringFieldUpdateOperationsInput>;
  heroImageUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isCateringDeliveryFeeTaxable?: Maybe<BoolFieldUpdateOperationsInput>;
  isCateringServiceFeeTaxable?: Maybe<BoolFieldUpdateOperationsInput>;
  isCmsEnabled?: Maybe<BoolFieldUpdateOperationsInput>;
  isConsumerDeliveryFeeTaxable?: Maybe<BoolFieldUpdateOperationsInput>;
  isConsumerServiceFeeTaxable?: Maybe<BoolFieldUpdateOperationsInput>;
  isFOHPrinterAvailable?: Maybe<BoolFieldUpdateOperationsInput>;
  isGiftCardEnabled?: Maybe<BoolFieldUpdateOperationsInput>;
  kdsType?: Maybe<EnumKdsTypeFieldUpdateOperationsInput>;
  kioskLogoUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  kitchens?: Maybe<KitchenUpdateManyWithoutFacilityInput>;
  lat?: Maybe<FloatFieldUpdateOperationsInput>;
  lng?: Maybe<FloatFieldUpdateOperationsInput>;
  logoUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  menuCategories?: Maybe<MenuCategoryUpdateManyWithoutFacilityInput>;
  menuSchedules?: Maybe<MenuScheduleUpdateManyWithoutFacilityInput>;
  methodOverrides?: Maybe<OrderMethodOverrideUpdateManyWithoutFacilityInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  nashOptionsId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  operationHours?: Maybe<FacilityUpdateoperationHoursInput>;
  orderMethods?: Maybe<FacilityUpdateorderMethodsInput>;
  orders?: Maybe<OrderUpdateManyWithoutFacilityInput>;
  payrocTerminal?: Maybe<PayrocTerminalUpdateOneWithoutFacilityInput>;
  phone?: Maybe<StringFieldUpdateOperationsInput>;
  pickupTimeBuffer?: Maybe<IntFieldUpdateOperationsInput>;
  placeId?: Maybe<StringFieldUpdateOperationsInput>;
  powerBiReports?: Maybe<PowerBiReportUpdateManyWithoutAvailableToFacilitiesInput>;
  promoBanners?: Maybe<PromoBannerUpdateManyWithoutFacilityInput>;
  promoFacilityRules?: Maybe<PromoFacilityRuleUpdateManyWithoutFacilityInput>;
  readonly?: Maybe<BoolFieldUpdateOperationsInput>;
  slug?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stations?: Maybe<StationUpdateManyWithoutFacilityInput>;
  taxRate?: Maybe<FloatFieldUpdateOperationsInput>;
  timeZone?: Maybe<StringFieldUpdateOperationsInput>;
  tookanTeamId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  users?: Maybe<UserUpdateManyWithoutFacilitiesInput>;
};

export type FacilityUpdatecravePlatformsInput = {
  push?: Maybe<Scalars['String']>;
  set?: Maybe<Array<Scalars['String']>>;
};

export type FacilityUpdateoperationHoursInput = {
  push?: Maybe<Scalars['Json']>;
  set?: Maybe<Array<Scalars['Json']>>;
};

export type FacilityUpdateorderMethodsInput = {
  push?: Maybe<OrderMethod>;
  set?: Maybe<Array<OrderMethod>>;
};

export type FacilityUpsertWithWhereUniqueWithoutPowerBiReportsInput = {
  create: FacilityCreateWithoutPowerBiReportsInput;
  update: FacilityUpdateWithoutPowerBiReportsInput;
  where: FacilityWhereUniqueInput;
};

export type FacilityUpsertWithWhereUniqueWithoutTenantInput = {
  create: FacilityCreateWithoutTenantInput;
  update: FacilityUpdateWithoutTenantInput;
  where: FacilityWhereUniqueInput;
};

export type FacilityUpsertWithWhereUniqueWithoutUsersInput = {
  create: FacilityCreateWithoutUsersInput;
  update: FacilityUpdateWithoutUsersInput;
  where: FacilityWhereUniqueInput;
};

export type FacilityUpsertWithoutCartUpsellItemInput = {
  create: FacilityCreateWithoutCartUpsellItemInput;
  update: FacilityUpdateWithoutCartUpsellItemInput;
};

export type FacilityUpsertWithoutKitchensInput = {
  create: FacilityCreateWithoutKitchensInput;
  update: FacilityUpdateWithoutKitchensInput;
};

export type FacilityUpsertWithoutMenuCategoriesInput = {
  create: FacilityCreateWithoutMenuCategoriesInput;
  update: FacilityUpdateWithoutMenuCategoriesInput;
};

export type FacilityUpsertWithoutMenuSchedulesInput = {
  create: FacilityCreateWithoutMenuSchedulesInput;
  update: FacilityUpdateWithoutMenuSchedulesInput;
};

export type FacilityUpsertWithoutOrdersInput = {
  create: FacilityCreateWithoutOrdersInput;
  update: FacilityUpdateWithoutOrdersInput;
};

export type FacilityUpsertWithoutPaymentInput = {
  create: FacilityCreateWithoutPaymentInput;
  update: FacilityUpdateWithoutPaymentInput;
};

export type FacilityUpsertWithoutPromoBannersInput = {
  create: FacilityCreateWithoutPromoBannersInput;
  update: FacilityUpdateWithoutPromoBannersInput;
};

export type FacilityUpsertWithoutPromoFacilityRulesInput = {
  create: FacilityCreateWithoutPromoFacilityRulesInput;
  update: FacilityUpdateWithoutPromoFacilityRulesInput;
};

export type FacilityUpsertWithoutStationsInput = {
  create: FacilityCreateWithoutStationsInput;
  update: FacilityUpdateWithoutStationsInput;
};

export type FacilityUpsertWithoutWorkplacesInput = {
  create: FacilityCreateWithoutWorkplacesInput;
  update: FacilityUpdateWithoutWorkplacesInput;
};

export type FacilityWhereInput = {
  AND?: Maybe<Array<FacilityWhereInput>>;
  CartUpsellItem?: Maybe<CartUpsellItemListRelationFilter>;
  NOT?: Maybe<Array<FacilityWhereInput>>;
  OR?: Maybe<Array<FacilityWhereInput>>;
  Payment?: Maybe<PaymentListRelationFilter>;
  Tenant?: Maybe<TenantWhereInput>;
  cateringDeliveryFee?: Maybe<FloatFilter>;
  cateringDeliveryFeeTaxRate?: Maybe<FloatFilter>;
  cateringServiceFee?: Maybe<FloatFilter>;
  cateringServiceFeeTaxRate?: Maybe<FloatFilter>;
  cateringServiceFeeType?: Maybe<EnumFeeTypeFilter>;
  consumerDeliveryFee?: Maybe<FloatFilter>;
  consumerDeliveryFeeTaxRate?: Maybe<FloatFilter>;
  consumerServiceFee?: Maybe<FloatFilter>;
  consumerServiceFeeTaxRate?: Maybe<FloatFilter>;
  consumerServiceFeeType?: Maybe<EnumFeeTypeFilter>;
  cravePlatforms?: Maybe<StringNullableListFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  deliveryLimitInSeconds?: Maybe<IntFilter>;
  deliveryTimeBuffer?: Maybe<IntFilter>;
  email?: Maybe<StringFilter>;
  enableCatering?: Maybe<BoolFilter>;
  enableKiosk?: Maybe<BoolFilter>;
  formattedAddress?: Maybe<StringFilter>;
  heroImageUrl?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  isCateringDeliveryFeeTaxable?: Maybe<BoolFilter>;
  isCateringServiceFeeTaxable?: Maybe<BoolFilter>;
  isCmsEnabled?: Maybe<BoolFilter>;
  isConsumerDeliveryFeeTaxable?: Maybe<BoolFilter>;
  isConsumerServiceFeeTaxable?: Maybe<BoolFilter>;
  isFOHPrinterAvailable?: Maybe<BoolFilter>;
  isGiftCardEnabled?: Maybe<BoolFilter>;
  kdsType?: Maybe<EnumKdsTypeFilter>;
  kioskLogoUrl?: Maybe<StringNullableFilter>;
  kitchens?: Maybe<KitchenListRelationFilter>;
  lat?: Maybe<FloatFilter>;
  lng?: Maybe<FloatFilter>;
  logoUrl?: Maybe<StringNullableFilter>;
  menuCategories?: Maybe<MenuCategoryListRelationFilter>;
  menuSchedules?: Maybe<MenuScheduleListRelationFilter>;
  methodOverrides?: Maybe<OrderMethodOverrideListRelationFilter>;
  name?: Maybe<StringFilter>;
  nashOptionsId?: Maybe<StringNullableFilter>;
  operationHours?: Maybe<JsonNullableListFilter>;
  orderMethods?: Maybe<EnumOrderMethodNullableListFilter>;
  orders?: Maybe<OrderListRelationFilter>;
  payrocTerminal?: Maybe<PayrocTerminalWhereInput>;
  payrocTerminalId?: Maybe<StringNullableFilter>;
  phone?: Maybe<StringFilter>;
  pickupTimeBuffer?: Maybe<IntFilter>;
  placeId?: Maybe<StringFilter>;
  powerBiReports?: Maybe<PowerBiReportListRelationFilter>;
  promoBanners?: Maybe<PromoBannerListRelationFilter>;
  promoFacilityRules?: Maybe<PromoFacilityRuleListRelationFilter>;
  readonly?: Maybe<BoolFilter>;
  slug?: Maybe<StringNullableFilter>;
  stations?: Maybe<StationListRelationFilter>;
  taxRate?: Maybe<FloatFilter>;
  tenantId?: Maybe<StringNullableFilter>;
  timeZone?: Maybe<StringFilter>;
  tookanTeamId?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  users?: Maybe<UserListRelationFilter>;
  workplaces?: Maybe<WorkplaceListRelationFilter>;
};

export type FacilityWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  slug_tenantId?: Maybe<FacilitySlugTenantIdCompoundUniqueInput>;
};

export enum FeeType {
  Flat = 'FLAT',
  Percentage = 'PERCENTAGE'
}

export enum FeeTypeEnum {
  Flat = 'FLAT',
  Percentage = 'PERCENTAGE'
}

export type FloatFieldUpdateOperationsInput = {
  decrement?: Maybe<Scalars['Float']>;
  divide?: Maybe<Scalars['Float']>;
  increment?: Maybe<Scalars['Float']>;
  multiply?: Maybe<Scalars['Float']>;
  set?: Maybe<Scalars['Float']>;
};

export type FloatFilter = {
  equals?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatFilter>;
  notIn?: Maybe<Array<Scalars['Float']>>;
};

export type FloatNullableFilter = {
  equals?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatNullableFilter>;
  notIn?: Maybe<Array<Scalars['Float']>>;
};

export type FloatNullableListFilter = {
  equals?: Maybe<Array<Scalars['Float']>>;
  has?: Maybe<Scalars['Float']>;
  hasEvery?: Maybe<Array<Scalars['Float']>>;
  hasSome?: Maybe<Array<Scalars['Float']>>;
  isEmpty?: Maybe<Scalars['Boolean']>;
};

export type IntFieldUpdateOperationsInput = {
  decrement?: Maybe<Scalars['Int']>;
  divide?: Maybe<Scalars['Int']>;
  increment?: Maybe<Scalars['Int']>;
  multiply?: Maybe<Scalars['Int']>;
  set?: Maybe<Scalars['Int']>;
};

export type IntFilter = {
  equals?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntFilter>;
  notIn?: Maybe<Array<Scalars['Int']>>;
};

export type IntNullableFilter = {
  equals?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntNullableFilter>;
  notIn?: Maybe<Array<Scalars['Int']>>;
};

export type IntNullableListFilter = {
  equals?: Maybe<Array<Scalars['Int']>>;
  has?: Maybe<Scalars['Int']>;
  hasEvery?: Maybe<Array<Scalars['Int']>>;
  hasSome?: Maybe<Array<Scalars['Int']>>;
  isEmpty?: Maybe<Scalars['Boolean']>;
};

export enum InventoryStatus {
  InStock = 'IN_STOCK',
  SoldOut = 'SOLD_OUT'
}

export type ItemWithQuantityInput = {
  id: Scalars['ID'];
  quantity: Scalars['Int'];
};


export type JsonNullableFilter = {
  equals?: Maybe<Scalars['Json']>;
  not?: Maybe<Scalars['Json']>;
};

export type JsonNullableListFilter = {
  equals?: Maybe<Array<Scalars['Json']>>;
  has?: Maybe<Scalars['Json']>;
  hasEvery?: Maybe<Array<Scalars['Json']>>;
  hasSome?: Maybe<Array<Scalars['Json']>>;
  isEmpty?: Maybe<Scalars['Boolean']>;
};

export enum KdsType {
  Kitchen = 'KITCHEN',
  Station = 'STATION'
}

export enum KdsOrdersWorkplace {
  Expo = 'EXPO',
  Foh = 'FOH',
  OrderStatus = 'ORDER_STATUS',
  Packaging = 'PACKAGING',
  Pickup = 'PICKUP',
  Runner = 'RUNNER'
}

export enum KdsStationOrdersWorkplace {
  Expo = 'EXPO',
  Kiosk = 'KIOSK'
}

export type KioskCosts = {
  delivery?: Maybe<Scalars['Float']>;
  fee?: Maybe<Scalars['Float']>;
  giftCardsDiscount?: Maybe<Scalars['Float']>;
  promoDiscount?: Maybe<Scalars['Float']>;
  subtotal: Scalars['Float'];
  tax?: Maybe<Scalars['Float']>;
  tip?: Maybe<Scalars['Float']>;
  tipPercent?: Maybe<Scalars['Float']>;
  total: Scalars['Float'];
};

export type KioskOrderConfirmation = {
  __typename?: 'KioskOrderConfirmation';
  order?: Maybe<Order>;
  validation: KioskOrderValidation;
};

export type KioskOrderInput = {
  costs?: Maybe<KioskCosts>;
  fulfillmentAt?: Maybe<Scalars['DateTime']>;
  note?: Maybe<Scalars['String']>;
  orderMethod?: Maybe<OrderMethod>;
  orderName?: Maybe<Scalars['String']>;
  orderPhoneNumber?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  stripePaymentId?: Maybe<Scalars['String']>;
};

export type KioskOrderValidation = {
  __typename?: 'KioskOrderValidation';
  costs: OrderCost;
  errors: Array<OrderValidationErrorResponseType>;
  giftCardCodes: Array<Scalars['String']>;
  giftCardDiscounts: Array<Scalars['Float']>;
  promoCodes: Array<Scalars['String']>;
  promoErrors: Array<PromoValidationErrorWithCode>;
  unavailableItemIds: Array<Scalars['ID']>;
};

export type KioskPromoInput = {
  cart: CartInput;
  order: KioskOrderInput;
};

export type Kitchen = {
  __typename?: 'Kitchen';
  brandWebUrl?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  cookTime: Scalars['Int'];
  cuisine?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  enableChowly: Scalars['Boolean'];
  facilityId: Scalars['String'];
  headerImgUrl?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  kitchenMenuItems: Array<KitchenMenuItem>;
  kitchenTicket: Array<KitchenTicket>;
  logoUrl?: Maybe<Scalars['String']>;
  /** Menu item count for kitchen list */
  menuItemCount?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  operationHours: Array<Scalars['Json']>;
  originalLocation?: Maybe<Scalars['String']>;
  owners: Array<User>;
  phone?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  sortId: Scalars['Int'];
  status: KitchenStatus;
  story?: Maybe<Scalars['String']>;
  type: KitchenType;
  users: Array<User>;
  workplace?: Maybe<Workplace>;
};


export type KitchenKitchenMenuItemsArgs = {
  after?: Maybe<KitchenMenuItemWhereUniqueInput>;
  before?: Maybe<KitchenMenuItemWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type KitchenKitchenTicketArgs = {
  after?: Maybe<KitchenTicketWhereUniqueInput>;
  before?: Maybe<KitchenTicketWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type KitchenUsersArgs = {
  after?: Maybe<UserWhereUniqueInput>;
  before?: Maybe<UserWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type KitchenCreateManyFacilityInput = {
  chowlyApiKey?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  cookTime?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  cuisine?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  enableChowly?: Maybe<Scalars['Boolean']>;
  headerImgUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  operationHours?: Maybe<KitchenCreateManyoperationHoursInput>;
  originalLocation?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  readonly?: Maybe<Scalars['Boolean']>;
  slug?: Maybe<Scalars['String']>;
  sortId?: Maybe<Scalars['Int']>;
  status?: Maybe<KitchenStatus>;
  story?: Maybe<Scalars['String']>;
  type?: Maybe<KitchenType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workplaceId?: Maybe<Scalars['String']>;
};

export type KitchenCreateManyFacilityInputEnvelope = {
  data?: Maybe<Array<KitchenCreateManyFacilityInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type KitchenCreateManyoperationHoursInput = {
  set?: Maybe<Array<Scalars['Json']>>;
};

export type KitchenCreateNestedManyWithoutFacilityInput = {
  connect?: Maybe<Array<KitchenWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<KitchenCreateOrConnectWithoutFacilityInput>>;
  create?: Maybe<Array<KitchenCreateWithoutFacilityInput>>;
  createMany?: Maybe<KitchenCreateManyFacilityInputEnvelope>;
};

export type KitchenCreateNestedManyWithoutPowerBiReportInput = {
  connect?: Maybe<Array<KitchenWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<KitchenCreateOrConnectWithoutPowerBiReportInput>>;
  create?: Maybe<Array<KitchenCreateWithoutPowerBiReportInput>>;
};

export type KitchenCreateNestedManyWithoutPromoFacilityRulesInput = {
  connect?: Maybe<Array<KitchenWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<KitchenCreateOrConnectWithoutPromoFacilityRulesInput>>;
  create?: Maybe<Array<KitchenCreateWithoutPromoFacilityRulesInput>>;
};

export type KitchenCreateNestedManyWithoutUsersInput = {
  connect?: Maybe<Array<KitchenWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<KitchenCreateOrConnectWithoutUsersInput>>;
  create?: Maybe<Array<KitchenCreateWithoutUsersInput>>;
};

export type KitchenCreateNestedOneWithoutExtraInput = {
  connect?: Maybe<KitchenWhereUniqueInput>;
  connectOrCreate?: Maybe<KitchenCreateOrConnectWithoutExtraInput>;
  create?: Maybe<KitchenCreateWithoutExtraInput>;
};

export type KitchenCreateNestedOneWithoutKitchenMenuItemsInput = {
  connect?: Maybe<KitchenWhereUniqueInput>;
  connectOrCreate?: Maybe<KitchenCreateOrConnectWithoutKitchenMenuItemsInput>;
  create?: Maybe<KitchenCreateWithoutKitchenMenuItemsInput>;
};

export type KitchenCreateNestedOneWithoutKitchenTicketInput = {
  connect?: Maybe<KitchenWhereUniqueInput>;
  connectOrCreate?: Maybe<KitchenCreateOrConnectWithoutKitchenTicketInput>;
  create?: Maybe<KitchenCreateWithoutKitchenTicketInput>;
};

export type KitchenCreateNestedOneWithoutMenuCategoriesInput = {
  connect?: Maybe<KitchenWhereUniqueInput>;
  connectOrCreate?: Maybe<KitchenCreateOrConnectWithoutMenuCategoriesInput>;
  create?: Maybe<KitchenCreateWithoutMenuCategoriesInput>;
};

export type KitchenCreateNestedOneWithoutMenuSchedulesInput = {
  connect?: Maybe<KitchenWhereUniqueInput>;
  connectOrCreate?: Maybe<KitchenCreateOrConnectWithoutMenuSchedulesInput>;
  create?: Maybe<KitchenCreateWithoutMenuSchedulesInput>;
};

export type KitchenCreateNestedOneWithoutWorkplaceInput = {
  connect?: Maybe<KitchenWhereUniqueInput>;
  connectOrCreate?: Maybe<KitchenCreateOrConnectWithoutWorkplaceInput>;
  create?: Maybe<KitchenCreateWithoutWorkplaceInput>;
};

export type KitchenCreateOrConnectWithoutExtraInput = {
  create: KitchenCreateWithoutExtraInput;
  where: KitchenWhereUniqueInput;
};

export type KitchenCreateOrConnectWithoutFacilityInput = {
  create: KitchenCreateWithoutFacilityInput;
  where: KitchenWhereUniqueInput;
};

export type KitchenCreateOrConnectWithoutKitchenMenuItemsInput = {
  create: KitchenCreateWithoutKitchenMenuItemsInput;
  where: KitchenWhereUniqueInput;
};

export type KitchenCreateOrConnectWithoutKitchenTicketInput = {
  create: KitchenCreateWithoutKitchenTicketInput;
  where: KitchenWhereUniqueInput;
};

export type KitchenCreateOrConnectWithoutMenuCategoriesInput = {
  create: KitchenCreateWithoutMenuCategoriesInput;
  where: KitchenWhereUniqueInput;
};

export type KitchenCreateOrConnectWithoutMenuSchedulesInput = {
  create: KitchenCreateWithoutMenuSchedulesInput;
  where: KitchenWhereUniqueInput;
};

export type KitchenCreateOrConnectWithoutPowerBiReportInput = {
  create: KitchenCreateWithoutPowerBiReportInput;
  where: KitchenWhereUniqueInput;
};

export type KitchenCreateOrConnectWithoutPromoFacilityRulesInput = {
  create: KitchenCreateWithoutPromoFacilityRulesInput;
  where: KitchenWhereUniqueInput;
};

export type KitchenCreateOrConnectWithoutUsersInput = {
  create: KitchenCreateWithoutUsersInput;
  where: KitchenWhereUniqueInput;
};

export type KitchenCreateOrConnectWithoutWorkplaceInput = {
  create: KitchenCreateWithoutWorkplaceInput;
  where: KitchenWhereUniqueInput;
};

export type KitchenCreateWithoutExtraInput = {
  chowlyApiKey?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  cookTime?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  cuisine?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  enableChowly?: Maybe<Scalars['Boolean']>;
  facility: FacilityCreateNestedOneWithoutKitchensInput;
  headerImgUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  kitchenMenuItems?: Maybe<KitchenMenuItemCreateNestedManyWithoutKitchenInput>;
  kitchenTicket?: Maybe<KitchenTicketCreateNestedManyWithoutKitchenInput>;
  logoUrl?: Maybe<Scalars['String']>;
  menuCategories?: Maybe<MenuCategoryCreateNestedManyWithoutKitchenInput>;
  menuSchedules?: Maybe<MenuScheduleCreateNestedManyWithoutKitchenInput>;
  name: Scalars['String'];
  operationHours?: Maybe<KitchenCreateoperationHoursInput>;
  originalLocation?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  powerBiReport?: Maybe<PowerBiReportCreateNestedManyWithoutAvailableToConceptsInput>;
  promoFacilityRules?: Maybe<PromoFacilityRuleCreateNestedManyWithoutKitchensInput>;
  readonly?: Maybe<Scalars['Boolean']>;
  slug?: Maybe<Scalars['String']>;
  sortId?: Maybe<Scalars['Int']>;
  status?: Maybe<KitchenStatus>;
  story?: Maybe<Scalars['String']>;
  type?: Maybe<KitchenType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserCreateNestedManyWithoutKitchensInput>;
  workplace?: Maybe<WorkplaceCreateNestedOneWithoutKitchenInput>;
};

export type KitchenCreateWithoutFacilityInput = {
  Extra?: Maybe<ExtraCreateNestedManyWithoutKitchenInput>;
  chowlyApiKey?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  cookTime?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  cuisine?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  enableChowly?: Maybe<Scalars['Boolean']>;
  headerImgUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  kitchenMenuItems?: Maybe<KitchenMenuItemCreateNestedManyWithoutKitchenInput>;
  kitchenTicket?: Maybe<KitchenTicketCreateNestedManyWithoutKitchenInput>;
  logoUrl?: Maybe<Scalars['String']>;
  menuCategories?: Maybe<MenuCategoryCreateNestedManyWithoutKitchenInput>;
  menuSchedules?: Maybe<MenuScheduleCreateNestedManyWithoutKitchenInput>;
  name: Scalars['String'];
  operationHours?: Maybe<KitchenCreateoperationHoursInput>;
  originalLocation?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  powerBiReport?: Maybe<PowerBiReportCreateNestedManyWithoutAvailableToConceptsInput>;
  promoFacilityRules?: Maybe<PromoFacilityRuleCreateNestedManyWithoutKitchensInput>;
  readonly?: Maybe<Scalars['Boolean']>;
  slug?: Maybe<Scalars['String']>;
  sortId?: Maybe<Scalars['Int']>;
  status?: Maybe<KitchenStatus>;
  story?: Maybe<Scalars['String']>;
  type?: Maybe<KitchenType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserCreateNestedManyWithoutKitchensInput>;
  workplace?: Maybe<WorkplaceCreateNestedOneWithoutKitchenInput>;
};

export type KitchenCreateWithoutKitchenMenuItemsInput = {
  Extra?: Maybe<ExtraCreateNestedManyWithoutKitchenInput>;
  chowlyApiKey?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  cookTime?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  cuisine?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  enableChowly?: Maybe<Scalars['Boolean']>;
  facility: FacilityCreateNestedOneWithoutKitchensInput;
  headerImgUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  kitchenTicket?: Maybe<KitchenTicketCreateNestedManyWithoutKitchenInput>;
  logoUrl?: Maybe<Scalars['String']>;
  menuCategories?: Maybe<MenuCategoryCreateNestedManyWithoutKitchenInput>;
  menuSchedules?: Maybe<MenuScheduleCreateNestedManyWithoutKitchenInput>;
  name: Scalars['String'];
  operationHours?: Maybe<KitchenCreateoperationHoursInput>;
  originalLocation?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  powerBiReport?: Maybe<PowerBiReportCreateNestedManyWithoutAvailableToConceptsInput>;
  promoFacilityRules?: Maybe<PromoFacilityRuleCreateNestedManyWithoutKitchensInput>;
  readonly?: Maybe<Scalars['Boolean']>;
  slug?: Maybe<Scalars['String']>;
  sortId?: Maybe<Scalars['Int']>;
  status?: Maybe<KitchenStatus>;
  story?: Maybe<Scalars['String']>;
  type?: Maybe<KitchenType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserCreateNestedManyWithoutKitchensInput>;
  workplace?: Maybe<WorkplaceCreateNestedOneWithoutKitchenInput>;
};

export type KitchenCreateWithoutKitchenTicketInput = {
  Extra?: Maybe<ExtraCreateNestedManyWithoutKitchenInput>;
  chowlyApiKey?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  cookTime?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  cuisine?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  enableChowly?: Maybe<Scalars['Boolean']>;
  facility: FacilityCreateNestedOneWithoutKitchensInput;
  headerImgUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  kitchenMenuItems?: Maybe<KitchenMenuItemCreateNestedManyWithoutKitchenInput>;
  logoUrl?: Maybe<Scalars['String']>;
  menuCategories?: Maybe<MenuCategoryCreateNestedManyWithoutKitchenInput>;
  menuSchedules?: Maybe<MenuScheduleCreateNestedManyWithoutKitchenInput>;
  name: Scalars['String'];
  operationHours?: Maybe<KitchenCreateoperationHoursInput>;
  originalLocation?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  powerBiReport?: Maybe<PowerBiReportCreateNestedManyWithoutAvailableToConceptsInput>;
  promoFacilityRules?: Maybe<PromoFacilityRuleCreateNestedManyWithoutKitchensInput>;
  readonly?: Maybe<Scalars['Boolean']>;
  slug?: Maybe<Scalars['String']>;
  sortId?: Maybe<Scalars['Int']>;
  status?: Maybe<KitchenStatus>;
  story?: Maybe<Scalars['String']>;
  type?: Maybe<KitchenType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserCreateNestedManyWithoutKitchensInput>;
  workplace?: Maybe<WorkplaceCreateNestedOneWithoutKitchenInput>;
};

export type KitchenCreateWithoutMenuCategoriesInput = {
  Extra?: Maybe<ExtraCreateNestedManyWithoutKitchenInput>;
  chowlyApiKey?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  cookTime?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  cuisine?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  enableChowly?: Maybe<Scalars['Boolean']>;
  facility: FacilityCreateNestedOneWithoutKitchensInput;
  headerImgUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  kitchenMenuItems?: Maybe<KitchenMenuItemCreateNestedManyWithoutKitchenInput>;
  kitchenTicket?: Maybe<KitchenTicketCreateNestedManyWithoutKitchenInput>;
  logoUrl?: Maybe<Scalars['String']>;
  menuSchedules?: Maybe<MenuScheduleCreateNestedManyWithoutKitchenInput>;
  name: Scalars['String'];
  operationHours?: Maybe<KitchenCreateoperationHoursInput>;
  originalLocation?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  powerBiReport?: Maybe<PowerBiReportCreateNestedManyWithoutAvailableToConceptsInput>;
  promoFacilityRules?: Maybe<PromoFacilityRuleCreateNestedManyWithoutKitchensInput>;
  readonly?: Maybe<Scalars['Boolean']>;
  slug?: Maybe<Scalars['String']>;
  sortId?: Maybe<Scalars['Int']>;
  status?: Maybe<KitchenStatus>;
  story?: Maybe<Scalars['String']>;
  type?: Maybe<KitchenType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserCreateNestedManyWithoutKitchensInput>;
  workplace?: Maybe<WorkplaceCreateNestedOneWithoutKitchenInput>;
};

export type KitchenCreateWithoutMenuSchedulesInput = {
  Extra?: Maybe<ExtraCreateNestedManyWithoutKitchenInput>;
  chowlyApiKey?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  cookTime?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  cuisine?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  enableChowly?: Maybe<Scalars['Boolean']>;
  facility: FacilityCreateNestedOneWithoutKitchensInput;
  headerImgUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  kitchenMenuItems?: Maybe<KitchenMenuItemCreateNestedManyWithoutKitchenInput>;
  kitchenTicket?: Maybe<KitchenTicketCreateNestedManyWithoutKitchenInput>;
  logoUrl?: Maybe<Scalars['String']>;
  menuCategories?: Maybe<MenuCategoryCreateNestedManyWithoutKitchenInput>;
  name: Scalars['String'];
  operationHours?: Maybe<KitchenCreateoperationHoursInput>;
  originalLocation?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  powerBiReport?: Maybe<PowerBiReportCreateNestedManyWithoutAvailableToConceptsInput>;
  promoFacilityRules?: Maybe<PromoFacilityRuleCreateNestedManyWithoutKitchensInput>;
  readonly?: Maybe<Scalars['Boolean']>;
  slug?: Maybe<Scalars['String']>;
  sortId?: Maybe<Scalars['Int']>;
  status?: Maybe<KitchenStatus>;
  story?: Maybe<Scalars['String']>;
  type?: Maybe<KitchenType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserCreateNestedManyWithoutKitchensInput>;
  workplace?: Maybe<WorkplaceCreateNestedOneWithoutKitchenInput>;
};

export type KitchenCreateWithoutPowerBiReportInput = {
  Extra?: Maybe<ExtraCreateNestedManyWithoutKitchenInput>;
  chowlyApiKey?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  cookTime?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  cuisine?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  enableChowly?: Maybe<Scalars['Boolean']>;
  facility: FacilityCreateNestedOneWithoutKitchensInput;
  headerImgUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  kitchenMenuItems?: Maybe<KitchenMenuItemCreateNestedManyWithoutKitchenInput>;
  kitchenTicket?: Maybe<KitchenTicketCreateNestedManyWithoutKitchenInput>;
  logoUrl?: Maybe<Scalars['String']>;
  menuCategories?: Maybe<MenuCategoryCreateNestedManyWithoutKitchenInput>;
  menuSchedules?: Maybe<MenuScheduleCreateNestedManyWithoutKitchenInput>;
  name: Scalars['String'];
  operationHours?: Maybe<KitchenCreateoperationHoursInput>;
  originalLocation?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  promoFacilityRules?: Maybe<PromoFacilityRuleCreateNestedManyWithoutKitchensInput>;
  readonly?: Maybe<Scalars['Boolean']>;
  slug?: Maybe<Scalars['String']>;
  sortId?: Maybe<Scalars['Int']>;
  status?: Maybe<KitchenStatus>;
  story?: Maybe<Scalars['String']>;
  type?: Maybe<KitchenType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserCreateNestedManyWithoutKitchensInput>;
  workplace?: Maybe<WorkplaceCreateNestedOneWithoutKitchenInput>;
};

export type KitchenCreateWithoutPromoFacilityRulesInput = {
  Extra?: Maybe<ExtraCreateNestedManyWithoutKitchenInput>;
  chowlyApiKey?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  cookTime?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  cuisine?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  enableChowly?: Maybe<Scalars['Boolean']>;
  facility: FacilityCreateNestedOneWithoutKitchensInput;
  headerImgUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  kitchenMenuItems?: Maybe<KitchenMenuItemCreateNestedManyWithoutKitchenInput>;
  kitchenTicket?: Maybe<KitchenTicketCreateNestedManyWithoutKitchenInput>;
  logoUrl?: Maybe<Scalars['String']>;
  menuCategories?: Maybe<MenuCategoryCreateNestedManyWithoutKitchenInput>;
  menuSchedules?: Maybe<MenuScheduleCreateNestedManyWithoutKitchenInput>;
  name: Scalars['String'];
  operationHours?: Maybe<KitchenCreateoperationHoursInput>;
  originalLocation?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  powerBiReport?: Maybe<PowerBiReportCreateNestedManyWithoutAvailableToConceptsInput>;
  readonly?: Maybe<Scalars['Boolean']>;
  slug?: Maybe<Scalars['String']>;
  sortId?: Maybe<Scalars['Int']>;
  status?: Maybe<KitchenStatus>;
  story?: Maybe<Scalars['String']>;
  type?: Maybe<KitchenType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserCreateNestedManyWithoutKitchensInput>;
  workplace?: Maybe<WorkplaceCreateNestedOneWithoutKitchenInput>;
};

export type KitchenCreateWithoutUsersInput = {
  Extra?: Maybe<ExtraCreateNestedManyWithoutKitchenInput>;
  chowlyApiKey?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  cookTime?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  cuisine?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  enableChowly?: Maybe<Scalars['Boolean']>;
  facility: FacilityCreateNestedOneWithoutKitchensInput;
  headerImgUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  kitchenMenuItems?: Maybe<KitchenMenuItemCreateNestedManyWithoutKitchenInput>;
  kitchenTicket?: Maybe<KitchenTicketCreateNestedManyWithoutKitchenInput>;
  logoUrl?: Maybe<Scalars['String']>;
  menuCategories?: Maybe<MenuCategoryCreateNestedManyWithoutKitchenInput>;
  menuSchedules?: Maybe<MenuScheduleCreateNestedManyWithoutKitchenInput>;
  name: Scalars['String'];
  operationHours?: Maybe<KitchenCreateoperationHoursInput>;
  originalLocation?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  powerBiReport?: Maybe<PowerBiReportCreateNestedManyWithoutAvailableToConceptsInput>;
  promoFacilityRules?: Maybe<PromoFacilityRuleCreateNestedManyWithoutKitchensInput>;
  readonly?: Maybe<Scalars['Boolean']>;
  slug?: Maybe<Scalars['String']>;
  sortId?: Maybe<Scalars['Int']>;
  status?: Maybe<KitchenStatus>;
  story?: Maybe<Scalars['String']>;
  type?: Maybe<KitchenType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workplace?: Maybe<WorkplaceCreateNestedOneWithoutKitchenInput>;
};

export type KitchenCreateWithoutWorkplaceInput = {
  Extra?: Maybe<ExtraCreateNestedManyWithoutKitchenInput>;
  chowlyApiKey?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  cookTime?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  cuisine?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  enableChowly?: Maybe<Scalars['Boolean']>;
  facility: FacilityCreateNestedOneWithoutKitchensInput;
  headerImgUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  kitchenMenuItems?: Maybe<KitchenMenuItemCreateNestedManyWithoutKitchenInput>;
  kitchenTicket?: Maybe<KitchenTicketCreateNestedManyWithoutKitchenInput>;
  logoUrl?: Maybe<Scalars['String']>;
  menuCategories?: Maybe<MenuCategoryCreateNestedManyWithoutKitchenInput>;
  menuSchedules?: Maybe<MenuScheduleCreateNestedManyWithoutKitchenInput>;
  name: Scalars['String'];
  operationHours?: Maybe<KitchenCreateoperationHoursInput>;
  originalLocation?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  powerBiReport?: Maybe<PowerBiReportCreateNestedManyWithoutAvailableToConceptsInput>;
  promoFacilityRules?: Maybe<PromoFacilityRuleCreateNestedManyWithoutKitchensInput>;
  readonly?: Maybe<Scalars['Boolean']>;
  slug?: Maybe<Scalars['String']>;
  sortId?: Maybe<Scalars['Int']>;
  status?: Maybe<KitchenStatus>;
  story?: Maybe<Scalars['String']>;
  type?: Maybe<KitchenType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserCreateNestedManyWithoutKitchensInput>;
};

export type KitchenCreateoperationHoursInput = {
  set?: Maybe<Array<Scalars['Json']>>;
};

export type KitchenListRelationFilter = {
  every?: Maybe<KitchenWhereInput>;
  none?: Maybe<KitchenWhereInput>;
  some?: Maybe<KitchenWhereInput>;
};

export type KitchenMenuItem = {
  __typename?: 'KitchenMenuItem';
  allergies: Array<Allergy>;
  availability: Array<MenuItemAvailability>;
  availableFrom?: Maybe<Scalars['DateTime']>;
  availableTo?: Maybe<Scalars['DateTime']>;
  cookTime?: Maybe<Scalars['Int']>;
  description: Scalars['String'];
  dishTypes: Array<DishType>;
  enableInventoryCount: Scalars['Boolean'];
  extras: Array<Extra>;
  facilityItems: Array<FacilityItem>;
  globalSortId: Scalars['Int'];
  hasExtras: Scalars['Boolean'];
  id: Scalars['String'];
  imgUrl?: Maybe<Scalars['String']>;
  inventoryCount?: Maybe<Scalars['Int']>;
  /** Number of pre-ordered items */
  inventoryPreSale: Scalars['Int'];
  inventoryStatus: InventoryStatus;
  isRetail: Scalars['Boolean'];
  kitchen: Kitchen;
  labels: Array<MenuItemLabel>;
  longDescription?: Maybe<Scalars['String']>;
  mealPackItems: Array<KitchenMenuItem>;
  mealPackStatus: KitchenMenuItemStatus;
  mealPacks: Array<KitchenMenuItem>;
  menuCategories: Array<MenuCategory>;
  name: Scalars['String'];
  price: Scalars['Float'];
  sortId: Scalars['Int'];
  sortKitchenIds: Array<Scalars['String']>;
  station?: Maybe<Station>;
  stationId?: Maybe<Scalars['String']>;
  status: KitchenMenuItemStatus;
  type: MenuItemType;
  upsellMenuItems: Array<UpsellMenuItem>;
};


export type KitchenMenuItemExtrasArgs = {
  after?: Maybe<ExtraWhereUniqueInput>;
  before?: Maybe<ExtraWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type KitchenMenuItemFacilityItemsArgs = {
  after?: Maybe<FacilityItemWhereUniqueInput>;
  before?: Maybe<FacilityItemWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type KitchenMenuItemMealPackItemsArgs = {
  after?: Maybe<KitchenMenuItemWhereUniqueInput>;
  before?: Maybe<KitchenMenuItemWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type KitchenMenuItemMealPacksArgs = {
  after?: Maybe<KitchenMenuItemWhereUniqueInput>;
  before?: Maybe<KitchenMenuItemWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type KitchenMenuItemMenuCategoriesArgs = {
  after?: Maybe<MenuCategoryWhereUniqueInput>;
  before?: Maybe<MenuCategoryWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type KitchenMenuItemUpsellMenuItemsArgs = {
  date?: Maybe<Scalars['DateTime']>;
};

export type KitchenMenuItemCartInput = {
  extraItems?: Maybe<Array<Maybe<CartExtraItemInput>>>;
  id: Scalars['ID'];
  quantity?: Maybe<Scalars['Int']>;
};

export type KitchenMenuItemCreateManyKitchenInput = {
  allergies?: Maybe<KitchenMenuItemCreateManyallergiesInput>;
  availability?: Maybe<KitchenMenuItemCreateManyavailabilityInput>;
  availableFrom?: Maybe<Scalars['DateTime']>;
  availableTo?: Maybe<Scalars['DateTime']>;
  chowlyId?: Maybe<Scalars['String']>;
  cookTime?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  dishTypes?: Maybe<KitchenMenuItemCreateManydishTypesInput>;
  enableInventoryCount: Scalars['Boolean'];
  globalSortId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  imgUrl?: Maybe<Scalars['String']>;
  inventoryCount?: Maybe<Scalars['Int']>;
  inventoryStatus?: Maybe<InventoryStatus>;
  isRetail?: Maybe<Scalars['Boolean']>;
  labels?: Maybe<KitchenMenuItemCreateManylabelsInput>;
  longDescription?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  price: Scalars['Float'];
  sortId?: Maybe<Scalars['Int']>;
  sortKitchenIds?: Maybe<KitchenMenuItemCreateManysortKitchenIdsInput>;
  stationId?: Maybe<Scalars['String']>;
  status: KitchenMenuItemStatus;
  type?: Maybe<MenuItemType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type KitchenMenuItemCreateManyKitchenInputEnvelope = {
  data?: Maybe<Array<KitchenMenuItemCreateManyKitchenInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type KitchenMenuItemCreateManyStationInput = {
  allergies?: Maybe<KitchenMenuItemCreateManyallergiesInput>;
  availability?: Maybe<KitchenMenuItemCreateManyavailabilityInput>;
  availableFrom?: Maybe<Scalars['DateTime']>;
  availableTo?: Maybe<Scalars['DateTime']>;
  chowlyId?: Maybe<Scalars['String']>;
  cookTime?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  dishTypes?: Maybe<KitchenMenuItemCreateManydishTypesInput>;
  enableInventoryCount: Scalars['Boolean'];
  globalSortId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  imgUrl?: Maybe<Scalars['String']>;
  inventoryCount?: Maybe<Scalars['Int']>;
  inventoryStatus?: Maybe<InventoryStatus>;
  isRetail?: Maybe<Scalars['Boolean']>;
  kitchenId: Scalars['String'];
  labels?: Maybe<KitchenMenuItemCreateManylabelsInput>;
  longDescription?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  price: Scalars['Float'];
  sortId?: Maybe<Scalars['Int']>;
  sortKitchenIds?: Maybe<KitchenMenuItemCreateManysortKitchenIdsInput>;
  status: KitchenMenuItemStatus;
  type?: Maybe<MenuItemType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type KitchenMenuItemCreateManyStationInputEnvelope = {
  data?: Maybe<Array<KitchenMenuItemCreateManyStationInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type KitchenMenuItemCreateManyallergiesInput = {
  set?: Maybe<Array<Allergy>>;
};

export type KitchenMenuItemCreateManyavailabilityInput = {
  set?: Maybe<Array<MenuItemAvailability>>;
};

export type KitchenMenuItemCreateManydishTypesInput = {
  set?: Maybe<Array<DishType>>;
};

export type KitchenMenuItemCreateManylabelsInput = {
  set?: Maybe<Array<MenuItemLabel>>;
};

export type KitchenMenuItemCreateManysortKitchenIdsInput = {
  set?: Maybe<Array<Scalars['String']>>;
};

export type KitchenMenuItemCreateNestedManyWithoutExtrasInput = {
  connect?: Maybe<Array<KitchenMenuItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<KitchenMenuItemCreateOrConnectWithoutExtrasInput>>;
  create?: Maybe<Array<KitchenMenuItemCreateWithoutExtrasInput>>;
};

export type KitchenMenuItemCreateNestedManyWithoutKitchenInput = {
  connect?: Maybe<Array<KitchenMenuItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<KitchenMenuItemCreateOrConnectWithoutKitchenInput>>;
  create?: Maybe<Array<KitchenMenuItemCreateWithoutKitchenInput>>;
  createMany?: Maybe<KitchenMenuItemCreateManyKitchenInputEnvelope>;
};

export type KitchenMenuItemCreateNestedManyWithoutMealPackItemsInput = {
  connect?: Maybe<Array<KitchenMenuItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<KitchenMenuItemCreateOrConnectWithoutMealPackItemsInput>>;
  create?: Maybe<Array<KitchenMenuItemCreateWithoutMealPackItemsInput>>;
};

export type KitchenMenuItemCreateNestedManyWithoutMealPacksInput = {
  connect?: Maybe<Array<KitchenMenuItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<KitchenMenuItemCreateOrConnectWithoutMealPacksInput>>;
  create?: Maybe<Array<KitchenMenuItemCreateWithoutMealPacksInput>>;
};

export type KitchenMenuItemCreateNestedManyWithoutMenuCategoriesInput = {
  connect?: Maybe<Array<KitchenMenuItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<KitchenMenuItemCreateOrConnectWithoutMenuCategoriesInput>>;
  create?: Maybe<Array<KitchenMenuItemCreateWithoutMenuCategoriesInput>>;
};

export type KitchenMenuItemCreateNestedManyWithoutMenuScheduleCategoriesInput = {
  connect?: Maybe<Array<KitchenMenuItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<KitchenMenuItemCreateOrConnectWithoutMenuScheduleCategoriesInput>>;
  create?: Maybe<Array<KitchenMenuItemCreateWithoutMenuScheduleCategoriesInput>>;
};

export type KitchenMenuItemCreateNestedManyWithoutStationInput = {
  connect?: Maybe<Array<KitchenMenuItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<KitchenMenuItemCreateOrConnectWithoutStationInput>>;
  create?: Maybe<Array<KitchenMenuItemCreateWithoutStationInput>>;
  createMany?: Maybe<KitchenMenuItemCreateManyStationInputEnvelope>;
};

export type KitchenMenuItemCreateNestedOneWithoutCartItemsInput = {
  connect?: Maybe<KitchenMenuItemWhereUniqueInput>;
  connectOrCreate?: Maybe<KitchenMenuItemCreateOrConnectWithoutCartItemsInput>;
  create?: Maybe<KitchenMenuItemCreateWithoutCartItemsInput>;
};

export type KitchenMenuItemCreateNestedOneWithoutCartUpsellItemInput = {
  connect?: Maybe<KitchenMenuItemWhereUniqueInput>;
  connectOrCreate?: Maybe<KitchenMenuItemCreateOrConnectWithoutCartUpsellItemInput>;
  create?: Maybe<KitchenMenuItemCreateWithoutCartUpsellItemInput>;
};

export type KitchenMenuItemCreateNestedOneWithoutFacilityItemsInput = {
  connect?: Maybe<KitchenMenuItemWhereUniqueInput>;
  connectOrCreate?: Maybe<KitchenMenuItemCreateOrConnectWithoutFacilityItemsInput>;
  create?: Maybe<KitchenMenuItemCreateWithoutFacilityItemsInput>;
};

export type KitchenMenuItemCreateNestedOneWithoutOrderItemsInput = {
  connect?: Maybe<KitchenMenuItemWhereUniqueInput>;
  connectOrCreate?: Maybe<KitchenMenuItemCreateOrConnectWithoutOrderItemsInput>;
  create?: Maybe<KitchenMenuItemCreateWithoutOrderItemsInput>;
};

export type KitchenMenuItemCreateNestedOneWithoutParentItemsInput = {
  connect?: Maybe<KitchenMenuItemWhereUniqueInput>;
  connectOrCreate?: Maybe<KitchenMenuItemCreateOrConnectWithoutParentItemsInput>;
  create?: Maybe<KitchenMenuItemCreateWithoutParentItemsInput>;
};

export type KitchenMenuItemCreateNestedOneWithoutUpsellMenuItemsInput = {
  connect?: Maybe<KitchenMenuItemWhereUniqueInput>;
  connectOrCreate?: Maybe<KitchenMenuItemCreateOrConnectWithoutUpsellMenuItemsInput>;
  create?: Maybe<KitchenMenuItemCreateWithoutUpsellMenuItemsInput>;
};

export type KitchenMenuItemCreateOrConnectWithoutCartItemsInput = {
  create: KitchenMenuItemCreateWithoutCartItemsInput;
  where: KitchenMenuItemWhereUniqueInput;
};

export type KitchenMenuItemCreateOrConnectWithoutCartUpsellItemInput = {
  create: KitchenMenuItemCreateWithoutCartUpsellItemInput;
  where: KitchenMenuItemWhereUniqueInput;
};

export type KitchenMenuItemCreateOrConnectWithoutExtrasInput = {
  create: KitchenMenuItemCreateWithoutExtrasInput;
  where: KitchenMenuItemWhereUniqueInput;
};

export type KitchenMenuItemCreateOrConnectWithoutFacilityItemsInput = {
  create: KitchenMenuItemCreateWithoutFacilityItemsInput;
  where: KitchenMenuItemWhereUniqueInput;
};

export type KitchenMenuItemCreateOrConnectWithoutKitchenInput = {
  create: KitchenMenuItemCreateWithoutKitchenInput;
  where: KitchenMenuItemWhereUniqueInput;
};

export type KitchenMenuItemCreateOrConnectWithoutMealPackItemsInput = {
  create: KitchenMenuItemCreateWithoutMealPackItemsInput;
  where: KitchenMenuItemWhereUniqueInput;
};

export type KitchenMenuItemCreateOrConnectWithoutMealPacksInput = {
  create: KitchenMenuItemCreateWithoutMealPacksInput;
  where: KitchenMenuItemWhereUniqueInput;
};

export type KitchenMenuItemCreateOrConnectWithoutMenuCategoriesInput = {
  create: KitchenMenuItemCreateWithoutMenuCategoriesInput;
  where: KitchenMenuItemWhereUniqueInput;
};

export type KitchenMenuItemCreateOrConnectWithoutMenuScheduleCategoriesInput = {
  create: KitchenMenuItemCreateWithoutMenuScheduleCategoriesInput;
  where: KitchenMenuItemWhereUniqueInput;
};

export type KitchenMenuItemCreateOrConnectWithoutOrderItemsInput = {
  create: KitchenMenuItemCreateWithoutOrderItemsInput;
  where: KitchenMenuItemWhereUniqueInput;
};

export type KitchenMenuItemCreateOrConnectWithoutParentItemsInput = {
  create: KitchenMenuItemCreateWithoutParentItemsInput;
  where: KitchenMenuItemWhereUniqueInput;
};

export type KitchenMenuItemCreateOrConnectWithoutStationInput = {
  create: KitchenMenuItemCreateWithoutStationInput;
  where: KitchenMenuItemWhereUniqueInput;
};

export type KitchenMenuItemCreateOrConnectWithoutUpsellMenuItemsInput = {
  create: KitchenMenuItemCreateWithoutUpsellMenuItemsInput;
  where: KitchenMenuItemWhereUniqueInput;
};

export type KitchenMenuItemCreateWithoutCartItemsInput = {
  allergies?: Maybe<KitchenMenuItemCreateallergiesInput>;
  availability?: Maybe<KitchenMenuItemCreateavailabilityInput>;
  availableFrom?: Maybe<Scalars['DateTime']>;
  availableTo?: Maybe<Scalars['DateTime']>;
  cartUpsellItem?: Maybe<CartUpsellItemCreateNestedManyWithoutUpsellItemInput>;
  chowlyId?: Maybe<Scalars['String']>;
  cookTime?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  dishTypes?: Maybe<KitchenMenuItemCreatedishTypesInput>;
  enableInventoryCount: Scalars['Boolean'];
  extras?: Maybe<ExtraCreateNestedManyWithoutKitchenMenuItemsInput>;
  facilityItems?: Maybe<FacilityItemCreateNestedManyWithoutMenuItemInput>;
  globalSortId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  imgUrl?: Maybe<Scalars['String']>;
  inventoryCount?: Maybe<Scalars['Int']>;
  inventoryStatus?: Maybe<InventoryStatus>;
  isRetail?: Maybe<Scalars['Boolean']>;
  kitchen: KitchenCreateNestedOneWithoutKitchenMenuItemsInput;
  labels?: Maybe<KitchenMenuItemCreatelabelsInput>;
  longDescription?: Maybe<Scalars['String']>;
  mealPackItems?: Maybe<KitchenMenuItemCreateNestedManyWithoutMealPacksInput>;
  mealPacks?: Maybe<KitchenMenuItemCreateNestedManyWithoutMealPackItemsInput>;
  menuCategories?: Maybe<MenuCategoryCreateNestedManyWithoutMenuItemsInput>;
  menuScheduleCategories?: Maybe<MenuScheduleCategoryCreateNestedManyWithoutMenuItemsInput>;
  name: Scalars['String'];
  orderItems?: Maybe<OrderItemCreateNestedManyWithoutMenuItemInput>;
  parentItems?: Maybe<UpsellMenuItemCreateNestedManyWithoutParentItemInput>;
  price: Scalars['Float'];
  sortId?: Maybe<Scalars['Int']>;
  sortKitchenIds?: Maybe<KitchenMenuItemCreatesortKitchenIdsInput>;
  station?: Maybe<StationCreateNestedOneWithoutKitchenMenuItemsInput>;
  status: KitchenMenuItemStatus;
  type?: Maybe<MenuItemType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  upsellMenuItems?: Maybe<UpsellMenuItemCreateNestedManyWithoutUpsellItemInput>;
};

export type KitchenMenuItemCreateWithoutCartUpsellItemInput = {
  allergies?: Maybe<KitchenMenuItemCreateallergiesInput>;
  availability?: Maybe<KitchenMenuItemCreateavailabilityInput>;
  availableFrom?: Maybe<Scalars['DateTime']>;
  availableTo?: Maybe<Scalars['DateTime']>;
  cartItems?: Maybe<CartItemCreateNestedManyWithoutKitchenMenuItemInput>;
  chowlyId?: Maybe<Scalars['String']>;
  cookTime?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  dishTypes?: Maybe<KitchenMenuItemCreatedishTypesInput>;
  enableInventoryCount: Scalars['Boolean'];
  extras?: Maybe<ExtraCreateNestedManyWithoutKitchenMenuItemsInput>;
  facilityItems?: Maybe<FacilityItemCreateNestedManyWithoutMenuItemInput>;
  globalSortId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  imgUrl?: Maybe<Scalars['String']>;
  inventoryCount?: Maybe<Scalars['Int']>;
  inventoryStatus?: Maybe<InventoryStatus>;
  isRetail?: Maybe<Scalars['Boolean']>;
  kitchen: KitchenCreateNestedOneWithoutKitchenMenuItemsInput;
  labels?: Maybe<KitchenMenuItemCreatelabelsInput>;
  longDescription?: Maybe<Scalars['String']>;
  mealPackItems?: Maybe<KitchenMenuItemCreateNestedManyWithoutMealPacksInput>;
  mealPacks?: Maybe<KitchenMenuItemCreateNestedManyWithoutMealPackItemsInput>;
  menuCategories?: Maybe<MenuCategoryCreateNestedManyWithoutMenuItemsInput>;
  menuScheduleCategories?: Maybe<MenuScheduleCategoryCreateNestedManyWithoutMenuItemsInput>;
  name: Scalars['String'];
  orderItems?: Maybe<OrderItemCreateNestedManyWithoutMenuItemInput>;
  parentItems?: Maybe<UpsellMenuItemCreateNestedManyWithoutParentItemInput>;
  price: Scalars['Float'];
  sortId?: Maybe<Scalars['Int']>;
  sortKitchenIds?: Maybe<KitchenMenuItemCreatesortKitchenIdsInput>;
  station?: Maybe<StationCreateNestedOneWithoutKitchenMenuItemsInput>;
  status: KitchenMenuItemStatus;
  type?: Maybe<MenuItemType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  upsellMenuItems?: Maybe<UpsellMenuItemCreateNestedManyWithoutUpsellItemInput>;
};

export type KitchenMenuItemCreateWithoutExtrasInput = {
  allergies?: Maybe<KitchenMenuItemCreateallergiesInput>;
  availability?: Maybe<KitchenMenuItemCreateavailabilityInput>;
  availableFrom?: Maybe<Scalars['DateTime']>;
  availableTo?: Maybe<Scalars['DateTime']>;
  cartItems?: Maybe<CartItemCreateNestedManyWithoutKitchenMenuItemInput>;
  cartUpsellItem?: Maybe<CartUpsellItemCreateNestedManyWithoutUpsellItemInput>;
  chowlyId?: Maybe<Scalars['String']>;
  cookTime?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  dishTypes?: Maybe<KitchenMenuItemCreatedishTypesInput>;
  enableInventoryCount: Scalars['Boolean'];
  facilityItems?: Maybe<FacilityItemCreateNestedManyWithoutMenuItemInput>;
  globalSortId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  imgUrl?: Maybe<Scalars['String']>;
  inventoryCount?: Maybe<Scalars['Int']>;
  inventoryStatus?: Maybe<InventoryStatus>;
  isRetail?: Maybe<Scalars['Boolean']>;
  kitchen: KitchenCreateNestedOneWithoutKitchenMenuItemsInput;
  labels?: Maybe<KitchenMenuItemCreatelabelsInput>;
  longDescription?: Maybe<Scalars['String']>;
  mealPackItems?: Maybe<KitchenMenuItemCreateNestedManyWithoutMealPacksInput>;
  mealPacks?: Maybe<KitchenMenuItemCreateNestedManyWithoutMealPackItemsInput>;
  menuCategories?: Maybe<MenuCategoryCreateNestedManyWithoutMenuItemsInput>;
  menuScheduleCategories?: Maybe<MenuScheduleCategoryCreateNestedManyWithoutMenuItemsInput>;
  name: Scalars['String'];
  orderItems?: Maybe<OrderItemCreateNestedManyWithoutMenuItemInput>;
  parentItems?: Maybe<UpsellMenuItemCreateNestedManyWithoutParentItemInput>;
  price: Scalars['Float'];
  sortId?: Maybe<Scalars['Int']>;
  sortKitchenIds?: Maybe<KitchenMenuItemCreatesortKitchenIdsInput>;
  station?: Maybe<StationCreateNestedOneWithoutKitchenMenuItemsInput>;
  status: KitchenMenuItemStatus;
  type?: Maybe<MenuItemType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  upsellMenuItems?: Maybe<UpsellMenuItemCreateNestedManyWithoutUpsellItemInput>;
};

export type KitchenMenuItemCreateWithoutFacilityItemsInput = {
  allergies?: Maybe<KitchenMenuItemCreateallergiesInput>;
  availability?: Maybe<KitchenMenuItemCreateavailabilityInput>;
  availableFrom?: Maybe<Scalars['DateTime']>;
  availableTo?: Maybe<Scalars['DateTime']>;
  cartItems?: Maybe<CartItemCreateNestedManyWithoutKitchenMenuItemInput>;
  cartUpsellItem?: Maybe<CartUpsellItemCreateNestedManyWithoutUpsellItemInput>;
  chowlyId?: Maybe<Scalars['String']>;
  cookTime?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  dishTypes?: Maybe<KitchenMenuItemCreatedishTypesInput>;
  enableInventoryCount: Scalars['Boolean'];
  extras?: Maybe<ExtraCreateNestedManyWithoutKitchenMenuItemsInput>;
  globalSortId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  imgUrl?: Maybe<Scalars['String']>;
  inventoryCount?: Maybe<Scalars['Int']>;
  inventoryStatus?: Maybe<InventoryStatus>;
  isRetail?: Maybe<Scalars['Boolean']>;
  kitchen: KitchenCreateNestedOneWithoutKitchenMenuItemsInput;
  labels?: Maybe<KitchenMenuItemCreatelabelsInput>;
  longDescription?: Maybe<Scalars['String']>;
  mealPackItems?: Maybe<KitchenMenuItemCreateNestedManyWithoutMealPacksInput>;
  mealPacks?: Maybe<KitchenMenuItemCreateNestedManyWithoutMealPackItemsInput>;
  menuCategories?: Maybe<MenuCategoryCreateNestedManyWithoutMenuItemsInput>;
  menuScheduleCategories?: Maybe<MenuScheduleCategoryCreateNestedManyWithoutMenuItemsInput>;
  name: Scalars['String'];
  orderItems?: Maybe<OrderItemCreateNestedManyWithoutMenuItemInput>;
  parentItems?: Maybe<UpsellMenuItemCreateNestedManyWithoutParentItemInput>;
  price: Scalars['Float'];
  sortId?: Maybe<Scalars['Int']>;
  sortKitchenIds?: Maybe<KitchenMenuItemCreatesortKitchenIdsInput>;
  station?: Maybe<StationCreateNestedOneWithoutKitchenMenuItemsInput>;
  status: KitchenMenuItemStatus;
  type?: Maybe<MenuItemType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  upsellMenuItems?: Maybe<UpsellMenuItemCreateNestedManyWithoutUpsellItemInput>;
};

export type KitchenMenuItemCreateWithoutKitchenInput = {
  allergies?: Maybe<KitchenMenuItemCreateallergiesInput>;
  availability?: Maybe<KitchenMenuItemCreateavailabilityInput>;
  availableFrom?: Maybe<Scalars['DateTime']>;
  availableTo?: Maybe<Scalars['DateTime']>;
  cartItems?: Maybe<CartItemCreateNestedManyWithoutKitchenMenuItemInput>;
  cartUpsellItem?: Maybe<CartUpsellItemCreateNestedManyWithoutUpsellItemInput>;
  chowlyId?: Maybe<Scalars['String']>;
  cookTime?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  dishTypes?: Maybe<KitchenMenuItemCreatedishTypesInput>;
  enableInventoryCount: Scalars['Boolean'];
  extras?: Maybe<ExtraCreateNestedManyWithoutKitchenMenuItemsInput>;
  facilityItems?: Maybe<FacilityItemCreateNestedManyWithoutMenuItemInput>;
  globalSortId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  imgUrl?: Maybe<Scalars['String']>;
  inventoryCount?: Maybe<Scalars['Int']>;
  inventoryStatus?: Maybe<InventoryStatus>;
  isRetail?: Maybe<Scalars['Boolean']>;
  labels?: Maybe<KitchenMenuItemCreatelabelsInput>;
  longDescription?: Maybe<Scalars['String']>;
  mealPackItems?: Maybe<KitchenMenuItemCreateNestedManyWithoutMealPacksInput>;
  mealPacks?: Maybe<KitchenMenuItemCreateNestedManyWithoutMealPackItemsInput>;
  menuCategories?: Maybe<MenuCategoryCreateNestedManyWithoutMenuItemsInput>;
  menuScheduleCategories?: Maybe<MenuScheduleCategoryCreateNestedManyWithoutMenuItemsInput>;
  name: Scalars['String'];
  orderItems?: Maybe<OrderItemCreateNestedManyWithoutMenuItemInput>;
  parentItems?: Maybe<UpsellMenuItemCreateNestedManyWithoutParentItemInput>;
  price: Scalars['Float'];
  sortId?: Maybe<Scalars['Int']>;
  sortKitchenIds?: Maybe<KitchenMenuItemCreatesortKitchenIdsInput>;
  station?: Maybe<StationCreateNestedOneWithoutKitchenMenuItemsInput>;
  status: KitchenMenuItemStatus;
  type?: Maybe<MenuItemType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  upsellMenuItems?: Maybe<UpsellMenuItemCreateNestedManyWithoutUpsellItemInput>;
};

export type KitchenMenuItemCreateWithoutMealPackItemsInput = {
  allergies?: Maybe<KitchenMenuItemCreateallergiesInput>;
  availability?: Maybe<KitchenMenuItemCreateavailabilityInput>;
  availableFrom?: Maybe<Scalars['DateTime']>;
  availableTo?: Maybe<Scalars['DateTime']>;
  cartItems?: Maybe<CartItemCreateNestedManyWithoutKitchenMenuItemInput>;
  cartUpsellItem?: Maybe<CartUpsellItemCreateNestedManyWithoutUpsellItemInput>;
  chowlyId?: Maybe<Scalars['String']>;
  cookTime?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  dishTypes?: Maybe<KitchenMenuItemCreatedishTypesInput>;
  enableInventoryCount: Scalars['Boolean'];
  extras?: Maybe<ExtraCreateNestedManyWithoutKitchenMenuItemsInput>;
  facilityItems?: Maybe<FacilityItemCreateNestedManyWithoutMenuItemInput>;
  globalSortId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  imgUrl?: Maybe<Scalars['String']>;
  inventoryCount?: Maybe<Scalars['Int']>;
  inventoryStatus?: Maybe<InventoryStatus>;
  isRetail?: Maybe<Scalars['Boolean']>;
  kitchen: KitchenCreateNestedOneWithoutKitchenMenuItemsInput;
  labels?: Maybe<KitchenMenuItemCreatelabelsInput>;
  longDescription?: Maybe<Scalars['String']>;
  mealPacks?: Maybe<KitchenMenuItemCreateNestedManyWithoutMealPackItemsInput>;
  menuCategories?: Maybe<MenuCategoryCreateNestedManyWithoutMenuItemsInput>;
  menuScheduleCategories?: Maybe<MenuScheduleCategoryCreateNestedManyWithoutMenuItemsInput>;
  name: Scalars['String'];
  orderItems?: Maybe<OrderItemCreateNestedManyWithoutMenuItemInput>;
  parentItems?: Maybe<UpsellMenuItemCreateNestedManyWithoutParentItemInput>;
  price: Scalars['Float'];
  sortId?: Maybe<Scalars['Int']>;
  sortKitchenIds?: Maybe<KitchenMenuItemCreatesortKitchenIdsInput>;
  station?: Maybe<StationCreateNestedOneWithoutKitchenMenuItemsInput>;
  status: KitchenMenuItemStatus;
  type?: Maybe<MenuItemType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  upsellMenuItems?: Maybe<UpsellMenuItemCreateNestedManyWithoutUpsellItemInput>;
};

export type KitchenMenuItemCreateWithoutMealPacksInput = {
  allergies?: Maybe<KitchenMenuItemCreateallergiesInput>;
  availability?: Maybe<KitchenMenuItemCreateavailabilityInput>;
  availableFrom?: Maybe<Scalars['DateTime']>;
  availableTo?: Maybe<Scalars['DateTime']>;
  cartItems?: Maybe<CartItemCreateNestedManyWithoutKitchenMenuItemInput>;
  cartUpsellItem?: Maybe<CartUpsellItemCreateNestedManyWithoutUpsellItemInput>;
  chowlyId?: Maybe<Scalars['String']>;
  cookTime?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  dishTypes?: Maybe<KitchenMenuItemCreatedishTypesInput>;
  enableInventoryCount: Scalars['Boolean'];
  extras?: Maybe<ExtraCreateNestedManyWithoutKitchenMenuItemsInput>;
  facilityItems?: Maybe<FacilityItemCreateNestedManyWithoutMenuItemInput>;
  globalSortId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  imgUrl?: Maybe<Scalars['String']>;
  inventoryCount?: Maybe<Scalars['Int']>;
  inventoryStatus?: Maybe<InventoryStatus>;
  isRetail?: Maybe<Scalars['Boolean']>;
  kitchen: KitchenCreateNestedOneWithoutKitchenMenuItemsInput;
  labels?: Maybe<KitchenMenuItemCreatelabelsInput>;
  longDescription?: Maybe<Scalars['String']>;
  mealPackItems?: Maybe<KitchenMenuItemCreateNestedManyWithoutMealPacksInput>;
  menuCategories?: Maybe<MenuCategoryCreateNestedManyWithoutMenuItemsInput>;
  menuScheduleCategories?: Maybe<MenuScheduleCategoryCreateNestedManyWithoutMenuItemsInput>;
  name: Scalars['String'];
  orderItems?: Maybe<OrderItemCreateNestedManyWithoutMenuItemInput>;
  parentItems?: Maybe<UpsellMenuItemCreateNestedManyWithoutParentItemInput>;
  price: Scalars['Float'];
  sortId?: Maybe<Scalars['Int']>;
  sortKitchenIds?: Maybe<KitchenMenuItemCreatesortKitchenIdsInput>;
  station?: Maybe<StationCreateNestedOneWithoutKitchenMenuItemsInput>;
  status: KitchenMenuItemStatus;
  type?: Maybe<MenuItemType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  upsellMenuItems?: Maybe<UpsellMenuItemCreateNestedManyWithoutUpsellItemInput>;
};

export type KitchenMenuItemCreateWithoutMenuCategoriesInput = {
  allergies?: Maybe<KitchenMenuItemCreateallergiesInput>;
  availability?: Maybe<KitchenMenuItemCreateavailabilityInput>;
  availableFrom?: Maybe<Scalars['DateTime']>;
  availableTo?: Maybe<Scalars['DateTime']>;
  cartItems?: Maybe<CartItemCreateNestedManyWithoutKitchenMenuItemInput>;
  cartUpsellItem?: Maybe<CartUpsellItemCreateNestedManyWithoutUpsellItemInput>;
  chowlyId?: Maybe<Scalars['String']>;
  cookTime?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  dishTypes?: Maybe<KitchenMenuItemCreatedishTypesInput>;
  enableInventoryCount: Scalars['Boolean'];
  extras?: Maybe<ExtraCreateNestedManyWithoutKitchenMenuItemsInput>;
  facilityItems?: Maybe<FacilityItemCreateNestedManyWithoutMenuItemInput>;
  globalSortId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  imgUrl?: Maybe<Scalars['String']>;
  inventoryCount?: Maybe<Scalars['Int']>;
  inventoryStatus?: Maybe<InventoryStatus>;
  isRetail?: Maybe<Scalars['Boolean']>;
  kitchen: KitchenCreateNestedOneWithoutKitchenMenuItemsInput;
  labels?: Maybe<KitchenMenuItemCreatelabelsInput>;
  longDescription?: Maybe<Scalars['String']>;
  mealPackItems?: Maybe<KitchenMenuItemCreateNestedManyWithoutMealPacksInput>;
  mealPacks?: Maybe<KitchenMenuItemCreateNestedManyWithoutMealPackItemsInput>;
  menuScheduleCategories?: Maybe<MenuScheduleCategoryCreateNestedManyWithoutMenuItemsInput>;
  name: Scalars['String'];
  orderItems?: Maybe<OrderItemCreateNestedManyWithoutMenuItemInput>;
  parentItems?: Maybe<UpsellMenuItemCreateNestedManyWithoutParentItemInput>;
  price: Scalars['Float'];
  sortId?: Maybe<Scalars['Int']>;
  sortKitchenIds?: Maybe<KitchenMenuItemCreatesortKitchenIdsInput>;
  station?: Maybe<StationCreateNestedOneWithoutKitchenMenuItemsInput>;
  status: KitchenMenuItemStatus;
  type?: Maybe<MenuItemType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  upsellMenuItems?: Maybe<UpsellMenuItemCreateNestedManyWithoutUpsellItemInput>;
};

export type KitchenMenuItemCreateWithoutMenuScheduleCategoriesInput = {
  allergies?: Maybe<KitchenMenuItemCreateallergiesInput>;
  availability?: Maybe<KitchenMenuItemCreateavailabilityInput>;
  availableFrom?: Maybe<Scalars['DateTime']>;
  availableTo?: Maybe<Scalars['DateTime']>;
  cartItems?: Maybe<CartItemCreateNestedManyWithoutKitchenMenuItemInput>;
  cartUpsellItem?: Maybe<CartUpsellItemCreateNestedManyWithoutUpsellItemInput>;
  chowlyId?: Maybe<Scalars['String']>;
  cookTime?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  dishTypes?: Maybe<KitchenMenuItemCreatedishTypesInput>;
  enableInventoryCount: Scalars['Boolean'];
  extras?: Maybe<ExtraCreateNestedManyWithoutKitchenMenuItemsInput>;
  facilityItems?: Maybe<FacilityItemCreateNestedManyWithoutMenuItemInput>;
  globalSortId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  imgUrl?: Maybe<Scalars['String']>;
  inventoryCount?: Maybe<Scalars['Int']>;
  inventoryStatus?: Maybe<InventoryStatus>;
  isRetail?: Maybe<Scalars['Boolean']>;
  kitchen: KitchenCreateNestedOneWithoutKitchenMenuItemsInput;
  labels?: Maybe<KitchenMenuItemCreatelabelsInput>;
  longDescription?: Maybe<Scalars['String']>;
  mealPackItems?: Maybe<KitchenMenuItemCreateNestedManyWithoutMealPacksInput>;
  mealPacks?: Maybe<KitchenMenuItemCreateNestedManyWithoutMealPackItemsInput>;
  menuCategories?: Maybe<MenuCategoryCreateNestedManyWithoutMenuItemsInput>;
  name: Scalars['String'];
  orderItems?: Maybe<OrderItemCreateNestedManyWithoutMenuItemInput>;
  parentItems?: Maybe<UpsellMenuItemCreateNestedManyWithoutParentItemInput>;
  price: Scalars['Float'];
  sortId?: Maybe<Scalars['Int']>;
  sortKitchenIds?: Maybe<KitchenMenuItemCreatesortKitchenIdsInput>;
  station?: Maybe<StationCreateNestedOneWithoutKitchenMenuItemsInput>;
  status: KitchenMenuItemStatus;
  type?: Maybe<MenuItemType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  upsellMenuItems?: Maybe<UpsellMenuItemCreateNestedManyWithoutUpsellItemInput>;
};

export type KitchenMenuItemCreateWithoutOrderItemsInput = {
  allergies?: Maybe<KitchenMenuItemCreateallergiesInput>;
  availability?: Maybe<KitchenMenuItemCreateavailabilityInput>;
  availableFrom?: Maybe<Scalars['DateTime']>;
  availableTo?: Maybe<Scalars['DateTime']>;
  cartItems?: Maybe<CartItemCreateNestedManyWithoutKitchenMenuItemInput>;
  cartUpsellItem?: Maybe<CartUpsellItemCreateNestedManyWithoutUpsellItemInput>;
  chowlyId?: Maybe<Scalars['String']>;
  cookTime?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  dishTypes?: Maybe<KitchenMenuItemCreatedishTypesInput>;
  enableInventoryCount: Scalars['Boolean'];
  extras?: Maybe<ExtraCreateNestedManyWithoutKitchenMenuItemsInput>;
  facilityItems?: Maybe<FacilityItemCreateNestedManyWithoutMenuItemInput>;
  globalSortId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  imgUrl?: Maybe<Scalars['String']>;
  inventoryCount?: Maybe<Scalars['Int']>;
  inventoryStatus?: Maybe<InventoryStatus>;
  isRetail?: Maybe<Scalars['Boolean']>;
  kitchen: KitchenCreateNestedOneWithoutKitchenMenuItemsInput;
  labels?: Maybe<KitchenMenuItemCreatelabelsInput>;
  longDescription?: Maybe<Scalars['String']>;
  mealPackItems?: Maybe<KitchenMenuItemCreateNestedManyWithoutMealPacksInput>;
  mealPacks?: Maybe<KitchenMenuItemCreateNestedManyWithoutMealPackItemsInput>;
  menuCategories?: Maybe<MenuCategoryCreateNestedManyWithoutMenuItemsInput>;
  menuScheduleCategories?: Maybe<MenuScheduleCategoryCreateNestedManyWithoutMenuItemsInput>;
  name: Scalars['String'];
  parentItems?: Maybe<UpsellMenuItemCreateNestedManyWithoutParentItemInput>;
  price: Scalars['Float'];
  sortId?: Maybe<Scalars['Int']>;
  sortKitchenIds?: Maybe<KitchenMenuItemCreatesortKitchenIdsInput>;
  station?: Maybe<StationCreateNestedOneWithoutKitchenMenuItemsInput>;
  status: KitchenMenuItemStatus;
  type?: Maybe<MenuItemType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  upsellMenuItems?: Maybe<UpsellMenuItemCreateNestedManyWithoutUpsellItemInput>;
};

export type KitchenMenuItemCreateWithoutParentItemsInput = {
  allergies?: Maybe<KitchenMenuItemCreateallergiesInput>;
  availability?: Maybe<KitchenMenuItemCreateavailabilityInput>;
  availableFrom?: Maybe<Scalars['DateTime']>;
  availableTo?: Maybe<Scalars['DateTime']>;
  cartItems?: Maybe<CartItemCreateNestedManyWithoutKitchenMenuItemInput>;
  cartUpsellItem?: Maybe<CartUpsellItemCreateNestedManyWithoutUpsellItemInput>;
  chowlyId?: Maybe<Scalars['String']>;
  cookTime?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  dishTypes?: Maybe<KitchenMenuItemCreatedishTypesInput>;
  enableInventoryCount: Scalars['Boolean'];
  extras?: Maybe<ExtraCreateNestedManyWithoutKitchenMenuItemsInput>;
  facilityItems?: Maybe<FacilityItemCreateNestedManyWithoutMenuItemInput>;
  globalSortId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  imgUrl?: Maybe<Scalars['String']>;
  inventoryCount?: Maybe<Scalars['Int']>;
  inventoryStatus?: Maybe<InventoryStatus>;
  isRetail?: Maybe<Scalars['Boolean']>;
  kitchen: KitchenCreateNestedOneWithoutKitchenMenuItemsInput;
  labels?: Maybe<KitchenMenuItemCreatelabelsInput>;
  longDescription?: Maybe<Scalars['String']>;
  mealPackItems?: Maybe<KitchenMenuItemCreateNestedManyWithoutMealPacksInput>;
  mealPacks?: Maybe<KitchenMenuItemCreateNestedManyWithoutMealPackItemsInput>;
  menuCategories?: Maybe<MenuCategoryCreateNestedManyWithoutMenuItemsInput>;
  menuScheduleCategories?: Maybe<MenuScheduleCategoryCreateNestedManyWithoutMenuItemsInput>;
  name: Scalars['String'];
  orderItems?: Maybe<OrderItemCreateNestedManyWithoutMenuItemInput>;
  price: Scalars['Float'];
  sortId?: Maybe<Scalars['Int']>;
  sortKitchenIds?: Maybe<KitchenMenuItemCreatesortKitchenIdsInput>;
  station?: Maybe<StationCreateNestedOneWithoutKitchenMenuItemsInput>;
  status: KitchenMenuItemStatus;
  type?: Maybe<MenuItemType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  upsellMenuItems?: Maybe<UpsellMenuItemCreateNestedManyWithoutUpsellItemInput>;
};

export type KitchenMenuItemCreateWithoutStationInput = {
  allergies?: Maybe<KitchenMenuItemCreateallergiesInput>;
  availability?: Maybe<KitchenMenuItemCreateavailabilityInput>;
  availableFrom?: Maybe<Scalars['DateTime']>;
  availableTo?: Maybe<Scalars['DateTime']>;
  cartItems?: Maybe<CartItemCreateNestedManyWithoutKitchenMenuItemInput>;
  cartUpsellItem?: Maybe<CartUpsellItemCreateNestedManyWithoutUpsellItemInput>;
  chowlyId?: Maybe<Scalars['String']>;
  cookTime?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  dishTypes?: Maybe<KitchenMenuItemCreatedishTypesInput>;
  enableInventoryCount: Scalars['Boolean'];
  extras?: Maybe<ExtraCreateNestedManyWithoutKitchenMenuItemsInput>;
  facilityItems?: Maybe<FacilityItemCreateNestedManyWithoutMenuItemInput>;
  globalSortId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  imgUrl?: Maybe<Scalars['String']>;
  inventoryCount?: Maybe<Scalars['Int']>;
  inventoryStatus?: Maybe<InventoryStatus>;
  isRetail?: Maybe<Scalars['Boolean']>;
  kitchen: KitchenCreateNestedOneWithoutKitchenMenuItemsInput;
  labels?: Maybe<KitchenMenuItemCreatelabelsInput>;
  longDescription?: Maybe<Scalars['String']>;
  mealPackItems?: Maybe<KitchenMenuItemCreateNestedManyWithoutMealPacksInput>;
  mealPacks?: Maybe<KitchenMenuItemCreateNestedManyWithoutMealPackItemsInput>;
  menuCategories?: Maybe<MenuCategoryCreateNestedManyWithoutMenuItemsInput>;
  menuScheduleCategories?: Maybe<MenuScheduleCategoryCreateNestedManyWithoutMenuItemsInput>;
  name: Scalars['String'];
  orderItems?: Maybe<OrderItemCreateNestedManyWithoutMenuItemInput>;
  parentItems?: Maybe<UpsellMenuItemCreateNestedManyWithoutParentItemInput>;
  price: Scalars['Float'];
  sortId?: Maybe<Scalars['Int']>;
  sortKitchenIds?: Maybe<KitchenMenuItemCreatesortKitchenIdsInput>;
  status: KitchenMenuItemStatus;
  type?: Maybe<MenuItemType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  upsellMenuItems?: Maybe<UpsellMenuItemCreateNestedManyWithoutUpsellItemInput>;
};

export type KitchenMenuItemCreateWithoutUpsellMenuItemsInput = {
  allergies?: Maybe<KitchenMenuItemCreateallergiesInput>;
  availability?: Maybe<KitchenMenuItemCreateavailabilityInput>;
  availableFrom?: Maybe<Scalars['DateTime']>;
  availableTo?: Maybe<Scalars['DateTime']>;
  cartItems?: Maybe<CartItemCreateNestedManyWithoutKitchenMenuItemInput>;
  cartUpsellItem?: Maybe<CartUpsellItemCreateNestedManyWithoutUpsellItemInput>;
  chowlyId?: Maybe<Scalars['String']>;
  cookTime?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  dishTypes?: Maybe<KitchenMenuItemCreatedishTypesInput>;
  enableInventoryCount: Scalars['Boolean'];
  extras?: Maybe<ExtraCreateNestedManyWithoutKitchenMenuItemsInput>;
  facilityItems?: Maybe<FacilityItemCreateNestedManyWithoutMenuItemInput>;
  globalSortId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  imgUrl?: Maybe<Scalars['String']>;
  inventoryCount?: Maybe<Scalars['Int']>;
  inventoryStatus?: Maybe<InventoryStatus>;
  isRetail?: Maybe<Scalars['Boolean']>;
  kitchen: KitchenCreateNestedOneWithoutKitchenMenuItemsInput;
  labels?: Maybe<KitchenMenuItemCreatelabelsInput>;
  longDescription?: Maybe<Scalars['String']>;
  mealPackItems?: Maybe<KitchenMenuItemCreateNestedManyWithoutMealPacksInput>;
  mealPacks?: Maybe<KitchenMenuItemCreateNestedManyWithoutMealPackItemsInput>;
  menuCategories?: Maybe<MenuCategoryCreateNestedManyWithoutMenuItemsInput>;
  menuScheduleCategories?: Maybe<MenuScheduleCategoryCreateNestedManyWithoutMenuItemsInput>;
  name: Scalars['String'];
  orderItems?: Maybe<OrderItemCreateNestedManyWithoutMenuItemInput>;
  parentItems?: Maybe<UpsellMenuItemCreateNestedManyWithoutParentItemInput>;
  price: Scalars['Float'];
  sortId?: Maybe<Scalars['Int']>;
  sortKitchenIds?: Maybe<KitchenMenuItemCreatesortKitchenIdsInput>;
  station?: Maybe<StationCreateNestedOneWithoutKitchenMenuItemsInput>;
  status: KitchenMenuItemStatus;
  type?: Maybe<MenuItemType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type KitchenMenuItemCreateallergiesInput = {
  set?: Maybe<Array<Allergy>>;
};

export type KitchenMenuItemCreateavailabilityInput = {
  set?: Maybe<Array<MenuItemAvailability>>;
};

export type KitchenMenuItemCreatedishTypesInput = {
  set?: Maybe<Array<DishType>>;
};

export type KitchenMenuItemCreatelabelsInput = {
  set?: Maybe<Array<MenuItemLabel>>;
};

export type KitchenMenuItemCreatesortKitchenIdsInput = {
  set?: Maybe<Array<Scalars['String']>>;
};

export type KitchenMenuItemInput = {
  allergies?: Maybe<Array<Allergy>>;
  availability?: Maybe<Array<MenuItemAvailability>>;
  availableFrom?: Maybe<Scalars['String']>;
  availableTo?: Maybe<Scalars['String']>;
  cookTime?: Maybe<Scalars['Float']>;
  description: Scalars['String'];
  dishTypes?: Maybe<Array<DishType>>;
  enableInventoryCount?: Maybe<Scalars['Boolean']>;
  extras?: Maybe<Array<Scalars['String']>>;
  facilityItems?: Maybe<Array<Scalars['String']>>;
  globalSortId?: Maybe<Scalars['Float']>;
  imgUrl?: Maybe<Scalars['String']>;
  inventoryCount?: Maybe<Scalars['Int']>;
  isRetail?: Maybe<Scalars['Boolean']>;
  kitchenId: Scalars['String'];
  labels?: Maybe<Array<MenuItemLabel>>;
  longDescription?: Maybe<Scalars['String']>;
  mealPackItems?: Maybe<Array<Scalars['String']>>;
  mealPackStatus?: Maybe<KitchenMenuItemStatus>;
  mealPacks?: Maybe<Array<Scalars['String']>>;
  menuCategories?: Maybe<Array<Scalars['String']>>;
  name: Scalars['String'];
  price: Scalars['Float'];
  sortId?: Maybe<Scalars['Float']>;
  sortKitchenIds?: Maybe<Array<Scalars['String']>>;
  stationId?: Maybe<Scalars['String']>;
  status: KitchenMenuItemStatus;
  type: MenuItemType;
  upsellMenuItems?: Maybe<Array<UpsellMenuItemCreateInput>>;
};

export type KitchenMenuItemListRelationFilter = {
  every?: Maybe<KitchenMenuItemWhereInput>;
  none?: Maybe<KitchenMenuItemWhereInput>;
  some?: Maybe<KitchenMenuItemWhereInput>;
};

export type KitchenMenuItemScalarWhereInput = {
  AND?: Maybe<Array<KitchenMenuItemScalarWhereInput>>;
  NOT?: Maybe<Array<KitchenMenuItemScalarWhereInput>>;
  OR?: Maybe<Array<KitchenMenuItemScalarWhereInput>>;
  allergies?: Maybe<EnumAllergyNullableListFilter>;
  availability?: Maybe<EnumMenuItemAvailabilityNullableListFilter>;
  availableFrom?: Maybe<DateTimeNullableFilter>;
  availableTo?: Maybe<DateTimeNullableFilter>;
  chowlyId?: Maybe<StringNullableFilter>;
  cookTime?: Maybe<IntNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  description?: Maybe<StringFilter>;
  dishTypes?: Maybe<EnumDishTypeNullableListFilter>;
  enableInventoryCount?: Maybe<BoolFilter>;
  globalSortId?: Maybe<IntFilter>;
  id?: Maybe<StringFilter>;
  imgUrl?: Maybe<StringNullableFilter>;
  inventoryCount?: Maybe<IntNullableFilter>;
  inventoryStatus?: Maybe<EnumInventoryStatusFilter>;
  isRetail?: Maybe<BoolFilter>;
  kitchenId?: Maybe<StringFilter>;
  labels?: Maybe<EnumMenuItemLabelNullableListFilter>;
  longDescription?: Maybe<StringNullableFilter>;
  name?: Maybe<StringFilter>;
  price?: Maybe<FloatFilter>;
  sortId?: Maybe<IntFilter>;
  sortKitchenIds?: Maybe<StringNullableListFilter>;
  stationId?: Maybe<StringNullableFilter>;
  status?: Maybe<EnumKitchenMenuItemStatusFilter>;
  type?: Maybe<EnumMenuItemTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export enum KitchenMenuItemStatus {
  Active = 'ACTIVE',
  Deleted = 'DELETED',
  Inactive = 'INACTIVE'
}

export type KitchenMenuItemUpdateManyMutationInput = {
  allergies?: Maybe<KitchenMenuItemUpdateallergiesInput>;
  availability?: Maybe<KitchenMenuItemUpdateavailabilityInput>;
  availableFrom?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  availableTo?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  chowlyId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cookTime?: Maybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<StringFieldUpdateOperationsInput>;
  dishTypes?: Maybe<KitchenMenuItemUpdatedishTypesInput>;
  enableInventoryCount?: Maybe<BoolFieldUpdateOperationsInput>;
  globalSortId?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  imgUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  inventoryCount?: Maybe<NullableIntFieldUpdateOperationsInput>;
  inventoryStatus?: Maybe<EnumInventoryStatusFieldUpdateOperationsInput>;
  isRetail?: Maybe<BoolFieldUpdateOperationsInput>;
  labels?: Maybe<KitchenMenuItemUpdatelabelsInput>;
  longDescription?: Maybe<NullableStringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  sortId?: Maybe<IntFieldUpdateOperationsInput>;
  sortKitchenIds?: Maybe<KitchenMenuItemUpdatesortKitchenIdsInput>;
  status?: Maybe<EnumKitchenMenuItemStatusFieldUpdateOperationsInput>;
  type?: Maybe<EnumMenuItemTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type KitchenMenuItemUpdateManyWithWhereWithoutExtrasInput = {
  data: KitchenMenuItemUpdateManyMutationInput;
  where: KitchenMenuItemScalarWhereInput;
};

export type KitchenMenuItemUpdateManyWithWhereWithoutKitchenInput = {
  data: KitchenMenuItemUpdateManyMutationInput;
  where: KitchenMenuItemScalarWhereInput;
};

export type KitchenMenuItemUpdateManyWithWhereWithoutMealPackItemsInput = {
  data: KitchenMenuItemUpdateManyMutationInput;
  where: KitchenMenuItemScalarWhereInput;
};

export type KitchenMenuItemUpdateManyWithWhereWithoutMealPacksInput = {
  data: KitchenMenuItemUpdateManyMutationInput;
  where: KitchenMenuItemScalarWhereInput;
};

export type KitchenMenuItemUpdateManyWithWhereWithoutMenuCategoriesInput = {
  data: KitchenMenuItemUpdateManyMutationInput;
  where: KitchenMenuItemScalarWhereInput;
};

export type KitchenMenuItemUpdateManyWithWhereWithoutMenuScheduleCategoriesInput = {
  data: KitchenMenuItemUpdateManyMutationInput;
  where: KitchenMenuItemScalarWhereInput;
};

export type KitchenMenuItemUpdateManyWithWhereWithoutStationInput = {
  data: KitchenMenuItemUpdateManyMutationInput;
  where: KitchenMenuItemScalarWhereInput;
};

export type KitchenMenuItemUpdateManyWithoutExtrasInput = {
  connect?: Maybe<Array<KitchenMenuItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<KitchenMenuItemCreateOrConnectWithoutExtrasInput>>;
  create?: Maybe<Array<KitchenMenuItemCreateWithoutExtrasInput>>;
  delete?: Maybe<Array<KitchenMenuItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<KitchenMenuItemScalarWhereInput>>;
  disconnect?: Maybe<Array<KitchenMenuItemWhereUniqueInput>>;
  set?: Maybe<Array<KitchenMenuItemWhereUniqueInput>>;
  update?: Maybe<Array<KitchenMenuItemUpdateWithWhereUniqueWithoutExtrasInput>>;
  updateMany?: Maybe<Array<KitchenMenuItemUpdateManyWithWhereWithoutExtrasInput>>;
  upsert?: Maybe<Array<KitchenMenuItemUpsertWithWhereUniqueWithoutExtrasInput>>;
};

export type KitchenMenuItemUpdateManyWithoutKitchenInput = {
  connect?: Maybe<Array<KitchenMenuItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<KitchenMenuItemCreateOrConnectWithoutKitchenInput>>;
  create?: Maybe<Array<KitchenMenuItemCreateWithoutKitchenInput>>;
  createMany?: Maybe<KitchenMenuItemCreateManyKitchenInputEnvelope>;
  delete?: Maybe<Array<KitchenMenuItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<KitchenMenuItemScalarWhereInput>>;
  disconnect?: Maybe<Array<KitchenMenuItemWhereUniqueInput>>;
  set?: Maybe<Array<KitchenMenuItemWhereUniqueInput>>;
  update?: Maybe<Array<KitchenMenuItemUpdateWithWhereUniqueWithoutKitchenInput>>;
  updateMany?: Maybe<Array<KitchenMenuItemUpdateManyWithWhereWithoutKitchenInput>>;
  upsert?: Maybe<Array<KitchenMenuItemUpsertWithWhereUniqueWithoutKitchenInput>>;
};

export type KitchenMenuItemUpdateManyWithoutMealPackItemsInput = {
  connect?: Maybe<Array<KitchenMenuItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<KitchenMenuItemCreateOrConnectWithoutMealPackItemsInput>>;
  create?: Maybe<Array<KitchenMenuItemCreateWithoutMealPackItemsInput>>;
  delete?: Maybe<Array<KitchenMenuItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<KitchenMenuItemScalarWhereInput>>;
  disconnect?: Maybe<Array<KitchenMenuItemWhereUniqueInput>>;
  set?: Maybe<Array<KitchenMenuItemWhereUniqueInput>>;
  update?: Maybe<Array<KitchenMenuItemUpdateWithWhereUniqueWithoutMealPackItemsInput>>;
  updateMany?: Maybe<Array<KitchenMenuItemUpdateManyWithWhereWithoutMealPackItemsInput>>;
  upsert?: Maybe<Array<KitchenMenuItemUpsertWithWhereUniqueWithoutMealPackItemsInput>>;
};

export type KitchenMenuItemUpdateManyWithoutMealPacksInput = {
  connect?: Maybe<Array<KitchenMenuItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<KitchenMenuItemCreateOrConnectWithoutMealPacksInput>>;
  create?: Maybe<Array<KitchenMenuItemCreateWithoutMealPacksInput>>;
  delete?: Maybe<Array<KitchenMenuItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<KitchenMenuItemScalarWhereInput>>;
  disconnect?: Maybe<Array<KitchenMenuItemWhereUniqueInput>>;
  set?: Maybe<Array<KitchenMenuItemWhereUniqueInput>>;
  update?: Maybe<Array<KitchenMenuItemUpdateWithWhereUniqueWithoutMealPacksInput>>;
  updateMany?: Maybe<Array<KitchenMenuItemUpdateManyWithWhereWithoutMealPacksInput>>;
  upsert?: Maybe<Array<KitchenMenuItemUpsertWithWhereUniqueWithoutMealPacksInput>>;
};

export type KitchenMenuItemUpdateManyWithoutMenuCategoriesInput = {
  connect?: Maybe<Array<KitchenMenuItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<KitchenMenuItemCreateOrConnectWithoutMenuCategoriesInput>>;
  create?: Maybe<Array<KitchenMenuItemCreateWithoutMenuCategoriesInput>>;
  delete?: Maybe<Array<KitchenMenuItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<KitchenMenuItemScalarWhereInput>>;
  disconnect?: Maybe<Array<KitchenMenuItemWhereUniqueInput>>;
  set?: Maybe<Array<KitchenMenuItemWhereUniqueInput>>;
  update?: Maybe<Array<KitchenMenuItemUpdateWithWhereUniqueWithoutMenuCategoriesInput>>;
  updateMany?: Maybe<Array<KitchenMenuItemUpdateManyWithWhereWithoutMenuCategoriesInput>>;
  upsert?: Maybe<Array<KitchenMenuItemUpsertWithWhereUniqueWithoutMenuCategoriesInput>>;
};

export type KitchenMenuItemUpdateManyWithoutMenuScheduleCategoriesInput = {
  connect?: Maybe<Array<KitchenMenuItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<KitchenMenuItemCreateOrConnectWithoutMenuScheduleCategoriesInput>>;
  create?: Maybe<Array<KitchenMenuItemCreateWithoutMenuScheduleCategoriesInput>>;
  delete?: Maybe<Array<KitchenMenuItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<KitchenMenuItemScalarWhereInput>>;
  disconnect?: Maybe<Array<KitchenMenuItemWhereUniqueInput>>;
  set?: Maybe<Array<KitchenMenuItemWhereUniqueInput>>;
  update?: Maybe<Array<KitchenMenuItemUpdateWithWhereUniqueWithoutMenuScheduleCategoriesInput>>;
  updateMany?: Maybe<Array<KitchenMenuItemUpdateManyWithWhereWithoutMenuScheduleCategoriesInput>>;
  upsert?: Maybe<Array<KitchenMenuItemUpsertWithWhereUniqueWithoutMenuScheduleCategoriesInput>>;
};

export type KitchenMenuItemUpdateManyWithoutStationInput = {
  connect?: Maybe<Array<KitchenMenuItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<KitchenMenuItemCreateOrConnectWithoutStationInput>>;
  create?: Maybe<Array<KitchenMenuItemCreateWithoutStationInput>>;
  createMany?: Maybe<KitchenMenuItemCreateManyStationInputEnvelope>;
  delete?: Maybe<Array<KitchenMenuItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<KitchenMenuItemScalarWhereInput>>;
  disconnect?: Maybe<Array<KitchenMenuItemWhereUniqueInput>>;
  set?: Maybe<Array<KitchenMenuItemWhereUniqueInput>>;
  update?: Maybe<Array<KitchenMenuItemUpdateWithWhereUniqueWithoutStationInput>>;
  updateMany?: Maybe<Array<KitchenMenuItemUpdateManyWithWhereWithoutStationInput>>;
  upsert?: Maybe<Array<KitchenMenuItemUpsertWithWhereUniqueWithoutStationInput>>;
};

export type KitchenMenuItemUpdateOneRequiredWithoutCartItemsInput = {
  connect?: Maybe<KitchenMenuItemWhereUniqueInput>;
  connectOrCreate?: Maybe<KitchenMenuItemCreateOrConnectWithoutCartItemsInput>;
  create?: Maybe<KitchenMenuItemCreateWithoutCartItemsInput>;
  update?: Maybe<KitchenMenuItemUpdateWithoutCartItemsInput>;
  upsert?: Maybe<KitchenMenuItemUpsertWithoutCartItemsInput>;
};

export type KitchenMenuItemUpdateOneRequiredWithoutCartUpsellItemInput = {
  connect?: Maybe<KitchenMenuItemWhereUniqueInput>;
  connectOrCreate?: Maybe<KitchenMenuItemCreateOrConnectWithoutCartUpsellItemInput>;
  create?: Maybe<KitchenMenuItemCreateWithoutCartUpsellItemInput>;
  update?: Maybe<KitchenMenuItemUpdateWithoutCartUpsellItemInput>;
  upsert?: Maybe<KitchenMenuItemUpsertWithoutCartUpsellItemInput>;
};

export type KitchenMenuItemUpdateOneRequiredWithoutFacilityItemsInput = {
  connect?: Maybe<KitchenMenuItemWhereUniqueInput>;
  connectOrCreate?: Maybe<KitchenMenuItemCreateOrConnectWithoutFacilityItemsInput>;
  create?: Maybe<KitchenMenuItemCreateWithoutFacilityItemsInput>;
  update?: Maybe<KitchenMenuItemUpdateWithoutFacilityItemsInput>;
  upsert?: Maybe<KitchenMenuItemUpsertWithoutFacilityItemsInput>;
};

export type KitchenMenuItemUpdateOneRequiredWithoutOrderItemsInput = {
  connect?: Maybe<KitchenMenuItemWhereUniqueInput>;
  connectOrCreate?: Maybe<KitchenMenuItemCreateOrConnectWithoutOrderItemsInput>;
  create?: Maybe<KitchenMenuItemCreateWithoutOrderItemsInput>;
  update?: Maybe<KitchenMenuItemUpdateWithoutOrderItemsInput>;
  upsert?: Maybe<KitchenMenuItemUpsertWithoutOrderItemsInput>;
};

export type KitchenMenuItemUpdateOneRequiredWithoutParentItemsInput = {
  connect?: Maybe<KitchenMenuItemWhereUniqueInput>;
  connectOrCreate?: Maybe<KitchenMenuItemCreateOrConnectWithoutParentItemsInput>;
  create?: Maybe<KitchenMenuItemCreateWithoutParentItemsInput>;
  update?: Maybe<KitchenMenuItemUpdateWithoutParentItemsInput>;
  upsert?: Maybe<KitchenMenuItemUpsertWithoutParentItemsInput>;
};

export type KitchenMenuItemUpdateOneRequiredWithoutUpsellMenuItemsInput = {
  connect?: Maybe<KitchenMenuItemWhereUniqueInput>;
  connectOrCreate?: Maybe<KitchenMenuItemCreateOrConnectWithoutUpsellMenuItemsInput>;
  create?: Maybe<KitchenMenuItemCreateWithoutUpsellMenuItemsInput>;
  update?: Maybe<KitchenMenuItemUpdateWithoutUpsellMenuItemsInput>;
  upsert?: Maybe<KitchenMenuItemUpsertWithoutUpsellMenuItemsInput>;
};

export type KitchenMenuItemUpdateWithWhereUniqueWithoutExtrasInput = {
  data: KitchenMenuItemUpdateWithoutExtrasInput;
  where: KitchenMenuItemWhereUniqueInput;
};

export type KitchenMenuItemUpdateWithWhereUniqueWithoutKitchenInput = {
  data: KitchenMenuItemUpdateWithoutKitchenInput;
  where: KitchenMenuItemWhereUniqueInput;
};

export type KitchenMenuItemUpdateWithWhereUniqueWithoutMealPackItemsInput = {
  data: KitchenMenuItemUpdateWithoutMealPackItemsInput;
  where: KitchenMenuItemWhereUniqueInput;
};

export type KitchenMenuItemUpdateWithWhereUniqueWithoutMealPacksInput = {
  data: KitchenMenuItemUpdateWithoutMealPacksInput;
  where: KitchenMenuItemWhereUniqueInput;
};

export type KitchenMenuItemUpdateWithWhereUniqueWithoutMenuCategoriesInput = {
  data: KitchenMenuItemUpdateWithoutMenuCategoriesInput;
  where: KitchenMenuItemWhereUniqueInput;
};

export type KitchenMenuItemUpdateWithWhereUniqueWithoutMenuScheduleCategoriesInput = {
  data: KitchenMenuItemUpdateWithoutMenuScheduleCategoriesInput;
  where: KitchenMenuItemWhereUniqueInput;
};

export type KitchenMenuItemUpdateWithWhereUniqueWithoutStationInput = {
  data: KitchenMenuItemUpdateWithoutStationInput;
  where: KitchenMenuItemWhereUniqueInput;
};

export type KitchenMenuItemUpdateWithoutCartItemsInput = {
  allergies?: Maybe<KitchenMenuItemUpdateallergiesInput>;
  availability?: Maybe<KitchenMenuItemUpdateavailabilityInput>;
  availableFrom?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  availableTo?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cartUpsellItem?: Maybe<CartUpsellItemUpdateManyWithoutUpsellItemInput>;
  chowlyId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cookTime?: Maybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<StringFieldUpdateOperationsInput>;
  dishTypes?: Maybe<KitchenMenuItemUpdatedishTypesInput>;
  enableInventoryCount?: Maybe<BoolFieldUpdateOperationsInput>;
  extras?: Maybe<ExtraUpdateManyWithoutKitchenMenuItemsInput>;
  facilityItems?: Maybe<FacilityItemUpdateManyWithoutMenuItemInput>;
  globalSortId?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  imgUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  inventoryCount?: Maybe<NullableIntFieldUpdateOperationsInput>;
  inventoryStatus?: Maybe<EnumInventoryStatusFieldUpdateOperationsInput>;
  isRetail?: Maybe<BoolFieldUpdateOperationsInput>;
  kitchen?: Maybe<KitchenUpdateOneRequiredWithoutKitchenMenuItemsInput>;
  labels?: Maybe<KitchenMenuItemUpdatelabelsInput>;
  longDescription?: Maybe<NullableStringFieldUpdateOperationsInput>;
  mealPackItems?: Maybe<KitchenMenuItemUpdateManyWithoutMealPacksInput>;
  mealPacks?: Maybe<KitchenMenuItemUpdateManyWithoutMealPackItemsInput>;
  menuCategories?: Maybe<MenuCategoryUpdateManyWithoutMenuItemsInput>;
  menuScheduleCategories?: Maybe<MenuScheduleCategoryUpdateManyWithoutMenuItemsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  orderItems?: Maybe<OrderItemUpdateManyWithoutMenuItemInput>;
  parentItems?: Maybe<UpsellMenuItemUpdateManyWithoutParentItemInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  sortId?: Maybe<IntFieldUpdateOperationsInput>;
  sortKitchenIds?: Maybe<KitchenMenuItemUpdatesortKitchenIdsInput>;
  station?: Maybe<StationUpdateOneWithoutKitchenMenuItemsInput>;
  status?: Maybe<EnumKitchenMenuItemStatusFieldUpdateOperationsInput>;
  type?: Maybe<EnumMenuItemTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  upsellMenuItems?: Maybe<UpsellMenuItemUpdateManyWithoutUpsellItemInput>;
};

export type KitchenMenuItemUpdateWithoutCartUpsellItemInput = {
  allergies?: Maybe<KitchenMenuItemUpdateallergiesInput>;
  availability?: Maybe<KitchenMenuItemUpdateavailabilityInput>;
  availableFrom?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  availableTo?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cartItems?: Maybe<CartItemUpdateManyWithoutKitchenMenuItemInput>;
  chowlyId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cookTime?: Maybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<StringFieldUpdateOperationsInput>;
  dishTypes?: Maybe<KitchenMenuItemUpdatedishTypesInput>;
  enableInventoryCount?: Maybe<BoolFieldUpdateOperationsInput>;
  extras?: Maybe<ExtraUpdateManyWithoutKitchenMenuItemsInput>;
  facilityItems?: Maybe<FacilityItemUpdateManyWithoutMenuItemInput>;
  globalSortId?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  imgUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  inventoryCount?: Maybe<NullableIntFieldUpdateOperationsInput>;
  inventoryStatus?: Maybe<EnumInventoryStatusFieldUpdateOperationsInput>;
  isRetail?: Maybe<BoolFieldUpdateOperationsInput>;
  kitchen?: Maybe<KitchenUpdateOneRequiredWithoutKitchenMenuItemsInput>;
  labels?: Maybe<KitchenMenuItemUpdatelabelsInput>;
  longDescription?: Maybe<NullableStringFieldUpdateOperationsInput>;
  mealPackItems?: Maybe<KitchenMenuItemUpdateManyWithoutMealPacksInput>;
  mealPacks?: Maybe<KitchenMenuItemUpdateManyWithoutMealPackItemsInput>;
  menuCategories?: Maybe<MenuCategoryUpdateManyWithoutMenuItemsInput>;
  menuScheduleCategories?: Maybe<MenuScheduleCategoryUpdateManyWithoutMenuItemsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  orderItems?: Maybe<OrderItemUpdateManyWithoutMenuItemInput>;
  parentItems?: Maybe<UpsellMenuItemUpdateManyWithoutParentItemInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  sortId?: Maybe<IntFieldUpdateOperationsInput>;
  sortKitchenIds?: Maybe<KitchenMenuItemUpdatesortKitchenIdsInput>;
  station?: Maybe<StationUpdateOneWithoutKitchenMenuItemsInput>;
  status?: Maybe<EnumKitchenMenuItemStatusFieldUpdateOperationsInput>;
  type?: Maybe<EnumMenuItemTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  upsellMenuItems?: Maybe<UpsellMenuItemUpdateManyWithoutUpsellItemInput>;
};

export type KitchenMenuItemUpdateWithoutExtrasInput = {
  allergies?: Maybe<KitchenMenuItemUpdateallergiesInput>;
  availability?: Maybe<KitchenMenuItemUpdateavailabilityInput>;
  availableFrom?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  availableTo?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cartItems?: Maybe<CartItemUpdateManyWithoutKitchenMenuItemInput>;
  cartUpsellItem?: Maybe<CartUpsellItemUpdateManyWithoutUpsellItemInput>;
  chowlyId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cookTime?: Maybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<StringFieldUpdateOperationsInput>;
  dishTypes?: Maybe<KitchenMenuItemUpdatedishTypesInput>;
  enableInventoryCount?: Maybe<BoolFieldUpdateOperationsInput>;
  facilityItems?: Maybe<FacilityItemUpdateManyWithoutMenuItemInput>;
  globalSortId?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  imgUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  inventoryCount?: Maybe<NullableIntFieldUpdateOperationsInput>;
  inventoryStatus?: Maybe<EnumInventoryStatusFieldUpdateOperationsInput>;
  isRetail?: Maybe<BoolFieldUpdateOperationsInput>;
  kitchen?: Maybe<KitchenUpdateOneRequiredWithoutKitchenMenuItemsInput>;
  labels?: Maybe<KitchenMenuItemUpdatelabelsInput>;
  longDescription?: Maybe<NullableStringFieldUpdateOperationsInput>;
  mealPackItems?: Maybe<KitchenMenuItemUpdateManyWithoutMealPacksInput>;
  mealPacks?: Maybe<KitchenMenuItemUpdateManyWithoutMealPackItemsInput>;
  menuCategories?: Maybe<MenuCategoryUpdateManyWithoutMenuItemsInput>;
  menuScheduleCategories?: Maybe<MenuScheduleCategoryUpdateManyWithoutMenuItemsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  orderItems?: Maybe<OrderItemUpdateManyWithoutMenuItemInput>;
  parentItems?: Maybe<UpsellMenuItemUpdateManyWithoutParentItemInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  sortId?: Maybe<IntFieldUpdateOperationsInput>;
  sortKitchenIds?: Maybe<KitchenMenuItemUpdatesortKitchenIdsInput>;
  station?: Maybe<StationUpdateOneWithoutKitchenMenuItemsInput>;
  status?: Maybe<EnumKitchenMenuItemStatusFieldUpdateOperationsInput>;
  type?: Maybe<EnumMenuItemTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  upsellMenuItems?: Maybe<UpsellMenuItemUpdateManyWithoutUpsellItemInput>;
};

export type KitchenMenuItemUpdateWithoutFacilityItemsInput = {
  allergies?: Maybe<KitchenMenuItemUpdateallergiesInput>;
  availability?: Maybe<KitchenMenuItemUpdateavailabilityInput>;
  availableFrom?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  availableTo?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cartItems?: Maybe<CartItemUpdateManyWithoutKitchenMenuItemInput>;
  cartUpsellItem?: Maybe<CartUpsellItemUpdateManyWithoutUpsellItemInput>;
  chowlyId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cookTime?: Maybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<StringFieldUpdateOperationsInput>;
  dishTypes?: Maybe<KitchenMenuItemUpdatedishTypesInput>;
  enableInventoryCount?: Maybe<BoolFieldUpdateOperationsInput>;
  extras?: Maybe<ExtraUpdateManyWithoutKitchenMenuItemsInput>;
  globalSortId?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  imgUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  inventoryCount?: Maybe<NullableIntFieldUpdateOperationsInput>;
  inventoryStatus?: Maybe<EnumInventoryStatusFieldUpdateOperationsInput>;
  isRetail?: Maybe<BoolFieldUpdateOperationsInput>;
  kitchen?: Maybe<KitchenUpdateOneRequiredWithoutKitchenMenuItemsInput>;
  labels?: Maybe<KitchenMenuItemUpdatelabelsInput>;
  longDescription?: Maybe<NullableStringFieldUpdateOperationsInput>;
  mealPackItems?: Maybe<KitchenMenuItemUpdateManyWithoutMealPacksInput>;
  mealPacks?: Maybe<KitchenMenuItemUpdateManyWithoutMealPackItemsInput>;
  menuCategories?: Maybe<MenuCategoryUpdateManyWithoutMenuItemsInput>;
  menuScheduleCategories?: Maybe<MenuScheduleCategoryUpdateManyWithoutMenuItemsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  orderItems?: Maybe<OrderItemUpdateManyWithoutMenuItemInput>;
  parentItems?: Maybe<UpsellMenuItemUpdateManyWithoutParentItemInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  sortId?: Maybe<IntFieldUpdateOperationsInput>;
  sortKitchenIds?: Maybe<KitchenMenuItemUpdatesortKitchenIdsInput>;
  station?: Maybe<StationUpdateOneWithoutKitchenMenuItemsInput>;
  status?: Maybe<EnumKitchenMenuItemStatusFieldUpdateOperationsInput>;
  type?: Maybe<EnumMenuItemTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  upsellMenuItems?: Maybe<UpsellMenuItemUpdateManyWithoutUpsellItemInput>;
};

export type KitchenMenuItemUpdateWithoutKitchenInput = {
  allergies?: Maybe<KitchenMenuItemUpdateallergiesInput>;
  availability?: Maybe<KitchenMenuItemUpdateavailabilityInput>;
  availableFrom?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  availableTo?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cartItems?: Maybe<CartItemUpdateManyWithoutKitchenMenuItemInput>;
  cartUpsellItem?: Maybe<CartUpsellItemUpdateManyWithoutUpsellItemInput>;
  chowlyId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cookTime?: Maybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<StringFieldUpdateOperationsInput>;
  dishTypes?: Maybe<KitchenMenuItemUpdatedishTypesInput>;
  enableInventoryCount?: Maybe<BoolFieldUpdateOperationsInput>;
  extras?: Maybe<ExtraUpdateManyWithoutKitchenMenuItemsInput>;
  facilityItems?: Maybe<FacilityItemUpdateManyWithoutMenuItemInput>;
  globalSortId?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  imgUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  inventoryCount?: Maybe<NullableIntFieldUpdateOperationsInput>;
  inventoryStatus?: Maybe<EnumInventoryStatusFieldUpdateOperationsInput>;
  isRetail?: Maybe<BoolFieldUpdateOperationsInput>;
  labels?: Maybe<KitchenMenuItemUpdatelabelsInput>;
  longDescription?: Maybe<NullableStringFieldUpdateOperationsInput>;
  mealPackItems?: Maybe<KitchenMenuItemUpdateManyWithoutMealPacksInput>;
  mealPacks?: Maybe<KitchenMenuItemUpdateManyWithoutMealPackItemsInput>;
  menuCategories?: Maybe<MenuCategoryUpdateManyWithoutMenuItemsInput>;
  menuScheduleCategories?: Maybe<MenuScheduleCategoryUpdateManyWithoutMenuItemsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  orderItems?: Maybe<OrderItemUpdateManyWithoutMenuItemInput>;
  parentItems?: Maybe<UpsellMenuItemUpdateManyWithoutParentItemInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  sortId?: Maybe<IntFieldUpdateOperationsInput>;
  sortKitchenIds?: Maybe<KitchenMenuItemUpdatesortKitchenIdsInput>;
  station?: Maybe<StationUpdateOneWithoutKitchenMenuItemsInput>;
  status?: Maybe<EnumKitchenMenuItemStatusFieldUpdateOperationsInput>;
  type?: Maybe<EnumMenuItemTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  upsellMenuItems?: Maybe<UpsellMenuItemUpdateManyWithoutUpsellItemInput>;
};

export type KitchenMenuItemUpdateWithoutMealPackItemsInput = {
  allergies?: Maybe<KitchenMenuItemUpdateallergiesInput>;
  availability?: Maybe<KitchenMenuItemUpdateavailabilityInput>;
  availableFrom?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  availableTo?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cartItems?: Maybe<CartItemUpdateManyWithoutKitchenMenuItemInput>;
  cartUpsellItem?: Maybe<CartUpsellItemUpdateManyWithoutUpsellItemInput>;
  chowlyId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cookTime?: Maybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<StringFieldUpdateOperationsInput>;
  dishTypes?: Maybe<KitchenMenuItemUpdatedishTypesInput>;
  enableInventoryCount?: Maybe<BoolFieldUpdateOperationsInput>;
  extras?: Maybe<ExtraUpdateManyWithoutKitchenMenuItemsInput>;
  facilityItems?: Maybe<FacilityItemUpdateManyWithoutMenuItemInput>;
  globalSortId?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  imgUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  inventoryCount?: Maybe<NullableIntFieldUpdateOperationsInput>;
  inventoryStatus?: Maybe<EnumInventoryStatusFieldUpdateOperationsInput>;
  isRetail?: Maybe<BoolFieldUpdateOperationsInput>;
  kitchen?: Maybe<KitchenUpdateOneRequiredWithoutKitchenMenuItemsInput>;
  labels?: Maybe<KitchenMenuItemUpdatelabelsInput>;
  longDescription?: Maybe<NullableStringFieldUpdateOperationsInput>;
  mealPacks?: Maybe<KitchenMenuItemUpdateManyWithoutMealPackItemsInput>;
  menuCategories?: Maybe<MenuCategoryUpdateManyWithoutMenuItemsInput>;
  menuScheduleCategories?: Maybe<MenuScheduleCategoryUpdateManyWithoutMenuItemsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  orderItems?: Maybe<OrderItemUpdateManyWithoutMenuItemInput>;
  parentItems?: Maybe<UpsellMenuItemUpdateManyWithoutParentItemInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  sortId?: Maybe<IntFieldUpdateOperationsInput>;
  sortKitchenIds?: Maybe<KitchenMenuItemUpdatesortKitchenIdsInput>;
  station?: Maybe<StationUpdateOneWithoutKitchenMenuItemsInput>;
  status?: Maybe<EnumKitchenMenuItemStatusFieldUpdateOperationsInput>;
  type?: Maybe<EnumMenuItemTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  upsellMenuItems?: Maybe<UpsellMenuItemUpdateManyWithoutUpsellItemInput>;
};

export type KitchenMenuItemUpdateWithoutMealPacksInput = {
  allergies?: Maybe<KitchenMenuItemUpdateallergiesInput>;
  availability?: Maybe<KitchenMenuItemUpdateavailabilityInput>;
  availableFrom?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  availableTo?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cartItems?: Maybe<CartItemUpdateManyWithoutKitchenMenuItemInput>;
  cartUpsellItem?: Maybe<CartUpsellItemUpdateManyWithoutUpsellItemInput>;
  chowlyId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cookTime?: Maybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<StringFieldUpdateOperationsInput>;
  dishTypes?: Maybe<KitchenMenuItemUpdatedishTypesInput>;
  enableInventoryCount?: Maybe<BoolFieldUpdateOperationsInput>;
  extras?: Maybe<ExtraUpdateManyWithoutKitchenMenuItemsInput>;
  facilityItems?: Maybe<FacilityItemUpdateManyWithoutMenuItemInput>;
  globalSortId?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  imgUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  inventoryCount?: Maybe<NullableIntFieldUpdateOperationsInput>;
  inventoryStatus?: Maybe<EnumInventoryStatusFieldUpdateOperationsInput>;
  isRetail?: Maybe<BoolFieldUpdateOperationsInput>;
  kitchen?: Maybe<KitchenUpdateOneRequiredWithoutKitchenMenuItemsInput>;
  labels?: Maybe<KitchenMenuItemUpdatelabelsInput>;
  longDescription?: Maybe<NullableStringFieldUpdateOperationsInput>;
  mealPackItems?: Maybe<KitchenMenuItemUpdateManyWithoutMealPacksInput>;
  menuCategories?: Maybe<MenuCategoryUpdateManyWithoutMenuItemsInput>;
  menuScheduleCategories?: Maybe<MenuScheduleCategoryUpdateManyWithoutMenuItemsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  orderItems?: Maybe<OrderItemUpdateManyWithoutMenuItemInput>;
  parentItems?: Maybe<UpsellMenuItemUpdateManyWithoutParentItemInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  sortId?: Maybe<IntFieldUpdateOperationsInput>;
  sortKitchenIds?: Maybe<KitchenMenuItemUpdatesortKitchenIdsInput>;
  station?: Maybe<StationUpdateOneWithoutKitchenMenuItemsInput>;
  status?: Maybe<EnumKitchenMenuItemStatusFieldUpdateOperationsInput>;
  type?: Maybe<EnumMenuItemTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  upsellMenuItems?: Maybe<UpsellMenuItemUpdateManyWithoutUpsellItemInput>;
};

export type KitchenMenuItemUpdateWithoutMenuCategoriesInput = {
  allergies?: Maybe<KitchenMenuItemUpdateallergiesInput>;
  availability?: Maybe<KitchenMenuItemUpdateavailabilityInput>;
  availableFrom?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  availableTo?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cartItems?: Maybe<CartItemUpdateManyWithoutKitchenMenuItemInput>;
  cartUpsellItem?: Maybe<CartUpsellItemUpdateManyWithoutUpsellItemInput>;
  chowlyId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cookTime?: Maybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<StringFieldUpdateOperationsInput>;
  dishTypes?: Maybe<KitchenMenuItemUpdatedishTypesInput>;
  enableInventoryCount?: Maybe<BoolFieldUpdateOperationsInput>;
  extras?: Maybe<ExtraUpdateManyWithoutKitchenMenuItemsInput>;
  facilityItems?: Maybe<FacilityItemUpdateManyWithoutMenuItemInput>;
  globalSortId?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  imgUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  inventoryCount?: Maybe<NullableIntFieldUpdateOperationsInput>;
  inventoryStatus?: Maybe<EnumInventoryStatusFieldUpdateOperationsInput>;
  isRetail?: Maybe<BoolFieldUpdateOperationsInput>;
  kitchen?: Maybe<KitchenUpdateOneRequiredWithoutKitchenMenuItemsInput>;
  labels?: Maybe<KitchenMenuItemUpdatelabelsInput>;
  longDescription?: Maybe<NullableStringFieldUpdateOperationsInput>;
  mealPackItems?: Maybe<KitchenMenuItemUpdateManyWithoutMealPacksInput>;
  mealPacks?: Maybe<KitchenMenuItemUpdateManyWithoutMealPackItemsInput>;
  menuScheduleCategories?: Maybe<MenuScheduleCategoryUpdateManyWithoutMenuItemsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  orderItems?: Maybe<OrderItemUpdateManyWithoutMenuItemInput>;
  parentItems?: Maybe<UpsellMenuItemUpdateManyWithoutParentItemInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  sortId?: Maybe<IntFieldUpdateOperationsInput>;
  sortKitchenIds?: Maybe<KitchenMenuItemUpdatesortKitchenIdsInput>;
  station?: Maybe<StationUpdateOneWithoutKitchenMenuItemsInput>;
  status?: Maybe<EnumKitchenMenuItemStatusFieldUpdateOperationsInput>;
  type?: Maybe<EnumMenuItemTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  upsellMenuItems?: Maybe<UpsellMenuItemUpdateManyWithoutUpsellItemInput>;
};

export type KitchenMenuItemUpdateWithoutMenuScheduleCategoriesInput = {
  allergies?: Maybe<KitchenMenuItemUpdateallergiesInput>;
  availability?: Maybe<KitchenMenuItemUpdateavailabilityInput>;
  availableFrom?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  availableTo?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cartItems?: Maybe<CartItemUpdateManyWithoutKitchenMenuItemInput>;
  cartUpsellItem?: Maybe<CartUpsellItemUpdateManyWithoutUpsellItemInput>;
  chowlyId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cookTime?: Maybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<StringFieldUpdateOperationsInput>;
  dishTypes?: Maybe<KitchenMenuItemUpdatedishTypesInput>;
  enableInventoryCount?: Maybe<BoolFieldUpdateOperationsInput>;
  extras?: Maybe<ExtraUpdateManyWithoutKitchenMenuItemsInput>;
  facilityItems?: Maybe<FacilityItemUpdateManyWithoutMenuItemInput>;
  globalSortId?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  imgUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  inventoryCount?: Maybe<NullableIntFieldUpdateOperationsInput>;
  inventoryStatus?: Maybe<EnumInventoryStatusFieldUpdateOperationsInput>;
  isRetail?: Maybe<BoolFieldUpdateOperationsInput>;
  kitchen?: Maybe<KitchenUpdateOneRequiredWithoutKitchenMenuItemsInput>;
  labels?: Maybe<KitchenMenuItemUpdatelabelsInput>;
  longDescription?: Maybe<NullableStringFieldUpdateOperationsInput>;
  mealPackItems?: Maybe<KitchenMenuItemUpdateManyWithoutMealPacksInput>;
  mealPacks?: Maybe<KitchenMenuItemUpdateManyWithoutMealPackItemsInput>;
  menuCategories?: Maybe<MenuCategoryUpdateManyWithoutMenuItemsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  orderItems?: Maybe<OrderItemUpdateManyWithoutMenuItemInput>;
  parentItems?: Maybe<UpsellMenuItemUpdateManyWithoutParentItemInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  sortId?: Maybe<IntFieldUpdateOperationsInput>;
  sortKitchenIds?: Maybe<KitchenMenuItemUpdatesortKitchenIdsInput>;
  station?: Maybe<StationUpdateOneWithoutKitchenMenuItemsInput>;
  status?: Maybe<EnumKitchenMenuItemStatusFieldUpdateOperationsInput>;
  type?: Maybe<EnumMenuItemTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  upsellMenuItems?: Maybe<UpsellMenuItemUpdateManyWithoutUpsellItemInput>;
};

export type KitchenMenuItemUpdateWithoutOrderItemsInput = {
  allergies?: Maybe<KitchenMenuItemUpdateallergiesInput>;
  availability?: Maybe<KitchenMenuItemUpdateavailabilityInput>;
  availableFrom?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  availableTo?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cartItems?: Maybe<CartItemUpdateManyWithoutKitchenMenuItemInput>;
  cartUpsellItem?: Maybe<CartUpsellItemUpdateManyWithoutUpsellItemInput>;
  chowlyId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cookTime?: Maybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<StringFieldUpdateOperationsInput>;
  dishTypes?: Maybe<KitchenMenuItemUpdatedishTypesInput>;
  enableInventoryCount?: Maybe<BoolFieldUpdateOperationsInput>;
  extras?: Maybe<ExtraUpdateManyWithoutKitchenMenuItemsInput>;
  facilityItems?: Maybe<FacilityItemUpdateManyWithoutMenuItemInput>;
  globalSortId?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  imgUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  inventoryCount?: Maybe<NullableIntFieldUpdateOperationsInput>;
  inventoryStatus?: Maybe<EnumInventoryStatusFieldUpdateOperationsInput>;
  isRetail?: Maybe<BoolFieldUpdateOperationsInput>;
  kitchen?: Maybe<KitchenUpdateOneRequiredWithoutKitchenMenuItemsInput>;
  labels?: Maybe<KitchenMenuItemUpdatelabelsInput>;
  longDescription?: Maybe<NullableStringFieldUpdateOperationsInput>;
  mealPackItems?: Maybe<KitchenMenuItemUpdateManyWithoutMealPacksInput>;
  mealPacks?: Maybe<KitchenMenuItemUpdateManyWithoutMealPackItemsInput>;
  menuCategories?: Maybe<MenuCategoryUpdateManyWithoutMenuItemsInput>;
  menuScheduleCategories?: Maybe<MenuScheduleCategoryUpdateManyWithoutMenuItemsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  parentItems?: Maybe<UpsellMenuItemUpdateManyWithoutParentItemInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  sortId?: Maybe<IntFieldUpdateOperationsInput>;
  sortKitchenIds?: Maybe<KitchenMenuItemUpdatesortKitchenIdsInput>;
  station?: Maybe<StationUpdateOneWithoutKitchenMenuItemsInput>;
  status?: Maybe<EnumKitchenMenuItemStatusFieldUpdateOperationsInput>;
  type?: Maybe<EnumMenuItemTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  upsellMenuItems?: Maybe<UpsellMenuItemUpdateManyWithoutUpsellItemInput>;
};

export type KitchenMenuItemUpdateWithoutParentItemsInput = {
  allergies?: Maybe<KitchenMenuItemUpdateallergiesInput>;
  availability?: Maybe<KitchenMenuItemUpdateavailabilityInput>;
  availableFrom?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  availableTo?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cartItems?: Maybe<CartItemUpdateManyWithoutKitchenMenuItemInput>;
  cartUpsellItem?: Maybe<CartUpsellItemUpdateManyWithoutUpsellItemInput>;
  chowlyId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cookTime?: Maybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<StringFieldUpdateOperationsInput>;
  dishTypes?: Maybe<KitchenMenuItemUpdatedishTypesInput>;
  enableInventoryCount?: Maybe<BoolFieldUpdateOperationsInput>;
  extras?: Maybe<ExtraUpdateManyWithoutKitchenMenuItemsInput>;
  facilityItems?: Maybe<FacilityItemUpdateManyWithoutMenuItemInput>;
  globalSortId?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  imgUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  inventoryCount?: Maybe<NullableIntFieldUpdateOperationsInput>;
  inventoryStatus?: Maybe<EnumInventoryStatusFieldUpdateOperationsInput>;
  isRetail?: Maybe<BoolFieldUpdateOperationsInput>;
  kitchen?: Maybe<KitchenUpdateOneRequiredWithoutKitchenMenuItemsInput>;
  labels?: Maybe<KitchenMenuItemUpdatelabelsInput>;
  longDescription?: Maybe<NullableStringFieldUpdateOperationsInput>;
  mealPackItems?: Maybe<KitchenMenuItemUpdateManyWithoutMealPacksInput>;
  mealPacks?: Maybe<KitchenMenuItemUpdateManyWithoutMealPackItemsInput>;
  menuCategories?: Maybe<MenuCategoryUpdateManyWithoutMenuItemsInput>;
  menuScheduleCategories?: Maybe<MenuScheduleCategoryUpdateManyWithoutMenuItemsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  orderItems?: Maybe<OrderItemUpdateManyWithoutMenuItemInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  sortId?: Maybe<IntFieldUpdateOperationsInput>;
  sortKitchenIds?: Maybe<KitchenMenuItemUpdatesortKitchenIdsInput>;
  station?: Maybe<StationUpdateOneWithoutKitchenMenuItemsInput>;
  status?: Maybe<EnumKitchenMenuItemStatusFieldUpdateOperationsInput>;
  type?: Maybe<EnumMenuItemTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  upsellMenuItems?: Maybe<UpsellMenuItemUpdateManyWithoutUpsellItemInput>;
};

export type KitchenMenuItemUpdateWithoutStationInput = {
  allergies?: Maybe<KitchenMenuItemUpdateallergiesInput>;
  availability?: Maybe<KitchenMenuItemUpdateavailabilityInput>;
  availableFrom?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  availableTo?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cartItems?: Maybe<CartItemUpdateManyWithoutKitchenMenuItemInput>;
  cartUpsellItem?: Maybe<CartUpsellItemUpdateManyWithoutUpsellItemInput>;
  chowlyId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cookTime?: Maybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<StringFieldUpdateOperationsInput>;
  dishTypes?: Maybe<KitchenMenuItemUpdatedishTypesInput>;
  enableInventoryCount?: Maybe<BoolFieldUpdateOperationsInput>;
  extras?: Maybe<ExtraUpdateManyWithoutKitchenMenuItemsInput>;
  facilityItems?: Maybe<FacilityItemUpdateManyWithoutMenuItemInput>;
  globalSortId?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  imgUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  inventoryCount?: Maybe<NullableIntFieldUpdateOperationsInput>;
  inventoryStatus?: Maybe<EnumInventoryStatusFieldUpdateOperationsInput>;
  isRetail?: Maybe<BoolFieldUpdateOperationsInput>;
  kitchen?: Maybe<KitchenUpdateOneRequiredWithoutKitchenMenuItemsInput>;
  labels?: Maybe<KitchenMenuItemUpdatelabelsInput>;
  longDescription?: Maybe<NullableStringFieldUpdateOperationsInput>;
  mealPackItems?: Maybe<KitchenMenuItemUpdateManyWithoutMealPacksInput>;
  mealPacks?: Maybe<KitchenMenuItemUpdateManyWithoutMealPackItemsInput>;
  menuCategories?: Maybe<MenuCategoryUpdateManyWithoutMenuItemsInput>;
  menuScheduleCategories?: Maybe<MenuScheduleCategoryUpdateManyWithoutMenuItemsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  orderItems?: Maybe<OrderItemUpdateManyWithoutMenuItemInput>;
  parentItems?: Maybe<UpsellMenuItemUpdateManyWithoutParentItemInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  sortId?: Maybe<IntFieldUpdateOperationsInput>;
  sortKitchenIds?: Maybe<KitchenMenuItemUpdatesortKitchenIdsInput>;
  status?: Maybe<EnumKitchenMenuItemStatusFieldUpdateOperationsInput>;
  type?: Maybe<EnumMenuItemTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  upsellMenuItems?: Maybe<UpsellMenuItemUpdateManyWithoutUpsellItemInput>;
};

export type KitchenMenuItemUpdateWithoutUpsellMenuItemsInput = {
  allergies?: Maybe<KitchenMenuItemUpdateallergiesInput>;
  availability?: Maybe<KitchenMenuItemUpdateavailabilityInput>;
  availableFrom?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  availableTo?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cartItems?: Maybe<CartItemUpdateManyWithoutKitchenMenuItemInput>;
  cartUpsellItem?: Maybe<CartUpsellItemUpdateManyWithoutUpsellItemInput>;
  chowlyId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cookTime?: Maybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<StringFieldUpdateOperationsInput>;
  dishTypes?: Maybe<KitchenMenuItemUpdatedishTypesInput>;
  enableInventoryCount?: Maybe<BoolFieldUpdateOperationsInput>;
  extras?: Maybe<ExtraUpdateManyWithoutKitchenMenuItemsInput>;
  facilityItems?: Maybe<FacilityItemUpdateManyWithoutMenuItemInput>;
  globalSortId?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  imgUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  inventoryCount?: Maybe<NullableIntFieldUpdateOperationsInput>;
  inventoryStatus?: Maybe<EnumInventoryStatusFieldUpdateOperationsInput>;
  isRetail?: Maybe<BoolFieldUpdateOperationsInput>;
  kitchen?: Maybe<KitchenUpdateOneRequiredWithoutKitchenMenuItemsInput>;
  labels?: Maybe<KitchenMenuItemUpdatelabelsInput>;
  longDescription?: Maybe<NullableStringFieldUpdateOperationsInput>;
  mealPackItems?: Maybe<KitchenMenuItemUpdateManyWithoutMealPacksInput>;
  mealPacks?: Maybe<KitchenMenuItemUpdateManyWithoutMealPackItemsInput>;
  menuCategories?: Maybe<MenuCategoryUpdateManyWithoutMenuItemsInput>;
  menuScheduleCategories?: Maybe<MenuScheduleCategoryUpdateManyWithoutMenuItemsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  orderItems?: Maybe<OrderItemUpdateManyWithoutMenuItemInput>;
  parentItems?: Maybe<UpsellMenuItemUpdateManyWithoutParentItemInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  sortId?: Maybe<IntFieldUpdateOperationsInput>;
  sortKitchenIds?: Maybe<KitchenMenuItemUpdatesortKitchenIdsInput>;
  station?: Maybe<StationUpdateOneWithoutKitchenMenuItemsInput>;
  status?: Maybe<EnumKitchenMenuItemStatusFieldUpdateOperationsInput>;
  type?: Maybe<EnumMenuItemTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type KitchenMenuItemUpdateallergiesInput = {
  push?: Maybe<Allergy>;
  set?: Maybe<Array<Allergy>>;
};

export type KitchenMenuItemUpdateavailabilityInput = {
  push?: Maybe<MenuItemAvailability>;
  set?: Maybe<Array<MenuItemAvailability>>;
};

export type KitchenMenuItemUpdatedishTypesInput = {
  push?: Maybe<DishType>;
  set?: Maybe<Array<DishType>>;
};

export type KitchenMenuItemUpdatelabelsInput = {
  push?: Maybe<MenuItemLabel>;
  set?: Maybe<Array<MenuItemLabel>>;
};

export type KitchenMenuItemUpdatesortKitchenIdsInput = {
  push?: Maybe<Scalars['String']>;
  set?: Maybe<Array<Scalars['String']>>;
};

export type KitchenMenuItemUpsertWithWhereUniqueWithoutExtrasInput = {
  create: KitchenMenuItemCreateWithoutExtrasInput;
  update: KitchenMenuItemUpdateWithoutExtrasInput;
  where: KitchenMenuItemWhereUniqueInput;
};

export type KitchenMenuItemUpsertWithWhereUniqueWithoutKitchenInput = {
  create: KitchenMenuItemCreateWithoutKitchenInput;
  update: KitchenMenuItemUpdateWithoutKitchenInput;
  where: KitchenMenuItemWhereUniqueInput;
};

export type KitchenMenuItemUpsertWithWhereUniqueWithoutMealPackItemsInput = {
  create: KitchenMenuItemCreateWithoutMealPackItemsInput;
  update: KitchenMenuItemUpdateWithoutMealPackItemsInput;
  where: KitchenMenuItemWhereUniqueInput;
};

export type KitchenMenuItemUpsertWithWhereUniqueWithoutMealPacksInput = {
  create: KitchenMenuItemCreateWithoutMealPacksInput;
  update: KitchenMenuItemUpdateWithoutMealPacksInput;
  where: KitchenMenuItemWhereUniqueInput;
};

export type KitchenMenuItemUpsertWithWhereUniqueWithoutMenuCategoriesInput = {
  create: KitchenMenuItemCreateWithoutMenuCategoriesInput;
  update: KitchenMenuItemUpdateWithoutMenuCategoriesInput;
  where: KitchenMenuItemWhereUniqueInput;
};

export type KitchenMenuItemUpsertWithWhereUniqueWithoutMenuScheduleCategoriesInput = {
  create: KitchenMenuItemCreateWithoutMenuScheduleCategoriesInput;
  update: KitchenMenuItemUpdateWithoutMenuScheduleCategoriesInput;
  where: KitchenMenuItemWhereUniqueInput;
};

export type KitchenMenuItemUpsertWithWhereUniqueWithoutStationInput = {
  create: KitchenMenuItemCreateWithoutStationInput;
  update: KitchenMenuItemUpdateWithoutStationInput;
  where: KitchenMenuItemWhereUniqueInput;
};

export type KitchenMenuItemUpsertWithoutCartItemsInput = {
  create: KitchenMenuItemCreateWithoutCartItemsInput;
  update: KitchenMenuItemUpdateWithoutCartItemsInput;
};

export type KitchenMenuItemUpsertWithoutCartUpsellItemInput = {
  create: KitchenMenuItemCreateWithoutCartUpsellItemInput;
  update: KitchenMenuItemUpdateWithoutCartUpsellItemInput;
};

export type KitchenMenuItemUpsertWithoutFacilityItemsInput = {
  create: KitchenMenuItemCreateWithoutFacilityItemsInput;
  update: KitchenMenuItemUpdateWithoutFacilityItemsInput;
};

export type KitchenMenuItemUpsertWithoutOrderItemsInput = {
  create: KitchenMenuItemCreateWithoutOrderItemsInput;
  update: KitchenMenuItemUpdateWithoutOrderItemsInput;
};

export type KitchenMenuItemUpsertWithoutParentItemsInput = {
  create: KitchenMenuItemCreateWithoutParentItemsInput;
  update: KitchenMenuItemUpdateWithoutParentItemsInput;
};

export type KitchenMenuItemUpsertWithoutUpsellMenuItemsInput = {
  create: KitchenMenuItemCreateWithoutUpsellMenuItemsInput;
  update: KitchenMenuItemUpdateWithoutUpsellMenuItemsInput;
};

export type KitchenMenuItemWhereInput = {
  AND?: Maybe<Array<KitchenMenuItemWhereInput>>;
  NOT?: Maybe<Array<KitchenMenuItemWhereInput>>;
  OR?: Maybe<Array<KitchenMenuItemWhereInput>>;
  allergies?: Maybe<EnumAllergyNullableListFilter>;
  availability?: Maybe<EnumMenuItemAvailabilityNullableListFilter>;
  availableFrom?: Maybe<DateTimeNullableFilter>;
  availableTo?: Maybe<DateTimeNullableFilter>;
  cartItems?: Maybe<CartItemListRelationFilter>;
  cartUpsellItem?: Maybe<CartUpsellItemListRelationFilter>;
  chowlyId?: Maybe<StringNullableFilter>;
  cookTime?: Maybe<IntNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  description?: Maybe<StringFilter>;
  dishTypes?: Maybe<EnumDishTypeNullableListFilter>;
  enableInventoryCount?: Maybe<BoolFilter>;
  extras?: Maybe<ExtraListRelationFilter>;
  facilityItems?: Maybe<FacilityItemListRelationFilter>;
  globalSortId?: Maybe<IntFilter>;
  id?: Maybe<StringFilter>;
  imgUrl?: Maybe<StringNullableFilter>;
  inventoryCount?: Maybe<IntNullableFilter>;
  inventoryStatus?: Maybe<EnumInventoryStatusFilter>;
  isRetail?: Maybe<BoolFilter>;
  kitchen?: Maybe<KitchenWhereInput>;
  kitchenId?: Maybe<StringFilter>;
  labels?: Maybe<EnumMenuItemLabelNullableListFilter>;
  longDescription?: Maybe<StringNullableFilter>;
  mealPackItems?: Maybe<KitchenMenuItemListRelationFilter>;
  mealPacks?: Maybe<KitchenMenuItemListRelationFilter>;
  menuCategories?: Maybe<MenuCategoryListRelationFilter>;
  menuScheduleCategories?: Maybe<MenuScheduleCategoryListRelationFilter>;
  name?: Maybe<StringFilter>;
  orderItems?: Maybe<OrderItemListRelationFilter>;
  parentItems?: Maybe<UpsellMenuItemListRelationFilter>;
  price?: Maybe<FloatFilter>;
  sortId?: Maybe<IntFilter>;
  sortKitchenIds?: Maybe<StringNullableListFilter>;
  station?: Maybe<StationWhereInput>;
  stationId?: Maybe<StringNullableFilter>;
  status?: Maybe<EnumKitchenMenuItemStatusFilter>;
  type?: Maybe<EnumMenuItemTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  upsellMenuItems?: Maybe<UpsellMenuItemListRelationFilter>;
};

export type KitchenMenuItemWhereUniqueInput = {
  chowlyId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type KitchenScalarWhereInput = {
  AND?: Maybe<Array<KitchenScalarWhereInput>>;
  NOT?: Maybe<Array<KitchenScalarWhereInput>>;
  OR?: Maybe<Array<KitchenScalarWhereInput>>;
  chowlyApiKey?: Maybe<StringNullableFilter>;
  color?: Maybe<StringNullableFilter>;
  cookTime?: Maybe<IntFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  cuisine?: Maybe<StringNullableFilter>;
  description?: Maybe<StringNullableFilter>;
  email?: Maybe<StringNullableFilter>;
  enableChowly?: Maybe<BoolFilter>;
  facilityId?: Maybe<StringFilter>;
  headerImgUrl?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  logoUrl?: Maybe<StringNullableFilter>;
  name?: Maybe<StringFilter>;
  operationHours?: Maybe<JsonNullableListFilter>;
  originalLocation?: Maybe<StringNullableFilter>;
  phone?: Maybe<StringNullableFilter>;
  readonly?: Maybe<BoolFilter>;
  slug?: Maybe<StringNullableFilter>;
  sortId?: Maybe<IntFilter>;
  status?: Maybe<EnumKitchenStatusFilter>;
  story?: Maybe<StringNullableFilter>;
  type?: Maybe<EnumKitchenTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  workplaceId?: Maybe<StringNullableFilter>;
};

export type KitchenSlugFacilityIdCompoundUniqueInput = {
  facilityId: Scalars['String'];
  slug: Scalars['String'];
};

export enum KitchenStatus {
  Active = 'ACTIVE',
  Deleted = 'DELETED',
  Inactive = 'INACTIVE'
}

export type KitchenTicket = {
  __typename?: 'KitchenTicket';
  cookTime?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  delayReason?: Maybe<KitchenTicketDelayReason>;
  delayTime?: Maybe<Scalars['Int']>;
  delayedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  isPrinted: Scalars['Boolean'];
  items: Array<FacilityItem>;
  kitchen?: Maybe<Kitchen>;
  order: Order;
  preparedAt?: Maybe<Scalars['DateTime']>;
  preparingFrom?: Maybe<Scalars['DateTime']>;
  preparingUntil?: Maybe<Scalars['DateTime']>;
  refireReason?: Maybe<RefireReason>;
  refiredAt?: Maybe<Scalars['DateTime']>;
  refiredFrom?: Maybe<KitchenTicket>;
  refiredFromId?: Maybe<Scalars['String']>;
  refiredTo: Array<KitchenTicket>;
  shortId: Scalars['Int'];
  station?: Maybe<Station>;
  status: KitchenTicketStatus;
  type: KdsType;
};


export type KitchenTicketItemsArgs = {
  after?: Maybe<FacilityItemWhereUniqueInput>;
  before?: Maybe<FacilityItemWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type KitchenTicketRefiredToArgs = {
  after?: Maybe<KitchenTicketWhereUniqueInput>;
  before?: Maybe<KitchenTicketWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type KitchenTicketCreateManyKitchenInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  delayReason?: Maybe<KitchenTicketDelayReason>;
  delayTime?: Maybe<Scalars['Int']>;
  delayedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isPrinted?: Maybe<Scalars['Boolean']>;
  orderId: Scalars['String'];
  preparedAt?: Maybe<Scalars['DateTime']>;
  preparingFrom?: Maybe<Scalars['DateTime']>;
  preparingUntil?: Maybe<Scalars['DateTime']>;
  refireReason?: Maybe<RefireReason>;
  refiredAt?: Maybe<Scalars['DateTime']>;
  refiredFromId?: Maybe<Scalars['String']>;
  shortId?: Maybe<Scalars['Int']>;
  stationId?: Maybe<Scalars['String']>;
  status?: Maybe<KitchenTicketStatus>;
  type?: Maybe<KdsType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type KitchenTicketCreateManyKitchenInputEnvelope = {
  data?: Maybe<Array<KitchenTicketCreateManyKitchenInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type KitchenTicketCreateManyOrderInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  delayReason?: Maybe<KitchenTicketDelayReason>;
  delayTime?: Maybe<Scalars['Int']>;
  delayedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isPrinted?: Maybe<Scalars['Boolean']>;
  kitchenId?: Maybe<Scalars['String']>;
  preparedAt?: Maybe<Scalars['DateTime']>;
  preparingFrom?: Maybe<Scalars['DateTime']>;
  preparingUntil?: Maybe<Scalars['DateTime']>;
  refireReason?: Maybe<RefireReason>;
  refiredAt?: Maybe<Scalars['DateTime']>;
  refiredFromId?: Maybe<Scalars['String']>;
  shortId?: Maybe<Scalars['Int']>;
  stationId?: Maybe<Scalars['String']>;
  status?: Maybe<KitchenTicketStatus>;
  type?: Maybe<KdsType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type KitchenTicketCreateManyOrderInputEnvelope = {
  data?: Maybe<Array<KitchenTicketCreateManyOrderInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type KitchenTicketCreateManyRefiredFromInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  delayReason?: Maybe<KitchenTicketDelayReason>;
  delayTime?: Maybe<Scalars['Int']>;
  delayedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isPrinted?: Maybe<Scalars['Boolean']>;
  kitchenId?: Maybe<Scalars['String']>;
  orderId: Scalars['String'];
  preparedAt?: Maybe<Scalars['DateTime']>;
  preparingFrom?: Maybe<Scalars['DateTime']>;
  preparingUntil?: Maybe<Scalars['DateTime']>;
  refireReason?: Maybe<RefireReason>;
  refiredAt?: Maybe<Scalars['DateTime']>;
  shortId?: Maybe<Scalars['Int']>;
  stationId?: Maybe<Scalars['String']>;
  status?: Maybe<KitchenTicketStatus>;
  type?: Maybe<KdsType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type KitchenTicketCreateManyRefiredFromInputEnvelope = {
  data?: Maybe<Array<KitchenTicketCreateManyRefiredFromInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type KitchenTicketCreateManyStationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  delayReason?: Maybe<KitchenTicketDelayReason>;
  delayTime?: Maybe<Scalars['Int']>;
  delayedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isPrinted?: Maybe<Scalars['Boolean']>;
  kitchenId?: Maybe<Scalars['String']>;
  orderId: Scalars['String'];
  preparedAt?: Maybe<Scalars['DateTime']>;
  preparingFrom?: Maybe<Scalars['DateTime']>;
  preparingUntil?: Maybe<Scalars['DateTime']>;
  refireReason?: Maybe<RefireReason>;
  refiredAt?: Maybe<Scalars['DateTime']>;
  refiredFromId?: Maybe<Scalars['String']>;
  shortId?: Maybe<Scalars['Int']>;
  status?: Maybe<KitchenTicketStatus>;
  type?: Maybe<KdsType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type KitchenTicketCreateManyStationInputEnvelope = {
  data?: Maybe<Array<KitchenTicketCreateManyStationInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type KitchenTicketCreateNestedManyWithoutKitchenInput = {
  connect?: Maybe<Array<KitchenTicketWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<KitchenTicketCreateOrConnectWithoutKitchenInput>>;
  create?: Maybe<Array<KitchenTicketCreateWithoutKitchenInput>>;
  createMany?: Maybe<KitchenTicketCreateManyKitchenInputEnvelope>;
};

export type KitchenTicketCreateNestedManyWithoutOrderInput = {
  connect?: Maybe<Array<KitchenTicketWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<KitchenTicketCreateOrConnectWithoutOrderInput>>;
  create?: Maybe<Array<KitchenTicketCreateWithoutOrderInput>>;
  createMany?: Maybe<KitchenTicketCreateManyOrderInputEnvelope>;
};

export type KitchenTicketCreateNestedManyWithoutRefiredFromInput = {
  connect?: Maybe<Array<KitchenTicketWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<KitchenTicketCreateOrConnectWithoutRefiredFromInput>>;
  create?: Maybe<Array<KitchenTicketCreateWithoutRefiredFromInput>>;
  createMany?: Maybe<KitchenTicketCreateManyRefiredFromInputEnvelope>;
};

export type KitchenTicketCreateNestedManyWithoutStationInput = {
  connect?: Maybe<Array<KitchenTicketWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<KitchenTicketCreateOrConnectWithoutStationInput>>;
  create?: Maybe<Array<KitchenTicketCreateWithoutStationInput>>;
  createMany?: Maybe<KitchenTicketCreateManyStationInputEnvelope>;
};

export type KitchenTicketCreateNestedOneWithoutItemsInput = {
  connect?: Maybe<KitchenTicketWhereUniqueInput>;
  connectOrCreate?: Maybe<KitchenTicketCreateOrConnectWithoutItemsInput>;
  create?: Maybe<KitchenTicketCreateWithoutItemsInput>;
};

export type KitchenTicketCreateNestedOneWithoutRefiredToInput = {
  connect?: Maybe<KitchenTicketWhereUniqueInput>;
  connectOrCreate?: Maybe<KitchenTicketCreateOrConnectWithoutRefiredToInput>;
  create?: Maybe<KitchenTicketCreateWithoutRefiredToInput>;
};

export type KitchenTicketCreateOrConnectWithoutItemsInput = {
  create: KitchenTicketCreateWithoutItemsInput;
  where: KitchenTicketWhereUniqueInput;
};

export type KitchenTicketCreateOrConnectWithoutKitchenInput = {
  create: KitchenTicketCreateWithoutKitchenInput;
  where: KitchenTicketWhereUniqueInput;
};

export type KitchenTicketCreateOrConnectWithoutOrderInput = {
  create: KitchenTicketCreateWithoutOrderInput;
  where: KitchenTicketWhereUniqueInput;
};

export type KitchenTicketCreateOrConnectWithoutRefiredFromInput = {
  create: KitchenTicketCreateWithoutRefiredFromInput;
  where: KitchenTicketWhereUniqueInput;
};

export type KitchenTicketCreateOrConnectWithoutRefiredToInput = {
  create: KitchenTicketCreateWithoutRefiredToInput;
  where: KitchenTicketWhereUniqueInput;
};

export type KitchenTicketCreateOrConnectWithoutStationInput = {
  create: KitchenTicketCreateWithoutStationInput;
  where: KitchenTicketWhereUniqueInput;
};

export type KitchenTicketCreateWithoutItemsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  delayReason?: Maybe<KitchenTicketDelayReason>;
  delayTime?: Maybe<Scalars['Int']>;
  delayedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isPrinted?: Maybe<Scalars['Boolean']>;
  kitchen?: Maybe<KitchenCreateNestedOneWithoutKitchenTicketInput>;
  order: OrderCreateNestedOneWithoutKitchenTicketsInput;
  preparedAt?: Maybe<Scalars['DateTime']>;
  preparingFrom?: Maybe<Scalars['DateTime']>;
  preparingUntil?: Maybe<Scalars['DateTime']>;
  refireReason?: Maybe<RefireReason>;
  refiredAt?: Maybe<Scalars['DateTime']>;
  refiredFrom?: Maybe<KitchenTicketCreateNestedOneWithoutRefiredToInput>;
  refiredTo?: Maybe<KitchenTicketCreateNestedManyWithoutRefiredFromInput>;
  shortId?: Maybe<Scalars['Int']>;
  station?: Maybe<StationCreateNestedOneWithoutTicketsInput>;
  status?: Maybe<KitchenTicketStatus>;
  type?: Maybe<KdsType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type KitchenTicketCreateWithoutKitchenInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  delayReason?: Maybe<KitchenTicketDelayReason>;
  delayTime?: Maybe<Scalars['Int']>;
  delayedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isPrinted?: Maybe<Scalars['Boolean']>;
  items?: Maybe<FacilityItemCreateNestedManyWithoutTicketInput>;
  order: OrderCreateNestedOneWithoutKitchenTicketsInput;
  preparedAt?: Maybe<Scalars['DateTime']>;
  preparingFrom?: Maybe<Scalars['DateTime']>;
  preparingUntil?: Maybe<Scalars['DateTime']>;
  refireReason?: Maybe<RefireReason>;
  refiredAt?: Maybe<Scalars['DateTime']>;
  refiredFrom?: Maybe<KitchenTicketCreateNestedOneWithoutRefiredToInput>;
  refiredTo?: Maybe<KitchenTicketCreateNestedManyWithoutRefiredFromInput>;
  shortId?: Maybe<Scalars['Int']>;
  station?: Maybe<StationCreateNestedOneWithoutTicketsInput>;
  status?: Maybe<KitchenTicketStatus>;
  type?: Maybe<KdsType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type KitchenTicketCreateWithoutOrderInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  delayReason?: Maybe<KitchenTicketDelayReason>;
  delayTime?: Maybe<Scalars['Int']>;
  delayedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isPrinted?: Maybe<Scalars['Boolean']>;
  items?: Maybe<FacilityItemCreateNestedManyWithoutTicketInput>;
  kitchen?: Maybe<KitchenCreateNestedOneWithoutKitchenTicketInput>;
  preparedAt?: Maybe<Scalars['DateTime']>;
  preparingFrom?: Maybe<Scalars['DateTime']>;
  preparingUntil?: Maybe<Scalars['DateTime']>;
  refireReason?: Maybe<RefireReason>;
  refiredAt?: Maybe<Scalars['DateTime']>;
  refiredFrom?: Maybe<KitchenTicketCreateNestedOneWithoutRefiredToInput>;
  refiredTo?: Maybe<KitchenTicketCreateNestedManyWithoutRefiredFromInput>;
  shortId?: Maybe<Scalars['Int']>;
  station?: Maybe<StationCreateNestedOneWithoutTicketsInput>;
  status?: Maybe<KitchenTicketStatus>;
  type?: Maybe<KdsType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type KitchenTicketCreateWithoutRefiredFromInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  delayReason?: Maybe<KitchenTicketDelayReason>;
  delayTime?: Maybe<Scalars['Int']>;
  delayedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isPrinted?: Maybe<Scalars['Boolean']>;
  items?: Maybe<FacilityItemCreateNestedManyWithoutTicketInput>;
  kitchen?: Maybe<KitchenCreateNestedOneWithoutKitchenTicketInput>;
  order: OrderCreateNestedOneWithoutKitchenTicketsInput;
  preparedAt?: Maybe<Scalars['DateTime']>;
  preparingFrom?: Maybe<Scalars['DateTime']>;
  preparingUntil?: Maybe<Scalars['DateTime']>;
  refireReason?: Maybe<RefireReason>;
  refiredAt?: Maybe<Scalars['DateTime']>;
  refiredTo?: Maybe<KitchenTicketCreateNestedManyWithoutRefiredFromInput>;
  shortId?: Maybe<Scalars['Int']>;
  station?: Maybe<StationCreateNestedOneWithoutTicketsInput>;
  status?: Maybe<KitchenTicketStatus>;
  type?: Maybe<KdsType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type KitchenTicketCreateWithoutRefiredToInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  delayReason?: Maybe<KitchenTicketDelayReason>;
  delayTime?: Maybe<Scalars['Int']>;
  delayedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isPrinted?: Maybe<Scalars['Boolean']>;
  items?: Maybe<FacilityItemCreateNestedManyWithoutTicketInput>;
  kitchen?: Maybe<KitchenCreateNestedOneWithoutKitchenTicketInput>;
  order: OrderCreateNestedOneWithoutKitchenTicketsInput;
  preparedAt?: Maybe<Scalars['DateTime']>;
  preparingFrom?: Maybe<Scalars['DateTime']>;
  preparingUntil?: Maybe<Scalars['DateTime']>;
  refireReason?: Maybe<RefireReason>;
  refiredAt?: Maybe<Scalars['DateTime']>;
  refiredFrom?: Maybe<KitchenTicketCreateNestedOneWithoutRefiredToInput>;
  shortId?: Maybe<Scalars['Int']>;
  station?: Maybe<StationCreateNestedOneWithoutTicketsInput>;
  status?: Maybe<KitchenTicketStatus>;
  type?: Maybe<KdsType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type KitchenTicketCreateWithoutStationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  delayReason?: Maybe<KitchenTicketDelayReason>;
  delayTime?: Maybe<Scalars['Int']>;
  delayedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isPrinted?: Maybe<Scalars['Boolean']>;
  items?: Maybe<FacilityItemCreateNestedManyWithoutTicketInput>;
  kitchen?: Maybe<KitchenCreateNestedOneWithoutKitchenTicketInput>;
  order: OrderCreateNestedOneWithoutKitchenTicketsInput;
  preparedAt?: Maybe<Scalars['DateTime']>;
  preparingFrom?: Maybe<Scalars['DateTime']>;
  preparingUntil?: Maybe<Scalars['DateTime']>;
  refireReason?: Maybe<RefireReason>;
  refiredAt?: Maybe<Scalars['DateTime']>;
  refiredFrom?: Maybe<KitchenTicketCreateNestedOneWithoutRefiredToInput>;
  refiredTo?: Maybe<KitchenTicketCreateNestedManyWithoutRefiredFromInput>;
  shortId?: Maybe<Scalars['Int']>;
  status?: Maybe<KitchenTicketStatus>;
  type?: Maybe<KdsType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export enum KitchenTicketDelayReason {
  Busy = 'BUSY',
  Mistake = 'MISTAKE',
  Other = 'OTHER'
}

export type KitchenTicketListRelationFilter = {
  every?: Maybe<KitchenTicketWhereInput>;
  none?: Maybe<KitchenTicketWhereInput>;
  some?: Maybe<KitchenTicketWhereInput>;
};

export type KitchenTicketScalarWhereInput = {
  AND?: Maybe<Array<KitchenTicketScalarWhereInput>>;
  NOT?: Maybe<Array<KitchenTicketScalarWhereInput>>;
  OR?: Maybe<Array<KitchenTicketScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  delayReason?: Maybe<EnumKitchenTicketDelayReasonNullableFilter>;
  delayTime?: Maybe<IntNullableFilter>;
  delayedAt?: Maybe<DateTimeNullableFilter>;
  id?: Maybe<StringFilter>;
  isPrinted?: Maybe<BoolFilter>;
  kitchenId?: Maybe<StringNullableFilter>;
  orderId?: Maybe<StringFilter>;
  preparedAt?: Maybe<DateTimeNullableFilter>;
  preparingFrom?: Maybe<DateTimeNullableFilter>;
  preparingUntil?: Maybe<DateTimeNullableFilter>;
  refireReason?: Maybe<EnumRefireReasonNullableFilter>;
  refiredAt?: Maybe<DateTimeNullableFilter>;
  refiredFromId?: Maybe<StringNullableFilter>;
  shortId?: Maybe<IntFilter>;
  stationId?: Maybe<StringNullableFilter>;
  status?: Maybe<EnumKitchenTicketStatusFilter>;
  type?: Maybe<EnumKdsTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export enum KitchenTicketStatus {
  Done = 'DONE',
  Expo = 'EXPO',
  New = 'NEW',
  Refired = 'REFIRED',
  Started = 'STARTED'
}

export type KitchenTicketSubscription = {
  __typename?: 'KitchenTicketSubscription';
  data: KitchenTicket;
  operation: SubscriptionOperation;
};

export type KitchenTicketUpdateInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  delayReason?: Maybe<NullableEnumKitchenTicketDelayReasonFieldUpdateOperationsInput>;
  delayTime?: Maybe<NullableIntFieldUpdateOperationsInput>;
  delayedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isPrinted?: Maybe<BoolFieldUpdateOperationsInput>;
  items?: Maybe<FacilityItemUpdateManyWithoutTicketInput>;
  kitchen?: Maybe<KitchenUpdateOneWithoutKitchenTicketInput>;
  order?: Maybe<OrderUpdateOneRequiredWithoutKitchenTicketsInput>;
  preparedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  preparingFrom?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  preparingUntil?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  refireReason?: Maybe<NullableEnumRefireReasonFieldUpdateOperationsInput>;
  refiredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  refiredFrom?: Maybe<KitchenTicketUpdateOneWithoutRefiredToInput>;
  refiredTo?: Maybe<KitchenTicketUpdateManyWithoutRefiredFromInput>;
  shortId?: Maybe<IntFieldUpdateOperationsInput>;
  station?: Maybe<StationUpdateOneWithoutTicketsInput>;
  status?: Maybe<EnumKitchenTicketStatusFieldUpdateOperationsInput>;
  type?: Maybe<EnumKdsTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type KitchenTicketUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  delayReason?: Maybe<NullableEnumKitchenTicketDelayReasonFieldUpdateOperationsInput>;
  delayTime?: Maybe<NullableIntFieldUpdateOperationsInput>;
  delayedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isPrinted?: Maybe<BoolFieldUpdateOperationsInput>;
  preparedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  preparingFrom?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  preparingUntil?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  refireReason?: Maybe<NullableEnumRefireReasonFieldUpdateOperationsInput>;
  refiredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  shortId?: Maybe<IntFieldUpdateOperationsInput>;
  status?: Maybe<EnumKitchenTicketStatusFieldUpdateOperationsInput>;
  type?: Maybe<EnumKdsTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type KitchenTicketUpdateManyWithWhereWithoutKitchenInput = {
  data: KitchenTicketUpdateManyMutationInput;
  where: KitchenTicketScalarWhereInput;
};

export type KitchenTicketUpdateManyWithWhereWithoutOrderInput = {
  data: KitchenTicketUpdateManyMutationInput;
  where: KitchenTicketScalarWhereInput;
};

export type KitchenTicketUpdateManyWithWhereWithoutRefiredFromInput = {
  data: KitchenTicketUpdateManyMutationInput;
  where: KitchenTicketScalarWhereInput;
};

export type KitchenTicketUpdateManyWithWhereWithoutStationInput = {
  data: KitchenTicketUpdateManyMutationInput;
  where: KitchenTicketScalarWhereInput;
};

export type KitchenTicketUpdateManyWithoutKitchenInput = {
  connect?: Maybe<Array<KitchenTicketWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<KitchenTicketCreateOrConnectWithoutKitchenInput>>;
  create?: Maybe<Array<KitchenTicketCreateWithoutKitchenInput>>;
  createMany?: Maybe<KitchenTicketCreateManyKitchenInputEnvelope>;
  delete?: Maybe<Array<KitchenTicketWhereUniqueInput>>;
  deleteMany?: Maybe<Array<KitchenTicketScalarWhereInput>>;
  disconnect?: Maybe<Array<KitchenTicketWhereUniqueInput>>;
  set?: Maybe<Array<KitchenTicketWhereUniqueInput>>;
  update?: Maybe<Array<KitchenTicketUpdateWithWhereUniqueWithoutKitchenInput>>;
  updateMany?: Maybe<Array<KitchenTicketUpdateManyWithWhereWithoutKitchenInput>>;
  upsert?: Maybe<Array<KitchenTicketUpsertWithWhereUniqueWithoutKitchenInput>>;
};

export type KitchenTicketUpdateManyWithoutOrderInput = {
  connect?: Maybe<Array<KitchenTicketWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<KitchenTicketCreateOrConnectWithoutOrderInput>>;
  create?: Maybe<Array<KitchenTicketCreateWithoutOrderInput>>;
  createMany?: Maybe<KitchenTicketCreateManyOrderInputEnvelope>;
  delete?: Maybe<Array<KitchenTicketWhereUniqueInput>>;
  deleteMany?: Maybe<Array<KitchenTicketScalarWhereInput>>;
  disconnect?: Maybe<Array<KitchenTicketWhereUniqueInput>>;
  set?: Maybe<Array<KitchenTicketWhereUniqueInput>>;
  update?: Maybe<Array<KitchenTicketUpdateWithWhereUniqueWithoutOrderInput>>;
  updateMany?: Maybe<Array<KitchenTicketUpdateManyWithWhereWithoutOrderInput>>;
  upsert?: Maybe<Array<KitchenTicketUpsertWithWhereUniqueWithoutOrderInput>>;
};

export type KitchenTicketUpdateManyWithoutRefiredFromInput = {
  connect?: Maybe<Array<KitchenTicketWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<KitchenTicketCreateOrConnectWithoutRefiredFromInput>>;
  create?: Maybe<Array<KitchenTicketCreateWithoutRefiredFromInput>>;
  createMany?: Maybe<KitchenTicketCreateManyRefiredFromInputEnvelope>;
  delete?: Maybe<Array<KitchenTicketWhereUniqueInput>>;
  deleteMany?: Maybe<Array<KitchenTicketScalarWhereInput>>;
  disconnect?: Maybe<Array<KitchenTicketWhereUniqueInput>>;
  set?: Maybe<Array<KitchenTicketWhereUniqueInput>>;
  update?: Maybe<Array<KitchenTicketUpdateWithWhereUniqueWithoutRefiredFromInput>>;
  updateMany?: Maybe<Array<KitchenTicketUpdateManyWithWhereWithoutRefiredFromInput>>;
  upsert?: Maybe<Array<KitchenTicketUpsertWithWhereUniqueWithoutRefiredFromInput>>;
};

export type KitchenTicketUpdateManyWithoutStationInput = {
  connect?: Maybe<Array<KitchenTicketWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<KitchenTicketCreateOrConnectWithoutStationInput>>;
  create?: Maybe<Array<KitchenTicketCreateWithoutStationInput>>;
  createMany?: Maybe<KitchenTicketCreateManyStationInputEnvelope>;
  delete?: Maybe<Array<KitchenTicketWhereUniqueInput>>;
  deleteMany?: Maybe<Array<KitchenTicketScalarWhereInput>>;
  disconnect?: Maybe<Array<KitchenTicketWhereUniqueInput>>;
  set?: Maybe<Array<KitchenTicketWhereUniqueInput>>;
  update?: Maybe<Array<KitchenTicketUpdateWithWhereUniqueWithoutStationInput>>;
  updateMany?: Maybe<Array<KitchenTicketUpdateManyWithWhereWithoutStationInput>>;
  upsert?: Maybe<Array<KitchenTicketUpsertWithWhereUniqueWithoutStationInput>>;
};

export type KitchenTicketUpdateOneRequiredWithoutItemsInput = {
  connect?: Maybe<KitchenTicketWhereUniqueInput>;
  connectOrCreate?: Maybe<KitchenTicketCreateOrConnectWithoutItemsInput>;
  create?: Maybe<KitchenTicketCreateWithoutItemsInput>;
  update?: Maybe<KitchenTicketUpdateWithoutItemsInput>;
  upsert?: Maybe<KitchenTicketUpsertWithoutItemsInput>;
};

export type KitchenTicketUpdateOneWithoutRefiredToInput = {
  connect?: Maybe<KitchenTicketWhereUniqueInput>;
  connectOrCreate?: Maybe<KitchenTicketCreateOrConnectWithoutRefiredToInput>;
  create?: Maybe<KitchenTicketCreateWithoutRefiredToInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<KitchenTicketUpdateWithoutRefiredToInput>;
  upsert?: Maybe<KitchenTicketUpsertWithoutRefiredToInput>;
};

export type KitchenTicketUpdateWithWhereUniqueWithoutKitchenInput = {
  data: KitchenTicketUpdateWithoutKitchenInput;
  where: KitchenTicketWhereUniqueInput;
};

export type KitchenTicketUpdateWithWhereUniqueWithoutOrderInput = {
  data: KitchenTicketUpdateWithoutOrderInput;
  where: KitchenTicketWhereUniqueInput;
};

export type KitchenTicketUpdateWithWhereUniqueWithoutRefiredFromInput = {
  data: KitchenTicketUpdateWithoutRefiredFromInput;
  where: KitchenTicketWhereUniqueInput;
};

export type KitchenTicketUpdateWithWhereUniqueWithoutStationInput = {
  data: KitchenTicketUpdateWithoutStationInput;
  where: KitchenTicketWhereUniqueInput;
};

export type KitchenTicketUpdateWithoutItemsInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  delayReason?: Maybe<NullableEnumKitchenTicketDelayReasonFieldUpdateOperationsInput>;
  delayTime?: Maybe<NullableIntFieldUpdateOperationsInput>;
  delayedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isPrinted?: Maybe<BoolFieldUpdateOperationsInput>;
  kitchen?: Maybe<KitchenUpdateOneWithoutKitchenTicketInput>;
  order?: Maybe<OrderUpdateOneRequiredWithoutKitchenTicketsInput>;
  preparedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  preparingFrom?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  preparingUntil?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  refireReason?: Maybe<NullableEnumRefireReasonFieldUpdateOperationsInput>;
  refiredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  refiredFrom?: Maybe<KitchenTicketUpdateOneWithoutRefiredToInput>;
  refiredTo?: Maybe<KitchenTicketUpdateManyWithoutRefiredFromInput>;
  shortId?: Maybe<IntFieldUpdateOperationsInput>;
  station?: Maybe<StationUpdateOneWithoutTicketsInput>;
  status?: Maybe<EnumKitchenTicketStatusFieldUpdateOperationsInput>;
  type?: Maybe<EnumKdsTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type KitchenTicketUpdateWithoutKitchenInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  delayReason?: Maybe<NullableEnumKitchenTicketDelayReasonFieldUpdateOperationsInput>;
  delayTime?: Maybe<NullableIntFieldUpdateOperationsInput>;
  delayedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isPrinted?: Maybe<BoolFieldUpdateOperationsInput>;
  items?: Maybe<FacilityItemUpdateManyWithoutTicketInput>;
  order?: Maybe<OrderUpdateOneRequiredWithoutKitchenTicketsInput>;
  preparedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  preparingFrom?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  preparingUntil?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  refireReason?: Maybe<NullableEnumRefireReasonFieldUpdateOperationsInput>;
  refiredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  refiredFrom?: Maybe<KitchenTicketUpdateOneWithoutRefiredToInput>;
  refiredTo?: Maybe<KitchenTicketUpdateManyWithoutRefiredFromInput>;
  shortId?: Maybe<IntFieldUpdateOperationsInput>;
  station?: Maybe<StationUpdateOneWithoutTicketsInput>;
  status?: Maybe<EnumKitchenTicketStatusFieldUpdateOperationsInput>;
  type?: Maybe<EnumKdsTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type KitchenTicketUpdateWithoutOrderInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  delayReason?: Maybe<NullableEnumKitchenTicketDelayReasonFieldUpdateOperationsInput>;
  delayTime?: Maybe<NullableIntFieldUpdateOperationsInput>;
  delayedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isPrinted?: Maybe<BoolFieldUpdateOperationsInput>;
  items?: Maybe<FacilityItemUpdateManyWithoutTicketInput>;
  kitchen?: Maybe<KitchenUpdateOneWithoutKitchenTicketInput>;
  preparedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  preparingFrom?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  preparingUntil?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  refireReason?: Maybe<NullableEnumRefireReasonFieldUpdateOperationsInput>;
  refiredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  refiredFrom?: Maybe<KitchenTicketUpdateOneWithoutRefiredToInput>;
  refiredTo?: Maybe<KitchenTicketUpdateManyWithoutRefiredFromInput>;
  shortId?: Maybe<IntFieldUpdateOperationsInput>;
  station?: Maybe<StationUpdateOneWithoutTicketsInput>;
  status?: Maybe<EnumKitchenTicketStatusFieldUpdateOperationsInput>;
  type?: Maybe<EnumKdsTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type KitchenTicketUpdateWithoutRefiredFromInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  delayReason?: Maybe<NullableEnumKitchenTicketDelayReasonFieldUpdateOperationsInput>;
  delayTime?: Maybe<NullableIntFieldUpdateOperationsInput>;
  delayedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isPrinted?: Maybe<BoolFieldUpdateOperationsInput>;
  items?: Maybe<FacilityItemUpdateManyWithoutTicketInput>;
  kitchen?: Maybe<KitchenUpdateOneWithoutKitchenTicketInput>;
  order?: Maybe<OrderUpdateOneRequiredWithoutKitchenTicketsInput>;
  preparedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  preparingFrom?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  preparingUntil?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  refireReason?: Maybe<NullableEnumRefireReasonFieldUpdateOperationsInput>;
  refiredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  refiredTo?: Maybe<KitchenTicketUpdateManyWithoutRefiredFromInput>;
  shortId?: Maybe<IntFieldUpdateOperationsInput>;
  station?: Maybe<StationUpdateOneWithoutTicketsInput>;
  status?: Maybe<EnumKitchenTicketStatusFieldUpdateOperationsInput>;
  type?: Maybe<EnumKdsTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type KitchenTicketUpdateWithoutRefiredToInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  delayReason?: Maybe<NullableEnumKitchenTicketDelayReasonFieldUpdateOperationsInput>;
  delayTime?: Maybe<NullableIntFieldUpdateOperationsInput>;
  delayedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isPrinted?: Maybe<BoolFieldUpdateOperationsInput>;
  items?: Maybe<FacilityItemUpdateManyWithoutTicketInput>;
  kitchen?: Maybe<KitchenUpdateOneWithoutKitchenTicketInput>;
  order?: Maybe<OrderUpdateOneRequiredWithoutKitchenTicketsInput>;
  preparedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  preparingFrom?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  preparingUntil?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  refireReason?: Maybe<NullableEnumRefireReasonFieldUpdateOperationsInput>;
  refiredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  refiredFrom?: Maybe<KitchenTicketUpdateOneWithoutRefiredToInput>;
  shortId?: Maybe<IntFieldUpdateOperationsInput>;
  station?: Maybe<StationUpdateOneWithoutTicketsInput>;
  status?: Maybe<EnumKitchenTicketStatusFieldUpdateOperationsInput>;
  type?: Maybe<EnumKdsTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type KitchenTicketUpdateWithoutStationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  delayReason?: Maybe<NullableEnumKitchenTicketDelayReasonFieldUpdateOperationsInput>;
  delayTime?: Maybe<NullableIntFieldUpdateOperationsInput>;
  delayedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isPrinted?: Maybe<BoolFieldUpdateOperationsInput>;
  items?: Maybe<FacilityItemUpdateManyWithoutTicketInput>;
  kitchen?: Maybe<KitchenUpdateOneWithoutKitchenTicketInput>;
  order?: Maybe<OrderUpdateOneRequiredWithoutKitchenTicketsInput>;
  preparedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  preparingFrom?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  preparingUntil?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  refireReason?: Maybe<NullableEnumRefireReasonFieldUpdateOperationsInput>;
  refiredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  refiredFrom?: Maybe<KitchenTicketUpdateOneWithoutRefiredToInput>;
  refiredTo?: Maybe<KitchenTicketUpdateManyWithoutRefiredFromInput>;
  shortId?: Maybe<IntFieldUpdateOperationsInput>;
  status?: Maybe<EnumKitchenTicketStatusFieldUpdateOperationsInput>;
  type?: Maybe<EnumKdsTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type KitchenTicketUpsertWithWhereUniqueWithoutKitchenInput = {
  create: KitchenTicketCreateWithoutKitchenInput;
  update: KitchenTicketUpdateWithoutKitchenInput;
  where: KitchenTicketWhereUniqueInput;
};

export type KitchenTicketUpsertWithWhereUniqueWithoutOrderInput = {
  create: KitchenTicketCreateWithoutOrderInput;
  update: KitchenTicketUpdateWithoutOrderInput;
  where: KitchenTicketWhereUniqueInput;
};

export type KitchenTicketUpsertWithWhereUniqueWithoutRefiredFromInput = {
  create: KitchenTicketCreateWithoutRefiredFromInput;
  update: KitchenTicketUpdateWithoutRefiredFromInput;
  where: KitchenTicketWhereUniqueInput;
};

export type KitchenTicketUpsertWithWhereUniqueWithoutStationInput = {
  create: KitchenTicketCreateWithoutStationInput;
  update: KitchenTicketUpdateWithoutStationInput;
  where: KitchenTicketWhereUniqueInput;
};

export type KitchenTicketUpsertWithoutItemsInput = {
  create: KitchenTicketCreateWithoutItemsInput;
  update: KitchenTicketUpdateWithoutItemsInput;
};

export type KitchenTicketUpsertWithoutRefiredToInput = {
  create: KitchenTicketCreateWithoutRefiredToInput;
  update: KitchenTicketUpdateWithoutRefiredToInput;
};

export type KitchenTicketWhereInput = {
  AND?: Maybe<Array<KitchenTicketWhereInput>>;
  NOT?: Maybe<Array<KitchenTicketWhereInput>>;
  OR?: Maybe<Array<KitchenTicketWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  delayReason?: Maybe<EnumKitchenTicketDelayReasonNullableFilter>;
  delayTime?: Maybe<IntNullableFilter>;
  delayedAt?: Maybe<DateTimeNullableFilter>;
  id?: Maybe<StringFilter>;
  isPrinted?: Maybe<BoolFilter>;
  items?: Maybe<FacilityItemListRelationFilter>;
  kitchen?: Maybe<KitchenWhereInput>;
  kitchenId?: Maybe<StringNullableFilter>;
  order?: Maybe<OrderWhereInput>;
  orderId?: Maybe<StringFilter>;
  preparedAt?: Maybe<DateTimeNullableFilter>;
  preparingFrom?: Maybe<DateTimeNullableFilter>;
  preparingUntil?: Maybe<DateTimeNullableFilter>;
  refireReason?: Maybe<EnumRefireReasonNullableFilter>;
  refiredAt?: Maybe<DateTimeNullableFilter>;
  refiredFrom?: Maybe<KitchenTicketWhereInput>;
  refiredFromId?: Maybe<StringNullableFilter>;
  refiredTo?: Maybe<KitchenTicketListRelationFilter>;
  shortId?: Maybe<IntFilter>;
  station?: Maybe<StationWhereInput>;
  stationId?: Maybe<StringNullableFilter>;
  status?: Maybe<EnumKitchenTicketStatusFilter>;
  type?: Maybe<EnumKdsTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type KitchenTicketWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum KitchenType {
  Crave = 'CRAVE',
  Default = 'DEFAULT'
}

export type KitchenUpdateManyMutationInput = {
  chowlyApiKey?: Maybe<NullableStringFieldUpdateOperationsInput>;
  color?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cookTime?: Maybe<IntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  cuisine?: Maybe<NullableStringFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  enableChowly?: Maybe<BoolFieldUpdateOperationsInput>;
  headerImgUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  logoUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationHours?: Maybe<KitchenUpdateoperationHoursInput>;
  originalLocation?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  readonly?: Maybe<BoolFieldUpdateOperationsInput>;
  slug?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sortId?: Maybe<IntFieldUpdateOperationsInput>;
  status?: Maybe<EnumKitchenStatusFieldUpdateOperationsInput>;
  story?: Maybe<NullableStringFieldUpdateOperationsInput>;
  type?: Maybe<EnumKitchenTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type KitchenUpdateManyWithWhereWithoutFacilityInput = {
  data: KitchenUpdateManyMutationInput;
  where: KitchenScalarWhereInput;
};

export type KitchenUpdateManyWithWhereWithoutPowerBiReportInput = {
  data: KitchenUpdateManyMutationInput;
  where: KitchenScalarWhereInput;
};

export type KitchenUpdateManyWithWhereWithoutPromoFacilityRulesInput = {
  data: KitchenUpdateManyMutationInput;
  where: KitchenScalarWhereInput;
};

export type KitchenUpdateManyWithWhereWithoutUsersInput = {
  data: KitchenUpdateManyMutationInput;
  where: KitchenScalarWhereInput;
};

export type KitchenUpdateManyWithoutFacilityInput = {
  connect?: Maybe<Array<KitchenWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<KitchenCreateOrConnectWithoutFacilityInput>>;
  create?: Maybe<Array<KitchenCreateWithoutFacilityInput>>;
  createMany?: Maybe<KitchenCreateManyFacilityInputEnvelope>;
  delete?: Maybe<Array<KitchenWhereUniqueInput>>;
  deleteMany?: Maybe<Array<KitchenScalarWhereInput>>;
  disconnect?: Maybe<Array<KitchenWhereUniqueInput>>;
  set?: Maybe<Array<KitchenWhereUniqueInput>>;
  update?: Maybe<Array<KitchenUpdateWithWhereUniqueWithoutFacilityInput>>;
  updateMany?: Maybe<Array<KitchenUpdateManyWithWhereWithoutFacilityInput>>;
  upsert?: Maybe<Array<KitchenUpsertWithWhereUniqueWithoutFacilityInput>>;
};

export type KitchenUpdateManyWithoutPowerBiReportInput = {
  connect?: Maybe<Array<KitchenWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<KitchenCreateOrConnectWithoutPowerBiReportInput>>;
  create?: Maybe<Array<KitchenCreateWithoutPowerBiReportInput>>;
  delete?: Maybe<Array<KitchenWhereUniqueInput>>;
  deleteMany?: Maybe<Array<KitchenScalarWhereInput>>;
  disconnect?: Maybe<Array<KitchenWhereUniqueInput>>;
  set?: Maybe<Array<KitchenWhereUniqueInput>>;
  update?: Maybe<Array<KitchenUpdateWithWhereUniqueWithoutPowerBiReportInput>>;
  updateMany?: Maybe<Array<KitchenUpdateManyWithWhereWithoutPowerBiReportInput>>;
  upsert?: Maybe<Array<KitchenUpsertWithWhereUniqueWithoutPowerBiReportInput>>;
};

export type KitchenUpdateManyWithoutPromoFacilityRulesInput = {
  connect?: Maybe<Array<KitchenWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<KitchenCreateOrConnectWithoutPromoFacilityRulesInput>>;
  create?: Maybe<Array<KitchenCreateWithoutPromoFacilityRulesInput>>;
  delete?: Maybe<Array<KitchenWhereUniqueInput>>;
  deleteMany?: Maybe<Array<KitchenScalarWhereInput>>;
  disconnect?: Maybe<Array<KitchenWhereUniqueInput>>;
  set?: Maybe<Array<KitchenWhereUniqueInput>>;
  update?: Maybe<Array<KitchenUpdateWithWhereUniqueWithoutPromoFacilityRulesInput>>;
  updateMany?: Maybe<Array<KitchenUpdateManyWithWhereWithoutPromoFacilityRulesInput>>;
  upsert?: Maybe<Array<KitchenUpsertWithWhereUniqueWithoutPromoFacilityRulesInput>>;
};

export type KitchenUpdateManyWithoutUsersInput = {
  connect?: Maybe<Array<KitchenWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<KitchenCreateOrConnectWithoutUsersInput>>;
  create?: Maybe<Array<KitchenCreateWithoutUsersInput>>;
  delete?: Maybe<Array<KitchenWhereUniqueInput>>;
  deleteMany?: Maybe<Array<KitchenScalarWhereInput>>;
  disconnect?: Maybe<Array<KitchenWhereUniqueInput>>;
  set?: Maybe<Array<KitchenWhereUniqueInput>>;
  update?: Maybe<Array<KitchenUpdateWithWhereUniqueWithoutUsersInput>>;
  updateMany?: Maybe<Array<KitchenUpdateManyWithWhereWithoutUsersInput>>;
  upsert?: Maybe<Array<KitchenUpsertWithWhereUniqueWithoutUsersInput>>;
};

export type KitchenUpdateOneRequiredWithoutKitchenMenuItemsInput = {
  connect?: Maybe<KitchenWhereUniqueInput>;
  connectOrCreate?: Maybe<KitchenCreateOrConnectWithoutKitchenMenuItemsInput>;
  create?: Maybe<KitchenCreateWithoutKitchenMenuItemsInput>;
  update?: Maybe<KitchenUpdateWithoutKitchenMenuItemsInput>;
  upsert?: Maybe<KitchenUpsertWithoutKitchenMenuItemsInput>;
};

export type KitchenUpdateOneWithoutExtraInput = {
  connect?: Maybe<KitchenWhereUniqueInput>;
  connectOrCreate?: Maybe<KitchenCreateOrConnectWithoutExtraInput>;
  create?: Maybe<KitchenCreateWithoutExtraInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<KitchenUpdateWithoutExtraInput>;
  upsert?: Maybe<KitchenUpsertWithoutExtraInput>;
};

export type KitchenUpdateOneWithoutKitchenTicketInput = {
  connect?: Maybe<KitchenWhereUniqueInput>;
  connectOrCreate?: Maybe<KitchenCreateOrConnectWithoutKitchenTicketInput>;
  create?: Maybe<KitchenCreateWithoutKitchenTicketInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<KitchenUpdateWithoutKitchenTicketInput>;
  upsert?: Maybe<KitchenUpsertWithoutKitchenTicketInput>;
};

export type KitchenUpdateOneWithoutMenuCategoriesInput = {
  connect?: Maybe<KitchenWhereUniqueInput>;
  connectOrCreate?: Maybe<KitchenCreateOrConnectWithoutMenuCategoriesInput>;
  create?: Maybe<KitchenCreateWithoutMenuCategoriesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<KitchenUpdateWithoutMenuCategoriesInput>;
  upsert?: Maybe<KitchenUpsertWithoutMenuCategoriesInput>;
};

export type KitchenUpdateOneWithoutMenuSchedulesInput = {
  connect?: Maybe<KitchenWhereUniqueInput>;
  connectOrCreate?: Maybe<KitchenCreateOrConnectWithoutMenuSchedulesInput>;
  create?: Maybe<KitchenCreateWithoutMenuSchedulesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<KitchenUpdateWithoutMenuSchedulesInput>;
  upsert?: Maybe<KitchenUpsertWithoutMenuSchedulesInput>;
};

export type KitchenUpdateOneWithoutWorkplaceInput = {
  connect?: Maybe<KitchenWhereUniqueInput>;
  connectOrCreate?: Maybe<KitchenCreateOrConnectWithoutWorkplaceInput>;
  create?: Maybe<KitchenCreateWithoutWorkplaceInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<KitchenUpdateWithoutWorkplaceInput>;
  upsert?: Maybe<KitchenUpsertWithoutWorkplaceInput>;
};

export type KitchenUpdateWithWhereUniqueWithoutFacilityInput = {
  data: KitchenUpdateWithoutFacilityInput;
  where: KitchenWhereUniqueInput;
};

export type KitchenUpdateWithWhereUniqueWithoutPowerBiReportInput = {
  data: KitchenUpdateWithoutPowerBiReportInput;
  where: KitchenWhereUniqueInput;
};

export type KitchenUpdateWithWhereUniqueWithoutPromoFacilityRulesInput = {
  data: KitchenUpdateWithoutPromoFacilityRulesInput;
  where: KitchenWhereUniqueInput;
};

export type KitchenUpdateWithWhereUniqueWithoutUsersInput = {
  data: KitchenUpdateWithoutUsersInput;
  where: KitchenWhereUniqueInput;
};

export type KitchenUpdateWithoutExtraInput = {
  chowlyApiKey?: Maybe<NullableStringFieldUpdateOperationsInput>;
  color?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cookTime?: Maybe<IntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  cuisine?: Maybe<NullableStringFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  enableChowly?: Maybe<BoolFieldUpdateOperationsInput>;
  facility?: Maybe<FacilityUpdateOneRequiredWithoutKitchensInput>;
  headerImgUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  kitchenMenuItems?: Maybe<KitchenMenuItemUpdateManyWithoutKitchenInput>;
  kitchenTicket?: Maybe<KitchenTicketUpdateManyWithoutKitchenInput>;
  logoUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  menuCategories?: Maybe<MenuCategoryUpdateManyWithoutKitchenInput>;
  menuSchedules?: Maybe<MenuScheduleUpdateManyWithoutKitchenInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationHours?: Maybe<KitchenUpdateoperationHoursInput>;
  originalLocation?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  powerBiReport?: Maybe<PowerBiReportUpdateManyWithoutAvailableToConceptsInput>;
  promoFacilityRules?: Maybe<PromoFacilityRuleUpdateManyWithoutKitchensInput>;
  readonly?: Maybe<BoolFieldUpdateOperationsInput>;
  slug?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sortId?: Maybe<IntFieldUpdateOperationsInput>;
  status?: Maybe<EnumKitchenStatusFieldUpdateOperationsInput>;
  story?: Maybe<NullableStringFieldUpdateOperationsInput>;
  type?: Maybe<EnumKitchenTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  users?: Maybe<UserUpdateManyWithoutKitchensInput>;
  workplace?: Maybe<WorkplaceUpdateOneWithoutKitchenInput>;
};

export type KitchenUpdateWithoutFacilityInput = {
  Extra?: Maybe<ExtraUpdateManyWithoutKitchenInput>;
  chowlyApiKey?: Maybe<NullableStringFieldUpdateOperationsInput>;
  color?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cookTime?: Maybe<IntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  cuisine?: Maybe<NullableStringFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  enableChowly?: Maybe<BoolFieldUpdateOperationsInput>;
  headerImgUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  kitchenMenuItems?: Maybe<KitchenMenuItemUpdateManyWithoutKitchenInput>;
  kitchenTicket?: Maybe<KitchenTicketUpdateManyWithoutKitchenInput>;
  logoUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  menuCategories?: Maybe<MenuCategoryUpdateManyWithoutKitchenInput>;
  menuSchedules?: Maybe<MenuScheduleUpdateManyWithoutKitchenInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationHours?: Maybe<KitchenUpdateoperationHoursInput>;
  originalLocation?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  powerBiReport?: Maybe<PowerBiReportUpdateManyWithoutAvailableToConceptsInput>;
  promoFacilityRules?: Maybe<PromoFacilityRuleUpdateManyWithoutKitchensInput>;
  readonly?: Maybe<BoolFieldUpdateOperationsInput>;
  slug?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sortId?: Maybe<IntFieldUpdateOperationsInput>;
  status?: Maybe<EnumKitchenStatusFieldUpdateOperationsInput>;
  story?: Maybe<NullableStringFieldUpdateOperationsInput>;
  type?: Maybe<EnumKitchenTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  users?: Maybe<UserUpdateManyWithoutKitchensInput>;
  workplace?: Maybe<WorkplaceUpdateOneWithoutKitchenInput>;
};

export type KitchenUpdateWithoutKitchenMenuItemsInput = {
  Extra?: Maybe<ExtraUpdateManyWithoutKitchenInput>;
  chowlyApiKey?: Maybe<NullableStringFieldUpdateOperationsInput>;
  color?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cookTime?: Maybe<IntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  cuisine?: Maybe<NullableStringFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  enableChowly?: Maybe<BoolFieldUpdateOperationsInput>;
  facility?: Maybe<FacilityUpdateOneRequiredWithoutKitchensInput>;
  headerImgUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  kitchenTicket?: Maybe<KitchenTicketUpdateManyWithoutKitchenInput>;
  logoUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  menuCategories?: Maybe<MenuCategoryUpdateManyWithoutKitchenInput>;
  menuSchedules?: Maybe<MenuScheduleUpdateManyWithoutKitchenInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationHours?: Maybe<KitchenUpdateoperationHoursInput>;
  originalLocation?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  powerBiReport?: Maybe<PowerBiReportUpdateManyWithoutAvailableToConceptsInput>;
  promoFacilityRules?: Maybe<PromoFacilityRuleUpdateManyWithoutKitchensInput>;
  readonly?: Maybe<BoolFieldUpdateOperationsInput>;
  slug?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sortId?: Maybe<IntFieldUpdateOperationsInput>;
  status?: Maybe<EnumKitchenStatusFieldUpdateOperationsInput>;
  story?: Maybe<NullableStringFieldUpdateOperationsInput>;
  type?: Maybe<EnumKitchenTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  users?: Maybe<UserUpdateManyWithoutKitchensInput>;
  workplace?: Maybe<WorkplaceUpdateOneWithoutKitchenInput>;
};

export type KitchenUpdateWithoutKitchenTicketInput = {
  Extra?: Maybe<ExtraUpdateManyWithoutKitchenInput>;
  chowlyApiKey?: Maybe<NullableStringFieldUpdateOperationsInput>;
  color?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cookTime?: Maybe<IntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  cuisine?: Maybe<NullableStringFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  enableChowly?: Maybe<BoolFieldUpdateOperationsInput>;
  facility?: Maybe<FacilityUpdateOneRequiredWithoutKitchensInput>;
  headerImgUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  kitchenMenuItems?: Maybe<KitchenMenuItemUpdateManyWithoutKitchenInput>;
  logoUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  menuCategories?: Maybe<MenuCategoryUpdateManyWithoutKitchenInput>;
  menuSchedules?: Maybe<MenuScheduleUpdateManyWithoutKitchenInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationHours?: Maybe<KitchenUpdateoperationHoursInput>;
  originalLocation?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  powerBiReport?: Maybe<PowerBiReportUpdateManyWithoutAvailableToConceptsInput>;
  promoFacilityRules?: Maybe<PromoFacilityRuleUpdateManyWithoutKitchensInput>;
  readonly?: Maybe<BoolFieldUpdateOperationsInput>;
  slug?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sortId?: Maybe<IntFieldUpdateOperationsInput>;
  status?: Maybe<EnumKitchenStatusFieldUpdateOperationsInput>;
  story?: Maybe<NullableStringFieldUpdateOperationsInput>;
  type?: Maybe<EnumKitchenTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  users?: Maybe<UserUpdateManyWithoutKitchensInput>;
  workplace?: Maybe<WorkplaceUpdateOneWithoutKitchenInput>;
};

export type KitchenUpdateWithoutMenuCategoriesInput = {
  Extra?: Maybe<ExtraUpdateManyWithoutKitchenInput>;
  chowlyApiKey?: Maybe<NullableStringFieldUpdateOperationsInput>;
  color?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cookTime?: Maybe<IntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  cuisine?: Maybe<NullableStringFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  enableChowly?: Maybe<BoolFieldUpdateOperationsInput>;
  facility?: Maybe<FacilityUpdateOneRequiredWithoutKitchensInput>;
  headerImgUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  kitchenMenuItems?: Maybe<KitchenMenuItemUpdateManyWithoutKitchenInput>;
  kitchenTicket?: Maybe<KitchenTicketUpdateManyWithoutKitchenInput>;
  logoUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  menuSchedules?: Maybe<MenuScheduleUpdateManyWithoutKitchenInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationHours?: Maybe<KitchenUpdateoperationHoursInput>;
  originalLocation?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  powerBiReport?: Maybe<PowerBiReportUpdateManyWithoutAvailableToConceptsInput>;
  promoFacilityRules?: Maybe<PromoFacilityRuleUpdateManyWithoutKitchensInput>;
  readonly?: Maybe<BoolFieldUpdateOperationsInput>;
  slug?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sortId?: Maybe<IntFieldUpdateOperationsInput>;
  status?: Maybe<EnumKitchenStatusFieldUpdateOperationsInput>;
  story?: Maybe<NullableStringFieldUpdateOperationsInput>;
  type?: Maybe<EnumKitchenTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  users?: Maybe<UserUpdateManyWithoutKitchensInput>;
  workplace?: Maybe<WorkplaceUpdateOneWithoutKitchenInput>;
};

export type KitchenUpdateWithoutMenuSchedulesInput = {
  Extra?: Maybe<ExtraUpdateManyWithoutKitchenInput>;
  chowlyApiKey?: Maybe<NullableStringFieldUpdateOperationsInput>;
  color?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cookTime?: Maybe<IntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  cuisine?: Maybe<NullableStringFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  enableChowly?: Maybe<BoolFieldUpdateOperationsInput>;
  facility?: Maybe<FacilityUpdateOneRequiredWithoutKitchensInput>;
  headerImgUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  kitchenMenuItems?: Maybe<KitchenMenuItemUpdateManyWithoutKitchenInput>;
  kitchenTicket?: Maybe<KitchenTicketUpdateManyWithoutKitchenInput>;
  logoUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  menuCategories?: Maybe<MenuCategoryUpdateManyWithoutKitchenInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationHours?: Maybe<KitchenUpdateoperationHoursInput>;
  originalLocation?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  powerBiReport?: Maybe<PowerBiReportUpdateManyWithoutAvailableToConceptsInput>;
  promoFacilityRules?: Maybe<PromoFacilityRuleUpdateManyWithoutKitchensInput>;
  readonly?: Maybe<BoolFieldUpdateOperationsInput>;
  slug?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sortId?: Maybe<IntFieldUpdateOperationsInput>;
  status?: Maybe<EnumKitchenStatusFieldUpdateOperationsInput>;
  story?: Maybe<NullableStringFieldUpdateOperationsInput>;
  type?: Maybe<EnumKitchenTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  users?: Maybe<UserUpdateManyWithoutKitchensInput>;
  workplace?: Maybe<WorkplaceUpdateOneWithoutKitchenInput>;
};

export type KitchenUpdateWithoutPowerBiReportInput = {
  Extra?: Maybe<ExtraUpdateManyWithoutKitchenInput>;
  chowlyApiKey?: Maybe<NullableStringFieldUpdateOperationsInput>;
  color?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cookTime?: Maybe<IntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  cuisine?: Maybe<NullableStringFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  enableChowly?: Maybe<BoolFieldUpdateOperationsInput>;
  facility?: Maybe<FacilityUpdateOneRequiredWithoutKitchensInput>;
  headerImgUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  kitchenMenuItems?: Maybe<KitchenMenuItemUpdateManyWithoutKitchenInput>;
  kitchenTicket?: Maybe<KitchenTicketUpdateManyWithoutKitchenInput>;
  logoUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  menuCategories?: Maybe<MenuCategoryUpdateManyWithoutKitchenInput>;
  menuSchedules?: Maybe<MenuScheduleUpdateManyWithoutKitchenInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationHours?: Maybe<KitchenUpdateoperationHoursInput>;
  originalLocation?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  promoFacilityRules?: Maybe<PromoFacilityRuleUpdateManyWithoutKitchensInput>;
  readonly?: Maybe<BoolFieldUpdateOperationsInput>;
  slug?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sortId?: Maybe<IntFieldUpdateOperationsInput>;
  status?: Maybe<EnumKitchenStatusFieldUpdateOperationsInput>;
  story?: Maybe<NullableStringFieldUpdateOperationsInput>;
  type?: Maybe<EnumKitchenTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  users?: Maybe<UserUpdateManyWithoutKitchensInput>;
  workplace?: Maybe<WorkplaceUpdateOneWithoutKitchenInput>;
};

export type KitchenUpdateWithoutPromoFacilityRulesInput = {
  Extra?: Maybe<ExtraUpdateManyWithoutKitchenInput>;
  chowlyApiKey?: Maybe<NullableStringFieldUpdateOperationsInput>;
  color?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cookTime?: Maybe<IntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  cuisine?: Maybe<NullableStringFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  enableChowly?: Maybe<BoolFieldUpdateOperationsInput>;
  facility?: Maybe<FacilityUpdateOneRequiredWithoutKitchensInput>;
  headerImgUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  kitchenMenuItems?: Maybe<KitchenMenuItemUpdateManyWithoutKitchenInput>;
  kitchenTicket?: Maybe<KitchenTicketUpdateManyWithoutKitchenInput>;
  logoUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  menuCategories?: Maybe<MenuCategoryUpdateManyWithoutKitchenInput>;
  menuSchedules?: Maybe<MenuScheduleUpdateManyWithoutKitchenInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationHours?: Maybe<KitchenUpdateoperationHoursInput>;
  originalLocation?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  powerBiReport?: Maybe<PowerBiReportUpdateManyWithoutAvailableToConceptsInput>;
  readonly?: Maybe<BoolFieldUpdateOperationsInput>;
  slug?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sortId?: Maybe<IntFieldUpdateOperationsInput>;
  status?: Maybe<EnumKitchenStatusFieldUpdateOperationsInput>;
  story?: Maybe<NullableStringFieldUpdateOperationsInput>;
  type?: Maybe<EnumKitchenTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  users?: Maybe<UserUpdateManyWithoutKitchensInput>;
  workplace?: Maybe<WorkplaceUpdateOneWithoutKitchenInput>;
};

export type KitchenUpdateWithoutUsersInput = {
  Extra?: Maybe<ExtraUpdateManyWithoutKitchenInput>;
  chowlyApiKey?: Maybe<NullableStringFieldUpdateOperationsInput>;
  color?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cookTime?: Maybe<IntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  cuisine?: Maybe<NullableStringFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  enableChowly?: Maybe<BoolFieldUpdateOperationsInput>;
  facility?: Maybe<FacilityUpdateOneRequiredWithoutKitchensInput>;
  headerImgUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  kitchenMenuItems?: Maybe<KitchenMenuItemUpdateManyWithoutKitchenInput>;
  kitchenTicket?: Maybe<KitchenTicketUpdateManyWithoutKitchenInput>;
  logoUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  menuCategories?: Maybe<MenuCategoryUpdateManyWithoutKitchenInput>;
  menuSchedules?: Maybe<MenuScheduleUpdateManyWithoutKitchenInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationHours?: Maybe<KitchenUpdateoperationHoursInput>;
  originalLocation?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  powerBiReport?: Maybe<PowerBiReportUpdateManyWithoutAvailableToConceptsInput>;
  promoFacilityRules?: Maybe<PromoFacilityRuleUpdateManyWithoutKitchensInput>;
  readonly?: Maybe<BoolFieldUpdateOperationsInput>;
  slug?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sortId?: Maybe<IntFieldUpdateOperationsInput>;
  status?: Maybe<EnumKitchenStatusFieldUpdateOperationsInput>;
  story?: Maybe<NullableStringFieldUpdateOperationsInput>;
  type?: Maybe<EnumKitchenTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  workplace?: Maybe<WorkplaceUpdateOneWithoutKitchenInput>;
};

export type KitchenUpdateWithoutWorkplaceInput = {
  Extra?: Maybe<ExtraUpdateManyWithoutKitchenInput>;
  chowlyApiKey?: Maybe<NullableStringFieldUpdateOperationsInput>;
  color?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cookTime?: Maybe<IntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  cuisine?: Maybe<NullableStringFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  enableChowly?: Maybe<BoolFieldUpdateOperationsInput>;
  facility?: Maybe<FacilityUpdateOneRequiredWithoutKitchensInput>;
  headerImgUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  kitchenMenuItems?: Maybe<KitchenMenuItemUpdateManyWithoutKitchenInput>;
  kitchenTicket?: Maybe<KitchenTicketUpdateManyWithoutKitchenInput>;
  logoUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  menuCategories?: Maybe<MenuCategoryUpdateManyWithoutKitchenInput>;
  menuSchedules?: Maybe<MenuScheduleUpdateManyWithoutKitchenInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationHours?: Maybe<KitchenUpdateoperationHoursInput>;
  originalLocation?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  powerBiReport?: Maybe<PowerBiReportUpdateManyWithoutAvailableToConceptsInput>;
  promoFacilityRules?: Maybe<PromoFacilityRuleUpdateManyWithoutKitchensInput>;
  readonly?: Maybe<BoolFieldUpdateOperationsInput>;
  slug?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sortId?: Maybe<IntFieldUpdateOperationsInput>;
  status?: Maybe<EnumKitchenStatusFieldUpdateOperationsInput>;
  story?: Maybe<NullableStringFieldUpdateOperationsInput>;
  type?: Maybe<EnumKitchenTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  users?: Maybe<UserUpdateManyWithoutKitchensInput>;
};

export type KitchenUpdateoperationHoursInput = {
  push?: Maybe<Scalars['Json']>;
  set?: Maybe<Array<Scalars['Json']>>;
};

export type KitchenUpsertInput = {
  color?: Maybe<Scalars['String']>;
  cookTime: Scalars['Int'];
  cuisine?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  enableChowly: Scalars['Boolean'];
  headerImgUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  logoUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  originalLocation?: Maybe<Scalars['String']>;
  ownerIds: Array<Scalars['ID']>;
  phone?: Maybe<Scalars['String']>;
  status: KitchenStatus;
  story?: Maybe<Scalars['String']>;
};

export type KitchenUpsertWithWhereUniqueWithoutFacilityInput = {
  create: KitchenCreateWithoutFacilityInput;
  update: KitchenUpdateWithoutFacilityInput;
  where: KitchenWhereUniqueInput;
};

export type KitchenUpsertWithWhereUniqueWithoutPowerBiReportInput = {
  create: KitchenCreateWithoutPowerBiReportInput;
  update: KitchenUpdateWithoutPowerBiReportInput;
  where: KitchenWhereUniqueInput;
};

export type KitchenUpsertWithWhereUniqueWithoutPromoFacilityRulesInput = {
  create: KitchenCreateWithoutPromoFacilityRulesInput;
  update: KitchenUpdateWithoutPromoFacilityRulesInput;
  where: KitchenWhereUniqueInput;
};

export type KitchenUpsertWithWhereUniqueWithoutUsersInput = {
  create: KitchenCreateWithoutUsersInput;
  update: KitchenUpdateWithoutUsersInput;
  where: KitchenWhereUniqueInput;
};

export type KitchenUpsertWithoutExtraInput = {
  create: KitchenCreateWithoutExtraInput;
  update: KitchenUpdateWithoutExtraInput;
};

export type KitchenUpsertWithoutKitchenMenuItemsInput = {
  create: KitchenCreateWithoutKitchenMenuItemsInput;
  update: KitchenUpdateWithoutKitchenMenuItemsInput;
};

export type KitchenUpsertWithoutKitchenTicketInput = {
  create: KitchenCreateWithoutKitchenTicketInput;
  update: KitchenUpdateWithoutKitchenTicketInput;
};

export type KitchenUpsertWithoutMenuCategoriesInput = {
  create: KitchenCreateWithoutMenuCategoriesInput;
  update: KitchenUpdateWithoutMenuCategoriesInput;
};

export type KitchenUpsertWithoutMenuSchedulesInput = {
  create: KitchenCreateWithoutMenuSchedulesInput;
  update: KitchenUpdateWithoutMenuSchedulesInput;
};

export type KitchenUpsertWithoutWorkplaceInput = {
  create: KitchenCreateWithoutWorkplaceInput;
  update: KitchenUpdateWithoutWorkplaceInput;
};

export type KitchenWhereInput = {
  AND?: Maybe<Array<KitchenWhereInput>>;
  Extra?: Maybe<ExtraListRelationFilter>;
  NOT?: Maybe<Array<KitchenWhereInput>>;
  OR?: Maybe<Array<KitchenWhereInput>>;
  chowlyApiKey?: Maybe<StringNullableFilter>;
  color?: Maybe<StringNullableFilter>;
  cookTime?: Maybe<IntFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  cuisine?: Maybe<StringNullableFilter>;
  description?: Maybe<StringNullableFilter>;
  email?: Maybe<StringNullableFilter>;
  enableChowly?: Maybe<BoolFilter>;
  facility?: Maybe<FacilityWhereInput>;
  facilityId?: Maybe<StringFilter>;
  headerImgUrl?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  kitchenMenuItems?: Maybe<KitchenMenuItemListRelationFilter>;
  kitchenTicket?: Maybe<KitchenTicketListRelationFilter>;
  logoUrl?: Maybe<StringNullableFilter>;
  menuCategories?: Maybe<MenuCategoryListRelationFilter>;
  menuSchedules?: Maybe<MenuScheduleListRelationFilter>;
  name?: Maybe<StringFilter>;
  operationHours?: Maybe<JsonNullableListFilter>;
  originalLocation?: Maybe<StringNullableFilter>;
  phone?: Maybe<StringNullableFilter>;
  powerBiReport?: Maybe<PowerBiReportListRelationFilter>;
  promoFacilityRules?: Maybe<PromoFacilityRuleListRelationFilter>;
  readonly?: Maybe<BoolFilter>;
  slug?: Maybe<StringNullableFilter>;
  sortId?: Maybe<IntFilter>;
  status?: Maybe<EnumKitchenStatusFilter>;
  story?: Maybe<StringNullableFilter>;
  type?: Maybe<EnumKitchenTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  users?: Maybe<UserListRelationFilter>;
  workplace?: Maybe<WorkplaceWhereInput>;
  workplaceId?: Maybe<StringNullableFilter>;
};

export type KitchenWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  slug_facilityId?: Maybe<KitchenSlugFacilityIdCompoundUniqueInput>;
  workplaceId?: Maybe<Scalars['String']>;
};

export type LineItemRefundCreateManyOrderItemInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  creditCardRefund: Scalars['Float'];
  deliveryFee: Scalars['Float'];
  deliveryFeeTax: Scalars['Float'];
  gratuity: Scalars['Float'];
  id?: Maybe<Scalars['String']>;
  itemsTax: Scalars['Float'];
  refundId: Scalars['String'];
  serviceFee: Scalars['Float'];
  serviceFeeTax: Scalars['Float'];
  subTotalGross: Scalars['Float'];
  subTotalNet: Scalars['Float'];
  totalGiftCardRefund: Scalars['Float'];
  totalRefund: Scalars['Float'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LineItemRefundCreateManyOrderItemInputEnvelope = {
  data?: Maybe<Array<LineItemRefundCreateManyOrderItemInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type LineItemRefundCreateManyRefundInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  creditCardRefund: Scalars['Float'];
  deliveryFee: Scalars['Float'];
  deliveryFeeTax: Scalars['Float'];
  gratuity: Scalars['Float'];
  id?: Maybe<Scalars['String']>;
  itemsTax: Scalars['Float'];
  orderItemId: Scalars['String'];
  serviceFee: Scalars['Float'];
  serviceFeeTax: Scalars['Float'];
  subTotalGross: Scalars['Float'];
  subTotalNet: Scalars['Float'];
  totalGiftCardRefund: Scalars['Float'];
  totalRefund: Scalars['Float'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LineItemRefundCreateManyRefundInputEnvelope = {
  data?: Maybe<Array<LineItemRefundCreateManyRefundInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type LineItemRefundCreateNestedManyWithoutOrderItemInput = {
  connect?: Maybe<Array<LineItemRefundWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LineItemRefundCreateOrConnectWithoutOrderItemInput>>;
  create?: Maybe<Array<LineItemRefundCreateWithoutOrderItemInput>>;
  createMany?: Maybe<LineItemRefundCreateManyOrderItemInputEnvelope>;
};

export type LineItemRefundCreateNestedManyWithoutRefundInput = {
  connect?: Maybe<Array<LineItemRefundWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LineItemRefundCreateOrConnectWithoutRefundInput>>;
  create?: Maybe<Array<LineItemRefundCreateWithoutRefundInput>>;
  createMany?: Maybe<LineItemRefundCreateManyRefundInputEnvelope>;
};

export type LineItemRefundCreateOrConnectWithoutOrderItemInput = {
  create: LineItemRefundCreateWithoutOrderItemInput;
  where: LineItemRefundWhereUniqueInput;
};

export type LineItemRefundCreateOrConnectWithoutRefundInput = {
  create: LineItemRefundCreateWithoutRefundInput;
  where: LineItemRefundWhereUniqueInput;
};

export type LineItemRefundCreateWithoutOrderItemInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  creditCardRefund: Scalars['Float'];
  deliveryFee: Scalars['Float'];
  deliveryFeeTax: Scalars['Float'];
  gratuity: Scalars['Float'];
  id?: Maybe<Scalars['String']>;
  itemsTax: Scalars['Float'];
  refund: RefundCreateNestedOneWithoutLineItemRefundInput;
  serviceFee: Scalars['Float'];
  serviceFeeTax: Scalars['Float'];
  subTotalGross: Scalars['Float'];
  subTotalNet: Scalars['Float'];
  totalGiftCardRefund: Scalars['Float'];
  totalRefund: Scalars['Float'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LineItemRefundCreateWithoutRefundInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  creditCardRefund: Scalars['Float'];
  deliveryFee: Scalars['Float'];
  deliveryFeeTax: Scalars['Float'];
  gratuity: Scalars['Float'];
  id?: Maybe<Scalars['String']>;
  itemsTax: Scalars['Float'];
  orderItem: OrderItemCreateNestedOneWithoutSingleRefundItemDetailInput;
  serviceFee: Scalars['Float'];
  serviceFeeTax: Scalars['Float'];
  subTotalGross: Scalars['Float'];
  subTotalNet: Scalars['Float'];
  totalGiftCardRefund: Scalars['Float'];
  totalRefund: Scalars['Float'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LineItemRefundListRelationFilter = {
  every?: Maybe<LineItemRefundWhereInput>;
  none?: Maybe<LineItemRefundWhereInput>;
  some?: Maybe<LineItemRefundWhereInput>;
};

export type LineItemRefundScalarWhereInput = {
  AND?: Maybe<Array<LineItemRefundScalarWhereInput>>;
  NOT?: Maybe<Array<LineItemRefundScalarWhereInput>>;
  OR?: Maybe<Array<LineItemRefundScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  creditCardRefund?: Maybe<FloatFilter>;
  deliveryFee?: Maybe<FloatFilter>;
  deliveryFeeTax?: Maybe<FloatFilter>;
  gratuity?: Maybe<FloatFilter>;
  id?: Maybe<StringFilter>;
  itemsTax?: Maybe<FloatFilter>;
  orderItemId?: Maybe<StringFilter>;
  refundId?: Maybe<StringFilter>;
  serviceFee?: Maybe<FloatFilter>;
  serviceFeeTax?: Maybe<FloatFilter>;
  subTotalGross?: Maybe<FloatFilter>;
  subTotalNet?: Maybe<FloatFilter>;
  totalGiftCardRefund?: Maybe<FloatFilter>;
  totalRefund?: Maybe<FloatFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type LineItemRefundUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  creditCardRefund?: Maybe<FloatFieldUpdateOperationsInput>;
  deliveryFee?: Maybe<FloatFieldUpdateOperationsInput>;
  deliveryFeeTax?: Maybe<FloatFieldUpdateOperationsInput>;
  gratuity?: Maybe<FloatFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  itemsTax?: Maybe<FloatFieldUpdateOperationsInput>;
  serviceFee?: Maybe<FloatFieldUpdateOperationsInput>;
  serviceFeeTax?: Maybe<FloatFieldUpdateOperationsInput>;
  subTotalGross?: Maybe<FloatFieldUpdateOperationsInput>;
  subTotalNet?: Maybe<FloatFieldUpdateOperationsInput>;
  totalGiftCardRefund?: Maybe<FloatFieldUpdateOperationsInput>;
  totalRefund?: Maybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type LineItemRefundUpdateManyWithWhereWithoutOrderItemInput = {
  data: LineItemRefundUpdateManyMutationInput;
  where: LineItemRefundScalarWhereInput;
};

export type LineItemRefundUpdateManyWithWhereWithoutRefundInput = {
  data: LineItemRefundUpdateManyMutationInput;
  where: LineItemRefundScalarWhereInput;
};

export type LineItemRefundUpdateManyWithoutOrderItemInput = {
  connect?: Maybe<Array<LineItemRefundWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LineItemRefundCreateOrConnectWithoutOrderItemInput>>;
  create?: Maybe<Array<LineItemRefundCreateWithoutOrderItemInput>>;
  createMany?: Maybe<LineItemRefundCreateManyOrderItemInputEnvelope>;
  delete?: Maybe<Array<LineItemRefundWhereUniqueInput>>;
  deleteMany?: Maybe<Array<LineItemRefundScalarWhereInput>>;
  disconnect?: Maybe<Array<LineItemRefundWhereUniqueInput>>;
  set?: Maybe<Array<LineItemRefundWhereUniqueInput>>;
  update?: Maybe<Array<LineItemRefundUpdateWithWhereUniqueWithoutOrderItemInput>>;
  updateMany?: Maybe<Array<LineItemRefundUpdateManyWithWhereWithoutOrderItemInput>>;
  upsert?: Maybe<Array<LineItemRefundUpsertWithWhereUniqueWithoutOrderItemInput>>;
};

export type LineItemRefundUpdateManyWithoutRefundInput = {
  connect?: Maybe<Array<LineItemRefundWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LineItemRefundCreateOrConnectWithoutRefundInput>>;
  create?: Maybe<Array<LineItemRefundCreateWithoutRefundInput>>;
  createMany?: Maybe<LineItemRefundCreateManyRefundInputEnvelope>;
  delete?: Maybe<Array<LineItemRefundWhereUniqueInput>>;
  deleteMany?: Maybe<Array<LineItemRefundScalarWhereInput>>;
  disconnect?: Maybe<Array<LineItemRefundWhereUniqueInput>>;
  set?: Maybe<Array<LineItemRefundWhereUniqueInput>>;
  update?: Maybe<Array<LineItemRefundUpdateWithWhereUniqueWithoutRefundInput>>;
  updateMany?: Maybe<Array<LineItemRefundUpdateManyWithWhereWithoutRefundInput>>;
  upsert?: Maybe<Array<LineItemRefundUpsertWithWhereUniqueWithoutRefundInput>>;
};

export type LineItemRefundUpdateWithWhereUniqueWithoutOrderItemInput = {
  data: LineItemRefundUpdateWithoutOrderItemInput;
  where: LineItemRefundWhereUniqueInput;
};

export type LineItemRefundUpdateWithWhereUniqueWithoutRefundInput = {
  data: LineItemRefundUpdateWithoutRefundInput;
  where: LineItemRefundWhereUniqueInput;
};

export type LineItemRefundUpdateWithoutOrderItemInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  creditCardRefund?: Maybe<FloatFieldUpdateOperationsInput>;
  deliveryFee?: Maybe<FloatFieldUpdateOperationsInput>;
  deliveryFeeTax?: Maybe<FloatFieldUpdateOperationsInput>;
  gratuity?: Maybe<FloatFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  itemsTax?: Maybe<FloatFieldUpdateOperationsInput>;
  refund?: Maybe<RefundUpdateOneRequiredWithoutLineItemRefundInput>;
  serviceFee?: Maybe<FloatFieldUpdateOperationsInput>;
  serviceFeeTax?: Maybe<FloatFieldUpdateOperationsInput>;
  subTotalGross?: Maybe<FloatFieldUpdateOperationsInput>;
  subTotalNet?: Maybe<FloatFieldUpdateOperationsInput>;
  totalGiftCardRefund?: Maybe<FloatFieldUpdateOperationsInput>;
  totalRefund?: Maybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type LineItemRefundUpdateWithoutRefundInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  creditCardRefund?: Maybe<FloatFieldUpdateOperationsInput>;
  deliveryFee?: Maybe<FloatFieldUpdateOperationsInput>;
  deliveryFeeTax?: Maybe<FloatFieldUpdateOperationsInput>;
  gratuity?: Maybe<FloatFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  itemsTax?: Maybe<FloatFieldUpdateOperationsInput>;
  orderItem?: Maybe<OrderItemUpdateOneRequiredWithoutSingleRefundItemDetailInput>;
  serviceFee?: Maybe<FloatFieldUpdateOperationsInput>;
  serviceFeeTax?: Maybe<FloatFieldUpdateOperationsInput>;
  subTotalGross?: Maybe<FloatFieldUpdateOperationsInput>;
  subTotalNet?: Maybe<FloatFieldUpdateOperationsInput>;
  totalGiftCardRefund?: Maybe<FloatFieldUpdateOperationsInput>;
  totalRefund?: Maybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type LineItemRefundUpsertWithWhereUniqueWithoutOrderItemInput = {
  create: LineItemRefundCreateWithoutOrderItemInput;
  update: LineItemRefundUpdateWithoutOrderItemInput;
  where: LineItemRefundWhereUniqueInput;
};

export type LineItemRefundUpsertWithWhereUniqueWithoutRefundInput = {
  create: LineItemRefundCreateWithoutRefundInput;
  update: LineItemRefundUpdateWithoutRefundInput;
  where: LineItemRefundWhereUniqueInput;
};

export type LineItemRefundWhereInput = {
  AND?: Maybe<Array<LineItemRefundWhereInput>>;
  NOT?: Maybe<Array<LineItemRefundWhereInput>>;
  OR?: Maybe<Array<LineItemRefundWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  creditCardRefund?: Maybe<FloatFilter>;
  deliveryFee?: Maybe<FloatFilter>;
  deliveryFeeTax?: Maybe<FloatFilter>;
  gratuity?: Maybe<FloatFilter>;
  id?: Maybe<StringFilter>;
  itemsTax?: Maybe<FloatFilter>;
  orderItem?: Maybe<OrderItemWhereInput>;
  orderItemId?: Maybe<StringFilter>;
  refund?: Maybe<RefundWhereInput>;
  refundId?: Maybe<StringFilter>;
  serviceFee?: Maybe<FloatFilter>;
  serviceFeeTax?: Maybe<FloatFilter>;
  subTotalGross?: Maybe<FloatFilter>;
  subTotalNet?: Maybe<FloatFilter>;
  totalGiftCardRefund?: Maybe<FloatFilter>;
  totalRefund?: Maybe<FloatFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type LineItemRefundWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type MenuCategory = {
  __typename?: 'MenuCategory';
  categoryType: MenuCategoryType;
  id: Scalars['String'];
  menuItemType: MenuItemType;
  menuItems: Array<KitchenMenuItem>;
  name: Scalars['String'];
};


export type MenuCategoryMenuItemsArgs = {
  after?: Maybe<KitchenMenuItemWhereUniqueInput>;
  before?: Maybe<KitchenMenuItemWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type MenuCategoryCreateInput = {
  categoryType: MenuCategoryType;
  menuItemType: MenuItemType;
  name: Scalars['String'];
};

export type MenuCategoryCreateManyFacilityInput = {
  categoryType?: Maybe<MenuCategoryType>;
  chowlyId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  kitchenId?: Maybe<Scalars['String']>;
  menuItemType?: Maybe<MenuItemType>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MenuCategoryCreateManyFacilityInputEnvelope = {
  data?: Maybe<Array<MenuCategoryCreateManyFacilityInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MenuCategoryCreateManyKitchenInput = {
  categoryType?: Maybe<MenuCategoryType>;
  chowlyId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  facilityId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  menuItemType?: Maybe<MenuItemType>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MenuCategoryCreateManyKitchenInputEnvelope = {
  data?: Maybe<Array<MenuCategoryCreateManyKitchenInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MenuCategoryCreateNestedManyWithoutFacilityInput = {
  connect?: Maybe<Array<MenuCategoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<MenuCategoryCreateOrConnectWithoutFacilityInput>>;
  create?: Maybe<Array<MenuCategoryCreateWithoutFacilityInput>>;
  createMany?: Maybe<MenuCategoryCreateManyFacilityInputEnvelope>;
};

export type MenuCategoryCreateNestedManyWithoutKitchenInput = {
  connect?: Maybe<Array<MenuCategoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<MenuCategoryCreateOrConnectWithoutKitchenInput>>;
  create?: Maybe<Array<MenuCategoryCreateWithoutKitchenInput>>;
  createMany?: Maybe<MenuCategoryCreateManyKitchenInputEnvelope>;
};

export type MenuCategoryCreateNestedManyWithoutMenuItemsInput = {
  connect?: Maybe<Array<MenuCategoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<MenuCategoryCreateOrConnectWithoutMenuItemsInput>>;
  create?: Maybe<Array<MenuCategoryCreateWithoutMenuItemsInput>>;
};

export type MenuCategoryCreateNestedOneWithoutMenuScheduleCategoriesInput = {
  connect?: Maybe<MenuCategoryWhereUniqueInput>;
  connectOrCreate?: Maybe<MenuCategoryCreateOrConnectWithoutMenuScheduleCategoriesInput>;
  create?: Maybe<MenuCategoryCreateWithoutMenuScheduleCategoriesInput>;
};

export type MenuCategoryCreateOrConnectWithoutFacilityInput = {
  create: MenuCategoryCreateWithoutFacilityInput;
  where: MenuCategoryWhereUniqueInput;
};

export type MenuCategoryCreateOrConnectWithoutKitchenInput = {
  create: MenuCategoryCreateWithoutKitchenInput;
  where: MenuCategoryWhereUniqueInput;
};

export type MenuCategoryCreateOrConnectWithoutMenuItemsInput = {
  create: MenuCategoryCreateWithoutMenuItemsInput;
  where: MenuCategoryWhereUniqueInput;
};

export type MenuCategoryCreateOrConnectWithoutMenuScheduleCategoriesInput = {
  create: MenuCategoryCreateWithoutMenuScheduleCategoriesInput;
  where: MenuCategoryWhereUniqueInput;
};

export type MenuCategoryCreateWithoutFacilityInput = {
  categoryType?: Maybe<MenuCategoryType>;
  chowlyId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  kitchen?: Maybe<KitchenCreateNestedOneWithoutMenuCategoriesInput>;
  menuItemType?: Maybe<MenuItemType>;
  menuItems?: Maybe<KitchenMenuItemCreateNestedManyWithoutMenuCategoriesInput>;
  menuScheduleCategories?: Maybe<MenuScheduleCategoryCreateNestedManyWithoutCategoryInput>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MenuCategoryCreateWithoutKitchenInput = {
  categoryType?: Maybe<MenuCategoryType>;
  chowlyId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  facility: FacilityCreateNestedOneWithoutMenuCategoriesInput;
  id?: Maybe<Scalars['String']>;
  menuItemType?: Maybe<MenuItemType>;
  menuItems?: Maybe<KitchenMenuItemCreateNestedManyWithoutMenuCategoriesInput>;
  menuScheduleCategories?: Maybe<MenuScheduleCategoryCreateNestedManyWithoutCategoryInput>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MenuCategoryCreateWithoutMenuItemsInput = {
  categoryType?: Maybe<MenuCategoryType>;
  chowlyId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  facility: FacilityCreateNestedOneWithoutMenuCategoriesInput;
  id?: Maybe<Scalars['String']>;
  kitchen?: Maybe<KitchenCreateNestedOneWithoutMenuCategoriesInput>;
  menuItemType?: Maybe<MenuItemType>;
  menuScheduleCategories?: Maybe<MenuScheduleCategoryCreateNestedManyWithoutCategoryInput>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MenuCategoryCreateWithoutMenuScheduleCategoriesInput = {
  categoryType?: Maybe<MenuCategoryType>;
  chowlyId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  facility: FacilityCreateNestedOneWithoutMenuCategoriesInput;
  id?: Maybe<Scalars['String']>;
  kitchen?: Maybe<KitchenCreateNestedOneWithoutMenuCategoriesInput>;
  menuItemType?: Maybe<MenuItemType>;
  menuItems?: Maybe<KitchenMenuItemCreateNestedManyWithoutMenuCategoriesInput>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MenuCategoryListRelationFilter = {
  every?: Maybe<MenuCategoryWhereInput>;
  none?: Maybe<MenuCategoryWhereInput>;
  some?: Maybe<MenuCategoryWhereInput>;
};

export type MenuCategoryScalarWhereInput = {
  AND?: Maybe<Array<MenuCategoryScalarWhereInput>>;
  NOT?: Maybe<Array<MenuCategoryScalarWhereInput>>;
  OR?: Maybe<Array<MenuCategoryScalarWhereInput>>;
  categoryType?: Maybe<EnumMenuCategoryTypeFilter>;
  chowlyId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  facilityId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  kitchenId?: Maybe<StringNullableFilter>;
  menuItemType?: Maybe<EnumMenuItemTypeFilter>;
  name?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export enum MenuCategoryType {
  Catering = 'CATERING',
  Regular = 'REGULAR',
  Special = 'SPECIAL'
}

export type MenuCategoryUpdateManyMutationInput = {
  categoryType?: Maybe<EnumMenuCategoryTypeFieldUpdateOperationsInput>;
  chowlyId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  menuItemType?: Maybe<EnumMenuItemTypeFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type MenuCategoryUpdateManyWithWhereWithoutFacilityInput = {
  data: MenuCategoryUpdateManyMutationInput;
  where: MenuCategoryScalarWhereInput;
};

export type MenuCategoryUpdateManyWithWhereWithoutKitchenInput = {
  data: MenuCategoryUpdateManyMutationInput;
  where: MenuCategoryScalarWhereInput;
};

export type MenuCategoryUpdateManyWithWhereWithoutMenuItemsInput = {
  data: MenuCategoryUpdateManyMutationInput;
  where: MenuCategoryScalarWhereInput;
};

export type MenuCategoryUpdateManyWithoutFacilityInput = {
  connect?: Maybe<Array<MenuCategoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<MenuCategoryCreateOrConnectWithoutFacilityInput>>;
  create?: Maybe<Array<MenuCategoryCreateWithoutFacilityInput>>;
  createMany?: Maybe<MenuCategoryCreateManyFacilityInputEnvelope>;
  delete?: Maybe<Array<MenuCategoryWhereUniqueInput>>;
  deleteMany?: Maybe<Array<MenuCategoryScalarWhereInput>>;
  disconnect?: Maybe<Array<MenuCategoryWhereUniqueInput>>;
  set?: Maybe<Array<MenuCategoryWhereUniqueInput>>;
  update?: Maybe<Array<MenuCategoryUpdateWithWhereUniqueWithoutFacilityInput>>;
  updateMany?: Maybe<Array<MenuCategoryUpdateManyWithWhereWithoutFacilityInput>>;
  upsert?: Maybe<Array<MenuCategoryUpsertWithWhereUniqueWithoutFacilityInput>>;
};

export type MenuCategoryUpdateManyWithoutKitchenInput = {
  connect?: Maybe<Array<MenuCategoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<MenuCategoryCreateOrConnectWithoutKitchenInput>>;
  create?: Maybe<Array<MenuCategoryCreateWithoutKitchenInput>>;
  createMany?: Maybe<MenuCategoryCreateManyKitchenInputEnvelope>;
  delete?: Maybe<Array<MenuCategoryWhereUniqueInput>>;
  deleteMany?: Maybe<Array<MenuCategoryScalarWhereInput>>;
  disconnect?: Maybe<Array<MenuCategoryWhereUniqueInput>>;
  set?: Maybe<Array<MenuCategoryWhereUniqueInput>>;
  update?: Maybe<Array<MenuCategoryUpdateWithWhereUniqueWithoutKitchenInput>>;
  updateMany?: Maybe<Array<MenuCategoryUpdateManyWithWhereWithoutKitchenInput>>;
  upsert?: Maybe<Array<MenuCategoryUpsertWithWhereUniqueWithoutKitchenInput>>;
};

export type MenuCategoryUpdateManyWithoutMenuItemsInput = {
  connect?: Maybe<Array<MenuCategoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<MenuCategoryCreateOrConnectWithoutMenuItemsInput>>;
  create?: Maybe<Array<MenuCategoryCreateWithoutMenuItemsInput>>;
  delete?: Maybe<Array<MenuCategoryWhereUniqueInput>>;
  deleteMany?: Maybe<Array<MenuCategoryScalarWhereInput>>;
  disconnect?: Maybe<Array<MenuCategoryWhereUniqueInput>>;
  set?: Maybe<Array<MenuCategoryWhereUniqueInput>>;
  update?: Maybe<Array<MenuCategoryUpdateWithWhereUniqueWithoutMenuItemsInput>>;
  updateMany?: Maybe<Array<MenuCategoryUpdateManyWithWhereWithoutMenuItemsInput>>;
  upsert?: Maybe<Array<MenuCategoryUpsertWithWhereUniqueWithoutMenuItemsInput>>;
};

export type MenuCategoryUpdateOneRequiredWithoutMenuScheduleCategoriesInput = {
  connect?: Maybe<MenuCategoryWhereUniqueInput>;
  connectOrCreate?: Maybe<MenuCategoryCreateOrConnectWithoutMenuScheduleCategoriesInput>;
  create?: Maybe<MenuCategoryCreateWithoutMenuScheduleCategoriesInput>;
  update?: Maybe<MenuCategoryUpdateWithoutMenuScheduleCategoriesInput>;
  upsert?: Maybe<MenuCategoryUpsertWithoutMenuScheduleCategoriesInput>;
};

export type MenuCategoryUpdateWithWhereUniqueWithoutFacilityInput = {
  data: MenuCategoryUpdateWithoutFacilityInput;
  where: MenuCategoryWhereUniqueInput;
};

export type MenuCategoryUpdateWithWhereUniqueWithoutKitchenInput = {
  data: MenuCategoryUpdateWithoutKitchenInput;
  where: MenuCategoryWhereUniqueInput;
};

export type MenuCategoryUpdateWithWhereUniqueWithoutMenuItemsInput = {
  data: MenuCategoryUpdateWithoutMenuItemsInput;
  where: MenuCategoryWhereUniqueInput;
};

export type MenuCategoryUpdateWithoutFacilityInput = {
  categoryType?: Maybe<EnumMenuCategoryTypeFieldUpdateOperationsInput>;
  chowlyId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  kitchen?: Maybe<KitchenUpdateOneWithoutMenuCategoriesInput>;
  menuItemType?: Maybe<EnumMenuItemTypeFieldUpdateOperationsInput>;
  menuItems?: Maybe<KitchenMenuItemUpdateManyWithoutMenuCategoriesInput>;
  menuScheduleCategories?: Maybe<MenuScheduleCategoryUpdateManyWithoutCategoryInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type MenuCategoryUpdateWithoutKitchenInput = {
  categoryType?: Maybe<EnumMenuCategoryTypeFieldUpdateOperationsInput>;
  chowlyId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  facility?: Maybe<FacilityUpdateOneRequiredWithoutMenuCategoriesInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  menuItemType?: Maybe<EnumMenuItemTypeFieldUpdateOperationsInput>;
  menuItems?: Maybe<KitchenMenuItemUpdateManyWithoutMenuCategoriesInput>;
  menuScheduleCategories?: Maybe<MenuScheduleCategoryUpdateManyWithoutCategoryInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type MenuCategoryUpdateWithoutMenuItemsInput = {
  categoryType?: Maybe<EnumMenuCategoryTypeFieldUpdateOperationsInput>;
  chowlyId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  facility?: Maybe<FacilityUpdateOneRequiredWithoutMenuCategoriesInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  kitchen?: Maybe<KitchenUpdateOneWithoutMenuCategoriesInput>;
  menuItemType?: Maybe<EnumMenuItemTypeFieldUpdateOperationsInput>;
  menuScheduleCategories?: Maybe<MenuScheduleCategoryUpdateManyWithoutCategoryInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type MenuCategoryUpdateWithoutMenuScheduleCategoriesInput = {
  categoryType?: Maybe<EnumMenuCategoryTypeFieldUpdateOperationsInput>;
  chowlyId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  facility?: Maybe<FacilityUpdateOneRequiredWithoutMenuCategoriesInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  kitchen?: Maybe<KitchenUpdateOneWithoutMenuCategoriesInput>;
  menuItemType?: Maybe<EnumMenuItemTypeFieldUpdateOperationsInput>;
  menuItems?: Maybe<KitchenMenuItemUpdateManyWithoutMenuCategoriesInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type MenuCategoryUpsertWithWhereUniqueWithoutFacilityInput = {
  create: MenuCategoryCreateWithoutFacilityInput;
  update: MenuCategoryUpdateWithoutFacilityInput;
  where: MenuCategoryWhereUniqueInput;
};

export type MenuCategoryUpsertWithWhereUniqueWithoutKitchenInput = {
  create: MenuCategoryCreateWithoutKitchenInput;
  update: MenuCategoryUpdateWithoutKitchenInput;
  where: MenuCategoryWhereUniqueInput;
};

export type MenuCategoryUpsertWithWhereUniqueWithoutMenuItemsInput = {
  create: MenuCategoryCreateWithoutMenuItemsInput;
  update: MenuCategoryUpdateWithoutMenuItemsInput;
  where: MenuCategoryWhereUniqueInput;
};

export type MenuCategoryUpsertWithoutMenuScheduleCategoriesInput = {
  create: MenuCategoryCreateWithoutMenuScheduleCategoriesInput;
  update: MenuCategoryUpdateWithoutMenuScheduleCategoriesInput;
};

export type MenuCategoryWhereInput = {
  AND?: Maybe<Array<MenuCategoryWhereInput>>;
  NOT?: Maybe<Array<MenuCategoryWhereInput>>;
  OR?: Maybe<Array<MenuCategoryWhereInput>>;
  categoryType?: Maybe<EnumMenuCategoryTypeFilter>;
  chowlyId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  facility?: Maybe<FacilityWhereInput>;
  facilityId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  kitchen?: Maybe<KitchenWhereInput>;
  kitchenId?: Maybe<StringNullableFilter>;
  menuItemType?: Maybe<EnumMenuItemTypeFilter>;
  menuItems?: Maybe<KitchenMenuItemListRelationFilter>;
  menuScheduleCategories?: Maybe<MenuScheduleCategoryListRelationFilter>;
  name?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type MenuCategoryWhereUniqueInput = {
  chowlyId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export enum MenuItemAvailability {
  Catering = 'CATERING',
  Consumer = 'CONSUMER',
  Kiosk = 'KIOSK',
  PickupOnly = 'PICKUP_ONLY'
}

export enum MenuItemLabel {
  Featured = 'FEATURED',
  New = 'NEW',
  Special = 'SPECIAL',
  ThreeCourses = 'THREE_COURSES'
}

export enum MenuItemType {
  Drink = 'DRINK',
  Food = 'FOOD',
  MealPack = 'MEAL_PACK'
}

export type MenuSchedule = {
  __typename?: 'MenuSchedule';
  end: Scalars['DateTime'];
  id: Scalars['String'];
  isActive: Scalars['Boolean'];
  isNonStop: Scalars['Boolean'];
  kitchen?: Maybe<Kitchen>;
  name?: Maybe<Scalars['String']>;
  repeatOnDays: Array<Scalars['Int']>;
  scheduleCategories: Array<MenuScheduleCategory>;
  sortedCategoryIds: Array<Scalars['String']>;
  start: Scalars['DateTime'];
};


export type MenuScheduleScheduleCategoriesArgs = {
  after?: Maybe<MenuScheduleCategoryWhereUniqueInput>;
  before?: Maybe<MenuScheduleCategoryWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type MenuScheduleCategory = {
  __typename?: 'MenuScheduleCategory';
  category: MenuCategory;
  id: Scalars['String'];
  menuItems: Array<KitchenMenuItem>;
  sortedMenuItemIds: Array<Scalars['String']>;
};


export type MenuScheduleCategoryMenuItemsArgs = {
  after?: Maybe<KitchenMenuItemWhereUniqueInput>;
  before?: Maybe<KitchenMenuItemWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type MenuScheduleCategoryCreateManyCategoryInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  menuScheduleId: Scalars['String'];
  sortedMenuItemIds?: Maybe<MenuScheduleCategoryCreateManysortedMenuItemIdsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MenuScheduleCategoryCreateManyCategoryInputEnvelope = {
  data?: Maybe<Array<MenuScheduleCategoryCreateManyCategoryInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MenuScheduleCategoryCreateManyMenuScheduleInput = {
  categoryId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  sortedMenuItemIds?: Maybe<MenuScheduleCategoryCreateManysortedMenuItemIdsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MenuScheduleCategoryCreateManyMenuScheduleInputEnvelope = {
  data?: Maybe<Array<MenuScheduleCategoryCreateManyMenuScheduleInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MenuScheduleCategoryCreateManysortedMenuItemIdsInput = {
  set?: Maybe<Array<Scalars['String']>>;
};

export type MenuScheduleCategoryCreateNestedManyWithoutCategoryInput = {
  connect?: Maybe<Array<MenuScheduleCategoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<MenuScheduleCategoryCreateOrConnectWithoutCategoryInput>>;
  create?: Maybe<Array<MenuScheduleCategoryCreateWithoutCategoryInput>>;
  createMany?: Maybe<MenuScheduleCategoryCreateManyCategoryInputEnvelope>;
};

export type MenuScheduleCategoryCreateNestedManyWithoutMenuItemsInput = {
  connect?: Maybe<Array<MenuScheduleCategoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<MenuScheduleCategoryCreateOrConnectWithoutMenuItemsInput>>;
  create?: Maybe<Array<MenuScheduleCategoryCreateWithoutMenuItemsInput>>;
};

export type MenuScheduleCategoryCreateNestedManyWithoutMenuScheduleInput = {
  connect?: Maybe<Array<MenuScheduleCategoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<MenuScheduleCategoryCreateOrConnectWithoutMenuScheduleInput>>;
  create?: Maybe<Array<MenuScheduleCategoryCreateWithoutMenuScheduleInput>>;
  createMany?: Maybe<MenuScheduleCategoryCreateManyMenuScheduleInputEnvelope>;
};

export type MenuScheduleCategoryCreateOrConnectWithoutCategoryInput = {
  create: MenuScheduleCategoryCreateWithoutCategoryInput;
  where: MenuScheduleCategoryWhereUniqueInput;
};

export type MenuScheduleCategoryCreateOrConnectWithoutMenuItemsInput = {
  create: MenuScheduleCategoryCreateWithoutMenuItemsInput;
  where: MenuScheduleCategoryWhereUniqueInput;
};

export type MenuScheduleCategoryCreateOrConnectWithoutMenuScheduleInput = {
  create: MenuScheduleCategoryCreateWithoutMenuScheduleInput;
  where: MenuScheduleCategoryWhereUniqueInput;
};

export type MenuScheduleCategoryCreateWithoutCategoryInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  menuItems?: Maybe<KitchenMenuItemCreateNestedManyWithoutMenuScheduleCategoriesInput>;
  menuSchedule: MenuScheduleCreateNestedOneWithoutScheduleCategoriesInput;
  sortedMenuItemIds?: Maybe<MenuScheduleCategoryCreatesortedMenuItemIdsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MenuScheduleCategoryCreateWithoutMenuItemsInput = {
  category: MenuCategoryCreateNestedOneWithoutMenuScheduleCategoriesInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  menuSchedule: MenuScheduleCreateNestedOneWithoutScheduleCategoriesInput;
  sortedMenuItemIds?: Maybe<MenuScheduleCategoryCreatesortedMenuItemIdsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MenuScheduleCategoryCreateWithoutMenuScheduleInput = {
  category: MenuCategoryCreateNestedOneWithoutMenuScheduleCategoriesInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  menuItems?: Maybe<KitchenMenuItemCreateNestedManyWithoutMenuScheduleCategoriesInput>;
  sortedMenuItemIds?: Maybe<MenuScheduleCategoryCreatesortedMenuItemIdsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MenuScheduleCategoryCreatesortedMenuItemIdsInput = {
  set?: Maybe<Array<Scalars['String']>>;
};

export type MenuScheduleCategoryInput = {
  categoryId: Scalars['ID'];
  id?: Maybe<Scalars['ID']>;
  sortedMenuItemIds: Array<Scalars['String']>;
};

export type MenuScheduleCategoryListRelationFilter = {
  every?: Maybe<MenuScheduleCategoryWhereInput>;
  none?: Maybe<MenuScheduleCategoryWhereInput>;
  some?: Maybe<MenuScheduleCategoryWhereInput>;
};

export type MenuScheduleCategoryScalarWhereInput = {
  AND?: Maybe<Array<MenuScheduleCategoryScalarWhereInput>>;
  NOT?: Maybe<Array<MenuScheduleCategoryScalarWhereInput>>;
  OR?: Maybe<Array<MenuScheduleCategoryScalarWhereInput>>;
  categoryId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  menuScheduleId?: Maybe<StringFilter>;
  sortedMenuItemIds?: Maybe<StringNullableListFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type MenuScheduleCategoryUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  sortedMenuItemIds?: Maybe<MenuScheduleCategoryUpdatesortedMenuItemIdsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type MenuScheduleCategoryUpdateManyWithWhereWithoutCategoryInput = {
  data: MenuScheduleCategoryUpdateManyMutationInput;
  where: MenuScheduleCategoryScalarWhereInput;
};

export type MenuScheduleCategoryUpdateManyWithWhereWithoutMenuItemsInput = {
  data: MenuScheduleCategoryUpdateManyMutationInput;
  where: MenuScheduleCategoryScalarWhereInput;
};

export type MenuScheduleCategoryUpdateManyWithWhereWithoutMenuScheduleInput = {
  data: MenuScheduleCategoryUpdateManyMutationInput;
  where: MenuScheduleCategoryScalarWhereInput;
};

export type MenuScheduleCategoryUpdateManyWithoutCategoryInput = {
  connect?: Maybe<Array<MenuScheduleCategoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<MenuScheduleCategoryCreateOrConnectWithoutCategoryInput>>;
  create?: Maybe<Array<MenuScheduleCategoryCreateWithoutCategoryInput>>;
  createMany?: Maybe<MenuScheduleCategoryCreateManyCategoryInputEnvelope>;
  delete?: Maybe<Array<MenuScheduleCategoryWhereUniqueInput>>;
  deleteMany?: Maybe<Array<MenuScheduleCategoryScalarWhereInput>>;
  disconnect?: Maybe<Array<MenuScheduleCategoryWhereUniqueInput>>;
  set?: Maybe<Array<MenuScheduleCategoryWhereUniqueInput>>;
  update?: Maybe<Array<MenuScheduleCategoryUpdateWithWhereUniqueWithoutCategoryInput>>;
  updateMany?: Maybe<Array<MenuScheduleCategoryUpdateManyWithWhereWithoutCategoryInput>>;
  upsert?: Maybe<Array<MenuScheduleCategoryUpsertWithWhereUniqueWithoutCategoryInput>>;
};

export type MenuScheduleCategoryUpdateManyWithoutMenuItemsInput = {
  connect?: Maybe<Array<MenuScheduleCategoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<MenuScheduleCategoryCreateOrConnectWithoutMenuItemsInput>>;
  create?: Maybe<Array<MenuScheduleCategoryCreateWithoutMenuItemsInput>>;
  delete?: Maybe<Array<MenuScheduleCategoryWhereUniqueInput>>;
  deleteMany?: Maybe<Array<MenuScheduleCategoryScalarWhereInput>>;
  disconnect?: Maybe<Array<MenuScheduleCategoryWhereUniqueInput>>;
  set?: Maybe<Array<MenuScheduleCategoryWhereUniqueInput>>;
  update?: Maybe<Array<MenuScheduleCategoryUpdateWithWhereUniqueWithoutMenuItemsInput>>;
  updateMany?: Maybe<Array<MenuScheduleCategoryUpdateManyWithWhereWithoutMenuItemsInput>>;
  upsert?: Maybe<Array<MenuScheduleCategoryUpsertWithWhereUniqueWithoutMenuItemsInput>>;
};

export type MenuScheduleCategoryUpdateManyWithoutMenuScheduleInput = {
  connect?: Maybe<Array<MenuScheduleCategoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<MenuScheduleCategoryCreateOrConnectWithoutMenuScheduleInput>>;
  create?: Maybe<Array<MenuScheduleCategoryCreateWithoutMenuScheduleInput>>;
  createMany?: Maybe<MenuScheduleCategoryCreateManyMenuScheduleInputEnvelope>;
  delete?: Maybe<Array<MenuScheduleCategoryWhereUniqueInput>>;
  deleteMany?: Maybe<Array<MenuScheduleCategoryScalarWhereInput>>;
  disconnect?: Maybe<Array<MenuScheduleCategoryWhereUniqueInput>>;
  set?: Maybe<Array<MenuScheduleCategoryWhereUniqueInput>>;
  update?: Maybe<Array<MenuScheduleCategoryUpdateWithWhereUniqueWithoutMenuScheduleInput>>;
  updateMany?: Maybe<Array<MenuScheduleCategoryUpdateManyWithWhereWithoutMenuScheduleInput>>;
  upsert?: Maybe<Array<MenuScheduleCategoryUpsertWithWhereUniqueWithoutMenuScheduleInput>>;
};

export type MenuScheduleCategoryUpdateWithWhereUniqueWithoutCategoryInput = {
  data: MenuScheduleCategoryUpdateWithoutCategoryInput;
  where: MenuScheduleCategoryWhereUniqueInput;
};

export type MenuScheduleCategoryUpdateWithWhereUniqueWithoutMenuItemsInput = {
  data: MenuScheduleCategoryUpdateWithoutMenuItemsInput;
  where: MenuScheduleCategoryWhereUniqueInput;
};

export type MenuScheduleCategoryUpdateWithWhereUniqueWithoutMenuScheduleInput = {
  data: MenuScheduleCategoryUpdateWithoutMenuScheduleInput;
  where: MenuScheduleCategoryWhereUniqueInput;
};

export type MenuScheduleCategoryUpdateWithoutCategoryInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  menuItems?: Maybe<KitchenMenuItemUpdateManyWithoutMenuScheduleCategoriesInput>;
  menuSchedule?: Maybe<MenuScheduleUpdateOneRequiredWithoutScheduleCategoriesInput>;
  sortedMenuItemIds?: Maybe<MenuScheduleCategoryUpdatesortedMenuItemIdsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type MenuScheduleCategoryUpdateWithoutMenuItemsInput = {
  category?: Maybe<MenuCategoryUpdateOneRequiredWithoutMenuScheduleCategoriesInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  menuSchedule?: Maybe<MenuScheduleUpdateOneRequiredWithoutScheduleCategoriesInput>;
  sortedMenuItemIds?: Maybe<MenuScheduleCategoryUpdatesortedMenuItemIdsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type MenuScheduleCategoryUpdateWithoutMenuScheduleInput = {
  category?: Maybe<MenuCategoryUpdateOneRequiredWithoutMenuScheduleCategoriesInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  menuItems?: Maybe<KitchenMenuItemUpdateManyWithoutMenuScheduleCategoriesInput>;
  sortedMenuItemIds?: Maybe<MenuScheduleCategoryUpdatesortedMenuItemIdsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type MenuScheduleCategoryUpdatesortedMenuItemIdsInput = {
  push?: Maybe<Scalars['String']>;
  set?: Maybe<Array<Scalars['String']>>;
};

export type MenuScheduleCategoryUpsertWithWhereUniqueWithoutCategoryInput = {
  create: MenuScheduleCategoryCreateWithoutCategoryInput;
  update: MenuScheduleCategoryUpdateWithoutCategoryInput;
  where: MenuScheduleCategoryWhereUniqueInput;
};

export type MenuScheduleCategoryUpsertWithWhereUniqueWithoutMenuItemsInput = {
  create: MenuScheduleCategoryCreateWithoutMenuItemsInput;
  update: MenuScheduleCategoryUpdateWithoutMenuItemsInput;
  where: MenuScheduleCategoryWhereUniqueInput;
};

export type MenuScheduleCategoryUpsertWithWhereUniqueWithoutMenuScheduleInput = {
  create: MenuScheduleCategoryCreateWithoutMenuScheduleInput;
  update: MenuScheduleCategoryUpdateWithoutMenuScheduleInput;
  where: MenuScheduleCategoryWhereUniqueInput;
};

export type MenuScheduleCategoryWhereInput = {
  AND?: Maybe<Array<MenuScheduleCategoryWhereInput>>;
  NOT?: Maybe<Array<MenuScheduleCategoryWhereInput>>;
  OR?: Maybe<Array<MenuScheduleCategoryWhereInput>>;
  category?: Maybe<MenuCategoryWhereInput>;
  categoryId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  menuItems?: Maybe<KitchenMenuItemListRelationFilter>;
  menuSchedule?: Maybe<MenuScheduleWhereInput>;
  menuScheduleId?: Maybe<StringFilter>;
  sortedMenuItemIds?: Maybe<StringNullableListFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type MenuScheduleCategoryWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type MenuScheduleCreateManyFacilityInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  end: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isNonStop?: Maybe<Scalars['Boolean']>;
  kitchenId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  repeatOnDays?: Maybe<MenuScheduleCreateManyrepeatOnDaysInput>;
  sortedCategoryIds?: Maybe<MenuScheduleCreateManysortedCategoryIdsInput>;
  start: Scalars['DateTime'];
  type?: Maybe<MenuScheduleType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MenuScheduleCreateManyFacilityInputEnvelope = {
  data?: Maybe<Array<MenuScheduleCreateManyFacilityInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MenuScheduleCreateManyKitchenInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  end: Scalars['DateTime'];
  facilityId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isNonStop?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  repeatOnDays?: Maybe<MenuScheduleCreateManyrepeatOnDaysInput>;
  sortedCategoryIds?: Maybe<MenuScheduleCreateManysortedCategoryIdsInput>;
  start: Scalars['DateTime'];
  type?: Maybe<MenuScheduleType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MenuScheduleCreateManyKitchenInputEnvelope = {
  data?: Maybe<Array<MenuScheduleCreateManyKitchenInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MenuScheduleCreateManyrepeatOnDaysInput = {
  set?: Maybe<Array<Scalars['Int']>>;
};

export type MenuScheduleCreateManysortedCategoryIdsInput = {
  set?: Maybe<Array<Scalars['String']>>;
};

export type MenuScheduleCreateNestedManyWithoutFacilityInput = {
  connect?: Maybe<Array<MenuScheduleWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<MenuScheduleCreateOrConnectWithoutFacilityInput>>;
  create?: Maybe<Array<MenuScheduleCreateWithoutFacilityInput>>;
  createMany?: Maybe<MenuScheduleCreateManyFacilityInputEnvelope>;
};

export type MenuScheduleCreateNestedManyWithoutKitchenInput = {
  connect?: Maybe<Array<MenuScheduleWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<MenuScheduleCreateOrConnectWithoutKitchenInput>>;
  create?: Maybe<Array<MenuScheduleCreateWithoutKitchenInput>>;
  createMany?: Maybe<MenuScheduleCreateManyKitchenInputEnvelope>;
};

export type MenuScheduleCreateNestedOneWithoutScheduleCategoriesInput = {
  connect?: Maybe<MenuScheduleWhereUniqueInput>;
  connectOrCreate?: Maybe<MenuScheduleCreateOrConnectWithoutScheduleCategoriesInput>;
  create?: Maybe<MenuScheduleCreateWithoutScheduleCategoriesInput>;
};

export type MenuScheduleCreateOrConnectWithoutFacilityInput = {
  create: MenuScheduleCreateWithoutFacilityInput;
  where: MenuScheduleWhereUniqueInput;
};

export type MenuScheduleCreateOrConnectWithoutKitchenInput = {
  create: MenuScheduleCreateWithoutKitchenInput;
  where: MenuScheduleWhereUniqueInput;
};

export type MenuScheduleCreateOrConnectWithoutScheduleCategoriesInput = {
  create: MenuScheduleCreateWithoutScheduleCategoriesInput;
  where: MenuScheduleWhereUniqueInput;
};

export type MenuScheduleCreateWithoutFacilityInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  end: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isNonStop?: Maybe<Scalars['Boolean']>;
  kitchen?: Maybe<KitchenCreateNestedOneWithoutMenuSchedulesInput>;
  name?: Maybe<Scalars['String']>;
  repeatOnDays?: Maybe<MenuScheduleCreaterepeatOnDaysInput>;
  scheduleCategories?: Maybe<MenuScheduleCategoryCreateNestedManyWithoutMenuScheduleInput>;
  sortedCategoryIds?: Maybe<MenuScheduleCreatesortedCategoryIdsInput>;
  start: Scalars['DateTime'];
  type?: Maybe<MenuScheduleType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MenuScheduleCreateWithoutKitchenInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  end: Scalars['DateTime'];
  facility: FacilityCreateNestedOneWithoutMenuSchedulesInput;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isNonStop?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  repeatOnDays?: Maybe<MenuScheduleCreaterepeatOnDaysInput>;
  scheduleCategories?: Maybe<MenuScheduleCategoryCreateNestedManyWithoutMenuScheduleInput>;
  sortedCategoryIds?: Maybe<MenuScheduleCreatesortedCategoryIdsInput>;
  start: Scalars['DateTime'];
  type?: Maybe<MenuScheduleType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MenuScheduleCreateWithoutScheduleCategoriesInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  end: Scalars['DateTime'];
  facility: FacilityCreateNestedOneWithoutMenuSchedulesInput;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isNonStop?: Maybe<Scalars['Boolean']>;
  kitchen?: Maybe<KitchenCreateNestedOneWithoutMenuSchedulesInput>;
  name?: Maybe<Scalars['String']>;
  repeatOnDays?: Maybe<MenuScheduleCreaterepeatOnDaysInput>;
  sortedCategoryIds?: Maybe<MenuScheduleCreatesortedCategoryIdsInput>;
  start: Scalars['DateTime'];
  type?: Maybe<MenuScheduleType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MenuScheduleCreaterepeatOnDaysInput = {
  set?: Maybe<Array<Scalars['Int']>>;
};

export type MenuScheduleCreatesortedCategoryIdsInput = {
  set?: Maybe<Array<Scalars['String']>>;
};

export enum MenuScheduleErrors {
  Overlap = 'OVERLAP',
  WrongDatetime = 'WRONG_DATETIME'
}

export type MenuScheduleListRelationFilter = {
  every?: Maybe<MenuScheduleWhereInput>;
  none?: Maybe<MenuScheduleWhereInput>;
  some?: Maybe<MenuScheduleWhereInput>;
};

export type MenuScheduleScalarWhereInput = {
  AND?: Maybe<Array<MenuScheduleScalarWhereInput>>;
  NOT?: Maybe<Array<MenuScheduleScalarWhereInput>>;
  OR?: Maybe<Array<MenuScheduleScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  end?: Maybe<DateTimeFilter>;
  facilityId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  isActive?: Maybe<BoolFilter>;
  isNonStop?: Maybe<BoolFilter>;
  kitchenId?: Maybe<StringNullableFilter>;
  name?: Maybe<StringNullableFilter>;
  repeatOnDays?: Maybe<IntNullableListFilter>;
  sortedCategoryIds?: Maybe<StringNullableListFilter>;
  start?: Maybe<DateTimeFilter>;
  type?: Maybe<EnumMenuScheduleTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export enum MenuScheduleType {
  Regular = 'REGULAR',
  Special = 'SPECIAL'
}

export type MenuScheduleUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  end?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isActive?: Maybe<BoolFieldUpdateOperationsInput>;
  isNonStop?: Maybe<BoolFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  repeatOnDays?: Maybe<MenuScheduleUpdaterepeatOnDaysInput>;
  sortedCategoryIds?: Maybe<MenuScheduleUpdatesortedCategoryIdsInput>;
  start?: Maybe<DateTimeFieldUpdateOperationsInput>;
  type?: Maybe<EnumMenuScheduleTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type MenuScheduleUpdateManyWithWhereWithoutFacilityInput = {
  data: MenuScheduleUpdateManyMutationInput;
  where: MenuScheduleScalarWhereInput;
};

export type MenuScheduleUpdateManyWithWhereWithoutKitchenInput = {
  data: MenuScheduleUpdateManyMutationInput;
  where: MenuScheduleScalarWhereInput;
};

export type MenuScheduleUpdateManyWithoutFacilityInput = {
  connect?: Maybe<Array<MenuScheduleWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<MenuScheduleCreateOrConnectWithoutFacilityInput>>;
  create?: Maybe<Array<MenuScheduleCreateWithoutFacilityInput>>;
  createMany?: Maybe<MenuScheduleCreateManyFacilityInputEnvelope>;
  delete?: Maybe<Array<MenuScheduleWhereUniqueInput>>;
  deleteMany?: Maybe<Array<MenuScheduleScalarWhereInput>>;
  disconnect?: Maybe<Array<MenuScheduleWhereUniqueInput>>;
  set?: Maybe<Array<MenuScheduleWhereUniqueInput>>;
  update?: Maybe<Array<MenuScheduleUpdateWithWhereUniqueWithoutFacilityInput>>;
  updateMany?: Maybe<Array<MenuScheduleUpdateManyWithWhereWithoutFacilityInput>>;
  upsert?: Maybe<Array<MenuScheduleUpsertWithWhereUniqueWithoutFacilityInput>>;
};

export type MenuScheduleUpdateManyWithoutKitchenInput = {
  connect?: Maybe<Array<MenuScheduleWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<MenuScheduleCreateOrConnectWithoutKitchenInput>>;
  create?: Maybe<Array<MenuScheduleCreateWithoutKitchenInput>>;
  createMany?: Maybe<MenuScheduleCreateManyKitchenInputEnvelope>;
  delete?: Maybe<Array<MenuScheduleWhereUniqueInput>>;
  deleteMany?: Maybe<Array<MenuScheduleScalarWhereInput>>;
  disconnect?: Maybe<Array<MenuScheduleWhereUniqueInput>>;
  set?: Maybe<Array<MenuScheduleWhereUniqueInput>>;
  update?: Maybe<Array<MenuScheduleUpdateWithWhereUniqueWithoutKitchenInput>>;
  updateMany?: Maybe<Array<MenuScheduleUpdateManyWithWhereWithoutKitchenInput>>;
  upsert?: Maybe<Array<MenuScheduleUpsertWithWhereUniqueWithoutKitchenInput>>;
};

export type MenuScheduleUpdateOneRequiredWithoutScheduleCategoriesInput = {
  connect?: Maybe<MenuScheduleWhereUniqueInput>;
  connectOrCreate?: Maybe<MenuScheduleCreateOrConnectWithoutScheduleCategoriesInput>;
  create?: Maybe<MenuScheduleCreateWithoutScheduleCategoriesInput>;
  update?: Maybe<MenuScheduleUpdateWithoutScheduleCategoriesInput>;
  upsert?: Maybe<MenuScheduleUpsertWithoutScheduleCategoriesInput>;
};

export type MenuScheduleUpdateWithWhereUniqueWithoutFacilityInput = {
  data: MenuScheduleUpdateWithoutFacilityInput;
  where: MenuScheduleWhereUniqueInput;
};

export type MenuScheduleUpdateWithWhereUniqueWithoutKitchenInput = {
  data: MenuScheduleUpdateWithoutKitchenInput;
  where: MenuScheduleWhereUniqueInput;
};

export type MenuScheduleUpdateWithoutFacilityInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  end?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isActive?: Maybe<BoolFieldUpdateOperationsInput>;
  isNonStop?: Maybe<BoolFieldUpdateOperationsInput>;
  kitchen?: Maybe<KitchenUpdateOneWithoutMenuSchedulesInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  repeatOnDays?: Maybe<MenuScheduleUpdaterepeatOnDaysInput>;
  scheduleCategories?: Maybe<MenuScheduleCategoryUpdateManyWithoutMenuScheduleInput>;
  sortedCategoryIds?: Maybe<MenuScheduleUpdatesortedCategoryIdsInput>;
  start?: Maybe<DateTimeFieldUpdateOperationsInput>;
  type?: Maybe<EnumMenuScheduleTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type MenuScheduleUpdateWithoutKitchenInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  end?: Maybe<DateTimeFieldUpdateOperationsInput>;
  facility?: Maybe<FacilityUpdateOneRequiredWithoutMenuSchedulesInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isActive?: Maybe<BoolFieldUpdateOperationsInput>;
  isNonStop?: Maybe<BoolFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  repeatOnDays?: Maybe<MenuScheduleUpdaterepeatOnDaysInput>;
  scheduleCategories?: Maybe<MenuScheduleCategoryUpdateManyWithoutMenuScheduleInput>;
  sortedCategoryIds?: Maybe<MenuScheduleUpdatesortedCategoryIdsInput>;
  start?: Maybe<DateTimeFieldUpdateOperationsInput>;
  type?: Maybe<EnumMenuScheduleTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type MenuScheduleUpdateWithoutScheduleCategoriesInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  end?: Maybe<DateTimeFieldUpdateOperationsInput>;
  facility?: Maybe<FacilityUpdateOneRequiredWithoutMenuSchedulesInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isActive?: Maybe<BoolFieldUpdateOperationsInput>;
  isNonStop?: Maybe<BoolFieldUpdateOperationsInput>;
  kitchen?: Maybe<KitchenUpdateOneWithoutMenuSchedulesInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  repeatOnDays?: Maybe<MenuScheduleUpdaterepeatOnDaysInput>;
  sortedCategoryIds?: Maybe<MenuScheduleUpdatesortedCategoryIdsInput>;
  start?: Maybe<DateTimeFieldUpdateOperationsInput>;
  type?: Maybe<EnumMenuScheduleTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type MenuScheduleUpdaterepeatOnDaysInput = {
  push?: Maybe<Scalars['Int']>;
  set?: Maybe<Array<Scalars['Int']>>;
};

export type MenuScheduleUpdatesortedCategoryIdsInput = {
  push?: Maybe<Scalars['String']>;
  set?: Maybe<Array<Scalars['String']>>;
};

export type MenuScheduleUpsertInput = {
  deleteScheduleCategoryIds: Array<Scalars['String']>;
  /** Contains both end day (Date) and hours (Time) */
  end: Scalars['DateTime'];
  id?: Maybe<Scalars['ID']>;
  isActive: Scalars['Boolean'];
  isNonStop: Scalars['Boolean'];
  kitchenId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  /** [0 - Sun, 1 - Mon, 2 - Tue, 3 - Wen, 4 - Thu, 5 - Fri, 6 - Sat] (based on the https://www.w3schools.com/jsref/jsref_getday.asp) */
  repeatOnDays: Array<Scalars['Int']>;
  scheduleCategories: Array<MenuScheduleCategoryInput>;
  sortedCategoryIds: Array<Scalars['String']>;
  /** Contains both start day (Date) and hours (Time) */
  start: Scalars['DateTime'];
};

export type MenuScheduleUpsertWithWhereUniqueWithoutFacilityInput = {
  create: MenuScheduleCreateWithoutFacilityInput;
  update: MenuScheduleUpdateWithoutFacilityInput;
  where: MenuScheduleWhereUniqueInput;
};

export type MenuScheduleUpsertWithWhereUniqueWithoutKitchenInput = {
  create: MenuScheduleCreateWithoutKitchenInput;
  update: MenuScheduleUpdateWithoutKitchenInput;
  where: MenuScheduleWhereUniqueInput;
};

export type MenuScheduleUpsertWithoutScheduleCategoriesInput = {
  create: MenuScheduleCreateWithoutScheduleCategoriesInput;
  update: MenuScheduleUpdateWithoutScheduleCategoriesInput;
};

export type MenuScheduleWhereInput = {
  AND?: Maybe<Array<MenuScheduleWhereInput>>;
  NOT?: Maybe<Array<MenuScheduleWhereInput>>;
  OR?: Maybe<Array<MenuScheduleWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  end?: Maybe<DateTimeFilter>;
  facility?: Maybe<FacilityWhereInput>;
  facilityId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  isActive?: Maybe<BoolFilter>;
  isNonStop?: Maybe<BoolFilter>;
  kitchen?: Maybe<KitchenWhereInput>;
  kitchenId?: Maybe<StringNullableFilter>;
  name?: Maybe<StringNullableFilter>;
  repeatOnDays?: Maybe<IntNullableListFilter>;
  scheduleCategories?: Maybe<MenuScheduleCategoryListRelationFilter>;
  sortedCategoryIds?: Maybe<StringNullableListFilter>;
  start?: Maybe<DateTimeFilter>;
  type?: Maybe<EnumMenuScheduleTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type MenuScheduleWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type Message = {
  __typename?: 'Message';
  orderId: Scalars['String'];
  shortId: Scalars['String'];
  status: OrderStatus;
  type: MessageType;
};

export enum MessageType {
  OrderReminderNextDay = 'ORDER_REMINDER_NEXT_DAY',
  OrderReminderToday = 'ORDER_REMINDER_TODAY',
  OrderStatusDelivery = 'ORDER_STATUS_DELIVERY',
  PickupOrderStatusReady = 'PICKUP_ORDER_STATUS_READY',
  Test = 'TEST'
}

export enum MobilePlatform {
  Android = 'ANDROID',
  Ios = 'IOS'
}

export type Mutation = {
  __typename?: 'Mutation';
  adminAddCustomerTag: UserCustomerInfo;
  /** Mutation for creating menu category in the system */
  adminCreateMenuCategory: MenuCategory;
  /** Create kitchen menu item */
  adminCreateOneKitchenMenuItem: KitchenMenuItem;
  adminCreateOrderBundleManually: OrderBundle;
  adminCreatePowerBiReport: PowerBiReport;
  adminCreateStation: Station;
  adminDeleteKitchen: Kitchen;
  /** Mutation for deleting menu category in the system */
  adminDeleteMenuCategory: MenuCategory;
  /** For admin portal users to delete menu schedule. */
  adminDeleteMenuSchedule: MenuSchedule;
  /** For admin portal users to delete modifier choice. */
  adminDeleteModifierChoice: ExtraItem;
  /** For admin portal users to delete modifier group. */
  adminDeleteModifierGroup: Extra;
  adminDeleteOneKitchenMenuItem: KitchenMenuItem;
  /** For admin portal users to delete a promo facility rule */
  adminDeleteOnePromoRule: PromoFacilityRule;
  /** For Crave admin to delete method override. */
  adminDeleteOrderMethodOverride: OrderMethodOverride;
  adminDeletePortalUser: Scalars['Boolean'];
  adminDeletePowerBiReport?: Maybe<PowerBiReport>;
  adminDeleteStation?: Maybe<Station>;
  /** For admin portal users to disable a promo code. */
  adminDisablePromo: Promo;
  adminNotifyFutureOrders: Scalars['Boolean'];
  adminRemoveCustomerTag: UserCustomerInfo;
  /** Mutation for re-ordering the position of a kitchen */
  adminReorderKitchen?: Maybe<Kitchen>;
  adminSendMessageToAll: Scalars['Boolean'];
  adminSendTestMessage: SendResponses;
  adminSortModifierChoices: Array<ExtraItem>;
  adminSortModifierGroups: Scalars['Boolean'];
  /** update cart upsell items */
  adminUpdateCartUpsellItem: Array<CartUpsellItem>;
  adminUpdateChoiceStatus: ExtraItem;
  adminUpdateEmail: EmailUpdate;
  adminUpdateFacility: Facility;
  adminUpdateFacilityContact: Facility;
  adminUpdateFacilityHeroImage: Facility;
  adminUpdateFacilityKioskLogo: Facility;
  adminUpdateFacilityLogo: Facility;
  /** For Crave admin to update facility operation hours. */
  adminUpdateFacilityOperationHours: Facility;
  adminUpdateGlobalSort: Scalars['Boolean'];
  /** For admin portal users to update kitchen color which displays in menu planner. */
  adminUpdateKitchenColor: Kitchen;
  adminUpdateKitchenMenuItemInventory: KitchenMenuItem;
  adminUpdateKitchenMenuItemStatus: KitchenMenuItem;
  /** For restaurant owner to update kitchen operation hours. */
  adminUpdateKitchenOperationHours: Kitchen;
  /** For admin portal users to update menu schedule status. */
  adminUpdateMenuScheduleStatus: MenuSchedule;
  adminUpdateModifierDefaultChoice: Extra;
  /** Update kitchen menu item */
  adminUpdateOneKitchenMenuItem: KitchenMenuItem;
  adminUpdateOneKitchenTicket?: Maybe<KitchenTicket>;
  adminUpdateOrderDeliveryTime: Scalars['Boolean'];
  /** For admin portal users to update order method status(active/not active). */
  adminUpdateOrderMethodOverrideStatus: OrderMethodOverride;
  adminUpdateOrderPaidAt: Order;
  adminUpdateOrderPreparingUntil: Scalars['Boolean'];
  adminUpdateOrderStatus: Order;
  adminUpdatePassword: Scalars['Boolean'];
  adminUpdatePowerBiReport?: Maybe<PowerBiReport>;
  adminUpdatePromoBanner?: Maybe<PromoBanner>;
  adminUpdateReportSort: Scalars['Boolean'];
  adminUpdateSort: Scalars['Boolean'];
  adminUpdateStation?: Maybe<Station>;
  adminUpdateUser: User;
  adminUpdateUserAddress: Address;
  adminUpsertKitchen: Kitchen;
  /** For concepts to create and update menu schedule. */
  adminUpsertMenuSchedule: MenuSchedule;
  /** For concepts to create and update a modifier. */
  adminUpsertModifier: Extra;
  /** For Crave admin to create or update method override. */
  adminUpsertOrderMethodOverride: OrderMethodOverride;
  /** For creating and updating promos */
  adminUpsertPromo: Promo;
  /** For Crave admin to create and update special menu schedule. */
  adminUpsertSpecialMenuSchedule: MenuSchedule;
  adminUserInvite?: Maybe<User>;
  clientLog: Scalars['Boolean'];
  createOrderBundles: Array<OrderBundle>;
  customerClearCart: UserCustomerInfo;
  customerConfirmKioskOrder: KioskOrderConfirmation;
  customerConfirmOrder: OrderConfirmation;
  customerCreateBraintreeClientToken: Scalars['String'];
  customerCreateCustomerLead: CustomerLead;
  customerCreateUser: User;
  customerCreateUserAddress: Address;
  customerDeleteAddress: Scalars['Boolean'];
  customerDeleteCartItem?: Maybe<UserCustomerInfo>;
  customerDeleteCartItems?: Maybe<UserCustomerInfo>;
  customerDeleteMyAccount: Scalars['Boolean'];
  customerKlaviyoReceipt: Scalars['Boolean'];
  customerRegisterDevice: Device;
  customerResetPassword: Scalars['Boolean'];
  customerResetPromosOrGiftCardCodesOnCart: UserCustomerInfo;
  customerSaveTilledPaymentMethod: TilledPaymentMethod;
  customerSetDefaultAddress?: Maybe<Address>;
  customerSetupConsumerApp: ConsumerAppSetup;
  customerUpdateMenuItemsOnCart: UserCustomerInfo;
  customerUpdatePromosOnCart: UserCustomerInfo;
  customerUpdateUser: User;
  customerUpdateUserAddress: Address;
  customerUpdateUtensilsOnCart: UserCustomerInfo;
  customerUpsertCartItem: UserCustomerInfo;
  customerValidateKioskPromo: KioskOrderValidation;
  customerValidateOrder: OrderValidation;
  kdsCompleteKitchenTicket: KitchenTicket;
  kdsKitchenTicketPrinted: KitchenTicket;
  kdsKitchenTicketStartPreparing: KitchenTicket;
  kdsOrderPickupComplete: Order;
  kdsRefireKitchenTicket: Order;
  kdsRefireOrder: Order;
  kdsRefireOrderItem: Order;
  kdsSetOrderWorkplace: Order;
  /** @deprecated Use more generic kdsSetOrderWorkplace */
  kdsSetPickupStation: Order;
  kdsUndoOrder: Order;
  kdsUndoTicket: KitchenTicket;
  orderRefundCreate: OrderRefund;
  setupConsumerApp: ConsumerAppSetup;
  updateCartTip: Cart;
  userOperationsUpdate?: Maybe<User>;
  userProfileUpdate?: Maybe<User>;
};


export type MutationAdminAddCustomerTagArgs = {
  customerId: Scalars['ID'];
  tag: TagCreateOrConnectInput;
};


export type MutationAdminCreateMenuCategoryArgs = {
  input: MenuCategoryCreateInput;
};


export type MutationAdminCreateOneKitchenMenuItemArgs = {
  data: KitchenMenuItemInput;
};


export type MutationAdminCreateOrderBundleManuallyArgs = {
  orderId: Scalars['ID'];
};


export type MutationAdminCreatePowerBiReportArgs = {
  data: PowerBiReportCreateInput;
};


export type MutationAdminCreateStationArgs = {
  data: StationCreateInput;
};


export type MutationAdminDeleteKitchenArgs = {
  id: Scalars['ID'];
};


export type MutationAdminDeleteMenuCategoryArgs = {
  id: Scalars['ID'];
};


export type MutationAdminDeleteMenuScheduleArgs = {
  id: Scalars['ID'];
};


export type MutationAdminDeleteModifierChoiceArgs = {
  id: Scalars['ID'];
};


export type MutationAdminDeleteModifierGroupArgs = {
  id: Scalars['ID'];
};


export type MutationAdminDeleteOneKitchenMenuItemArgs = {
  id: Scalars['ID'];
};


export type MutationAdminDeleteOnePromoRuleArgs = {
  id: Scalars['ID'];
};


export type MutationAdminDeleteOrderMethodOverrideArgs = {
  id: Scalars['ID'];
};


export type MutationAdminDeletePortalUserArgs = {
  id: Scalars['ID'];
};


export type MutationAdminDeletePowerBiReportArgs = {
  where: PowerBiReportWhereUniqueInput;
};


export type MutationAdminDeleteStationArgs = {
  where: StationWhereUniqueInput;
};


export type MutationAdminDisablePromoArgs = {
  id: Scalars['ID'];
};


export type MutationAdminNotifyFutureOrdersArgs = {
  send?: Maybe<Scalars['Boolean']>;
};


export type MutationAdminRemoveCustomerTagArgs = {
  customerId: Scalars['ID'];
  tagId: Scalars['ID'];
};


export type MutationAdminReorderKitchenArgs = {
  input: AdminReorderKitchenInput;
};


export type MutationAdminSendMessageToAllArgs = {
  message: Scalars['String'];
  send?: Maybe<Scalars['Boolean']>;
  title: Scalars['String'];
};


export type MutationAdminSendTestMessageArgs = {
  dryRun?: Maybe<Scalars['Boolean']>;
  type?: Maybe<MessageType>;
};


export type MutationAdminSortModifierChoicesArgs = {
  input: Array<Scalars['String']>;
};


export type MutationAdminSortModifierGroupsArgs = {
  input: Array<Scalars['String']>;
};


export type MutationAdminUpdateCartUpsellItemArgs = {
  input: CartUpsellItemInput;
};


export type MutationAdminUpdateChoiceStatusArgs = {
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
};


export type MutationAdminUpdateEmailArgs = {
  input: EmailUpdateInput;
};


export type MutationAdminUpdateFacilityArgs = {
  input: FacilityUpdateInput;
};


export type MutationAdminUpdateFacilityContactArgs = {
  email: Scalars['String'];
  id: Scalars['ID'];
  phone: Scalars['String'];
};


export type MutationAdminUpdateFacilityHeroImageArgs = {
  heroImageUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};


export type MutationAdminUpdateFacilityKioskLogoArgs = {
  id: Scalars['ID'];
  kioskLogoUrl?: Maybe<Scalars['String']>;
};


export type MutationAdminUpdateFacilityLogoArgs = {
  id: Scalars['ID'];
  logoUrl?: Maybe<Scalars['String']>;
};


export type MutationAdminUpdateFacilityOperationHoursArgs = {
  input: OperationHoursUpdateInput;
};


export type MutationAdminUpdateGlobalSortArgs = {
  input: Array<Scalars['String']>;
};


export type MutationAdminUpdateKitchenColorArgs = {
  color: Scalars['String'];
  id: Scalars['ID'];
};


export type MutationAdminUpdateKitchenMenuItemInventoryArgs = {
  id: Scalars['ID'];
  inventoryCount: Scalars['Int'];
};


export type MutationAdminUpdateKitchenMenuItemStatusArgs = {
  id: Scalars['ID'];
  status: KitchenMenuItemStatus;
};


export type MutationAdminUpdateKitchenOperationHoursArgs = {
  input: OperationHoursUpdateInput;
};


export type MutationAdminUpdateMenuScheduleStatusArgs = {
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
};


export type MutationAdminUpdateModifierDefaultChoiceArgs = {
  defaultItemId: Scalars['String'];
  id: Scalars['ID'];
};


export type MutationAdminUpdateOneKitchenMenuItemArgs = {
  data: KitchenMenuItemInput;
  id: Scalars['String'];
};


export type MutationAdminUpdateOneKitchenTicketArgs = {
  data: KitchenTicketUpdateInput;
  where: KitchenTicketWhereUniqueInput;
};


export type MutationAdminUpdateOrderDeliveryTimeArgs = {
  input: UpdateDeliveryInput;
};


export type MutationAdminUpdateOrderMethodOverrideStatusArgs = {
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
};


export type MutationAdminUpdateOrderPaidAtArgs = {
  isPaid: Scalars['Boolean'];
  orderId: Scalars['ID'];
};


export type MutationAdminUpdateOrderPreparingUntilArgs = {
  input: UpdateOrderPreparingUntilInput;
};


export type MutationAdminUpdateOrderStatusArgs = {
  input: OrderStatusInput;
};


export type MutationAdminUpdatePasswordArgs = {
  input: PasswordUpdateInput;
};


export type MutationAdminUpdatePowerBiReportArgs = {
  data: PowerBiReportUpdateInput;
  where: PowerBiReportWhereUniqueInput;
};


export type MutationAdminUpdatePromoBannerArgs = {
  data: PromoBannerUpdateInput;
  where: PromoBannerWhereUniqueInput;
};


export type MutationAdminUpdateReportSortArgs = {
  input: Array<Scalars['String']>;
};


export type MutationAdminUpdateSortArgs = {
  input: Array<Scalars['String']>;
};


export type MutationAdminUpdateStationArgs = {
  data: StationUpdateInput;
  where: StationWhereUniqueInput;
};


export type MutationAdminUpdateUserArgs = {
  input: AdminUserUpdateInput;
};


export type MutationAdminUpdateUserAddressArgs = {
  input: AdminAddressUpdateInput;
};


export type MutationAdminUpsertKitchenArgs = {
  input: KitchenUpsertInput;
};


export type MutationAdminUpsertMenuScheduleArgs = {
  input: MenuScheduleUpsertInput;
};


export type MutationAdminUpsertModifierArgs = {
  input: ExtraUpsertInput;
};


export type MutationAdminUpsertOrderMethodOverrideArgs = {
  input: OrderMethodOverrideUpsertInput;
};


export type MutationAdminUpsertPromoArgs = {
  input: PromoUpsertInput;
};


export type MutationAdminUpsertSpecialMenuScheduleArgs = {
  input: SpecialMenuScheduleUpsertInput;
};


export type MutationAdminUserInviteArgs = {
  input: UserInviteInput;
};


export type MutationClientLogArgs = {
  message: Scalars['String'];
  meta?: Maybe<Scalars['String']>;
};


export type MutationCreateOrderBundlesArgs = {
  date?: Maybe<Scalars['DateTime']>;
};


export type MutationCustomerConfirmKioskOrderArgs = {
  input: ConfirmKioskOrderInput;
};


export type MutationCustomerConfirmOrderArgs = {
  input: ConfirmOrderInput;
};


export type MutationCustomerCreateCustomerLeadArgs = {
  data: CustomerLeadCreateInput;
};


export type MutationCustomerCreateUserArgs = {
  input: UserCreateInput;
};


export type MutationCustomerCreateUserAddressArgs = {
  input: AddressCreateInput;
};


export type MutationCustomerDeleteAddressArgs = {
  id: Scalars['ID'];
};


export type MutationCustomerDeleteCartItemArgs = {
  input: DeleteCartItemInput;
};


export type MutationCustomerDeleteCartItemsArgs = {
  input: DeleteCartItemsInput;
};


export type MutationCustomerKlaviyoReceiptArgs = {
  checked?: Maybe<Scalars['Boolean']>;
  email: Scalars['String'];
  orderId: Scalars['ID'];
};


export type MutationCustomerRegisterDeviceArgs = {
  input: DeviceRegisterInput;
};


export type MutationCustomerResetPasswordArgs = {
  email: Scalars['String'];
};


export type MutationCustomerResetPromosOrGiftCardCodesOnCartArgs = {
  orderDate?: Maybe<Scalars['DateTime']>;
  resetType: PromoResetType;
};


export type MutationCustomerSaveTilledPaymentMethodArgs = {
  nickName?: Maybe<Scalars['String']>;
  paymentMethodId: Scalars['String'];
};


export type MutationCustomerSetDefaultAddressArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type MutationCustomerSetupConsumerAppArgs = {
  input: SetupConsumerAppInput;
};


export type MutationCustomerUpdateMenuItemsOnCartArgs = {
  items?: Maybe<Array<Maybe<KitchenMenuItemCartInput>>>;
};


export type MutationCustomerUpdatePromosOnCartArgs = {
  orderDate?: Maybe<Scalars['DateTime']>;
  promoCodes: Array<Scalars['String']>;
};


export type MutationCustomerUpdateUserArgs = {
  input: UserUpdateInput;
};


export type MutationCustomerUpdateUserAddressArgs = {
  input: AddressUpdateInput;
};


export type MutationCustomerUpdateUtensilsOnCartArgs = {
  utensilsCount: Scalars['Int'];
};


export type MutationCustomerUpsertCartItemArgs = {
  input: UpsertCartItemInput;
};


export type MutationCustomerValidateKioskPromoArgs = {
  input: KioskPromoInput;
};


export type MutationCustomerValidateOrderArgs = {
  input: OrderInput;
};


export type MutationKdsCompleteKitchenTicketArgs = {
  id: Scalars['ID'];
};


export type MutationKdsKitchenTicketPrintedArgs = {
  id: Scalars['ID'];
};


export type MutationKdsKitchenTicketStartPreparingArgs = {
  ticketId: Scalars['ID'];
};


export type MutationKdsOrderPickupCompleteArgs = {
  orderId: Scalars['ID'];
};


export type MutationKdsRefireKitchenTicketArgs = {
  input: RefireKitchenTicketInput;
};


export type MutationKdsRefireOrderArgs = {
  input: RefireOrderInput;
};


export type MutationKdsRefireOrderItemArgs = {
  input: RefireOrderItemInput;
};


export type MutationKdsSetOrderWorkplaceArgs = {
  index: Scalars['Int'];
  orderId: Scalars['ID'];
  type: WorkplaceType;
};


export type MutationKdsSetPickupStationArgs = {
  orderId: Scalars['ID'];
  stationIndex: Scalars['Int'];
};


export type MutationKdsUndoOrderArgs = {
  input: UndoOrderInput;
};


export type MutationKdsUndoTicketArgs = {
  input: UndoTicketInput;
};


export type MutationOrderRefundCreateArgs = {
  request: RefundInput;
};


export type MutationSetupConsumerAppArgs = {
  input: SetupConsumerAppInput;
};


export type MutationUpdateCartTipArgs = {
  tip?: Maybe<Scalars['Float']>;
  tipPercent?: Maybe<Scalars['Float']>;
};


export type MutationUserOperationsUpdateArgs = {
  input: UserOperationsUpdateInput;
};


export type MutationUserProfileUpdateArgs = {
  input: UserProfileUpdateInput;
};

export type NestedBoolFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolFilter>;
};

export type NestedBoolNullableFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolNullableFilter>;
};

export type NestedDateTimeFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeFilter>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
};

export type NestedDateTimeNullableFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeNullableFilter>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
};

export type NestedEnumBraintreePaymentMethodFilter = {
  equals?: Maybe<BraintreePaymentMethod>;
  in?: Maybe<Array<BraintreePaymentMethod>>;
  not?: Maybe<NestedEnumBraintreePaymentMethodFilter>;
  notIn?: Maybe<Array<BraintreePaymentMethod>>;
};

export type NestedEnumBraintreePaymentSuccessStatusFilter = {
  equals?: Maybe<BraintreePaymentSuccessStatus>;
  in?: Maybe<Array<BraintreePaymentSuccessStatus>>;
  not?: Maybe<NestedEnumBraintreePaymentSuccessStatusFilter>;
  notIn?: Maybe<Array<BraintreePaymentSuccessStatus>>;
};

export type NestedEnumBusinessTypeNullableFilter = {
  equals?: Maybe<BusinessType>;
  in?: Maybe<Array<BusinessType>>;
  not?: Maybe<NestedEnumBusinessTypeNullableFilter>;
  notIn?: Maybe<Array<BusinessType>>;
};

export type NestedEnumCustomerRatingTypeFilter = {
  equals?: Maybe<CustomerRatingType>;
  in?: Maybe<Array<CustomerRatingType>>;
  not?: Maybe<NestedEnumCustomerRatingTypeFilter>;
  notIn?: Maybe<Array<CustomerRatingType>>;
};

export type NestedEnumDeliveryOptionNullableFilter = {
  equals?: Maybe<DeliveryOption>;
  in?: Maybe<Array<DeliveryOption>>;
  not?: Maybe<NestedEnumDeliveryOptionNullableFilter>;
  notIn?: Maybe<Array<DeliveryOption>>;
};

export type NestedEnumDeviceStatusFilter = {
  equals?: Maybe<DeviceStatus>;
  in?: Maybe<Array<DeviceStatus>>;
  not?: Maybe<NestedEnumDeviceStatusFilter>;
  notIn?: Maybe<Array<DeviceStatus>>;
};

export type NestedEnumFeeTypeFilter = {
  equals?: Maybe<FeeType>;
  in?: Maybe<Array<FeeType>>;
  not?: Maybe<NestedEnumFeeTypeFilter>;
  notIn?: Maybe<Array<FeeType>>;
};

export type NestedEnumFeeTypeNullableFilter = {
  equals?: Maybe<FeeType>;
  in?: Maybe<Array<FeeType>>;
  not?: Maybe<NestedEnumFeeTypeNullableFilter>;
  notIn?: Maybe<Array<FeeType>>;
};

export type NestedEnumInventoryStatusFilter = {
  equals?: Maybe<InventoryStatus>;
  in?: Maybe<Array<InventoryStatus>>;
  not?: Maybe<NestedEnumInventoryStatusFilter>;
  notIn?: Maybe<Array<InventoryStatus>>;
};

export type NestedEnumKdsTypeFilter = {
  equals?: Maybe<KdsType>;
  in?: Maybe<Array<KdsType>>;
  not?: Maybe<NestedEnumKdsTypeFilter>;
  notIn?: Maybe<Array<KdsType>>;
};

export type NestedEnumKitchenMenuItemStatusFilter = {
  equals?: Maybe<KitchenMenuItemStatus>;
  in?: Maybe<Array<KitchenMenuItemStatus>>;
  not?: Maybe<NestedEnumKitchenMenuItemStatusFilter>;
  notIn?: Maybe<Array<KitchenMenuItemStatus>>;
};

export type NestedEnumKitchenStatusFilter = {
  equals?: Maybe<KitchenStatus>;
  in?: Maybe<Array<KitchenStatus>>;
  not?: Maybe<NestedEnumKitchenStatusFilter>;
  notIn?: Maybe<Array<KitchenStatus>>;
};

export type NestedEnumKitchenTicketDelayReasonNullableFilter = {
  equals?: Maybe<KitchenTicketDelayReason>;
  in?: Maybe<Array<KitchenTicketDelayReason>>;
  not?: Maybe<NestedEnumKitchenTicketDelayReasonNullableFilter>;
  notIn?: Maybe<Array<KitchenTicketDelayReason>>;
};

export type NestedEnumKitchenTicketStatusFilter = {
  equals?: Maybe<KitchenTicketStatus>;
  in?: Maybe<Array<KitchenTicketStatus>>;
  not?: Maybe<NestedEnumKitchenTicketStatusFilter>;
  notIn?: Maybe<Array<KitchenTicketStatus>>;
};

export type NestedEnumKitchenTypeFilter = {
  equals?: Maybe<KitchenType>;
  in?: Maybe<Array<KitchenType>>;
  not?: Maybe<NestedEnumKitchenTypeFilter>;
  notIn?: Maybe<Array<KitchenType>>;
};

export type NestedEnumMenuCategoryTypeFilter = {
  equals?: Maybe<MenuCategoryType>;
  in?: Maybe<Array<MenuCategoryType>>;
  not?: Maybe<NestedEnumMenuCategoryTypeFilter>;
  notIn?: Maybe<Array<MenuCategoryType>>;
};

export type NestedEnumMenuItemTypeFilter = {
  equals?: Maybe<MenuItemType>;
  in?: Maybe<Array<MenuItemType>>;
  not?: Maybe<NestedEnumMenuItemTypeFilter>;
  notIn?: Maybe<Array<MenuItemType>>;
};

export type NestedEnumMenuScheduleTypeFilter = {
  equals?: Maybe<MenuScheduleType>;
  in?: Maybe<Array<MenuScheduleType>>;
  not?: Maybe<NestedEnumMenuScheduleTypeFilter>;
  notIn?: Maybe<Array<MenuScheduleType>>;
};

export type NestedEnumOrderBundleStatusFilter = {
  equals?: Maybe<OrderBundleStatus>;
  in?: Maybe<Array<OrderBundleStatus>>;
  not?: Maybe<NestedEnumOrderBundleStatusFilter>;
  notIn?: Maybe<Array<OrderBundleStatus>>;
};

export type NestedEnumOrderEventTypeFilter = {
  equals?: Maybe<OrderEventType>;
  in?: Maybe<Array<OrderEventType>>;
  not?: Maybe<NestedEnumOrderEventTypeFilter>;
  notIn?: Maybe<Array<OrderEventType>>;
};

export type NestedEnumOrderItemTypeFilter = {
  equals?: Maybe<OrderItemType>;
  in?: Maybe<Array<OrderItemType>>;
  not?: Maybe<NestedEnumOrderItemTypeFilter>;
  notIn?: Maybe<Array<OrderItemType>>;
};

export type NestedEnumOrderMethodFilter = {
  equals?: Maybe<OrderMethod>;
  in?: Maybe<Array<OrderMethod>>;
  not?: Maybe<NestedEnumOrderMethodFilter>;
  notIn?: Maybe<Array<OrderMethod>>;
};

export type NestedEnumOrderStatusFilter = {
  equals?: Maybe<OrderStatus>;
  in?: Maybe<Array<OrderStatus>>;
  not?: Maybe<NestedEnumOrderStatusFilter>;
  notIn?: Maybe<Array<OrderStatus>>;
};

export type NestedEnumPaymentProcessorFilter = {
  equals?: Maybe<PaymentProcessor>;
  in?: Maybe<Array<PaymentProcessor>>;
  not?: Maybe<NestedEnumPaymentProcessorFilter>;
  notIn?: Maybe<Array<PaymentProcessor>>;
};

export type NestedEnumPaymentStatusFilter = {
  equals?: Maybe<PaymentStatus>;
  in?: Maybe<Array<PaymentStatus>>;
  not?: Maybe<NestedEnumPaymentStatusFilter>;
  notIn?: Maybe<Array<PaymentStatus>>;
};

export type NestedEnumPaymentTypeFilter = {
  equals?: Maybe<PaymentType>;
  in?: Maybe<Array<PaymentType>>;
  not?: Maybe<NestedEnumPaymentTypeFilter>;
  notIn?: Maybe<Array<PaymentType>>;
};

export type NestedEnumPlatformFilter = {
  equals?: Maybe<Platform>;
  in?: Maybe<Array<Platform>>;
  not?: Maybe<NestedEnumPlatformFilter>;
  notIn?: Maybe<Array<Platform>>;
};

export type NestedEnumPromoAppliesToFilter = {
  equals?: Maybe<PromoAppliesTo>;
  in?: Maybe<Array<PromoAppliesTo>>;
  not?: Maybe<NestedEnumPromoAppliesToFilter>;
  notIn?: Maybe<Array<PromoAppliesTo>>;
};

export type NestedEnumPromoEligibilityFilter = {
  equals?: Maybe<PromoEligibility>;
  in?: Maybe<Array<PromoEligibility>>;
  not?: Maybe<NestedEnumPromoEligibilityFilter>;
  notIn?: Maybe<Array<PromoEligibility>>;
};

export type NestedEnumPromoRequirementFilter = {
  equals?: Maybe<PromoRequirement>;
  in?: Maybe<Array<PromoRequirement>>;
  not?: Maybe<NestedEnumPromoRequirementFilter>;
  notIn?: Maybe<Array<PromoRequirement>>;
};

export type NestedEnumPromoStatusFilter = {
  equals?: Maybe<PromoStatus>;
  in?: Maybe<Array<PromoStatus>>;
  not?: Maybe<NestedEnumPromoStatusFilter>;
  notIn?: Maybe<Array<PromoStatus>>;
};

export type NestedEnumPromoTypeFilter = {
  equals?: Maybe<PromoType>;
  in?: Maybe<Array<PromoType>>;
  not?: Maybe<NestedEnumPromoTypeFilter>;
  notIn?: Maybe<Array<PromoType>>;
};

export type NestedEnumPromoUsageLimitsFilter = {
  equals?: Maybe<PromoUsageLimits>;
  in?: Maybe<Array<PromoUsageLimits>>;
  not?: Maybe<NestedEnumPromoUsageLimitsFilter>;
  notIn?: Maybe<Array<PromoUsageLimits>>;
};

export type NestedEnumRefireReasonNullableFilter = {
  equals?: Maybe<RefireReason>;
  in?: Maybe<Array<RefireReason>>;
  not?: Maybe<NestedEnumRefireReasonNullableFilter>;
  notIn?: Maybe<Array<RefireReason>>;
};

export type NestedEnumRefundReasonFilter = {
  equals?: Maybe<RefundReason>;
  in?: Maybe<Array<RefundReason>>;
  not?: Maybe<NestedEnumRefundReasonFilter>;
  notIn?: Maybe<Array<RefundReason>>;
};

export type NestedEnumRefundTypeFilter = {
  equals?: Maybe<RefundType>;
  in?: Maybe<Array<RefundType>>;
  not?: Maybe<NestedEnumRefundTypeFilter>;
  notIn?: Maybe<Array<RefundType>>;
};

export type NestedEnumTilledCancellationReasonNullableFilter = {
  equals?: Maybe<TilledCancellationReason>;
  in?: Maybe<Array<TilledCancellationReason>>;
  not?: Maybe<NestedEnumTilledCancellationReasonNullableFilter>;
  notIn?: Maybe<Array<TilledCancellationReason>>;
};

export type NestedEnumTilledCurrencyFilter = {
  equals?: Maybe<TilledCurrency>;
  in?: Maybe<Array<TilledCurrency>>;
  not?: Maybe<NestedEnumTilledCurrencyFilter>;
  notIn?: Maybe<Array<TilledCurrency>>;
};

export type NestedEnumTilledPaymentErrorTypeNullableFilter = {
  equals?: Maybe<TilledPaymentErrorType>;
  in?: Maybe<Array<TilledPaymentErrorType>>;
  not?: Maybe<NestedEnumTilledPaymentErrorTypeNullableFilter>;
  notIn?: Maybe<Array<TilledPaymentErrorType>>;
};

export type NestedEnumTilledPaymentMethodTypeFilter = {
  equals?: Maybe<TilledPaymentMethodType>;
  in?: Maybe<Array<TilledPaymentMethodType>>;
  not?: Maybe<NestedEnumTilledPaymentMethodTypeFilter>;
  notIn?: Maybe<Array<TilledPaymentMethodType>>;
};

export type NestedEnumTilledPaymentStatusFilter = {
  equals?: Maybe<TilledPaymentStatus>;
  in?: Maybe<Array<TilledPaymentStatus>>;
  not?: Maybe<NestedEnumTilledPaymentStatusFilter>;
  notIn?: Maybe<Array<TilledPaymentStatus>>;
};

export type NestedEnumWorkplaceTypeFilter = {
  equals?: Maybe<WorkplaceType>;
  in?: Maybe<Array<WorkplaceType>>;
  not?: Maybe<NestedEnumWorkplaceTypeFilter>;
  notIn?: Maybe<Array<WorkplaceType>>;
};

export type NestedFloatFilter = {
  equals?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatFilter>;
  notIn?: Maybe<Array<Scalars['Float']>>;
};

export type NestedFloatNullableFilter = {
  equals?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatNullableFilter>;
  notIn?: Maybe<Array<Scalars['Float']>>;
};

export type NestedIntFilter = {
  equals?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntFilter>;
  notIn?: Maybe<Array<Scalars['Int']>>;
};

export type NestedIntNullableFilter = {
  equals?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntNullableFilter>;
  notIn?: Maybe<Array<Scalars['Int']>>;
};

export type NestedStringFilter = {
  contains?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  equals?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  not?: Maybe<NestedStringFilter>;
  notIn?: Maybe<Array<Scalars['String']>>;
  startsWith?: Maybe<Scalars['String']>;
};

export type NestedStringNullableFilter = {
  contains?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  equals?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  not?: Maybe<NestedStringNullableFilter>;
  notIn?: Maybe<Array<Scalars['String']>>;
  startsWith?: Maybe<Scalars['String']>;
};

export type NullableBoolFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['Boolean']>;
};

export type NullableDateTimeFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['DateTime']>;
};

export type NullableEnumBusinessTypeFieldUpdateOperationsInput = {
  set?: Maybe<BusinessType>;
};

export type NullableEnumDeliveryOptionFieldUpdateOperationsInput = {
  set?: Maybe<DeliveryOption>;
};

export type NullableEnumFeeTypeFieldUpdateOperationsInput = {
  set?: Maybe<FeeType>;
};

export type NullableEnumKitchenTicketDelayReasonFieldUpdateOperationsInput = {
  set?: Maybe<KitchenTicketDelayReason>;
};

export type NullableEnumRefireReasonFieldUpdateOperationsInput = {
  set?: Maybe<RefireReason>;
};

export type NullableEnumTilledCancellationReasonFieldUpdateOperationsInput = {
  set?: Maybe<TilledCancellationReason>;
};

export type NullableEnumTilledPaymentErrorTypeFieldUpdateOperationsInput = {
  set?: Maybe<TilledPaymentErrorType>;
};

export type NullableFloatFieldUpdateOperationsInput = {
  decrement?: Maybe<Scalars['Float']>;
  divide?: Maybe<Scalars['Float']>;
  increment?: Maybe<Scalars['Float']>;
  multiply?: Maybe<Scalars['Float']>;
  set?: Maybe<Scalars['Float']>;
};

export type NullableIntFieldUpdateOperationsInput = {
  decrement?: Maybe<Scalars['Int']>;
  divide?: Maybe<Scalars['Int']>;
  increment?: Maybe<Scalars['Int']>;
  multiply?: Maybe<Scalars['Int']>;
  set?: Maybe<Scalars['Int']>;
};

export type NullableStringFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['String']>;
};

export type OperationHoursInput = {
  /** 0 for Sunday..6 for Saturday */
  day: Scalars['Int'];
  endHour: Scalars['Int'];
  endMinute: Scalars['Int'];
  isClosed: Scalars['Boolean'];
  isNonStop?: Maybe<Scalars['Boolean']>;
  startHour: Scalars['Int'];
  startMinute: Scalars['Int'];
};

export type OperationHoursUpdateInput = {
  id: Scalars['ID'];
  operationHours: Array<OperationHoursInput>;
};

export type Order = {
  __typename?: 'Order';
  addressNote?: Maybe<Scalars['String']>;
  braintreeTransactions: Array<BraintreeTransaction>;
  bundle?: Maybe<OrderBundle>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  customer: UserCustomerInfo;
  deliverToEmail?: Maybe<Scalars['String']>;
  deliverToName?: Maybe<Scalars['String']>;
  deliverToPhone?: Maybe<Scalars['String']>;
  deliveredAt?: Maybe<Scalars['DateTime']>;
  delivery: Scalars['Float'];
  deliveryEstimation?: Maybe<Scalars['DateTime']>;
  deliveryOption?: Maybe<DeliveryOption>;
  deliveryTimeInSeconds?: Maybe<Scalars['Int']>;
  expeditedAt?: Maybe<Scalars['DateTime']>;
  facility: Facility;
  fee?: Maybe<Scalars['Float']>;
  formattedAddress?: Maybe<Scalars['String']>;
  fulfillmentAt: Scalars['DateTime'];
  fulfillmentBy: Scalars['DateTime'];
  giftCardCodes: Array<Scalars['String']>;
  giftCardDiscounts: Array<Scalars['Float']>;
  giftCardsDiscount: Scalars['Float'];
  id: Scalars['String'];
  isCatering: Scalars['Boolean'];
  isEmployee: Scalars['Boolean'];
  isOrderNow: Scalars['Boolean'];
  isVip: Scalars['Boolean'];
  items: Array<OrderItem>;
  kdsType: KdsType;
  kitchenPreparingUntil?: Maybe<Scalars['DateTime']>;
  kitchenTickets: Array<KitchenTicket>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  locality?: Maybe<Scalars['String']>;
  method: OrderMethod;
  note?: Maybe<Scalars['String']>;
  numberOfGuests?: Maybe<Scalars['Int']>;
  orderEmail?: Maybe<Scalars['String']>;
  orderEvents: Array<OrderEvent>;
  orderName?: Maybe<Scalars['String']>;
  orderPhoneNumber?: Maybe<Scalars['String']>;
  overwraps?: Maybe<Scalars['Int']>;
  packager?: Maybe<Workplace>;
  packedAt?: Maybe<Scalars['DateTime']>;
  payedAt?: Maybe<Scalars['DateTime']>;
  pickup?: Maybe<Workplace>;
  placeId?: Maybe<Scalars['String']>;
  platform: Platform;
  postalCode?: Maybe<Scalars['String']>;
  preparingUntil?: Maybe<Scalars['DateTime']>;
  promoCodes: Array<Scalars['String']>;
  promoDiscount: Scalars['Float'];
  promos: Array<Promo>;
  refireDelay: Scalars['Int'];
  refired?: Maybe<Scalars['Boolean']>;
  refundableItems: Array<OrderItem>;
  refunds: Array<OrderRefund>;
  retailItems: Array<OrderItem>;
  shortId: Scalars['String'];
  source?: Maybe<Scalars['String']>;
  status: OrderStatus;
  street?: Maybe<Scalars['String']>;
  stripePaymentId?: Maybe<Scalars['String']>;
  submittedAt: Scalars['DateTime'];
  subtotal: Scalars['Float'];
  tags: Array<Tag>;
  tax?: Maybe<Scalars['Float']>;
  taxFees: Scalars['Float'];
  tip: Scalars['Float'];
  total: Scalars['Float'];
  utensilsCount: Scalars['Int'];
};


export type OrderBraintreeTransactionsArgs = {
  after?: Maybe<BraintreeTransactionWhereUniqueInput>;
  before?: Maybe<BraintreeTransactionWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type OrderItemsArgs = {
  after?: Maybe<OrderItemWhereUniqueInput>;
  before?: Maybe<OrderItemWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type OrderKitchenTicketsArgs = {
  after?: Maybe<KitchenTicketWhereUniqueInput>;
  before?: Maybe<KitchenTicketWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type OrderOrderEventsArgs = {
  after?: Maybe<OrderEventWhereUniqueInput>;
  before?: Maybe<OrderEventWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type OrderPromosArgs = {
  after?: Maybe<PromoWhereUniqueInput>;
  before?: Maybe<PromoWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type OrderRefundableItemsArgs = {
  ticketId?: Maybe<Scalars['ID']>;
};


export type OrderTagsArgs = {
  after?: Maybe<TagWhereUniqueInput>;
  before?: Maybe<TagWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type OrderBundle = {
  __typename?: 'OrderBundle';
  createdAt: Scalars['DateTime'];
  deliveryStart: Scalars['DateTime'];
  id: Scalars['String'];
  isLocked: Scalars['Boolean'];
  orders: Array<Order>;
  shortId: Scalars['Int'];
  sortedOrderIds: Array<Scalars['String']>;
  status: OrderBundleStatus;
};


export type OrderBundleOrdersArgs = {
  after?: Maybe<OrderWhereUniqueInput>;
  before?: Maybe<OrderWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type OrderBundleCreateNestedOneWithoutOrdersInput = {
  connect?: Maybe<OrderBundleWhereUniqueInput>;
  connectOrCreate?: Maybe<OrderBundleCreateOrConnectWithoutOrdersInput>;
  create?: Maybe<OrderBundleCreateWithoutOrdersInput>;
};

export type OrderBundleCreateOrConnectWithoutOrdersInput = {
  create: OrderBundleCreateWithoutOrdersInput;
  where: OrderBundleWhereUniqueInput;
};

export type OrderBundleCreateWithoutOrdersInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  deliveryStart: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  isLocked?: Maybe<Scalars['Boolean']>;
  shortId?: Maybe<Scalars['Int']>;
  sortedOrderIds?: Maybe<OrderBundleCreatesortedOrderIdsInput>;
  status?: Maybe<OrderBundleStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrderBundleCreatesortedOrderIdsInput = {
  set?: Maybe<Array<Scalars['String']>>;
};

export enum OrderBundleStatus {
  Completed = 'COMPLETED',
  Delivery = 'DELIVERY',
  New = 'NEW',
  Ready = 'READY'
}

export type OrderBundleUpdateOneWithoutOrdersInput = {
  connect?: Maybe<OrderBundleWhereUniqueInput>;
  connectOrCreate?: Maybe<OrderBundleCreateOrConnectWithoutOrdersInput>;
  create?: Maybe<OrderBundleCreateWithoutOrdersInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<OrderBundleUpdateWithoutOrdersInput>;
  upsert?: Maybe<OrderBundleUpsertWithoutOrdersInput>;
};

export type OrderBundleUpdateWithoutOrdersInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deliveryStart?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isLocked?: Maybe<BoolFieldUpdateOperationsInput>;
  shortId?: Maybe<IntFieldUpdateOperationsInput>;
  sortedOrderIds?: Maybe<OrderBundleUpdatesortedOrderIdsInput>;
  status?: Maybe<EnumOrderBundleStatusFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrderBundleUpdatesortedOrderIdsInput = {
  push?: Maybe<Scalars['String']>;
  set?: Maybe<Array<Scalars['String']>>;
};

export type OrderBundleUpsertWithoutOrdersInput = {
  create: OrderBundleCreateWithoutOrdersInput;
  update: OrderBundleUpdateWithoutOrdersInput;
};

export type OrderBundleWhereInput = {
  AND?: Maybe<Array<OrderBundleWhereInput>>;
  NOT?: Maybe<Array<OrderBundleWhereInput>>;
  OR?: Maybe<Array<OrderBundleWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  deliveryStart?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  isLocked?: Maybe<BoolFilter>;
  orders?: Maybe<OrderListRelationFilter>;
  shortId?: Maybe<IntFilter>;
  sortedOrderIds?: Maybe<StringNullableListFilter>;
  status?: Maybe<EnumOrderBundleStatusFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type OrderBundleWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type OrderConfirmation = {
  __typename?: 'OrderConfirmation';
  order?: Maybe<Order>;
  payment?: Maybe<TilledPayment>;
  transaction?: Maybe<BraintreeTransaction>;
  validation: OrderValidation;
};

export type OrderCost = {
  __typename?: 'OrderCost';
  delivery: Scalars['Float'];
  fee: Scalars['Float'];
  giftCardsDiscount: Scalars['Float'];
  promoDiscount: Scalars['Float'];
  subtotal: Scalars['Float'];
  tax: Scalars['Float'];
  taxFees: Scalars['Float'];
  tip: Scalars['Float'];
  total: Scalars['Float'];
};

export type OrderCreateManyCustomerInput = {
  addressNote?: Maybe<Scalars['String']>;
  appliedPromos?: Maybe<OrderCreateManyappliedPromosInput>;
  bundleId?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  chowlyOrderId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deliverToEmail?: Maybe<Scalars['String']>;
  deliverToName?: Maybe<Scalars['String']>;
  deliverToPhone?: Maybe<Scalars['String']>;
  deliveredAt?: Maybe<Scalars['DateTime']>;
  delivery: Scalars['Float'];
  deliveryEstimation?: Maybe<Scalars['DateTime']>;
  deliveryFeeConst?: Maybe<Scalars['Float']>;
  deliveryFeeTaxRateConst?: Maybe<Scalars['Float']>;
  deliveryJobId?: Maybe<Scalars['String']>;
  deliveryOption?: Maybe<DeliveryOption>;
  deliveryTax?: Maybe<Scalars['Float']>;
  deliveryTimeInSeconds?: Maybe<Scalars['Int']>;
  expeditedAt?: Maybe<Scalars['DateTime']>;
  expoId?: Maybe<Scalars['String']>;
  facilityId: Scalars['String'];
  fee?: Maybe<Scalars['Float']>;
  formattedAddress?: Maybe<Scalars['String']>;
  fulfillmentAt: Scalars['DateTime'];
  fulfillmentBy: Scalars['DateTime'];
  giftCardCodes?: Maybe<OrderCreateManygiftCardCodesInput>;
  giftCardDiscounts?: Maybe<OrderCreateManygiftCardDiscountsInput>;
  giftCardsDiscount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  isEmployee?: Maybe<Scalars['Boolean']>;
  isOrderNow?: Maybe<Scalars['Boolean']>;
  isPaidByInvoice?: Maybe<Scalars['Boolean']>;
  isRefunded?: Maybe<Scalars['Boolean']>;
  isVip?: Maybe<Scalars['Boolean']>;
  itemTax?: Maybe<Scalars['Float']>;
  kdsType?: Maybe<KdsType>;
  kitchenPreparingUntil?: Maybe<Scalars['DateTime']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  locality?: Maybe<Scalars['String']>;
  method?: Maybe<OrderMethod>;
  note?: Maybe<Scalars['String']>;
  numberOfGuests?: Maybe<Scalars['Int']>;
  orderEmail?: Maybe<Scalars['String']>;
  orderName?: Maybe<Scalars['String']>;
  orderPhoneNumber?: Maybe<Scalars['String']>;
  overwraps?: Maybe<Scalars['Int']>;
  packagerId?: Maybe<Scalars['String']>;
  packedAt?: Maybe<Scalars['DateTime']>;
  payedAt?: Maybe<Scalars['DateTime']>;
  pickupId?: Maybe<Scalars['String']>;
  placeId?: Maybe<Scalars['String']>;
  platform?: Maybe<Platform>;
  postalCode?: Maybe<Scalars['String']>;
  preparingUntil?: Maybe<Scalars['DateTime']>;
  promoCodes?: Maybe<OrderCreateManypromoCodesInput>;
  promoDiscount: Scalars['Float'];
  promoIds?: Maybe<OrderCreateManypromoIdsInput>;
  refireDelay?: Maybe<Scalars['Int']>;
  refired?: Maybe<Scalars['Boolean']>;
  region?: Maybe<Scalars['String']>;
  runnerId?: Maybe<Scalars['String']>;
  sequentialNumber?: Maybe<Scalars['Int']>;
  serviceFeeConst?: Maybe<Scalars['Float']>;
  serviceFeeTax?: Maybe<Scalars['Float']>;
  serviceFeeTaxRateConst?: Maybe<Scalars['Float']>;
  serviceFeeType?: Maybe<FeeType>;
  shortId: Scalars['String'];
  source?: Maybe<Scalars['String']>;
  status: OrderStatus;
  street?: Maybe<Scalars['String']>;
  stripePaymentId?: Maybe<Scalars['String']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  subtotal: Scalars['Float'];
  tax?: Maybe<Scalars['Float']>;
  taxFees: Scalars['Float'];
  taxRateConst?: Maybe<Scalars['Float']>;
  tip: Scalars['Float'];
  tookanTaskId?: Maybe<Scalars['String']>;
  total: Scalars['Float'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  utensilsCount?: Maybe<Scalars['Int']>;
};

export type OrderCreateManyCustomerInputEnvelope = {
  data?: Maybe<Array<OrderCreateManyCustomerInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type OrderCreateManyExpoInput = {
  addressNote?: Maybe<Scalars['String']>;
  appliedPromos?: Maybe<OrderCreateManyappliedPromosInput>;
  bundleId?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  chowlyOrderId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customerId: Scalars['String'];
  deliverToEmail?: Maybe<Scalars['String']>;
  deliverToName?: Maybe<Scalars['String']>;
  deliverToPhone?: Maybe<Scalars['String']>;
  deliveredAt?: Maybe<Scalars['DateTime']>;
  delivery: Scalars['Float'];
  deliveryEstimation?: Maybe<Scalars['DateTime']>;
  deliveryFeeConst?: Maybe<Scalars['Float']>;
  deliveryFeeTaxRateConst?: Maybe<Scalars['Float']>;
  deliveryJobId?: Maybe<Scalars['String']>;
  deliveryOption?: Maybe<DeliveryOption>;
  deliveryTax?: Maybe<Scalars['Float']>;
  deliveryTimeInSeconds?: Maybe<Scalars['Int']>;
  expeditedAt?: Maybe<Scalars['DateTime']>;
  facilityId: Scalars['String'];
  fee?: Maybe<Scalars['Float']>;
  formattedAddress?: Maybe<Scalars['String']>;
  fulfillmentAt: Scalars['DateTime'];
  fulfillmentBy: Scalars['DateTime'];
  giftCardCodes?: Maybe<OrderCreateManygiftCardCodesInput>;
  giftCardDiscounts?: Maybe<OrderCreateManygiftCardDiscountsInput>;
  giftCardsDiscount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  isEmployee?: Maybe<Scalars['Boolean']>;
  isOrderNow?: Maybe<Scalars['Boolean']>;
  isPaidByInvoice?: Maybe<Scalars['Boolean']>;
  isRefunded?: Maybe<Scalars['Boolean']>;
  isVip?: Maybe<Scalars['Boolean']>;
  itemTax?: Maybe<Scalars['Float']>;
  kdsType?: Maybe<KdsType>;
  kitchenPreparingUntil?: Maybe<Scalars['DateTime']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  locality?: Maybe<Scalars['String']>;
  method?: Maybe<OrderMethod>;
  note?: Maybe<Scalars['String']>;
  numberOfGuests?: Maybe<Scalars['Int']>;
  orderEmail?: Maybe<Scalars['String']>;
  orderName?: Maybe<Scalars['String']>;
  orderPhoneNumber?: Maybe<Scalars['String']>;
  overwraps?: Maybe<Scalars['Int']>;
  packagerId?: Maybe<Scalars['String']>;
  packedAt?: Maybe<Scalars['DateTime']>;
  payedAt?: Maybe<Scalars['DateTime']>;
  pickupId?: Maybe<Scalars['String']>;
  placeId?: Maybe<Scalars['String']>;
  platform?: Maybe<Platform>;
  postalCode?: Maybe<Scalars['String']>;
  preparingUntil?: Maybe<Scalars['DateTime']>;
  promoCodes?: Maybe<OrderCreateManypromoCodesInput>;
  promoDiscount: Scalars['Float'];
  promoIds?: Maybe<OrderCreateManypromoIdsInput>;
  refireDelay?: Maybe<Scalars['Int']>;
  refired?: Maybe<Scalars['Boolean']>;
  region?: Maybe<Scalars['String']>;
  runnerId?: Maybe<Scalars['String']>;
  sequentialNumber?: Maybe<Scalars['Int']>;
  serviceFeeConst?: Maybe<Scalars['Float']>;
  serviceFeeTax?: Maybe<Scalars['Float']>;
  serviceFeeTaxRateConst?: Maybe<Scalars['Float']>;
  serviceFeeType?: Maybe<FeeType>;
  shortId: Scalars['String'];
  source?: Maybe<Scalars['String']>;
  status: OrderStatus;
  street?: Maybe<Scalars['String']>;
  stripePaymentId?: Maybe<Scalars['String']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  subtotal: Scalars['Float'];
  tax?: Maybe<Scalars['Float']>;
  taxFees: Scalars['Float'];
  taxRateConst?: Maybe<Scalars['Float']>;
  tip: Scalars['Float'];
  tookanTaskId?: Maybe<Scalars['String']>;
  total: Scalars['Float'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  utensilsCount?: Maybe<Scalars['Int']>;
};

export type OrderCreateManyExpoInputEnvelope = {
  data?: Maybe<Array<OrderCreateManyExpoInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type OrderCreateManyFacilityInput = {
  addressNote?: Maybe<Scalars['String']>;
  appliedPromos?: Maybe<OrderCreateManyappliedPromosInput>;
  bundleId?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  chowlyOrderId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customerId: Scalars['String'];
  deliverToEmail?: Maybe<Scalars['String']>;
  deliverToName?: Maybe<Scalars['String']>;
  deliverToPhone?: Maybe<Scalars['String']>;
  deliveredAt?: Maybe<Scalars['DateTime']>;
  delivery: Scalars['Float'];
  deliveryEstimation?: Maybe<Scalars['DateTime']>;
  deliveryFeeConst?: Maybe<Scalars['Float']>;
  deliveryFeeTaxRateConst?: Maybe<Scalars['Float']>;
  deliveryJobId?: Maybe<Scalars['String']>;
  deliveryOption?: Maybe<DeliveryOption>;
  deliveryTax?: Maybe<Scalars['Float']>;
  deliveryTimeInSeconds?: Maybe<Scalars['Int']>;
  expeditedAt?: Maybe<Scalars['DateTime']>;
  expoId?: Maybe<Scalars['String']>;
  fee?: Maybe<Scalars['Float']>;
  formattedAddress?: Maybe<Scalars['String']>;
  fulfillmentAt: Scalars['DateTime'];
  fulfillmentBy: Scalars['DateTime'];
  giftCardCodes?: Maybe<OrderCreateManygiftCardCodesInput>;
  giftCardDiscounts?: Maybe<OrderCreateManygiftCardDiscountsInput>;
  giftCardsDiscount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  isEmployee?: Maybe<Scalars['Boolean']>;
  isOrderNow?: Maybe<Scalars['Boolean']>;
  isPaidByInvoice?: Maybe<Scalars['Boolean']>;
  isRefunded?: Maybe<Scalars['Boolean']>;
  isVip?: Maybe<Scalars['Boolean']>;
  itemTax?: Maybe<Scalars['Float']>;
  kdsType?: Maybe<KdsType>;
  kitchenPreparingUntil?: Maybe<Scalars['DateTime']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  locality?: Maybe<Scalars['String']>;
  method?: Maybe<OrderMethod>;
  note?: Maybe<Scalars['String']>;
  numberOfGuests?: Maybe<Scalars['Int']>;
  orderEmail?: Maybe<Scalars['String']>;
  orderName?: Maybe<Scalars['String']>;
  orderPhoneNumber?: Maybe<Scalars['String']>;
  overwraps?: Maybe<Scalars['Int']>;
  packagerId?: Maybe<Scalars['String']>;
  packedAt?: Maybe<Scalars['DateTime']>;
  payedAt?: Maybe<Scalars['DateTime']>;
  pickupId?: Maybe<Scalars['String']>;
  placeId?: Maybe<Scalars['String']>;
  platform?: Maybe<Platform>;
  postalCode?: Maybe<Scalars['String']>;
  preparingUntil?: Maybe<Scalars['DateTime']>;
  promoCodes?: Maybe<OrderCreateManypromoCodesInput>;
  promoDiscount: Scalars['Float'];
  promoIds?: Maybe<OrderCreateManypromoIdsInput>;
  refireDelay?: Maybe<Scalars['Int']>;
  refired?: Maybe<Scalars['Boolean']>;
  region?: Maybe<Scalars['String']>;
  runnerId?: Maybe<Scalars['String']>;
  sequentialNumber?: Maybe<Scalars['Int']>;
  serviceFeeConst?: Maybe<Scalars['Float']>;
  serviceFeeTax?: Maybe<Scalars['Float']>;
  serviceFeeTaxRateConst?: Maybe<Scalars['Float']>;
  serviceFeeType?: Maybe<FeeType>;
  shortId: Scalars['String'];
  source?: Maybe<Scalars['String']>;
  status: OrderStatus;
  street?: Maybe<Scalars['String']>;
  stripePaymentId?: Maybe<Scalars['String']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  subtotal: Scalars['Float'];
  tax?: Maybe<Scalars['Float']>;
  taxFees: Scalars['Float'];
  taxRateConst?: Maybe<Scalars['Float']>;
  tip: Scalars['Float'];
  tookanTaskId?: Maybe<Scalars['String']>;
  total: Scalars['Float'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  utensilsCount?: Maybe<Scalars['Int']>;
};

export type OrderCreateManyFacilityInputEnvelope = {
  data?: Maybe<Array<OrderCreateManyFacilityInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type OrderCreateManyPackagerInput = {
  addressNote?: Maybe<Scalars['String']>;
  appliedPromos?: Maybe<OrderCreateManyappliedPromosInput>;
  bundleId?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  chowlyOrderId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customerId: Scalars['String'];
  deliverToEmail?: Maybe<Scalars['String']>;
  deliverToName?: Maybe<Scalars['String']>;
  deliverToPhone?: Maybe<Scalars['String']>;
  deliveredAt?: Maybe<Scalars['DateTime']>;
  delivery: Scalars['Float'];
  deliveryEstimation?: Maybe<Scalars['DateTime']>;
  deliveryFeeConst?: Maybe<Scalars['Float']>;
  deliveryFeeTaxRateConst?: Maybe<Scalars['Float']>;
  deliveryJobId?: Maybe<Scalars['String']>;
  deliveryOption?: Maybe<DeliveryOption>;
  deliveryTax?: Maybe<Scalars['Float']>;
  deliveryTimeInSeconds?: Maybe<Scalars['Int']>;
  expeditedAt?: Maybe<Scalars['DateTime']>;
  expoId?: Maybe<Scalars['String']>;
  facilityId: Scalars['String'];
  fee?: Maybe<Scalars['Float']>;
  formattedAddress?: Maybe<Scalars['String']>;
  fulfillmentAt: Scalars['DateTime'];
  fulfillmentBy: Scalars['DateTime'];
  giftCardCodes?: Maybe<OrderCreateManygiftCardCodesInput>;
  giftCardDiscounts?: Maybe<OrderCreateManygiftCardDiscountsInput>;
  giftCardsDiscount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  isEmployee?: Maybe<Scalars['Boolean']>;
  isOrderNow?: Maybe<Scalars['Boolean']>;
  isPaidByInvoice?: Maybe<Scalars['Boolean']>;
  isRefunded?: Maybe<Scalars['Boolean']>;
  isVip?: Maybe<Scalars['Boolean']>;
  itemTax?: Maybe<Scalars['Float']>;
  kdsType?: Maybe<KdsType>;
  kitchenPreparingUntil?: Maybe<Scalars['DateTime']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  locality?: Maybe<Scalars['String']>;
  method?: Maybe<OrderMethod>;
  note?: Maybe<Scalars['String']>;
  numberOfGuests?: Maybe<Scalars['Int']>;
  orderEmail?: Maybe<Scalars['String']>;
  orderName?: Maybe<Scalars['String']>;
  orderPhoneNumber?: Maybe<Scalars['String']>;
  overwraps?: Maybe<Scalars['Int']>;
  packedAt?: Maybe<Scalars['DateTime']>;
  payedAt?: Maybe<Scalars['DateTime']>;
  pickupId?: Maybe<Scalars['String']>;
  placeId?: Maybe<Scalars['String']>;
  platform?: Maybe<Platform>;
  postalCode?: Maybe<Scalars['String']>;
  preparingUntil?: Maybe<Scalars['DateTime']>;
  promoCodes?: Maybe<OrderCreateManypromoCodesInput>;
  promoDiscount: Scalars['Float'];
  promoIds?: Maybe<OrderCreateManypromoIdsInput>;
  refireDelay?: Maybe<Scalars['Int']>;
  refired?: Maybe<Scalars['Boolean']>;
  region?: Maybe<Scalars['String']>;
  runnerId?: Maybe<Scalars['String']>;
  sequentialNumber?: Maybe<Scalars['Int']>;
  serviceFeeConst?: Maybe<Scalars['Float']>;
  serviceFeeTax?: Maybe<Scalars['Float']>;
  serviceFeeTaxRateConst?: Maybe<Scalars['Float']>;
  serviceFeeType?: Maybe<FeeType>;
  shortId: Scalars['String'];
  source?: Maybe<Scalars['String']>;
  status: OrderStatus;
  street?: Maybe<Scalars['String']>;
  stripePaymentId?: Maybe<Scalars['String']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  subtotal: Scalars['Float'];
  tax?: Maybe<Scalars['Float']>;
  taxFees: Scalars['Float'];
  taxRateConst?: Maybe<Scalars['Float']>;
  tip: Scalars['Float'];
  tookanTaskId?: Maybe<Scalars['String']>;
  total: Scalars['Float'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  utensilsCount?: Maybe<Scalars['Int']>;
};

export type OrderCreateManyPackagerInputEnvelope = {
  data?: Maybe<Array<OrderCreateManyPackagerInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type OrderCreateManyPickupInput = {
  addressNote?: Maybe<Scalars['String']>;
  appliedPromos?: Maybe<OrderCreateManyappliedPromosInput>;
  bundleId?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  chowlyOrderId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customerId: Scalars['String'];
  deliverToEmail?: Maybe<Scalars['String']>;
  deliverToName?: Maybe<Scalars['String']>;
  deliverToPhone?: Maybe<Scalars['String']>;
  deliveredAt?: Maybe<Scalars['DateTime']>;
  delivery: Scalars['Float'];
  deliveryEstimation?: Maybe<Scalars['DateTime']>;
  deliveryFeeConst?: Maybe<Scalars['Float']>;
  deliveryFeeTaxRateConst?: Maybe<Scalars['Float']>;
  deliveryJobId?: Maybe<Scalars['String']>;
  deliveryOption?: Maybe<DeliveryOption>;
  deliveryTax?: Maybe<Scalars['Float']>;
  deliveryTimeInSeconds?: Maybe<Scalars['Int']>;
  expeditedAt?: Maybe<Scalars['DateTime']>;
  expoId?: Maybe<Scalars['String']>;
  facilityId: Scalars['String'];
  fee?: Maybe<Scalars['Float']>;
  formattedAddress?: Maybe<Scalars['String']>;
  fulfillmentAt: Scalars['DateTime'];
  fulfillmentBy: Scalars['DateTime'];
  giftCardCodes?: Maybe<OrderCreateManygiftCardCodesInput>;
  giftCardDiscounts?: Maybe<OrderCreateManygiftCardDiscountsInput>;
  giftCardsDiscount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  isEmployee?: Maybe<Scalars['Boolean']>;
  isOrderNow?: Maybe<Scalars['Boolean']>;
  isPaidByInvoice?: Maybe<Scalars['Boolean']>;
  isRefunded?: Maybe<Scalars['Boolean']>;
  isVip?: Maybe<Scalars['Boolean']>;
  itemTax?: Maybe<Scalars['Float']>;
  kdsType?: Maybe<KdsType>;
  kitchenPreparingUntil?: Maybe<Scalars['DateTime']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  locality?: Maybe<Scalars['String']>;
  method?: Maybe<OrderMethod>;
  note?: Maybe<Scalars['String']>;
  numberOfGuests?: Maybe<Scalars['Int']>;
  orderEmail?: Maybe<Scalars['String']>;
  orderName?: Maybe<Scalars['String']>;
  orderPhoneNumber?: Maybe<Scalars['String']>;
  overwraps?: Maybe<Scalars['Int']>;
  packagerId?: Maybe<Scalars['String']>;
  packedAt?: Maybe<Scalars['DateTime']>;
  payedAt?: Maybe<Scalars['DateTime']>;
  placeId?: Maybe<Scalars['String']>;
  platform?: Maybe<Platform>;
  postalCode?: Maybe<Scalars['String']>;
  preparingUntil?: Maybe<Scalars['DateTime']>;
  promoCodes?: Maybe<OrderCreateManypromoCodesInput>;
  promoDiscount: Scalars['Float'];
  promoIds?: Maybe<OrderCreateManypromoIdsInput>;
  refireDelay?: Maybe<Scalars['Int']>;
  refired?: Maybe<Scalars['Boolean']>;
  region?: Maybe<Scalars['String']>;
  runnerId?: Maybe<Scalars['String']>;
  sequentialNumber?: Maybe<Scalars['Int']>;
  serviceFeeConst?: Maybe<Scalars['Float']>;
  serviceFeeTax?: Maybe<Scalars['Float']>;
  serviceFeeTaxRateConst?: Maybe<Scalars['Float']>;
  serviceFeeType?: Maybe<FeeType>;
  shortId: Scalars['String'];
  source?: Maybe<Scalars['String']>;
  status: OrderStatus;
  street?: Maybe<Scalars['String']>;
  stripePaymentId?: Maybe<Scalars['String']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  subtotal: Scalars['Float'];
  tax?: Maybe<Scalars['Float']>;
  taxFees: Scalars['Float'];
  taxRateConst?: Maybe<Scalars['Float']>;
  tip: Scalars['Float'];
  tookanTaskId?: Maybe<Scalars['String']>;
  total: Scalars['Float'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  utensilsCount?: Maybe<Scalars['Int']>;
};

export type OrderCreateManyPickupInputEnvelope = {
  data?: Maybe<Array<OrderCreateManyPickupInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type OrderCreateManyRunnerInput = {
  addressNote?: Maybe<Scalars['String']>;
  appliedPromos?: Maybe<OrderCreateManyappliedPromosInput>;
  bundleId?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  chowlyOrderId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customerId: Scalars['String'];
  deliverToEmail?: Maybe<Scalars['String']>;
  deliverToName?: Maybe<Scalars['String']>;
  deliverToPhone?: Maybe<Scalars['String']>;
  deliveredAt?: Maybe<Scalars['DateTime']>;
  delivery: Scalars['Float'];
  deliveryEstimation?: Maybe<Scalars['DateTime']>;
  deliveryFeeConst?: Maybe<Scalars['Float']>;
  deliveryFeeTaxRateConst?: Maybe<Scalars['Float']>;
  deliveryJobId?: Maybe<Scalars['String']>;
  deliveryOption?: Maybe<DeliveryOption>;
  deliveryTax?: Maybe<Scalars['Float']>;
  deliveryTimeInSeconds?: Maybe<Scalars['Int']>;
  expeditedAt?: Maybe<Scalars['DateTime']>;
  expoId?: Maybe<Scalars['String']>;
  facilityId: Scalars['String'];
  fee?: Maybe<Scalars['Float']>;
  formattedAddress?: Maybe<Scalars['String']>;
  fulfillmentAt: Scalars['DateTime'];
  fulfillmentBy: Scalars['DateTime'];
  giftCardCodes?: Maybe<OrderCreateManygiftCardCodesInput>;
  giftCardDiscounts?: Maybe<OrderCreateManygiftCardDiscountsInput>;
  giftCardsDiscount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  isEmployee?: Maybe<Scalars['Boolean']>;
  isOrderNow?: Maybe<Scalars['Boolean']>;
  isPaidByInvoice?: Maybe<Scalars['Boolean']>;
  isRefunded?: Maybe<Scalars['Boolean']>;
  isVip?: Maybe<Scalars['Boolean']>;
  itemTax?: Maybe<Scalars['Float']>;
  kdsType?: Maybe<KdsType>;
  kitchenPreparingUntil?: Maybe<Scalars['DateTime']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  locality?: Maybe<Scalars['String']>;
  method?: Maybe<OrderMethod>;
  note?: Maybe<Scalars['String']>;
  numberOfGuests?: Maybe<Scalars['Int']>;
  orderEmail?: Maybe<Scalars['String']>;
  orderName?: Maybe<Scalars['String']>;
  orderPhoneNumber?: Maybe<Scalars['String']>;
  overwraps?: Maybe<Scalars['Int']>;
  packagerId?: Maybe<Scalars['String']>;
  packedAt?: Maybe<Scalars['DateTime']>;
  payedAt?: Maybe<Scalars['DateTime']>;
  pickupId?: Maybe<Scalars['String']>;
  placeId?: Maybe<Scalars['String']>;
  platform?: Maybe<Platform>;
  postalCode?: Maybe<Scalars['String']>;
  preparingUntil?: Maybe<Scalars['DateTime']>;
  promoCodes?: Maybe<OrderCreateManypromoCodesInput>;
  promoDiscount: Scalars['Float'];
  promoIds?: Maybe<OrderCreateManypromoIdsInput>;
  refireDelay?: Maybe<Scalars['Int']>;
  refired?: Maybe<Scalars['Boolean']>;
  region?: Maybe<Scalars['String']>;
  sequentialNumber?: Maybe<Scalars['Int']>;
  serviceFeeConst?: Maybe<Scalars['Float']>;
  serviceFeeTax?: Maybe<Scalars['Float']>;
  serviceFeeTaxRateConst?: Maybe<Scalars['Float']>;
  serviceFeeType?: Maybe<FeeType>;
  shortId: Scalars['String'];
  source?: Maybe<Scalars['String']>;
  status: OrderStatus;
  street?: Maybe<Scalars['String']>;
  stripePaymentId?: Maybe<Scalars['String']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  subtotal: Scalars['Float'];
  tax?: Maybe<Scalars['Float']>;
  taxFees: Scalars['Float'];
  taxRateConst?: Maybe<Scalars['Float']>;
  tip: Scalars['Float'];
  tookanTaskId?: Maybe<Scalars['String']>;
  total: Scalars['Float'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  utensilsCount?: Maybe<Scalars['Int']>;
};

export type OrderCreateManyRunnerInputEnvelope = {
  data?: Maybe<Array<OrderCreateManyRunnerInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type OrderCreateManyappliedPromosInput = {
  set?: Maybe<Array<Scalars['Json']>>;
};

export type OrderCreateManygiftCardCodesInput = {
  set?: Maybe<Array<Scalars['String']>>;
};

export type OrderCreateManygiftCardDiscountsInput = {
  set?: Maybe<Array<Scalars['Float']>>;
};

export type OrderCreateManypromoCodesInput = {
  set?: Maybe<Array<Scalars['String']>>;
};

export type OrderCreateManypromoIdsInput = {
  set?: Maybe<Array<Scalars['String']>>;
};

export type OrderCreateNestedManyWithoutCustomerInput = {
  connect?: Maybe<Array<OrderWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrderCreateOrConnectWithoutCustomerInput>>;
  create?: Maybe<Array<OrderCreateWithoutCustomerInput>>;
  createMany?: Maybe<OrderCreateManyCustomerInputEnvelope>;
};

export type OrderCreateNestedManyWithoutExpoInput = {
  connect?: Maybe<Array<OrderWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrderCreateOrConnectWithoutExpoInput>>;
  create?: Maybe<Array<OrderCreateWithoutExpoInput>>;
  createMany?: Maybe<OrderCreateManyExpoInputEnvelope>;
};

export type OrderCreateNestedManyWithoutFacilityInput = {
  connect?: Maybe<Array<OrderWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrderCreateOrConnectWithoutFacilityInput>>;
  create?: Maybe<Array<OrderCreateWithoutFacilityInput>>;
  createMany?: Maybe<OrderCreateManyFacilityInputEnvelope>;
};

export type OrderCreateNestedManyWithoutPackagerInput = {
  connect?: Maybe<Array<OrderWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrderCreateOrConnectWithoutPackagerInput>>;
  create?: Maybe<Array<OrderCreateWithoutPackagerInput>>;
  createMany?: Maybe<OrderCreateManyPackagerInputEnvelope>;
};

export type OrderCreateNestedManyWithoutPickupInput = {
  connect?: Maybe<Array<OrderWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrderCreateOrConnectWithoutPickupInput>>;
  create?: Maybe<Array<OrderCreateWithoutPickupInput>>;
  createMany?: Maybe<OrderCreateManyPickupInputEnvelope>;
};

export type OrderCreateNestedManyWithoutPromosInput = {
  connect?: Maybe<Array<OrderWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrderCreateOrConnectWithoutPromosInput>>;
  create?: Maybe<Array<OrderCreateWithoutPromosInput>>;
};

export type OrderCreateNestedManyWithoutRunnerInput = {
  connect?: Maybe<Array<OrderWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrderCreateOrConnectWithoutRunnerInput>>;
  create?: Maybe<Array<OrderCreateWithoutRunnerInput>>;
  createMany?: Maybe<OrderCreateManyRunnerInputEnvelope>;
};

export type OrderCreateNestedManyWithoutTagsInput = {
  connect?: Maybe<Array<OrderWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrderCreateOrConnectWithoutTagsInput>>;
  create?: Maybe<Array<OrderCreateWithoutTagsInput>>;
};

export type OrderCreateNestedOneWithoutItemsInput = {
  connect?: Maybe<OrderWhereUniqueInput>;
  connectOrCreate?: Maybe<OrderCreateOrConnectWithoutItemsInput>;
  create?: Maybe<OrderCreateWithoutItemsInput>;
};

export type OrderCreateNestedOneWithoutKitchenTicketsInput = {
  connect?: Maybe<OrderWhereUniqueInput>;
  connectOrCreate?: Maybe<OrderCreateOrConnectWithoutKitchenTicketsInput>;
  create?: Maybe<OrderCreateWithoutKitchenTicketsInput>;
};

export type OrderCreateNestedOneWithoutPaymentInput = {
  connect?: Maybe<OrderWhereUniqueInput>;
  connectOrCreate?: Maybe<OrderCreateOrConnectWithoutPaymentInput>;
  create?: Maybe<OrderCreateWithoutPaymentInput>;
};

export type OrderCreateNestedOneWithoutRefundInput = {
  connect?: Maybe<OrderWhereUniqueInput>;
  connectOrCreate?: Maybe<OrderCreateOrConnectWithoutRefundInput>;
  create?: Maybe<OrderCreateWithoutRefundInput>;
};

export type OrderCreateNestedOneWithoutTilledPaymentsInput = {
  connect?: Maybe<OrderWhereUniqueInput>;
  connectOrCreate?: Maybe<OrderCreateOrConnectWithoutTilledPaymentsInput>;
  create?: Maybe<OrderCreateWithoutTilledPaymentsInput>;
};

export type OrderCreateOrConnectWithoutCustomerInput = {
  create: OrderCreateWithoutCustomerInput;
  where: OrderWhereUniqueInput;
};

export type OrderCreateOrConnectWithoutExpoInput = {
  create: OrderCreateWithoutExpoInput;
  where: OrderWhereUniqueInput;
};

export type OrderCreateOrConnectWithoutFacilityInput = {
  create: OrderCreateWithoutFacilityInput;
  where: OrderWhereUniqueInput;
};

export type OrderCreateOrConnectWithoutItemsInput = {
  create: OrderCreateWithoutItemsInput;
  where: OrderWhereUniqueInput;
};

export type OrderCreateOrConnectWithoutKitchenTicketsInput = {
  create: OrderCreateWithoutKitchenTicketsInput;
  where: OrderWhereUniqueInput;
};

export type OrderCreateOrConnectWithoutPackagerInput = {
  create: OrderCreateWithoutPackagerInput;
  where: OrderWhereUniqueInput;
};

export type OrderCreateOrConnectWithoutPaymentInput = {
  create: OrderCreateWithoutPaymentInput;
  where: OrderWhereUniqueInput;
};

export type OrderCreateOrConnectWithoutPickupInput = {
  create: OrderCreateWithoutPickupInput;
  where: OrderWhereUniqueInput;
};

export type OrderCreateOrConnectWithoutPromosInput = {
  create: OrderCreateWithoutPromosInput;
  where: OrderWhereUniqueInput;
};

export type OrderCreateOrConnectWithoutRefundInput = {
  create: OrderCreateWithoutRefundInput;
  where: OrderWhereUniqueInput;
};

export type OrderCreateOrConnectWithoutRunnerInput = {
  create: OrderCreateWithoutRunnerInput;
  where: OrderWhereUniqueInput;
};

export type OrderCreateOrConnectWithoutTagsInput = {
  create: OrderCreateWithoutTagsInput;
  where: OrderWhereUniqueInput;
};

export type OrderCreateOrConnectWithoutTilledPaymentsInput = {
  create: OrderCreateWithoutTilledPaymentsInput;
  where: OrderWhereUniqueInput;
};

export type OrderCreateWithoutCustomerInput = {
  Payment?: Maybe<PaymentCreateNestedManyWithoutOrderInput>;
  Refund?: Maybe<RefundCreateNestedManyWithoutOrderInput>;
  addressNote?: Maybe<Scalars['String']>;
  appliedPromos?: Maybe<OrderCreateappliedPromosInput>;
  braintreeTransactions?: Maybe<BraintreeTransactionCreateNestedManyWithoutOrderInput>;
  bundle?: Maybe<OrderBundleCreateNestedOneWithoutOrdersInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  chowlyOrderId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deliverToEmail?: Maybe<Scalars['String']>;
  deliverToName?: Maybe<Scalars['String']>;
  deliverToPhone?: Maybe<Scalars['String']>;
  deliveredAt?: Maybe<Scalars['DateTime']>;
  delivery: Scalars['Float'];
  deliveryEstimation?: Maybe<Scalars['DateTime']>;
  deliveryFeeConst?: Maybe<Scalars['Float']>;
  deliveryFeeTaxRateConst?: Maybe<Scalars['Float']>;
  deliveryJobId?: Maybe<Scalars['String']>;
  deliveryOption?: Maybe<DeliveryOption>;
  deliveryTax?: Maybe<Scalars['Float']>;
  deliveryTimeInSeconds?: Maybe<Scalars['Int']>;
  expeditedAt?: Maybe<Scalars['DateTime']>;
  expo?: Maybe<WorkplaceCreateNestedOneWithoutExpoInput>;
  facility: FacilityCreateNestedOneWithoutOrdersInput;
  fee?: Maybe<Scalars['Float']>;
  formattedAddress?: Maybe<Scalars['String']>;
  fulfillmentAt: Scalars['DateTime'];
  fulfillmentBy: Scalars['DateTime'];
  giftCardCodes?: Maybe<OrderCreategiftCardCodesInput>;
  giftCardDiscounts?: Maybe<OrderCreategiftCardDiscountsInput>;
  giftCardsDiscount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  isEmployee?: Maybe<Scalars['Boolean']>;
  isOrderNow?: Maybe<Scalars['Boolean']>;
  isPaidByInvoice?: Maybe<Scalars['Boolean']>;
  isRefunded?: Maybe<Scalars['Boolean']>;
  isVip?: Maybe<Scalars['Boolean']>;
  itemTax?: Maybe<Scalars['Float']>;
  items?: Maybe<OrderItemCreateNestedManyWithoutOrderInput>;
  kdsType?: Maybe<KdsType>;
  kitchenPreparingUntil?: Maybe<Scalars['DateTime']>;
  kitchenTickets?: Maybe<KitchenTicketCreateNestedManyWithoutOrderInput>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  locality?: Maybe<Scalars['String']>;
  method?: Maybe<OrderMethod>;
  note?: Maybe<Scalars['String']>;
  numberOfGuests?: Maybe<Scalars['Int']>;
  orderEmail?: Maybe<Scalars['String']>;
  orderEvents?: Maybe<OrderEventCreateNestedManyWithoutOrderInput>;
  orderName?: Maybe<Scalars['String']>;
  orderPhoneNumber?: Maybe<Scalars['String']>;
  overwraps?: Maybe<Scalars['Int']>;
  packager?: Maybe<WorkplaceCreateNestedOneWithoutPackagerInput>;
  packedAt?: Maybe<Scalars['DateTime']>;
  payedAt?: Maybe<Scalars['DateTime']>;
  pickup?: Maybe<WorkplaceCreateNestedOneWithoutPickupInput>;
  placeId?: Maybe<Scalars['String']>;
  platform?: Maybe<Platform>;
  postalCode?: Maybe<Scalars['String']>;
  preparingUntil?: Maybe<Scalars['DateTime']>;
  promoCodes?: Maybe<OrderCreatepromoCodesInput>;
  promoDiscount: Scalars['Float'];
  promoIds?: Maybe<OrderCreatepromoIdsInput>;
  promos?: Maybe<PromoCreateNestedManyWithoutOrdersInput>;
  refireDelay?: Maybe<Scalars['Int']>;
  refired?: Maybe<Scalars['Boolean']>;
  region?: Maybe<Scalars['String']>;
  runner?: Maybe<WorkplaceCreateNestedOneWithoutRunnerInput>;
  sequentialNumber?: Maybe<Scalars['Int']>;
  serviceFeeConst?: Maybe<Scalars['Float']>;
  serviceFeeTax?: Maybe<Scalars['Float']>;
  serviceFeeTaxRateConst?: Maybe<Scalars['Float']>;
  serviceFeeType?: Maybe<FeeType>;
  shortId: Scalars['String'];
  source?: Maybe<Scalars['String']>;
  status: OrderStatus;
  street?: Maybe<Scalars['String']>;
  stripePaymentId?: Maybe<Scalars['String']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  subtotal: Scalars['Float'];
  tags?: Maybe<TagCreateNestedManyWithoutOrdersInput>;
  tax?: Maybe<Scalars['Float']>;
  taxFees: Scalars['Float'];
  taxRateConst?: Maybe<Scalars['Float']>;
  tilledPayments?: Maybe<TilledPaymentCreateNestedManyWithoutOrderInput>;
  tip: Scalars['Float'];
  tookanTaskId?: Maybe<Scalars['String']>;
  total: Scalars['Float'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  utensilsCount?: Maybe<Scalars['Int']>;
};

export type OrderCreateWithoutExpoInput = {
  Payment?: Maybe<PaymentCreateNestedManyWithoutOrderInput>;
  Refund?: Maybe<RefundCreateNestedManyWithoutOrderInput>;
  addressNote?: Maybe<Scalars['String']>;
  appliedPromos?: Maybe<OrderCreateappliedPromosInput>;
  braintreeTransactions?: Maybe<BraintreeTransactionCreateNestedManyWithoutOrderInput>;
  bundle?: Maybe<OrderBundleCreateNestedOneWithoutOrdersInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  chowlyOrderId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customer: UserCustomerInfoCreateNestedOneWithoutOrdersInput;
  deliverToEmail?: Maybe<Scalars['String']>;
  deliverToName?: Maybe<Scalars['String']>;
  deliverToPhone?: Maybe<Scalars['String']>;
  deliveredAt?: Maybe<Scalars['DateTime']>;
  delivery: Scalars['Float'];
  deliveryEstimation?: Maybe<Scalars['DateTime']>;
  deliveryFeeConst?: Maybe<Scalars['Float']>;
  deliveryFeeTaxRateConst?: Maybe<Scalars['Float']>;
  deliveryJobId?: Maybe<Scalars['String']>;
  deliveryOption?: Maybe<DeliveryOption>;
  deliveryTax?: Maybe<Scalars['Float']>;
  deliveryTimeInSeconds?: Maybe<Scalars['Int']>;
  expeditedAt?: Maybe<Scalars['DateTime']>;
  facility: FacilityCreateNestedOneWithoutOrdersInput;
  fee?: Maybe<Scalars['Float']>;
  formattedAddress?: Maybe<Scalars['String']>;
  fulfillmentAt: Scalars['DateTime'];
  fulfillmentBy: Scalars['DateTime'];
  giftCardCodes?: Maybe<OrderCreategiftCardCodesInput>;
  giftCardDiscounts?: Maybe<OrderCreategiftCardDiscountsInput>;
  giftCardsDiscount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  isEmployee?: Maybe<Scalars['Boolean']>;
  isOrderNow?: Maybe<Scalars['Boolean']>;
  isPaidByInvoice?: Maybe<Scalars['Boolean']>;
  isRefunded?: Maybe<Scalars['Boolean']>;
  isVip?: Maybe<Scalars['Boolean']>;
  itemTax?: Maybe<Scalars['Float']>;
  items?: Maybe<OrderItemCreateNestedManyWithoutOrderInput>;
  kdsType?: Maybe<KdsType>;
  kitchenPreparingUntil?: Maybe<Scalars['DateTime']>;
  kitchenTickets?: Maybe<KitchenTicketCreateNestedManyWithoutOrderInput>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  locality?: Maybe<Scalars['String']>;
  method?: Maybe<OrderMethod>;
  note?: Maybe<Scalars['String']>;
  numberOfGuests?: Maybe<Scalars['Int']>;
  orderEmail?: Maybe<Scalars['String']>;
  orderEvents?: Maybe<OrderEventCreateNestedManyWithoutOrderInput>;
  orderName?: Maybe<Scalars['String']>;
  orderPhoneNumber?: Maybe<Scalars['String']>;
  overwraps?: Maybe<Scalars['Int']>;
  packager?: Maybe<WorkplaceCreateNestedOneWithoutPackagerInput>;
  packedAt?: Maybe<Scalars['DateTime']>;
  payedAt?: Maybe<Scalars['DateTime']>;
  pickup?: Maybe<WorkplaceCreateNestedOneWithoutPickupInput>;
  placeId?: Maybe<Scalars['String']>;
  platform?: Maybe<Platform>;
  postalCode?: Maybe<Scalars['String']>;
  preparingUntil?: Maybe<Scalars['DateTime']>;
  promoCodes?: Maybe<OrderCreatepromoCodesInput>;
  promoDiscount: Scalars['Float'];
  promoIds?: Maybe<OrderCreatepromoIdsInput>;
  promos?: Maybe<PromoCreateNestedManyWithoutOrdersInput>;
  refireDelay?: Maybe<Scalars['Int']>;
  refired?: Maybe<Scalars['Boolean']>;
  region?: Maybe<Scalars['String']>;
  runner?: Maybe<WorkplaceCreateNestedOneWithoutRunnerInput>;
  sequentialNumber?: Maybe<Scalars['Int']>;
  serviceFeeConst?: Maybe<Scalars['Float']>;
  serviceFeeTax?: Maybe<Scalars['Float']>;
  serviceFeeTaxRateConst?: Maybe<Scalars['Float']>;
  serviceFeeType?: Maybe<FeeType>;
  shortId: Scalars['String'];
  source?: Maybe<Scalars['String']>;
  status: OrderStatus;
  street?: Maybe<Scalars['String']>;
  stripePaymentId?: Maybe<Scalars['String']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  subtotal: Scalars['Float'];
  tags?: Maybe<TagCreateNestedManyWithoutOrdersInput>;
  tax?: Maybe<Scalars['Float']>;
  taxFees: Scalars['Float'];
  taxRateConst?: Maybe<Scalars['Float']>;
  tilledPayments?: Maybe<TilledPaymentCreateNestedManyWithoutOrderInput>;
  tip: Scalars['Float'];
  tookanTaskId?: Maybe<Scalars['String']>;
  total: Scalars['Float'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  utensilsCount?: Maybe<Scalars['Int']>;
};

export type OrderCreateWithoutFacilityInput = {
  Payment?: Maybe<PaymentCreateNestedManyWithoutOrderInput>;
  Refund?: Maybe<RefundCreateNestedManyWithoutOrderInput>;
  addressNote?: Maybe<Scalars['String']>;
  appliedPromos?: Maybe<OrderCreateappliedPromosInput>;
  braintreeTransactions?: Maybe<BraintreeTransactionCreateNestedManyWithoutOrderInput>;
  bundle?: Maybe<OrderBundleCreateNestedOneWithoutOrdersInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  chowlyOrderId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customer: UserCustomerInfoCreateNestedOneWithoutOrdersInput;
  deliverToEmail?: Maybe<Scalars['String']>;
  deliverToName?: Maybe<Scalars['String']>;
  deliverToPhone?: Maybe<Scalars['String']>;
  deliveredAt?: Maybe<Scalars['DateTime']>;
  delivery: Scalars['Float'];
  deliveryEstimation?: Maybe<Scalars['DateTime']>;
  deliveryFeeConst?: Maybe<Scalars['Float']>;
  deliveryFeeTaxRateConst?: Maybe<Scalars['Float']>;
  deliveryJobId?: Maybe<Scalars['String']>;
  deliveryOption?: Maybe<DeliveryOption>;
  deliveryTax?: Maybe<Scalars['Float']>;
  deliveryTimeInSeconds?: Maybe<Scalars['Int']>;
  expeditedAt?: Maybe<Scalars['DateTime']>;
  expo?: Maybe<WorkplaceCreateNestedOneWithoutExpoInput>;
  fee?: Maybe<Scalars['Float']>;
  formattedAddress?: Maybe<Scalars['String']>;
  fulfillmentAt: Scalars['DateTime'];
  fulfillmentBy: Scalars['DateTime'];
  giftCardCodes?: Maybe<OrderCreategiftCardCodesInput>;
  giftCardDiscounts?: Maybe<OrderCreategiftCardDiscountsInput>;
  giftCardsDiscount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  isEmployee?: Maybe<Scalars['Boolean']>;
  isOrderNow?: Maybe<Scalars['Boolean']>;
  isPaidByInvoice?: Maybe<Scalars['Boolean']>;
  isRefunded?: Maybe<Scalars['Boolean']>;
  isVip?: Maybe<Scalars['Boolean']>;
  itemTax?: Maybe<Scalars['Float']>;
  items?: Maybe<OrderItemCreateNestedManyWithoutOrderInput>;
  kdsType?: Maybe<KdsType>;
  kitchenPreparingUntil?: Maybe<Scalars['DateTime']>;
  kitchenTickets?: Maybe<KitchenTicketCreateNestedManyWithoutOrderInput>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  locality?: Maybe<Scalars['String']>;
  method?: Maybe<OrderMethod>;
  note?: Maybe<Scalars['String']>;
  numberOfGuests?: Maybe<Scalars['Int']>;
  orderEmail?: Maybe<Scalars['String']>;
  orderEvents?: Maybe<OrderEventCreateNestedManyWithoutOrderInput>;
  orderName?: Maybe<Scalars['String']>;
  orderPhoneNumber?: Maybe<Scalars['String']>;
  overwraps?: Maybe<Scalars['Int']>;
  packager?: Maybe<WorkplaceCreateNestedOneWithoutPackagerInput>;
  packedAt?: Maybe<Scalars['DateTime']>;
  payedAt?: Maybe<Scalars['DateTime']>;
  pickup?: Maybe<WorkplaceCreateNestedOneWithoutPickupInput>;
  placeId?: Maybe<Scalars['String']>;
  platform?: Maybe<Platform>;
  postalCode?: Maybe<Scalars['String']>;
  preparingUntil?: Maybe<Scalars['DateTime']>;
  promoCodes?: Maybe<OrderCreatepromoCodesInput>;
  promoDiscount: Scalars['Float'];
  promoIds?: Maybe<OrderCreatepromoIdsInput>;
  promos?: Maybe<PromoCreateNestedManyWithoutOrdersInput>;
  refireDelay?: Maybe<Scalars['Int']>;
  refired?: Maybe<Scalars['Boolean']>;
  region?: Maybe<Scalars['String']>;
  runner?: Maybe<WorkplaceCreateNestedOneWithoutRunnerInput>;
  sequentialNumber?: Maybe<Scalars['Int']>;
  serviceFeeConst?: Maybe<Scalars['Float']>;
  serviceFeeTax?: Maybe<Scalars['Float']>;
  serviceFeeTaxRateConst?: Maybe<Scalars['Float']>;
  serviceFeeType?: Maybe<FeeType>;
  shortId: Scalars['String'];
  source?: Maybe<Scalars['String']>;
  status: OrderStatus;
  street?: Maybe<Scalars['String']>;
  stripePaymentId?: Maybe<Scalars['String']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  subtotal: Scalars['Float'];
  tags?: Maybe<TagCreateNestedManyWithoutOrdersInput>;
  tax?: Maybe<Scalars['Float']>;
  taxFees: Scalars['Float'];
  taxRateConst?: Maybe<Scalars['Float']>;
  tilledPayments?: Maybe<TilledPaymentCreateNestedManyWithoutOrderInput>;
  tip: Scalars['Float'];
  tookanTaskId?: Maybe<Scalars['String']>;
  total: Scalars['Float'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  utensilsCount?: Maybe<Scalars['Int']>;
};

export type OrderCreateWithoutItemsInput = {
  Payment?: Maybe<PaymentCreateNestedManyWithoutOrderInput>;
  Refund?: Maybe<RefundCreateNestedManyWithoutOrderInput>;
  addressNote?: Maybe<Scalars['String']>;
  appliedPromos?: Maybe<OrderCreateappliedPromosInput>;
  braintreeTransactions?: Maybe<BraintreeTransactionCreateNestedManyWithoutOrderInput>;
  bundle?: Maybe<OrderBundleCreateNestedOneWithoutOrdersInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  chowlyOrderId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customer: UserCustomerInfoCreateNestedOneWithoutOrdersInput;
  deliverToEmail?: Maybe<Scalars['String']>;
  deliverToName?: Maybe<Scalars['String']>;
  deliverToPhone?: Maybe<Scalars['String']>;
  deliveredAt?: Maybe<Scalars['DateTime']>;
  delivery: Scalars['Float'];
  deliveryEstimation?: Maybe<Scalars['DateTime']>;
  deliveryFeeConst?: Maybe<Scalars['Float']>;
  deliveryFeeTaxRateConst?: Maybe<Scalars['Float']>;
  deliveryJobId?: Maybe<Scalars['String']>;
  deliveryOption?: Maybe<DeliveryOption>;
  deliveryTax?: Maybe<Scalars['Float']>;
  deliveryTimeInSeconds?: Maybe<Scalars['Int']>;
  expeditedAt?: Maybe<Scalars['DateTime']>;
  expo?: Maybe<WorkplaceCreateNestedOneWithoutExpoInput>;
  facility: FacilityCreateNestedOneWithoutOrdersInput;
  fee?: Maybe<Scalars['Float']>;
  formattedAddress?: Maybe<Scalars['String']>;
  fulfillmentAt: Scalars['DateTime'];
  fulfillmentBy: Scalars['DateTime'];
  giftCardCodes?: Maybe<OrderCreategiftCardCodesInput>;
  giftCardDiscounts?: Maybe<OrderCreategiftCardDiscountsInput>;
  giftCardsDiscount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  isEmployee?: Maybe<Scalars['Boolean']>;
  isOrderNow?: Maybe<Scalars['Boolean']>;
  isPaidByInvoice?: Maybe<Scalars['Boolean']>;
  isRefunded?: Maybe<Scalars['Boolean']>;
  isVip?: Maybe<Scalars['Boolean']>;
  itemTax?: Maybe<Scalars['Float']>;
  kdsType?: Maybe<KdsType>;
  kitchenPreparingUntil?: Maybe<Scalars['DateTime']>;
  kitchenTickets?: Maybe<KitchenTicketCreateNestedManyWithoutOrderInput>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  locality?: Maybe<Scalars['String']>;
  method?: Maybe<OrderMethod>;
  note?: Maybe<Scalars['String']>;
  numberOfGuests?: Maybe<Scalars['Int']>;
  orderEmail?: Maybe<Scalars['String']>;
  orderEvents?: Maybe<OrderEventCreateNestedManyWithoutOrderInput>;
  orderName?: Maybe<Scalars['String']>;
  orderPhoneNumber?: Maybe<Scalars['String']>;
  overwraps?: Maybe<Scalars['Int']>;
  packager?: Maybe<WorkplaceCreateNestedOneWithoutPackagerInput>;
  packedAt?: Maybe<Scalars['DateTime']>;
  payedAt?: Maybe<Scalars['DateTime']>;
  pickup?: Maybe<WorkplaceCreateNestedOneWithoutPickupInput>;
  placeId?: Maybe<Scalars['String']>;
  platform?: Maybe<Platform>;
  postalCode?: Maybe<Scalars['String']>;
  preparingUntil?: Maybe<Scalars['DateTime']>;
  promoCodes?: Maybe<OrderCreatepromoCodesInput>;
  promoDiscount: Scalars['Float'];
  promoIds?: Maybe<OrderCreatepromoIdsInput>;
  promos?: Maybe<PromoCreateNestedManyWithoutOrdersInput>;
  refireDelay?: Maybe<Scalars['Int']>;
  refired?: Maybe<Scalars['Boolean']>;
  region?: Maybe<Scalars['String']>;
  runner?: Maybe<WorkplaceCreateNestedOneWithoutRunnerInput>;
  sequentialNumber?: Maybe<Scalars['Int']>;
  serviceFeeConst?: Maybe<Scalars['Float']>;
  serviceFeeTax?: Maybe<Scalars['Float']>;
  serviceFeeTaxRateConst?: Maybe<Scalars['Float']>;
  serviceFeeType?: Maybe<FeeType>;
  shortId: Scalars['String'];
  source?: Maybe<Scalars['String']>;
  status: OrderStatus;
  street?: Maybe<Scalars['String']>;
  stripePaymentId?: Maybe<Scalars['String']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  subtotal: Scalars['Float'];
  tags?: Maybe<TagCreateNestedManyWithoutOrdersInput>;
  tax?: Maybe<Scalars['Float']>;
  taxFees: Scalars['Float'];
  taxRateConst?: Maybe<Scalars['Float']>;
  tilledPayments?: Maybe<TilledPaymentCreateNestedManyWithoutOrderInput>;
  tip: Scalars['Float'];
  tookanTaskId?: Maybe<Scalars['String']>;
  total: Scalars['Float'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  utensilsCount?: Maybe<Scalars['Int']>;
};

export type OrderCreateWithoutKitchenTicketsInput = {
  Payment?: Maybe<PaymentCreateNestedManyWithoutOrderInput>;
  Refund?: Maybe<RefundCreateNestedManyWithoutOrderInput>;
  addressNote?: Maybe<Scalars['String']>;
  appliedPromos?: Maybe<OrderCreateappliedPromosInput>;
  braintreeTransactions?: Maybe<BraintreeTransactionCreateNestedManyWithoutOrderInput>;
  bundle?: Maybe<OrderBundleCreateNestedOneWithoutOrdersInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  chowlyOrderId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customer: UserCustomerInfoCreateNestedOneWithoutOrdersInput;
  deliverToEmail?: Maybe<Scalars['String']>;
  deliverToName?: Maybe<Scalars['String']>;
  deliverToPhone?: Maybe<Scalars['String']>;
  deliveredAt?: Maybe<Scalars['DateTime']>;
  delivery: Scalars['Float'];
  deliveryEstimation?: Maybe<Scalars['DateTime']>;
  deliveryFeeConst?: Maybe<Scalars['Float']>;
  deliveryFeeTaxRateConst?: Maybe<Scalars['Float']>;
  deliveryJobId?: Maybe<Scalars['String']>;
  deliveryOption?: Maybe<DeliveryOption>;
  deliveryTax?: Maybe<Scalars['Float']>;
  deliveryTimeInSeconds?: Maybe<Scalars['Int']>;
  expeditedAt?: Maybe<Scalars['DateTime']>;
  expo?: Maybe<WorkplaceCreateNestedOneWithoutExpoInput>;
  facility: FacilityCreateNestedOneWithoutOrdersInput;
  fee?: Maybe<Scalars['Float']>;
  formattedAddress?: Maybe<Scalars['String']>;
  fulfillmentAt: Scalars['DateTime'];
  fulfillmentBy: Scalars['DateTime'];
  giftCardCodes?: Maybe<OrderCreategiftCardCodesInput>;
  giftCardDiscounts?: Maybe<OrderCreategiftCardDiscountsInput>;
  giftCardsDiscount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  isEmployee?: Maybe<Scalars['Boolean']>;
  isOrderNow?: Maybe<Scalars['Boolean']>;
  isPaidByInvoice?: Maybe<Scalars['Boolean']>;
  isRefunded?: Maybe<Scalars['Boolean']>;
  isVip?: Maybe<Scalars['Boolean']>;
  itemTax?: Maybe<Scalars['Float']>;
  items?: Maybe<OrderItemCreateNestedManyWithoutOrderInput>;
  kdsType?: Maybe<KdsType>;
  kitchenPreparingUntil?: Maybe<Scalars['DateTime']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  locality?: Maybe<Scalars['String']>;
  method?: Maybe<OrderMethod>;
  note?: Maybe<Scalars['String']>;
  numberOfGuests?: Maybe<Scalars['Int']>;
  orderEmail?: Maybe<Scalars['String']>;
  orderEvents?: Maybe<OrderEventCreateNestedManyWithoutOrderInput>;
  orderName?: Maybe<Scalars['String']>;
  orderPhoneNumber?: Maybe<Scalars['String']>;
  overwraps?: Maybe<Scalars['Int']>;
  packager?: Maybe<WorkplaceCreateNestedOneWithoutPackagerInput>;
  packedAt?: Maybe<Scalars['DateTime']>;
  payedAt?: Maybe<Scalars['DateTime']>;
  pickup?: Maybe<WorkplaceCreateNestedOneWithoutPickupInput>;
  placeId?: Maybe<Scalars['String']>;
  platform?: Maybe<Platform>;
  postalCode?: Maybe<Scalars['String']>;
  preparingUntil?: Maybe<Scalars['DateTime']>;
  promoCodes?: Maybe<OrderCreatepromoCodesInput>;
  promoDiscount: Scalars['Float'];
  promoIds?: Maybe<OrderCreatepromoIdsInput>;
  promos?: Maybe<PromoCreateNestedManyWithoutOrdersInput>;
  refireDelay?: Maybe<Scalars['Int']>;
  refired?: Maybe<Scalars['Boolean']>;
  region?: Maybe<Scalars['String']>;
  runner?: Maybe<WorkplaceCreateNestedOneWithoutRunnerInput>;
  sequentialNumber?: Maybe<Scalars['Int']>;
  serviceFeeConst?: Maybe<Scalars['Float']>;
  serviceFeeTax?: Maybe<Scalars['Float']>;
  serviceFeeTaxRateConst?: Maybe<Scalars['Float']>;
  serviceFeeType?: Maybe<FeeType>;
  shortId: Scalars['String'];
  source?: Maybe<Scalars['String']>;
  status: OrderStatus;
  street?: Maybe<Scalars['String']>;
  stripePaymentId?: Maybe<Scalars['String']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  subtotal: Scalars['Float'];
  tags?: Maybe<TagCreateNestedManyWithoutOrdersInput>;
  tax?: Maybe<Scalars['Float']>;
  taxFees: Scalars['Float'];
  taxRateConst?: Maybe<Scalars['Float']>;
  tilledPayments?: Maybe<TilledPaymentCreateNestedManyWithoutOrderInput>;
  tip: Scalars['Float'];
  tookanTaskId?: Maybe<Scalars['String']>;
  total: Scalars['Float'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  utensilsCount?: Maybe<Scalars['Int']>;
};

export type OrderCreateWithoutPackagerInput = {
  Payment?: Maybe<PaymentCreateNestedManyWithoutOrderInput>;
  Refund?: Maybe<RefundCreateNestedManyWithoutOrderInput>;
  addressNote?: Maybe<Scalars['String']>;
  appliedPromos?: Maybe<OrderCreateappliedPromosInput>;
  braintreeTransactions?: Maybe<BraintreeTransactionCreateNestedManyWithoutOrderInput>;
  bundle?: Maybe<OrderBundleCreateNestedOneWithoutOrdersInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  chowlyOrderId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customer: UserCustomerInfoCreateNestedOneWithoutOrdersInput;
  deliverToEmail?: Maybe<Scalars['String']>;
  deliverToName?: Maybe<Scalars['String']>;
  deliverToPhone?: Maybe<Scalars['String']>;
  deliveredAt?: Maybe<Scalars['DateTime']>;
  delivery: Scalars['Float'];
  deliveryEstimation?: Maybe<Scalars['DateTime']>;
  deliveryFeeConst?: Maybe<Scalars['Float']>;
  deliveryFeeTaxRateConst?: Maybe<Scalars['Float']>;
  deliveryJobId?: Maybe<Scalars['String']>;
  deliveryOption?: Maybe<DeliveryOption>;
  deliveryTax?: Maybe<Scalars['Float']>;
  deliveryTimeInSeconds?: Maybe<Scalars['Int']>;
  expeditedAt?: Maybe<Scalars['DateTime']>;
  expo?: Maybe<WorkplaceCreateNestedOneWithoutExpoInput>;
  facility: FacilityCreateNestedOneWithoutOrdersInput;
  fee?: Maybe<Scalars['Float']>;
  formattedAddress?: Maybe<Scalars['String']>;
  fulfillmentAt: Scalars['DateTime'];
  fulfillmentBy: Scalars['DateTime'];
  giftCardCodes?: Maybe<OrderCreategiftCardCodesInput>;
  giftCardDiscounts?: Maybe<OrderCreategiftCardDiscountsInput>;
  giftCardsDiscount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  isEmployee?: Maybe<Scalars['Boolean']>;
  isOrderNow?: Maybe<Scalars['Boolean']>;
  isPaidByInvoice?: Maybe<Scalars['Boolean']>;
  isRefunded?: Maybe<Scalars['Boolean']>;
  isVip?: Maybe<Scalars['Boolean']>;
  itemTax?: Maybe<Scalars['Float']>;
  items?: Maybe<OrderItemCreateNestedManyWithoutOrderInput>;
  kdsType?: Maybe<KdsType>;
  kitchenPreparingUntil?: Maybe<Scalars['DateTime']>;
  kitchenTickets?: Maybe<KitchenTicketCreateNestedManyWithoutOrderInput>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  locality?: Maybe<Scalars['String']>;
  method?: Maybe<OrderMethod>;
  note?: Maybe<Scalars['String']>;
  numberOfGuests?: Maybe<Scalars['Int']>;
  orderEmail?: Maybe<Scalars['String']>;
  orderEvents?: Maybe<OrderEventCreateNestedManyWithoutOrderInput>;
  orderName?: Maybe<Scalars['String']>;
  orderPhoneNumber?: Maybe<Scalars['String']>;
  overwraps?: Maybe<Scalars['Int']>;
  packedAt?: Maybe<Scalars['DateTime']>;
  payedAt?: Maybe<Scalars['DateTime']>;
  pickup?: Maybe<WorkplaceCreateNestedOneWithoutPickupInput>;
  placeId?: Maybe<Scalars['String']>;
  platform?: Maybe<Platform>;
  postalCode?: Maybe<Scalars['String']>;
  preparingUntil?: Maybe<Scalars['DateTime']>;
  promoCodes?: Maybe<OrderCreatepromoCodesInput>;
  promoDiscount: Scalars['Float'];
  promoIds?: Maybe<OrderCreatepromoIdsInput>;
  promos?: Maybe<PromoCreateNestedManyWithoutOrdersInput>;
  refireDelay?: Maybe<Scalars['Int']>;
  refired?: Maybe<Scalars['Boolean']>;
  region?: Maybe<Scalars['String']>;
  runner?: Maybe<WorkplaceCreateNestedOneWithoutRunnerInput>;
  sequentialNumber?: Maybe<Scalars['Int']>;
  serviceFeeConst?: Maybe<Scalars['Float']>;
  serviceFeeTax?: Maybe<Scalars['Float']>;
  serviceFeeTaxRateConst?: Maybe<Scalars['Float']>;
  serviceFeeType?: Maybe<FeeType>;
  shortId: Scalars['String'];
  source?: Maybe<Scalars['String']>;
  status: OrderStatus;
  street?: Maybe<Scalars['String']>;
  stripePaymentId?: Maybe<Scalars['String']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  subtotal: Scalars['Float'];
  tags?: Maybe<TagCreateNestedManyWithoutOrdersInput>;
  tax?: Maybe<Scalars['Float']>;
  taxFees: Scalars['Float'];
  taxRateConst?: Maybe<Scalars['Float']>;
  tilledPayments?: Maybe<TilledPaymentCreateNestedManyWithoutOrderInput>;
  tip: Scalars['Float'];
  tookanTaskId?: Maybe<Scalars['String']>;
  total: Scalars['Float'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  utensilsCount?: Maybe<Scalars['Int']>;
};

export type OrderCreateWithoutPaymentInput = {
  Refund?: Maybe<RefundCreateNestedManyWithoutOrderInput>;
  addressNote?: Maybe<Scalars['String']>;
  appliedPromos?: Maybe<OrderCreateappliedPromosInput>;
  braintreeTransactions?: Maybe<BraintreeTransactionCreateNestedManyWithoutOrderInput>;
  bundle?: Maybe<OrderBundleCreateNestedOneWithoutOrdersInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  chowlyOrderId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customer: UserCustomerInfoCreateNestedOneWithoutOrdersInput;
  deliverToEmail?: Maybe<Scalars['String']>;
  deliverToName?: Maybe<Scalars['String']>;
  deliverToPhone?: Maybe<Scalars['String']>;
  deliveredAt?: Maybe<Scalars['DateTime']>;
  delivery: Scalars['Float'];
  deliveryEstimation?: Maybe<Scalars['DateTime']>;
  deliveryFeeConst?: Maybe<Scalars['Float']>;
  deliveryFeeTaxRateConst?: Maybe<Scalars['Float']>;
  deliveryJobId?: Maybe<Scalars['String']>;
  deliveryOption?: Maybe<DeliveryOption>;
  deliveryTax?: Maybe<Scalars['Float']>;
  deliveryTimeInSeconds?: Maybe<Scalars['Int']>;
  expeditedAt?: Maybe<Scalars['DateTime']>;
  expo?: Maybe<WorkplaceCreateNestedOneWithoutExpoInput>;
  facility: FacilityCreateNestedOneWithoutOrdersInput;
  fee?: Maybe<Scalars['Float']>;
  formattedAddress?: Maybe<Scalars['String']>;
  fulfillmentAt: Scalars['DateTime'];
  fulfillmentBy: Scalars['DateTime'];
  giftCardCodes?: Maybe<OrderCreategiftCardCodesInput>;
  giftCardDiscounts?: Maybe<OrderCreategiftCardDiscountsInput>;
  giftCardsDiscount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  isEmployee?: Maybe<Scalars['Boolean']>;
  isOrderNow?: Maybe<Scalars['Boolean']>;
  isPaidByInvoice?: Maybe<Scalars['Boolean']>;
  isRefunded?: Maybe<Scalars['Boolean']>;
  isVip?: Maybe<Scalars['Boolean']>;
  itemTax?: Maybe<Scalars['Float']>;
  items?: Maybe<OrderItemCreateNestedManyWithoutOrderInput>;
  kdsType?: Maybe<KdsType>;
  kitchenPreparingUntil?: Maybe<Scalars['DateTime']>;
  kitchenTickets?: Maybe<KitchenTicketCreateNestedManyWithoutOrderInput>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  locality?: Maybe<Scalars['String']>;
  method?: Maybe<OrderMethod>;
  note?: Maybe<Scalars['String']>;
  numberOfGuests?: Maybe<Scalars['Int']>;
  orderEmail?: Maybe<Scalars['String']>;
  orderEvents?: Maybe<OrderEventCreateNestedManyWithoutOrderInput>;
  orderName?: Maybe<Scalars['String']>;
  orderPhoneNumber?: Maybe<Scalars['String']>;
  overwraps?: Maybe<Scalars['Int']>;
  packager?: Maybe<WorkplaceCreateNestedOneWithoutPackagerInput>;
  packedAt?: Maybe<Scalars['DateTime']>;
  payedAt?: Maybe<Scalars['DateTime']>;
  pickup?: Maybe<WorkplaceCreateNestedOneWithoutPickupInput>;
  placeId?: Maybe<Scalars['String']>;
  platform?: Maybe<Platform>;
  postalCode?: Maybe<Scalars['String']>;
  preparingUntil?: Maybe<Scalars['DateTime']>;
  promoCodes?: Maybe<OrderCreatepromoCodesInput>;
  promoDiscount: Scalars['Float'];
  promoIds?: Maybe<OrderCreatepromoIdsInput>;
  promos?: Maybe<PromoCreateNestedManyWithoutOrdersInput>;
  refireDelay?: Maybe<Scalars['Int']>;
  refired?: Maybe<Scalars['Boolean']>;
  region?: Maybe<Scalars['String']>;
  runner?: Maybe<WorkplaceCreateNestedOneWithoutRunnerInput>;
  sequentialNumber?: Maybe<Scalars['Int']>;
  serviceFeeConst?: Maybe<Scalars['Float']>;
  serviceFeeTax?: Maybe<Scalars['Float']>;
  serviceFeeTaxRateConst?: Maybe<Scalars['Float']>;
  serviceFeeType?: Maybe<FeeType>;
  shortId: Scalars['String'];
  source?: Maybe<Scalars['String']>;
  status: OrderStatus;
  street?: Maybe<Scalars['String']>;
  stripePaymentId?: Maybe<Scalars['String']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  subtotal: Scalars['Float'];
  tags?: Maybe<TagCreateNestedManyWithoutOrdersInput>;
  tax?: Maybe<Scalars['Float']>;
  taxFees: Scalars['Float'];
  taxRateConst?: Maybe<Scalars['Float']>;
  tilledPayments?: Maybe<TilledPaymentCreateNestedManyWithoutOrderInput>;
  tip: Scalars['Float'];
  tookanTaskId?: Maybe<Scalars['String']>;
  total: Scalars['Float'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  utensilsCount?: Maybe<Scalars['Int']>;
};

export type OrderCreateWithoutPickupInput = {
  Payment?: Maybe<PaymentCreateNestedManyWithoutOrderInput>;
  Refund?: Maybe<RefundCreateNestedManyWithoutOrderInput>;
  addressNote?: Maybe<Scalars['String']>;
  appliedPromos?: Maybe<OrderCreateappliedPromosInput>;
  braintreeTransactions?: Maybe<BraintreeTransactionCreateNestedManyWithoutOrderInput>;
  bundle?: Maybe<OrderBundleCreateNestedOneWithoutOrdersInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  chowlyOrderId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customer: UserCustomerInfoCreateNestedOneWithoutOrdersInput;
  deliverToEmail?: Maybe<Scalars['String']>;
  deliverToName?: Maybe<Scalars['String']>;
  deliverToPhone?: Maybe<Scalars['String']>;
  deliveredAt?: Maybe<Scalars['DateTime']>;
  delivery: Scalars['Float'];
  deliveryEstimation?: Maybe<Scalars['DateTime']>;
  deliveryFeeConst?: Maybe<Scalars['Float']>;
  deliveryFeeTaxRateConst?: Maybe<Scalars['Float']>;
  deliveryJobId?: Maybe<Scalars['String']>;
  deliveryOption?: Maybe<DeliveryOption>;
  deliveryTax?: Maybe<Scalars['Float']>;
  deliveryTimeInSeconds?: Maybe<Scalars['Int']>;
  expeditedAt?: Maybe<Scalars['DateTime']>;
  expo?: Maybe<WorkplaceCreateNestedOneWithoutExpoInput>;
  facility: FacilityCreateNestedOneWithoutOrdersInput;
  fee?: Maybe<Scalars['Float']>;
  formattedAddress?: Maybe<Scalars['String']>;
  fulfillmentAt: Scalars['DateTime'];
  fulfillmentBy: Scalars['DateTime'];
  giftCardCodes?: Maybe<OrderCreategiftCardCodesInput>;
  giftCardDiscounts?: Maybe<OrderCreategiftCardDiscountsInput>;
  giftCardsDiscount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  isEmployee?: Maybe<Scalars['Boolean']>;
  isOrderNow?: Maybe<Scalars['Boolean']>;
  isPaidByInvoice?: Maybe<Scalars['Boolean']>;
  isRefunded?: Maybe<Scalars['Boolean']>;
  isVip?: Maybe<Scalars['Boolean']>;
  itemTax?: Maybe<Scalars['Float']>;
  items?: Maybe<OrderItemCreateNestedManyWithoutOrderInput>;
  kdsType?: Maybe<KdsType>;
  kitchenPreparingUntil?: Maybe<Scalars['DateTime']>;
  kitchenTickets?: Maybe<KitchenTicketCreateNestedManyWithoutOrderInput>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  locality?: Maybe<Scalars['String']>;
  method?: Maybe<OrderMethod>;
  note?: Maybe<Scalars['String']>;
  numberOfGuests?: Maybe<Scalars['Int']>;
  orderEmail?: Maybe<Scalars['String']>;
  orderEvents?: Maybe<OrderEventCreateNestedManyWithoutOrderInput>;
  orderName?: Maybe<Scalars['String']>;
  orderPhoneNumber?: Maybe<Scalars['String']>;
  overwraps?: Maybe<Scalars['Int']>;
  packager?: Maybe<WorkplaceCreateNestedOneWithoutPackagerInput>;
  packedAt?: Maybe<Scalars['DateTime']>;
  payedAt?: Maybe<Scalars['DateTime']>;
  placeId?: Maybe<Scalars['String']>;
  platform?: Maybe<Platform>;
  postalCode?: Maybe<Scalars['String']>;
  preparingUntil?: Maybe<Scalars['DateTime']>;
  promoCodes?: Maybe<OrderCreatepromoCodesInput>;
  promoDiscount: Scalars['Float'];
  promoIds?: Maybe<OrderCreatepromoIdsInput>;
  promos?: Maybe<PromoCreateNestedManyWithoutOrdersInput>;
  refireDelay?: Maybe<Scalars['Int']>;
  refired?: Maybe<Scalars['Boolean']>;
  region?: Maybe<Scalars['String']>;
  runner?: Maybe<WorkplaceCreateNestedOneWithoutRunnerInput>;
  sequentialNumber?: Maybe<Scalars['Int']>;
  serviceFeeConst?: Maybe<Scalars['Float']>;
  serviceFeeTax?: Maybe<Scalars['Float']>;
  serviceFeeTaxRateConst?: Maybe<Scalars['Float']>;
  serviceFeeType?: Maybe<FeeType>;
  shortId: Scalars['String'];
  source?: Maybe<Scalars['String']>;
  status: OrderStatus;
  street?: Maybe<Scalars['String']>;
  stripePaymentId?: Maybe<Scalars['String']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  subtotal: Scalars['Float'];
  tags?: Maybe<TagCreateNestedManyWithoutOrdersInput>;
  tax?: Maybe<Scalars['Float']>;
  taxFees: Scalars['Float'];
  taxRateConst?: Maybe<Scalars['Float']>;
  tilledPayments?: Maybe<TilledPaymentCreateNestedManyWithoutOrderInput>;
  tip: Scalars['Float'];
  tookanTaskId?: Maybe<Scalars['String']>;
  total: Scalars['Float'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  utensilsCount?: Maybe<Scalars['Int']>;
};

export type OrderCreateWithoutPromosInput = {
  Payment?: Maybe<PaymentCreateNestedManyWithoutOrderInput>;
  Refund?: Maybe<RefundCreateNestedManyWithoutOrderInput>;
  addressNote?: Maybe<Scalars['String']>;
  appliedPromos?: Maybe<OrderCreateappliedPromosInput>;
  braintreeTransactions?: Maybe<BraintreeTransactionCreateNestedManyWithoutOrderInput>;
  bundle?: Maybe<OrderBundleCreateNestedOneWithoutOrdersInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  chowlyOrderId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customer: UserCustomerInfoCreateNestedOneWithoutOrdersInput;
  deliverToEmail?: Maybe<Scalars['String']>;
  deliverToName?: Maybe<Scalars['String']>;
  deliverToPhone?: Maybe<Scalars['String']>;
  deliveredAt?: Maybe<Scalars['DateTime']>;
  delivery: Scalars['Float'];
  deliveryEstimation?: Maybe<Scalars['DateTime']>;
  deliveryFeeConst?: Maybe<Scalars['Float']>;
  deliveryFeeTaxRateConst?: Maybe<Scalars['Float']>;
  deliveryJobId?: Maybe<Scalars['String']>;
  deliveryOption?: Maybe<DeliveryOption>;
  deliveryTax?: Maybe<Scalars['Float']>;
  deliveryTimeInSeconds?: Maybe<Scalars['Int']>;
  expeditedAt?: Maybe<Scalars['DateTime']>;
  expo?: Maybe<WorkplaceCreateNestedOneWithoutExpoInput>;
  facility: FacilityCreateNestedOneWithoutOrdersInput;
  fee?: Maybe<Scalars['Float']>;
  formattedAddress?: Maybe<Scalars['String']>;
  fulfillmentAt: Scalars['DateTime'];
  fulfillmentBy: Scalars['DateTime'];
  giftCardCodes?: Maybe<OrderCreategiftCardCodesInput>;
  giftCardDiscounts?: Maybe<OrderCreategiftCardDiscountsInput>;
  giftCardsDiscount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  isEmployee?: Maybe<Scalars['Boolean']>;
  isOrderNow?: Maybe<Scalars['Boolean']>;
  isPaidByInvoice?: Maybe<Scalars['Boolean']>;
  isRefunded?: Maybe<Scalars['Boolean']>;
  isVip?: Maybe<Scalars['Boolean']>;
  itemTax?: Maybe<Scalars['Float']>;
  items?: Maybe<OrderItemCreateNestedManyWithoutOrderInput>;
  kdsType?: Maybe<KdsType>;
  kitchenPreparingUntil?: Maybe<Scalars['DateTime']>;
  kitchenTickets?: Maybe<KitchenTicketCreateNestedManyWithoutOrderInput>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  locality?: Maybe<Scalars['String']>;
  method?: Maybe<OrderMethod>;
  note?: Maybe<Scalars['String']>;
  numberOfGuests?: Maybe<Scalars['Int']>;
  orderEmail?: Maybe<Scalars['String']>;
  orderEvents?: Maybe<OrderEventCreateNestedManyWithoutOrderInput>;
  orderName?: Maybe<Scalars['String']>;
  orderPhoneNumber?: Maybe<Scalars['String']>;
  overwraps?: Maybe<Scalars['Int']>;
  packager?: Maybe<WorkplaceCreateNestedOneWithoutPackagerInput>;
  packedAt?: Maybe<Scalars['DateTime']>;
  payedAt?: Maybe<Scalars['DateTime']>;
  pickup?: Maybe<WorkplaceCreateNestedOneWithoutPickupInput>;
  placeId?: Maybe<Scalars['String']>;
  platform?: Maybe<Platform>;
  postalCode?: Maybe<Scalars['String']>;
  preparingUntil?: Maybe<Scalars['DateTime']>;
  promoCodes?: Maybe<OrderCreatepromoCodesInput>;
  promoDiscount: Scalars['Float'];
  promoIds?: Maybe<OrderCreatepromoIdsInput>;
  refireDelay?: Maybe<Scalars['Int']>;
  refired?: Maybe<Scalars['Boolean']>;
  region?: Maybe<Scalars['String']>;
  runner?: Maybe<WorkplaceCreateNestedOneWithoutRunnerInput>;
  sequentialNumber?: Maybe<Scalars['Int']>;
  serviceFeeConst?: Maybe<Scalars['Float']>;
  serviceFeeTax?: Maybe<Scalars['Float']>;
  serviceFeeTaxRateConst?: Maybe<Scalars['Float']>;
  serviceFeeType?: Maybe<FeeType>;
  shortId: Scalars['String'];
  source?: Maybe<Scalars['String']>;
  status: OrderStatus;
  street?: Maybe<Scalars['String']>;
  stripePaymentId?: Maybe<Scalars['String']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  subtotal: Scalars['Float'];
  tags?: Maybe<TagCreateNestedManyWithoutOrdersInput>;
  tax?: Maybe<Scalars['Float']>;
  taxFees: Scalars['Float'];
  taxRateConst?: Maybe<Scalars['Float']>;
  tilledPayments?: Maybe<TilledPaymentCreateNestedManyWithoutOrderInput>;
  tip: Scalars['Float'];
  tookanTaskId?: Maybe<Scalars['String']>;
  total: Scalars['Float'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  utensilsCount?: Maybe<Scalars['Int']>;
};

export type OrderCreateWithoutRefundInput = {
  Payment?: Maybe<PaymentCreateNestedManyWithoutOrderInput>;
  addressNote?: Maybe<Scalars['String']>;
  appliedPromos?: Maybe<OrderCreateappliedPromosInput>;
  braintreeTransactions?: Maybe<BraintreeTransactionCreateNestedManyWithoutOrderInput>;
  bundle?: Maybe<OrderBundleCreateNestedOneWithoutOrdersInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  chowlyOrderId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customer: UserCustomerInfoCreateNestedOneWithoutOrdersInput;
  deliverToEmail?: Maybe<Scalars['String']>;
  deliverToName?: Maybe<Scalars['String']>;
  deliverToPhone?: Maybe<Scalars['String']>;
  deliveredAt?: Maybe<Scalars['DateTime']>;
  delivery: Scalars['Float'];
  deliveryEstimation?: Maybe<Scalars['DateTime']>;
  deliveryFeeConst?: Maybe<Scalars['Float']>;
  deliveryFeeTaxRateConst?: Maybe<Scalars['Float']>;
  deliveryJobId?: Maybe<Scalars['String']>;
  deliveryOption?: Maybe<DeliveryOption>;
  deliveryTax?: Maybe<Scalars['Float']>;
  deliveryTimeInSeconds?: Maybe<Scalars['Int']>;
  expeditedAt?: Maybe<Scalars['DateTime']>;
  expo?: Maybe<WorkplaceCreateNestedOneWithoutExpoInput>;
  facility: FacilityCreateNestedOneWithoutOrdersInput;
  fee?: Maybe<Scalars['Float']>;
  formattedAddress?: Maybe<Scalars['String']>;
  fulfillmentAt: Scalars['DateTime'];
  fulfillmentBy: Scalars['DateTime'];
  giftCardCodes?: Maybe<OrderCreategiftCardCodesInput>;
  giftCardDiscounts?: Maybe<OrderCreategiftCardDiscountsInput>;
  giftCardsDiscount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  isEmployee?: Maybe<Scalars['Boolean']>;
  isOrderNow?: Maybe<Scalars['Boolean']>;
  isPaidByInvoice?: Maybe<Scalars['Boolean']>;
  isRefunded?: Maybe<Scalars['Boolean']>;
  isVip?: Maybe<Scalars['Boolean']>;
  itemTax?: Maybe<Scalars['Float']>;
  items?: Maybe<OrderItemCreateNestedManyWithoutOrderInput>;
  kdsType?: Maybe<KdsType>;
  kitchenPreparingUntil?: Maybe<Scalars['DateTime']>;
  kitchenTickets?: Maybe<KitchenTicketCreateNestedManyWithoutOrderInput>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  locality?: Maybe<Scalars['String']>;
  method?: Maybe<OrderMethod>;
  note?: Maybe<Scalars['String']>;
  numberOfGuests?: Maybe<Scalars['Int']>;
  orderEmail?: Maybe<Scalars['String']>;
  orderEvents?: Maybe<OrderEventCreateNestedManyWithoutOrderInput>;
  orderName?: Maybe<Scalars['String']>;
  orderPhoneNumber?: Maybe<Scalars['String']>;
  overwraps?: Maybe<Scalars['Int']>;
  packager?: Maybe<WorkplaceCreateNestedOneWithoutPackagerInput>;
  packedAt?: Maybe<Scalars['DateTime']>;
  payedAt?: Maybe<Scalars['DateTime']>;
  pickup?: Maybe<WorkplaceCreateNestedOneWithoutPickupInput>;
  placeId?: Maybe<Scalars['String']>;
  platform?: Maybe<Platform>;
  postalCode?: Maybe<Scalars['String']>;
  preparingUntil?: Maybe<Scalars['DateTime']>;
  promoCodes?: Maybe<OrderCreatepromoCodesInput>;
  promoDiscount: Scalars['Float'];
  promoIds?: Maybe<OrderCreatepromoIdsInput>;
  promos?: Maybe<PromoCreateNestedManyWithoutOrdersInput>;
  refireDelay?: Maybe<Scalars['Int']>;
  refired?: Maybe<Scalars['Boolean']>;
  region?: Maybe<Scalars['String']>;
  runner?: Maybe<WorkplaceCreateNestedOneWithoutRunnerInput>;
  sequentialNumber?: Maybe<Scalars['Int']>;
  serviceFeeConst?: Maybe<Scalars['Float']>;
  serviceFeeTax?: Maybe<Scalars['Float']>;
  serviceFeeTaxRateConst?: Maybe<Scalars['Float']>;
  serviceFeeType?: Maybe<FeeType>;
  shortId: Scalars['String'];
  source?: Maybe<Scalars['String']>;
  status: OrderStatus;
  street?: Maybe<Scalars['String']>;
  stripePaymentId?: Maybe<Scalars['String']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  subtotal: Scalars['Float'];
  tags?: Maybe<TagCreateNestedManyWithoutOrdersInput>;
  tax?: Maybe<Scalars['Float']>;
  taxFees: Scalars['Float'];
  taxRateConst?: Maybe<Scalars['Float']>;
  tilledPayments?: Maybe<TilledPaymentCreateNestedManyWithoutOrderInput>;
  tip: Scalars['Float'];
  tookanTaskId?: Maybe<Scalars['String']>;
  total: Scalars['Float'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  utensilsCount?: Maybe<Scalars['Int']>;
};

export type OrderCreateWithoutRunnerInput = {
  Payment?: Maybe<PaymentCreateNestedManyWithoutOrderInput>;
  Refund?: Maybe<RefundCreateNestedManyWithoutOrderInput>;
  addressNote?: Maybe<Scalars['String']>;
  appliedPromos?: Maybe<OrderCreateappliedPromosInput>;
  braintreeTransactions?: Maybe<BraintreeTransactionCreateNestedManyWithoutOrderInput>;
  bundle?: Maybe<OrderBundleCreateNestedOneWithoutOrdersInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  chowlyOrderId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customer: UserCustomerInfoCreateNestedOneWithoutOrdersInput;
  deliverToEmail?: Maybe<Scalars['String']>;
  deliverToName?: Maybe<Scalars['String']>;
  deliverToPhone?: Maybe<Scalars['String']>;
  deliveredAt?: Maybe<Scalars['DateTime']>;
  delivery: Scalars['Float'];
  deliveryEstimation?: Maybe<Scalars['DateTime']>;
  deliveryFeeConst?: Maybe<Scalars['Float']>;
  deliveryFeeTaxRateConst?: Maybe<Scalars['Float']>;
  deliveryJobId?: Maybe<Scalars['String']>;
  deliveryOption?: Maybe<DeliveryOption>;
  deliveryTax?: Maybe<Scalars['Float']>;
  deliveryTimeInSeconds?: Maybe<Scalars['Int']>;
  expeditedAt?: Maybe<Scalars['DateTime']>;
  expo?: Maybe<WorkplaceCreateNestedOneWithoutExpoInput>;
  facility: FacilityCreateNestedOneWithoutOrdersInput;
  fee?: Maybe<Scalars['Float']>;
  formattedAddress?: Maybe<Scalars['String']>;
  fulfillmentAt: Scalars['DateTime'];
  fulfillmentBy: Scalars['DateTime'];
  giftCardCodes?: Maybe<OrderCreategiftCardCodesInput>;
  giftCardDiscounts?: Maybe<OrderCreategiftCardDiscountsInput>;
  giftCardsDiscount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  isEmployee?: Maybe<Scalars['Boolean']>;
  isOrderNow?: Maybe<Scalars['Boolean']>;
  isPaidByInvoice?: Maybe<Scalars['Boolean']>;
  isRefunded?: Maybe<Scalars['Boolean']>;
  isVip?: Maybe<Scalars['Boolean']>;
  itemTax?: Maybe<Scalars['Float']>;
  items?: Maybe<OrderItemCreateNestedManyWithoutOrderInput>;
  kdsType?: Maybe<KdsType>;
  kitchenPreparingUntil?: Maybe<Scalars['DateTime']>;
  kitchenTickets?: Maybe<KitchenTicketCreateNestedManyWithoutOrderInput>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  locality?: Maybe<Scalars['String']>;
  method?: Maybe<OrderMethod>;
  note?: Maybe<Scalars['String']>;
  numberOfGuests?: Maybe<Scalars['Int']>;
  orderEmail?: Maybe<Scalars['String']>;
  orderEvents?: Maybe<OrderEventCreateNestedManyWithoutOrderInput>;
  orderName?: Maybe<Scalars['String']>;
  orderPhoneNumber?: Maybe<Scalars['String']>;
  overwraps?: Maybe<Scalars['Int']>;
  packager?: Maybe<WorkplaceCreateNestedOneWithoutPackagerInput>;
  packedAt?: Maybe<Scalars['DateTime']>;
  payedAt?: Maybe<Scalars['DateTime']>;
  pickup?: Maybe<WorkplaceCreateNestedOneWithoutPickupInput>;
  placeId?: Maybe<Scalars['String']>;
  platform?: Maybe<Platform>;
  postalCode?: Maybe<Scalars['String']>;
  preparingUntil?: Maybe<Scalars['DateTime']>;
  promoCodes?: Maybe<OrderCreatepromoCodesInput>;
  promoDiscount: Scalars['Float'];
  promoIds?: Maybe<OrderCreatepromoIdsInput>;
  promos?: Maybe<PromoCreateNestedManyWithoutOrdersInput>;
  refireDelay?: Maybe<Scalars['Int']>;
  refired?: Maybe<Scalars['Boolean']>;
  region?: Maybe<Scalars['String']>;
  sequentialNumber?: Maybe<Scalars['Int']>;
  serviceFeeConst?: Maybe<Scalars['Float']>;
  serviceFeeTax?: Maybe<Scalars['Float']>;
  serviceFeeTaxRateConst?: Maybe<Scalars['Float']>;
  serviceFeeType?: Maybe<FeeType>;
  shortId: Scalars['String'];
  source?: Maybe<Scalars['String']>;
  status: OrderStatus;
  street?: Maybe<Scalars['String']>;
  stripePaymentId?: Maybe<Scalars['String']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  subtotal: Scalars['Float'];
  tags?: Maybe<TagCreateNestedManyWithoutOrdersInput>;
  tax?: Maybe<Scalars['Float']>;
  taxFees: Scalars['Float'];
  taxRateConst?: Maybe<Scalars['Float']>;
  tilledPayments?: Maybe<TilledPaymentCreateNestedManyWithoutOrderInput>;
  tip: Scalars['Float'];
  tookanTaskId?: Maybe<Scalars['String']>;
  total: Scalars['Float'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  utensilsCount?: Maybe<Scalars['Int']>;
};

export type OrderCreateWithoutTagsInput = {
  Payment?: Maybe<PaymentCreateNestedManyWithoutOrderInput>;
  Refund?: Maybe<RefundCreateNestedManyWithoutOrderInput>;
  addressNote?: Maybe<Scalars['String']>;
  appliedPromos?: Maybe<OrderCreateappliedPromosInput>;
  braintreeTransactions?: Maybe<BraintreeTransactionCreateNestedManyWithoutOrderInput>;
  bundle?: Maybe<OrderBundleCreateNestedOneWithoutOrdersInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  chowlyOrderId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customer: UserCustomerInfoCreateNestedOneWithoutOrdersInput;
  deliverToEmail?: Maybe<Scalars['String']>;
  deliverToName?: Maybe<Scalars['String']>;
  deliverToPhone?: Maybe<Scalars['String']>;
  deliveredAt?: Maybe<Scalars['DateTime']>;
  delivery: Scalars['Float'];
  deliveryEstimation?: Maybe<Scalars['DateTime']>;
  deliveryFeeConst?: Maybe<Scalars['Float']>;
  deliveryFeeTaxRateConst?: Maybe<Scalars['Float']>;
  deliveryJobId?: Maybe<Scalars['String']>;
  deliveryOption?: Maybe<DeliveryOption>;
  deliveryTax?: Maybe<Scalars['Float']>;
  deliveryTimeInSeconds?: Maybe<Scalars['Int']>;
  expeditedAt?: Maybe<Scalars['DateTime']>;
  expo?: Maybe<WorkplaceCreateNestedOneWithoutExpoInput>;
  facility: FacilityCreateNestedOneWithoutOrdersInput;
  fee?: Maybe<Scalars['Float']>;
  formattedAddress?: Maybe<Scalars['String']>;
  fulfillmentAt: Scalars['DateTime'];
  fulfillmentBy: Scalars['DateTime'];
  giftCardCodes?: Maybe<OrderCreategiftCardCodesInput>;
  giftCardDiscounts?: Maybe<OrderCreategiftCardDiscountsInput>;
  giftCardsDiscount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  isEmployee?: Maybe<Scalars['Boolean']>;
  isOrderNow?: Maybe<Scalars['Boolean']>;
  isPaidByInvoice?: Maybe<Scalars['Boolean']>;
  isRefunded?: Maybe<Scalars['Boolean']>;
  isVip?: Maybe<Scalars['Boolean']>;
  itemTax?: Maybe<Scalars['Float']>;
  items?: Maybe<OrderItemCreateNestedManyWithoutOrderInput>;
  kdsType?: Maybe<KdsType>;
  kitchenPreparingUntil?: Maybe<Scalars['DateTime']>;
  kitchenTickets?: Maybe<KitchenTicketCreateNestedManyWithoutOrderInput>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  locality?: Maybe<Scalars['String']>;
  method?: Maybe<OrderMethod>;
  note?: Maybe<Scalars['String']>;
  numberOfGuests?: Maybe<Scalars['Int']>;
  orderEmail?: Maybe<Scalars['String']>;
  orderEvents?: Maybe<OrderEventCreateNestedManyWithoutOrderInput>;
  orderName?: Maybe<Scalars['String']>;
  orderPhoneNumber?: Maybe<Scalars['String']>;
  overwraps?: Maybe<Scalars['Int']>;
  packager?: Maybe<WorkplaceCreateNestedOneWithoutPackagerInput>;
  packedAt?: Maybe<Scalars['DateTime']>;
  payedAt?: Maybe<Scalars['DateTime']>;
  pickup?: Maybe<WorkplaceCreateNestedOneWithoutPickupInput>;
  placeId?: Maybe<Scalars['String']>;
  platform?: Maybe<Platform>;
  postalCode?: Maybe<Scalars['String']>;
  preparingUntil?: Maybe<Scalars['DateTime']>;
  promoCodes?: Maybe<OrderCreatepromoCodesInput>;
  promoDiscount: Scalars['Float'];
  promoIds?: Maybe<OrderCreatepromoIdsInput>;
  promos?: Maybe<PromoCreateNestedManyWithoutOrdersInput>;
  refireDelay?: Maybe<Scalars['Int']>;
  refired?: Maybe<Scalars['Boolean']>;
  region?: Maybe<Scalars['String']>;
  runner?: Maybe<WorkplaceCreateNestedOneWithoutRunnerInput>;
  sequentialNumber?: Maybe<Scalars['Int']>;
  serviceFeeConst?: Maybe<Scalars['Float']>;
  serviceFeeTax?: Maybe<Scalars['Float']>;
  serviceFeeTaxRateConst?: Maybe<Scalars['Float']>;
  serviceFeeType?: Maybe<FeeType>;
  shortId: Scalars['String'];
  source?: Maybe<Scalars['String']>;
  status: OrderStatus;
  street?: Maybe<Scalars['String']>;
  stripePaymentId?: Maybe<Scalars['String']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  subtotal: Scalars['Float'];
  tax?: Maybe<Scalars['Float']>;
  taxFees: Scalars['Float'];
  taxRateConst?: Maybe<Scalars['Float']>;
  tilledPayments?: Maybe<TilledPaymentCreateNestedManyWithoutOrderInput>;
  tip: Scalars['Float'];
  tookanTaskId?: Maybe<Scalars['String']>;
  total: Scalars['Float'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  utensilsCount?: Maybe<Scalars['Int']>;
};

export type OrderCreateWithoutTilledPaymentsInput = {
  Payment?: Maybe<PaymentCreateNestedManyWithoutOrderInput>;
  Refund?: Maybe<RefundCreateNestedManyWithoutOrderInput>;
  addressNote?: Maybe<Scalars['String']>;
  appliedPromos?: Maybe<OrderCreateappliedPromosInput>;
  braintreeTransactions?: Maybe<BraintreeTransactionCreateNestedManyWithoutOrderInput>;
  bundle?: Maybe<OrderBundleCreateNestedOneWithoutOrdersInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  chowlyOrderId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customer: UserCustomerInfoCreateNestedOneWithoutOrdersInput;
  deliverToEmail?: Maybe<Scalars['String']>;
  deliverToName?: Maybe<Scalars['String']>;
  deliverToPhone?: Maybe<Scalars['String']>;
  deliveredAt?: Maybe<Scalars['DateTime']>;
  delivery: Scalars['Float'];
  deliveryEstimation?: Maybe<Scalars['DateTime']>;
  deliveryFeeConst?: Maybe<Scalars['Float']>;
  deliveryFeeTaxRateConst?: Maybe<Scalars['Float']>;
  deliveryJobId?: Maybe<Scalars['String']>;
  deliveryOption?: Maybe<DeliveryOption>;
  deliveryTax?: Maybe<Scalars['Float']>;
  deliveryTimeInSeconds?: Maybe<Scalars['Int']>;
  expeditedAt?: Maybe<Scalars['DateTime']>;
  expo?: Maybe<WorkplaceCreateNestedOneWithoutExpoInput>;
  facility: FacilityCreateNestedOneWithoutOrdersInput;
  fee?: Maybe<Scalars['Float']>;
  formattedAddress?: Maybe<Scalars['String']>;
  fulfillmentAt: Scalars['DateTime'];
  fulfillmentBy: Scalars['DateTime'];
  giftCardCodes?: Maybe<OrderCreategiftCardCodesInput>;
  giftCardDiscounts?: Maybe<OrderCreategiftCardDiscountsInput>;
  giftCardsDiscount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  isEmployee?: Maybe<Scalars['Boolean']>;
  isOrderNow?: Maybe<Scalars['Boolean']>;
  isPaidByInvoice?: Maybe<Scalars['Boolean']>;
  isRefunded?: Maybe<Scalars['Boolean']>;
  isVip?: Maybe<Scalars['Boolean']>;
  itemTax?: Maybe<Scalars['Float']>;
  items?: Maybe<OrderItemCreateNestedManyWithoutOrderInput>;
  kdsType?: Maybe<KdsType>;
  kitchenPreparingUntil?: Maybe<Scalars['DateTime']>;
  kitchenTickets?: Maybe<KitchenTicketCreateNestedManyWithoutOrderInput>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  locality?: Maybe<Scalars['String']>;
  method?: Maybe<OrderMethod>;
  note?: Maybe<Scalars['String']>;
  numberOfGuests?: Maybe<Scalars['Int']>;
  orderEmail?: Maybe<Scalars['String']>;
  orderEvents?: Maybe<OrderEventCreateNestedManyWithoutOrderInput>;
  orderName?: Maybe<Scalars['String']>;
  orderPhoneNumber?: Maybe<Scalars['String']>;
  overwraps?: Maybe<Scalars['Int']>;
  packager?: Maybe<WorkplaceCreateNestedOneWithoutPackagerInput>;
  packedAt?: Maybe<Scalars['DateTime']>;
  payedAt?: Maybe<Scalars['DateTime']>;
  pickup?: Maybe<WorkplaceCreateNestedOneWithoutPickupInput>;
  placeId?: Maybe<Scalars['String']>;
  platform?: Maybe<Platform>;
  postalCode?: Maybe<Scalars['String']>;
  preparingUntil?: Maybe<Scalars['DateTime']>;
  promoCodes?: Maybe<OrderCreatepromoCodesInput>;
  promoDiscount: Scalars['Float'];
  promoIds?: Maybe<OrderCreatepromoIdsInput>;
  promos?: Maybe<PromoCreateNestedManyWithoutOrdersInput>;
  refireDelay?: Maybe<Scalars['Int']>;
  refired?: Maybe<Scalars['Boolean']>;
  region?: Maybe<Scalars['String']>;
  runner?: Maybe<WorkplaceCreateNestedOneWithoutRunnerInput>;
  sequentialNumber?: Maybe<Scalars['Int']>;
  serviceFeeConst?: Maybe<Scalars['Float']>;
  serviceFeeTax?: Maybe<Scalars['Float']>;
  serviceFeeTaxRateConst?: Maybe<Scalars['Float']>;
  serviceFeeType?: Maybe<FeeType>;
  shortId: Scalars['String'];
  source?: Maybe<Scalars['String']>;
  status: OrderStatus;
  street?: Maybe<Scalars['String']>;
  stripePaymentId?: Maybe<Scalars['String']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  subtotal: Scalars['Float'];
  tags?: Maybe<TagCreateNestedManyWithoutOrdersInput>;
  tax?: Maybe<Scalars['Float']>;
  taxFees: Scalars['Float'];
  taxRateConst?: Maybe<Scalars['Float']>;
  tip: Scalars['Float'];
  tookanTaskId?: Maybe<Scalars['String']>;
  total: Scalars['Float'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  utensilsCount?: Maybe<Scalars['Int']>;
};

export type OrderCreateappliedPromosInput = {
  set?: Maybe<Array<Scalars['Json']>>;
};

export type OrderCreategiftCardCodesInput = {
  set?: Maybe<Array<Scalars['String']>>;
};

export type OrderCreategiftCardDiscountsInput = {
  set?: Maybe<Array<Scalars['Float']>>;
};

export type OrderCreatepromoCodesInput = {
  set?: Maybe<Array<Scalars['String']>>;
};

export type OrderCreatepromoIdsInput = {
  set?: Maybe<Array<Scalars['String']>>;
};

export type OrderEvent = {
  __typename?: 'OrderEvent';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  metadata: Scalars['Json'];
  type: OrderEventType;
  updatedAt: Scalars['DateTime'];
};

export type OrderEventCreateManyOrderInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  metadata: Scalars['Json'];
  type: OrderEventType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrderEventCreateManyOrderInputEnvelope = {
  data?: Maybe<Array<OrderEventCreateManyOrderInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type OrderEventCreateNestedManyWithoutOrderInput = {
  connect?: Maybe<Array<OrderEventWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrderEventCreateOrConnectWithoutOrderInput>>;
  create?: Maybe<Array<OrderEventCreateWithoutOrderInput>>;
  createMany?: Maybe<OrderEventCreateManyOrderInputEnvelope>;
};

export type OrderEventCreateOrConnectWithoutOrderInput = {
  create: OrderEventCreateWithoutOrderInput;
  where: OrderEventWhereUniqueInput;
};

export type OrderEventCreateWithoutOrderInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  metadata: Scalars['Json'];
  type: OrderEventType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrderEventListRelationFilter = {
  every?: Maybe<OrderEventWhereInput>;
  none?: Maybe<OrderEventWhereInput>;
  some?: Maybe<OrderEventWhereInput>;
};

export type OrderEventScalarWhereInput = {
  AND?: Maybe<Array<OrderEventScalarWhereInput>>;
  NOT?: Maybe<Array<OrderEventScalarWhereInput>>;
  OR?: Maybe<Array<OrderEventScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  orderId?: Maybe<StringFilter>;
  type?: Maybe<EnumOrderEventTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export enum OrderEventType {
  Created = 'CREATED',
  ExpoRefired = 'EXPO_REFIRED',
  KitchenDelay = 'KITCHEN_DELAY',
  KitchenStatusUpdate = 'KITCHEN_STATUS_UPDATE',
  StatusUpdate = 'STATUS_UPDATE',
  WorkplaceUpdate = 'WORKPLACE_UPDATE'
}

export type OrderEventUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  metadata?: Maybe<Scalars['Json']>;
  type?: Maybe<EnumOrderEventTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrderEventUpdateManyWithWhereWithoutOrderInput = {
  data: OrderEventUpdateManyMutationInput;
  where: OrderEventScalarWhereInput;
};

export type OrderEventUpdateManyWithoutOrderInput = {
  connect?: Maybe<Array<OrderEventWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrderEventCreateOrConnectWithoutOrderInput>>;
  create?: Maybe<Array<OrderEventCreateWithoutOrderInput>>;
  createMany?: Maybe<OrderEventCreateManyOrderInputEnvelope>;
  delete?: Maybe<Array<OrderEventWhereUniqueInput>>;
  deleteMany?: Maybe<Array<OrderEventScalarWhereInput>>;
  disconnect?: Maybe<Array<OrderEventWhereUniqueInput>>;
  set?: Maybe<Array<OrderEventWhereUniqueInput>>;
  update?: Maybe<Array<OrderEventUpdateWithWhereUniqueWithoutOrderInput>>;
  updateMany?: Maybe<Array<OrderEventUpdateManyWithWhereWithoutOrderInput>>;
  upsert?: Maybe<Array<OrderEventUpsertWithWhereUniqueWithoutOrderInput>>;
};

export type OrderEventUpdateWithWhereUniqueWithoutOrderInput = {
  data: OrderEventUpdateWithoutOrderInput;
  where: OrderEventWhereUniqueInput;
};

export type OrderEventUpdateWithoutOrderInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  metadata?: Maybe<Scalars['Json']>;
  type?: Maybe<EnumOrderEventTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrderEventUpsertWithWhereUniqueWithoutOrderInput = {
  create: OrderEventCreateWithoutOrderInput;
  update: OrderEventUpdateWithoutOrderInput;
  where: OrderEventWhereUniqueInput;
};

export type OrderEventWhereInput = {
  AND?: Maybe<Array<OrderEventWhereInput>>;
  NOT?: Maybe<Array<OrderEventWhereInput>>;
  OR?: Maybe<Array<OrderEventWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  order?: Maybe<OrderWhereInput>;
  orderId?: Maybe<StringFilter>;
  type?: Maybe<EnumOrderEventTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type OrderEventWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type OrderExtraItem = {
  __typename?: 'OrderExtraItem';
  extraItem: ExtraItem;
  extraItemId: Scalars['ID'];
  quantity: Scalars['Float'];
};

export type OrderInput = {
  addressId?: Maybe<Scalars['ID']>;
  deliverToEmail?: Maybe<Scalars['String']>;
  deliverToName?: Maybe<Scalars['String']>;
  deliverToPhone?: Maybe<Scalars['String']>;
  deliveryOption?: Maybe<DeliveryOption>;
  fulfillmentAt?: Maybe<Scalars['DateTime']>;
  isOrderNow: Scalars['Boolean'];
  note?: Maybe<Scalars['String']>;
  numberOfGuests?: Maybe<Scalars['Int']>;
  orderEmail?: Maybe<Scalars['String']>;
  orderMethod: OrderMethod;
  orderName?: Maybe<Scalars['String']>;
  orderPhoneNumber?: Maybe<Scalars['String']>;
  tip?: Maybe<Scalars['Float']>;
  tipPercent?: Maybe<Scalars['Float']>;
};

export type OrderItem = {
  __typename?: 'OrderItem';
  extraItems: Array<OrderItemExtra>;
  facilityItem: Array<FacilityItem>;
  id: Scalars['String'];
  mealPackOrderItems: Array<OrderItem>;
  menuItem: KitchenMenuItem;
  menuItemId: Scalars['String'];
  name: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  order: Order;
  orderId: Scalars['String'];
  orderMealPack?: Maybe<OrderItem>;
  orderMealPackId?: Maybe<Scalars['String']>;
  price: Scalars['Float'];
  quantity: Scalars['Float'];
  type: OrderItemType;
};


export type OrderItemExtraItemsArgs = {
  after?: Maybe<OrderItemExtraWhereUniqueInput>;
  before?: Maybe<OrderItemExtraWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type OrderItemFacilityItemArgs = {
  after?: Maybe<FacilityItemWhereUniqueInput>;
  before?: Maybe<FacilityItemWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type OrderItemMealPackOrderItemsArgs = {
  after?: Maybe<OrderItemWhereUniqueInput>;
  before?: Maybe<OrderItemWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type OrderItemCreateManyMenuItemInput = {
  chowlyId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  orderId: Scalars['String'];
  orderMealPackId?: Maybe<Scalars['String']>;
  price: Scalars['Float'];
  quantity: Scalars['Float'];
  type?: Maybe<OrderItemType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrderItemCreateManyMenuItemInputEnvelope = {
  data?: Maybe<Array<OrderItemCreateManyMenuItemInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type OrderItemCreateManyOrderInput = {
  chowlyId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  menuItemId: Scalars['String'];
  name: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  orderMealPackId?: Maybe<Scalars['String']>;
  price: Scalars['Float'];
  quantity: Scalars['Float'];
  type?: Maybe<OrderItemType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrderItemCreateManyOrderInputEnvelope = {
  data?: Maybe<Array<OrderItemCreateManyOrderInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type OrderItemCreateManyOrderMealPackInput = {
  chowlyId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  menuItemId: Scalars['String'];
  name: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  orderId: Scalars['String'];
  price: Scalars['Float'];
  quantity: Scalars['Float'];
  type?: Maybe<OrderItemType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrderItemCreateManyOrderMealPackInputEnvelope = {
  data?: Maybe<Array<OrderItemCreateManyOrderMealPackInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type OrderItemCreateNestedManyWithoutMenuItemInput = {
  connect?: Maybe<Array<OrderItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrderItemCreateOrConnectWithoutMenuItemInput>>;
  create?: Maybe<Array<OrderItemCreateWithoutMenuItemInput>>;
  createMany?: Maybe<OrderItemCreateManyMenuItemInputEnvelope>;
};

export type OrderItemCreateNestedManyWithoutOrderInput = {
  connect?: Maybe<Array<OrderItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrderItemCreateOrConnectWithoutOrderInput>>;
  create?: Maybe<Array<OrderItemCreateWithoutOrderInput>>;
  createMany?: Maybe<OrderItemCreateManyOrderInputEnvelope>;
};

export type OrderItemCreateNestedManyWithoutOrderMealPackInput = {
  connect?: Maybe<Array<OrderItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrderItemCreateOrConnectWithoutOrderMealPackInput>>;
  create?: Maybe<Array<OrderItemCreateWithoutOrderMealPackInput>>;
  createMany?: Maybe<OrderItemCreateManyOrderMealPackInputEnvelope>;
};

export type OrderItemCreateNestedOneWithoutExtraItemsInput = {
  connect?: Maybe<OrderItemWhereUniqueInput>;
  connectOrCreate?: Maybe<OrderItemCreateOrConnectWithoutExtraItemsInput>;
  create?: Maybe<OrderItemCreateWithoutExtraItemsInput>;
};

export type OrderItemCreateNestedOneWithoutFacilityItemInput = {
  connect?: Maybe<OrderItemWhereUniqueInput>;
  connectOrCreate?: Maybe<OrderItemCreateOrConnectWithoutFacilityItemInput>;
  create?: Maybe<OrderItemCreateWithoutFacilityItemInput>;
};

export type OrderItemCreateNestedOneWithoutMealPackOrderItemsInput = {
  connect?: Maybe<OrderItemWhereUniqueInput>;
  connectOrCreate?: Maybe<OrderItemCreateOrConnectWithoutMealPackOrderItemsInput>;
  create?: Maybe<OrderItemCreateWithoutMealPackOrderItemsInput>;
};

export type OrderItemCreateNestedOneWithoutRefundItemInput = {
  connect?: Maybe<OrderItemWhereUniqueInput>;
  connectOrCreate?: Maybe<OrderItemCreateOrConnectWithoutRefundItemInput>;
  create?: Maybe<OrderItemCreateWithoutRefundItemInput>;
};

export type OrderItemCreateNestedOneWithoutSingleRefundItemDetailInput = {
  connect?: Maybe<OrderItemWhereUniqueInput>;
  connectOrCreate?: Maybe<OrderItemCreateOrConnectWithoutSingleRefundItemDetailInput>;
  create?: Maybe<OrderItemCreateWithoutSingleRefundItemDetailInput>;
};

export type OrderItemCreateOrConnectWithoutExtraItemsInput = {
  create: OrderItemCreateWithoutExtraItemsInput;
  where: OrderItemWhereUniqueInput;
};

export type OrderItemCreateOrConnectWithoutFacilityItemInput = {
  create: OrderItemCreateWithoutFacilityItemInput;
  where: OrderItemWhereUniqueInput;
};

export type OrderItemCreateOrConnectWithoutMealPackOrderItemsInput = {
  create: OrderItemCreateWithoutMealPackOrderItemsInput;
  where: OrderItemWhereUniqueInput;
};

export type OrderItemCreateOrConnectWithoutMenuItemInput = {
  create: OrderItemCreateWithoutMenuItemInput;
  where: OrderItemWhereUniqueInput;
};

export type OrderItemCreateOrConnectWithoutOrderInput = {
  create: OrderItemCreateWithoutOrderInput;
  where: OrderItemWhereUniqueInput;
};

export type OrderItemCreateOrConnectWithoutOrderMealPackInput = {
  create: OrderItemCreateWithoutOrderMealPackInput;
  where: OrderItemWhereUniqueInput;
};

export type OrderItemCreateOrConnectWithoutRefundItemInput = {
  create: OrderItemCreateWithoutRefundItemInput;
  where: OrderItemWhereUniqueInput;
};

export type OrderItemCreateOrConnectWithoutSingleRefundItemDetailInput = {
  create: OrderItemCreateWithoutSingleRefundItemDetailInput;
  where: OrderItemWhereUniqueInput;
};

export type OrderItemCreateWithoutExtraItemsInput = {
  RefundItem?: Maybe<RefundItemCreateNestedManyWithoutOrderItemInput>;
  SingleRefundItemDetail?: Maybe<LineItemRefundCreateNestedManyWithoutOrderItemInput>;
  chowlyId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  facilityItem?: Maybe<FacilityItemCreateNestedManyWithoutOrderItemInput>;
  id?: Maybe<Scalars['String']>;
  mealPackOrderItems?: Maybe<OrderItemCreateNestedManyWithoutOrderMealPackInput>;
  menuItem: KitchenMenuItemCreateNestedOneWithoutOrderItemsInput;
  name: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  order: OrderCreateNestedOneWithoutItemsInput;
  orderMealPack?: Maybe<OrderItemCreateNestedOneWithoutMealPackOrderItemsInput>;
  price: Scalars['Float'];
  quantity: Scalars['Float'];
  type?: Maybe<OrderItemType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrderItemCreateWithoutFacilityItemInput = {
  RefundItem?: Maybe<RefundItemCreateNestedManyWithoutOrderItemInput>;
  SingleRefundItemDetail?: Maybe<LineItemRefundCreateNestedManyWithoutOrderItemInput>;
  chowlyId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  extraItems?: Maybe<OrderItemExtraCreateNestedManyWithoutOrderItemInput>;
  id?: Maybe<Scalars['String']>;
  mealPackOrderItems?: Maybe<OrderItemCreateNestedManyWithoutOrderMealPackInput>;
  menuItem: KitchenMenuItemCreateNestedOneWithoutOrderItemsInput;
  name: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  order: OrderCreateNestedOneWithoutItemsInput;
  orderMealPack?: Maybe<OrderItemCreateNestedOneWithoutMealPackOrderItemsInput>;
  price: Scalars['Float'];
  quantity: Scalars['Float'];
  type?: Maybe<OrderItemType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrderItemCreateWithoutMealPackOrderItemsInput = {
  RefundItem?: Maybe<RefundItemCreateNestedManyWithoutOrderItemInput>;
  SingleRefundItemDetail?: Maybe<LineItemRefundCreateNestedManyWithoutOrderItemInput>;
  chowlyId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  extraItems?: Maybe<OrderItemExtraCreateNestedManyWithoutOrderItemInput>;
  facilityItem?: Maybe<FacilityItemCreateNestedManyWithoutOrderItemInput>;
  id?: Maybe<Scalars['String']>;
  menuItem: KitchenMenuItemCreateNestedOneWithoutOrderItemsInput;
  name: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  order: OrderCreateNestedOneWithoutItemsInput;
  orderMealPack?: Maybe<OrderItemCreateNestedOneWithoutMealPackOrderItemsInput>;
  price: Scalars['Float'];
  quantity: Scalars['Float'];
  type?: Maybe<OrderItemType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrderItemCreateWithoutMenuItemInput = {
  RefundItem?: Maybe<RefundItemCreateNestedManyWithoutOrderItemInput>;
  SingleRefundItemDetail?: Maybe<LineItemRefundCreateNestedManyWithoutOrderItemInput>;
  chowlyId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  extraItems?: Maybe<OrderItemExtraCreateNestedManyWithoutOrderItemInput>;
  facilityItem?: Maybe<FacilityItemCreateNestedManyWithoutOrderItemInput>;
  id?: Maybe<Scalars['String']>;
  mealPackOrderItems?: Maybe<OrderItemCreateNestedManyWithoutOrderMealPackInput>;
  name: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  order: OrderCreateNestedOneWithoutItemsInput;
  orderMealPack?: Maybe<OrderItemCreateNestedOneWithoutMealPackOrderItemsInput>;
  price: Scalars['Float'];
  quantity: Scalars['Float'];
  type?: Maybe<OrderItemType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrderItemCreateWithoutOrderInput = {
  RefundItem?: Maybe<RefundItemCreateNestedManyWithoutOrderItemInput>;
  SingleRefundItemDetail?: Maybe<LineItemRefundCreateNestedManyWithoutOrderItemInput>;
  chowlyId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  extraItems?: Maybe<OrderItemExtraCreateNestedManyWithoutOrderItemInput>;
  facilityItem?: Maybe<FacilityItemCreateNestedManyWithoutOrderItemInput>;
  id?: Maybe<Scalars['String']>;
  mealPackOrderItems?: Maybe<OrderItemCreateNestedManyWithoutOrderMealPackInput>;
  menuItem: KitchenMenuItemCreateNestedOneWithoutOrderItemsInput;
  name: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  orderMealPack?: Maybe<OrderItemCreateNestedOneWithoutMealPackOrderItemsInput>;
  price: Scalars['Float'];
  quantity: Scalars['Float'];
  type?: Maybe<OrderItemType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrderItemCreateWithoutOrderMealPackInput = {
  RefundItem?: Maybe<RefundItemCreateNestedManyWithoutOrderItemInput>;
  SingleRefundItemDetail?: Maybe<LineItemRefundCreateNestedManyWithoutOrderItemInput>;
  chowlyId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  extraItems?: Maybe<OrderItemExtraCreateNestedManyWithoutOrderItemInput>;
  facilityItem?: Maybe<FacilityItemCreateNestedManyWithoutOrderItemInput>;
  id?: Maybe<Scalars['String']>;
  mealPackOrderItems?: Maybe<OrderItemCreateNestedManyWithoutOrderMealPackInput>;
  menuItem: KitchenMenuItemCreateNestedOneWithoutOrderItemsInput;
  name: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  order: OrderCreateNestedOneWithoutItemsInput;
  price: Scalars['Float'];
  quantity: Scalars['Float'];
  type?: Maybe<OrderItemType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrderItemCreateWithoutRefundItemInput = {
  SingleRefundItemDetail?: Maybe<LineItemRefundCreateNestedManyWithoutOrderItemInput>;
  chowlyId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  extraItems?: Maybe<OrderItemExtraCreateNestedManyWithoutOrderItemInput>;
  facilityItem?: Maybe<FacilityItemCreateNestedManyWithoutOrderItemInput>;
  id?: Maybe<Scalars['String']>;
  mealPackOrderItems?: Maybe<OrderItemCreateNestedManyWithoutOrderMealPackInput>;
  menuItem: KitchenMenuItemCreateNestedOneWithoutOrderItemsInput;
  name: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  order: OrderCreateNestedOneWithoutItemsInput;
  orderMealPack?: Maybe<OrderItemCreateNestedOneWithoutMealPackOrderItemsInput>;
  price: Scalars['Float'];
  quantity: Scalars['Float'];
  type?: Maybe<OrderItemType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrderItemCreateWithoutSingleRefundItemDetailInput = {
  RefundItem?: Maybe<RefundItemCreateNestedManyWithoutOrderItemInput>;
  chowlyId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  extraItems?: Maybe<OrderItemExtraCreateNestedManyWithoutOrderItemInput>;
  facilityItem?: Maybe<FacilityItemCreateNestedManyWithoutOrderItemInput>;
  id?: Maybe<Scalars['String']>;
  mealPackOrderItems?: Maybe<OrderItemCreateNestedManyWithoutOrderMealPackInput>;
  menuItem: KitchenMenuItemCreateNestedOneWithoutOrderItemsInput;
  name: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  order: OrderCreateNestedOneWithoutItemsInput;
  orderMealPack?: Maybe<OrderItemCreateNestedOneWithoutMealPackOrderItemsInput>;
  price: Scalars['Float'];
  quantity: Scalars['Float'];
  type?: Maybe<OrderItemType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrderItemExtra = {
  __typename?: 'OrderItemExtra';
  extraItem?: Maybe<ExtraItem>;
  extraItemSortId: Scalars['Int'];
  extraSortId: Scalars['Int'];
  id: Scalars['String'];
  mealPackItemId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  orderItem: OrderItem;
  price: Scalars['Float'];
  quantity: Scalars['Int'];
};

export type OrderItemExtraCreateManyExtraItemInput = {
  categoryChowlyId?: Maybe<Scalars['String']>;
  chowlyId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  extraItemSortId?: Maybe<Scalars['Int']>;
  extraSortId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  mealPackItemId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  orderItemId: Scalars['String'];
  price: Scalars['Float'];
  quantity: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrderItemExtraCreateManyExtraItemInputEnvelope = {
  data?: Maybe<Array<OrderItemExtraCreateManyExtraItemInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type OrderItemExtraCreateManyOrderItemInput = {
  categoryChowlyId?: Maybe<Scalars['String']>;
  chowlyId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  extraItemId?: Maybe<Scalars['String']>;
  extraItemSortId?: Maybe<Scalars['Int']>;
  extraSortId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  mealPackItemId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  price: Scalars['Float'];
  quantity: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrderItemExtraCreateManyOrderItemInputEnvelope = {
  data?: Maybe<Array<OrderItemExtraCreateManyOrderItemInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type OrderItemExtraCreateNestedManyWithoutExtraItemInput = {
  connect?: Maybe<Array<OrderItemExtraWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrderItemExtraCreateOrConnectWithoutExtraItemInput>>;
  create?: Maybe<Array<OrderItemExtraCreateWithoutExtraItemInput>>;
  createMany?: Maybe<OrderItemExtraCreateManyExtraItemInputEnvelope>;
};

export type OrderItemExtraCreateNestedManyWithoutOrderItemInput = {
  connect?: Maybe<Array<OrderItemExtraWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrderItemExtraCreateOrConnectWithoutOrderItemInput>>;
  create?: Maybe<Array<OrderItemExtraCreateWithoutOrderItemInput>>;
  createMany?: Maybe<OrderItemExtraCreateManyOrderItemInputEnvelope>;
};

export type OrderItemExtraCreateOrConnectWithoutExtraItemInput = {
  create: OrderItemExtraCreateWithoutExtraItemInput;
  where: OrderItemExtraWhereUniqueInput;
};

export type OrderItemExtraCreateOrConnectWithoutOrderItemInput = {
  create: OrderItemExtraCreateWithoutOrderItemInput;
  where: OrderItemExtraWhereUniqueInput;
};

export type OrderItemExtraCreateWithoutExtraItemInput = {
  categoryChowlyId?: Maybe<Scalars['String']>;
  chowlyId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  extraItemSortId?: Maybe<Scalars['Int']>;
  extraSortId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  mealPackItemId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  orderItem: OrderItemCreateNestedOneWithoutExtraItemsInput;
  price: Scalars['Float'];
  quantity: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrderItemExtraCreateWithoutOrderItemInput = {
  categoryChowlyId?: Maybe<Scalars['String']>;
  chowlyId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  extraItem?: Maybe<ExtraItemCreateNestedOneWithoutOrderItemExtrasInput>;
  extraItemSortId?: Maybe<Scalars['Int']>;
  extraSortId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  mealPackItemId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  price: Scalars['Float'];
  quantity: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrderItemExtraListRelationFilter = {
  every?: Maybe<OrderItemExtraWhereInput>;
  none?: Maybe<OrderItemExtraWhereInput>;
  some?: Maybe<OrderItemExtraWhereInput>;
};

export type OrderItemExtraScalarWhereInput = {
  AND?: Maybe<Array<OrderItemExtraScalarWhereInput>>;
  NOT?: Maybe<Array<OrderItemExtraScalarWhereInput>>;
  OR?: Maybe<Array<OrderItemExtraScalarWhereInput>>;
  categoryChowlyId?: Maybe<StringNullableFilter>;
  chowlyId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  extraItemId?: Maybe<StringNullableFilter>;
  extraItemSortId?: Maybe<IntFilter>;
  extraSortId?: Maybe<IntFilter>;
  id?: Maybe<StringFilter>;
  mealPackItemId?: Maybe<StringNullableFilter>;
  name?: Maybe<StringFilter>;
  orderItemId?: Maybe<StringFilter>;
  price?: Maybe<FloatFilter>;
  quantity?: Maybe<IntFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type OrderItemExtraUpdateManyMutationInput = {
  categoryChowlyId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  chowlyId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  extraItemSortId?: Maybe<IntFieldUpdateOperationsInput>;
  extraSortId?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  mealPackItemId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  quantity?: Maybe<IntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrderItemExtraUpdateManyWithWhereWithoutExtraItemInput = {
  data: OrderItemExtraUpdateManyMutationInput;
  where: OrderItemExtraScalarWhereInput;
};

export type OrderItemExtraUpdateManyWithWhereWithoutOrderItemInput = {
  data: OrderItemExtraUpdateManyMutationInput;
  where: OrderItemExtraScalarWhereInput;
};

export type OrderItemExtraUpdateManyWithoutExtraItemInput = {
  connect?: Maybe<Array<OrderItemExtraWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrderItemExtraCreateOrConnectWithoutExtraItemInput>>;
  create?: Maybe<Array<OrderItemExtraCreateWithoutExtraItemInput>>;
  createMany?: Maybe<OrderItemExtraCreateManyExtraItemInputEnvelope>;
  delete?: Maybe<Array<OrderItemExtraWhereUniqueInput>>;
  deleteMany?: Maybe<Array<OrderItemExtraScalarWhereInput>>;
  disconnect?: Maybe<Array<OrderItemExtraWhereUniqueInput>>;
  set?: Maybe<Array<OrderItemExtraWhereUniqueInput>>;
  update?: Maybe<Array<OrderItemExtraUpdateWithWhereUniqueWithoutExtraItemInput>>;
  updateMany?: Maybe<Array<OrderItemExtraUpdateManyWithWhereWithoutExtraItemInput>>;
  upsert?: Maybe<Array<OrderItemExtraUpsertWithWhereUniqueWithoutExtraItemInput>>;
};

export type OrderItemExtraUpdateManyWithoutOrderItemInput = {
  connect?: Maybe<Array<OrderItemExtraWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrderItemExtraCreateOrConnectWithoutOrderItemInput>>;
  create?: Maybe<Array<OrderItemExtraCreateWithoutOrderItemInput>>;
  createMany?: Maybe<OrderItemExtraCreateManyOrderItemInputEnvelope>;
  delete?: Maybe<Array<OrderItemExtraWhereUniqueInput>>;
  deleteMany?: Maybe<Array<OrderItemExtraScalarWhereInput>>;
  disconnect?: Maybe<Array<OrderItemExtraWhereUniqueInput>>;
  set?: Maybe<Array<OrderItemExtraWhereUniqueInput>>;
  update?: Maybe<Array<OrderItemExtraUpdateWithWhereUniqueWithoutOrderItemInput>>;
  updateMany?: Maybe<Array<OrderItemExtraUpdateManyWithWhereWithoutOrderItemInput>>;
  upsert?: Maybe<Array<OrderItemExtraUpsertWithWhereUniqueWithoutOrderItemInput>>;
};

export type OrderItemExtraUpdateWithWhereUniqueWithoutExtraItemInput = {
  data: OrderItemExtraUpdateWithoutExtraItemInput;
  where: OrderItemExtraWhereUniqueInput;
};

export type OrderItemExtraUpdateWithWhereUniqueWithoutOrderItemInput = {
  data: OrderItemExtraUpdateWithoutOrderItemInput;
  where: OrderItemExtraWhereUniqueInput;
};

export type OrderItemExtraUpdateWithoutExtraItemInput = {
  categoryChowlyId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  chowlyId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  extraItemSortId?: Maybe<IntFieldUpdateOperationsInput>;
  extraSortId?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  mealPackItemId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  orderItem?: Maybe<OrderItemUpdateOneRequiredWithoutExtraItemsInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  quantity?: Maybe<IntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrderItemExtraUpdateWithoutOrderItemInput = {
  categoryChowlyId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  chowlyId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  extraItem?: Maybe<ExtraItemUpdateOneWithoutOrderItemExtrasInput>;
  extraItemSortId?: Maybe<IntFieldUpdateOperationsInput>;
  extraSortId?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  mealPackItemId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  quantity?: Maybe<IntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrderItemExtraUpsertWithWhereUniqueWithoutExtraItemInput = {
  create: OrderItemExtraCreateWithoutExtraItemInput;
  update: OrderItemExtraUpdateWithoutExtraItemInput;
  where: OrderItemExtraWhereUniqueInput;
};

export type OrderItemExtraUpsertWithWhereUniqueWithoutOrderItemInput = {
  create: OrderItemExtraCreateWithoutOrderItemInput;
  update: OrderItemExtraUpdateWithoutOrderItemInput;
  where: OrderItemExtraWhereUniqueInput;
};

export type OrderItemExtraWhereInput = {
  AND?: Maybe<Array<OrderItemExtraWhereInput>>;
  NOT?: Maybe<Array<OrderItemExtraWhereInput>>;
  OR?: Maybe<Array<OrderItemExtraWhereInput>>;
  categoryChowlyId?: Maybe<StringNullableFilter>;
  chowlyId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  extraItem?: Maybe<ExtraItemWhereInput>;
  extraItemId?: Maybe<StringNullableFilter>;
  extraItemSortId?: Maybe<IntFilter>;
  extraSortId?: Maybe<IntFilter>;
  id?: Maybe<StringFilter>;
  mealPackItemId?: Maybe<StringNullableFilter>;
  name?: Maybe<StringFilter>;
  orderItem?: Maybe<OrderItemWhereInput>;
  orderItemId?: Maybe<StringFilter>;
  price?: Maybe<FloatFilter>;
  quantity?: Maybe<IntFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type OrderItemExtraWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type OrderItemInput = {
  extraItems?: Maybe<Array<Maybe<ExtraItemInput>>>;
  productId: Scalars['ID'];
  quantity: Scalars['Float'];
};

export type OrderItemListRelationFilter = {
  every?: Maybe<OrderItemWhereInput>;
  none?: Maybe<OrderItemWhereInput>;
  some?: Maybe<OrderItemWhereInput>;
};

export type OrderItemScalarWhereInput = {
  AND?: Maybe<Array<OrderItemScalarWhereInput>>;
  NOT?: Maybe<Array<OrderItemScalarWhereInput>>;
  OR?: Maybe<Array<OrderItemScalarWhereInput>>;
  chowlyId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  menuItemId?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  note?: Maybe<StringNullableFilter>;
  orderId?: Maybe<StringFilter>;
  orderMealPackId?: Maybe<StringNullableFilter>;
  price?: Maybe<FloatFilter>;
  quantity?: Maybe<FloatFilter>;
  type?: Maybe<EnumOrderItemTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export enum OrderItemType {
  Item = 'ITEM',
  MealPack = 'MEAL_PACK',
  MealPackItem = 'MEAL_PACK_ITEM'
}

export type OrderItemUpdateManyMutationInput = {
  chowlyId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  note?: Maybe<NullableStringFieldUpdateOperationsInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  quantity?: Maybe<FloatFieldUpdateOperationsInput>;
  type?: Maybe<EnumOrderItemTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrderItemUpdateManyWithWhereWithoutMenuItemInput = {
  data: OrderItemUpdateManyMutationInput;
  where: OrderItemScalarWhereInput;
};

export type OrderItemUpdateManyWithWhereWithoutOrderInput = {
  data: OrderItemUpdateManyMutationInput;
  where: OrderItemScalarWhereInput;
};

export type OrderItemUpdateManyWithWhereWithoutOrderMealPackInput = {
  data: OrderItemUpdateManyMutationInput;
  where: OrderItemScalarWhereInput;
};

export type OrderItemUpdateManyWithoutMenuItemInput = {
  connect?: Maybe<Array<OrderItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrderItemCreateOrConnectWithoutMenuItemInput>>;
  create?: Maybe<Array<OrderItemCreateWithoutMenuItemInput>>;
  createMany?: Maybe<OrderItemCreateManyMenuItemInputEnvelope>;
  delete?: Maybe<Array<OrderItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<OrderItemScalarWhereInput>>;
  disconnect?: Maybe<Array<OrderItemWhereUniqueInput>>;
  set?: Maybe<Array<OrderItemWhereUniqueInput>>;
  update?: Maybe<Array<OrderItemUpdateWithWhereUniqueWithoutMenuItemInput>>;
  updateMany?: Maybe<Array<OrderItemUpdateManyWithWhereWithoutMenuItemInput>>;
  upsert?: Maybe<Array<OrderItemUpsertWithWhereUniqueWithoutMenuItemInput>>;
};

export type OrderItemUpdateManyWithoutOrderInput = {
  connect?: Maybe<Array<OrderItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrderItemCreateOrConnectWithoutOrderInput>>;
  create?: Maybe<Array<OrderItemCreateWithoutOrderInput>>;
  createMany?: Maybe<OrderItemCreateManyOrderInputEnvelope>;
  delete?: Maybe<Array<OrderItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<OrderItemScalarWhereInput>>;
  disconnect?: Maybe<Array<OrderItemWhereUniqueInput>>;
  set?: Maybe<Array<OrderItemWhereUniqueInput>>;
  update?: Maybe<Array<OrderItemUpdateWithWhereUniqueWithoutOrderInput>>;
  updateMany?: Maybe<Array<OrderItemUpdateManyWithWhereWithoutOrderInput>>;
  upsert?: Maybe<Array<OrderItemUpsertWithWhereUniqueWithoutOrderInput>>;
};

export type OrderItemUpdateManyWithoutOrderMealPackInput = {
  connect?: Maybe<Array<OrderItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrderItemCreateOrConnectWithoutOrderMealPackInput>>;
  create?: Maybe<Array<OrderItemCreateWithoutOrderMealPackInput>>;
  createMany?: Maybe<OrderItemCreateManyOrderMealPackInputEnvelope>;
  delete?: Maybe<Array<OrderItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<OrderItemScalarWhereInput>>;
  disconnect?: Maybe<Array<OrderItemWhereUniqueInput>>;
  set?: Maybe<Array<OrderItemWhereUniqueInput>>;
  update?: Maybe<Array<OrderItemUpdateWithWhereUniqueWithoutOrderMealPackInput>>;
  updateMany?: Maybe<Array<OrderItemUpdateManyWithWhereWithoutOrderMealPackInput>>;
  upsert?: Maybe<Array<OrderItemUpsertWithWhereUniqueWithoutOrderMealPackInput>>;
};

export type OrderItemUpdateOneRequiredWithoutExtraItemsInput = {
  connect?: Maybe<OrderItemWhereUniqueInput>;
  connectOrCreate?: Maybe<OrderItemCreateOrConnectWithoutExtraItemsInput>;
  create?: Maybe<OrderItemCreateWithoutExtraItemsInput>;
  update?: Maybe<OrderItemUpdateWithoutExtraItemsInput>;
  upsert?: Maybe<OrderItemUpsertWithoutExtraItemsInput>;
};

export type OrderItemUpdateOneRequiredWithoutFacilityItemInput = {
  connect?: Maybe<OrderItemWhereUniqueInput>;
  connectOrCreate?: Maybe<OrderItemCreateOrConnectWithoutFacilityItemInput>;
  create?: Maybe<OrderItemCreateWithoutFacilityItemInput>;
  update?: Maybe<OrderItemUpdateWithoutFacilityItemInput>;
  upsert?: Maybe<OrderItemUpsertWithoutFacilityItemInput>;
};

export type OrderItemUpdateOneRequiredWithoutRefundItemInput = {
  connect?: Maybe<OrderItemWhereUniqueInput>;
  connectOrCreate?: Maybe<OrderItemCreateOrConnectWithoutRefundItemInput>;
  create?: Maybe<OrderItemCreateWithoutRefundItemInput>;
  update?: Maybe<OrderItemUpdateWithoutRefundItemInput>;
  upsert?: Maybe<OrderItemUpsertWithoutRefundItemInput>;
};

export type OrderItemUpdateOneRequiredWithoutSingleRefundItemDetailInput = {
  connect?: Maybe<OrderItemWhereUniqueInput>;
  connectOrCreate?: Maybe<OrderItemCreateOrConnectWithoutSingleRefundItemDetailInput>;
  create?: Maybe<OrderItemCreateWithoutSingleRefundItemDetailInput>;
  update?: Maybe<OrderItemUpdateWithoutSingleRefundItemDetailInput>;
  upsert?: Maybe<OrderItemUpsertWithoutSingleRefundItemDetailInput>;
};

export type OrderItemUpdateOneWithoutMealPackOrderItemsInput = {
  connect?: Maybe<OrderItemWhereUniqueInput>;
  connectOrCreate?: Maybe<OrderItemCreateOrConnectWithoutMealPackOrderItemsInput>;
  create?: Maybe<OrderItemCreateWithoutMealPackOrderItemsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<OrderItemUpdateWithoutMealPackOrderItemsInput>;
  upsert?: Maybe<OrderItemUpsertWithoutMealPackOrderItemsInput>;
};

export type OrderItemUpdateWithWhereUniqueWithoutMenuItemInput = {
  data: OrderItemUpdateWithoutMenuItemInput;
  where: OrderItemWhereUniqueInput;
};

export type OrderItemUpdateWithWhereUniqueWithoutOrderInput = {
  data: OrderItemUpdateWithoutOrderInput;
  where: OrderItemWhereUniqueInput;
};

export type OrderItemUpdateWithWhereUniqueWithoutOrderMealPackInput = {
  data: OrderItemUpdateWithoutOrderMealPackInput;
  where: OrderItemWhereUniqueInput;
};

export type OrderItemUpdateWithoutExtraItemsInput = {
  RefundItem?: Maybe<RefundItemUpdateManyWithoutOrderItemInput>;
  SingleRefundItemDetail?: Maybe<LineItemRefundUpdateManyWithoutOrderItemInput>;
  chowlyId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  facilityItem?: Maybe<FacilityItemUpdateManyWithoutOrderItemInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  mealPackOrderItems?: Maybe<OrderItemUpdateManyWithoutOrderMealPackInput>;
  menuItem?: Maybe<KitchenMenuItemUpdateOneRequiredWithoutOrderItemsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  note?: Maybe<NullableStringFieldUpdateOperationsInput>;
  order?: Maybe<OrderUpdateOneRequiredWithoutItemsInput>;
  orderMealPack?: Maybe<OrderItemUpdateOneWithoutMealPackOrderItemsInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  quantity?: Maybe<FloatFieldUpdateOperationsInput>;
  type?: Maybe<EnumOrderItemTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrderItemUpdateWithoutFacilityItemInput = {
  RefundItem?: Maybe<RefundItemUpdateManyWithoutOrderItemInput>;
  SingleRefundItemDetail?: Maybe<LineItemRefundUpdateManyWithoutOrderItemInput>;
  chowlyId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  extraItems?: Maybe<OrderItemExtraUpdateManyWithoutOrderItemInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  mealPackOrderItems?: Maybe<OrderItemUpdateManyWithoutOrderMealPackInput>;
  menuItem?: Maybe<KitchenMenuItemUpdateOneRequiredWithoutOrderItemsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  note?: Maybe<NullableStringFieldUpdateOperationsInput>;
  order?: Maybe<OrderUpdateOneRequiredWithoutItemsInput>;
  orderMealPack?: Maybe<OrderItemUpdateOneWithoutMealPackOrderItemsInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  quantity?: Maybe<FloatFieldUpdateOperationsInput>;
  type?: Maybe<EnumOrderItemTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrderItemUpdateWithoutMealPackOrderItemsInput = {
  RefundItem?: Maybe<RefundItemUpdateManyWithoutOrderItemInput>;
  SingleRefundItemDetail?: Maybe<LineItemRefundUpdateManyWithoutOrderItemInput>;
  chowlyId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  extraItems?: Maybe<OrderItemExtraUpdateManyWithoutOrderItemInput>;
  facilityItem?: Maybe<FacilityItemUpdateManyWithoutOrderItemInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  menuItem?: Maybe<KitchenMenuItemUpdateOneRequiredWithoutOrderItemsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  note?: Maybe<NullableStringFieldUpdateOperationsInput>;
  order?: Maybe<OrderUpdateOneRequiredWithoutItemsInput>;
  orderMealPack?: Maybe<OrderItemUpdateOneWithoutMealPackOrderItemsInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  quantity?: Maybe<FloatFieldUpdateOperationsInput>;
  type?: Maybe<EnumOrderItemTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrderItemUpdateWithoutMenuItemInput = {
  RefundItem?: Maybe<RefundItemUpdateManyWithoutOrderItemInput>;
  SingleRefundItemDetail?: Maybe<LineItemRefundUpdateManyWithoutOrderItemInput>;
  chowlyId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  extraItems?: Maybe<OrderItemExtraUpdateManyWithoutOrderItemInput>;
  facilityItem?: Maybe<FacilityItemUpdateManyWithoutOrderItemInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  mealPackOrderItems?: Maybe<OrderItemUpdateManyWithoutOrderMealPackInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  note?: Maybe<NullableStringFieldUpdateOperationsInput>;
  order?: Maybe<OrderUpdateOneRequiredWithoutItemsInput>;
  orderMealPack?: Maybe<OrderItemUpdateOneWithoutMealPackOrderItemsInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  quantity?: Maybe<FloatFieldUpdateOperationsInput>;
  type?: Maybe<EnumOrderItemTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrderItemUpdateWithoutOrderInput = {
  RefundItem?: Maybe<RefundItemUpdateManyWithoutOrderItemInput>;
  SingleRefundItemDetail?: Maybe<LineItemRefundUpdateManyWithoutOrderItemInput>;
  chowlyId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  extraItems?: Maybe<OrderItemExtraUpdateManyWithoutOrderItemInput>;
  facilityItem?: Maybe<FacilityItemUpdateManyWithoutOrderItemInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  mealPackOrderItems?: Maybe<OrderItemUpdateManyWithoutOrderMealPackInput>;
  menuItem?: Maybe<KitchenMenuItemUpdateOneRequiredWithoutOrderItemsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  note?: Maybe<NullableStringFieldUpdateOperationsInput>;
  orderMealPack?: Maybe<OrderItemUpdateOneWithoutMealPackOrderItemsInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  quantity?: Maybe<FloatFieldUpdateOperationsInput>;
  type?: Maybe<EnumOrderItemTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrderItemUpdateWithoutOrderMealPackInput = {
  RefundItem?: Maybe<RefundItemUpdateManyWithoutOrderItemInput>;
  SingleRefundItemDetail?: Maybe<LineItemRefundUpdateManyWithoutOrderItemInput>;
  chowlyId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  extraItems?: Maybe<OrderItemExtraUpdateManyWithoutOrderItemInput>;
  facilityItem?: Maybe<FacilityItemUpdateManyWithoutOrderItemInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  mealPackOrderItems?: Maybe<OrderItemUpdateManyWithoutOrderMealPackInput>;
  menuItem?: Maybe<KitchenMenuItemUpdateOneRequiredWithoutOrderItemsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  note?: Maybe<NullableStringFieldUpdateOperationsInput>;
  order?: Maybe<OrderUpdateOneRequiredWithoutItemsInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  quantity?: Maybe<FloatFieldUpdateOperationsInput>;
  type?: Maybe<EnumOrderItemTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrderItemUpdateWithoutRefundItemInput = {
  SingleRefundItemDetail?: Maybe<LineItemRefundUpdateManyWithoutOrderItemInput>;
  chowlyId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  extraItems?: Maybe<OrderItemExtraUpdateManyWithoutOrderItemInput>;
  facilityItem?: Maybe<FacilityItemUpdateManyWithoutOrderItemInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  mealPackOrderItems?: Maybe<OrderItemUpdateManyWithoutOrderMealPackInput>;
  menuItem?: Maybe<KitchenMenuItemUpdateOneRequiredWithoutOrderItemsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  note?: Maybe<NullableStringFieldUpdateOperationsInput>;
  order?: Maybe<OrderUpdateOneRequiredWithoutItemsInput>;
  orderMealPack?: Maybe<OrderItemUpdateOneWithoutMealPackOrderItemsInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  quantity?: Maybe<FloatFieldUpdateOperationsInput>;
  type?: Maybe<EnumOrderItemTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrderItemUpdateWithoutSingleRefundItemDetailInput = {
  RefundItem?: Maybe<RefundItemUpdateManyWithoutOrderItemInput>;
  chowlyId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  extraItems?: Maybe<OrderItemExtraUpdateManyWithoutOrderItemInput>;
  facilityItem?: Maybe<FacilityItemUpdateManyWithoutOrderItemInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  mealPackOrderItems?: Maybe<OrderItemUpdateManyWithoutOrderMealPackInput>;
  menuItem?: Maybe<KitchenMenuItemUpdateOneRequiredWithoutOrderItemsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  note?: Maybe<NullableStringFieldUpdateOperationsInput>;
  order?: Maybe<OrderUpdateOneRequiredWithoutItemsInput>;
  orderMealPack?: Maybe<OrderItemUpdateOneWithoutMealPackOrderItemsInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  quantity?: Maybe<FloatFieldUpdateOperationsInput>;
  type?: Maybe<EnumOrderItemTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrderItemUpsertWithWhereUniqueWithoutMenuItemInput = {
  create: OrderItemCreateWithoutMenuItemInput;
  update: OrderItemUpdateWithoutMenuItemInput;
  where: OrderItemWhereUniqueInput;
};

export type OrderItemUpsertWithWhereUniqueWithoutOrderInput = {
  create: OrderItemCreateWithoutOrderInput;
  update: OrderItemUpdateWithoutOrderInput;
  where: OrderItemWhereUniqueInput;
};

export type OrderItemUpsertWithWhereUniqueWithoutOrderMealPackInput = {
  create: OrderItemCreateWithoutOrderMealPackInput;
  update: OrderItemUpdateWithoutOrderMealPackInput;
  where: OrderItemWhereUniqueInput;
};

export type OrderItemUpsertWithoutExtraItemsInput = {
  create: OrderItemCreateWithoutExtraItemsInput;
  update: OrderItemUpdateWithoutExtraItemsInput;
};

export type OrderItemUpsertWithoutFacilityItemInput = {
  create: OrderItemCreateWithoutFacilityItemInput;
  update: OrderItemUpdateWithoutFacilityItemInput;
};

export type OrderItemUpsertWithoutMealPackOrderItemsInput = {
  create: OrderItemCreateWithoutMealPackOrderItemsInput;
  update: OrderItemUpdateWithoutMealPackOrderItemsInput;
};

export type OrderItemUpsertWithoutRefundItemInput = {
  create: OrderItemCreateWithoutRefundItemInput;
  update: OrderItemUpdateWithoutRefundItemInput;
};

export type OrderItemUpsertWithoutSingleRefundItemDetailInput = {
  create: OrderItemCreateWithoutSingleRefundItemDetailInput;
  update: OrderItemUpdateWithoutSingleRefundItemDetailInput;
};

export type OrderItemWhereInput = {
  AND?: Maybe<Array<OrderItemWhereInput>>;
  NOT?: Maybe<Array<OrderItemWhereInput>>;
  OR?: Maybe<Array<OrderItemWhereInput>>;
  RefundItem?: Maybe<RefundItemListRelationFilter>;
  SingleRefundItemDetail?: Maybe<LineItemRefundListRelationFilter>;
  chowlyId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  extraItems?: Maybe<OrderItemExtraListRelationFilter>;
  facilityItem?: Maybe<FacilityItemListRelationFilter>;
  id?: Maybe<StringFilter>;
  mealPackOrderItems?: Maybe<OrderItemListRelationFilter>;
  menuItem?: Maybe<KitchenMenuItemWhereInput>;
  menuItemId?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  note?: Maybe<StringNullableFilter>;
  order?: Maybe<OrderWhereInput>;
  orderId?: Maybe<StringFilter>;
  orderMealPack?: Maybe<OrderItemWhereInput>;
  orderMealPackId?: Maybe<StringNullableFilter>;
  price?: Maybe<FloatFilter>;
  quantity?: Maybe<FloatFilter>;
  type?: Maybe<EnumOrderItemTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type OrderItemWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type OrderListRelationFilter = {
  every?: Maybe<OrderWhereInput>;
  none?: Maybe<OrderWhereInput>;
  some?: Maybe<OrderWhereInput>;
};

export enum OrderMethod {
  Delivery = 'DELIVERY',
  PickUp = 'PICK_UP'
}

export type OrderMethodOverride = {
  __typename?: 'OrderMethodOverride';
  disabledOrderMethods: Array<OrderMethod>;
  end: Scalars['DateTime'];
  id: Scalars['String'];
  isActive: Scalars['Boolean'];
  isNonStop: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  repeatOnDays: Array<Scalars['Int']>;
  start: Scalars['DateTime'];
};

export type OrderMethodOverrideCreateManyFacilityInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  disabledOrderMethods?: Maybe<OrderMethodOverrideCreateManydisabledOrderMethodsInput>;
  end: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isNonStop?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  repeatOnDays?: Maybe<OrderMethodOverrideCreateManyrepeatOnDaysInput>;
  start: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrderMethodOverrideCreateManyFacilityInputEnvelope = {
  data?: Maybe<Array<OrderMethodOverrideCreateManyFacilityInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type OrderMethodOverrideCreateManydisabledOrderMethodsInput = {
  set?: Maybe<Array<OrderMethod>>;
};

export type OrderMethodOverrideCreateManyrepeatOnDaysInput = {
  set?: Maybe<Array<Scalars['Int']>>;
};

export type OrderMethodOverrideCreateNestedManyWithoutFacilityInput = {
  connect?: Maybe<Array<OrderMethodOverrideWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrderMethodOverrideCreateOrConnectWithoutFacilityInput>>;
  create?: Maybe<Array<OrderMethodOverrideCreateWithoutFacilityInput>>;
  createMany?: Maybe<OrderMethodOverrideCreateManyFacilityInputEnvelope>;
};

export type OrderMethodOverrideCreateOrConnectWithoutFacilityInput = {
  create: OrderMethodOverrideCreateWithoutFacilityInput;
  where: OrderMethodOverrideWhereUniqueInput;
};

export type OrderMethodOverrideCreateWithoutFacilityInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  disabledOrderMethods?: Maybe<OrderMethodOverrideCreatedisabledOrderMethodsInput>;
  end: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isNonStop?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  repeatOnDays?: Maybe<OrderMethodOverrideCreaterepeatOnDaysInput>;
  start: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrderMethodOverrideCreatedisabledOrderMethodsInput = {
  set?: Maybe<Array<OrderMethod>>;
};

export type OrderMethodOverrideCreaterepeatOnDaysInput = {
  set?: Maybe<Array<Scalars['Int']>>;
};

export enum OrderMethodOverrideErrors {
  Overlap = 'OVERLAP',
  WrongDatetime = 'WRONG_DATETIME'
}

export type OrderMethodOverrideListRelationFilter = {
  every?: Maybe<OrderMethodOverrideWhereInput>;
  none?: Maybe<OrderMethodOverrideWhereInput>;
  some?: Maybe<OrderMethodOverrideWhereInput>;
};

export type OrderMethodOverrideScalarWhereInput = {
  AND?: Maybe<Array<OrderMethodOverrideScalarWhereInput>>;
  NOT?: Maybe<Array<OrderMethodOverrideScalarWhereInput>>;
  OR?: Maybe<Array<OrderMethodOverrideScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  disabledOrderMethods?: Maybe<EnumOrderMethodNullableListFilter>;
  end?: Maybe<DateTimeFilter>;
  facilityId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  isActive?: Maybe<BoolFilter>;
  isNonStop?: Maybe<BoolFilter>;
  name?: Maybe<StringNullableFilter>;
  repeatOnDays?: Maybe<IntNullableListFilter>;
  start?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type OrderMethodOverrideUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  disabledOrderMethods?: Maybe<OrderMethodOverrideUpdatedisabledOrderMethodsInput>;
  end?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isActive?: Maybe<BoolFieldUpdateOperationsInput>;
  isNonStop?: Maybe<BoolFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  repeatOnDays?: Maybe<OrderMethodOverrideUpdaterepeatOnDaysInput>;
  start?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrderMethodOverrideUpdateManyWithWhereWithoutFacilityInput = {
  data: OrderMethodOverrideUpdateManyMutationInput;
  where: OrderMethodOverrideScalarWhereInput;
};

export type OrderMethodOverrideUpdateManyWithoutFacilityInput = {
  connect?: Maybe<Array<OrderMethodOverrideWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrderMethodOverrideCreateOrConnectWithoutFacilityInput>>;
  create?: Maybe<Array<OrderMethodOverrideCreateWithoutFacilityInput>>;
  createMany?: Maybe<OrderMethodOverrideCreateManyFacilityInputEnvelope>;
  delete?: Maybe<Array<OrderMethodOverrideWhereUniqueInput>>;
  deleteMany?: Maybe<Array<OrderMethodOverrideScalarWhereInput>>;
  disconnect?: Maybe<Array<OrderMethodOverrideWhereUniqueInput>>;
  set?: Maybe<Array<OrderMethodOverrideWhereUniqueInput>>;
  update?: Maybe<Array<OrderMethodOverrideUpdateWithWhereUniqueWithoutFacilityInput>>;
  updateMany?: Maybe<Array<OrderMethodOverrideUpdateManyWithWhereWithoutFacilityInput>>;
  upsert?: Maybe<Array<OrderMethodOverrideUpsertWithWhereUniqueWithoutFacilityInput>>;
};

export type OrderMethodOverrideUpdateWithWhereUniqueWithoutFacilityInput = {
  data: OrderMethodOverrideUpdateWithoutFacilityInput;
  where: OrderMethodOverrideWhereUniqueInput;
};

export type OrderMethodOverrideUpdateWithoutFacilityInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  disabledOrderMethods?: Maybe<OrderMethodOverrideUpdatedisabledOrderMethodsInput>;
  end?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isActive?: Maybe<BoolFieldUpdateOperationsInput>;
  isNonStop?: Maybe<BoolFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  repeatOnDays?: Maybe<OrderMethodOverrideUpdaterepeatOnDaysInput>;
  start?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrderMethodOverrideUpdatedisabledOrderMethodsInput = {
  push?: Maybe<OrderMethod>;
  set?: Maybe<Array<OrderMethod>>;
};

export type OrderMethodOverrideUpdaterepeatOnDaysInput = {
  push?: Maybe<Scalars['Int']>;
  set?: Maybe<Array<Scalars['Int']>>;
};

export type OrderMethodOverrideUpsertInput = {
  disabledOrderMethods: Array<OrderMethod>;
  /** Contains both end day (Date) and hours (Time) */
  end: Scalars['DateTime'];
  id?: Maybe<Scalars['ID']>;
  isActive: Scalars['Boolean'];
  isNonStop: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  /** [0 - Sun, 1 - Mon, 2 - Tue, 3 - Wen, 4 - Thu, 5 - Fri, 6 - Sat] (based on the https://www.w3schools.com/jsref/jsref_getday.asp) */
  repeatOnDays: Array<Scalars['Int']>;
  /** Contains both start day (Date) and hours (Time) */
  start: Scalars['DateTime'];
};

export type OrderMethodOverrideUpsertWithWhereUniqueWithoutFacilityInput = {
  create: OrderMethodOverrideCreateWithoutFacilityInput;
  update: OrderMethodOverrideUpdateWithoutFacilityInput;
  where: OrderMethodOverrideWhereUniqueInput;
};

export type OrderMethodOverrideWhereInput = {
  AND?: Maybe<Array<OrderMethodOverrideWhereInput>>;
  NOT?: Maybe<Array<OrderMethodOverrideWhereInput>>;
  OR?: Maybe<Array<OrderMethodOverrideWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  disabledOrderMethods?: Maybe<EnumOrderMethodNullableListFilter>;
  end?: Maybe<DateTimeFilter>;
  facility?: Maybe<FacilityWhereInput>;
  facilityId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  isActive?: Maybe<BoolFilter>;
  isNonStop?: Maybe<BoolFilter>;
  name?: Maybe<StringNullableFilter>;
  repeatOnDays?: Maybe<IntNullableListFilter>;
  start?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type OrderMethodOverrideWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type OrderOverwrapsInput = {
  orderId: Scalars['ID'];
  overwraps: Scalars['Int'];
};

export type OrderRefund = {
  __typename?: 'OrderRefund';
  calculations: OrderRefundCalculations;
  createdAt: Scalars['DateTime'];
  items: Array<RefundItem>;
  reason: RefundReason;
  type: RefundType;
};

export type OrderRefundCalculations = {
  __typename?: 'OrderRefundCalculations';
  creditCardRefund: Scalars['Float'];
  deliveryFee: Scalars['Float'];
  giftCardRefund: Scalars['Float'];
  gratuity: Scalars['Float'];
  serviceFee: Scalars['Float'];
  subtotal: Scalars['Float'];
  tax: Scalars['Float'];
  total: Scalars['Float'];
};

export type OrderRefundPreview = {
  __typename?: 'OrderRefundPreview';
  calculations: OrderRefundCalculations;
  createdAt: Scalars['DateTime'];
  items: Array<OrderRefundPreviewItem>;
  reason: RefundReason;
  type: RefundType;
};

export type OrderRefundPreviewItem = {
  __typename?: 'OrderRefundPreviewItem';
  name: Scalars['String'];
  orderItemId: Scalars['ID'];
  price: Scalars['Float'];
  quantity: Scalars['Int'];
};

export type OrderScalarWhereInput = {
  AND?: Maybe<Array<OrderScalarWhereInput>>;
  NOT?: Maybe<Array<OrderScalarWhereInput>>;
  OR?: Maybe<Array<OrderScalarWhereInput>>;
  addressNote?: Maybe<StringNullableFilter>;
  appliedPromos?: Maybe<JsonNullableListFilter>;
  bundleId?: Maybe<StringNullableFilter>;
  canceledAt?: Maybe<DateTimeNullableFilter>;
  chowlyOrderId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  customerId?: Maybe<StringFilter>;
  deliverToEmail?: Maybe<StringNullableFilter>;
  deliverToName?: Maybe<StringNullableFilter>;
  deliverToPhone?: Maybe<StringNullableFilter>;
  deliveredAt?: Maybe<DateTimeNullableFilter>;
  delivery?: Maybe<FloatFilter>;
  deliveryEstimation?: Maybe<DateTimeNullableFilter>;
  deliveryFeeConst?: Maybe<FloatNullableFilter>;
  deliveryFeeTaxRateConst?: Maybe<FloatNullableFilter>;
  deliveryJobId?: Maybe<StringNullableFilter>;
  deliveryOption?: Maybe<EnumDeliveryOptionNullableFilter>;
  deliveryTax?: Maybe<FloatNullableFilter>;
  deliveryTimeInSeconds?: Maybe<IntNullableFilter>;
  expeditedAt?: Maybe<DateTimeNullableFilter>;
  expoId?: Maybe<StringNullableFilter>;
  facilityId?: Maybe<StringFilter>;
  fee?: Maybe<FloatNullableFilter>;
  formattedAddress?: Maybe<StringNullableFilter>;
  fulfillmentAt?: Maybe<DateTimeFilter>;
  fulfillmentBy?: Maybe<DateTimeFilter>;
  giftCardCodes?: Maybe<StringNullableListFilter>;
  giftCardDiscounts?: Maybe<FloatNullableListFilter>;
  giftCardsDiscount?: Maybe<FloatFilter>;
  id?: Maybe<StringFilter>;
  isEmployee?: Maybe<BoolFilter>;
  isOrderNow?: Maybe<BoolFilter>;
  isPaidByInvoice?: Maybe<BoolFilter>;
  isRefunded?: Maybe<BoolFilter>;
  isVip?: Maybe<BoolFilter>;
  itemTax?: Maybe<FloatNullableFilter>;
  kdsType?: Maybe<EnumKdsTypeFilter>;
  kitchenPreparingUntil?: Maybe<DateTimeNullableFilter>;
  lat?: Maybe<FloatNullableFilter>;
  lng?: Maybe<FloatNullableFilter>;
  locality?: Maybe<StringNullableFilter>;
  method?: Maybe<EnumOrderMethodFilter>;
  note?: Maybe<StringNullableFilter>;
  numberOfGuests?: Maybe<IntNullableFilter>;
  orderEmail?: Maybe<StringNullableFilter>;
  orderName?: Maybe<StringNullableFilter>;
  orderPhoneNumber?: Maybe<StringNullableFilter>;
  overwraps?: Maybe<IntNullableFilter>;
  packagerId?: Maybe<StringNullableFilter>;
  packedAt?: Maybe<DateTimeNullableFilter>;
  payedAt?: Maybe<DateTimeNullableFilter>;
  pickupId?: Maybe<StringNullableFilter>;
  placeId?: Maybe<StringNullableFilter>;
  platform?: Maybe<EnumPlatformFilter>;
  postalCode?: Maybe<StringNullableFilter>;
  preparingUntil?: Maybe<DateTimeNullableFilter>;
  promoCodes?: Maybe<StringNullableListFilter>;
  promoDiscount?: Maybe<FloatFilter>;
  promoIds?: Maybe<StringNullableListFilter>;
  refireDelay?: Maybe<IntFilter>;
  refired?: Maybe<BoolNullableFilter>;
  region?: Maybe<StringNullableFilter>;
  runnerId?: Maybe<StringNullableFilter>;
  sequentialNumber?: Maybe<IntFilter>;
  serviceFeeConst?: Maybe<FloatNullableFilter>;
  serviceFeeTax?: Maybe<FloatNullableFilter>;
  serviceFeeTaxRateConst?: Maybe<FloatNullableFilter>;
  serviceFeeType?: Maybe<EnumFeeTypeNullableFilter>;
  shortId?: Maybe<StringFilter>;
  source?: Maybe<StringNullableFilter>;
  status?: Maybe<EnumOrderStatusFilter>;
  street?: Maybe<StringNullableFilter>;
  stripePaymentId?: Maybe<StringNullableFilter>;
  submittedAt?: Maybe<DateTimeFilter>;
  subtotal?: Maybe<FloatFilter>;
  tax?: Maybe<FloatNullableFilter>;
  taxFees?: Maybe<FloatFilter>;
  taxRateConst?: Maybe<FloatNullableFilter>;
  tip?: Maybe<FloatFilter>;
  tookanTaskId?: Maybe<StringNullableFilter>;
  total?: Maybe<FloatFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  utensilsCount?: Maybe<IntFilter>;
};

export type OrderStatistics = {
  __typename?: 'OrderStatistics';
  expo: Scalars['Int'];
  packaging: Scalars['Int'];
};

export enum OrderStatus {
  Arrived = 'ARRIVED',
  Assigning = 'ASSIGNING',
  Canceled = 'CANCELED',
  Complete = 'COMPLETE',
  Delivery = 'DELIVERY',
  ExpoUndone = 'EXPO_UNDONE',
  New = 'NEW',
  Packing = 'PACKING',
  Pending = 'PENDING',
  Preparing = 'PREPARING',
  QaPass = 'QA_PASS',
  Ready = 'READY',
  Runner = 'RUNNER'
}

export type OrderStatusInput = {
  orderId: Scalars['ID'];
  status: OrderStatus;
};

export type OrderSubscription = {
  __typename?: 'OrderSubscription';
  data: Order;
  operation: SubscriptionOperation;
};

export type OrderUpdateManyMutationInput = {
  addressNote?: Maybe<NullableStringFieldUpdateOperationsInput>;
  appliedPromos?: Maybe<OrderUpdateappliedPromosInput>;
  canceledAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  chowlyOrderId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deliverToEmail?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deliverToName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deliverToPhone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deliveredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  delivery?: Maybe<FloatFieldUpdateOperationsInput>;
  deliveryEstimation?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  deliveryFeeConst?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  deliveryFeeTaxRateConst?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  deliveryJobId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deliveryOption?: Maybe<NullableEnumDeliveryOptionFieldUpdateOperationsInput>;
  deliveryTax?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  deliveryTimeInSeconds?: Maybe<NullableIntFieldUpdateOperationsInput>;
  expeditedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  fee?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  formattedAddress?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fulfillmentAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  fulfillmentBy?: Maybe<DateTimeFieldUpdateOperationsInput>;
  giftCardCodes?: Maybe<OrderUpdategiftCardCodesInput>;
  giftCardDiscounts?: Maybe<OrderUpdategiftCardDiscountsInput>;
  giftCardsDiscount?: Maybe<FloatFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isEmployee?: Maybe<BoolFieldUpdateOperationsInput>;
  isOrderNow?: Maybe<BoolFieldUpdateOperationsInput>;
  isPaidByInvoice?: Maybe<BoolFieldUpdateOperationsInput>;
  isRefunded?: Maybe<BoolFieldUpdateOperationsInput>;
  isVip?: Maybe<BoolFieldUpdateOperationsInput>;
  itemTax?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  kdsType?: Maybe<EnumKdsTypeFieldUpdateOperationsInput>;
  kitchenPreparingUntil?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  lat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  lng?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  locality?: Maybe<NullableStringFieldUpdateOperationsInput>;
  method?: Maybe<EnumOrderMethodFieldUpdateOperationsInput>;
  note?: Maybe<NullableStringFieldUpdateOperationsInput>;
  numberOfGuests?: Maybe<NullableIntFieldUpdateOperationsInput>;
  orderEmail?: Maybe<NullableStringFieldUpdateOperationsInput>;
  orderName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  orderPhoneNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  overwraps?: Maybe<NullableIntFieldUpdateOperationsInput>;
  packedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  payedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  placeId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  platform?: Maybe<EnumPlatformFieldUpdateOperationsInput>;
  postalCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  preparingUntil?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  promoCodes?: Maybe<OrderUpdatepromoCodesInput>;
  promoDiscount?: Maybe<FloatFieldUpdateOperationsInput>;
  promoIds?: Maybe<OrderUpdatepromoIdsInput>;
  refireDelay?: Maybe<IntFieldUpdateOperationsInput>;
  refired?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  region?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sequentialNumber?: Maybe<IntFieldUpdateOperationsInput>;
  serviceFeeConst?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  serviceFeeTax?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  serviceFeeTaxRateConst?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  serviceFeeType?: Maybe<NullableEnumFeeTypeFieldUpdateOperationsInput>;
  shortId?: Maybe<StringFieldUpdateOperationsInput>;
  source?: Maybe<NullableStringFieldUpdateOperationsInput>;
  status?: Maybe<EnumOrderStatusFieldUpdateOperationsInput>;
  street?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripePaymentId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  submittedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  subtotal?: Maybe<FloatFieldUpdateOperationsInput>;
  tax?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  taxFees?: Maybe<FloatFieldUpdateOperationsInput>;
  taxRateConst?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  tip?: Maybe<FloatFieldUpdateOperationsInput>;
  tookanTaskId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  total?: Maybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  utensilsCount?: Maybe<IntFieldUpdateOperationsInput>;
};

export type OrderUpdateManyWithWhereWithoutCustomerInput = {
  data: OrderUpdateManyMutationInput;
  where: OrderScalarWhereInput;
};

export type OrderUpdateManyWithWhereWithoutExpoInput = {
  data: OrderUpdateManyMutationInput;
  where: OrderScalarWhereInput;
};

export type OrderUpdateManyWithWhereWithoutFacilityInput = {
  data: OrderUpdateManyMutationInput;
  where: OrderScalarWhereInput;
};

export type OrderUpdateManyWithWhereWithoutPackagerInput = {
  data: OrderUpdateManyMutationInput;
  where: OrderScalarWhereInput;
};

export type OrderUpdateManyWithWhereWithoutPickupInput = {
  data: OrderUpdateManyMutationInput;
  where: OrderScalarWhereInput;
};

export type OrderUpdateManyWithWhereWithoutPromosInput = {
  data: OrderUpdateManyMutationInput;
  where: OrderScalarWhereInput;
};

export type OrderUpdateManyWithWhereWithoutRunnerInput = {
  data: OrderUpdateManyMutationInput;
  where: OrderScalarWhereInput;
};

export type OrderUpdateManyWithWhereWithoutTagsInput = {
  data: OrderUpdateManyMutationInput;
  where: OrderScalarWhereInput;
};

export type OrderUpdateManyWithoutCustomerInput = {
  connect?: Maybe<Array<OrderWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrderCreateOrConnectWithoutCustomerInput>>;
  create?: Maybe<Array<OrderCreateWithoutCustomerInput>>;
  createMany?: Maybe<OrderCreateManyCustomerInputEnvelope>;
  delete?: Maybe<Array<OrderWhereUniqueInput>>;
  deleteMany?: Maybe<Array<OrderScalarWhereInput>>;
  disconnect?: Maybe<Array<OrderWhereUniqueInput>>;
  set?: Maybe<Array<OrderWhereUniqueInput>>;
  update?: Maybe<Array<OrderUpdateWithWhereUniqueWithoutCustomerInput>>;
  updateMany?: Maybe<Array<OrderUpdateManyWithWhereWithoutCustomerInput>>;
  upsert?: Maybe<Array<OrderUpsertWithWhereUniqueWithoutCustomerInput>>;
};

export type OrderUpdateManyWithoutExpoInput = {
  connect?: Maybe<Array<OrderWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrderCreateOrConnectWithoutExpoInput>>;
  create?: Maybe<Array<OrderCreateWithoutExpoInput>>;
  createMany?: Maybe<OrderCreateManyExpoInputEnvelope>;
  delete?: Maybe<Array<OrderWhereUniqueInput>>;
  deleteMany?: Maybe<Array<OrderScalarWhereInput>>;
  disconnect?: Maybe<Array<OrderWhereUniqueInput>>;
  set?: Maybe<Array<OrderWhereUniqueInput>>;
  update?: Maybe<Array<OrderUpdateWithWhereUniqueWithoutExpoInput>>;
  updateMany?: Maybe<Array<OrderUpdateManyWithWhereWithoutExpoInput>>;
  upsert?: Maybe<Array<OrderUpsertWithWhereUniqueWithoutExpoInput>>;
};

export type OrderUpdateManyWithoutFacilityInput = {
  connect?: Maybe<Array<OrderWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrderCreateOrConnectWithoutFacilityInput>>;
  create?: Maybe<Array<OrderCreateWithoutFacilityInput>>;
  createMany?: Maybe<OrderCreateManyFacilityInputEnvelope>;
  delete?: Maybe<Array<OrderWhereUniqueInput>>;
  deleteMany?: Maybe<Array<OrderScalarWhereInput>>;
  disconnect?: Maybe<Array<OrderWhereUniqueInput>>;
  set?: Maybe<Array<OrderWhereUniqueInput>>;
  update?: Maybe<Array<OrderUpdateWithWhereUniqueWithoutFacilityInput>>;
  updateMany?: Maybe<Array<OrderUpdateManyWithWhereWithoutFacilityInput>>;
  upsert?: Maybe<Array<OrderUpsertWithWhereUniqueWithoutFacilityInput>>;
};

export type OrderUpdateManyWithoutPackagerInput = {
  connect?: Maybe<Array<OrderWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrderCreateOrConnectWithoutPackagerInput>>;
  create?: Maybe<Array<OrderCreateWithoutPackagerInput>>;
  createMany?: Maybe<OrderCreateManyPackagerInputEnvelope>;
  delete?: Maybe<Array<OrderWhereUniqueInput>>;
  deleteMany?: Maybe<Array<OrderScalarWhereInput>>;
  disconnect?: Maybe<Array<OrderWhereUniqueInput>>;
  set?: Maybe<Array<OrderWhereUniqueInput>>;
  update?: Maybe<Array<OrderUpdateWithWhereUniqueWithoutPackagerInput>>;
  updateMany?: Maybe<Array<OrderUpdateManyWithWhereWithoutPackagerInput>>;
  upsert?: Maybe<Array<OrderUpsertWithWhereUniqueWithoutPackagerInput>>;
};

export type OrderUpdateManyWithoutPickupInput = {
  connect?: Maybe<Array<OrderWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrderCreateOrConnectWithoutPickupInput>>;
  create?: Maybe<Array<OrderCreateWithoutPickupInput>>;
  createMany?: Maybe<OrderCreateManyPickupInputEnvelope>;
  delete?: Maybe<Array<OrderWhereUniqueInput>>;
  deleteMany?: Maybe<Array<OrderScalarWhereInput>>;
  disconnect?: Maybe<Array<OrderWhereUniqueInput>>;
  set?: Maybe<Array<OrderWhereUniqueInput>>;
  update?: Maybe<Array<OrderUpdateWithWhereUniqueWithoutPickupInput>>;
  updateMany?: Maybe<Array<OrderUpdateManyWithWhereWithoutPickupInput>>;
  upsert?: Maybe<Array<OrderUpsertWithWhereUniqueWithoutPickupInput>>;
};

export type OrderUpdateManyWithoutPromosInput = {
  connect?: Maybe<Array<OrderWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrderCreateOrConnectWithoutPromosInput>>;
  create?: Maybe<Array<OrderCreateWithoutPromosInput>>;
  delete?: Maybe<Array<OrderWhereUniqueInput>>;
  deleteMany?: Maybe<Array<OrderScalarWhereInput>>;
  disconnect?: Maybe<Array<OrderWhereUniqueInput>>;
  set?: Maybe<Array<OrderWhereUniqueInput>>;
  update?: Maybe<Array<OrderUpdateWithWhereUniqueWithoutPromosInput>>;
  updateMany?: Maybe<Array<OrderUpdateManyWithWhereWithoutPromosInput>>;
  upsert?: Maybe<Array<OrderUpsertWithWhereUniqueWithoutPromosInput>>;
};

export type OrderUpdateManyWithoutRunnerInput = {
  connect?: Maybe<Array<OrderWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrderCreateOrConnectWithoutRunnerInput>>;
  create?: Maybe<Array<OrderCreateWithoutRunnerInput>>;
  createMany?: Maybe<OrderCreateManyRunnerInputEnvelope>;
  delete?: Maybe<Array<OrderWhereUniqueInput>>;
  deleteMany?: Maybe<Array<OrderScalarWhereInput>>;
  disconnect?: Maybe<Array<OrderWhereUniqueInput>>;
  set?: Maybe<Array<OrderWhereUniqueInput>>;
  update?: Maybe<Array<OrderUpdateWithWhereUniqueWithoutRunnerInput>>;
  updateMany?: Maybe<Array<OrderUpdateManyWithWhereWithoutRunnerInput>>;
  upsert?: Maybe<Array<OrderUpsertWithWhereUniqueWithoutRunnerInput>>;
};

export type OrderUpdateManyWithoutTagsInput = {
  connect?: Maybe<Array<OrderWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrderCreateOrConnectWithoutTagsInput>>;
  create?: Maybe<Array<OrderCreateWithoutTagsInput>>;
  delete?: Maybe<Array<OrderWhereUniqueInput>>;
  deleteMany?: Maybe<Array<OrderScalarWhereInput>>;
  disconnect?: Maybe<Array<OrderWhereUniqueInput>>;
  set?: Maybe<Array<OrderWhereUniqueInput>>;
  update?: Maybe<Array<OrderUpdateWithWhereUniqueWithoutTagsInput>>;
  updateMany?: Maybe<Array<OrderUpdateManyWithWhereWithoutTagsInput>>;
  upsert?: Maybe<Array<OrderUpsertWithWhereUniqueWithoutTagsInput>>;
};

export type OrderUpdateOneRequiredWithoutItemsInput = {
  connect?: Maybe<OrderWhereUniqueInput>;
  connectOrCreate?: Maybe<OrderCreateOrConnectWithoutItemsInput>;
  create?: Maybe<OrderCreateWithoutItemsInput>;
  update?: Maybe<OrderUpdateWithoutItemsInput>;
  upsert?: Maybe<OrderUpsertWithoutItemsInput>;
};

export type OrderUpdateOneRequiredWithoutKitchenTicketsInput = {
  connect?: Maybe<OrderWhereUniqueInput>;
  connectOrCreate?: Maybe<OrderCreateOrConnectWithoutKitchenTicketsInput>;
  create?: Maybe<OrderCreateWithoutKitchenTicketsInput>;
  update?: Maybe<OrderUpdateWithoutKitchenTicketsInput>;
  upsert?: Maybe<OrderUpsertWithoutKitchenTicketsInput>;
};

export type OrderUpdateOneRequiredWithoutRefundInput = {
  connect?: Maybe<OrderWhereUniqueInput>;
  connectOrCreate?: Maybe<OrderCreateOrConnectWithoutRefundInput>;
  create?: Maybe<OrderCreateWithoutRefundInput>;
  update?: Maybe<OrderUpdateWithoutRefundInput>;
  upsert?: Maybe<OrderUpsertWithoutRefundInput>;
};

export type OrderUpdateOneRequiredWithoutTilledPaymentsInput = {
  connect?: Maybe<OrderWhereUniqueInput>;
  connectOrCreate?: Maybe<OrderCreateOrConnectWithoutTilledPaymentsInput>;
  create?: Maybe<OrderCreateWithoutTilledPaymentsInput>;
  update?: Maybe<OrderUpdateWithoutTilledPaymentsInput>;
  upsert?: Maybe<OrderUpsertWithoutTilledPaymentsInput>;
};

export type OrderUpdateOneWithoutPaymentInput = {
  connect?: Maybe<OrderWhereUniqueInput>;
  connectOrCreate?: Maybe<OrderCreateOrConnectWithoutPaymentInput>;
  create?: Maybe<OrderCreateWithoutPaymentInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<OrderUpdateWithoutPaymentInput>;
  upsert?: Maybe<OrderUpsertWithoutPaymentInput>;
};

export type OrderUpdateWithWhereUniqueWithoutCustomerInput = {
  data: OrderUpdateWithoutCustomerInput;
  where: OrderWhereUniqueInput;
};

export type OrderUpdateWithWhereUniqueWithoutExpoInput = {
  data: OrderUpdateWithoutExpoInput;
  where: OrderWhereUniqueInput;
};

export type OrderUpdateWithWhereUniqueWithoutFacilityInput = {
  data: OrderUpdateWithoutFacilityInput;
  where: OrderWhereUniqueInput;
};

export type OrderUpdateWithWhereUniqueWithoutPackagerInput = {
  data: OrderUpdateWithoutPackagerInput;
  where: OrderWhereUniqueInput;
};

export type OrderUpdateWithWhereUniqueWithoutPickupInput = {
  data: OrderUpdateWithoutPickupInput;
  where: OrderWhereUniqueInput;
};

export type OrderUpdateWithWhereUniqueWithoutPromosInput = {
  data: OrderUpdateWithoutPromosInput;
  where: OrderWhereUniqueInput;
};

export type OrderUpdateWithWhereUniqueWithoutRunnerInput = {
  data: OrderUpdateWithoutRunnerInput;
  where: OrderWhereUniqueInput;
};

export type OrderUpdateWithWhereUniqueWithoutTagsInput = {
  data: OrderUpdateWithoutTagsInput;
  where: OrderWhereUniqueInput;
};

export type OrderUpdateWithoutCustomerInput = {
  Payment?: Maybe<PaymentUpdateManyWithoutOrderInput>;
  Refund?: Maybe<RefundUpdateManyWithoutOrderInput>;
  addressNote?: Maybe<NullableStringFieldUpdateOperationsInput>;
  appliedPromos?: Maybe<OrderUpdateappliedPromosInput>;
  braintreeTransactions?: Maybe<BraintreeTransactionUpdateManyWithoutOrderInput>;
  bundle?: Maybe<OrderBundleUpdateOneWithoutOrdersInput>;
  canceledAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  chowlyOrderId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deliverToEmail?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deliverToName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deliverToPhone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deliveredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  delivery?: Maybe<FloatFieldUpdateOperationsInput>;
  deliveryEstimation?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  deliveryFeeConst?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  deliveryFeeTaxRateConst?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  deliveryJobId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deliveryOption?: Maybe<NullableEnumDeliveryOptionFieldUpdateOperationsInput>;
  deliveryTax?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  deliveryTimeInSeconds?: Maybe<NullableIntFieldUpdateOperationsInput>;
  expeditedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  expo?: Maybe<WorkplaceUpdateOneWithoutExpoInput>;
  facility?: Maybe<FacilityUpdateOneRequiredWithoutOrdersInput>;
  fee?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  formattedAddress?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fulfillmentAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  fulfillmentBy?: Maybe<DateTimeFieldUpdateOperationsInput>;
  giftCardCodes?: Maybe<OrderUpdategiftCardCodesInput>;
  giftCardDiscounts?: Maybe<OrderUpdategiftCardDiscountsInput>;
  giftCardsDiscount?: Maybe<FloatFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isEmployee?: Maybe<BoolFieldUpdateOperationsInput>;
  isOrderNow?: Maybe<BoolFieldUpdateOperationsInput>;
  isPaidByInvoice?: Maybe<BoolFieldUpdateOperationsInput>;
  isRefunded?: Maybe<BoolFieldUpdateOperationsInput>;
  isVip?: Maybe<BoolFieldUpdateOperationsInput>;
  itemTax?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  items?: Maybe<OrderItemUpdateManyWithoutOrderInput>;
  kdsType?: Maybe<EnumKdsTypeFieldUpdateOperationsInput>;
  kitchenPreparingUntil?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  kitchenTickets?: Maybe<KitchenTicketUpdateManyWithoutOrderInput>;
  lat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  lng?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  locality?: Maybe<NullableStringFieldUpdateOperationsInput>;
  method?: Maybe<EnumOrderMethodFieldUpdateOperationsInput>;
  note?: Maybe<NullableStringFieldUpdateOperationsInput>;
  numberOfGuests?: Maybe<NullableIntFieldUpdateOperationsInput>;
  orderEmail?: Maybe<NullableStringFieldUpdateOperationsInput>;
  orderEvents?: Maybe<OrderEventUpdateManyWithoutOrderInput>;
  orderName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  orderPhoneNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  overwraps?: Maybe<NullableIntFieldUpdateOperationsInput>;
  packager?: Maybe<WorkplaceUpdateOneWithoutPackagerInput>;
  packedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  payedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  pickup?: Maybe<WorkplaceUpdateOneWithoutPickupInput>;
  placeId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  platform?: Maybe<EnumPlatformFieldUpdateOperationsInput>;
  postalCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  preparingUntil?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  promoCodes?: Maybe<OrderUpdatepromoCodesInput>;
  promoDiscount?: Maybe<FloatFieldUpdateOperationsInput>;
  promoIds?: Maybe<OrderUpdatepromoIdsInput>;
  promos?: Maybe<PromoUpdateManyWithoutOrdersInput>;
  refireDelay?: Maybe<IntFieldUpdateOperationsInput>;
  refired?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  region?: Maybe<NullableStringFieldUpdateOperationsInput>;
  runner?: Maybe<WorkplaceUpdateOneWithoutRunnerInput>;
  sequentialNumber?: Maybe<IntFieldUpdateOperationsInput>;
  serviceFeeConst?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  serviceFeeTax?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  serviceFeeTaxRateConst?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  serviceFeeType?: Maybe<NullableEnumFeeTypeFieldUpdateOperationsInput>;
  shortId?: Maybe<StringFieldUpdateOperationsInput>;
  source?: Maybe<NullableStringFieldUpdateOperationsInput>;
  status?: Maybe<EnumOrderStatusFieldUpdateOperationsInput>;
  street?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripePaymentId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  submittedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  subtotal?: Maybe<FloatFieldUpdateOperationsInput>;
  tags?: Maybe<TagUpdateManyWithoutOrdersInput>;
  tax?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  taxFees?: Maybe<FloatFieldUpdateOperationsInput>;
  taxRateConst?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  tilledPayments?: Maybe<TilledPaymentUpdateManyWithoutOrderInput>;
  tip?: Maybe<FloatFieldUpdateOperationsInput>;
  tookanTaskId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  total?: Maybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  utensilsCount?: Maybe<IntFieldUpdateOperationsInput>;
};

export type OrderUpdateWithoutExpoInput = {
  Payment?: Maybe<PaymentUpdateManyWithoutOrderInput>;
  Refund?: Maybe<RefundUpdateManyWithoutOrderInput>;
  addressNote?: Maybe<NullableStringFieldUpdateOperationsInput>;
  appliedPromos?: Maybe<OrderUpdateappliedPromosInput>;
  braintreeTransactions?: Maybe<BraintreeTransactionUpdateManyWithoutOrderInput>;
  bundle?: Maybe<OrderBundleUpdateOneWithoutOrdersInput>;
  canceledAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  chowlyOrderId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customer?: Maybe<UserCustomerInfoUpdateOneRequiredWithoutOrdersInput>;
  deliverToEmail?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deliverToName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deliverToPhone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deliveredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  delivery?: Maybe<FloatFieldUpdateOperationsInput>;
  deliveryEstimation?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  deliveryFeeConst?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  deliveryFeeTaxRateConst?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  deliveryJobId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deliveryOption?: Maybe<NullableEnumDeliveryOptionFieldUpdateOperationsInput>;
  deliveryTax?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  deliveryTimeInSeconds?: Maybe<NullableIntFieldUpdateOperationsInput>;
  expeditedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  facility?: Maybe<FacilityUpdateOneRequiredWithoutOrdersInput>;
  fee?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  formattedAddress?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fulfillmentAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  fulfillmentBy?: Maybe<DateTimeFieldUpdateOperationsInput>;
  giftCardCodes?: Maybe<OrderUpdategiftCardCodesInput>;
  giftCardDiscounts?: Maybe<OrderUpdategiftCardDiscountsInput>;
  giftCardsDiscount?: Maybe<FloatFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isEmployee?: Maybe<BoolFieldUpdateOperationsInput>;
  isOrderNow?: Maybe<BoolFieldUpdateOperationsInput>;
  isPaidByInvoice?: Maybe<BoolFieldUpdateOperationsInput>;
  isRefunded?: Maybe<BoolFieldUpdateOperationsInput>;
  isVip?: Maybe<BoolFieldUpdateOperationsInput>;
  itemTax?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  items?: Maybe<OrderItemUpdateManyWithoutOrderInput>;
  kdsType?: Maybe<EnumKdsTypeFieldUpdateOperationsInput>;
  kitchenPreparingUntil?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  kitchenTickets?: Maybe<KitchenTicketUpdateManyWithoutOrderInput>;
  lat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  lng?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  locality?: Maybe<NullableStringFieldUpdateOperationsInput>;
  method?: Maybe<EnumOrderMethodFieldUpdateOperationsInput>;
  note?: Maybe<NullableStringFieldUpdateOperationsInput>;
  numberOfGuests?: Maybe<NullableIntFieldUpdateOperationsInput>;
  orderEmail?: Maybe<NullableStringFieldUpdateOperationsInput>;
  orderEvents?: Maybe<OrderEventUpdateManyWithoutOrderInput>;
  orderName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  orderPhoneNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  overwraps?: Maybe<NullableIntFieldUpdateOperationsInput>;
  packager?: Maybe<WorkplaceUpdateOneWithoutPackagerInput>;
  packedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  payedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  pickup?: Maybe<WorkplaceUpdateOneWithoutPickupInput>;
  placeId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  platform?: Maybe<EnumPlatformFieldUpdateOperationsInput>;
  postalCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  preparingUntil?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  promoCodes?: Maybe<OrderUpdatepromoCodesInput>;
  promoDiscount?: Maybe<FloatFieldUpdateOperationsInput>;
  promoIds?: Maybe<OrderUpdatepromoIdsInput>;
  promos?: Maybe<PromoUpdateManyWithoutOrdersInput>;
  refireDelay?: Maybe<IntFieldUpdateOperationsInput>;
  refired?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  region?: Maybe<NullableStringFieldUpdateOperationsInput>;
  runner?: Maybe<WorkplaceUpdateOneWithoutRunnerInput>;
  sequentialNumber?: Maybe<IntFieldUpdateOperationsInput>;
  serviceFeeConst?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  serviceFeeTax?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  serviceFeeTaxRateConst?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  serviceFeeType?: Maybe<NullableEnumFeeTypeFieldUpdateOperationsInput>;
  shortId?: Maybe<StringFieldUpdateOperationsInput>;
  source?: Maybe<NullableStringFieldUpdateOperationsInput>;
  status?: Maybe<EnumOrderStatusFieldUpdateOperationsInput>;
  street?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripePaymentId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  submittedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  subtotal?: Maybe<FloatFieldUpdateOperationsInput>;
  tags?: Maybe<TagUpdateManyWithoutOrdersInput>;
  tax?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  taxFees?: Maybe<FloatFieldUpdateOperationsInput>;
  taxRateConst?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  tilledPayments?: Maybe<TilledPaymentUpdateManyWithoutOrderInput>;
  tip?: Maybe<FloatFieldUpdateOperationsInput>;
  tookanTaskId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  total?: Maybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  utensilsCount?: Maybe<IntFieldUpdateOperationsInput>;
};

export type OrderUpdateWithoutFacilityInput = {
  Payment?: Maybe<PaymentUpdateManyWithoutOrderInput>;
  Refund?: Maybe<RefundUpdateManyWithoutOrderInput>;
  addressNote?: Maybe<NullableStringFieldUpdateOperationsInput>;
  appliedPromos?: Maybe<OrderUpdateappliedPromosInput>;
  braintreeTransactions?: Maybe<BraintreeTransactionUpdateManyWithoutOrderInput>;
  bundle?: Maybe<OrderBundleUpdateOneWithoutOrdersInput>;
  canceledAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  chowlyOrderId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customer?: Maybe<UserCustomerInfoUpdateOneRequiredWithoutOrdersInput>;
  deliverToEmail?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deliverToName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deliverToPhone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deliveredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  delivery?: Maybe<FloatFieldUpdateOperationsInput>;
  deliveryEstimation?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  deliveryFeeConst?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  deliveryFeeTaxRateConst?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  deliveryJobId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deliveryOption?: Maybe<NullableEnumDeliveryOptionFieldUpdateOperationsInput>;
  deliveryTax?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  deliveryTimeInSeconds?: Maybe<NullableIntFieldUpdateOperationsInput>;
  expeditedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  expo?: Maybe<WorkplaceUpdateOneWithoutExpoInput>;
  fee?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  formattedAddress?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fulfillmentAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  fulfillmentBy?: Maybe<DateTimeFieldUpdateOperationsInput>;
  giftCardCodes?: Maybe<OrderUpdategiftCardCodesInput>;
  giftCardDiscounts?: Maybe<OrderUpdategiftCardDiscountsInput>;
  giftCardsDiscount?: Maybe<FloatFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isEmployee?: Maybe<BoolFieldUpdateOperationsInput>;
  isOrderNow?: Maybe<BoolFieldUpdateOperationsInput>;
  isPaidByInvoice?: Maybe<BoolFieldUpdateOperationsInput>;
  isRefunded?: Maybe<BoolFieldUpdateOperationsInput>;
  isVip?: Maybe<BoolFieldUpdateOperationsInput>;
  itemTax?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  items?: Maybe<OrderItemUpdateManyWithoutOrderInput>;
  kdsType?: Maybe<EnumKdsTypeFieldUpdateOperationsInput>;
  kitchenPreparingUntil?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  kitchenTickets?: Maybe<KitchenTicketUpdateManyWithoutOrderInput>;
  lat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  lng?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  locality?: Maybe<NullableStringFieldUpdateOperationsInput>;
  method?: Maybe<EnumOrderMethodFieldUpdateOperationsInput>;
  note?: Maybe<NullableStringFieldUpdateOperationsInput>;
  numberOfGuests?: Maybe<NullableIntFieldUpdateOperationsInput>;
  orderEmail?: Maybe<NullableStringFieldUpdateOperationsInput>;
  orderEvents?: Maybe<OrderEventUpdateManyWithoutOrderInput>;
  orderName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  orderPhoneNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  overwraps?: Maybe<NullableIntFieldUpdateOperationsInput>;
  packager?: Maybe<WorkplaceUpdateOneWithoutPackagerInput>;
  packedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  payedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  pickup?: Maybe<WorkplaceUpdateOneWithoutPickupInput>;
  placeId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  platform?: Maybe<EnumPlatformFieldUpdateOperationsInput>;
  postalCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  preparingUntil?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  promoCodes?: Maybe<OrderUpdatepromoCodesInput>;
  promoDiscount?: Maybe<FloatFieldUpdateOperationsInput>;
  promoIds?: Maybe<OrderUpdatepromoIdsInput>;
  promos?: Maybe<PromoUpdateManyWithoutOrdersInput>;
  refireDelay?: Maybe<IntFieldUpdateOperationsInput>;
  refired?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  region?: Maybe<NullableStringFieldUpdateOperationsInput>;
  runner?: Maybe<WorkplaceUpdateOneWithoutRunnerInput>;
  sequentialNumber?: Maybe<IntFieldUpdateOperationsInput>;
  serviceFeeConst?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  serviceFeeTax?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  serviceFeeTaxRateConst?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  serviceFeeType?: Maybe<NullableEnumFeeTypeFieldUpdateOperationsInput>;
  shortId?: Maybe<StringFieldUpdateOperationsInput>;
  source?: Maybe<NullableStringFieldUpdateOperationsInput>;
  status?: Maybe<EnumOrderStatusFieldUpdateOperationsInput>;
  street?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripePaymentId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  submittedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  subtotal?: Maybe<FloatFieldUpdateOperationsInput>;
  tags?: Maybe<TagUpdateManyWithoutOrdersInput>;
  tax?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  taxFees?: Maybe<FloatFieldUpdateOperationsInput>;
  taxRateConst?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  tilledPayments?: Maybe<TilledPaymentUpdateManyWithoutOrderInput>;
  tip?: Maybe<FloatFieldUpdateOperationsInput>;
  tookanTaskId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  total?: Maybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  utensilsCount?: Maybe<IntFieldUpdateOperationsInput>;
};

export type OrderUpdateWithoutItemsInput = {
  Payment?: Maybe<PaymentUpdateManyWithoutOrderInput>;
  Refund?: Maybe<RefundUpdateManyWithoutOrderInput>;
  addressNote?: Maybe<NullableStringFieldUpdateOperationsInput>;
  appliedPromos?: Maybe<OrderUpdateappliedPromosInput>;
  braintreeTransactions?: Maybe<BraintreeTransactionUpdateManyWithoutOrderInput>;
  bundle?: Maybe<OrderBundleUpdateOneWithoutOrdersInput>;
  canceledAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  chowlyOrderId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customer?: Maybe<UserCustomerInfoUpdateOneRequiredWithoutOrdersInput>;
  deliverToEmail?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deliverToName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deliverToPhone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deliveredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  delivery?: Maybe<FloatFieldUpdateOperationsInput>;
  deliveryEstimation?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  deliveryFeeConst?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  deliveryFeeTaxRateConst?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  deliveryJobId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deliveryOption?: Maybe<NullableEnumDeliveryOptionFieldUpdateOperationsInput>;
  deliveryTax?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  deliveryTimeInSeconds?: Maybe<NullableIntFieldUpdateOperationsInput>;
  expeditedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  expo?: Maybe<WorkplaceUpdateOneWithoutExpoInput>;
  facility?: Maybe<FacilityUpdateOneRequiredWithoutOrdersInput>;
  fee?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  formattedAddress?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fulfillmentAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  fulfillmentBy?: Maybe<DateTimeFieldUpdateOperationsInput>;
  giftCardCodes?: Maybe<OrderUpdategiftCardCodesInput>;
  giftCardDiscounts?: Maybe<OrderUpdategiftCardDiscountsInput>;
  giftCardsDiscount?: Maybe<FloatFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isEmployee?: Maybe<BoolFieldUpdateOperationsInput>;
  isOrderNow?: Maybe<BoolFieldUpdateOperationsInput>;
  isPaidByInvoice?: Maybe<BoolFieldUpdateOperationsInput>;
  isRefunded?: Maybe<BoolFieldUpdateOperationsInput>;
  isVip?: Maybe<BoolFieldUpdateOperationsInput>;
  itemTax?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  kdsType?: Maybe<EnumKdsTypeFieldUpdateOperationsInput>;
  kitchenPreparingUntil?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  kitchenTickets?: Maybe<KitchenTicketUpdateManyWithoutOrderInput>;
  lat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  lng?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  locality?: Maybe<NullableStringFieldUpdateOperationsInput>;
  method?: Maybe<EnumOrderMethodFieldUpdateOperationsInput>;
  note?: Maybe<NullableStringFieldUpdateOperationsInput>;
  numberOfGuests?: Maybe<NullableIntFieldUpdateOperationsInput>;
  orderEmail?: Maybe<NullableStringFieldUpdateOperationsInput>;
  orderEvents?: Maybe<OrderEventUpdateManyWithoutOrderInput>;
  orderName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  orderPhoneNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  overwraps?: Maybe<NullableIntFieldUpdateOperationsInput>;
  packager?: Maybe<WorkplaceUpdateOneWithoutPackagerInput>;
  packedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  payedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  pickup?: Maybe<WorkplaceUpdateOneWithoutPickupInput>;
  placeId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  platform?: Maybe<EnumPlatformFieldUpdateOperationsInput>;
  postalCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  preparingUntil?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  promoCodes?: Maybe<OrderUpdatepromoCodesInput>;
  promoDiscount?: Maybe<FloatFieldUpdateOperationsInput>;
  promoIds?: Maybe<OrderUpdatepromoIdsInput>;
  promos?: Maybe<PromoUpdateManyWithoutOrdersInput>;
  refireDelay?: Maybe<IntFieldUpdateOperationsInput>;
  refired?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  region?: Maybe<NullableStringFieldUpdateOperationsInput>;
  runner?: Maybe<WorkplaceUpdateOneWithoutRunnerInput>;
  sequentialNumber?: Maybe<IntFieldUpdateOperationsInput>;
  serviceFeeConst?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  serviceFeeTax?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  serviceFeeTaxRateConst?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  serviceFeeType?: Maybe<NullableEnumFeeTypeFieldUpdateOperationsInput>;
  shortId?: Maybe<StringFieldUpdateOperationsInput>;
  source?: Maybe<NullableStringFieldUpdateOperationsInput>;
  status?: Maybe<EnumOrderStatusFieldUpdateOperationsInput>;
  street?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripePaymentId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  submittedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  subtotal?: Maybe<FloatFieldUpdateOperationsInput>;
  tags?: Maybe<TagUpdateManyWithoutOrdersInput>;
  tax?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  taxFees?: Maybe<FloatFieldUpdateOperationsInput>;
  taxRateConst?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  tilledPayments?: Maybe<TilledPaymentUpdateManyWithoutOrderInput>;
  tip?: Maybe<FloatFieldUpdateOperationsInput>;
  tookanTaskId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  total?: Maybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  utensilsCount?: Maybe<IntFieldUpdateOperationsInput>;
};

export type OrderUpdateWithoutKitchenTicketsInput = {
  Payment?: Maybe<PaymentUpdateManyWithoutOrderInput>;
  Refund?: Maybe<RefundUpdateManyWithoutOrderInput>;
  addressNote?: Maybe<NullableStringFieldUpdateOperationsInput>;
  appliedPromos?: Maybe<OrderUpdateappliedPromosInput>;
  braintreeTransactions?: Maybe<BraintreeTransactionUpdateManyWithoutOrderInput>;
  bundle?: Maybe<OrderBundleUpdateOneWithoutOrdersInput>;
  canceledAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  chowlyOrderId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customer?: Maybe<UserCustomerInfoUpdateOneRequiredWithoutOrdersInput>;
  deliverToEmail?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deliverToName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deliverToPhone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deliveredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  delivery?: Maybe<FloatFieldUpdateOperationsInput>;
  deliveryEstimation?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  deliveryFeeConst?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  deliveryFeeTaxRateConst?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  deliveryJobId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deliveryOption?: Maybe<NullableEnumDeliveryOptionFieldUpdateOperationsInput>;
  deliveryTax?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  deliveryTimeInSeconds?: Maybe<NullableIntFieldUpdateOperationsInput>;
  expeditedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  expo?: Maybe<WorkplaceUpdateOneWithoutExpoInput>;
  facility?: Maybe<FacilityUpdateOneRequiredWithoutOrdersInput>;
  fee?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  formattedAddress?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fulfillmentAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  fulfillmentBy?: Maybe<DateTimeFieldUpdateOperationsInput>;
  giftCardCodes?: Maybe<OrderUpdategiftCardCodesInput>;
  giftCardDiscounts?: Maybe<OrderUpdategiftCardDiscountsInput>;
  giftCardsDiscount?: Maybe<FloatFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isEmployee?: Maybe<BoolFieldUpdateOperationsInput>;
  isOrderNow?: Maybe<BoolFieldUpdateOperationsInput>;
  isPaidByInvoice?: Maybe<BoolFieldUpdateOperationsInput>;
  isRefunded?: Maybe<BoolFieldUpdateOperationsInput>;
  isVip?: Maybe<BoolFieldUpdateOperationsInput>;
  itemTax?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  items?: Maybe<OrderItemUpdateManyWithoutOrderInput>;
  kdsType?: Maybe<EnumKdsTypeFieldUpdateOperationsInput>;
  kitchenPreparingUntil?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  lat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  lng?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  locality?: Maybe<NullableStringFieldUpdateOperationsInput>;
  method?: Maybe<EnumOrderMethodFieldUpdateOperationsInput>;
  note?: Maybe<NullableStringFieldUpdateOperationsInput>;
  numberOfGuests?: Maybe<NullableIntFieldUpdateOperationsInput>;
  orderEmail?: Maybe<NullableStringFieldUpdateOperationsInput>;
  orderEvents?: Maybe<OrderEventUpdateManyWithoutOrderInput>;
  orderName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  orderPhoneNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  overwraps?: Maybe<NullableIntFieldUpdateOperationsInput>;
  packager?: Maybe<WorkplaceUpdateOneWithoutPackagerInput>;
  packedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  payedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  pickup?: Maybe<WorkplaceUpdateOneWithoutPickupInput>;
  placeId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  platform?: Maybe<EnumPlatformFieldUpdateOperationsInput>;
  postalCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  preparingUntil?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  promoCodes?: Maybe<OrderUpdatepromoCodesInput>;
  promoDiscount?: Maybe<FloatFieldUpdateOperationsInput>;
  promoIds?: Maybe<OrderUpdatepromoIdsInput>;
  promos?: Maybe<PromoUpdateManyWithoutOrdersInput>;
  refireDelay?: Maybe<IntFieldUpdateOperationsInput>;
  refired?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  region?: Maybe<NullableStringFieldUpdateOperationsInput>;
  runner?: Maybe<WorkplaceUpdateOneWithoutRunnerInput>;
  sequentialNumber?: Maybe<IntFieldUpdateOperationsInput>;
  serviceFeeConst?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  serviceFeeTax?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  serviceFeeTaxRateConst?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  serviceFeeType?: Maybe<NullableEnumFeeTypeFieldUpdateOperationsInput>;
  shortId?: Maybe<StringFieldUpdateOperationsInput>;
  source?: Maybe<NullableStringFieldUpdateOperationsInput>;
  status?: Maybe<EnumOrderStatusFieldUpdateOperationsInput>;
  street?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripePaymentId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  submittedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  subtotal?: Maybe<FloatFieldUpdateOperationsInput>;
  tags?: Maybe<TagUpdateManyWithoutOrdersInput>;
  tax?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  taxFees?: Maybe<FloatFieldUpdateOperationsInput>;
  taxRateConst?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  tilledPayments?: Maybe<TilledPaymentUpdateManyWithoutOrderInput>;
  tip?: Maybe<FloatFieldUpdateOperationsInput>;
  tookanTaskId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  total?: Maybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  utensilsCount?: Maybe<IntFieldUpdateOperationsInput>;
};

export type OrderUpdateWithoutPackagerInput = {
  Payment?: Maybe<PaymentUpdateManyWithoutOrderInput>;
  Refund?: Maybe<RefundUpdateManyWithoutOrderInput>;
  addressNote?: Maybe<NullableStringFieldUpdateOperationsInput>;
  appliedPromos?: Maybe<OrderUpdateappliedPromosInput>;
  braintreeTransactions?: Maybe<BraintreeTransactionUpdateManyWithoutOrderInput>;
  bundle?: Maybe<OrderBundleUpdateOneWithoutOrdersInput>;
  canceledAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  chowlyOrderId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customer?: Maybe<UserCustomerInfoUpdateOneRequiredWithoutOrdersInput>;
  deliverToEmail?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deliverToName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deliverToPhone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deliveredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  delivery?: Maybe<FloatFieldUpdateOperationsInput>;
  deliveryEstimation?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  deliveryFeeConst?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  deliveryFeeTaxRateConst?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  deliveryJobId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deliveryOption?: Maybe<NullableEnumDeliveryOptionFieldUpdateOperationsInput>;
  deliveryTax?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  deliveryTimeInSeconds?: Maybe<NullableIntFieldUpdateOperationsInput>;
  expeditedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  expo?: Maybe<WorkplaceUpdateOneWithoutExpoInput>;
  facility?: Maybe<FacilityUpdateOneRequiredWithoutOrdersInput>;
  fee?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  formattedAddress?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fulfillmentAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  fulfillmentBy?: Maybe<DateTimeFieldUpdateOperationsInput>;
  giftCardCodes?: Maybe<OrderUpdategiftCardCodesInput>;
  giftCardDiscounts?: Maybe<OrderUpdategiftCardDiscountsInput>;
  giftCardsDiscount?: Maybe<FloatFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isEmployee?: Maybe<BoolFieldUpdateOperationsInput>;
  isOrderNow?: Maybe<BoolFieldUpdateOperationsInput>;
  isPaidByInvoice?: Maybe<BoolFieldUpdateOperationsInput>;
  isRefunded?: Maybe<BoolFieldUpdateOperationsInput>;
  isVip?: Maybe<BoolFieldUpdateOperationsInput>;
  itemTax?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  items?: Maybe<OrderItemUpdateManyWithoutOrderInput>;
  kdsType?: Maybe<EnumKdsTypeFieldUpdateOperationsInput>;
  kitchenPreparingUntil?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  kitchenTickets?: Maybe<KitchenTicketUpdateManyWithoutOrderInput>;
  lat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  lng?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  locality?: Maybe<NullableStringFieldUpdateOperationsInput>;
  method?: Maybe<EnumOrderMethodFieldUpdateOperationsInput>;
  note?: Maybe<NullableStringFieldUpdateOperationsInput>;
  numberOfGuests?: Maybe<NullableIntFieldUpdateOperationsInput>;
  orderEmail?: Maybe<NullableStringFieldUpdateOperationsInput>;
  orderEvents?: Maybe<OrderEventUpdateManyWithoutOrderInput>;
  orderName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  orderPhoneNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  overwraps?: Maybe<NullableIntFieldUpdateOperationsInput>;
  packedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  payedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  pickup?: Maybe<WorkplaceUpdateOneWithoutPickupInput>;
  placeId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  platform?: Maybe<EnumPlatformFieldUpdateOperationsInput>;
  postalCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  preparingUntil?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  promoCodes?: Maybe<OrderUpdatepromoCodesInput>;
  promoDiscount?: Maybe<FloatFieldUpdateOperationsInput>;
  promoIds?: Maybe<OrderUpdatepromoIdsInput>;
  promos?: Maybe<PromoUpdateManyWithoutOrdersInput>;
  refireDelay?: Maybe<IntFieldUpdateOperationsInput>;
  refired?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  region?: Maybe<NullableStringFieldUpdateOperationsInput>;
  runner?: Maybe<WorkplaceUpdateOneWithoutRunnerInput>;
  sequentialNumber?: Maybe<IntFieldUpdateOperationsInput>;
  serviceFeeConst?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  serviceFeeTax?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  serviceFeeTaxRateConst?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  serviceFeeType?: Maybe<NullableEnumFeeTypeFieldUpdateOperationsInput>;
  shortId?: Maybe<StringFieldUpdateOperationsInput>;
  source?: Maybe<NullableStringFieldUpdateOperationsInput>;
  status?: Maybe<EnumOrderStatusFieldUpdateOperationsInput>;
  street?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripePaymentId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  submittedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  subtotal?: Maybe<FloatFieldUpdateOperationsInput>;
  tags?: Maybe<TagUpdateManyWithoutOrdersInput>;
  tax?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  taxFees?: Maybe<FloatFieldUpdateOperationsInput>;
  taxRateConst?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  tilledPayments?: Maybe<TilledPaymentUpdateManyWithoutOrderInput>;
  tip?: Maybe<FloatFieldUpdateOperationsInput>;
  tookanTaskId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  total?: Maybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  utensilsCount?: Maybe<IntFieldUpdateOperationsInput>;
};

export type OrderUpdateWithoutPaymentInput = {
  Refund?: Maybe<RefundUpdateManyWithoutOrderInput>;
  addressNote?: Maybe<NullableStringFieldUpdateOperationsInput>;
  appliedPromos?: Maybe<OrderUpdateappliedPromosInput>;
  braintreeTransactions?: Maybe<BraintreeTransactionUpdateManyWithoutOrderInput>;
  bundle?: Maybe<OrderBundleUpdateOneWithoutOrdersInput>;
  canceledAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  chowlyOrderId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customer?: Maybe<UserCustomerInfoUpdateOneRequiredWithoutOrdersInput>;
  deliverToEmail?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deliverToName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deliverToPhone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deliveredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  delivery?: Maybe<FloatFieldUpdateOperationsInput>;
  deliveryEstimation?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  deliveryFeeConst?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  deliveryFeeTaxRateConst?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  deliveryJobId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deliveryOption?: Maybe<NullableEnumDeliveryOptionFieldUpdateOperationsInput>;
  deliveryTax?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  deliveryTimeInSeconds?: Maybe<NullableIntFieldUpdateOperationsInput>;
  expeditedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  expo?: Maybe<WorkplaceUpdateOneWithoutExpoInput>;
  facility?: Maybe<FacilityUpdateOneRequiredWithoutOrdersInput>;
  fee?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  formattedAddress?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fulfillmentAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  fulfillmentBy?: Maybe<DateTimeFieldUpdateOperationsInput>;
  giftCardCodes?: Maybe<OrderUpdategiftCardCodesInput>;
  giftCardDiscounts?: Maybe<OrderUpdategiftCardDiscountsInput>;
  giftCardsDiscount?: Maybe<FloatFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isEmployee?: Maybe<BoolFieldUpdateOperationsInput>;
  isOrderNow?: Maybe<BoolFieldUpdateOperationsInput>;
  isPaidByInvoice?: Maybe<BoolFieldUpdateOperationsInput>;
  isRefunded?: Maybe<BoolFieldUpdateOperationsInput>;
  isVip?: Maybe<BoolFieldUpdateOperationsInput>;
  itemTax?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  items?: Maybe<OrderItemUpdateManyWithoutOrderInput>;
  kdsType?: Maybe<EnumKdsTypeFieldUpdateOperationsInput>;
  kitchenPreparingUntil?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  kitchenTickets?: Maybe<KitchenTicketUpdateManyWithoutOrderInput>;
  lat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  lng?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  locality?: Maybe<NullableStringFieldUpdateOperationsInput>;
  method?: Maybe<EnumOrderMethodFieldUpdateOperationsInput>;
  note?: Maybe<NullableStringFieldUpdateOperationsInput>;
  numberOfGuests?: Maybe<NullableIntFieldUpdateOperationsInput>;
  orderEmail?: Maybe<NullableStringFieldUpdateOperationsInput>;
  orderEvents?: Maybe<OrderEventUpdateManyWithoutOrderInput>;
  orderName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  orderPhoneNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  overwraps?: Maybe<NullableIntFieldUpdateOperationsInput>;
  packager?: Maybe<WorkplaceUpdateOneWithoutPackagerInput>;
  packedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  payedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  pickup?: Maybe<WorkplaceUpdateOneWithoutPickupInput>;
  placeId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  platform?: Maybe<EnumPlatformFieldUpdateOperationsInput>;
  postalCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  preparingUntil?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  promoCodes?: Maybe<OrderUpdatepromoCodesInput>;
  promoDiscount?: Maybe<FloatFieldUpdateOperationsInput>;
  promoIds?: Maybe<OrderUpdatepromoIdsInput>;
  promos?: Maybe<PromoUpdateManyWithoutOrdersInput>;
  refireDelay?: Maybe<IntFieldUpdateOperationsInput>;
  refired?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  region?: Maybe<NullableStringFieldUpdateOperationsInput>;
  runner?: Maybe<WorkplaceUpdateOneWithoutRunnerInput>;
  sequentialNumber?: Maybe<IntFieldUpdateOperationsInput>;
  serviceFeeConst?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  serviceFeeTax?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  serviceFeeTaxRateConst?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  serviceFeeType?: Maybe<NullableEnumFeeTypeFieldUpdateOperationsInput>;
  shortId?: Maybe<StringFieldUpdateOperationsInput>;
  source?: Maybe<NullableStringFieldUpdateOperationsInput>;
  status?: Maybe<EnumOrderStatusFieldUpdateOperationsInput>;
  street?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripePaymentId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  submittedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  subtotal?: Maybe<FloatFieldUpdateOperationsInput>;
  tags?: Maybe<TagUpdateManyWithoutOrdersInput>;
  tax?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  taxFees?: Maybe<FloatFieldUpdateOperationsInput>;
  taxRateConst?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  tilledPayments?: Maybe<TilledPaymentUpdateManyWithoutOrderInput>;
  tip?: Maybe<FloatFieldUpdateOperationsInput>;
  tookanTaskId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  total?: Maybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  utensilsCount?: Maybe<IntFieldUpdateOperationsInput>;
};

export type OrderUpdateWithoutPickupInput = {
  Payment?: Maybe<PaymentUpdateManyWithoutOrderInput>;
  Refund?: Maybe<RefundUpdateManyWithoutOrderInput>;
  addressNote?: Maybe<NullableStringFieldUpdateOperationsInput>;
  appliedPromos?: Maybe<OrderUpdateappliedPromosInput>;
  braintreeTransactions?: Maybe<BraintreeTransactionUpdateManyWithoutOrderInput>;
  bundle?: Maybe<OrderBundleUpdateOneWithoutOrdersInput>;
  canceledAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  chowlyOrderId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customer?: Maybe<UserCustomerInfoUpdateOneRequiredWithoutOrdersInput>;
  deliverToEmail?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deliverToName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deliverToPhone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deliveredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  delivery?: Maybe<FloatFieldUpdateOperationsInput>;
  deliveryEstimation?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  deliveryFeeConst?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  deliveryFeeTaxRateConst?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  deliveryJobId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deliveryOption?: Maybe<NullableEnumDeliveryOptionFieldUpdateOperationsInput>;
  deliveryTax?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  deliveryTimeInSeconds?: Maybe<NullableIntFieldUpdateOperationsInput>;
  expeditedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  expo?: Maybe<WorkplaceUpdateOneWithoutExpoInput>;
  facility?: Maybe<FacilityUpdateOneRequiredWithoutOrdersInput>;
  fee?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  formattedAddress?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fulfillmentAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  fulfillmentBy?: Maybe<DateTimeFieldUpdateOperationsInput>;
  giftCardCodes?: Maybe<OrderUpdategiftCardCodesInput>;
  giftCardDiscounts?: Maybe<OrderUpdategiftCardDiscountsInput>;
  giftCardsDiscount?: Maybe<FloatFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isEmployee?: Maybe<BoolFieldUpdateOperationsInput>;
  isOrderNow?: Maybe<BoolFieldUpdateOperationsInput>;
  isPaidByInvoice?: Maybe<BoolFieldUpdateOperationsInput>;
  isRefunded?: Maybe<BoolFieldUpdateOperationsInput>;
  isVip?: Maybe<BoolFieldUpdateOperationsInput>;
  itemTax?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  items?: Maybe<OrderItemUpdateManyWithoutOrderInput>;
  kdsType?: Maybe<EnumKdsTypeFieldUpdateOperationsInput>;
  kitchenPreparingUntil?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  kitchenTickets?: Maybe<KitchenTicketUpdateManyWithoutOrderInput>;
  lat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  lng?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  locality?: Maybe<NullableStringFieldUpdateOperationsInput>;
  method?: Maybe<EnumOrderMethodFieldUpdateOperationsInput>;
  note?: Maybe<NullableStringFieldUpdateOperationsInput>;
  numberOfGuests?: Maybe<NullableIntFieldUpdateOperationsInput>;
  orderEmail?: Maybe<NullableStringFieldUpdateOperationsInput>;
  orderEvents?: Maybe<OrderEventUpdateManyWithoutOrderInput>;
  orderName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  orderPhoneNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  overwraps?: Maybe<NullableIntFieldUpdateOperationsInput>;
  packager?: Maybe<WorkplaceUpdateOneWithoutPackagerInput>;
  packedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  payedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  placeId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  platform?: Maybe<EnumPlatformFieldUpdateOperationsInput>;
  postalCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  preparingUntil?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  promoCodes?: Maybe<OrderUpdatepromoCodesInput>;
  promoDiscount?: Maybe<FloatFieldUpdateOperationsInput>;
  promoIds?: Maybe<OrderUpdatepromoIdsInput>;
  promos?: Maybe<PromoUpdateManyWithoutOrdersInput>;
  refireDelay?: Maybe<IntFieldUpdateOperationsInput>;
  refired?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  region?: Maybe<NullableStringFieldUpdateOperationsInput>;
  runner?: Maybe<WorkplaceUpdateOneWithoutRunnerInput>;
  sequentialNumber?: Maybe<IntFieldUpdateOperationsInput>;
  serviceFeeConst?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  serviceFeeTax?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  serviceFeeTaxRateConst?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  serviceFeeType?: Maybe<NullableEnumFeeTypeFieldUpdateOperationsInput>;
  shortId?: Maybe<StringFieldUpdateOperationsInput>;
  source?: Maybe<NullableStringFieldUpdateOperationsInput>;
  status?: Maybe<EnumOrderStatusFieldUpdateOperationsInput>;
  street?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripePaymentId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  submittedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  subtotal?: Maybe<FloatFieldUpdateOperationsInput>;
  tags?: Maybe<TagUpdateManyWithoutOrdersInput>;
  tax?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  taxFees?: Maybe<FloatFieldUpdateOperationsInput>;
  taxRateConst?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  tilledPayments?: Maybe<TilledPaymentUpdateManyWithoutOrderInput>;
  tip?: Maybe<FloatFieldUpdateOperationsInput>;
  tookanTaskId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  total?: Maybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  utensilsCount?: Maybe<IntFieldUpdateOperationsInput>;
};

export type OrderUpdateWithoutPromosInput = {
  Payment?: Maybe<PaymentUpdateManyWithoutOrderInput>;
  Refund?: Maybe<RefundUpdateManyWithoutOrderInput>;
  addressNote?: Maybe<NullableStringFieldUpdateOperationsInput>;
  appliedPromos?: Maybe<OrderUpdateappliedPromosInput>;
  braintreeTransactions?: Maybe<BraintreeTransactionUpdateManyWithoutOrderInput>;
  bundle?: Maybe<OrderBundleUpdateOneWithoutOrdersInput>;
  canceledAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  chowlyOrderId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customer?: Maybe<UserCustomerInfoUpdateOneRequiredWithoutOrdersInput>;
  deliverToEmail?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deliverToName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deliverToPhone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deliveredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  delivery?: Maybe<FloatFieldUpdateOperationsInput>;
  deliveryEstimation?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  deliveryFeeConst?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  deliveryFeeTaxRateConst?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  deliveryJobId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deliveryOption?: Maybe<NullableEnumDeliveryOptionFieldUpdateOperationsInput>;
  deliveryTax?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  deliveryTimeInSeconds?: Maybe<NullableIntFieldUpdateOperationsInput>;
  expeditedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  expo?: Maybe<WorkplaceUpdateOneWithoutExpoInput>;
  facility?: Maybe<FacilityUpdateOneRequiredWithoutOrdersInput>;
  fee?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  formattedAddress?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fulfillmentAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  fulfillmentBy?: Maybe<DateTimeFieldUpdateOperationsInput>;
  giftCardCodes?: Maybe<OrderUpdategiftCardCodesInput>;
  giftCardDiscounts?: Maybe<OrderUpdategiftCardDiscountsInput>;
  giftCardsDiscount?: Maybe<FloatFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isEmployee?: Maybe<BoolFieldUpdateOperationsInput>;
  isOrderNow?: Maybe<BoolFieldUpdateOperationsInput>;
  isPaidByInvoice?: Maybe<BoolFieldUpdateOperationsInput>;
  isRefunded?: Maybe<BoolFieldUpdateOperationsInput>;
  isVip?: Maybe<BoolFieldUpdateOperationsInput>;
  itemTax?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  items?: Maybe<OrderItemUpdateManyWithoutOrderInput>;
  kdsType?: Maybe<EnumKdsTypeFieldUpdateOperationsInput>;
  kitchenPreparingUntil?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  kitchenTickets?: Maybe<KitchenTicketUpdateManyWithoutOrderInput>;
  lat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  lng?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  locality?: Maybe<NullableStringFieldUpdateOperationsInput>;
  method?: Maybe<EnumOrderMethodFieldUpdateOperationsInput>;
  note?: Maybe<NullableStringFieldUpdateOperationsInput>;
  numberOfGuests?: Maybe<NullableIntFieldUpdateOperationsInput>;
  orderEmail?: Maybe<NullableStringFieldUpdateOperationsInput>;
  orderEvents?: Maybe<OrderEventUpdateManyWithoutOrderInput>;
  orderName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  orderPhoneNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  overwraps?: Maybe<NullableIntFieldUpdateOperationsInput>;
  packager?: Maybe<WorkplaceUpdateOneWithoutPackagerInput>;
  packedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  payedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  pickup?: Maybe<WorkplaceUpdateOneWithoutPickupInput>;
  placeId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  platform?: Maybe<EnumPlatformFieldUpdateOperationsInput>;
  postalCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  preparingUntil?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  promoCodes?: Maybe<OrderUpdatepromoCodesInput>;
  promoDiscount?: Maybe<FloatFieldUpdateOperationsInput>;
  promoIds?: Maybe<OrderUpdatepromoIdsInput>;
  refireDelay?: Maybe<IntFieldUpdateOperationsInput>;
  refired?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  region?: Maybe<NullableStringFieldUpdateOperationsInput>;
  runner?: Maybe<WorkplaceUpdateOneWithoutRunnerInput>;
  sequentialNumber?: Maybe<IntFieldUpdateOperationsInput>;
  serviceFeeConst?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  serviceFeeTax?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  serviceFeeTaxRateConst?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  serviceFeeType?: Maybe<NullableEnumFeeTypeFieldUpdateOperationsInput>;
  shortId?: Maybe<StringFieldUpdateOperationsInput>;
  source?: Maybe<NullableStringFieldUpdateOperationsInput>;
  status?: Maybe<EnumOrderStatusFieldUpdateOperationsInput>;
  street?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripePaymentId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  submittedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  subtotal?: Maybe<FloatFieldUpdateOperationsInput>;
  tags?: Maybe<TagUpdateManyWithoutOrdersInput>;
  tax?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  taxFees?: Maybe<FloatFieldUpdateOperationsInput>;
  taxRateConst?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  tilledPayments?: Maybe<TilledPaymentUpdateManyWithoutOrderInput>;
  tip?: Maybe<FloatFieldUpdateOperationsInput>;
  tookanTaskId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  total?: Maybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  utensilsCount?: Maybe<IntFieldUpdateOperationsInput>;
};

export type OrderUpdateWithoutRefundInput = {
  Payment?: Maybe<PaymentUpdateManyWithoutOrderInput>;
  addressNote?: Maybe<NullableStringFieldUpdateOperationsInput>;
  appliedPromos?: Maybe<OrderUpdateappliedPromosInput>;
  braintreeTransactions?: Maybe<BraintreeTransactionUpdateManyWithoutOrderInput>;
  bundle?: Maybe<OrderBundleUpdateOneWithoutOrdersInput>;
  canceledAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  chowlyOrderId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customer?: Maybe<UserCustomerInfoUpdateOneRequiredWithoutOrdersInput>;
  deliverToEmail?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deliverToName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deliverToPhone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deliveredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  delivery?: Maybe<FloatFieldUpdateOperationsInput>;
  deliveryEstimation?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  deliveryFeeConst?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  deliveryFeeTaxRateConst?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  deliveryJobId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deliveryOption?: Maybe<NullableEnumDeliveryOptionFieldUpdateOperationsInput>;
  deliveryTax?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  deliveryTimeInSeconds?: Maybe<NullableIntFieldUpdateOperationsInput>;
  expeditedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  expo?: Maybe<WorkplaceUpdateOneWithoutExpoInput>;
  facility?: Maybe<FacilityUpdateOneRequiredWithoutOrdersInput>;
  fee?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  formattedAddress?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fulfillmentAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  fulfillmentBy?: Maybe<DateTimeFieldUpdateOperationsInput>;
  giftCardCodes?: Maybe<OrderUpdategiftCardCodesInput>;
  giftCardDiscounts?: Maybe<OrderUpdategiftCardDiscountsInput>;
  giftCardsDiscount?: Maybe<FloatFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isEmployee?: Maybe<BoolFieldUpdateOperationsInput>;
  isOrderNow?: Maybe<BoolFieldUpdateOperationsInput>;
  isPaidByInvoice?: Maybe<BoolFieldUpdateOperationsInput>;
  isRefunded?: Maybe<BoolFieldUpdateOperationsInput>;
  isVip?: Maybe<BoolFieldUpdateOperationsInput>;
  itemTax?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  items?: Maybe<OrderItemUpdateManyWithoutOrderInput>;
  kdsType?: Maybe<EnumKdsTypeFieldUpdateOperationsInput>;
  kitchenPreparingUntil?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  kitchenTickets?: Maybe<KitchenTicketUpdateManyWithoutOrderInput>;
  lat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  lng?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  locality?: Maybe<NullableStringFieldUpdateOperationsInput>;
  method?: Maybe<EnumOrderMethodFieldUpdateOperationsInput>;
  note?: Maybe<NullableStringFieldUpdateOperationsInput>;
  numberOfGuests?: Maybe<NullableIntFieldUpdateOperationsInput>;
  orderEmail?: Maybe<NullableStringFieldUpdateOperationsInput>;
  orderEvents?: Maybe<OrderEventUpdateManyWithoutOrderInput>;
  orderName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  orderPhoneNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  overwraps?: Maybe<NullableIntFieldUpdateOperationsInput>;
  packager?: Maybe<WorkplaceUpdateOneWithoutPackagerInput>;
  packedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  payedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  pickup?: Maybe<WorkplaceUpdateOneWithoutPickupInput>;
  placeId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  platform?: Maybe<EnumPlatformFieldUpdateOperationsInput>;
  postalCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  preparingUntil?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  promoCodes?: Maybe<OrderUpdatepromoCodesInput>;
  promoDiscount?: Maybe<FloatFieldUpdateOperationsInput>;
  promoIds?: Maybe<OrderUpdatepromoIdsInput>;
  promos?: Maybe<PromoUpdateManyWithoutOrdersInput>;
  refireDelay?: Maybe<IntFieldUpdateOperationsInput>;
  refired?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  region?: Maybe<NullableStringFieldUpdateOperationsInput>;
  runner?: Maybe<WorkplaceUpdateOneWithoutRunnerInput>;
  sequentialNumber?: Maybe<IntFieldUpdateOperationsInput>;
  serviceFeeConst?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  serviceFeeTax?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  serviceFeeTaxRateConst?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  serviceFeeType?: Maybe<NullableEnumFeeTypeFieldUpdateOperationsInput>;
  shortId?: Maybe<StringFieldUpdateOperationsInput>;
  source?: Maybe<NullableStringFieldUpdateOperationsInput>;
  status?: Maybe<EnumOrderStatusFieldUpdateOperationsInput>;
  street?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripePaymentId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  submittedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  subtotal?: Maybe<FloatFieldUpdateOperationsInput>;
  tags?: Maybe<TagUpdateManyWithoutOrdersInput>;
  tax?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  taxFees?: Maybe<FloatFieldUpdateOperationsInput>;
  taxRateConst?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  tilledPayments?: Maybe<TilledPaymentUpdateManyWithoutOrderInput>;
  tip?: Maybe<FloatFieldUpdateOperationsInput>;
  tookanTaskId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  total?: Maybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  utensilsCount?: Maybe<IntFieldUpdateOperationsInput>;
};

export type OrderUpdateWithoutRunnerInput = {
  Payment?: Maybe<PaymentUpdateManyWithoutOrderInput>;
  Refund?: Maybe<RefundUpdateManyWithoutOrderInput>;
  addressNote?: Maybe<NullableStringFieldUpdateOperationsInput>;
  appliedPromos?: Maybe<OrderUpdateappliedPromosInput>;
  braintreeTransactions?: Maybe<BraintreeTransactionUpdateManyWithoutOrderInput>;
  bundle?: Maybe<OrderBundleUpdateOneWithoutOrdersInput>;
  canceledAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  chowlyOrderId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customer?: Maybe<UserCustomerInfoUpdateOneRequiredWithoutOrdersInput>;
  deliverToEmail?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deliverToName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deliverToPhone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deliveredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  delivery?: Maybe<FloatFieldUpdateOperationsInput>;
  deliveryEstimation?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  deliveryFeeConst?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  deliveryFeeTaxRateConst?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  deliveryJobId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deliveryOption?: Maybe<NullableEnumDeliveryOptionFieldUpdateOperationsInput>;
  deliveryTax?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  deliveryTimeInSeconds?: Maybe<NullableIntFieldUpdateOperationsInput>;
  expeditedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  expo?: Maybe<WorkplaceUpdateOneWithoutExpoInput>;
  facility?: Maybe<FacilityUpdateOneRequiredWithoutOrdersInput>;
  fee?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  formattedAddress?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fulfillmentAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  fulfillmentBy?: Maybe<DateTimeFieldUpdateOperationsInput>;
  giftCardCodes?: Maybe<OrderUpdategiftCardCodesInput>;
  giftCardDiscounts?: Maybe<OrderUpdategiftCardDiscountsInput>;
  giftCardsDiscount?: Maybe<FloatFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isEmployee?: Maybe<BoolFieldUpdateOperationsInput>;
  isOrderNow?: Maybe<BoolFieldUpdateOperationsInput>;
  isPaidByInvoice?: Maybe<BoolFieldUpdateOperationsInput>;
  isRefunded?: Maybe<BoolFieldUpdateOperationsInput>;
  isVip?: Maybe<BoolFieldUpdateOperationsInput>;
  itemTax?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  items?: Maybe<OrderItemUpdateManyWithoutOrderInput>;
  kdsType?: Maybe<EnumKdsTypeFieldUpdateOperationsInput>;
  kitchenPreparingUntil?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  kitchenTickets?: Maybe<KitchenTicketUpdateManyWithoutOrderInput>;
  lat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  lng?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  locality?: Maybe<NullableStringFieldUpdateOperationsInput>;
  method?: Maybe<EnumOrderMethodFieldUpdateOperationsInput>;
  note?: Maybe<NullableStringFieldUpdateOperationsInput>;
  numberOfGuests?: Maybe<NullableIntFieldUpdateOperationsInput>;
  orderEmail?: Maybe<NullableStringFieldUpdateOperationsInput>;
  orderEvents?: Maybe<OrderEventUpdateManyWithoutOrderInput>;
  orderName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  orderPhoneNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  overwraps?: Maybe<NullableIntFieldUpdateOperationsInput>;
  packager?: Maybe<WorkplaceUpdateOneWithoutPackagerInput>;
  packedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  payedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  pickup?: Maybe<WorkplaceUpdateOneWithoutPickupInput>;
  placeId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  platform?: Maybe<EnumPlatformFieldUpdateOperationsInput>;
  postalCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  preparingUntil?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  promoCodes?: Maybe<OrderUpdatepromoCodesInput>;
  promoDiscount?: Maybe<FloatFieldUpdateOperationsInput>;
  promoIds?: Maybe<OrderUpdatepromoIdsInput>;
  promos?: Maybe<PromoUpdateManyWithoutOrdersInput>;
  refireDelay?: Maybe<IntFieldUpdateOperationsInput>;
  refired?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  region?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sequentialNumber?: Maybe<IntFieldUpdateOperationsInput>;
  serviceFeeConst?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  serviceFeeTax?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  serviceFeeTaxRateConst?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  serviceFeeType?: Maybe<NullableEnumFeeTypeFieldUpdateOperationsInput>;
  shortId?: Maybe<StringFieldUpdateOperationsInput>;
  source?: Maybe<NullableStringFieldUpdateOperationsInput>;
  status?: Maybe<EnumOrderStatusFieldUpdateOperationsInput>;
  street?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripePaymentId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  submittedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  subtotal?: Maybe<FloatFieldUpdateOperationsInput>;
  tags?: Maybe<TagUpdateManyWithoutOrdersInput>;
  tax?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  taxFees?: Maybe<FloatFieldUpdateOperationsInput>;
  taxRateConst?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  tilledPayments?: Maybe<TilledPaymentUpdateManyWithoutOrderInput>;
  tip?: Maybe<FloatFieldUpdateOperationsInput>;
  tookanTaskId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  total?: Maybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  utensilsCount?: Maybe<IntFieldUpdateOperationsInput>;
};

export type OrderUpdateWithoutTagsInput = {
  Payment?: Maybe<PaymentUpdateManyWithoutOrderInput>;
  Refund?: Maybe<RefundUpdateManyWithoutOrderInput>;
  addressNote?: Maybe<NullableStringFieldUpdateOperationsInput>;
  appliedPromos?: Maybe<OrderUpdateappliedPromosInput>;
  braintreeTransactions?: Maybe<BraintreeTransactionUpdateManyWithoutOrderInput>;
  bundle?: Maybe<OrderBundleUpdateOneWithoutOrdersInput>;
  canceledAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  chowlyOrderId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customer?: Maybe<UserCustomerInfoUpdateOneRequiredWithoutOrdersInput>;
  deliverToEmail?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deliverToName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deliverToPhone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deliveredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  delivery?: Maybe<FloatFieldUpdateOperationsInput>;
  deliveryEstimation?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  deliveryFeeConst?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  deliveryFeeTaxRateConst?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  deliveryJobId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deliveryOption?: Maybe<NullableEnumDeliveryOptionFieldUpdateOperationsInput>;
  deliveryTax?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  deliveryTimeInSeconds?: Maybe<NullableIntFieldUpdateOperationsInput>;
  expeditedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  expo?: Maybe<WorkplaceUpdateOneWithoutExpoInput>;
  facility?: Maybe<FacilityUpdateOneRequiredWithoutOrdersInput>;
  fee?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  formattedAddress?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fulfillmentAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  fulfillmentBy?: Maybe<DateTimeFieldUpdateOperationsInput>;
  giftCardCodes?: Maybe<OrderUpdategiftCardCodesInput>;
  giftCardDiscounts?: Maybe<OrderUpdategiftCardDiscountsInput>;
  giftCardsDiscount?: Maybe<FloatFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isEmployee?: Maybe<BoolFieldUpdateOperationsInput>;
  isOrderNow?: Maybe<BoolFieldUpdateOperationsInput>;
  isPaidByInvoice?: Maybe<BoolFieldUpdateOperationsInput>;
  isRefunded?: Maybe<BoolFieldUpdateOperationsInput>;
  isVip?: Maybe<BoolFieldUpdateOperationsInput>;
  itemTax?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  items?: Maybe<OrderItemUpdateManyWithoutOrderInput>;
  kdsType?: Maybe<EnumKdsTypeFieldUpdateOperationsInput>;
  kitchenPreparingUntil?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  kitchenTickets?: Maybe<KitchenTicketUpdateManyWithoutOrderInput>;
  lat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  lng?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  locality?: Maybe<NullableStringFieldUpdateOperationsInput>;
  method?: Maybe<EnumOrderMethodFieldUpdateOperationsInput>;
  note?: Maybe<NullableStringFieldUpdateOperationsInput>;
  numberOfGuests?: Maybe<NullableIntFieldUpdateOperationsInput>;
  orderEmail?: Maybe<NullableStringFieldUpdateOperationsInput>;
  orderEvents?: Maybe<OrderEventUpdateManyWithoutOrderInput>;
  orderName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  orderPhoneNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  overwraps?: Maybe<NullableIntFieldUpdateOperationsInput>;
  packager?: Maybe<WorkplaceUpdateOneWithoutPackagerInput>;
  packedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  payedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  pickup?: Maybe<WorkplaceUpdateOneWithoutPickupInput>;
  placeId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  platform?: Maybe<EnumPlatformFieldUpdateOperationsInput>;
  postalCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  preparingUntil?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  promoCodes?: Maybe<OrderUpdatepromoCodesInput>;
  promoDiscount?: Maybe<FloatFieldUpdateOperationsInput>;
  promoIds?: Maybe<OrderUpdatepromoIdsInput>;
  promos?: Maybe<PromoUpdateManyWithoutOrdersInput>;
  refireDelay?: Maybe<IntFieldUpdateOperationsInput>;
  refired?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  region?: Maybe<NullableStringFieldUpdateOperationsInput>;
  runner?: Maybe<WorkplaceUpdateOneWithoutRunnerInput>;
  sequentialNumber?: Maybe<IntFieldUpdateOperationsInput>;
  serviceFeeConst?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  serviceFeeTax?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  serviceFeeTaxRateConst?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  serviceFeeType?: Maybe<NullableEnumFeeTypeFieldUpdateOperationsInput>;
  shortId?: Maybe<StringFieldUpdateOperationsInput>;
  source?: Maybe<NullableStringFieldUpdateOperationsInput>;
  status?: Maybe<EnumOrderStatusFieldUpdateOperationsInput>;
  street?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripePaymentId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  submittedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  subtotal?: Maybe<FloatFieldUpdateOperationsInput>;
  tax?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  taxFees?: Maybe<FloatFieldUpdateOperationsInput>;
  taxRateConst?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  tilledPayments?: Maybe<TilledPaymentUpdateManyWithoutOrderInput>;
  tip?: Maybe<FloatFieldUpdateOperationsInput>;
  tookanTaskId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  total?: Maybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  utensilsCount?: Maybe<IntFieldUpdateOperationsInput>;
};

export type OrderUpdateWithoutTilledPaymentsInput = {
  Payment?: Maybe<PaymentUpdateManyWithoutOrderInput>;
  Refund?: Maybe<RefundUpdateManyWithoutOrderInput>;
  addressNote?: Maybe<NullableStringFieldUpdateOperationsInput>;
  appliedPromos?: Maybe<OrderUpdateappliedPromosInput>;
  braintreeTransactions?: Maybe<BraintreeTransactionUpdateManyWithoutOrderInput>;
  bundle?: Maybe<OrderBundleUpdateOneWithoutOrdersInput>;
  canceledAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  chowlyOrderId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customer?: Maybe<UserCustomerInfoUpdateOneRequiredWithoutOrdersInput>;
  deliverToEmail?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deliverToName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deliverToPhone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deliveredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  delivery?: Maybe<FloatFieldUpdateOperationsInput>;
  deliveryEstimation?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  deliveryFeeConst?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  deliveryFeeTaxRateConst?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  deliveryJobId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deliveryOption?: Maybe<NullableEnumDeliveryOptionFieldUpdateOperationsInput>;
  deliveryTax?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  deliveryTimeInSeconds?: Maybe<NullableIntFieldUpdateOperationsInput>;
  expeditedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  expo?: Maybe<WorkplaceUpdateOneWithoutExpoInput>;
  facility?: Maybe<FacilityUpdateOneRequiredWithoutOrdersInput>;
  fee?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  formattedAddress?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fulfillmentAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  fulfillmentBy?: Maybe<DateTimeFieldUpdateOperationsInput>;
  giftCardCodes?: Maybe<OrderUpdategiftCardCodesInput>;
  giftCardDiscounts?: Maybe<OrderUpdategiftCardDiscountsInput>;
  giftCardsDiscount?: Maybe<FloatFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isEmployee?: Maybe<BoolFieldUpdateOperationsInput>;
  isOrderNow?: Maybe<BoolFieldUpdateOperationsInput>;
  isPaidByInvoice?: Maybe<BoolFieldUpdateOperationsInput>;
  isRefunded?: Maybe<BoolFieldUpdateOperationsInput>;
  isVip?: Maybe<BoolFieldUpdateOperationsInput>;
  itemTax?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  items?: Maybe<OrderItemUpdateManyWithoutOrderInput>;
  kdsType?: Maybe<EnumKdsTypeFieldUpdateOperationsInput>;
  kitchenPreparingUntil?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  kitchenTickets?: Maybe<KitchenTicketUpdateManyWithoutOrderInput>;
  lat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  lng?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  locality?: Maybe<NullableStringFieldUpdateOperationsInput>;
  method?: Maybe<EnumOrderMethodFieldUpdateOperationsInput>;
  note?: Maybe<NullableStringFieldUpdateOperationsInput>;
  numberOfGuests?: Maybe<NullableIntFieldUpdateOperationsInput>;
  orderEmail?: Maybe<NullableStringFieldUpdateOperationsInput>;
  orderEvents?: Maybe<OrderEventUpdateManyWithoutOrderInput>;
  orderName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  orderPhoneNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  overwraps?: Maybe<NullableIntFieldUpdateOperationsInput>;
  packager?: Maybe<WorkplaceUpdateOneWithoutPackagerInput>;
  packedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  payedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  pickup?: Maybe<WorkplaceUpdateOneWithoutPickupInput>;
  placeId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  platform?: Maybe<EnumPlatformFieldUpdateOperationsInput>;
  postalCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  preparingUntil?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  promoCodes?: Maybe<OrderUpdatepromoCodesInput>;
  promoDiscount?: Maybe<FloatFieldUpdateOperationsInput>;
  promoIds?: Maybe<OrderUpdatepromoIdsInput>;
  promos?: Maybe<PromoUpdateManyWithoutOrdersInput>;
  refireDelay?: Maybe<IntFieldUpdateOperationsInput>;
  refired?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  region?: Maybe<NullableStringFieldUpdateOperationsInput>;
  runner?: Maybe<WorkplaceUpdateOneWithoutRunnerInput>;
  sequentialNumber?: Maybe<IntFieldUpdateOperationsInput>;
  serviceFeeConst?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  serviceFeeTax?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  serviceFeeTaxRateConst?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  serviceFeeType?: Maybe<NullableEnumFeeTypeFieldUpdateOperationsInput>;
  shortId?: Maybe<StringFieldUpdateOperationsInput>;
  source?: Maybe<NullableStringFieldUpdateOperationsInput>;
  status?: Maybe<EnumOrderStatusFieldUpdateOperationsInput>;
  street?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripePaymentId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  submittedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  subtotal?: Maybe<FloatFieldUpdateOperationsInput>;
  tags?: Maybe<TagUpdateManyWithoutOrdersInput>;
  tax?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  taxFees?: Maybe<FloatFieldUpdateOperationsInput>;
  taxRateConst?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  tip?: Maybe<FloatFieldUpdateOperationsInput>;
  tookanTaskId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  total?: Maybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  utensilsCount?: Maybe<IntFieldUpdateOperationsInput>;
};

export type OrderUpdateappliedPromosInput = {
  push?: Maybe<Scalars['Json']>;
  set?: Maybe<Array<Scalars['Json']>>;
};

export type OrderUpdategiftCardCodesInput = {
  push?: Maybe<Scalars['String']>;
  set?: Maybe<Array<Scalars['String']>>;
};

export type OrderUpdategiftCardDiscountsInput = {
  push?: Maybe<Scalars['Float']>;
  set?: Maybe<Array<Scalars['Float']>>;
};

export type OrderUpdatepromoCodesInput = {
  push?: Maybe<Scalars['String']>;
  set?: Maybe<Array<Scalars['String']>>;
};

export type OrderUpdatepromoIdsInput = {
  push?: Maybe<Scalars['String']>;
  set?: Maybe<Array<Scalars['String']>>;
};

export type OrderUpsertWithWhereUniqueWithoutCustomerInput = {
  create: OrderCreateWithoutCustomerInput;
  update: OrderUpdateWithoutCustomerInput;
  where: OrderWhereUniqueInput;
};

export type OrderUpsertWithWhereUniqueWithoutExpoInput = {
  create: OrderCreateWithoutExpoInput;
  update: OrderUpdateWithoutExpoInput;
  where: OrderWhereUniqueInput;
};

export type OrderUpsertWithWhereUniqueWithoutFacilityInput = {
  create: OrderCreateWithoutFacilityInput;
  update: OrderUpdateWithoutFacilityInput;
  where: OrderWhereUniqueInput;
};

export type OrderUpsertWithWhereUniqueWithoutPackagerInput = {
  create: OrderCreateWithoutPackagerInput;
  update: OrderUpdateWithoutPackagerInput;
  where: OrderWhereUniqueInput;
};

export type OrderUpsertWithWhereUniqueWithoutPickupInput = {
  create: OrderCreateWithoutPickupInput;
  update: OrderUpdateWithoutPickupInput;
  where: OrderWhereUniqueInput;
};

export type OrderUpsertWithWhereUniqueWithoutPromosInput = {
  create: OrderCreateWithoutPromosInput;
  update: OrderUpdateWithoutPromosInput;
  where: OrderWhereUniqueInput;
};

export type OrderUpsertWithWhereUniqueWithoutRunnerInput = {
  create: OrderCreateWithoutRunnerInput;
  update: OrderUpdateWithoutRunnerInput;
  where: OrderWhereUniqueInput;
};

export type OrderUpsertWithWhereUniqueWithoutTagsInput = {
  create: OrderCreateWithoutTagsInput;
  update: OrderUpdateWithoutTagsInput;
  where: OrderWhereUniqueInput;
};

export type OrderUpsertWithoutItemsInput = {
  create: OrderCreateWithoutItemsInput;
  update: OrderUpdateWithoutItemsInput;
};

export type OrderUpsertWithoutKitchenTicketsInput = {
  create: OrderCreateWithoutKitchenTicketsInput;
  update: OrderUpdateWithoutKitchenTicketsInput;
};

export type OrderUpsertWithoutPaymentInput = {
  create: OrderCreateWithoutPaymentInput;
  update: OrderUpdateWithoutPaymentInput;
};

export type OrderUpsertWithoutRefundInput = {
  create: OrderCreateWithoutRefundInput;
  update: OrderUpdateWithoutRefundInput;
};

export type OrderUpsertWithoutTilledPaymentsInput = {
  create: OrderCreateWithoutTilledPaymentsInput;
  update: OrderUpdateWithoutTilledPaymentsInput;
};

export type OrderValidation = {
  __typename?: 'OrderValidation';
  address?: Maybe<Address>;
  costs: OrderCost;
  delivery?: Maybe<DeliveryInfo>;
  errors: Array<OrderValidationErrorResponseType>;
  giftCardCodes: Array<Scalars['String']>;
  giftCardDiscounts: Array<Scalars['Float']>;
  orderMethod: OrderMethod;
  pickupOnlyItemIds: Array<Scalars['ID']>;
  promoCodes: Array<Scalars['String']>;
  promoErrors: Array<PromoValidationErrorWithCode>;
  unavailableItemIds: Array<Scalars['ID']>;
};

export enum OrderValidationError {
  KitchenIsNotAvailable = 'KITCHEN_IS_NOT_AVAILABLE',
  MissingAddress = 'MISSING_ADDRESS',
  MissingItems = 'MISSING_ITEMS',
  NashPhoneValidationFailed = 'NASH_PHONE_VALIDATION_FAILED',
  NoDriverAvailable = 'NO_DRIVER_AVAILABLE',
  OrderMethodNotAvailable = 'ORDER_METHOD_NOT_AVAILABLE',
  OutOfDelivery = 'OUT_OF_DELIVERY',
  PaymentFailed = 'PAYMENT_FAILED',
  PickupOnlyItems = 'PICKUP_ONLY_ITEMS',
  TimeSlotPassed = 'TIME_SLOT_PASSED',
  UnavailableItems = 'UNAVAILABLE_ITEMS'
}

export type OrderValidationErrorResponseType = {
  __typename?: 'OrderValidationErrorResponseType';
  /** Additional attributes for the validation error */
  attributes?: Maybe<Scalars['Json']>;
  /** The error code for the validation error */
  code: OrderValidationError;
};

export type OrderWhereInput = {
  AND?: Maybe<Array<OrderWhereInput>>;
  NOT?: Maybe<Array<OrderWhereInput>>;
  OR?: Maybe<Array<OrderWhereInput>>;
  Payment?: Maybe<PaymentListRelationFilter>;
  Refund?: Maybe<RefundListRelationFilter>;
  addressNote?: Maybe<StringNullableFilter>;
  appliedPromos?: Maybe<JsonNullableListFilter>;
  braintreeTransactions?: Maybe<BraintreeTransactionListRelationFilter>;
  bundle?: Maybe<OrderBundleWhereInput>;
  bundleId?: Maybe<StringNullableFilter>;
  canceledAt?: Maybe<DateTimeNullableFilter>;
  chowlyOrderId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  customer?: Maybe<UserCustomerInfoWhereInput>;
  customerId?: Maybe<StringFilter>;
  deliverToEmail?: Maybe<StringNullableFilter>;
  deliverToName?: Maybe<StringNullableFilter>;
  deliverToPhone?: Maybe<StringNullableFilter>;
  deliveredAt?: Maybe<DateTimeNullableFilter>;
  delivery?: Maybe<FloatFilter>;
  deliveryEstimation?: Maybe<DateTimeNullableFilter>;
  deliveryFeeConst?: Maybe<FloatNullableFilter>;
  deliveryFeeTaxRateConst?: Maybe<FloatNullableFilter>;
  deliveryJobId?: Maybe<StringNullableFilter>;
  deliveryOption?: Maybe<EnumDeliveryOptionNullableFilter>;
  deliveryTax?: Maybe<FloatNullableFilter>;
  deliveryTimeInSeconds?: Maybe<IntNullableFilter>;
  expeditedAt?: Maybe<DateTimeNullableFilter>;
  expo?: Maybe<WorkplaceWhereInput>;
  expoId?: Maybe<StringNullableFilter>;
  facility?: Maybe<FacilityWhereInput>;
  facilityId?: Maybe<StringFilter>;
  fee?: Maybe<FloatNullableFilter>;
  formattedAddress?: Maybe<StringNullableFilter>;
  fulfillmentAt?: Maybe<DateTimeFilter>;
  fulfillmentBy?: Maybe<DateTimeFilter>;
  giftCardCodes?: Maybe<StringNullableListFilter>;
  giftCardDiscounts?: Maybe<FloatNullableListFilter>;
  giftCardsDiscount?: Maybe<FloatFilter>;
  id?: Maybe<StringFilter>;
  isEmployee?: Maybe<BoolFilter>;
  isOrderNow?: Maybe<BoolFilter>;
  isPaidByInvoice?: Maybe<BoolFilter>;
  isRefunded?: Maybe<BoolFilter>;
  isVip?: Maybe<BoolFilter>;
  itemTax?: Maybe<FloatNullableFilter>;
  items?: Maybe<OrderItemListRelationFilter>;
  kdsType?: Maybe<EnumKdsTypeFilter>;
  kitchenPreparingUntil?: Maybe<DateTimeNullableFilter>;
  kitchenTickets?: Maybe<KitchenTicketListRelationFilter>;
  lat?: Maybe<FloatNullableFilter>;
  lng?: Maybe<FloatNullableFilter>;
  locality?: Maybe<StringNullableFilter>;
  method?: Maybe<EnumOrderMethodFilter>;
  note?: Maybe<StringNullableFilter>;
  numberOfGuests?: Maybe<IntNullableFilter>;
  orderEmail?: Maybe<StringNullableFilter>;
  orderEvents?: Maybe<OrderEventListRelationFilter>;
  orderName?: Maybe<StringNullableFilter>;
  orderPhoneNumber?: Maybe<StringNullableFilter>;
  overwraps?: Maybe<IntNullableFilter>;
  packager?: Maybe<WorkplaceWhereInput>;
  packagerId?: Maybe<StringNullableFilter>;
  packedAt?: Maybe<DateTimeNullableFilter>;
  payedAt?: Maybe<DateTimeNullableFilter>;
  pickup?: Maybe<WorkplaceWhereInput>;
  pickupId?: Maybe<StringNullableFilter>;
  placeId?: Maybe<StringNullableFilter>;
  platform?: Maybe<EnumPlatformFilter>;
  postalCode?: Maybe<StringNullableFilter>;
  preparingUntil?: Maybe<DateTimeNullableFilter>;
  promoCodes?: Maybe<StringNullableListFilter>;
  promoDiscount?: Maybe<FloatFilter>;
  promoIds?: Maybe<StringNullableListFilter>;
  promos?: Maybe<PromoListRelationFilter>;
  refireDelay?: Maybe<IntFilter>;
  refired?: Maybe<BoolNullableFilter>;
  region?: Maybe<StringNullableFilter>;
  runner?: Maybe<WorkplaceWhereInput>;
  runnerId?: Maybe<StringNullableFilter>;
  sequentialNumber?: Maybe<IntFilter>;
  serviceFeeConst?: Maybe<FloatNullableFilter>;
  serviceFeeTax?: Maybe<FloatNullableFilter>;
  serviceFeeTaxRateConst?: Maybe<FloatNullableFilter>;
  serviceFeeType?: Maybe<EnumFeeTypeNullableFilter>;
  shortId?: Maybe<StringFilter>;
  source?: Maybe<StringNullableFilter>;
  status?: Maybe<EnumOrderStatusFilter>;
  street?: Maybe<StringNullableFilter>;
  stripePaymentId?: Maybe<StringNullableFilter>;
  submittedAt?: Maybe<DateTimeFilter>;
  subtotal?: Maybe<FloatFilter>;
  tags?: Maybe<TagListRelationFilter>;
  tax?: Maybe<FloatNullableFilter>;
  taxFees?: Maybe<FloatFilter>;
  taxRateConst?: Maybe<FloatNullableFilter>;
  tilledPayments?: Maybe<TilledPaymentListRelationFilter>;
  tip?: Maybe<FloatFilter>;
  tookanTaskId?: Maybe<StringNullableFilter>;
  total?: Maybe<FloatFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  utensilsCount?: Maybe<IntFilter>;
};

export type OrderWhereUniqueInput = {
  deliveryJobId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type PaginationArg = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
};

export type PasswordUpdateInput = {
  customerId: Scalars['ID'];
  password: Scalars['String'];
};

export enum PaymentCardType {
  Amex = 'AMEX',
  DebitMastercard = 'DEBIT_MASTERCARD',
  Diners = 'DINERS',
  Discover = 'DISCOVER',
  Electron = 'ELECTRON',
  Jcb = 'JCB',
  Maestro = 'MAESTRO',
  Mastercard = 'MASTERCARD',
  Solo = 'SOLO',
  Unknown = 'UNKNOWN',
  Visa = 'VISA',
  VisaDebit = 'VISA_DEBIT'
}

export type PaymentCreateManyCartInput = {
  amount: Scalars['Float'];
  createdAt?: Maybe<Scalars['DateTime']>;
  customerId: Scalars['String'];
  facilityId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['String']>;
  payload?: Maybe<Scalars['Json']>;
  processor: PaymentProcessor;
  saveCard: Scalars['Boolean'];
  shortId: Scalars['String'];
  status: PaymentStatus;
  type: PaymentType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PaymentCreateManyCartInputEnvelope = {
  data?: Maybe<Array<PaymentCreateManyCartInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PaymentCreateManyCustomerInput = {
  amount: Scalars['Float'];
  cartId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  facilityId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['String']>;
  payload?: Maybe<Scalars['Json']>;
  processor: PaymentProcessor;
  saveCard: Scalars['Boolean'];
  shortId: Scalars['String'];
  status: PaymentStatus;
  type: PaymentType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PaymentCreateManyCustomerInputEnvelope = {
  data?: Maybe<Array<PaymentCreateManyCustomerInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PaymentCreateManyFacilityInput = {
  amount: Scalars['Float'];
  cartId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customerId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['String']>;
  payload?: Maybe<Scalars['Json']>;
  processor: PaymentProcessor;
  saveCard: Scalars['Boolean'];
  shortId: Scalars['String'];
  status: PaymentStatus;
  type: PaymentType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PaymentCreateManyFacilityInputEnvelope = {
  data?: Maybe<Array<PaymentCreateManyFacilityInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PaymentCreateManyOrderInput = {
  amount: Scalars['Float'];
  cartId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customerId: Scalars['String'];
  facilityId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  payload?: Maybe<Scalars['Json']>;
  processor: PaymentProcessor;
  saveCard: Scalars['Boolean'];
  shortId: Scalars['String'];
  status: PaymentStatus;
  type: PaymentType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PaymentCreateManyOrderInputEnvelope = {
  data?: Maybe<Array<PaymentCreateManyOrderInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PaymentCreateNestedManyWithoutCartInput = {
  connect?: Maybe<Array<PaymentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PaymentCreateOrConnectWithoutCartInput>>;
  create?: Maybe<Array<PaymentCreateWithoutCartInput>>;
  createMany?: Maybe<PaymentCreateManyCartInputEnvelope>;
};

export type PaymentCreateNestedManyWithoutCustomerInput = {
  connect?: Maybe<Array<PaymentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PaymentCreateOrConnectWithoutCustomerInput>>;
  create?: Maybe<Array<PaymentCreateWithoutCustomerInput>>;
  createMany?: Maybe<PaymentCreateManyCustomerInputEnvelope>;
};

export type PaymentCreateNestedManyWithoutFacilityInput = {
  connect?: Maybe<Array<PaymentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PaymentCreateOrConnectWithoutFacilityInput>>;
  create?: Maybe<Array<PaymentCreateWithoutFacilityInput>>;
  createMany?: Maybe<PaymentCreateManyFacilityInputEnvelope>;
};

export type PaymentCreateNestedManyWithoutOrderInput = {
  connect?: Maybe<Array<PaymentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PaymentCreateOrConnectWithoutOrderInput>>;
  create?: Maybe<Array<PaymentCreateWithoutOrderInput>>;
  createMany?: Maybe<PaymentCreateManyOrderInputEnvelope>;
};

export type PaymentCreateOrConnectWithoutCartInput = {
  create: PaymentCreateWithoutCartInput;
  where: PaymentWhereUniqueInput;
};

export type PaymentCreateOrConnectWithoutCustomerInput = {
  create: PaymentCreateWithoutCustomerInput;
  where: PaymentWhereUniqueInput;
};

export type PaymentCreateOrConnectWithoutFacilityInput = {
  create: PaymentCreateWithoutFacilityInput;
  where: PaymentWhereUniqueInput;
};

export type PaymentCreateOrConnectWithoutOrderInput = {
  create: PaymentCreateWithoutOrderInput;
  where: PaymentWhereUniqueInput;
};

export type PaymentCreateWithoutCartInput = {
  amount: Scalars['Float'];
  createdAt?: Maybe<Scalars['DateTime']>;
  customer: UserCreateNestedOneWithoutPaymentInput;
  facility: FacilityCreateNestedOneWithoutPaymentInput;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<OrderCreateNestedOneWithoutPaymentInput>;
  payload?: Maybe<Scalars['Json']>;
  processor: PaymentProcessor;
  saveCard: Scalars['Boolean'];
  shortId: Scalars['String'];
  status: PaymentStatus;
  type: PaymentType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PaymentCreateWithoutCustomerInput = {
  amount: Scalars['Float'];
  cart?: Maybe<CartCreateNestedOneWithoutPaymentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  facility: FacilityCreateNestedOneWithoutPaymentInput;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<OrderCreateNestedOneWithoutPaymentInput>;
  payload?: Maybe<Scalars['Json']>;
  processor: PaymentProcessor;
  saveCard: Scalars['Boolean'];
  shortId: Scalars['String'];
  status: PaymentStatus;
  type: PaymentType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PaymentCreateWithoutFacilityInput = {
  amount: Scalars['Float'];
  cart?: Maybe<CartCreateNestedOneWithoutPaymentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customer: UserCreateNestedOneWithoutPaymentInput;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<OrderCreateNestedOneWithoutPaymentInput>;
  payload?: Maybe<Scalars['Json']>;
  processor: PaymentProcessor;
  saveCard: Scalars['Boolean'];
  shortId: Scalars['String'];
  status: PaymentStatus;
  type: PaymentType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PaymentCreateWithoutOrderInput = {
  amount: Scalars['Float'];
  cart?: Maybe<CartCreateNestedOneWithoutPaymentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customer: UserCreateNestedOneWithoutPaymentInput;
  facility: FacilityCreateNestedOneWithoutPaymentInput;
  id?: Maybe<Scalars['String']>;
  payload?: Maybe<Scalars['Json']>;
  processor: PaymentProcessor;
  saveCard: Scalars['Boolean'];
  shortId: Scalars['String'];
  status: PaymentStatus;
  type: PaymentType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PaymentListRelationFilter = {
  every?: Maybe<PaymentWhereInput>;
  none?: Maybe<PaymentWhereInput>;
  some?: Maybe<PaymentWhereInput>;
};

export enum PaymentProcessor {
  Payroc = 'PAYROC'
}

export type PaymentScalarWhereInput = {
  AND?: Maybe<Array<PaymentScalarWhereInput>>;
  NOT?: Maybe<Array<PaymentScalarWhereInput>>;
  OR?: Maybe<Array<PaymentScalarWhereInput>>;
  amount?: Maybe<FloatFilter>;
  cartId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  customerId?: Maybe<StringFilter>;
  facilityId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  orderId?: Maybe<StringNullableFilter>;
  payload?: Maybe<JsonNullableFilter>;
  processor?: Maybe<EnumPaymentProcessorFilter>;
  saveCard?: Maybe<BoolFilter>;
  shortId?: Maybe<StringFilter>;
  status?: Maybe<EnumPaymentStatusFilter>;
  type?: Maybe<EnumPaymentTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export enum PaymentStatus {
  Cancelled = 'CANCELLED',
  Captured = 'CAPTURED',
  Created = 'CREATED',
  Error = 'ERROR',
  PreApproved = 'PRE_APPROVED'
}

export enum PaymentType {
  Payment = 'PAYMENT',
  Refund = 'REFUND'
}

export type PaymentUpdateManyMutationInput = {
  amount?: Maybe<FloatFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  payload?: Maybe<Scalars['Json']>;
  processor?: Maybe<EnumPaymentProcessorFieldUpdateOperationsInput>;
  saveCard?: Maybe<BoolFieldUpdateOperationsInput>;
  shortId?: Maybe<StringFieldUpdateOperationsInput>;
  status?: Maybe<EnumPaymentStatusFieldUpdateOperationsInput>;
  type?: Maybe<EnumPaymentTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type PaymentUpdateManyWithWhereWithoutCartInput = {
  data: PaymentUpdateManyMutationInput;
  where: PaymentScalarWhereInput;
};

export type PaymentUpdateManyWithWhereWithoutCustomerInput = {
  data: PaymentUpdateManyMutationInput;
  where: PaymentScalarWhereInput;
};

export type PaymentUpdateManyWithWhereWithoutFacilityInput = {
  data: PaymentUpdateManyMutationInput;
  where: PaymentScalarWhereInput;
};

export type PaymentUpdateManyWithWhereWithoutOrderInput = {
  data: PaymentUpdateManyMutationInput;
  where: PaymentScalarWhereInput;
};

export type PaymentUpdateManyWithoutCartInput = {
  connect?: Maybe<Array<PaymentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PaymentCreateOrConnectWithoutCartInput>>;
  create?: Maybe<Array<PaymentCreateWithoutCartInput>>;
  createMany?: Maybe<PaymentCreateManyCartInputEnvelope>;
  delete?: Maybe<Array<PaymentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PaymentScalarWhereInput>>;
  disconnect?: Maybe<Array<PaymentWhereUniqueInput>>;
  set?: Maybe<Array<PaymentWhereUniqueInput>>;
  update?: Maybe<Array<PaymentUpdateWithWhereUniqueWithoutCartInput>>;
  updateMany?: Maybe<Array<PaymentUpdateManyWithWhereWithoutCartInput>>;
  upsert?: Maybe<Array<PaymentUpsertWithWhereUniqueWithoutCartInput>>;
};

export type PaymentUpdateManyWithoutCustomerInput = {
  connect?: Maybe<Array<PaymentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PaymentCreateOrConnectWithoutCustomerInput>>;
  create?: Maybe<Array<PaymentCreateWithoutCustomerInput>>;
  createMany?: Maybe<PaymentCreateManyCustomerInputEnvelope>;
  delete?: Maybe<Array<PaymentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PaymentScalarWhereInput>>;
  disconnect?: Maybe<Array<PaymentWhereUniqueInput>>;
  set?: Maybe<Array<PaymentWhereUniqueInput>>;
  update?: Maybe<Array<PaymentUpdateWithWhereUniqueWithoutCustomerInput>>;
  updateMany?: Maybe<Array<PaymentUpdateManyWithWhereWithoutCustomerInput>>;
  upsert?: Maybe<Array<PaymentUpsertWithWhereUniqueWithoutCustomerInput>>;
};

export type PaymentUpdateManyWithoutFacilityInput = {
  connect?: Maybe<Array<PaymentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PaymentCreateOrConnectWithoutFacilityInput>>;
  create?: Maybe<Array<PaymentCreateWithoutFacilityInput>>;
  createMany?: Maybe<PaymentCreateManyFacilityInputEnvelope>;
  delete?: Maybe<Array<PaymentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PaymentScalarWhereInput>>;
  disconnect?: Maybe<Array<PaymentWhereUniqueInput>>;
  set?: Maybe<Array<PaymentWhereUniqueInput>>;
  update?: Maybe<Array<PaymentUpdateWithWhereUniqueWithoutFacilityInput>>;
  updateMany?: Maybe<Array<PaymentUpdateManyWithWhereWithoutFacilityInput>>;
  upsert?: Maybe<Array<PaymentUpsertWithWhereUniqueWithoutFacilityInput>>;
};

export type PaymentUpdateManyWithoutOrderInput = {
  connect?: Maybe<Array<PaymentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PaymentCreateOrConnectWithoutOrderInput>>;
  create?: Maybe<Array<PaymentCreateWithoutOrderInput>>;
  createMany?: Maybe<PaymentCreateManyOrderInputEnvelope>;
  delete?: Maybe<Array<PaymentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PaymentScalarWhereInput>>;
  disconnect?: Maybe<Array<PaymentWhereUniqueInput>>;
  set?: Maybe<Array<PaymentWhereUniqueInput>>;
  update?: Maybe<Array<PaymentUpdateWithWhereUniqueWithoutOrderInput>>;
  updateMany?: Maybe<Array<PaymentUpdateManyWithWhereWithoutOrderInput>>;
  upsert?: Maybe<Array<PaymentUpsertWithWhereUniqueWithoutOrderInput>>;
};

export type PaymentUpdateWithWhereUniqueWithoutCartInput = {
  data: PaymentUpdateWithoutCartInput;
  where: PaymentWhereUniqueInput;
};

export type PaymentUpdateWithWhereUniqueWithoutCustomerInput = {
  data: PaymentUpdateWithoutCustomerInput;
  where: PaymentWhereUniqueInput;
};

export type PaymentUpdateWithWhereUniqueWithoutFacilityInput = {
  data: PaymentUpdateWithoutFacilityInput;
  where: PaymentWhereUniqueInput;
};

export type PaymentUpdateWithWhereUniqueWithoutOrderInput = {
  data: PaymentUpdateWithoutOrderInput;
  where: PaymentWhereUniqueInput;
};

export type PaymentUpdateWithoutCartInput = {
  amount?: Maybe<FloatFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customer?: Maybe<UserUpdateOneRequiredWithoutPaymentInput>;
  facility?: Maybe<FacilityUpdateOneRequiredWithoutPaymentInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<OrderUpdateOneWithoutPaymentInput>;
  payload?: Maybe<Scalars['Json']>;
  processor?: Maybe<EnumPaymentProcessorFieldUpdateOperationsInput>;
  saveCard?: Maybe<BoolFieldUpdateOperationsInput>;
  shortId?: Maybe<StringFieldUpdateOperationsInput>;
  status?: Maybe<EnumPaymentStatusFieldUpdateOperationsInput>;
  type?: Maybe<EnumPaymentTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type PaymentUpdateWithoutCustomerInput = {
  amount?: Maybe<FloatFieldUpdateOperationsInput>;
  cart?: Maybe<CartUpdateOneWithoutPaymentInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  facility?: Maybe<FacilityUpdateOneRequiredWithoutPaymentInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<OrderUpdateOneWithoutPaymentInput>;
  payload?: Maybe<Scalars['Json']>;
  processor?: Maybe<EnumPaymentProcessorFieldUpdateOperationsInput>;
  saveCard?: Maybe<BoolFieldUpdateOperationsInput>;
  shortId?: Maybe<StringFieldUpdateOperationsInput>;
  status?: Maybe<EnumPaymentStatusFieldUpdateOperationsInput>;
  type?: Maybe<EnumPaymentTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type PaymentUpdateWithoutFacilityInput = {
  amount?: Maybe<FloatFieldUpdateOperationsInput>;
  cart?: Maybe<CartUpdateOneWithoutPaymentInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customer?: Maybe<UserUpdateOneRequiredWithoutPaymentInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<OrderUpdateOneWithoutPaymentInput>;
  payload?: Maybe<Scalars['Json']>;
  processor?: Maybe<EnumPaymentProcessorFieldUpdateOperationsInput>;
  saveCard?: Maybe<BoolFieldUpdateOperationsInput>;
  shortId?: Maybe<StringFieldUpdateOperationsInput>;
  status?: Maybe<EnumPaymentStatusFieldUpdateOperationsInput>;
  type?: Maybe<EnumPaymentTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type PaymentUpdateWithoutOrderInput = {
  amount?: Maybe<FloatFieldUpdateOperationsInput>;
  cart?: Maybe<CartUpdateOneWithoutPaymentInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customer?: Maybe<UserUpdateOneRequiredWithoutPaymentInput>;
  facility?: Maybe<FacilityUpdateOneRequiredWithoutPaymentInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  payload?: Maybe<Scalars['Json']>;
  processor?: Maybe<EnumPaymentProcessorFieldUpdateOperationsInput>;
  saveCard?: Maybe<BoolFieldUpdateOperationsInput>;
  shortId?: Maybe<StringFieldUpdateOperationsInput>;
  status?: Maybe<EnumPaymentStatusFieldUpdateOperationsInput>;
  type?: Maybe<EnumPaymentTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type PaymentUpsertWithWhereUniqueWithoutCartInput = {
  create: PaymentCreateWithoutCartInput;
  update: PaymentUpdateWithoutCartInput;
  where: PaymentWhereUniqueInput;
};

export type PaymentUpsertWithWhereUniqueWithoutCustomerInput = {
  create: PaymentCreateWithoutCustomerInput;
  update: PaymentUpdateWithoutCustomerInput;
  where: PaymentWhereUniqueInput;
};

export type PaymentUpsertWithWhereUniqueWithoutFacilityInput = {
  create: PaymentCreateWithoutFacilityInput;
  update: PaymentUpdateWithoutFacilityInput;
  where: PaymentWhereUniqueInput;
};

export type PaymentUpsertWithWhereUniqueWithoutOrderInput = {
  create: PaymentCreateWithoutOrderInput;
  update: PaymentUpdateWithoutOrderInput;
  where: PaymentWhereUniqueInput;
};

export type PaymentWhereInput = {
  AND?: Maybe<Array<PaymentWhereInput>>;
  NOT?: Maybe<Array<PaymentWhereInput>>;
  OR?: Maybe<Array<PaymentWhereInput>>;
  amount?: Maybe<FloatFilter>;
  cart?: Maybe<CartWhereInput>;
  cartId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  customer?: Maybe<UserWhereInput>;
  customerId?: Maybe<StringFilter>;
  facility?: Maybe<FacilityWhereInput>;
  facilityId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  order?: Maybe<OrderWhereInput>;
  orderId?: Maybe<StringNullableFilter>;
  payload?: Maybe<JsonNullableFilter>;
  processor?: Maybe<EnumPaymentProcessorFilter>;
  saveCard?: Maybe<BoolFilter>;
  shortId?: Maybe<StringFilter>;
  status?: Maybe<EnumPaymentStatusFilter>;
  type?: Maybe<EnumPaymentTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type PaymentWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type PayrocCustomerCard = {
  __typename?: 'PayrocCustomerCard';
  cardExpiry: Scalars['String'];
  cardNumber: Scalars['String'];
  cardType: PaymentCardType;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
};

export type PayrocCustomerCardCreateManyCustomerInput = {
  cardExpiry: Scalars['String'];
  cardNumber: Scalars['String'];
  cardReference: Scalars['String'];
  cardType: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  merchantRef: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PayrocCustomerCardCreateManyCustomerInputEnvelope = {
  data?: Maybe<Array<PayrocCustomerCardCreateManyCustomerInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PayrocCustomerCardCreateNestedManyWithoutCustomerInput = {
  connect?: Maybe<Array<PayrocCustomerCardWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrocCustomerCardCreateOrConnectWithoutCustomerInput>>;
  create?: Maybe<Array<PayrocCustomerCardCreateWithoutCustomerInput>>;
  createMany?: Maybe<PayrocCustomerCardCreateManyCustomerInputEnvelope>;
};

export type PayrocCustomerCardCreateOrConnectWithoutCustomerInput = {
  create: PayrocCustomerCardCreateWithoutCustomerInput;
  where: PayrocCustomerCardWhereUniqueInput;
};

export type PayrocCustomerCardCreateWithoutCustomerInput = {
  cardExpiry: Scalars['String'];
  cardNumber: Scalars['String'];
  cardReference: Scalars['String'];
  cardType: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  merchantRef: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PayrocCustomerCardListRelationFilter = {
  every?: Maybe<PayrocCustomerCardWhereInput>;
  none?: Maybe<PayrocCustomerCardWhereInput>;
  some?: Maybe<PayrocCustomerCardWhereInput>;
};

export type PayrocCustomerCardScalarWhereInput = {
  AND?: Maybe<Array<PayrocCustomerCardScalarWhereInput>>;
  NOT?: Maybe<Array<PayrocCustomerCardScalarWhereInput>>;
  OR?: Maybe<Array<PayrocCustomerCardScalarWhereInput>>;
  cardExpiry?: Maybe<StringFilter>;
  cardNumber?: Maybe<StringFilter>;
  cardReference?: Maybe<StringFilter>;
  cardType?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  customerId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  merchantRef?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type PayrocCustomerCardUpdateManyMutationInput = {
  cardExpiry?: Maybe<StringFieldUpdateOperationsInput>;
  cardNumber?: Maybe<StringFieldUpdateOperationsInput>;
  cardReference?: Maybe<StringFieldUpdateOperationsInput>;
  cardType?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  merchantRef?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type PayrocCustomerCardUpdateManyWithWhereWithoutCustomerInput = {
  data: PayrocCustomerCardUpdateManyMutationInput;
  where: PayrocCustomerCardScalarWhereInput;
};

export type PayrocCustomerCardUpdateManyWithoutCustomerInput = {
  connect?: Maybe<Array<PayrocCustomerCardWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrocCustomerCardCreateOrConnectWithoutCustomerInput>>;
  create?: Maybe<Array<PayrocCustomerCardCreateWithoutCustomerInput>>;
  createMany?: Maybe<PayrocCustomerCardCreateManyCustomerInputEnvelope>;
  delete?: Maybe<Array<PayrocCustomerCardWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PayrocCustomerCardScalarWhereInput>>;
  disconnect?: Maybe<Array<PayrocCustomerCardWhereUniqueInput>>;
  set?: Maybe<Array<PayrocCustomerCardWhereUniqueInput>>;
  update?: Maybe<Array<PayrocCustomerCardUpdateWithWhereUniqueWithoutCustomerInput>>;
  updateMany?: Maybe<Array<PayrocCustomerCardUpdateManyWithWhereWithoutCustomerInput>>;
  upsert?: Maybe<Array<PayrocCustomerCardUpsertWithWhereUniqueWithoutCustomerInput>>;
};

export type PayrocCustomerCardUpdateWithWhereUniqueWithoutCustomerInput = {
  data: PayrocCustomerCardUpdateWithoutCustomerInput;
  where: PayrocCustomerCardWhereUniqueInput;
};

export type PayrocCustomerCardUpdateWithoutCustomerInput = {
  cardExpiry?: Maybe<StringFieldUpdateOperationsInput>;
  cardNumber?: Maybe<StringFieldUpdateOperationsInput>;
  cardReference?: Maybe<StringFieldUpdateOperationsInput>;
  cardType?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  merchantRef?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type PayrocCustomerCardUpsertWithWhereUniqueWithoutCustomerInput = {
  create: PayrocCustomerCardCreateWithoutCustomerInput;
  update: PayrocCustomerCardUpdateWithoutCustomerInput;
  where: PayrocCustomerCardWhereUniqueInput;
};

export type PayrocCustomerCardWhereInput = {
  AND?: Maybe<Array<PayrocCustomerCardWhereInput>>;
  NOT?: Maybe<Array<PayrocCustomerCardWhereInput>>;
  OR?: Maybe<Array<PayrocCustomerCardWhereInput>>;
  cardExpiry?: Maybe<StringFilter>;
  cardNumber?: Maybe<StringFilter>;
  cardReference?: Maybe<StringFilter>;
  cardType?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  customer?: Maybe<UserWhereInput>;
  customerId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  merchantRef?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type PayrocCustomerCardWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type PayrocTerminalCreateNestedOneWithoutFacilityInput = {
  connect?: Maybe<PayrocTerminalWhereUniqueInput>;
  connectOrCreate?: Maybe<PayrocTerminalCreateOrConnectWithoutFacilityInput>;
  create?: Maybe<PayrocTerminalCreateWithoutFacilityInput>;
};

export type PayrocTerminalCreateOrConnectWithoutFacilityInput = {
  create: PayrocTerminalCreateWithoutFacilityInput;
  where: PayrocTerminalWhereUniqueInput;
};

export type PayrocTerminalCreateWithoutFacilityInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  terminalApiKey: Scalars['String'];
  terminalId: Scalars['String'];
  terminalSecret: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PayrocTerminalUpdateOneWithoutFacilityInput = {
  connect?: Maybe<PayrocTerminalWhereUniqueInput>;
  connectOrCreate?: Maybe<PayrocTerminalCreateOrConnectWithoutFacilityInput>;
  create?: Maybe<PayrocTerminalCreateWithoutFacilityInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<PayrocTerminalUpdateWithoutFacilityInput>;
  upsert?: Maybe<PayrocTerminalUpsertWithoutFacilityInput>;
};

export type PayrocTerminalUpdateWithoutFacilityInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  terminalApiKey?: Maybe<StringFieldUpdateOperationsInput>;
  terminalId?: Maybe<StringFieldUpdateOperationsInput>;
  terminalSecret?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type PayrocTerminalUpsertWithoutFacilityInput = {
  create: PayrocTerminalCreateWithoutFacilityInput;
  update: PayrocTerminalUpdateWithoutFacilityInput;
};

export type PayrocTerminalWhereInput = {
  AND?: Maybe<Array<PayrocTerminalWhereInput>>;
  Facility?: Maybe<FacilityListRelationFilter>;
  NOT?: Maybe<Array<PayrocTerminalWhereInput>>;
  OR?: Maybe<Array<PayrocTerminalWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  terminalApiKey?: Maybe<StringFilter>;
  terminalId?: Maybe<StringFilter>;
  terminalSecret?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type PayrocTerminalWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum Platform {
  Catering = 'CATERING',
  Chowly = 'CHOWLY',
  Consumer = 'CONSUMER',
  ConsumerWeb = 'CONSUMER_WEB',
  Kiosk = 'KIOSK'
}

export type PlatformUsers = {
  __typename?: 'PlatformUsers';
  adminUsers: Array<User>;
  total: Scalars['Int'];
};

export type PowerBiReport = {
  __typename?: 'PowerBiReport';
  availableToConcepts: Array<Kitchen>;
  availableToFacilities: Array<Facility>;
  availableToRoles: Array<UserRole>;
  id: Scalars['String'];
  reportId: Scalars['String'];
  title: Scalars['String'];
  url: Scalars['String'];
};


export type PowerBiReportAvailableToConceptsArgs = {
  after?: Maybe<KitchenWhereUniqueInput>;
  before?: Maybe<KitchenWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type PowerBiReportAvailableToFacilitiesArgs = {
  after?: Maybe<FacilityWhereUniqueInput>;
  before?: Maybe<FacilityWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type PowerBiReportCreateInput = {
  availableToConcepts?: Maybe<KitchenCreateNestedManyWithoutPowerBiReportInput>;
  availableToFacilities?: Maybe<FacilityCreateNestedManyWithoutPowerBiReportsInput>;
  availableToRoles?: Maybe<PowerBiReportCreateavailableToRolesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  datasetId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  reportId?: Maybe<Scalars['String']>;
  sortId?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  workspaceId?: Maybe<Scalars['String']>;
};

export type PowerBiReportCreateNestedManyWithoutAvailableToConceptsInput = {
  connect?: Maybe<Array<PowerBiReportWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PowerBiReportCreateOrConnectWithoutAvailableToConceptsInput>>;
  create?: Maybe<Array<PowerBiReportCreateWithoutAvailableToConceptsInput>>;
};

export type PowerBiReportCreateNestedManyWithoutAvailableToFacilitiesInput = {
  connect?: Maybe<Array<PowerBiReportWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PowerBiReportCreateOrConnectWithoutAvailableToFacilitiesInput>>;
  create?: Maybe<Array<PowerBiReportCreateWithoutAvailableToFacilitiesInput>>;
};

export type PowerBiReportCreateOrConnectWithoutAvailableToConceptsInput = {
  create: PowerBiReportCreateWithoutAvailableToConceptsInput;
  where: PowerBiReportWhereUniqueInput;
};

export type PowerBiReportCreateOrConnectWithoutAvailableToFacilitiesInput = {
  create: PowerBiReportCreateWithoutAvailableToFacilitiesInput;
  where: PowerBiReportWhereUniqueInput;
};

export type PowerBiReportCreateWithoutAvailableToConceptsInput = {
  availableToFacilities?: Maybe<FacilityCreateNestedManyWithoutPowerBiReportsInput>;
  availableToRoles?: Maybe<PowerBiReportCreateavailableToRolesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  datasetId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  reportId?: Maybe<Scalars['String']>;
  sortId?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  workspaceId?: Maybe<Scalars['String']>;
};

export type PowerBiReportCreateWithoutAvailableToFacilitiesInput = {
  availableToConcepts?: Maybe<KitchenCreateNestedManyWithoutPowerBiReportInput>;
  availableToRoles?: Maybe<PowerBiReportCreateavailableToRolesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  datasetId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  reportId?: Maybe<Scalars['String']>;
  sortId?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  workspaceId?: Maybe<Scalars['String']>;
};

export type PowerBiReportCreateavailableToRolesInput = {
  set?: Maybe<Array<UserRole>>;
};

export type PowerBiReportListRelationFilter = {
  every?: Maybe<PowerBiReportWhereInput>;
  none?: Maybe<PowerBiReportWhereInput>;
  some?: Maybe<PowerBiReportWhereInput>;
};

export type PowerBiReportScalarWhereInput = {
  AND?: Maybe<Array<PowerBiReportScalarWhereInput>>;
  NOT?: Maybe<Array<PowerBiReportScalarWhereInput>>;
  OR?: Maybe<Array<PowerBiReportScalarWhereInput>>;
  availableToRoles?: Maybe<EnumUserRoleNullableListFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  datasetId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  reportId?: Maybe<StringFilter>;
  sortId?: Maybe<IntFilter>;
  title?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  url?: Maybe<StringFilter>;
  workspaceId?: Maybe<StringFilter>;
};

export type PowerBiReportUpdateInput = {
  availableToConcepts?: Maybe<KitchenUpdateManyWithoutPowerBiReportInput>;
  availableToFacilities?: Maybe<FacilityUpdateManyWithoutPowerBiReportsInput>;
  availableToRoles?: Maybe<PowerBiReportUpdateavailableToRolesInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  datasetId?: Maybe<StringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  reportId?: Maybe<StringFieldUpdateOperationsInput>;
  sortId?: Maybe<IntFieldUpdateOperationsInput>;
  title?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  url?: Maybe<StringFieldUpdateOperationsInput>;
  workspaceId?: Maybe<StringFieldUpdateOperationsInput>;
};

export type PowerBiReportUpdateManyMutationInput = {
  availableToRoles?: Maybe<PowerBiReportUpdateavailableToRolesInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  datasetId?: Maybe<StringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  reportId?: Maybe<StringFieldUpdateOperationsInput>;
  sortId?: Maybe<IntFieldUpdateOperationsInput>;
  title?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  url?: Maybe<StringFieldUpdateOperationsInput>;
  workspaceId?: Maybe<StringFieldUpdateOperationsInput>;
};

export type PowerBiReportUpdateManyWithWhereWithoutAvailableToConceptsInput = {
  data: PowerBiReportUpdateManyMutationInput;
  where: PowerBiReportScalarWhereInput;
};

export type PowerBiReportUpdateManyWithWhereWithoutAvailableToFacilitiesInput = {
  data: PowerBiReportUpdateManyMutationInput;
  where: PowerBiReportScalarWhereInput;
};

export type PowerBiReportUpdateManyWithoutAvailableToConceptsInput = {
  connect?: Maybe<Array<PowerBiReportWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PowerBiReportCreateOrConnectWithoutAvailableToConceptsInput>>;
  create?: Maybe<Array<PowerBiReportCreateWithoutAvailableToConceptsInput>>;
  delete?: Maybe<Array<PowerBiReportWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PowerBiReportScalarWhereInput>>;
  disconnect?: Maybe<Array<PowerBiReportWhereUniqueInput>>;
  set?: Maybe<Array<PowerBiReportWhereUniqueInput>>;
  update?: Maybe<Array<PowerBiReportUpdateWithWhereUniqueWithoutAvailableToConceptsInput>>;
  updateMany?: Maybe<Array<PowerBiReportUpdateManyWithWhereWithoutAvailableToConceptsInput>>;
  upsert?: Maybe<Array<PowerBiReportUpsertWithWhereUniqueWithoutAvailableToConceptsInput>>;
};

export type PowerBiReportUpdateManyWithoutAvailableToFacilitiesInput = {
  connect?: Maybe<Array<PowerBiReportWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PowerBiReportCreateOrConnectWithoutAvailableToFacilitiesInput>>;
  create?: Maybe<Array<PowerBiReportCreateWithoutAvailableToFacilitiesInput>>;
  delete?: Maybe<Array<PowerBiReportWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PowerBiReportScalarWhereInput>>;
  disconnect?: Maybe<Array<PowerBiReportWhereUniqueInput>>;
  set?: Maybe<Array<PowerBiReportWhereUniqueInput>>;
  update?: Maybe<Array<PowerBiReportUpdateWithWhereUniqueWithoutAvailableToFacilitiesInput>>;
  updateMany?: Maybe<Array<PowerBiReportUpdateManyWithWhereWithoutAvailableToFacilitiesInput>>;
  upsert?: Maybe<Array<PowerBiReportUpsertWithWhereUniqueWithoutAvailableToFacilitiesInput>>;
};

export type PowerBiReportUpdateWithWhereUniqueWithoutAvailableToConceptsInput = {
  data: PowerBiReportUpdateWithoutAvailableToConceptsInput;
  where: PowerBiReportWhereUniqueInput;
};

export type PowerBiReportUpdateWithWhereUniqueWithoutAvailableToFacilitiesInput = {
  data: PowerBiReportUpdateWithoutAvailableToFacilitiesInput;
  where: PowerBiReportWhereUniqueInput;
};

export type PowerBiReportUpdateWithoutAvailableToConceptsInput = {
  availableToFacilities?: Maybe<FacilityUpdateManyWithoutPowerBiReportsInput>;
  availableToRoles?: Maybe<PowerBiReportUpdateavailableToRolesInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  datasetId?: Maybe<StringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  reportId?: Maybe<StringFieldUpdateOperationsInput>;
  sortId?: Maybe<IntFieldUpdateOperationsInput>;
  title?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  url?: Maybe<StringFieldUpdateOperationsInput>;
  workspaceId?: Maybe<StringFieldUpdateOperationsInput>;
};

export type PowerBiReportUpdateWithoutAvailableToFacilitiesInput = {
  availableToConcepts?: Maybe<KitchenUpdateManyWithoutPowerBiReportInput>;
  availableToRoles?: Maybe<PowerBiReportUpdateavailableToRolesInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  datasetId?: Maybe<StringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  reportId?: Maybe<StringFieldUpdateOperationsInput>;
  sortId?: Maybe<IntFieldUpdateOperationsInput>;
  title?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  url?: Maybe<StringFieldUpdateOperationsInput>;
  workspaceId?: Maybe<StringFieldUpdateOperationsInput>;
};

export type PowerBiReportUpdateavailableToRolesInput = {
  push?: Maybe<UserRole>;
  set?: Maybe<Array<UserRole>>;
};

export type PowerBiReportUpsertWithWhereUniqueWithoutAvailableToConceptsInput = {
  create: PowerBiReportCreateWithoutAvailableToConceptsInput;
  update: PowerBiReportUpdateWithoutAvailableToConceptsInput;
  where: PowerBiReportWhereUniqueInput;
};

export type PowerBiReportUpsertWithWhereUniqueWithoutAvailableToFacilitiesInput = {
  create: PowerBiReportCreateWithoutAvailableToFacilitiesInput;
  update: PowerBiReportUpdateWithoutAvailableToFacilitiesInput;
  where: PowerBiReportWhereUniqueInput;
};

export type PowerBiReportWhereInput = {
  AND?: Maybe<Array<PowerBiReportWhereInput>>;
  NOT?: Maybe<Array<PowerBiReportWhereInput>>;
  OR?: Maybe<Array<PowerBiReportWhereInput>>;
  availableToConcepts?: Maybe<KitchenListRelationFilter>;
  availableToFacilities?: Maybe<FacilityListRelationFilter>;
  availableToRoles?: Maybe<EnumUserRoleNullableListFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  datasetId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  reportId?: Maybe<StringFilter>;
  sortId?: Maybe<IntFilter>;
  title?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  url?: Maybe<StringFilter>;
  workspaceId?: Maybe<StringFilter>;
};

export type PowerBiReportWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type PowerBiReportsAndToken = {
  __typename?: 'PowerBiReportsAndToken';
  embedToken: Scalars['String'];
  reports: Array<PowerBiReport>;
};

export type Promo = {
  __typename?: 'Promo';
  appliesTo: PromoAppliesTo;
  appliesToDefinition?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  definition?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  eligibility: PromoEligibility;
  eligibilityDefinition?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isAllKitchensAllFacilities: Scalars['Boolean'];
  method: Array<OrderMethod>;
  orderCount: Scalars['Int'];
  orders: Array<Order>;
  promoFacilityRules: Array<PromoFacilityRule>;
  requirements: PromoRequirement;
  requirementsDefinition?: Maybe<Scalars['Float']>;
  status: PromoStatus;
  type: PromoType;
  typeDefinition?: Maybe<Scalars['String']>;
  usageLimit: PromoUsageLimits;
  usageLimitDefinition?: Maybe<Scalars['String']>;
  validSince: Scalars['DateTime'];
  validTill?: Maybe<Scalars['DateTime']>;
};


export type PromoOrdersArgs = {
  after?: Maybe<OrderWhereUniqueInput>;
  before?: Maybe<OrderWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type PromoPromoFacilityRulesArgs = {
  after?: Maybe<PromoFacilityRuleWhereUniqueInput>;
  before?: Maybe<PromoFacilityRuleWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export enum PromoAppliesTo {
  Category = 'CATEGORY',
  ExceptItems = 'EXCEPT_ITEMS',
  Item = 'ITEM',
  Order = 'ORDER'
}

export type PromoBanner = {
  __typename?: 'PromoBanner';
  active: Scalars['Boolean'];
  color: Scalars['String'];
  description: Scalars['String'];
  hasDarkStatusBar: Scalars['Boolean'];
  id: Scalars['String'];
  textColor: Scalars['String'];
  title: Scalars['String'];
};

export type PromoBannerCreateManyFacilityInput = {
  active: Scalars['Boolean'];
  color?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  textColor?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PromoBannerCreateManyFacilityInputEnvelope = {
  data?: Maybe<Array<PromoBannerCreateManyFacilityInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PromoBannerCreateNestedManyWithoutFacilityInput = {
  connect?: Maybe<Array<PromoBannerWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PromoBannerCreateOrConnectWithoutFacilityInput>>;
  create?: Maybe<Array<PromoBannerCreateWithoutFacilityInput>>;
  createMany?: Maybe<PromoBannerCreateManyFacilityInputEnvelope>;
};

export type PromoBannerCreateOrConnectWithoutFacilityInput = {
  create: PromoBannerCreateWithoutFacilityInput;
  where: PromoBannerWhereUniqueInput;
};

export type PromoBannerCreateWithoutFacilityInput = {
  active: Scalars['Boolean'];
  color?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  textColor?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PromoBannerListRelationFilter = {
  every?: Maybe<PromoBannerWhereInput>;
  none?: Maybe<PromoBannerWhereInput>;
  some?: Maybe<PromoBannerWhereInput>;
};

export type PromoBannerScalarWhereInput = {
  AND?: Maybe<Array<PromoBannerScalarWhereInput>>;
  NOT?: Maybe<Array<PromoBannerScalarWhereInput>>;
  OR?: Maybe<Array<PromoBannerScalarWhereInput>>;
  active?: Maybe<BoolFilter>;
  color?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  description?: Maybe<StringFilter>;
  facilityId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  textColor?: Maybe<StringFilter>;
  title?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type PromoBannerUpdateInput = {
  active?: Maybe<BoolFieldUpdateOperationsInput>;
  color?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<StringFieldUpdateOperationsInput>;
  facility?: Maybe<FacilityUpdateOneRequiredWithoutPromoBannersInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  textColor?: Maybe<StringFieldUpdateOperationsInput>;
  title?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type PromoBannerUpdateManyMutationInput = {
  active?: Maybe<BoolFieldUpdateOperationsInput>;
  color?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<StringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  textColor?: Maybe<StringFieldUpdateOperationsInput>;
  title?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type PromoBannerUpdateManyWithWhereWithoutFacilityInput = {
  data: PromoBannerUpdateManyMutationInput;
  where: PromoBannerScalarWhereInput;
};

export type PromoBannerUpdateManyWithoutFacilityInput = {
  connect?: Maybe<Array<PromoBannerWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PromoBannerCreateOrConnectWithoutFacilityInput>>;
  create?: Maybe<Array<PromoBannerCreateWithoutFacilityInput>>;
  createMany?: Maybe<PromoBannerCreateManyFacilityInputEnvelope>;
  delete?: Maybe<Array<PromoBannerWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PromoBannerScalarWhereInput>>;
  disconnect?: Maybe<Array<PromoBannerWhereUniqueInput>>;
  set?: Maybe<Array<PromoBannerWhereUniqueInput>>;
  update?: Maybe<Array<PromoBannerUpdateWithWhereUniqueWithoutFacilityInput>>;
  updateMany?: Maybe<Array<PromoBannerUpdateManyWithWhereWithoutFacilityInput>>;
  upsert?: Maybe<Array<PromoBannerUpsertWithWhereUniqueWithoutFacilityInput>>;
};

export type PromoBannerUpdateWithWhereUniqueWithoutFacilityInput = {
  data: PromoBannerUpdateWithoutFacilityInput;
  where: PromoBannerWhereUniqueInput;
};

export type PromoBannerUpdateWithoutFacilityInput = {
  active?: Maybe<BoolFieldUpdateOperationsInput>;
  color?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<StringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  textColor?: Maybe<StringFieldUpdateOperationsInput>;
  title?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type PromoBannerUpsertWithWhereUniqueWithoutFacilityInput = {
  create: PromoBannerCreateWithoutFacilityInput;
  update: PromoBannerUpdateWithoutFacilityInput;
  where: PromoBannerWhereUniqueInput;
};

export type PromoBannerWhereInput = {
  AND?: Maybe<Array<PromoBannerWhereInput>>;
  NOT?: Maybe<Array<PromoBannerWhereInput>>;
  OR?: Maybe<Array<PromoBannerWhereInput>>;
  active?: Maybe<BoolFilter>;
  color?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  description?: Maybe<StringFilter>;
  facility?: Maybe<FacilityWhereInput>;
  facilityId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  textColor?: Maybe<StringFilter>;
  title?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type PromoBannerWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type PromoCreateManyTenantInput = {
  appliesTo?: Maybe<PromoAppliesTo>;
  appliesToDefinition?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  definition?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  eligibility?: Maybe<PromoEligibility>;
  eligibilityDefinition?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  initialValue?: Maybe<Scalars['Float']>;
  isAllKitchensAllFacilities?: Maybe<Scalars['Boolean']>;
  method?: Maybe<PromoCreateManymethodInput>;
  requirements?: Maybe<PromoRequirement>;
  requirementsDefinition?: Maybe<Scalars['Float']>;
  status?: Maybe<PromoStatus>;
  type: PromoType;
  typeDefinition?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageLimit?: Maybe<PromoUsageLimits>;
  usageLimitDefinition?: Maybe<Scalars['String']>;
  validSince: Scalars['DateTime'];
  validTill?: Maybe<Scalars['DateTime']>;
};

export type PromoCreateManyTenantInputEnvelope = {
  data?: Maybe<Array<PromoCreateManyTenantInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PromoCreateManymethodInput = {
  set?: Maybe<Array<OrderMethod>>;
};

export type PromoCreateNestedManyWithoutOrdersInput = {
  connect?: Maybe<Array<PromoWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PromoCreateOrConnectWithoutOrdersInput>>;
  create?: Maybe<Array<PromoCreateWithoutOrdersInput>>;
};

export type PromoCreateNestedManyWithoutTenantInput = {
  connect?: Maybe<Array<PromoWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PromoCreateOrConnectWithoutTenantInput>>;
  create?: Maybe<Array<PromoCreateWithoutTenantInput>>;
  createMany?: Maybe<PromoCreateManyTenantInputEnvelope>;
};

export type PromoCreateNestedOneWithoutPromoFacilityRulesInput = {
  connect?: Maybe<PromoWhereUniqueInput>;
  connectOrCreate?: Maybe<PromoCreateOrConnectWithoutPromoFacilityRulesInput>;
  create?: Maybe<PromoCreateWithoutPromoFacilityRulesInput>;
};

export type PromoCreateOrConnectWithoutOrdersInput = {
  create: PromoCreateWithoutOrdersInput;
  where: PromoWhereUniqueInput;
};

export type PromoCreateOrConnectWithoutPromoFacilityRulesInput = {
  create: PromoCreateWithoutPromoFacilityRulesInput;
  where: PromoWhereUniqueInput;
};

export type PromoCreateOrConnectWithoutTenantInput = {
  create: PromoCreateWithoutTenantInput;
  where: PromoWhereUniqueInput;
};

export type PromoCreateWithoutOrdersInput = {
  appliesTo?: Maybe<PromoAppliesTo>;
  appliesToDefinition?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  definition?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  eligibility?: Maybe<PromoEligibility>;
  eligibilityDefinition?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  initialValue?: Maybe<Scalars['Float']>;
  isAllKitchensAllFacilities?: Maybe<Scalars['Boolean']>;
  method?: Maybe<PromoCreatemethodInput>;
  promoFacilityRules?: Maybe<PromoFacilityRuleCreateNestedManyWithoutPromoInput>;
  requirements?: Maybe<PromoRequirement>;
  requirementsDefinition?: Maybe<Scalars['Float']>;
  status?: Maybe<PromoStatus>;
  tenant: TenantCreateNestedOneWithoutPromosInput;
  type: PromoType;
  typeDefinition?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageLimit?: Maybe<PromoUsageLimits>;
  usageLimitDefinition?: Maybe<Scalars['String']>;
  validSince: Scalars['DateTime'];
  validTill?: Maybe<Scalars['DateTime']>;
};

export type PromoCreateWithoutPromoFacilityRulesInput = {
  appliesTo?: Maybe<PromoAppliesTo>;
  appliesToDefinition?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  definition?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  eligibility?: Maybe<PromoEligibility>;
  eligibilityDefinition?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  initialValue?: Maybe<Scalars['Float']>;
  isAllKitchensAllFacilities?: Maybe<Scalars['Boolean']>;
  method?: Maybe<PromoCreatemethodInput>;
  orders?: Maybe<OrderCreateNestedManyWithoutPromosInput>;
  requirements?: Maybe<PromoRequirement>;
  requirementsDefinition?: Maybe<Scalars['Float']>;
  status?: Maybe<PromoStatus>;
  tenant: TenantCreateNestedOneWithoutPromosInput;
  type: PromoType;
  typeDefinition?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageLimit?: Maybe<PromoUsageLimits>;
  usageLimitDefinition?: Maybe<Scalars['String']>;
  validSince: Scalars['DateTime'];
  validTill?: Maybe<Scalars['DateTime']>;
};

export type PromoCreateWithoutTenantInput = {
  appliesTo?: Maybe<PromoAppliesTo>;
  appliesToDefinition?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  definition?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  eligibility?: Maybe<PromoEligibility>;
  eligibilityDefinition?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  initialValue?: Maybe<Scalars['Float']>;
  isAllKitchensAllFacilities?: Maybe<Scalars['Boolean']>;
  method?: Maybe<PromoCreatemethodInput>;
  orders?: Maybe<OrderCreateNestedManyWithoutPromosInput>;
  promoFacilityRules?: Maybe<PromoFacilityRuleCreateNestedManyWithoutPromoInput>;
  requirements?: Maybe<PromoRequirement>;
  requirementsDefinition?: Maybe<Scalars['Float']>;
  status?: Maybe<PromoStatus>;
  type: PromoType;
  typeDefinition?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageLimit?: Maybe<PromoUsageLimits>;
  usageLimitDefinition?: Maybe<Scalars['String']>;
  validSince: Scalars['DateTime'];
  validTill?: Maybe<Scalars['DateTime']>;
};

export type PromoCreatemethodInput = {
  set?: Maybe<Array<OrderMethod>>;
};

export enum PromoEligibility {
  Customer = 'CUSTOMER',
  Employee = 'EMPLOYEE',
  Everyone = 'EVERYONE',
  Segment = 'SEGMENT'
}

export type PromoFacilityRule = {
  __typename?: 'PromoFacilityRule';
  facilityId: Scalars['String'];
  id: Scalars['String'];
  isAllKitchens: Scalars['Boolean'];
  kitchens: Array<Kitchen>;
  promoId: Scalars['String'];
};


export type PromoFacilityRuleKitchensArgs = {
  after?: Maybe<KitchenWhereUniqueInput>;
  before?: Maybe<KitchenWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type PromoFacilityRuleCreateManyFacilityInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isAllKitchens: Scalars['Boolean'];
  promoId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PromoFacilityRuleCreateManyFacilityInputEnvelope = {
  data?: Maybe<Array<PromoFacilityRuleCreateManyFacilityInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PromoFacilityRuleCreateManyPromoInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  facilityId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  isAllKitchens: Scalars['Boolean'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PromoFacilityRuleCreateManyPromoInputEnvelope = {
  data?: Maybe<Array<PromoFacilityRuleCreateManyPromoInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PromoFacilityRuleCreateNestedManyWithoutFacilityInput = {
  connect?: Maybe<Array<PromoFacilityRuleWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PromoFacilityRuleCreateOrConnectWithoutFacilityInput>>;
  create?: Maybe<Array<PromoFacilityRuleCreateWithoutFacilityInput>>;
  createMany?: Maybe<PromoFacilityRuleCreateManyFacilityInputEnvelope>;
};

export type PromoFacilityRuleCreateNestedManyWithoutKitchensInput = {
  connect?: Maybe<Array<PromoFacilityRuleWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PromoFacilityRuleCreateOrConnectWithoutKitchensInput>>;
  create?: Maybe<Array<PromoFacilityRuleCreateWithoutKitchensInput>>;
};

export type PromoFacilityRuleCreateNestedManyWithoutPromoInput = {
  connect?: Maybe<Array<PromoFacilityRuleWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PromoFacilityRuleCreateOrConnectWithoutPromoInput>>;
  create?: Maybe<Array<PromoFacilityRuleCreateWithoutPromoInput>>;
  createMany?: Maybe<PromoFacilityRuleCreateManyPromoInputEnvelope>;
};

export type PromoFacilityRuleCreateOrConnectWithoutFacilityInput = {
  create: PromoFacilityRuleCreateWithoutFacilityInput;
  where: PromoFacilityRuleWhereUniqueInput;
};

export type PromoFacilityRuleCreateOrConnectWithoutKitchensInput = {
  create: PromoFacilityRuleCreateWithoutKitchensInput;
  where: PromoFacilityRuleWhereUniqueInput;
};

export type PromoFacilityRuleCreateOrConnectWithoutPromoInput = {
  create: PromoFacilityRuleCreateWithoutPromoInput;
  where: PromoFacilityRuleWhereUniqueInput;
};

export type PromoFacilityRuleCreateWithoutFacilityInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isAllKitchens: Scalars['Boolean'];
  kitchens?: Maybe<KitchenCreateNestedManyWithoutPromoFacilityRulesInput>;
  promo: PromoCreateNestedOneWithoutPromoFacilityRulesInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PromoFacilityRuleCreateWithoutKitchensInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  facility: FacilityCreateNestedOneWithoutPromoFacilityRulesInput;
  id?: Maybe<Scalars['String']>;
  isAllKitchens: Scalars['Boolean'];
  promo: PromoCreateNestedOneWithoutPromoFacilityRulesInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PromoFacilityRuleCreateWithoutPromoInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  facility: FacilityCreateNestedOneWithoutPromoFacilityRulesInput;
  id?: Maybe<Scalars['String']>;
  isAllKitchens: Scalars['Boolean'];
  kitchens?: Maybe<KitchenCreateNestedManyWithoutPromoFacilityRulesInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PromoFacilityRuleListRelationFilter = {
  every?: Maybe<PromoFacilityRuleWhereInput>;
  none?: Maybe<PromoFacilityRuleWhereInput>;
  some?: Maybe<PromoFacilityRuleWhereInput>;
};

export type PromoFacilityRuleScalarWhereInput = {
  AND?: Maybe<Array<PromoFacilityRuleScalarWhereInput>>;
  NOT?: Maybe<Array<PromoFacilityRuleScalarWhereInput>>;
  OR?: Maybe<Array<PromoFacilityRuleScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  facilityId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  isAllKitchens?: Maybe<BoolFilter>;
  promoId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type PromoFacilityRuleUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isAllKitchens?: Maybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type PromoFacilityRuleUpdateManyWithWhereWithoutFacilityInput = {
  data: PromoFacilityRuleUpdateManyMutationInput;
  where: PromoFacilityRuleScalarWhereInput;
};

export type PromoFacilityRuleUpdateManyWithWhereWithoutKitchensInput = {
  data: PromoFacilityRuleUpdateManyMutationInput;
  where: PromoFacilityRuleScalarWhereInput;
};

export type PromoFacilityRuleUpdateManyWithWhereWithoutPromoInput = {
  data: PromoFacilityRuleUpdateManyMutationInput;
  where: PromoFacilityRuleScalarWhereInput;
};

export type PromoFacilityRuleUpdateManyWithoutFacilityInput = {
  connect?: Maybe<Array<PromoFacilityRuleWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PromoFacilityRuleCreateOrConnectWithoutFacilityInput>>;
  create?: Maybe<Array<PromoFacilityRuleCreateWithoutFacilityInput>>;
  createMany?: Maybe<PromoFacilityRuleCreateManyFacilityInputEnvelope>;
  delete?: Maybe<Array<PromoFacilityRuleWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PromoFacilityRuleScalarWhereInput>>;
  disconnect?: Maybe<Array<PromoFacilityRuleWhereUniqueInput>>;
  set?: Maybe<Array<PromoFacilityRuleWhereUniqueInput>>;
  update?: Maybe<Array<PromoFacilityRuleUpdateWithWhereUniqueWithoutFacilityInput>>;
  updateMany?: Maybe<Array<PromoFacilityRuleUpdateManyWithWhereWithoutFacilityInput>>;
  upsert?: Maybe<Array<PromoFacilityRuleUpsertWithWhereUniqueWithoutFacilityInput>>;
};

export type PromoFacilityRuleUpdateManyWithoutKitchensInput = {
  connect?: Maybe<Array<PromoFacilityRuleWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PromoFacilityRuleCreateOrConnectWithoutKitchensInput>>;
  create?: Maybe<Array<PromoFacilityRuleCreateWithoutKitchensInput>>;
  delete?: Maybe<Array<PromoFacilityRuleWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PromoFacilityRuleScalarWhereInput>>;
  disconnect?: Maybe<Array<PromoFacilityRuleWhereUniqueInput>>;
  set?: Maybe<Array<PromoFacilityRuleWhereUniqueInput>>;
  update?: Maybe<Array<PromoFacilityRuleUpdateWithWhereUniqueWithoutKitchensInput>>;
  updateMany?: Maybe<Array<PromoFacilityRuleUpdateManyWithWhereWithoutKitchensInput>>;
  upsert?: Maybe<Array<PromoFacilityRuleUpsertWithWhereUniqueWithoutKitchensInput>>;
};

export type PromoFacilityRuleUpdateManyWithoutPromoInput = {
  connect?: Maybe<Array<PromoFacilityRuleWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PromoFacilityRuleCreateOrConnectWithoutPromoInput>>;
  create?: Maybe<Array<PromoFacilityRuleCreateWithoutPromoInput>>;
  createMany?: Maybe<PromoFacilityRuleCreateManyPromoInputEnvelope>;
  delete?: Maybe<Array<PromoFacilityRuleWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PromoFacilityRuleScalarWhereInput>>;
  disconnect?: Maybe<Array<PromoFacilityRuleWhereUniqueInput>>;
  set?: Maybe<Array<PromoFacilityRuleWhereUniqueInput>>;
  update?: Maybe<Array<PromoFacilityRuleUpdateWithWhereUniqueWithoutPromoInput>>;
  updateMany?: Maybe<Array<PromoFacilityRuleUpdateManyWithWhereWithoutPromoInput>>;
  upsert?: Maybe<Array<PromoFacilityRuleUpsertWithWhereUniqueWithoutPromoInput>>;
};

export type PromoFacilityRuleUpdateWithWhereUniqueWithoutFacilityInput = {
  data: PromoFacilityRuleUpdateWithoutFacilityInput;
  where: PromoFacilityRuleWhereUniqueInput;
};

export type PromoFacilityRuleUpdateWithWhereUniqueWithoutKitchensInput = {
  data: PromoFacilityRuleUpdateWithoutKitchensInput;
  where: PromoFacilityRuleWhereUniqueInput;
};

export type PromoFacilityRuleUpdateWithWhereUniqueWithoutPromoInput = {
  data: PromoFacilityRuleUpdateWithoutPromoInput;
  where: PromoFacilityRuleWhereUniqueInput;
};

export type PromoFacilityRuleUpdateWithoutFacilityInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isAllKitchens?: Maybe<BoolFieldUpdateOperationsInput>;
  kitchens?: Maybe<KitchenUpdateManyWithoutPromoFacilityRulesInput>;
  promo?: Maybe<PromoUpdateOneRequiredWithoutPromoFacilityRulesInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type PromoFacilityRuleUpdateWithoutKitchensInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  facility?: Maybe<FacilityUpdateOneRequiredWithoutPromoFacilityRulesInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isAllKitchens?: Maybe<BoolFieldUpdateOperationsInput>;
  promo?: Maybe<PromoUpdateOneRequiredWithoutPromoFacilityRulesInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type PromoFacilityRuleUpdateWithoutPromoInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  facility?: Maybe<FacilityUpdateOneRequiredWithoutPromoFacilityRulesInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isAllKitchens?: Maybe<BoolFieldUpdateOperationsInput>;
  kitchens?: Maybe<KitchenUpdateManyWithoutPromoFacilityRulesInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type PromoFacilityRuleUpsertWithWhereUniqueWithoutFacilityInput = {
  create: PromoFacilityRuleCreateWithoutFacilityInput;
  update: PromoFacilityRuleUpdateWithoutFacilityInput;
  where: PromoFacilityRuleWhereUniqueInput;
};

export type PromoFacilityRuleUpsertWithWhereUniqueWithoutKitchensInput = {
  create: PromoFacilityRuleCreateWithoutKitchensInput;
  update: PromoFacilityRuleUpdateWithoutKitchensInput;
  where: PromoFacilityRuleWhereUniqueInput;
};

export type PromoFacilityRuleUpsertWithWhereUniqueWithoutPromoInput = {
  create: PromoFacilityRuleCreateWithoutPromoInput;
  update: PromoFacilityRuleUpdateWithoutPromoInput;
  where: PromoFacilityRuleWhereUniqueInput;
};

export type PromoFacilityRuleWhereInput = {
  AND?: Maybe<Array<PromoFacilityRuleWhereInput>>;
  NOT?: Maybe<Array<PromoFacilityRuleWhereInput>>;
  OR?: Maybe<Array<PromoFacilityRuleWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  facility?: Maybe<FacilityWhereInput>;
  facilityId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  isAllKitchens?: Maybe<BoolFilter>;
  kitchens?: Maybe<KitchenListRelationFilter>;
  promo?: Maybe<PromoWhereInput>;
  promoId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type PromoFacilityRuleWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type PromoFacilityRulesInput = {
  facilityId: Scalars['ID'];
  id?: Maybe<Scalars['ID']>;
  isAllKitchens: Scalars['Boolean'];
  kitchens?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export enum PromoFilter {
  Active = 'ACTIVE',
  All = 'ALL',
  Expired = 'EXPIRED',
  GiftCard = 'GIFT_CARD',
  Inactive = 'INACTIVE',
  Scheduled = 'SCHEDULED'
}

export type PromoListRelationFilter = {
  every?: Maybe<PromoWhereInput>;
  none?: Maybe<PromoWhereInput>;
  some?: Maybe<PromoWhereInput>;
};

export enum PromoRequirement {
  MinAmount = 'MIN_AMOUNT',
  MinItems = 'MIN_ITEMS',
  None = 'NONE'
}

export enum PromoResetType {
  GiftCards = 'GIFT_CARDS',
  PromoCodes = 'PROMO_CODES'
}

export type PromoScalarWhereInput = {
  AND?: Maybe<Array<PromoScalarWhereInput>>;
  NOT?: Maybe<Array<PromoScalarWhereInput>>;
  OR?: Maybe<Array<PromoScalarWhereInput>>;
  appliesTo?: Maybe<EnumPromoAppliesToFilter>;
  appliesToDefinition?: Maybe<StringNullableFilter>;
  code?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  definition?: Maybe<StringNullableFilter>;
  description?: Maybe<StringNullableFilter>;
  eligibility?: Maybe<EnumPromoEligibilityFilter>;
  eligibilityDefinition?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  initialValue?: Maybe<FloatNullableFilter>;
  isAllKitchensAllFacilities?: Maybe<BoolFilter>;
  method?: Maybe<EnumOrderMethodNullableListFilter>;
  requirements?: Maybe<EnumPromoRequirementFilter>;
  requirementsDefinition?: Maybe<FloatNullableFilter>;
  status?: Maybe<EnumPromoStatusFilter>;
  tenantId?: Maybe<StringFilter>;
  type?: Maybe<EnumPromoTypeFilter>;
  typeDefinition?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  usageLimit?: Maybe<EnumPromoUsageLimitsFilter>;
  usageLimitDefinition?: Maybe<StringNullableFilter>;
  validSince?: Maybe<DateTimeFilter>;
  validTill?: Maybe<DateTimeNullableFilter>;
};

export enum PromoStatus {
  Active = 'ACTIVE',
  Deleted = 'DELETED',
  Disabled = 'DISABLED'
}

export enum PromoType {
  BuyXGetY = 'BUY_X_GET_Y',
  DiscountAmount = 'DISCOUNT_AMOUNT',
  DiscountPercentage = 'DISCOUNT_PERCENTAGE',
  FreeDelivery = 'FREE_DELIVERY',
  FreeX = 'FREE_X',
  GiftCard = 'GIFT_CARD'
}

export type PromoUpdateManyMutationInput = {
  appliesTo?: Maybe<EnumPromoAppliesToFieldUpdateOperationsInput>;
  appliesToDefinition?: Maybe<NullableStringFieldUpdateOperationsInput>;
  code?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  definition?: Maybe<NullableStringFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  eligibility?: Maybe<EnumPromoEligibilityFieldUpdateOperationsInput>;
  eligibilityDefinition?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  initialValue?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  isAllKitchensAllFacilities?: Maybe<BoolFieldUpdateOperationsInput>;
  method?: Maybe<PromoUpdatemethodInput>;
  requirements?: Maybe<EnumPromoRequirementFieldUpdateOperationsInput>;
  requirementsDefinition?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  status?: Maybe<EnumPromoStatusFieldUpdateOperationsInput>;
  type?: Maybe<EnumPromoTypeFieldUpdateOperationsInput>;
  typeDefinition?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  usageLimit?: Maybe<EnumPromoUsageLimitsFieldUpdateOperationsInput>;
  usageLimitDefinition?: Maybe<NullableStringFieldUpdateOperationsInput>;
  validSince?: Maybe<DateTimeFieldUpdateOperationsInput>;
  validTill?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type PromoUpdateManyWithWhereWithoutOrdersInput = {
  data: PromoUpdateManyMutationInput;
  where: PromoScalarWhereInput;
};

export type PromoUpdateManyWithWhereWithoutTenantInput = {
  data: PromoUpdateManyMutationInput;
  where: PromoScalarWhereInput;
};

export type PromoUpdateManyWithoutOrdersInput = {
  connect?: Maybe<Array<PromoWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PromoCreateOrConnectWithoutOrdersInput>>;
  create?: Maybe<Array<PromoCreateWithoutOrdersInput>>;
  delete?: Maybe<Array<PromoWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PromoScalarWhereInput>>;
  disconnect?: Maybe<Array<PromoWhereUniqueInput>>;
  set?: Maybe<Array<PromoWhereUniqueInput>>;
  update?: Maybe<Array<PromoUpdateWithWhereUniqueWithoutOrdersInput>>;
  updateMany?: Maybe<Array<PromoUpdateManyWithWhereWithoutOrdersInput>>;
  upsert?: Maybe<Array<PromoUpsertWithWhereUniqueWithoutOrdersInput>>;
};

export type PromoUpdateManyWithoutTenantInput = {
  connect?: Maybe<Array<PromoWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PromoCreateOrConnectWithoutTenantInput>>;
  create?: Maybe<Array<PromoCreateWithoutTenantInput>>;
  createMany?: Maybe<PromoCreateManyTenantInputEnvelope>;
  delete?: Maybe<Array<PromoWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PromoScalarWhereInput>>;
  disconnect?: Maybe<Array<PromoWhereUniqueInput>>;
  set?: Maybe<Array<PromoWhereUniqueInput>>;
  update?: Maybe<Array<PromoUpdateWithWhereUniqueWithoutTenantInput>>;
  updateMany?: Maybe<Array<PromoUpdateManyWithWhereWithoutTenantInput>>;
  upsert?: Maybe<Array<PromoUpsertWithWhereUniqueWithoutTenantInput>>;
};

export type PromoUpdateOneRequiredWithoutPromoFacilityRulesInput = {
  connect?: Maybe<PromoWhereUniqueInput>;
  connectOrCreate?: Maybe<PromoCreateOrConnectWithoutPromoFacilityRulesInput>;
  create?: Maybe<PromoCreateWithoutPromoFacilityRulesInput>;
  update?: Maybe<PromoUpdateWithoutPromoFacilityRulesInput>;
  upsert?: Maybe<PromoUpsertWithoutPromoFacilityRulesInput>;
};

export type PromoUpdateWithWhereUniqueWithoutOrdersInput = {
  data: PromoUpdateWithoutOrdersInput;
  where: PromoWhereUniqueInput;
};

export type PromoUpdateWithWhereUniqueWithoutTenantInput = {
  data: PromoUpdateWithoutTenantInput;
  where: PromoWhereUniqueInput;
};

export type PromoUpdateWithoutOrdersInput = {
  appliesTo?: Maybe<EnumPromoAppliesToFieldUpdateOperationsInput>;
  appliesToDefinition?: Maybe<NullableStringFieldUpdateOperationsInput>;
  code?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  definition?: Maybe<NullableStringFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  eligibility?: Maybe<EnumPromoEligibilityFieldUpdateOperationsInput>;
  eligibilityDefinition?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  initialValue?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  isAllKitchensAllFacilities?: Maybe<BoolFieldUpdateOperationsInput>;
  method?: Maybe<PromoUpdatemethodInput>;
  promoFacilityRules?: Maybe<PromoFacilityRuleUpdateManyWithoutPromoInput>;
  requirements?: Maybe<EnumPromoRequirementFieldUpdateOperationsInput>;
  requirementsDefinition?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  status?: Maybe<EnumPromoStatusFieldUpdateOperationsInput>;
  tenant?: Maybe<TenantUpdateOneRequiredWithoutPromosInput>;
  type?: Maybe<EnumPromoTypeFieldUpdateOperationsInput>;
  typeDefinition?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  usageLimit?: Maybe<EnumPromoUsageLimitsFieldUpdateOperationsInput>;
  usageLimitDefinition?: Maybe<NullableStringFieldUpdateOperationsInput>;
  validSince?: Maybe<DateTimeFieldUpdateOperationsInput>;
  validTill?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type PromoUpdateWithoutPromoFacilityRulesInput = {
  appliesTo?: Maybe<EnumPromoAppliesToFieldUpdateOperationsInput>;
  appliesToDefinition?: Maybe<NullableStringFieldUpdateOperationsInput>;
  code?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  definition?: Maybe<NullableStringFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  eligibility?: Maybe<EnumPromoEligibilityFieldUpdateOperationsInput>;
  eligibilityDefinition?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  initialValue?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  isAllKitchensAllFacilities?: Maybe<BoolFieldUpdateOperationsInput>;
  method?: Maybe<PromoUpdatemethodInput>;
  orders?: Maybe<OrderUpdateManyWithoutPromosInput>;
  requirements?: Maybe<EnumPromoRequirementFieldUpdateOperationsInput>;
  requirementsDefinition?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  status?: Maybe<EnumPromoStatusFieldUpdateOperationsInput>;
  tenant?: Maybe<TenantUpdateOneRequiredWithoutPromosInput>;
  type?: Maybe<EnumPromoTypeFieldUpdateOperationsInput>;
  typeDefinition?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  usageLimit?: Maybe<EnumPromoUsageLimitsFieldUpdateOperationsInput>;
  usageLimitDefinition?: Maybe<NullableStringFieldUpdateOperationsInput>;
  validSince?: Maybe<DateTimeFieldUpdateOperationsInput>;
  validTill?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type PromoUpdateWithoutTenantInput = {
  appliesTo?: Maybe<EnumPromoAppliesToFieldUpdateOperationsInput>;
  appliesToDefinition?: Maybe<NullableStringFieldUpdateOperationsInput>;
  code?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  definition?: Maybe<NullableStringFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  eligibility?: Maybe<EnumPromoEligibilityFieldUpdateOperationsInput>;
  eligibilityDefinition?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  initialValue?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  isAllKitchensAllFacilities?: Maybe<BoolFieldUpdateOperationsInput>;
  method?: Maybe<PromoUpdatemethodInput>;
  orders?: Maybe<OrderUpdateManyWithoutPromosInput>;
  promoFacilityRules?: Maybe<PromoFacilityRuleUpdateManyWithoutPromoInput>;
  requirements?: Maybe<EnumPromoRequirementFieldUpdateOperationsInput>;
  requirementsDefinition?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  status?: Maybe<EnumPromoStatusFieldUpdateOperationsInput>;
  type?: Maybe<EnumPromoTypeFieldUpdateOperationsInput>;
  typeDefinition?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  usageLimit?: Maybe<EnumPromoUsageLimitsFieldUpdateOperationsInput>;
  usageLimitDefinition?: Maybe<NullableStringFieldUpdateOperationsInput>;
  validSince?: Maybe<DateTimeFieldUpdateOperationsInput>;
  validTill?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type PromoUpdatemethodInput = {
  push?: Maybe<OrderMethod>;
  set?: Maybe<Array<OrderMethod>>;
};

export type PromoUpsertInput = {
  /** What the promo applies to (default: ORDER) */
  appliesTo: PromoAppliesTo;
  appliesToDefinition?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  /** Promo eligibility (default: EVERYONE) */
  eligibility: PromoEligibility;
  eligibilityDefinition?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  isAllKitchensAllFacilities: Scalars['Boolean'];
  /** Order method the promo applies to */
  method: Array<OrderMethod>;
  orderCount?: Maybe<Scalars['Int']>;
  promoFacilityRules?: Maybe<Array<Maybe<PromoFacilityRulesInput>>>;
  /** Promo requirements (default: NONE) */
  requirements: PromoRequirement;
  requirementsDefinition?: Maybe<Scalars['Float']>;
  /** Promo status (default: ACTIVE) */
  status: PromoStatus;
  /** Promo type (ex: DISCOUNT_PERCENTAGE) */
  type: PromoType;
  typeDefinition?: Maybe<Scalars['String']>;
  /** Promo usage limit (default: ONCE) */
  usageLimit: PromoUsageLimits;
  usageLimitDefinition?: Maybe<Scalars['String']>;
  /** Contains both start day (Date) and hours (Time) */
  validSince: Scalars['DateTime'];
  /** Contains both end day (Date) and hours (Time) */
  validTill?: Maybe<Scalars['DateTime']>;
};

export type PromoUpsertWithWhereUniqueWithoutOrdersInput = {
  create: PromoCreateWithoutOrdersInput;
  update: PromoUpdateWithoutOrdersInput;
  where: PromoWhereUniqueInput;
};

export type PromoUpsertWithWhereUniqueWithoutTenantInput = {
  create: PromoCreateWithoutTenantInput;
  update: PromoUpdateWithoutTenantInput;
  where: PromoWhereUniqueInput;
};

export type PromoUpsertWithoutPromoFacilityRulesInput = {
  create: PromoCreateWithoutPromoFacilityRulesInput;
  update: PromoUpdateWithoutPromoFacilityRulesInput;
};

export enum PromoUsageLimits {
  DayOfWeek = 'DAY_OF_WEEK',
  NumberOfTimes = 'NUMBER_OF_TIMES',
  Once = 'ONCE',
  OncePerDay = 'ONCE_PER_DAY'
}

export type PromoUsers = {
  __typename?: 'PromoUsers';
  otherUsers: Array<User>;
  selectedUsers: Array<User>;
};

export enum PromoValidationError {
  InvalidPromo = 'INVALID_PROMO',
  PromoBuyXGetYInvalid = 'PROMO_BUY_X_GET_Y_INVALID',
  PromoFreeDeliveryInvalid = 'PROMO_FREE_DELIVERY_INVALID',
  PromoFreeXInvalid = 'PROMO_FREE_X_INVALID',
  PromoIsNotCustomer = 'PROMO_IS_NOT_CUSTOMER',
  PromoIsNotEmployee = 'PROMO_IS_NOT_EMPLOYEE',
  PromoIsNotInSegmentGroup = 'PROMO_IS_NOT_IN_SEGMENT_GROUP',
  PromoNotApplicable = 'PROMO_NOT_APPLICABLE',
  PromoNotYetValid = 'PROMO_NOT_YET_VALID',
  PromoNoMoreValid = 'PROMO_NO_MORE_VALID',
  PromoUnderMinAmount = 'PROMO_UNDER_MIN_AMOUNT',
  PromoUnderMinItems = 'PROMO_UNDER_MIN_ITEMS',
  PromoWasUsed = 'PROMO_WAS_USED',
  PromoWasUsedX = 'PROMO_WAS_USED_X',
  PromoWrongDayOfWeek = 'PROMO_WRONG_DAY_OF_WEEK',
  PromoWrongOrderMethod = 'PROMO_WRONG_ORDER_METHOD',
  TooManyPromos = 'TOO_MANY_PROMOS'
}

export type PromoValidationErrorWithCode = {
  __typename?: 'PromoValidationErrorWithCode';
  error: PromoValidationError;
  promoCode: Scalars['String'];
};

export type PromoWhereInput = {
  AND?: Maybe<Array<PromoWhereInput>>;
  NOT?: Maybe<Array<PromoWhereInput>>;
  OR?: Maybe<Array<PromoWhereInput>>;
  appliesTo?: Maybe<EnumPromoAppliesToFilter>;
  appliesToDefinition?: Maybe<StringNullableFilter>;
  code?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  definition?: Maybe<StringNullableFilter>;
  description?: Maybe<StringNullableFilter>;
  eligibility?: Maybe<EnumPromoEligibilityFilter>;
  eligibilityDefinition?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  initialValue?: Maybe<FloatNullableFilter>;
  isAllKitchensAllFacilities?: Maybe<BoolFilter>;
  method?: Maybe<EnumOrderMethodNullableListFilter>;
  orders?: Maybe<OrderListRelationFilter>;
  promoFacilityRules?: Maybe<PromoFacilityRuleListRelationFilter>;
  requirements?: Maybe<EnumPromoRequirementFilter>;
  requirementsDefinition?: Maybe<FloatNullableFilter>;
  status?: Maybe<EnumPromoStatusFilter>;
  tenant?: Maybe<TenantWhereInput>;
  tenantId?: Maybe<StringFilter>;
  type?: Maybe<EnumPromoTypeFilter>;
  typeDefinition?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  usageLimit?: Maybe<EnumPromoUsageLimitsFilter>;
  usageLimitDefinition?: Maybe<StringNullableFilter>;
  validSince?: Maybe<DateTimeFilter>;
  validTill?: Maybe<DateTimeNullableFilter>;
};

export type PromoWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type PromosInput = {
  date?: Maybe<Scalars['DateTime']>;
  filter: PromoFilter;
  pagination: PaginationArg;
  query: Scalars['String'];
};

export type PromosList = {
  __typename?: 'PromosList';
  promos: Array<Promo>;
  total: Scalars['Int'];
};

export type Query = {
  __typename?: 'Query';
  addressAutocompleteOptions: Scalars['String'];
  /** For corporate admin to pull all kitchens from all facilities */
  adminAllKitchens: Array<Kitchen>;
  /** For Crave admin users to view menu schedules of concepts. */
  adminAllMenuSchedules: Array<MenuSchedule>;
  adminAvailablePowerBiReports: Array<AvailablePowerBiReport>;
  adminAvailablePowerBiWorkspaces: Array<AvailablePowerBiWorkspace>;
  adminDrivers: Array<Driver>;
  adminExtras: Array<Extra>;
  /** All available facilities to a user */
  adminFacilities: Array<Facility>;
  adminGetCartUpsellItems: Array<CartUpsellItem>;
  adminInviteUserRoles: Array<UserRole>;
  adminKitchen: Kitchen;
  adminKitchenMenuItem: KitchenMenuItem;
  adminKitchenMenuItems: Array<KitchenMenuItem>;
  adminKitchenModifier: Extra;
  adminKitchens: Array<Kitchen>;
  /** For admins to pull all available kitchens based on facilityId */
  adminKitchensByFacilityId: Array<Kitchen>;
  adminMenuCategories: Array<MenuCategory>;
  /** For concepts to view menu schedule detail. */
  adminMenuSchedule?: Maybe<MenuSchedule>;
  /** For concepts to view their menu schedules. */
  adminMenuSchedules: Array<MenuSchedule>;
  adminOrder: Order;
  adminOrderBundles: Array<OrderBundle>;
  /** For admins to view order method override */
  adminOrderMethodOverride?: Maybe<OrderMethodOverride>;
  /** For admins to view order method overrides */
  adminOrderMethodOverrides: Array<OrderMethodOverride>;
  adminOrders: Array<Order>;
  adminPortalUser?: Maybe<User>;
  adminPowerBiReportsAll: Array<PowerBiReport>;
  adminPowerBiReportsAndToken: PowerBiReportsAndToken;
  adminPromo?: Maybe<Promo>;
  adminPromoBanner?: Maybe<PromoBanner>;
  adminPromoUsers: PromoUsers;
  adminPromos: PromosList;
  adminRestaurantOwners: Array<User>;
  adminSegments: Array<Segment>;
  /** For Crave admin users to view special menu schedules. */
  adminSpecialMenuSchedules: Array<MenuSchedule>;
  adminStandardizedBrandRemittanceReport: Array<StandardizedBrandRemittanceReport>;
  adminStandardizedBrandSalesReport: Array<StandardizedBrandSalesReport>;
  adminStandardizedDailySalesReport: Array<StandardizedDailySalesReport>;
  adminStandardizedGiftCardReport: Array<StandardizedGiftCardReport>;
  adminStandardizedItemSalesReport: Array<StandardizedItemSalesReport>;
  adminStandardizedItemTransactionsReport: Array<StandardizedItemTransactionsReport>;
  adminStandardizedModifierSalesReport: Array<StandardizedModifierSalesReport>;
  adminStandardizedOperationsDetailedReport: Array<StandardizedOperationsDetailedReport>;
  adminStandardizedPartnerRemittanceReport: Array<StandardizedPartnerRemittanceReport>;
  adminStandardizedPlatformMixReport: Array<StandardizedPlatformMixReport>;
  adminStandardizedPromoReport: Array<StandardizedPromoReport>;
  adminStandardizedTimeOfDayBreakdownReport: Array<StandardizedTimeOfDayBreakdownReport>;
  adminStandardizedTransactionsReport: Array<StandardizedTransactionsReport>;
  adminStation: Station;
  adminStations: Array<Station>;
  adminTags: Array<Tag>;
  adminWorkplaces: Array<Workplace>;
  /** get upsell menu items by parent item id */
  cartUpsellItems: Array<CartUpsellItem>;
  cateringUserAddress: Address;
  customer: User;
  /** For customers to know which dates are available for given order method */
  customerAvailableDates: Array<Scalars['DateTime']>;
  customerCartCosts: CartCosts;
  customerCartPaymentUrl: CartPaymentUrl;
  customerDeliveryInfos: Array<DeliveryInfo>;
  /** For customers to choose from available facilities */
  customerFacilities: Array<Facility>;
  /** For customers to view menu */
  customerFullMenu: Array<CustomerKitchen>;
  customerKitchen: CustomerKitchen;
  customerKitchenMenuItem: KitchenMenuItem;
  /** For customers to view available kitchens. Kitchens are sorted by sortId. */
  customerKitchens: Array<CustomerKitchen>;
  /** @deprecated Regardless of currently selected time */
  customerKitchensAll: Array<CustomerKitchen>;
  /**
   * All kitchens open first, closed second.
   *
   * For CONSUMER platform only (Menu items are filtrated by CA availability)
   */
  customerKitchensAllSortedByOpen: Array<CustomerKitchenByOpen>;
  customerKitchensItemSearch: Array<KitchenMenuItem>;
  /** For customers to view menu */
  customerMenu: Array<CustomerKitchen>;
  /** For customers to display list of categories available within selected time */
  customerMenuCategories: Array<MenuCategory>;
  /** For customers to display list of categories available within selected time */
  customerMenuKitchens: Array<CustomerKitchenInList>;
  customerOrder: Order;
  customerOrders: Array<Order>;
  customerPayrocStoredCards: Array<PayrocCustomerCard>;
  customerPromoBanner?: Maybe<PromoBanner>;
  /** For customers to view special menu */
  customerSpecialMenu: Array<MenuCategory>;
  /** For customers to view suggested menu */
  customerSuggestedItems: Array<MenuCategory>;
  customerTilledSettings: TilledCustomerSettings;
  /**
   * For customers to know which time slots are available for given date and order method.
   *
   * Returned time slots are in UTC
   *
   * @consumer: Time slots are filtrated by menu schedules so only time slots with scheduled menus in the time are returned
   */
  customerTimeslots: Array<Timeslot>;
  customers: CustomersReturnType;
  /** Stations in a facility */
  facilityStations: Array<Station>;
  /** Workplaces in a facility */
  facilityWorkplaces: Array<Workplace>;
  /** Get all stations for KDS */
  getKdsStations: Array<Station>;
  kdsDoneKitchenTickets: Array<KitchenTicket>;
  kdsDoneOrders: Array<Order>;
  kdsDoneStationTickets: Array<KitchenTicket>;
  kdsFacilityOrders: Array<Order>;
  kdsKitchenTicket: KitchenTicket;
  kdsKitchenTickets: Array<KitchenTicket>;
  kdsOrderBundles: Array<OrderBundle>;
  kdsOrderStatistics: OrderStatistics;
  kdsOrders: Array<Order>;
  kdsStationDoneOrders: Array<Order>;
  kdsStationOrders: Array<Order>;
  kdsStationStatistics: StationStatistics;
  kdsStationTickets: Array<KitchenTicket>;
  kdsTicketStatistics: TicketStatistics;
  kioskCartCosts: CartCosts;
  kioskTags: Array<Tag>;
  kitchenBySlugAndFacilitySlug?: Maybe<Kitchen>;
  me?: Maybe<User>;
  orderItemsRefundable: Array<OrderItem>;
  orderRefundPreview: OrderRefundPreview;
  platformUsers: PlatformUsers;
  scheduledNotifications: Scalars['Boolean'];
  tenant: Tenant;
};


export type QueryAddressAutocompleteOptionsArgs = {
  input: Scalars['String'];
};


export type QueryAdminAllMenuSchedulesArgs = {
  end: Scalars['DateTime'];
  kitchenIds: Array<Scalars['ID']>;
  start: Scalars['DateTime'];
};


export type QueryAdminAvailablePowerBiReportsArgs = {
  workspaceId: Scalars['String'];
};


export type QueryAdminExtrasArgs = {
  kitchenId: Scalars['ID'];
};


export type QueryAdminGetCartUpsellItemsArgs = {
  facilityId: Scalars['String'];
  platform: ClientPlatformInput;
};


export type QueryAdminKitchenArgs = {
  kitchenId: Scalars['ID'];
};


export type QueryAdminKitchenMenuItemArgs = {
  id: Scalars['ID'];
};


export type QueryAdminKitchenMenuItemsArgs = {
  activeItems?: Maybe<Scalars['Boolean']>;
  availability?: Maybe<Array<MenuItemAvailability>>;
  kitchenIds?: Maybe<Array<Scalars['ID']>>;
  menuCategoryType?: Maybe<MenuCategoryType>;
  parentUpsellItemId?: Maybe<Scalars['ID']>;
  searchTerm?: Maybe<Scalars['String']>;
};


export type QueryAdminKitchenModifierArgs = {
  id: Scalars['ID'];
};


export type QueryAdminKitchensArgs = {
  activeKitchens?: Maybe<Scalars['Boolean']>;
};


export type QueryAdminKitchensByFacilityIdArgs = {
  facilityId: Scalars['ID'];
};


export type QueryAdminMenuCategoriesArgs = {
  type?: Maybe<MenuCategoryType>;
};


export type QueryAdminMenuScheduleArgs = {
  id: Scalars['ID'];
};


export type QueryAdminMenuSchedulesArgs = {
  end: Scalars['DateTime'];
  kitchenId: Scalars['ID'];
  start: Scalars['DateTime'];
};


export type QueryAdminOrderArgs = {
  id: Scalars['ID'];
};


export type QueryAdminOrderBundlesArgs = {
  end: Scalars['DateTime'];
  start: Scalars['DateTime'];
  statuses?: Maybe<Array<Maybe<OrderBundleStatus>>>;
};


export type QueryAdminOrderMethodOverrideArgs = {
  id: Scalars['ID'];
};


export type QueryAdminOrderMethodOverridesArgs = {
  end: Scalars['DateTime'];
  start: Scalars['DateTime'];
};


export type QueryAdminOrdersArgs = {
  bundled?: Maybe<Scalars['Boolean']>;
  customerId?: Maybe<Scalars['ID']>;
  end?: Maybe<Scalars['DateTime']>;
  isCatering?: Maybe<Scalars['Boolean']>;
  method?: Maybe<OrderMethod>;
  start?: Maybe<Scalars['DateTime']>;
};


export type QueryAdminPortalUserArgs = {
  id: Scalars['ID'];
};


export type QueryAdminPowerBiReportsAllArgs = {
  after?: Maybe<PowerBiReportWhereUniqueInput>;
  before?: Maybe<PowerBiReportWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<QueryPowerBiReportsOrderByInput>>;
};


export type QueryAdminPromoArgs = {
  where: PromoWhereUniqueInput;
};


export type QueryAdminPromoUsersArgs = {
  isEmployee: Scalars['Boolean'];
  pagination: PaginationArg;
  query?: Maybe<Scalars['String']>;
  selectedUsers?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type QueryAdminPromosArgs = {
  input: PromosInput;
};


export type QueryAdminSpecialMenuSchedulesArgs = {
  end: Scalars['DateTime'];
  start: Scalars['DateTime'];
};


export type QueryAdminStandardizedBrandRemittanceReportArgs = {
  endDate?: Maybe<Scalars['DateTime']>;
  facilityId?: Maybe<Scalars['String']>;
  facilityIds: Array<Scalars['ID']>;
  kitchenIds: Array<Scalars['ID']>;
  role?: Maybe<UserRole>;
  startDate?: Maybe<Scalars['DateTime']>;
};


export type QueryAdminStandardizedBrandSalesReportArgs = {
  endDate?: Maybe<Scalars['DateTime']>;
  facilityId?: Maybe<Scalars['String']>;
  facilityIds: Array<Scalars['ID']>;
  kitchenIds: Array<Scalars['ID']>;
  role?: Maybe<UserRole>;
  startDate?: Maybe<Scalars['DateTime']>;
};


export type QueryAdminStandardizedDailySalesReportArgs = {
  endDate?: Maybe<Scalars['DateTime']>;
  facilityId?: Maybe<Scalars['String']>;
  facilityIds: Array<Scalars['ID']>;
  kitchenIds: Array<Scalars['ID']>;
  role?: Maybe<UserRole>;
  startDate?: Maybe<Scalars['DateTime']>;
};


export type QueryAdminStandardizedGiftCardReportArgs = {
  endDate?: Maybe<Scalars['DateTime']>;
  facilityId?: Maybe<Scalars['String']>;
  facilityIds: Array<Scalars['ID']>;
  kitchenIds: Array<Scalars['ID']>;
  role?: Maybe<UserRole>;
  startDate?: Maybe<Scalars['DateTime']>;
};


export type QueryAdminStandardizedItemSalesReportArgs = {
  endDate?: Maybe<Scalars['DateTime']>;
  facilityId?: Maybe<Scalars['String']>;
  facilityIds: Array<Scalars['ID']>;
  kitchenIds: Array<Scalars['ID']>;
  role?: Maybe<UserRole>;
  startDate?: Maybe<Scalars['DateTime']>;
};


export type QueryAdminStandardizedItemTransactionsReportArgs = {
  endDate?: Maybe<Scalars['DateTime']>;
  facilityId?: Maybe<Scalars['String']>;
  facilityIds: Array<Scalars['ID']>;
  kitchenIds: Array<Scalars['ID']>;
  role?: Maybe<UserRole>;
  startDate?: Maybe<Scalars['DateTime']>;
};


export type QueryAdminStandardizedModifierSalesReportArgs = {
  endDate?: Maybe<Scalars['DateTime']>;
  facilityId?: Maybe<Scalars['String']>;
  facilityIds: Array<Scalars['ID']>;
  kitchenIds: Array<Scalars['ID']>;
  role?: Maybe<UserRole>;
  startDate?: Maybe<Scalars['DateTime']>;
};


export type QueryAdminStandardizedOperationsDetailedReportArgs = {
  endDate?: Maybe<Scalars['DateTime']>;
  facilityId?: Maybe<Scalars['String']>;
  facilityIds: Array<Scalars['ID']>;
  kitchenIds: Array<Scalars['ID']>;
  role?: Maybe<UserRole>;
  startDate?: Maybe<Scalars['DateTime']>;
};


export type QueryAdminStandardizedPartnerRemittanceReportArgs = {
  endDate?: Maybe<Scalars['DateTime']>;
  facilityId?: Maybe<Scalars['String']>;
  facilityIds: Array<Scalars['ID']>;
  kitchenIds: Array<Scalars['ID']>;
  role?: Maybe<UserRole>;
  startDate?: Maybe<Scalars['DateTime']>;
};


export type QueryAdminStandardizedPlatformMixReportArgs = {
  endDate?: Maybe<Scalars['DateTime']>;
  facilityId?: Maybe<Scalars['String']>;
  facilityIds: Array<Scalars['ID']>;
  kitchenIds: Array<Scalars['ID']>;
  role?: Maybe<UserRole>;
  startDate?: Maybe<Scalars['DateTime']>;
};


export type QueryAdminStandardizedPromoReportArgs = {
  endDate?: Maybe<Scalars['DateTime']>;
  facilityId?: Maybe<Scalars['String']>;
  facilityIds: Array<Scalars['ID']>;
  kitchenIds: Array<Scalars['ID']>;
  role?: Maybe<UserRole>;
  startDate?: Maybe<Scalars['DateTime']>;
};


export type QueryAdminStandardizedTimeOfDayBreakdownReportArgs = {
  endDate?: Maybe<Scalars['DateTime']>;
  facilityId?: Maybe<Scalars['String']>;
  facilityIds: Array<Scalars['ID']>;
  kitchenIds: Array<Scalars['ID']>;
  role?: Maybe<UserRole>;
  startDate?: Maybe<Scalars['DateTime']>;
};


export type QueryAdminStandardizedTransactionsReportArgs = {
  endDate?: Maybe<Scalars['DateTime']>;
  facilityId?: Maybe<Scalars['String']>;
  facilityIds: Array<Scalars['ID']>;
  kitchenIds: Array<Scalars['ID']>;
  role?: Maybe<UserRole>;
  startDate?: Maybe<Scalars['DateTime']>;
};


export type QueryAdminStationArgs = {
  id: Scalars['ID'];
};


export type QueryAdminStationsArgs = {
  where?: Maybe<StationWhereInput>;
};


export type QueryAdminTagsArgs = {
  pagination: PaginationArg;
  query?: Maybe<Scalars['String']>;
};


export type QueryAdminWorkplacesArgs = {
  where?: Maybe<WorkplaceWhereInput>;
};


export type QueryCartUpsellItemsArgs = {
  requestedDate?: Maybe<Scalars['DateTime']>;
};


export type QueryCateringUserAddressArgs = {
  placeId: Scalars['ID'];
};


export type QueryCustomerArgs = {
  id: Scalars['ID'];
};


export type QueryCustomerAvailableDatesArgs = {
  kitchenId?: Maybe<Scalars['ID']>;
  orderMethod: OrderMethod;
};


export type QueryCustomerCartCostsArgs = {
  addressId?: Maybe<Scalars['ID']>;
  orderMethod: OrderMethod;
  placeId?: Maybe<Scalars['String']>;
};


export type QueryCustomerCartPaymentUrlArgs = {
  cardReference?: Maybe<Scalars['String']>;
  isIframe?: Maybe<Scalars['Boolean']>;
  order: OrderInput;
};


export type QueryCustomerDeliveryInfosArgs = {
  placeId: Scalars['String'];
};


export type QueryCustomerFullMenuArgs = {
  date?: Maybe<Scalars['DateTime']>;
};


export type QueryCustomerKitchenArgs = {
  date?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
};


export type QueryCustomerKitchenMenuItemArgs = {
  id: Scalars['ID'];
};


export type QueryCustomerKitchensArgs = {
  date?: Maybe<Scalars['DateTime']>;
};


export type QueryCustomerKitchensAllArgs = {
  facilityId?: Maybe<Scalars['ID']>;
};


export type QueryCustomerKitchensAllSortedByOpenArgs = {
  date?: Maybe<Scalars['DateTime']>;
};


export type QueryCustomerKitchensItemSearchArgs = {
  searchValue?: Maybe<Scalars['String']>;
};


export type QueryCustomerMenuArgs = {
  categoryId?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
  kitchenId?: Maybe<Scalars['String']>;
  orderMethod?: OrderMethod;
  preselectFirstCategory?: Maybe<Scalars['Boolean']>;
};


export type QueryCustomerMenuCategoriesArgs = {
  date?: Maybe<Scalars['DateTime']>;
  kitchenId?: Maybe<Scalars['String']>;
};


export type QueryCustomerMenuKitchensArgs = {
  date?: Maybe<Scalars['DateTime']>;
};


export type QueryCustomerOrderArgs = {
  id: Scalars['ID'];
};


export type QueryCustomerSpecialMenuArgs = {
  date?: Maybe<Scalars['DateTime']>;
};


export type QueryCustomerSuggestedItemsArgs = {
  date?: Maybe<Scalars['DateTime']>;
};


export type QueryCustomerTimeslotsArgs = {
  date: Scalars['DateTime'];
  kitchenId?: Maybe<Scalars['ID']>;
  orderMethod: OrderMethod;
};


export type QueryCustomersArgs = {
  nameFilter: Scalars['String'];
  orderBy: UserOrderByArg;
  pagination: PaginationArg;
};


export type QueryFacilityStationsArgs = {
  id: Scalars['ID'];
};


export type QueryFacilityWorkplacesArgs = {
  id: Scalars['ID'];
};


export type QueryKdsDoneKitchenTicketsArgs = {
  kitchenId: Scalars['ID'];
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryKdsDoneOrdersArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  type: KdsOrdersWorkplace;
};


export type QueryKdsDoneStationTicketsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  stationId: Scalars['ID'];
};


export type QueryKdsFacilityOrdersArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  shortId?: Maybe<Scalars['String']>;
};


export type QueryKdsKitchenTicketArgs = {
  id: Scalars['ID'];
};


export type QueryKdsKitchenTicketsArgs = {
  endDate?: Maybe<Scalars['DateTime']>;
  kitchenId: Scalars['ID'];
  startDate?: Maybe<Scalars['DateTime']>;
};


export type QueryKdsOrdersArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  type: KdsOrdersWorkplace;
  workplace?: Maybe<Scalars['Int']>;
};


export type QueryKdsStationDoneOrdersArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryKdsStationOrdersArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  type: KdsStationOrdersWorkplace;
  workplace?: Maybe<Scalars['Int']>;
};


export type QueryKdsStationTicketsArgs = {
  endDate?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  stationId: Scalars['ID'];
};


export type QueryKioskCartCostsArgs = {
  cartInput?: Maybe<Scalars['String']>;
};


export type QueryKioskTagsArgs = {
  query?: Maybe<Scalars['String']>;
};


export type QueryKitchenBySlugAndFacilitySlugArgs = {
  facilitySlug: Scalars['String'];
  slug: Scalars['String'];
};


export type QueryOrderItemsRefundableArgs = {
  orderId: Scalars['ID'];
  ticketId?: Maybe<Scalars['ID']>;
};


export type QueryOrderRefundPreviewArgs = {
  request: RefundInput;
};


export type QueryPlatformUsersArgs = {
  nameFilter?: Maybe<Scalars['String']>;
  orderBy: AdminPortalUserOrderByArg;
  pagination: PaginationArg;
};

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive'
}

export type QueryPowerBiReportsOrderByInput = {
  sortId?: Maybe<SortOrder>;
};

export type RefireKitchenTicketInput = {
  delay: Scalars['Int'];
  kitchenTicketId: Scalars['String'];
  reason?: Maybe<RefireReason>;
};

export type RefireOrderInput = {
  delay: Scalars['Int'];
  orderId: Scalars['String'];
  reason?: Maybe<RefireReason>;
};

export type RefireOrderItemInput = {
  delay: Scalars['Int'];
  facilityItemId: Scalars['String'];
  quantity: Scalars['Int'];
  reason?: Maybe<RefireReason>;
};

export enum RefireReason {
  MissingItem = 'MISSING_ITEM',
  NotFresh = 'NOT_FRESH',
  WrongIngredients = 'WRONG_INGREDIENTS',
  WrongPresentation = 'WRONG_PRESENTATION',
  WrongTemperature = 'WRONG_TEMPERATURE'
}

export type RefundCreateManyOrderInput = {
  appliedPromos?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creditCardRefund: Scalars['Float'];
  deliveryFee: Scalars['Float'];
  deliveryFeeTax: Scalars['Float'];
  gratuity: Scalars['Float'];
  id?: Maybe<Scalars['String']>;
  itemsTax: Scalars['Float'];
  reason: RefundReason;
  refundedGiftCards?: Maybe<Scalars['Json']>;
  serviceFee: Scalars['Float'];
  serviceFeeTax: Scalars['Float'];
  subTotalGross: Scalars['Float'];
  subTotalNet: Scalars['Float'];
  totalGiftCardRefund: Scalars['Float'];
  totalRefund: Scalars['Float'];
  type: RefundType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RefundCreateManyOrderInputEnvelope = {
  data?: Maybe<Array<RefundCreateManyOrderInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type RefundCreateNestedManyWithoutOrderInput = {
  connect?: Maybe<Array<RefundWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<RefundCreateOrConnectWithoutOrderInput>>;
  create?: Maybe<Array<RefundCreateWithoutOrderInput>>;
  createMany?: Maybe<RefundCreateManyOrderInputEnvelope>;
};

export type RefundCreateNestedOneWithoutItemsInput = {
  connect?: Maybe<RefundWhereUniqueInput>;
  connectOrCreate?: Maybe<RefundCreateOrConnectWithoutItemsInput>;
  create?: Maybe<RefundCreateWithoutItemsInput>;
};

export type RefundCreateNestedOneWithoutLineItemRefundInput = {
  connect?: Maybe<RefundWhereUniqueInput>;
  connectOrCreate?: Maybe<RefundCreateOrConnectWithoutLineItemRefundInput>;
  create?: Maybe<RefundCreateWithoutLineItemRefundInput>;
};

export type RefundCreateOrConnectWithoutItemsInput = {
  create: RefundCreateWithoutItemsInput;
  where: RefundWhereUniqueInput;
};

export type RefundCreateOrConnectWithoutLineItemRefundInput = {
  create: RefundCreateWithoutLineItemRefundInput;
  where: RefundWhereUniqueInput;
};

export type RefundCreateOrConnectWithoutOrderInput = {
  create: RefundCreateWithoutOrderInput;
  where: RefundWhereUniqueInput;
};

export type RefundCreateWithoutItemsInput = {
  LineItemRefund?: Maybe<LineItemRefundCreateNestedManyWithoutRefundInput>;
  appliedPromos?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creditCardRefund: Scalars['Float'];
  deliveryFee: Scalars['Float'];
  deliveryFeeTax: Scalars['Float'];
  gratuity: Scalars['Float'];
  id?: Maybe<Scalars['String']>;
  itemsTax: Scalars['Float'];
  order: OrderCreateNestedOneWithoutRefundInput;
  reason: RefundReason;
  refundedGiftCards?: Maybe<Scalars['Json']>;
  serviceFee: Scalars['Float'];
  serviceFeeTax: Scalars['Float'];
  subTotalGross: Scalars['Float'];
  subTotalNet: Scalars['Float'];
  totalGiftCardRefund: Scalars['Float'];
  totalRefund: Scalars['Float'];
  type: RefundType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RefundCreateWithoutLineItemRefundInput = {
  appliedPromos?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creditCardRefund: Scalars['Float'];
  deliveryFee: Scalars['Float'];
  deliveryFeeTax: Scalars['Float'];
  gratuity: Scalars['Float'];
  id?: Maybe<Scalars['String']>;
  items?: Maybe<RefundItemCreateNestedManyWithoutRefundInput>;
  itemsTax: Scalars['Float'];
  order: OrderCreateNestedOneWithoutRefundInput;
  reason: RefundReason;
  refundedGiftCards?: Maybe<Scalars['Json']>;
  serviceFee: Scalars['Float'];
  serviceFeeTax: Scalars['Float'];
  subTotalGross: Scalars['Float'];
  subTotalNet: Scalars['Float'];
  totalGiftCardRefund: Scalars['Float'];
  totalRefund: Scalars['Float'];
  type: RefundType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RefundCreateWithoutOrderInput = {
  LineItemRefund?: Maybe<LineItemRefundCreateNestedManyWithoutRefundInput>;
  appliedPromos?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creditCardRefund: Scalars['Float'];
  deliveryFee: Scalars['Float'];
  deliveryFeeTax: Scalars['Float'];
  gratuity: Scalars['Float'];
  id?: Maybe<Scalars['String']>;
  items?: Maybe<RefundItemCreateNestedManyWithoutRefundInput>;
  itemsTax: Scalars['Float'];
  reason: RefundReason;
  refundedGiftCards?: Maybe<Scalars['Json']>;
  serviceFee: Scalars['Float'];
  serviceFeeTax: Scalars['Float'];
  subTotalGross: Scalars['Float'];
  subTotalNet: Scalars['Float'];
  totalGiftCardRefund: Scalars['Float'];
  totalRefund: Scalars['Float'];
  type: RefundType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RefundInput = {
  items: Array<ItemWithQuantityInput>;
  orderId: Scalars['ID'];
  reason: RefundReason;
  type: RefundType;
};

export type RefundItem = {
  __typename?: 'RefundItem';
  id: Scalars['String'];
  orderItem: OrderItem;
  orderItemId: Scalars['String'];
  quantity: Scalars['Int'];
};

export type RefundItemCreateManyOrderItemInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  quantity: Scalars['Int'];
  refundId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RefundItemCreateManyOrderItemInputEnvelope = {
  data?: Maybe<Array<RefundItemCreateManyOrderItemInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type RefundItemCreateManyRefundInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  orderItemId: Scalars['String'];
  quantity: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RefundItemCreateManyRefundInputEnvelope = {
  data?: Maybe<Array<RefundItemCreateManyRefundInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type RefundItemCreateNestedManyWithoutOrderItemInput = {
  connect?: Maybe<Array<RefundItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<RefundItemCreateOrConnectWithoutOrderItemInput>>;
  create?: Maybe<Array<RefundItemCreateWithoutOrderItemInput>>;
  createMany?: Maybe<RefundItemCreateManyOrderItemInputEnvelope>;
};

export type RefundItemCreateNestedManyWithoutRefundInput = {
  connect?: Maybe<Array<RefundItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<RefundItemCreateOrConnectWithoutRefundInput>>;
  create?: Maybe<Array<RefundItemCreateWithoutRefundInput>>;
  createMany?: Maybe<RefundItemCreateManyRefundInputEnvelope>;
};

export type RefundItemCreateOrConnectWithoutOrderItemInput = {
  create: RefundItemCreateWithoutOrderItemInput;
  where: RefundItemWhereUniqueInput;
};

export type RefundItemCreateOrConnectWithoutRefundInput = {
  create: RefundItemCreateWithoutRefundInput;
  where: RefundItemWhereUniqueInput;
};

export type RefundItemCreateWithoutOrderItemInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  quantity: Scalars['Int'];
  refund: RefundCreateNestedOneWithoutItemsInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RefundItemCreateWithoutRefundInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  orderItem: OrderItemCreateNestedOneWithoutRefundItemInput;
  quantity: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RefundItemListRelationFilter = {
  every?: Maybe<RefundItemWhereInput>;
  none?: Maybe<RefundItemWhereInput>;
  some?: Maybe<RefundItemWhereInput>;
};

export type RefundItemRefundIdOrderItemIdCompoundUniqueInput = {
  orderItemId: Scalars['String'];
  refundId: Scalars['String'];
};

export type RefundItemScalarWhereInput = {
  AND?: Maybe<Array<RefundItemScalarWhereInput>>;
  NOT?: Maybe<Array<RefundItemScalarWhereInput>>;
  OR?: Maybe<Array<RefundItemScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  orderItemId?: Maybe<StringFilter>;
  quantity?: Maybe<IntFilter>;
  refundId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type RefundItemUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  quantity?: Maybe<IntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type RefundItemUpdateManyWithWhereWithoutOrderItemInput = {
  data: RefundItemUpdateManyMutationInput;
  where: RefundItemScalarWhereInput;
};

export type RefundItemUpdateManyWithWhereWithoutRefundInput = {
  data: RefundItemUpdateManyMutationInput;
  where: RefundItemScalarWhereInput;
};

export type RefundItemUpdateManyWithoutOrderItemInput = {
  connect?: Maybe<Array<RefundItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<RefundItemCreateOrConnectWithoutOrderItemInput>>;
  create?: Maybe<Array<RefundItemCreateWithoutOrderItemInput>>;
  createMany?: Maybe<RefundItemCreateManyOrderItemInputEnvelope>;
  delete?: Maybe<Array<RefundItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<RefundItemScalarWhereInput>>;
  disconnect?: Maybe<Array<RefundItemWhereUniqueInput>>;
  set?: Maybe<Array<RefundItemWhereUniqueInput>>;
  update?: Maybe<Array<RefundItemUpdateWithWhereUniqueWithoutOrderItemInput>>;
  updateMany?: Maybe<Array<RefundItemUpdateManyWithWhereWithoutOrderItemInput>>;
  upsert?: Maybe<Array<RefundItemUpsertWithWhereUniqueWithoutOrderItemInput>>;
};

export type RefundItemUpdateManyWithoutRefundInput = {
  connect?: Maybe<Array<RefundItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<RefundItemCreateOrConnectWithoutRefundInput>>;
  create?: Maybe<Array<RefundItemCreateWithoutRefundInput>>;
  createMany?: Maybe<RefundItemCreateManyRefundInputEnvelope>;
  delete?: Maybe<Array<RefundItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<RefundItemScalarWhereInput>>;
  disconnect?: Maybe<Array<RefundItemWhereUniqueInput>>;
  set?: Maybe<Array<RefundItemWhereUniqueInput>>;
  update?: Maybe<Array<RefundItemUpdateWithWhereUniqueWithoutRefundInput>>;
  updateMany?: Maybe<Array<RefundItemUpdateManyWithWhereWithoutRefundInput>>;
  upsert?: Maybe<Array<RefundItemUpsertWithWhereUniqueWithoutRefundInput>>;
};

export type RefundItemUpdateWithWhereUniqueWithoutOrderItemInput = {
  data: RefundItemUpdateWithoutOrderItemInput;
  where: RefundItemWhereUniqueInput;
};

export type RefundItemUpdateWithWhereUniqueWithoutRefundInput = {
  data: RefundItemUpdateWithoutRefundInput;
  where: RefundItemWhereUniqueInput;
};

export type RefundItemUpdateWithoutOrderItemInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  quantity?: Maybe<IntFieldUpdateOperationsInput>;
  refund?: Maybe<RefundUpdateOneRequiredWithoutItemsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type RefundItemUpdateWithoutRefundInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  orderItem?: Maybe<OrderItemUpdateOneRequiredWithoutRefundItemInput>;
  quantity?: Maybe<IntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type RefundItemUpsertWithWhereUniqueWithoutOrderItemInput = {
  create: RefundItemCreateWithoutOrderItemInput;
  update: RefundItemUpdateWithoutOrderItemInput;
  where: RefundItemWhereUniqueInput;
};

export type RefundItemUpsertWithWhereUniqueWithoutRefundInput = {
  create: RefundItemCreateWithoutRefundInput;
  update: RefundItemUpdateWithoutRefundInput;
  where: RefundItemWhereUniqueInput;
};

export type RefundItemWhereInput = {
  AND?: Maybe<Array<RefundItemWhereInput>>;
  NOT?: Maybe<Array<RefundItemWhereInput>>;
  OR?: Maybe<Array<RefundItemWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  orderItem?: Maybe<OrderItemWhereInput>;
  orderItemId?: Maybe<StringFilter>;
  quantity?: Maybe<IntFilter>;
  refund?: Maybe<RefundWhereInput>;
  refundId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type RefundItemWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  refundId_orderItemId?: Maybe<RefundItemRefundIdOrderItemIdCompoundUniqueInput>;
};

export type RefundListRelationFilter = {
  every?: Maybe<RefundWhereInput>;
  none?: Maybe<RefundWhereInput>;
  some?: Maybe<RefundWhereInput>;
};

export enum RefundReason {
  CustomerRequest = 'CUSTOMER_REQUEST',
  KitchenClosed = 'KITCHEN_CLOSED',
  Other = 'OTHER',
  OutOfStock = 'OUT_OF_STOCK',
  StaffingIssue = 'STAFFING_ISSUE'
}

export type RefundScalarWhereInput = {
  AND?: Maybe<Array<RefundScalarWhereInput>>;
  NOT?: Maybe<Array<RefundScalarWhereInput>>;
  OR?: Maybe<Array<RefundScalarWhereInput>>;
  appliedPromos?: Maybe<JsonNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  creditCardRefund?: Maybe<FloatFilter>;
  deliveryFee?: Maybe<FloatFilter>;
  deliveryFeeTax?: Maybe<FloatFilter>;
  gratuity?: Maybe<FloatFilter>;
  id?: Maybe<StringFilter>;
  itemsTax?: Maybe<FloatFilter>;
  orderId?: Maybe<StringFilter>;
  reason?: Maybe<EnumRefundReasonFilter>;
  refundedGiftCards?: Maybe<JsonNullableFilter>;
  serviceFee?: Maybe<FloatFilter>;
  serviceFeeTax?: Maybe<FloatFilter>;
  subTotalGross?: Maybe<FloatFilter>;
  subTotalNet?: Maybe<FloatFilter>;
  totalGiftCardRefund?: Maybe<FloatFilter>;
  totalRefund?: Maybe<FloatFilter>;
  type?: Maybe<EnumRefundTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export enum RefundType {
  Cancel = 'CANCEL',
  Compensate = 'COMPENSATE'
}

export type RefundUpdateManyMutationInput = {
  appliedPromos?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  creditCardRefund?: Maybe<FloatFieldUpdateOperationsInput>;
  deliveryFee?: Maybe<FloatFieldUpdateOperationsInput>;
  deliveryFeeTax?: Maybe<FloatFieldUpdateOperationsInput>;
  gratuity?: Maybe<FloatFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  itemsTax?: Maybe<FloatFieldUpdateOperationsInput>;
  reason?: Maybe<EnumRefundReasonFieldUpdateOperationsInput>;
  refundedGiftCards?: Maybe<Scalars['Json']>;
  serviceFee?: Maybe<FloatFieldUpdateOperationsInput>;
  serviceFeeTax?: Maybe<FloatFieldUpdateOperationsInput>;
  subTotalGross?: Maybe<FloatFieldUpdateOperationsInput>;
  subTotalNet?: Maybe<FloatFieldUpdateOperationsInput>;
  totalGiftCardRefund?: Maybe<FloatFieldUpdateOperationsInput>;
  totalRefund?: Maybe<FloatFieldUpdateOperationsInput>;
  type?: Maybe<EnumRefundTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type RefundUpdateManyWithWhereWithoutOrderInput = {
  data: RefundUpdateManyMutationInput;
  where: RefundScalarWhereInput;
};

export type RefundUpdateManyWithoutOrderInput = {
  connect?: Maybe<Array<RefundWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<RefundCreateOrConnectWithoutOrderInput>>;
  create?: Maybe<Array<RefundCreateWithoutOrderInput>>;
  createMany?: Maybe<RefundCreateManyOrderInputEnvelope>;
  delete?: Maybe<Array<RefundWhereUniqueInput>>;
  deleteMany?: Maybe<Array<RefundScalarWhereInput>>;
  disconnect?: Maybe<Array<RefundWhereUniqueInput>>;
  set?: Maybe<Array<RefundWhereUniqueInput>>;
  update?: Maybe<Array<RefundUpdateWithWhereUniqueWithoutOrderInput>>;
  updateMany?: Maybe<Array<RefundUpdateManyWithWhereWithoutOrderInput>>;
  upsert?: Maybe<Array<RefundUpsertWithWhereUniqueWithoutOrderInput>>;
};

export type RefundUpdateOneRequiredWithoutItemsInput = {
  connect?: Maybe<RefundWhereUniqueInput>;
  connectOrCreate?: Maybe<RefundCreateOrConnectWithoutItemsInput>;
  create?: Maybe<RefundCreateWithoutItemsInput>;
  update?: Maybe<RefundUpdateWithoutItemsInput>;
  upsert?: Maybe<RefundUpsertWithoutItemsInput>;
};

export type RefundUpdateOneRequiredWithoutLineItemRefundInput = {
  connect?: Maybe<RefundWhereUniqueInput>;
  connectOrCreate?: Maybe<RefundCreateOrConnectWithoutLineItemRefundInput>;
  create?: Maybe<RefundCreateWithoutLineItemRefundInput>;
  update?: Maybe<RefundUpdateWithoutLineItemRefundInput>;
  upsert?: Maybe<RefundUpsertWithoutLineItemRefundInput>;
};

export type RefundUpdateWithWhereUniqueWithoutOrderInput = {
  data: RefundUpdateWithoutOrderInput;
  where: RefundWhereUniqueInput;
};

export type RefundUpdateWithoutItemsInput = {
  LineItemRefund?: Maybe<LineItemRefundUpdateManyWithoutRefundInput>;
  appliedPromos?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  creditCardRefund?: Maybe<FloatFieldUpdateOperationsInput>;
  deliveryFee?: Maybe<FloatFieldUpdateOperationsInput>;
  deliveryFeeTax?: Maybe<FloatFieldUpdateOperationsInput>;
  gratuity?: Maybe<FloatFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  itemsTax?: Maybe<FloatFieldUpdateOperationsInput>;
  order?: Maybe<OrderUpdateOneRequiredWithoutRefundInput>;
  reason?: Maybe<EnumRefundReasonFieldUpdateOperationsInput>;
  refundedGiftCards?: Maybe<Scalars['Json']>;
  serviceFee?: Maybe<FloatFieldUpdateOperationsInput>;
  serviceFeeTax?: Maybe<FloatFieldUpdateOperationsInput>;
  subTotalGross?: Maybe<FloatFieldUpdateOperationsInput>;
  subTotalNet?: Maybe<FloatFieldUpdateOperationsInput>;
  totalGiftCardRefund?: Maybe<FloatFieldUpdateOperationsInput>;
  totalRefund?: Maybe<FloatFieldUpdateOperationsInput>;
  type?: Maybe<EnumRefundTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type RefundUpdateWithoutLineItemRefundInput = {
  appliedPromos?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  creditCardRefund?: Maybe<FloatFieldUpdateOperationsInput>;
  deliveryFee?: Maybe<FloatFieldUpdateOperationsInput>;
  deliveryFeeTax?: Maybe<FloatFieldUpdateOperationsInput>;
  gratuity?: Maybe<FloatFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  items?: Maybe<RefundItemUpdateManyWithoutRefundInput>;
  itemsTax?: Maybe<FloatFieldUpdateOperationsInput>;
  order?: Maybe<OrderUpdateOneRequiredWithoutRefundInput>;
  reason?: Maybe<EnumRefundReasonFieldUpdateOperationsInput>;
  refundedGiftCards?: Maybe<Scalars['Json']>;
  serviceFee?: Maybe<FloatFieldUpdateOperationsInput>;
  serviceFeeTax?: Maybe<FloatFieldUpdateOperationsInput>;
  subTotalGross?: Maybe<FloatFieldUpdateOperationsInput>;
  subTotalNet?: Maybe<FloatFieldUpdateOperationsInput>;
  totalGiftCardRefund?: Maybe<FloatFieldUpdateOperationsInput>;
  totalRefund?: Maybe<FloatFieldUpdateOperationsInput>;
  type?: Maybe<EnumRefundTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type RefundUpdateWithoutOrderInput = {
  LineItemRefund?: Maybe<LineItemRefundUpdateManyWithoutRefundInput>;
  appliedPromos?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  creditCardRefund?: Maybe<FloatFieldUpdateOperationsInput>;
  deliveryFee?: Maybe<FloatFieldUpdateOperationsInput>;
  deliveryFeeTax?: Maybe<FloatFieldUpdateOperationsInput>;
  gratuity?: Maybe<FloatFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  items?: Maybe<RefundItemUpdateManyWithoutRefundInput>;
  itemsTax?: Maybe<FloatFieldUpdateOperationsInput>;
  reason?: Maybe<EnumRefundReasonFieldUpdateOperationsInput>;
  refundedGiftCards?: Maybe<Scalars['Json']>;
  serviceFee?: Maybe<FloatFieldUpdateOperationsInput>;
  serviceFeeTax?: Maybe<FloatFieldUpdateOperationsInput>;
  subTotalGross?: Maybe<FloatFieldUpdateOperationsInput>;
  subTotalNet?: Maybe<FloatFieldUpdateOperationsInput>;
  totalGiftCardRefund?: Maybe<FloatFieldUpdateOperationsInput>;
  totalRefund?: Maybe<FloatFieldUpdateOperationsInput>;
  type?: Maybe<EnumRefundTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type RefundUpsertWithWhereUniqueWithoutOrderInput = {
  create: RefundCreateWithoutOrderInput;
  update: RefundUpdateWithoutOrderInput;
  where: RefundWhereUniqueInput;
};

export type RefundUpsertWithoutItemsInput = {
  create: RefundCreateWithoutItemsInput;
  update: RefundUpdateWithoutItemsInput;
};

export type RefundUpsertWithoutLineItemRefundInput = {
  create: RefundCreateWithoutLineItemRefundInput;
  update: RefundUpdateWithoutLineItemRefundInput;
};

export type RefundWhereInput = {
  AND?: Maybe<Array<RefundWhereInput>>;
  LineItemRefund?: Maybe<LineItemRefundListRelationFilter>;
  NOT?: Maybe<Array<RefundWhereInput>>;
  OR?: Maybe<Array<RefundWhereInput>>;
  appliedPromos?: Maybe<JsonNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  creditCardRefund?: Maybe<FloatFilter>;
  deliveryFee?: Maybe<FloatFilter>;
  deliveryFeeTax?: Maybe<FloatFilter>;
  gratuity?: Maybe<FloatFilter>;
  id?: Maybe<StringFilter>;
  items?: Maybe<RefundItemListRelationFilter>;
  itemsTax?: Maybe<FloatFilter>;
  order?: Maybe<OrderWhereInput>;
  orderId?: Maybe<StringFilter>;
  reason?: Maybe<EnumRefundReasonFilter>;
  refundedGiftCards?: Maybe<JsonNullableFilter>;
  serviceFee?: Maybe<FloatFilter>;
  serviceFeeTax?: Maybe<FloatFilter>;
  subTotalGross?: Maybe<FloatFilter>;
  subTotalNet?: Maybe<FloatFilter>;
  totalGiftCardRefund?: Maybe<FloatFilter>;
  totalRefund?: Maybe<FloatFilter>;
  type?: Maybe<EnumRefundTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type RefundWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum ScreenType {
  Expo = 'EXPO',
  Foh = 'FOH',
  Kitchen = 'KITCHEN',
  OrderAssignment = 'ORDER_ASSIGNMENT',
  OrderStatus = 'ORDER_STATUS',
  Packager = 'PACKAGER',
  Pickup = 'PICKUP',
  Runner = 'RUNNER',
  Station = 'STATION',
  TableAssignment = 'TABLE_ASSIGNMENT'
}

export type Segment = {
  __typename?: 'Segment';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type SendError = {
  __typename?: 'SendError';
  code: Scalars['String'];
  message: Scalars['String'];
};

export type SendResponse = {
  __typename?: 'SendResponse';
  error?: Maybe<SendError>;
  messageId?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
  token?: Maybe<Scalars['String']>;
};

export type SendResponses = {
  __typename?: 'SendResponses';
  failedRegistrationTokens: Array<Scalars['String']>;
  failureCount: Scalars['Int'];
  invalidRegistrationTokens: Array<Scalars['String']>;
  responses: Array<Maybe<SendResponse>>;
  successCount: Scalars['Int'];
};

export type SetupConsumerAppInput = {
  platform: MobilePlatform;
  version: Scalars['String'];
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export enum SortOrderArg {
  Asc = 'asc',
  Desc = 'desc'
}

export type SpecialMenuScheduleUpsertInput = {
  deleteScheduleCategoryIds: Array<Scalars['String']>;
  /** Contains both end day (Date) and hours (Time) */
  end: Scalars['DateTime'];
  id?: Maybe<Scalars['ID']>;
  isActive: Scalars['Boolean'];
  isNonStop: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  /** [0 - Sun, 1 - Mon, 2 - Tue, 3 - Wen, 4 - Thu, 5 - Fri, 6 - Sat] (based on the https://www.w3schools.com/jsref/jsref_getday.asp) */
  repeatOnDays: Array<Scalars['Int']>;
  scheduleCategories: Array<MenuScheduleCategoryInput>;
  sortedCategoryIds: Array<Scalars['String']>;
  /** Contains both start day (Date) and hours (Time) */
  start: Scalars['DateTime'];
};

export type StandardizedBrandRemittanceReport = {
  __typename?: 'StandardizedBrandRemittanceReport';
  accountingDateRange?: Maybe<Scalars['DateTime']>;
  adjustmentOther?: Maybe<Scalars['String']>;
  adjustmentsChargebacks?: Maybe<Scalars['String']>;
  adjustmentsRefunds?: Maybe<Scalars['String']>;
  brandAgreements?: Maybe<Scalars['String']>;
  cardIncomeAfterFees?: Maybe<Scalars['String']>;
  cartTotal?: Maybe<Scalars['Float']>;
  craveProcessingFees?: Maybe<Scalars['Float']>;
  deliveryAggregatorExpense?: Maybe<Scalars['Float']>;
  deliveryAggregatorExpenseRemainder?: Maybe<Scalars['Float']>;
  deliveryFeeTax?: Maybe<Scalars['Float']>;
  deliveryFees?: Maybe<Scalars['Float']>;
  deliveryTips?: Maybe<Scalars['Float']>;
  discounts?: Maybe<Scalars['Float']>;
  finalPayout?: Maybe<Scalars['Float']>;
  foodSalesTax?: Maybe<Scalars['Float']>;
  giftCardAmount?: Maybe<Scalars['Float']>;
  grossRestaurantSales?: Maybe<Scalars['Float']>;
  kitchenId: Scalars['String'];
  kitchenName: Scalars['String'];
  lastUpdateDate?: Maybe<Scalars['DateTime']>;
  netSalesPretax?: Maybe<Scalars['Float']>;
  otherTender?: Maybe<Scalars['Float']>;
  partnerAgreements?: Maybe<Scalars['Float']>;
  payoutDate?: Maybe<Scalars['DateTime']>;
  payoutDescriptor?: Maybe<Scalars['String']>;
  payoutId?: Maybe<Scalars['String']>;
  pickupTips?: Maybe<Scalars['Float']>;
  serviceFeeTax?: Maybe<Scalars['Float']>;
  serviceFees?: Maybe<Scalars['Float']>;
};

export type StandardizedBrandSalesReport = {
  __typename?: 'StandardizedBrandSalesReport';
  AOV: Scalars['Float'];
  brandName: Scalars['String'];
  settlementAmount: Scalars['Float'];
  settlementPercentage: Scalars['Float'];
  totalOrders: Scalars['Int'];
  totalSales: Scalars['Float'];
};

export type StandardizedDailySalesReport = {
  __typename?: 'StandardizedDailySalesReport';
  dailyAIO: Scalars['Float'];
  dailyAOV: Scalars['Float'];
  dailyAvgBrandsPerOrder: Scalars['Float'];
  date: Scalars['String'];
  dayOfWeek: Scalars['String'];
  totalItems: Scalars['Int'];
  totalOrders: Scalars['Int'];
  totalSales: Scalars['Float'];
};

export type StandardizedGiftCardReport = {
  __typename?: 'StandardizedGiftCardReport';
  beginningBalance: Scalars['Float'];
  codeName: Scalars['String'];
  facilityId?: Maybe<Scalars['String']>;
  lastActivity: Scalars['DateTime'];
  remainingBalance: Scalars['Float'];
  status: Scalars['String'];
};

export type StandardizedItemSalesReport = {
  __typename?: 'StandardizedItemSalesReport';
  avgMenuItemPrice: Scalars['Float'];
  avgModifierPrice: Scalars['Float'];
  brandName: Scalars['String'];
  menuItemName: Scalars['String'];
  modifierNames: Scalars['String'];
  modifierSales: Scalars['Float'];
  modifierSold: Scalars['Float'];
  sales: Scalars['Float'];
  sold: Scalars['Int'];
  totalSales: Scalars['Float'];
};

export type StandardizedItemTransactionsReport = {
  __typename?: 'StandardizedItemTransactionsReport';
  brand: Scalars['String'];
  brandAgreements?: Maybe<Scalars['Float']>;
  brandMenuItemName: Scalars['String'];
  customerEmail?: Maybe<Scalars['String']>;
  customerName?: Maybe<Scalars['String']>;
  customerPhone?: Maybe<Scalars['String']>;
  deliveryFee?: Maybe<Scalars['Float']>;
  deliveryFeeTax?: Maybe<Scalars['Float']>;
  foodSalesTax?: Maybe<Scalars['Float']>;
  giftCardAmount?: Maybe<Scalars['Float']>;
  giftCode?: Maybe<Scalars['String']>;
  netSales?: Maybe<Scalars['Float']>;
  numberOfBrands?: Maybe<Scalars['Int']>;
  numberOfOrderedItems?: Maybe<Scalars['Int']>;
  orderDate: Scalars['DateTime'];
  orderTime: Scalars['String'];
  orderTotal?: Maybe<Scalars['Float']>;
  orderType: Scalars['String'];
  orderingProvider?: Maybe<Scalars['String']>;
  partnerAgreements?: Maybe<Scalars['Float']>;
  platform: Scalars['String'];
  promoAmount?: Maybe<Scalars['Float']>;
  promoCode?: Maybe<Scalars['String']>;
  serviceFee?: Maybe<Scalars['Float']>;
  serviceFeeTax?: Maybe<Scalars['Float']>;
  shortId: Scalars['String'];
  subtotal?: Maybe<Scalars['Float']>;
  tipAmount?: Maybe<Scalars['Float']>;
  transactionId?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<Scalars['String']>;
  transactionType?: Maybe<Scalars['String']>;
};

export type StandardizedModifierSalesReport = {
  __typename?: 'StandardizedModifierSalesReport';
  brandName: Scalars['String'];
  menuItemName: Scalars['String'];
  modifierName: Scalars['String'];
  modifierPrice: Scalars['Float'];
  modifierSales: Scalars['Float'];
  modifiersSold: Scalars['Int'];
  orderDate: Scalars['DateTime'];
};

export type StandardizedOperationsDetailedReport = {
  __typename?: 'StandardizedOperationsDetailedReport';
  datePlaced: Scalars['DateTime'];
  deliveryEndTime: Scalars['String'];
  deliveryStartTime: Scalars['String'];
  numberOfItems: Scalars['Int'];
  numberOfRefiredItems: Scalars['Int'];
  orderId: Scalars['String'];
  orderMethod: Scalars['String'];
  shortId: Scalars['String'];
  startPreparingTime: Scalars['String'];
  timeAwaitingPickup: Scalars['Float'];
  timeDelayedInKitchen: Scalars['Int'];
  timeInExpo: Scalars['Int'];
  timeInKitchen: Scalars['Int'];
  timeInPackaging: Scalars['Int'];
  timePlaced: Scalars['String'];
  timeWanted: Scalars['String'];
  totalTimeToCompletion: Scalars['String'];
};

export type StandardizedPartnerRemittanceReport = {
  __typename?: 'StandardizedPartnerRemittanceReport';
  accountingDateRange: Scalars['DateTime'];
  adjustmentOther?: Maybe<Scalars['Float']>;
  adjustmentsChargebacks?: Maybe<Scalars['Float']>;
  adjustmentsRefunds?: Maybe<Scalars['Float']>;
  brandAgreements?: Maybe<Scalars['Float']>;
  cardIncomeAfterFees?: Maybe<Scalars['Float']>;
  cartTotal?: Maybe<Scalars['Float']>;
  craveProcessingFees?: Maybe<Scalars['Float']>;
  deliveryAggregatorExpense?: Maybe<Scalars['Float']>;
  deliveryAggregatorExpenseRemainder?: Maybe<Scalars['Float']>;
  deliveryFeeTax?: Maybe<Scalars['Float']>;
  deliveryFees?: Maybe<Scalars['Float']>;
  deliveryTips?: Maybe<Scalars['Float']>;
  discounts?: Maybe<Scalars['Float']>;
  finalPayout?: Maybe<Scalars['Float']>;
  foodSalesTax?: Maybe<Scalars['Float']>;
  giftCardAmount?: Maybe<Scalars['Float']>;
  grossRestaurantSales?: Maybe<Scalars['Float']>;
  kitchenId: Scalars['String'];
  kitchenName: Scalars['String'];
  lastUpdateDate: Scalars['DateTime'];
  netSalesPretax?: Maybe<Scalars['Float']>;
  otherTender?: Maybe<Scalars['Float']>;
  partnerAgreements?: Maybe<Scalars['Float']>;
  payoutDate: Scalars['DateTime'];
  payoutDescriptor: Scalars['String'];
  payoutId: Scalars['String'];
  pickupTips?: Maybe<Scalars['Float']>;
  serviceFeeTax?: Maybe<Scalars['Float']>;
  serviceFees?: Maybe<Scalars['Float']>;
  tenantId: Scalars['String'];
};

export type StandardizedPlatformMixReport = {
  __typename?: 'StandardizedPlatformMixReport';
  orderDate: Scalars['DateTime'];
  orderingPlatformName: Scalars['String'];
  totalOrders: Scalars['Int'];
  totalSales: Scalars['String'];
};

export type StandardizedPromoReport = {
  __typename?: 'StandardizedPromoReport';
  numberOfOrders: Scalars['Int'];
  promoCodeName: Scalars['String'];
  promoCodeType: Scalars['String'];
  promoValueRedeemed?: Maybe<Scalars['Float']>;
  status: Scalars['String'];
};

export type StandardizedTimeOfDayBreakdownReport = {
  __typename?: 'StandardizedTimeOfDayBreakdownReport';
  brandName: Scalars['String'];
  date: Scalars['DateTime'];
  numberOfOrdersPerBrand: Scalars['Int'];
  timeSlot: Scalars['DateTime'];
  totalSalesPerTimeSlot: Scalars['String'];
};

export type StandardizedTransactionsReport = {
  __typename?: 'StandardizedTransactionsReport';
  brandFee?: Maybe<Scalars['Float']>;
  brands: Scalars['String'];
  combinedCartTotal?: Maybe<Scalars['Float']>;
  creditCardProcessingFee?: Maybe<Scalars['Float']>;
  customerEmail?: Maybe<Scalars['String']>;
  customerName?: Maybe<Scalars['String']>;
  customerPhone?: Maybe<Scalars['String']>;
  deliveryCharge?: Maybe<Scalars['String']>;
  deliveryFee?: Maybe<Scalars['Float']>;
  deliveryTax?: Maybe<Scalars['Float']>;
  foodSalesTax?: Maybe<Scalars['Float']>;
  fulfillmentType?: Maybe<Scalars['String']>;
  giftCardAmount?: Maybe<Scalars['Float']>;
  giftCode?: Maybe<Scalars['String']>;
  netSales?: Maybe<Scalars['Float']>;
  numberOfOrderedItems?: Maybe<Scalars['Int']>;
  numberOfRestaurants?: Maybe<Scalars['Int']>;
  orderDate?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['String']>;
  orderTime?: Maybe<Scalars['String']>;
  orderTotal?: Maybe<Scalars['Float']>;
  orderType?: Maybe<Scalars['String']>;
  orderingProvider?: Maybe<Scalars['String']>;
  partnerFee?: Maybe<Scalars['Float']>;
  platform?: Maybe<Scalars['String']>;
  promoAmount?: Maybe<Scalars['Float']>;
  promoCode?: Maybe<Scalars['String']>;
  serviceFee?: Maybe<Scalars['Float']>;
  serviceFeeTax?: Maybe<Scalars['Float']>;
  shortId?: Maybe<Scalars['String']>;
  subtotal?: Maybe<Scalars['Float']>;
  taxAmount?: Maybe<Scalars['Float']>;
  tipAmount?: Maybe<Scalars['Float']>;
  transactionId?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<Scalars['String']>;
  transactionType?: Maybe<Scalars['String']>;
};

export type Station = {
  __typename?: 'Station';
  id: Scalars['String'];
  kitchen: Scalars['Int'];
  name: Scalars['String'];
};

export type StationCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  facility: FacilityCreateNestedOneWithoutStationsInput;
  id?: Maybe<Scalars['String']>;
  kitchenMenuItems?: Maybe<KitchenMenuItemCreateNestedManyWithoutStationInput>;
  name: Scalars['String'];
  tickets?: Maybe<KitchenTicketCreateNestedManyWithoutStationInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StationCreateManyFacilityInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StationCreateManyFacilityInputEnvelope = {
  data?: Maybe<Array<StationCreateManyFacilityInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type StationCreateNestedManyWithoutFacilityInput = {
  connect?: Maybe<Array<StationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StationCreateOrConnectWithoutFacilityInput>>;
  create?: Maybe<Array<StationCreateWithoutFacilityInput>>;
  createMany?: Maybe<StationCreateManyFacilityInputEnvelope>;
};

export type StationCreateNestedOneWithoutKitchenMenuItemsInput = {
  connect?: Maybe<StationWhereUniqueInput>;
  connectOrCreate?: Maybe<StationCreateOrConnectWithoutKitchenMenuItemsInput>;
  create?: Maybe<StationCreateWithoutKitchenMenuItemsInput>;
};

export type StationCreateNestedOneWithoutTicketsInput = {
  connect?: Maybe<StationWhereUniqueInput>;
  connectOrCreate?: Maybe<StationCreateOrConnectWithoutTicketsInput>;
  create?: Maybe<StationCreateWithoutTicketsInput>;
};

export type StationCreateOrConnectWithoutFacilityInput = {
  create: StationCreateWithoutFacilityInput;
  where: StationWhereUniqueInput;
};

export type StationCreateOrConnectWithoutKitchenMenuItemsInput = {
  create: StationCreateWithoutKitchenMenuItemsInput;
  where: StationWhereUniqueInput;
};

export type StationCreateOrConnectWithoutTicketsInput = {
  create: StationCreateWithoutTicketsInput;
  where: StationWhereUniqueInput;
};

export type StationCreateWithoutFacilityInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  kitchenMenuItems?: Maybe<KitchenMenuItemCreateNestedManyWithoutStationInput>;
  name: Scalars['String'];
  tickets?: Maybe<KitchenTicketCreateNestedManyWithoutStationInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StationCreateWithoutKitchenMenuItemsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  facility: FacilityCreateNestedOneWithoutStationsInput;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  tickets?: Maybe<KitchenTicketCreateNestedManyWithoutStationInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StationCreateWithoutTicketsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  facility: FacilityCreateNestedOneWithoutStationsInput;
  id?: Maybe<Scalars['String']>;
  kitchenMenuItems?: Maybe<KitchenMenuItemCreateNestedManyWithoutStationInput>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StationListRelationFilter = {
  every?: Maybe<StationWhereInput>;
  none?: Maybe<StationWhereInput>;
  some?: Maybe<StationWhereInput>;
};

export type StationScalarWhereInput = {
  AND?: Maybe<Array<StationScalarWhereInput>>;
  NOT?: Maybe<Array<StationScalarWhereInput>>;
  OR?: Maybe<Array<StationScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  facilityId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type StationStatistics = {
  __typename?: 'StationStatistics';
  station: Scalars['Int'];
};

export type StationUpdateInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  facility?: Maybe<FacilityUpdateOneRequiredWithoutStationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  kitchenMenuItems?: Maybe<KitchenMenuItemUpdateManyWithoutStationInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  tickets?: Maybe<KitchenTicketUpdateManyWithoutStationInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type StationUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type StationUpdateManyWithWhereWithoutFacilityInput = {
  data: StationUpdateManyMutationInput;
  where: StationScalarWhereInput;
};

export type StationUpdateManyWithoutFacilityInput = {
  connect?: Maybe<Array<StationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StationCreateOrConnectWithoutFacilityInput>>;
  create?: Maybe<Array<StationCreateWithoutFacilityInput>>;
  createMany?: Maybe<StationCreateManyFacilityInputEnvelope>;
  delete?: Maybe<Array<StationWhereUniqueInput>>;
  deleteMany?: Maybe<Array<StationScalarWhereInput>>;
  disconnect?: Maybe<Array<StationWhereUniqueInput>>;
  set?: Maybe<Array<StationWhereUniqueInput>>;
  update?: Maybe<Array<StationUpdateWithWhereUniqueWithoutFacilityInput>>;
  updateMany?: Maybe<Array<StationUpdateManyWithWhereWithoutFacilityInput>>;
  upsert?: Maybe<Array<StationUpsertWithWhereUniqueWithoutFacilityInput>>;
};

export type StationUpdateOneWithoutKitchenMenuItemsInput = {
  connect?: Maybe<StationWhereUniqueInput>;
  connectOrCreate?: Maybe<StationCreateOrConnectWithoutKitchenMenuItemsInput>;
  create?: Maybe<StationCreateWithoutKitchenMenuItemsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<StationUpdateWithoutKitchenMenuItemsInput>;
  upsert?: Maybe<StationUpsertWithoutKitchenMenuItemsInput>;
};

export type StationUpdateOneWithoutTicketsInput = {
  connect?: Maybe<StationWhereUniqueInput>;
  connectOrCreate?: Maybe<StationCreateOrConnectWithoutTicketsInput>;
  create?: Maybe<StationCreateWithoutTicketsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<StationUpdateWithoutTicketsInput>;
  upsert?: Maybe<StationUpsertWithoutTicketsInput>;
};

export type StationUpdateWithWhereUniqueWithoutFacilityInput = {
  data: StationUpdateWithoutFacilityInput;
  where: StationWhereUniqueInput;
};

export type StationUpdateWithoutFacilityInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  kitchenMenuItems?: Maybe<KitchenMenuItemUpdateManyWithoutStationInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  tickets?: Maybe<KitchenTicketUpdateManyWithoutStationInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type StationUpdateWithoutKitchenMenuItemsInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  facility?: Maybe<FacilityUpdateOneRequiredWithoutStationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  tickets?: Maybe<KitchenTicketUpdateManyWithoutStationInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type StationUpdateWithoutTicketsInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  facility?: Maybe<FacilityUpdateOneRequiredWithoutStationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  kitchenMenuItems?: Maybe<KitchenMenuItemUpdateManyWithoutStationInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type StationUpsertWithWhereUniqueWithoutFacilityInput = {
  create: StationCreateWithoutFacilityInput;
  update: StationUpdateWithoutFacilityInput;
  where: StationWhereUniqueInput;
};

export type StationUpsertWithoutKitchenMenuItemsInput = {
  create: StationCreateWithoutKitchenMenuItemsInput;
  update: StationUpdateWithoutKitchenMenuItemsInput;
};

export type StationUpsertWithoutTicketsInput = {
  create: StationCreateWithoutTicketsInput;
  update: StationUpdateWithoutTicketsInput;
};

export type StationWhereInput = {
  AND?: Maybe<Array<StationWhereInput>>;
  NOT?: Maybe<Array<StationWhereInput>>;
  OR?: Maybe<Array<StationWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  facility?: Maybe<FacilityWhereInput>;
  facilityId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  kitchenMenuItems?: Maybe<KitchenMenuItemListRelationFilter>;
  name?: Maybe<StringFilter>;
  tickets?: Maybe<KitchenTicketListRelationFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type StationWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type StringFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['String']>;
};

export type StringFilter = {
  contains?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  equals?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  mode?: Maybe<QueryMode>;
  not?: Maybe<NestedStringFilter>;
  notIn?: Maybe<Array<Scalars['String']>>;
  startsWith?: Maybe<Scalars['String']>;
};

export type StringNullableFilter = {
  contains?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  equals?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  mode?: Maybe<QueryMode>;
  not?: Maybe<NestedStringNullableFilter>;
  notIn?: Maybe<Array<Scalars['String']>>;
  startsWith?: Maybe<Scalars['String']>;
};

export type StringNullableListFilter = {
  equals?: Maybe<Array<Scalars['String']>>;
  has?: Maybe<Scalars['String']>;
  hasEvery?: Maybe<Array<Scalars['String']>>;
  hasSome?: Maybe<Array<Scalars['String']>>;
  isEmpty?: Maybe<Scalars['Boolean']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  orderChanged?: Maybe<OrderSubscription>;
  stationTicketChanged?: Maybe<KitchenTicketSubscription>;
  ticketChanged?: Maybe<KitchenTicketSubscription>;
};


export type SubscriptionOrderChangedArgs = {
  endDate?: Maybe<Scalars['DateTime']>;
  screenId?: Maybe<Scalars['String']>;
  screenType?: Maybe<ScreenType>;
  startDate?: Maybe<Scalars['DateTime']>;
  statuses?: Maybe<Array<Maybe<OrderStatus>>>;
  workplace?: Maybe<Scalars['Int']>;
};


export type SubscriptionStationTicketChangedArgs = {
  screenId?: Maybe<Scalars['String']>;
  screenType?: Maybe<ScreenType>;
  stationId?: Maybe<Scalars['ID']>;
};


export type SubscriptionTicketChangedArgs = {
  kitchenId?: Maybe<Scalars['ID']>;
  screenId?: Maybe<Scalars['String']>;
  screenType?: Maybe<ScreenType>;
};

export enum SubscriptionOperation {
  Create = 'create',
  Delete = 'delete',
  DeleteMany = 'deleteMany',
  Update = 'update',
  UpdateMany = 'updateMany',
  Upsert = 'upsert'
}

export type Tag = {
  __typename?: 'Tag';
  customers: Array<UserCustomerInfo>;
  id: Scalars['String'];
  name: Scalars['String'];
  orders: Array<Order>;
};


export type TagCustomersArgs = {
  after?: Maybe<UserCustomerInfoWhereUniqueInput>;
  before?: Maybe<UserCustomerInfoWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type TagOrdersArgs = {
  after?: Maybe<OrderWhereUniqueInput>;
  before?: Maybe<OrderWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type TagCreateNestedManyWithoutCustomersInput = {
  connect?: Maybe<Array<TagWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<TagCreateOrConnectWithoutCustomersInput>>;
  create?: Maybe<Array<TagCreateWithoutCustomersInput>>;
};

export type TagCreateNestedManyWithoutOrdersInput = {
  connect?: Maybe<Array<TagWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<TagCreateOrConnectWithoutOrdersInput>>;
  create?: Maybe<Array<TagCreateWithoutOrdersInput>>;
};

export type TagCreateOrConnectInput = {
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
};

export type TagCreateOrConnectWithoutCustomersInput = {
  create: TagCreateWithoutCustomersInput;
  where: TagWhereUniqueInput;
};

export type TagCreateOrConnectWithoutOrdersInput = {
  create: TagCreateWithoutOrdersInput;
  where: TagWhereUniqueInput;
};

export type TagCreateWithoutCustomersInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  orders?: Maybe<OrderCreateNestedManyWithoutTagsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TagCreateWithoutOrdersInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  customers?: Maybe<UserCustomerInfoCreateNestedManyWithoutTagsInput>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TagListRelationFilter = {
  every?: Maybe<TagWhereInput>;
  none?: Maybe<TagWhereInput>;
  some?: Maybe<TagWhereInput>;
};

export type TagScalarWhereInput = {
  AND?: Maybe<Array<TagScalarWhereInput>>;
  NOT?: Maybe<Array<TagScalarWhereInput>>;
  OR?: Maybe<Array<TagScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type TagUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type TagUpdateManyWithWhereWithoutCustomersInput = {
  data: TagUpdateManyMutationInput;
  where: TagScalarWhereInput;
};

export type TagUpdateManyWithWhereWithoutOrdersInput = {
  data: TagUpdateManyMutationInput;
  where: TagScalarWhereInput;
};

export type TagUpdateManyWithoutCustomersInput = {
  connect?: Maybe<Array<TagWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<TagCreateOrConnectWithoutCustomersInput>>;
  create?: Maybe<Array<TagCreateWithoutCustomersInput>>;
  delete?: Maybe<Array<TagWhereUniqueInput>>;
  deleteMany?: Maybe<Array<TagScalarWhereInput>>;
  disconnect?: Maybe<Array<TagWhereUniqueInput>>;
  set?: Maybe<Array<TagWhereUniqueInput>>;
  update?: Maybe<Array<TagUpdateWithWhereUniqueWithoutCustomersInput>>;
  updateMany?: Maybe<Array<TagUpdateManyWithWhereWithoutCustomersInput>>;
  upsert?: Maybe<Array<TagUpsertWithWhereUniqueWithoutCustomersInput>>;
};

export type TagUpdateManyWithoutOrdersInput = {
  connect?: Maybe<Array<TagWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<TagCreateOrConnectWithoutOrdersInput>>;
  create?: Maybe<Array<TagCreateWithoutOrdersInput>>;
  delete?: Maybe<Array<TagWhereUniqueInput>>;
  deleteMany?: Maybe<Array<TagScalarWhereInput>>;
  disconnect?: Maybe<Array<TagWhereUniqueInput>>;
  set?: Maybe<Array<TagWhereUniqueInput>>;
  update?: Maybe<Array<TagUpdateWithWhereUniqueWithoutOrdersInput>>;
  updateMany?: Maybe<Array<TagUpdateManyWithWhereWithoutOrdersInput>>;
  upsert?: Maybe<Array<TagUpsertWithWhereUniqueWithoutOrdersInput>>;
};

export type TagUpdateWithWhereUniqueWithoutCustomersInput = {
  data: TagUpdateWithoutCustomersInput;
  where: TagWhereUniqueInput;
};

export type TagUpdateWithWhereUniqueWithoutOrdersInput = {
  data: TagUpdateWithoutOrdersInput;
  where: TagWhereUniqueInput;
};

export type TagUpdateWithoutCustomersInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  orders?: Maybe<OrderUpdateManyWithoutTagsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type TagUpdateWithoutOrdersInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customers?: Maybe<UserCustomerInfoUpdateManyWithoutTagsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type TagUpsertWithWhereUniqueWithoutCustomersInput = {
  create: TagCreateWithoutCustomersInput;
  update: TagUpdateWithoutCustomersInput;
  where: TagWhereUniqueInput;
};

export type TagUpsertWithWhereUniqueWithoutOrdersInput = {
  create: TagCreateWithoutOrdersInput;
  update: TagUpdateWithoutOrdersInput;
  where: TagWhereUniqueInput;
};

export type TagWhereInput = {
  AND?: Maybe<Array<TagWhereInput>>;
  NOT?: Maybe<Array<TagWhereInput>>;
  OR?: Maybe<Array<TagWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  customers?: Maybe<UserCustomerInfoListRelationFilter>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  orders?: Maybe<OrderListRelationFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type TagWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type Tenant = {
  __typename?: 'Tenant';
  favicon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  metaTitle?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  withoutTenantAuth?: Maybe<Scalars['Boolean']>;
};

export type TenantCreateNestedOneWithoutFacilityInput = {
  connect?: Maybe<TenantWhereUniqueInput>;
  connectOrCreate?: Maybe<TenantCreateOrConnectWithoutFacilityInput>;
  create?: Maybe<TenantCreateWithoutFacilityInput>;
};

export type TenantCreateNestedOneWithoutPromosInput = {
  connect?: Maybe<TenantWhereUniqueInput>;
  connectOrCreate?: Maybe<TenantCreateOrConnectWithoutPromosInput>;
  create?: Maybe<TenantCreateWithoutPromosInput>;
};

export type TenantCreateOrConnectWithoutFacilityInput = {
  create: TenantCreateWithoutFacilityInput;
  where: TenantWhereUniqueInput;
};

export type TenantCreateOrConnectWithoutPromosInput = {
  create: TenantCreateWithoutPromosInput;
  where: TenantWhereUniqueInput;
};

export type TenantCreateWithoutFacilityInput = {
  TenantDomain?: Maybe<TenantDomainCreateNestedManyWithoutTenantInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  favicon?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  metaTitle?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  nashApiKey?: Maybe<Scalars['String']>;
  promos?: Maybe<PromoCreateNestedManyWithoutTenantInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  withoutTenantAuth?: Maybe<Scalars['Boolean']>;
};

export type TenantCreateWithoutPromosInput = {
  Facility?: Maybe<FacilityCreateNestedManyWithoutTenantInput>;
  TenantDomain?: Maybe<TenantDomainCreateNestedManyWithoutTenantInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  favicon?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  metaTitle?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  nashApiKey?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  withoutTenantAuth?: Maybe<Scalars['Boolean']>;
};

export type TenantDomainCreateManyTenantInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  rootDomain: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TenantDomainCreateManyTenantInputEnvelope = {
  data?: Maybe<Array<TenantDomainCreateManyTenantInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type TenantDomainCreateNestedManyWithoutTenantInput = {
  connect?: Maybe<Array<TenantDomainWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<TenantDomainCreateOrConnectWithoutTenantInput>>;
  create?: Maybe<Array<TenantDomainCreateWithoutTenantInput>>;
  createMany?: Maybe<TenantDomainCreateManyTenantInputEnvelope>;
};

export type TenantDomainCreateOrConnectWithoutTenantInput = {
  create: TenantDomainCreateWithoutTenantInput;
  where: TenantDomainWhereUniqueInput;
};

export type TenantDomainCreateWithoutTenantInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  rootDomain: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TenantDomainListRelationFilter = {
  every?: Maybe<TenantDomainWhereInput>;
  none?: Maybe<TenantDomainWhereInput>;
  some?: Maybe<TenantDomainWhereInput>;
};

export type TenantDomainScalarWhereInput = {
  AND?: Maybe<Array<TenantDomainScalarWhereInput>>;
  NOT?: Maybe<Array<TenantDomainScalarWhereInput>>;
  OR?: Maybe<Array<TenantDomainScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  rootDomain?: Maybe<StringFilter>;
  tenantId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type TenantDomainUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  rootDomain?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type TenantDomainUpdateManyWithWhereWithoutTenantInput = {
  data: TenantDomainUpdateManyMutationInput;
  where: TenantDomainScalarWhereInput;
};

export type TenantDomainUpdateManyWithoutTenantInput = {
  connect?: Maybe<Array<TenantDomainWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<TenantDomainCreateOrConnectWithoutTenantInput>>;
  create?: Maybe<Array<TenantDomainCreateWithoutTenantInput>>;
  createMany?: Maybe<TenantDomainCreateManyTenantInputEnvelope>;
  delete?: Maybe<Array<TenantDomainWhereUniqueInput>>;
  deleteMany?: Maybe<Array<TenantDomainScalarWhereInput>>;
  disconnect?: Maybe<Array<TenantDomainWhereUniqueInput>>;
  set?: Maybe<Array<TenantDomainWhereUniqueInput>>;
  update?: Maybe<Array<TenantDomainUpdateWithWhereUniqueWithoutTenantInput>>;
  updateMany?: Maybe<Array<TenantDomainUpdateManyWithWhereWithoutTenantInput>>;
  upsert?: Maybe<Array<TenantDomainUpsertWithWhereUniqueWithoutTenantInput>>;
};

export type TenantDomainUpdateWithWhereUniqueWithoutTenantInput = {
  data: TenantDomainUpdateWithoutTenantInput;
  where: TenantDomainWhereUniqueInput;
};

export type TenantDomainUpdateWithoutTenantInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  rootDomain?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type TenantDomainUpsertWithWhereUniqueWithoutTenantInput = {
  create: TenantDomainCreateWithoutTenantInput;
  update: TenantDomainUpdateWithoutTenantInput;
  where: TenantDomainWhereUniqueInput;
};

export type TenantDomainWhereInput = {
  AND?: Maybe<Array<TenantDomainWhereInput>>;
  NOT?: Maybe<Array<TenantDomainWhereInput>>;
  OR?: Maybe<Array<TenantDomainWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  rootDomain?: Maybe<StringFilter>;
  tenant?: Maybe<TenantWhereInput>;
  tenantId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type TenantDomainWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type TenantUpdateOneRequiredWithoutPromosInput = {
  connect?: Maybe<TenantWhereUniqueInput>;
  connectOrCreate?: Maybe<TenantCreateOrConnectWithoutPromosInput>;
  create?: Maybe<TenantCreateWithoutPromosInput>;
  update?: Maybe<TenantUpdateWithoutPromosInput>;
  upsert?: Maybe<TenantUpsertWithoutPromosInput>;
};

export type TenantUpdateOneWithoutFacilityInput = {
  connect?: Maybe<TenantWhereUniqueInput>;
  connectOrCreate?: Maybe<TenantCreateOrConnectWithoutFacilityInput>;
  create?: Maybe<TenantCreateWithoutFacilityInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<TenantUpdateWithoutFacilityInput>;
  upsert?: Maybe<TenantUpsertWithoutFacilityInput>;
};

export type TenantUpdateWithoutFacilityInput = {
  TenantDomain?: Maybe<TenantDomainUpdateManyWithoutTenantInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  favicon?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  metaTitle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  nashApiKey?: Maybe<NullableStringFieldUpdateOperationsInput>;
  promos?: Maybe<PromoUpdateManyWithoutTenantInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  withoutTenantAuth?: Maybe<BoolFieldUpdateOperationsInput>;
};

export type TenantUpdateWithoutPromosInput = {
  Facility?: Maybe<FacilityUpdateManyWithoutTenantInput>;
  TenantDomain?: Maybe<TenantDomainUpdateManyWithoutTenantInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  favicon?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  metaTitle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  nashApiKey?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  withoutTenantAuth?: Maybe<BoolFieldUpdateOperationsInput>;
};

export type TenantUpsertWithoutFacilityInput = {
  create: TenantCreateWithoutFacilityInput;
  update: TenantUpdateWithoutFacilityInput;
};

export type TenantUpsertWithoutPromosInput = {
  create: TenantCreateWithoutPromosInput;
  update: TenantUpdateWithoutPromosInput;
};

export type TenantWhereInput = {
  AND?: Maybe<Array<TenantWhereInput>>;
  Facility?: Maybe<FacilityListRelationFilter>;
  NOT?: Maybe<Array<TenantWhereInput>>;
  OR?: Maybe<Array<TenantWhereInput>>;
  TenantDomain?: Maybe<TenantDomainListRelationFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  favicon?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  metaTitle?: Maybe<StringNullableFilter>;
  name?: Maybe<StringFilter>;
  nashApiKey?: Maybe<StringNullableFilter>;
  promos?: Maybe<PromoListRelationFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  withoutTenantAuth?: Maybe<BoolFilter>;
};

export type TenantWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type TicketStatistics = {
  __typename?: 'TicketStatistics';
  kitchen: Scalars['Int'];
};

export enum TilledCancellationReason {
  Abandoned = 'abandoned',
  Automatic = 'automatic',
  Duplicate = 'duplicate',
  Fraudulent = 'fraudulent',
  RequestedByCustomer = 'requested_by_customer'
}

export type TilledCard = {
  __typename?: 'TilledCard';
  brand: TilledCardBrand;
  expMonth?: Maybe<Scalars['Int']>;
  expYear?: Maybe<Scalars['Int']>;
  holderName?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export enum TilledCardBrand {
  Amex = 'amex',
  Diners = 'diners',
  Discover = 'discover',
  Jcb = 'jcb',
  Maestro = 'maestro',
  Mastercard = 'mastercard',
  Solo = 'solo',
  Unknown = 'unknown',
  Visa = 'visa',
  VisaDebit = 'visa_debit',
  VisaElectron = 'visa_electron'
}

export enum TilledCurrency {
  Aud = 'aud',
  Cad = 'cad',
  Chf = 'chf',
  Dkk = 'dkk',
  Eur = 'eur',
  Gbp = 'gbp',
  Hkd = 'hkd',
  Jpy = 'jpy',
  Nok = 'nok',
  Nzd = 'nzd',
  Sek = 'sek',
  Usd = 'usd',
  Zar = 'zar'
}

export enum TilledCustomerPaymentMethodType {
  AchDebit = 'ach_debit',
  Card = 'card',
  EftDebit = 'eft_debit'
}

export type TilledCustomerSettings = {
  __typename?: 'TilledCustomerSettings';
  savedPaymentMethods: Array<TilledPaymentMethod>;
  serviceSettings: TilledServiceSettings;
};

export type TilledPayment = {
  __typename?: 'TilledPayment';
  amount: Scalars['Int'];
  cancellationReason?: Maybe<TilledCancellationReason>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  currency: TilledCurrency;
  id: Scalars['String'];
  paymentMethod: TilledPaymentMethod;
  paymentMethodId: Scalars['String'];
  status: TilledPaymentStatus;
  updatedAt: Scalars['DateTime'];
};

export type TilledPaymentCreateManyOrderInput = {
  amount: Scalars['Int'];
  cancellationReason?: Maybe<TilledCancellationReason>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency: TilledCurrency;
  id: Scalars['String'];
  lastErrorMessage?: Maybe<Scalars['String']>;
  lastErrorType?: Maybe<TilledPaymentErrorType>;
  paymentMethodId: Scalars['String'];
  status: TilledPaymentStatus;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TilledPaymentCreateManyOrderInputEnvelope = {
  data?: Maybe<Array<TilledPaymentCreateManyOrderInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type TilledPaymentCreateManyPaymentMethodInput = {
  amount: Scalars['Int'];
  cancellationReason?: Maybe<TilledCancellationReason>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency: TilledCurrency;
  id: Scalars['String'];
  lastErrorMessage?: Maybe<Scalars['String']>;
  lastErrorType?: Maybe<TilledPaymentErrorType>;
  orderId: Scalars['String'];
  status: TilledPaymentStatus;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TilledPaymentCreateManyPaymentMethodInputEnvelope = {
  data?: Maybe<Array<TilledPaymentCreateManyPaymentMethodInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type TilledPaymentCreateNestedManyWithoutOrderInput = {
  connect?: Maybe<Array<TilledPaymentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<TilledPaymentCreateOrConnectWithoutOrderInput>>;
  create?: Maybe<Array<TilledPaymentCreateWithoutOrderInput>>;
  createMany?: Maybe<TilledPaymentCreateManyOrderInputEnvelope>;
};

export type TilledPaymentCreateNestedManyWithoutPaymentMethodInput = {
  connect?: Maybe<Array<TilledPaymentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<TilledPaymentCreateOrConnectWithoutPaymentMethodInput>>;
  create?: Maybe<Array<TilledPaymentCreateWithoutPaymentMethodInput>>;
  createMany?: Maybe<TilledPaymentCreateManyPaymentMethodInputEnvelope>;
};

export type TilledPaymentCreateOrConnectWithoutOrderInput = {
  create: TilledPaymentCreateWithoutOrderInput;
  where: TilledPaymentWhereUniqueInput;
};

export type TilledPaymentCreateOrConnectWithoutPaymentMethodInput = {
  create: TilledPaymentCreateWithoutPaymentMethodInput;
  where: TilledPaymentWhereUniqueInput;
};

export type TilledPaymentCreateWithoutOrderInput = {
  amount: Scalars['Int'];
  cancellationReason?: Maybe<TilledCancellationReason>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency: TilledCurrency;
  id: Scalars['String'];
  lastErrorMessage?: Maybe<Scalars['String']>;
  lastErrorType?: Maybe<TilledPaymentErrorType>;
  paymentMethod: TilledPaymentMethodCreateNestedOneWithoutPaymentsInput;
  status: TilledPaymentStatus;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TilledPaymentCreateWithoutPaymentMethodInput = {
  amount: Scalars['Int'];
  cancellationReason?: Maybe<TilledCancellationReason>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency: TilledCurrency;
  id: Scalars['String'];
  lastErrorMessage?: Maybe<Scalars['String']>;
  lastErrorType?: Maybe<TilledPaymentErrorType>;
  order: OrderCreateNestedOneWithoutTilledPaymentsInput;
  status: TilledPaymentStatus;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export enum TilledPaymentErrorType {
  AvsCheckFailed = 'avs_check_failed',
  BankAccountBlocked = 'bank_account_blocked',
  CallIssuer = 'call_issuer',
  CardNotSupported = 'card_not_supported',
  ExpiredCard = 'expired_card',
  Fraudulent = 'fraudulent',
  GenericDecline = 'generic_decline',
  IncorrectCvc = 'incorrect_cvc',
  InsufficientFunds = 'insufficient_funds',
  InternalError = 'internal_error',
  InvalidExpiry = 'invalid_expiry',
  InvalidNumber = 'invalid_number',
  LimitExceeded = 'limit_exceeded',
  NotPermitted = 'not_permitted',
  PickupCard = 'pickup_card',
  ProcessingError = 'processing_error',
  RestrictedCard = 'restricted_card',
  ServiceNotAllowed = 'service_not_allowed'
}

export enum TilledPaymentIntentStatus {
  Canceled = 'canceled',
  Processing = 'processing',
  RequiresAction = 'requires_action',
  RequiresCapture = 'requires_capture',
  RequiresConfirmation = 'requires_confirmation',
  RequiresPaymentMethod = 'requires_payment_method',
  Succeeded = 'succeeded'
}

export type TilledPaymentListRelationFilter = {
  every?: Maybe<TilledPaymentWhereInput>;
  none?: Maybe<TilledPaymentWhereInput>;
  some?: Maybe<TilledPaymentWhereInput>;
};

export type TilledPaymentMethod = {
  __typename?: 'TilledPaymentMethod';
  card?: Maybe<TilledCard>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  lastUsedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  nickName?: Maybe<Scalars['String']>;
  type: TilledPaymentMethodType;
  updatedAt: Scalars['DateTime'];
};

export type TilledPaymentMethodCreateManyCustomerInfoInput = {
  card?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  isSaved?: Maybe<Scalars['Boolean']>;
  lastUsedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  nickName?: Maybe<Scalars['String']>;
  type: TilledPaymentMethodType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TilledPaymentMethodCreateManyCustomerInfoInputEnvelope = {
  data?: Maybe<Array<TilledPaymentMethodCreateManyCustomerInfoInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type TilledPaymentMethodCreateNestedManyWithoutCustomerInfoInput = {
  connect?: Maybe<Array<TilledPaymentMethodWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<TilledPaymentMethodCreateOrConnectWithoutCustomerInfoInput>>;
  create?: Maybe<Array<TilledPaymentMethodCreateWithoutCustomerInfoInput>>;
  createMany?: Maybe<TilledPaymentMethodCreateManyCustomerInfoInputEnvelope>;
};

export type TilledPaymentMethodCreateNestedOneWithoutPaymentsInput = {
  connect?: Maybe<TilledPaymentMethodWhereUniqueInput>;
  connectOrCreate?: Maybe<TilledPaymentMethodCreateOrConnectWithoutPaymentsInput>;
  create?: Maybe<TilledPaymentMethodCreateWithoutPaymentsInput>;
};

export type TilledPaymentMethodCreateOrConnectWithoutCustomerInfoInput = {
  create: TilledPaymentMethodCreateWithoutCustomerInfoInput;
  where: TilledPaymentMethodWhereUniqueInput;
};

export type TilledPaymentMethodCreateOrConnectWithoutPaymentsInput = {
  create: TilledPaymentMethodCreateWithoutPaymentsInput;
  where: TilledPaymentMethodWhereUniqueInput;
};

export type TilledPaymentMethodCreateWithoutCustomerInfoInput = {
  card?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  isSaved?: Maybe<Scalars['Boolean']>;
  lastUsedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  nickName?: Maybe<Scalars['String']>;
  payments?: Maybe<TilledPaymentCreateNestedManyWithoutPaymentMethodInput>;
  type: TilledPaymentMethodType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TilledPaymentMethodCreateWithoutPaymentsInput = {
  card?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customerInfo: UserCustomerInfoCreateNestedOneWithoutTilledPaymentMethodsInput;
  id: Scalars['String'];
  isSaved?: Maybe<Scalars['Boolean']>;
  lastUsedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  nickName?: Maybe<Scalars['String']>;
  type: TilledPaymentMethodType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TilledPaymentMethodListRelationFilter = {
  every?: Maybe<TilledPaymentMethodWhereInput>;
  none?: Maybe<TilledPaymentMethodWhereInput>;
  some?: Maybe<TilledPaymentMethodWhereInput>;
};

export type TilledPaymentMethodScalarWhereInput = {
  AND?: Maybe<Array<TilledPaymentMethodScalarWhereInput>>;
  NOT?: Maybe<Array<TilledPaymentMethodScalarWhereInput>>;
  OR?: Maybe<Array<TilledPaymentMethodScalarWhereInput>>;
  card?: Maybe<JsonNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  customerInfoId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  isSaved?: Maybe<BoolFilter>;
  lastUsedAt?: Maybe<DateTimeNullableFilter>;
  name?: Maybe<StringFilter>;
  nickName?: Maybe<StringNullableFilter>;
  type?: Maybe<EnumTilledPaymentMethodTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export enum TilledPaymentMethodType {
  AchDebit = 'ach_debit',
  Card = 'card',
  CardPresent = 'card_present',
  EftDebit = 'eft_debit'
}

export type TilledPaymentMethodUpdateManyMutationInput = {
  card?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isSaved?: Maybe<BoolFieldUpdateOperationsInput>;
  lastUsedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  nickName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  type?: Maybe<EnumTilledPaymentMethodTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type TilledPaymentMethodUpdateManyWithWhereWithoutCustomerInfoInput = {
  data: TilledPaymentMethodUpdateManyMutationInput;
  where: TilledPaymentMethodScalarWhereInput;
};

export type TilledPaymentMethodUpdateManyWithoutCustomerInfoInput = {
  connect?: Maybe<Array<TilledPaymentMethodWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<TilledPaymentMethodCreateOrConnectWithoutCustomerInfoInput>>;
  create?: Maybe<Array<TilledPaymentMethodCreateWithoutCustomerInfoInput>>;
  createMany?: Maybe<TilledPaymentMethodCreateManyCustomerInfoInputEnvelope>;
  delete?: Maybe<Array<TilledPaymentMethodWhereUniqueInput>>;
  deleteMany?: Maybe<Array<TilledPaymentMethodScalarWhereInput>>;
  disconnect?: Maybe<Array<TilledPaymentMethodWhereUniqueInput>>;
  set?: Maybe<Array<TilledPaymentMethodWhereUniqueInput>>;
  update?: Maybe<Array<TilledPaymentMethodUpdateWithWhereUniqueWithoutCustomerInfoInput>>;
  updateMany?: Maybe<Array<TilledPaymentMethodUpdateManyWithWhereWithoutCustomerInfoInput>>;
  upsert?: Maybe<Array<TilledPaymentMethodUpsertWithWhereUniqueWithoutCustomerInfoInput>>;
};

export type TilledPaymentMethodUpdateOneRequiredWithoutPaymentsInput = {
  connect?: Maybe<TilledPaymentMethodWhereUniqueInput>;
  connectOrCreate?: Maybe<TilledPaymentMethodCreateOrConnectWithoutPaymentsInput>;
  create?: Maybe<TilledPaymentMethodCreateWithoutPaymentsInput>;
  update?: Maybe<TilledPaymentMethodUpdateWithoutPaymentsInput>;
  upsert?: Maybe<TilledPaymentMethodUpsertWithoutPaymentsInput>;
};

export type TilledPaymentMethodUpdateWithWhereUniqueWithoutCustomerInfoInput = {
  data: TilledPaymentMethodUpdateWithoutCustomerInfoInput;
  where: TilledPaymentMethodWhereUniqueInput;
};

export type TilledPaymentMethodUpdateWithoutCustomerInfoInput = {
  card?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isSaved?: Maybe<BoolFieldUpdateOperationsInput>;
  lastUsedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  nickName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  payments?: Maybe<TilledPaymentUpdateManyWithoutPaymentMethodInput>;
  type?: Maybe<EnumTilledPaymentMethodTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type TilledPaymentMethodUpdateWithoutPaymentsInput = {
  card?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customerInfo?: Maybe<UserCustomerInfoUpdateOneRequiredWithoutTilledPaymentMethodsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isSaved?: Maybe<BoolFieldUpdateOperationsInput>;
  lastUsedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  nickName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  type?: Maybe<EnumTilledPaymentMethodTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type TilledPaymentMethodUpsertWithWhereUniqueWithoutCustomerInfoInput = {
  create: TilledPaymentMethodCreateWithoutCustomerInfoInput;
  update: TilledPaymentMethodUpdateWithoutCustomerInfoInput;
  where: TilledPaymentMethodWhereUniqueInput;
};

export type TilledPaymentMethodUpsertWithoutPaymentsInput = {
  create: TilledPaymentMethodCreateWithoutPaymentsInput;
  update: TilledPaymentMethodUpdateWithoutPaymentsInput;
};

export type TilledPaymentMethodWhereInput = {
  AND?: Maybe<Array<TilledPaymentMethodWhereInput>>;
  NOT?: Maybe<Array<TilledPaymentMethodWhereInput>>;
  OR?: Maybe<Array<TilledPaymentMethodWhereInput>>;
  card?: Maybe<JsonNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  customerInfo?: Maybe<UserCustomerInfoWhereInput>;
  customerInfoId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  isSaved?: Maybe<BoolFilter>;
  lastUsedAt?: Maybe<DateTimeNullableFilter>;
  name?: Maybe<StringFilter>;
  nickName?: Maybe<StringNullableFilter>;
  payments?: Maybe<TilledPaymentListRelationFilter>;
  type?: Maybe<EnumTilledPaymentMethodTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type TilledPaymentMethodWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type TilledPaymentScalarWhereInput = {
  AND?: Maybe<Array<TilledPaymentScalarWhereInput>>;
  NOT?: Maybe<Array<TilledPaymentScalarWhereInput>>;
  OR?: Maybe<Array<TilledPaymentScalarWhereInput>>;
  amount?: Maybe<IntFilter>;
  cancellationReason?: Maybe<EnumTilledCancellationReasonNullableFilter>;
  cancelledAt?: Maybe<DateTimeNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  currency?: Maybe<EnumTilledCurrencyFilter>;
  id?: Maybe<StringFilter>;
  lastErrorMessage?: Maybe<StringNullableFilter>;
  lastErrorType?: Maybe<EnumTilledPaymentErrorTypeNullableFilter>;
  orderId?: Maybe<StringFilter>;
  paymentMethodId?: Maybe<StringFilter>;
  status?: Maybe<EnumTilledPaymentStatusFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export enum TilledPaymentStatus {
  Canceled = 'canceled',
  Processing = 'processing',
  RequiresAction = 'requires_action',
  RequiresCapture = 'requires_capture',
  RequiresConfirmation = 'requires_confirmation',
  RequiresPaymentMethod = 'requires_payment_method',
  Succeeded = 'succeeded'
}

export type TilledPaymentUpdateManyMutationInput = {
  amount?: Maybe<IntFieldUpdateOperationsInput>;
  cancellationReason?: Maybe<NullableEnumTilledCancellationReasonFieldUpdateOperationsInput>;
  cancelledAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  currency?: Maybe<EnumTilledCurrencyFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  lastErrorMessage?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastErrorType?: Maybe<NullableEnumTilledPaymentErrorTypeFieldUpdateOperationsInput>;
  status?: Maybe<EnumTilledPaymentStatusFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type TilledPaymentUpdateManyWithWhereWithoutOrderInput = {
  data: TilledPaymentUpdateManyMutationInput;
  where: TilledPaymentScalarWhereInput;
};

export type TilledPaymentUpdateManyWithWhereWithoutPaymentMethodInput = {
  data: TilledPaymentUpdateManyMutationInput;
  where: TilledPaymentScalarWhereInput;
};

export type TilledPaymentUpdateManyWithoutOrderInput = {
  connect?: Maybe<Array<TilledPaymentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<TilledPaymentCreateOrConnectWithoutOrderInput>>;
  create?: Maybe<Array<TilledPaymentCreateWithoutOrderInput>>;
  createMany?: Maybe<TilledPaymentCreateManyOrderInputEnvelope>;
  delete?: Maybe<Array<TilledPaymentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<TilledPaymentScalarWhereInput>>;
  disconnect?: Maybe<Array<TilledPaymentWhereUniqueInput>>;
  set?: Maybe<Array<TilledPaymentWhereUniqueInput>>;
  update?: Maybe<Array<TilledPaymentUpdateWithWhereUniqueWithoutOrderInput>>;
  updateMany?: Maybe<Array<TilledPaymentUpdateManyWithWhereWithoutOrderInput>>;
  upsert?: Maybe<Array<TilledPaymentUpsertWithWhereUniqueWithoutOrderInput>>;
};

export type TilledPaymentUpdateManyWithoutPaymentMethodInput = {
  connect?: Maybe<Array<TilledPaymentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<TilledPaymentCreateOrConnectWithoutPaymentMethodInput>>;
  create?: Maybe<Array<TilledPaymentCreateWithoutPaymentMethodInput>>;
  createMany?: Maybe<TilledPaymentCreateManyPaymentMethodInputEnvelope>;
  delete?: Maybe<Array<TilledPaymentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<TilledPaymentScalarWhereInput>>;
  disconnect?: Maybe<Array<TilledPaymentWhereUniqueInput>>;
  set?: Maybe<Array<TilledPaymentWhereUniqueInput>>;
  update?: Maybe<Array<TilledPaymentUpdateWithWhereUniqueWithoutPaymentMethodInput>>;
  updateMany?: Maybe<Array<TilledPaymentUpdateManyWithWhereWithoutPaymentMethodInput>>;
  upsert?: Maybe<Array<TilledPaymentUpsertWithWhereUniqueWithoutPaymentMethodInput>>;
};

export type TilledPaymentUpdateWithWhereUniqueWithoutOrderInput = {
  data: TilledPaymentUpdateWithoutOrderInput;
  where: TilledPaymentWhereUniqueInput;
};

export type TilledPaymentUpdateWithWhereUniqueWithoutPaymentMethodInput = {
  data: TilledPaymentUpdateWithoutPaymentMethodInput;
  where: TilledPaymentWhereUniqueInput;
};

export type TilledPaymentUpdateWithoutOrderInput = {
  amount?: Maybe<IntFieldUpdateOperationsInput>;
  cancellationReason?: Maybe<NullableEnumTilledCancellationReasonFieldUpdateOperationsInput>;
  cancelledAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  currency?: Maybe<EnumTilledCurrencyFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  lastErrorMessage?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastErrorType?: Maybe<NullableEnumTilledPaymentErrorTypeFieldUpdateOperationsInput>;
  paymentMethod?: Maybe<TilledPaymentMethodUpdateOneRequiredWithoutPaymentsInput>;
  status?: Maybe<EnumTilledPaymentStatusFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type TilledPaymentUpdateWithoutPaymentMethodInput = {
  amount?: Maybe<IntFieldUpdateOperationsInput>;
  cancellationReason?: Maybe<NullableEnumTilledCancellationReasonFieldUpdateOperationsInput>;
  cancelledAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  currency?: Maybe<EnumTilledCurrencyFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  lastErrorMessage?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastErrorType?: Maybe<NullableEnumTilledPaymentErrorTypeFieldUpdateOperationsInput>;
  order?: Maybe<OrderUpdateOneRequiredWithoutTilledPaymentsInput>;
  status?: Maybe<EnumTilledPaymentStatusFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type TilledPaymentUpsertWithWhereUniqueWithoutOrderInput = {
  create: TilledPaymentCreateWithoutOrderInput;
  update: TilledPaymentUpdateWithoutOrderInput;
  where: TilledPaymentWhereUniqueInput;
};

export type TilledPaymentUpsertWithWhereUniqueWithoutPaymentMethodInput = {
  create: TilledPaymentCreateWithoutPaymentMethodInput;
  update: TilledPaymentUpdateWithoutPaymentMethodInput;
  where: TilledPaymentWhereUniqueInput;
};

export type TilledPaymentWhereInput = {
  AND?: Maybe<Array<TilledPaymentWhereInput>>;
  NOT?: Maybe<Array<TilledPaymentWhereInput>>;
  OR?: Maybe<Array<TilledPaymentWhereInput>>;
  amount?: Maybe<IntFilter>;
  cancellationReason?: Maybe<EnumTilledCancellationReasonNullableFilter>;
  cancelledAt?: Maybe<DateTimeNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  currency?: Maybe<EnumTilledCurrencyFilter>;
  id?: Maybe<StringFilter>;
  lastErrorMessage?: Maybe<StringNullableFilter>;
  lastErrorType?: Maybe<EnumTilledPaymentErrorTypeNullableFilter>;
  order?: Maybe<OrderWhereInput>;
  orderId?: Maybe<StringFilter>;
  paymentMethod?: Maybe<TilledPaymentMethodWhereInput>;
  paymentMethodId?: Maybe<StringFilter>;
  status?: Maybe<EnumTilledPaymentStatusFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type TilledPaymentWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type TilledServiceSettings = {
  __typename?: 'TilledServiceSettings';
  accountId: Scalars['String'];
  isSandbox: Scalars['Boolean'];
  publicKey: Scalars['String'];
};

export type Timeslot = {
  __typename?: 'Timeslot';
  end: Scalars['DateTime'];
  start: Scalars['DateTime'];
};

export type UndoOrderInput = {
  orderId: Scalars['String'];
};

export type UndoTicketInput = {
  ticketId: Scalars['String'];
};

export type UpdateDeliveryInput = {
  deliveredAt: Scalars['DateTime'];
  orderId: Scalars['String'];
};

export type UpdateOrderPreparingUntilInput = {
  orderId: Scalars['String'];
  preparingUntil: Scalars['DateTime'];
};

export type UpsellMenuItem = {
  __typename?: 'UpsellMenuItem';
  id: Scalars['String'];
  parentItem: KitchenMenuItem;
  parentItemId: Scalars['String'];
  sortId: Scalars['Int'];
  upsellItem: KitchenMenuItem;
  upsellItemId: Scalars['String'];
};

export type UpsellMenuItemCreateInput = {
  sortId: Scalars['Int'];
  upsellItemId: Scalars['String'];
};

export type UpsellMenuItemCreateManyParentItemInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  sortId: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  upsellItemId: Scalars['String'];
};

export type UpsellMenuItemCreateManyParentItemInputEnvelope = {
  data?: Maybe<Array<UpsellMenuItemCreateManyParentItemInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type UpsellMenuItemCreateManyUpsellItemInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  parentItemId: Scalars['String'];
  sortId: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UpsellMenuItemCreateManyUpsellItemInputEnvelope = {
  data?: Maybe<Array<UpsellMenuItemCreateManyUpsellItemInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type UpsellMenuItemCreateNestedManyWithoutParentItemInput = {
  connect?: Maybe<Array<UpsellMenuItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UpsellMenuItemCreateOrConnectWithoutParentItemInput>>;
  create?: Maybe<Array<UpsellMenuItemCreateWithoutParentItemInput>>;
  createMany?: Maybe<UpsellMenuItemCreateManyParentItemInputEnvelope>;
};

export type UpsellMenuItemCreateNestedManyWithoutUpsellItemInput = {
  connect?: Maybe<Array<UpsellMenuItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UpsellMenuItemCreateOrConnectWithoutUpsellItemInput>>;
  create?: Maybe<Array<UpsellMenuItemCreateWithoutUpsellItemInput>>;
  createMany?: Maybe<UpsellMenuItemCreateManyUpsellItemInputEnvelope>;
};

export type UpsellMenuItemCreateOrConnectWithoutParentItemInput = {
  create: UpsellMenuItemCreateWithoutParentItemInput;
  where: UpsellMenuItemWhereUniqueInput;
};

export type UpsellMenuItemCreateOrConnectWithoutUpsellItemInput = {
  create: UpsellMenuItemCreateWithoutUpsellItemInput;
  where: UpsellMenuItemWhereUniqueInput;
};

export type UpsellMenuItemCreateWithoutParentItemInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  sortId: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  upsellItem: KitchenMenuItemCreateNestedOneWithoutUpsellMenuItemsInput;
};

export type UpsellMenuItemCreateWithoutUpsellItemInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  parentItem: KitchenMenuItemCreateNestedOneWithoutParentItemsInput;
  sortId: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UpsellMenuItemListRelationFilter = {
  every?: Maybe<UpsellMenuItemWhereInput>;
  none?: Maybe<UpsellMenuItemWhereInput>;
  some?: Maybe<UpsellMenuItemWhereInput>;
};

export type UpsellMenuItemScalarWhereInput = {
  AND?: Maybe<Array<UpsellMenuItemScalarWhereInput>>;
  NOT?: Maybe<Array<UpsellMenuItemScalarWhereInput>>;
  OR?: Maybe<Array<UpsellMenuItemScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  parentItemId?: Maybe<StringFilter>;
  sortId?: Maybe<IntFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  upsellItemId?: Maybe<StringFilter>;
};

export type UpsellMenuItemUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  sortId?: Maybe<IntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type UpsellMenuItemUpdateManyWithWhereWithoutParentItemInput = {
  data: UpsellMenuItemUpdateManyMutationInput;
  where: UpsellMenuItemScalarWhereInput;
};

export type UpsellMenuItemUpdateManyWithWhereWithoutUpsellItemInput = {
  data: UpsellMenuItemUpdateManyMutationInput;
  where: UpsellMenuItemScalarWhereInput;
};

export type UpsellMenuItemUpdateManyWithoutParentItemInput = {
  connect?: Maybe<Array<UpsellMenuItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UpsellMenuItemCreateOrConnectWithoutParentItemInput>>;
  create?: Maybe<Array<UpsellMenuItemCreateWithoutParentItemInput>>;
  createMany?: Maybe<UpsellMenuItemCreateManyParentItemInputEnvelope>;
  delete?: Maybe<Array<UpsellMenuItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<UpsellMenuItemScalarWhereInput>>;
  disconnect?: Maybe<Array<UpsellMenuItemWhereUniqueInput>>;
  set?: Maybe<Array<UpsellMenuItemWhereUniqueInput>>;
  update?: Maybe<Array<UpsellMenuItemUpdateWithWhereUniqueWithoutParentItemInput>>;
  updateMany?: Maybe<Array<UpsellMenuItemUpdateManyWithWhereWithoutParentItemInput>>;
  upsert?: Maybe<Array<UpsellMenuItemUpsertWithWhereUniqueWithoutParentItemInput>>;
};

export type UpsellMenuItemUpdateManyWithoutUpsellItemInput = {
  connect?: Maybe<Array<UpsellMenuItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UpsellMenuItemCreateOrConnectWithoutUpsellItemInput>>;
  create?: Maybe<Array<UpsellMenuItemCreateWithoutUpsellItemInput>>;
  createMany?: Maybe<UpsellMenuItemCreateManyUpsellItemInputEnvelope>;
  delete?: Maybe<Array<UpsellMenuItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<UpsellMenuItemScalarWhereInput>>;
  disconnect?: Maybe<Array<UpsellMenuItemWhereUniqueInput>>;
  set?: Maybe<Array<UpsellMenuItemWhereUniqueInput>>;
  update?: Maybe<Array<UpsellMenuItemUpdateWithWhereUniqueWithoutUpsellItemInput>>;
  updateMany?: Maybe<Array<UpsellMenuItemUpdateManyWithWhereWithoutUpsellItemInput>>;
  upsert?: Maybe<Array<UpsellMenuItemUpsertWithWhereUniqueWithoutUpsellItemInput>>;
};

export type UpsellMenuItemUpdateWithWhereUniqueWithoutParentItemInput = {
  data: UpsellMenuItemUpdateWithoutParentItemInput;
  where: UpsellMenuItemWhereUniqueInput;
};

export type UpsellMenuItemUpdateWithWhereUniqueWithoutUpsellItemInput = {
  data: UpsellMenuItemUpdateWithoutUpsellItemInput;
  where: UpsellMenuItemWhereUniqueInput;
};

export type UpsellMenuItemUpdateWithoutParentItemInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  sortId?: Maybe<IntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  upsellItem?: Maybe<KitchenMenuItemUpdateOneRequiredWithoutUpsellMenuItemsInput>;
};

export type UpsellMenuItemUpdateWithoutUpsellItemInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  parentItem?: Maybe<KitchenMenuItemUpdateOneRequiredWithoutParentItemsInput>;
  sortId?: Maybe<IntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type UpsellMenuItemUpsertWithWhereUniqueWithoutParentItemInput = {
  create: UpsellMenuItemCreateWithoutParentItemInput;
  update: UpsellMenuItemUpdateWithoutParentItemInput;
  where: UpsellMenuItemWhereUniqueInput;
};

export type UpsellMenuItemUpsertWithWhereUniqueWithoutUpsellItemInput = {
  create: UpsellMenuItemCreateWithoutUpsellItemInput;
  update: UpsellMenuItemUpdateWithoutUpsellItemInput;
  where: UpsellMenuItemWhereUniqueInput;
};

export type UpsellMenuItemWhereInput = {
  AND?: Maybe<Array<UpsellMenuItemWhereInput>>;
  NOT?: Maybe<Array<UpsellMenuItemWhereInput>>;
  OR?: Maybe<Array<UpsellMenuItemWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  parentItem?: Maybe<KitchenMenuItemWhereInput>;
  parentItemId?: Maybe<StringFilter>;
  sortId?: Maybe<IntFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  upsellItem?: Maybe<KitchenMenuItemWhereInput>;
  upsellItemId?: Maybe<StringFilter>;
};

export type UpsellMenuItemWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type UpsertCartItemInput = {
  cartItemId?: Maybe<Scalars['ID']>;
  extraItems?: Maybe<Array<Maybe<CartExtraItemInput>>>;
  kitchenMenuItemId: Scalars['ID'];
  quantity?: Maybe<Scalars['Int']>;
};

export type User = {
  __typename?: 'User';
  createdAt: Scalars['DateTime'];
  customerInfo?: Maybe<UserCustomerInfo>;
  devices: Array<Device>;
  email?: Maybe<Scalars['String']>;
  facilities: Array<Facility>;
  id: Scalars['String'];
  isDeleted: Scalars['Boolean'];
  isGuest: Scalars['Boolean'];
  kitchens: Array<Kitchen>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  roles: Array<UserRole>;
};


export type UserDevicesArgs = {
  after?: Maybe<DeviceWhereUniqueInput>;
  before?: Maybe<DeviceWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type UserFacilitiesArgs = {
  after?: Maybe<FacilityWhereUniqueInput>;
  before?: Maybe<FacilityWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type UserKitchensArgs = {
  after?: Maybe<KitchenWhereUniqueInput>;
  before?: Maybe<KitchenWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type UserCreateInput = {
  address?: Maybe<AddressCreateInput>;
  browserNotifications?: Maybe<Scalars['Boolean']>;
  businessName?: Maybe<Scalars['String']>;
  businessType?: Maybe<BusinessType>;
  email: Scalars['String'];
  id: Scalars['ID'];
  isGuest?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  phone: Scalars['String'];
};

export type UserCreateNestedManyWithoutFacilitiesInput = {
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutFacilitiesInput>>;
  create?: Maybe<Array<UserCreateWithoutFacilitiesInput>>;
};

export type UserCreateNestedManyWithoutKitchensInput = {
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutKitchensInput>>;
  create?: Maybe<Array<UserCreateWithoutKitchensInput>>;
};

export type UserCreateNestedOneWithoutCustomerInfoInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutCustomerInfoInput>;
  create?: Maybe<UserCreateWithoutCustomerInfoInput>;
};

export type UserCreateNestedOneWithoutPaymentInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutPaymentInput>;
  create?: Maybe<UserCreateWithoutPaymentInput>;
};

export type UserCreateOrConnectWithoutCustomerInfoInput = {
  create: UserCreateWithoutCustomerInfoInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutFacilitiesInput = {
  create: UserCreateWithoutFacilitiesInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutKitchensInput = {
  create: UserCreateWithoutKitchensInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutPaymentInput = {
  create: UserCreateWithoutPaymentInput;
  where: UserWhereUniqueInput;
};

export type UserCreateWithoutCustomerInfoInput = {
  Payment?: Maybe<PaymentCreateNestedManyWithoutCustomerInput>;
  PayrocCustomerCard?: Maybe<PayrocCustomerCardCreateNestedManyWithoutCustomerInput>;
  addresses?: Maybe<CustomerDeliveryAddressCreateNestedManyWithoutUserInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  devices?: Maybe<DeviceCreateNestedManyWithoutUserInput>;
  email?: Maybe<Scalars['String']>;
  facilities?: Maybe<FacilityCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isGuest?: Maybe<Scalars['Boolean']>;
  kitchens?: Maybe<KitchenCreateNestedManyWithoutUsersInput>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  roles?: Maybe<UserCreaterolesInput>;
  tenantId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UserCreateWithoutFacilitiesInput = {
  Payment?: Maybe<PaymentCreateNestedManyWithoutCustomerInput>;
  PayrocCustomerCard?: Maybe<PayrocCustomerCardCreateNestedManyWithoutCustomerInput>;
  addresses?: Maybe<CustomerDeliveryAddressCreateNestedManyWithoutUserInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customerInfo?: Maybe<UserCustomerInfoCreateNestedOneWithoutUserInput>;
  devices?: Maybe<DeviceCreateNestedManyWithoutUserInput>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isGuest?: Maybe<Scalars['Boolean']>;
  kitchens?: Maybe<KitchenCreateNestedManyWithoutUsersInput>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  roles?: Maybe<UserCreaterolesInput>;
  tenantId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UserCreateWithoutKitchensInput = {
  Payment?: Maybe<PaymentCreateNestedManyWithoutCustomerInput>;
  PayrocCustomerCard?: Maybe<PayrocCustomerCardCreateNestedManyWithoutCustomerInput>;
  addresses?: Maybe<CustomerDeliveryAddressCreateNestedManyWithoutUserInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customerInfo?: Maybe<UserCustomerInfoCreateNestedOneWithoutUserInput>;
  devices?: Maybe<DeviceCreateNestedManyWithoutUserInput>;
  email?: Maybe<Scalars['String']>;
  facilities?: Maybe<FacilityCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isGuest?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  roles?: Maybe<UserCreaterolesInput>;
  tenantId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UserCreateWithoutPaymentInput = {
  PayrocCustomerCard?: Maybe<PayrocCustomerCardCreateNestedManyWithoutCustomerInput>;
  addresses?: Maybe<CustomerDeliveryAddressCreateNestedManyWithoutUserInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customerInfo?: Maybe<UserCustomerInfoCreateNestedOneWithoutUserInput>;
  devices?: Maybe<DeviceCreateNestedManyWithoutUserInput>;
  email?: Maybe<Scalars['String']>;
  facilities?: Maybe<FacilityCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isGuest?: Maybe<Scalars['Boolean']>;
  kitchens?: Maybe<KitchenCreateNestedManyWithoutUsersInput>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  roles?: Maybe<UserCreaterolesInput>;
  tenantId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UserCreaterolesInput = {
  set?: Maybe<Array<UserRole>>;
};

export type UserCustomerInfo = {
  __typename?: 'UserCustomerInfo';
  addresses: Array<Address>;
  businessName?: Maybe<Scalars['String']>;
  businessType?: Maybe<BusinessType>;
  cart: Cart;
  customerNotes: Array<Scalars['String']>;
  defaultAddressId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  internalNotes: Array<Scalars['String']>;
  isEmployee: Scalars['Boolean'];
  orders: Array<Order>;
  ordersCount: Scalars['Int'];
  shouldDisplayRating: Scalars['Boolean'];
  tags: Array<Tag>;
  user?: Maybe<User>;
  vip: Scalars['Boolean'];
};


export type UserCustomerInfoOrdersArgs = {
  after?: Maybe<OrderWhereUniqueInput>;
  before?: Maybe<OrderWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type UserCustomerInfoTagsArgs = {
  after?: Maybe<TagWhereUniqueInput>;
  before?: Maybe<TagWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type UserCustomerInfoCreateManyCartInput = {
  braintreeCustomerId?: Maybe<Scalars['String']>;
  businessName?: Maybe<Scalars['String']>;
  businessType?: Maybe<BusinessType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customerNotes?: Maybe<UserCustomerInfoCreateManycustomerNotesInput>;
  defaultAddressId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  imgUrl?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<UserCustomerInfoCreateManyinternalNotesInput>;
  isAllowedToPayByInvoice?: Maybe<Scalars['Boolean']>;
  isEmployee?: Maybe<Scalars['Boolean']>;
  ordersCount?: Maybe<Scalars['Int']>;
  shouldDisplayRating?: Maybe<Scalars['Boolean']>;
  tilledCustomerId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
  vip?: Maybe<Scalars['Boolean']>;
};

export type UserCustomerInfoCreateManyCartInputEnvelope = {
  data?: Maybe<Array<UserCustomerInfoCreateManyCartInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type UserCustomerInfoCreateManycustomerNotesInput = {
  set?: Maybe<Array<Scalars['String']>>;
};

export type UserCustomerInfoCreateManyinternalNotesInput = {
  set?: Maybe<Array<Scalars['String']>>;
};

export type UserCustomerInfoCreateNestedManyWithoutCartInput = {
  connect?: Maybe<Array<UserCustomerInfoWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserCustomerInfoCreateOrConnectWithoutCartInput>>;
  create?: Maybe<Array<UserCustomerInfoCreateWithoutCartInput>>;
  createMany?: Maybe<UserCustomerInfoCreateManyCartInputEnvelope>;
};

export type UserCustomerInfoCreateNestedManyWithoutTagsInput = {
  connect?: Maybe<Array<UserCustomerInfoWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserCustomerInfoCreateOrConnectWithoutTagsInput>>;
  create?: Maybe<Array<UserCustomerInfoCreateWithoutTagsInput>>;
};

export type UserCustomerInfoCreateNestedOneWithoutOrdersInput = {
  connect?: Maybe<UserCustomerInfoWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCustomerInfoCreateOrConnectWithoutOrdersInput>;
  create?: Maybe<UserCustomerInfoCreateWithoutOrdersInput>;
};

export type UserCustomerInfoCreateNestedOneWithoutTilledPaymentMethodsInput = {
  connect?: Maybe<UserCustomerInfoWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCustomerInfoCreateOrConnectWithoutTilledPaymentMethodsInput>;
  create?: Maybe<UserCustomerInfoCreateWithoutTilledPaymentMethodsInput>;
};

export type UserCustomerInfoCreateNestedOneWithoutUserInput = {
  connect?: Maybe<UserCustomerInfoWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCustomerInfoCreateOrConnectWithoutUserInput>;
  create?: Maybe<UserCustomerInfoCreateWithoutUserInput>;
};

export type UserCustomerInfoCreateOrConnectWithoutCartInput = {
  create: UserCustomerInfoCreateWithoutCartInput;
  where: UserCustomerInfoWhereUniqueInput;
};

export type UserCustomerInfoCreateOrConnectWithoutOrdersInput = {
  create: UserCustomerInfoCreateWithoutOrdersInput;
  where: UserCustomerInfoWhereUniqueInput;
};

export type UserCustomerInfoCreateOrConnectWithoutTagsInput = {
  create: UserCustomerInfoCreateWithoutTagsInput;
  where: UserCustomerInfoWhereUniqueInput;
};

export type UserCustomerInfoCreateOrConnectWithoutTilledPaymentMethodsInput = {
  create: UserCustomerInfoCreateWithoutTilledPaymentMethodsInput;
  where: UserCustomerInfoWhereUniqueInput;
};

export type UserCustomerInfoCreateOrConnectWithoutUserInput = {
  create: UserCustomerInfoCreateWithoutUserInput;
  where: UserCustomerInfoWhereUniqueInput;
};

export type UserCustomerInfoCreateWithoutCartInput = {
  braintreeCustomerId?: Maybe<Scalars['String']>;
  businessName?: Maybe<Scalars['String']>;
  businessType?: Maybe<BusinessType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customerNotes?: Maybe<UserCustomerInfoCreatecustomerNotesInput>;
  defaultAddressId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  imgUrl?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<UserCustomerInfoCreateinternalNotesInput>;
  isAllowedToPayByInvoice?: Maybe<Scalars['Boolean']>;
  isEmployee?: Maybe<Scalars['Boolean']>;
  orders?: Maybe<OrderCreateNestedManyWithoutCustomerInput>;
  ordersCount?: Maybe<Scalars['Int']>;
  ratings?: Maybe<CustomerRatingCreateNestedManyWithoutCustomerInput>;
  shouldDisplayRating?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<TagCreateNestedManyWithoutCustomersInput>;
  tilledCustomerId?: Maybe<Scalars['String']>;
  tilledPaymentMethods?: Maybe<TilledPaymentMethodCreateNestedManyWithoutCustomerInfoInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutCustomerInfoInput>;
  vip?: Maybe<Scalars['Boolean']>;
};

export type UserCustomerInfoCreateWithoutOrdersInput = {
  braintreeCustomerId?: Maybe<Scalars['String']>;
  businessName?: Maybe<Scalars['String']>;
  businessType?: Maybe<BusinessType>;
  cart: CartCreateNestedOneWithoutUserCustomerInfoInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  customerNotes?: Maybe<UserCustomerInfoCreatecustomerNotesInput>;
  defaultAddressId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  imgUrl?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<UserCustomerInfoCreateinternalNotesInput>;
  isAllowedToPayByInvoice?: Maybe<Scalars['Boolean']>;
  isEmployee?: Maybe<Scalars['Boolean']>;
  ordersCount?: Maybe<Scalars['Int']>;
  ratings?: Maybe<CustomerRatingCreateNestedManyWithoutCustomerInput>;
  shouldDisplayRating?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<TagCreateNestedManyWithoutCustomersInput>;
  tilledCustomerId?: Maybe<Scalars['String']>;
  tilledPaymentMethods?: Maybe<TilledPaymentMethodCreateNestedManyWithoutCustomerInfoInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutCustomerInfoInput>;
  vip?: Maybe<Scalars['Boolean']>;
};

export type UserCustomerInfoCreateWithoutTagsInput = {
  braintreeCustomerId?: Maybe<Scalars['String']>;
  businessName?: Maybe<Scalars['String']>;
  businessType?: Maybe<BusinessType>;
  cart: CartCreateNestedOneWithoutUserCustomerInfoInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  customerNotes?: Maybe<UserCustomerInfoCreatecustomerNotesInput>;
  defaultAddressId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  imgUrl?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<UserCustomerInfoCreateinternalNotesInput>;
  isAllowedToPayByInvoice?: Maybe<Scalars['Boolean']>;
  isEmployee?: Maybe<Scalars['Boolean']>;
  orders?: Maybe<OrderCreateNestedManyWithoutCustomerInput>;
  ordersCount?: Maybe<Scalars['Int']>;
  ratings?: Maybe<CustomerRatingCreateNestedManyWithoutCustomerInput>;
  shouldDisplayRating?: Maybe<Scalars['Boolean']>;
  tilledCustomerId?: Maybe<Scalars['String']>;
  tilledPaymentMethods?: Maybe<TilledPaymentMethodCreateNestedManyWithoutCustomerInfoInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutCustomerInfoInput>;
  vip?: Maybe<Scalars['Boolean']>;
};

export type UserCustomerInfoCreateWithoutTilledPaymentMethodsInput = {
  braintreeCustomerId?: Maybe<Scalars['String']>;
  businessName?: Maybe<Scalars['String']>;
  businessType?: Maybe<BusinessType>;
  cart: CartCreateNestedOneWithoutUserCustomerInfoInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  customerNotes?: Maybe<UserCustomerInfoCreatecustomerNotesInput>;
  defaultAddressId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  imgUrl?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<UserCustomerInfoCreateinternalNotesInput>;
  isAllowedToPayByInvoice?: Maybe<Scalars['Boolean']>;
  isEmployee?: Maybe<Scalars['Boolean']>;
  orders?: Maybe<OrderCreateNestedManyWithoutCustomerInput>;
  ordersCount?: Maybe<Scalars['Int']>;
  ratings?: Maybe<CustomerRatingCreateNestedManyWithoutCustomerInput>;
  shouldDisplayRating?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<TagCreateNestedManyWithoutCustomersInput>;
  tilledCustomerId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutCustomerInfoInput>;
  vip?: Maybe<Scalars['Boolean']>;
};

export type UserCustomerInfoCreateWithoutUserInput = {
  braintreeCustomerId?: Maybe<Scalars['String']>;
  businessName?: Maybe<Scalars['String']>;
  businessType?: Maybe<BusinessType>;
  cart: CartCreateNestedOneWithoutUserCustomerInfoInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  customerNotes?: Maybe<UserCustomerInfoCreatecustomerNotesInput>;
  defaultAddressId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  imgUrl?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<UserCustomerInfoCreateinternalNotesInput>;
  isAllowedToPayByInvoice?: Maybe<Scalars['Boolean']>;
  isEmployee?: Maybe<Scalars['Boolean']>;
  orders?: Maybe<OrderCreateNestedManyWithoutCustomerInput>;
  ordersCount?: Maybe<Scalars['Int']>;
  ratings?: Maybe<CustomerRatingCreateNestedManyWithoutCustomerInput>;
  shouldDisplayRating?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<TagCreateNestedManyWithoutCustomersInput>;
  tilledCustomerId?: Maybe<Scalars['String']>;
  tilledPaymentMethods?: Maybe<TilledPaymentMethodCreateNestedManyWithoutCustomerInfoInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vip?: Maybe<Scalars['Boolean']>;
};

export type UserCustomerInfoCreatecustomerNotesInput = {
  set?: Maybe<Array<Scalars['String']>>;
};

export type UserCustomerInfoCreateinternalNotesInput = {
  set?: Maybe<Array<Scalars['String']>>;
};

export type UserCustomerInfoListRelationFilter = {
  every?: Maybe<UserCustomerInfoWhereInput>;
  none?: Maybe<UserCustomerInfoWhereInput>;
  some?: Maybe<UserCustomerInfoWhereInput>;
};

export type UserCustomerInfoScalarWhereInput = {
  AND?: Maybe<Array<UserCustomerInfoScalarWhereInput>>;
  NOT?: Maybe<Array<UserCustomerInfoScalarWhereInput>>;
  OR?: Maybe<Array<UserCustomerInfoScalarWhereInput>>;
  braintreeCustomerId?: Maybe<StringNullableFilter>;
  businessName?: Maybe<StringNullableFilter>;
  businessType?: Maybe<EnumBusinessTypeNullableFilter>;
  cartId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  customerNotes?: Maybe<StringNullableListFilter>;
  defaultAddressId?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  imgUrl?: Maybe<StringNullableFilter>;
  internalNotes?: Maybe<StringNullableListFilter>;
  isAllowedToPayByInvoice?: Maybe<BoolFilter>;
  isEmployee?: Maybe<BoolFilter>;
  ordersCount?: Maybe<IntFilter>;
  shouldDisplayRating?: Maybe<BoolFilter>;
  tilledCustomerId?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  userId?: Maybe<StringNullableFilter>;
  vip?: Maybe<BoolFilter>;
};

export type UserCustomerInfoUpdateManyMutationInput = {
  braintreeCustomerId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  businessName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  businessType?: Maybe<NullableEnumBusinessTypeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customerNotes?: Maybe<UserCustomerInfoUpdatecustomerNotesInput>;
  defaultAddressId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  imgUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  internalNotes?: Maybe<UserCustomerInfoUpdateinternalNotesInput>;
  isAllowedToPayByInvoice?: Maybe<BoolFieldUpdateOperationsInput>;
  isEmployee?: Maybe<BoolFieldUpdateOperationsInput>;
  ordersCount?: Maybe<IntFieldUpdateOperationsInput>;
  shouldDisplayRating?: Maybe<BoolFieldUpdateOperationsInput>;
  tilledCustomerId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  vip?: Maybe<BoolFieldUpdateOperationsInput>;
};

export type UserCustomerInfoUpdateManyWithWhereWithoutCartInput = {
  data: UserCustomerInfoUpdateManyMutationInput;
  where: UserCustomerInfoScalarWhereInput;
};

export type UserCustomerInfoUpdateManyWithWhereWithoutTagsInput = {
  data: UserCustomerInfoUpdateManyMutationInput;
  where: UserCustomerInfoScalarWhereInput;
};

export type UserCustomerInfoUpdateManyWithoutCartInput = {
  connect?: Maybe<Array<UserCustomerInfoWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserCustomerInfoCreateOrConnectWithoutCartInput>>;
  create?: Maybe<Array<UserCustomerInfoCreateWithoutCartInput>>;
  createMany?: Maybe<UserCustomerInfoCreateManyCartInputEnvelope>;
  delete?: Maybe<Array<UserCustomerInfoWhereUniqueInput>>;
  deleteMany?: Maybe<Array<UserCustomerInfoScalarWhereInput>>;
  disconnect?: Maybe<Array<UserCustomerInfoWhereUniqueInput>>;
  set?: Maybe<Array<UserCustomerInfoWhereUniqueInput>>;
  update?: Maybe<Array<UserCustomerInfoUpdateWithWhereUniqueWithoutCartInput>>;
  updateMany?: Maybe<Array<UserCustomerInfoUpdateManyWithWhereWithoutCartInput>>;
  upsert?: Maybe<Array<UserCustomerInfoUpsertWithWhereUniqueWithoutCartInput>>;
};

export type UserCustomerInfoUpdateManyWithoutTagsInput = {
  connect?: Maybe<Array<UserCustomerInfoWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserCustomerInfoCreateOrConnectWithoutTagsInput>>;
  create?: Maybe<Array<UserCustomerInfoCreateWithoutTagsInput>>;
  delete?: Maybe<Array<UserCustomerInfoWhereUniqueInput>>;
  deleteMany?: Maybe<Array<UserCustomerInfoScalarWhereInput>>;
  disconnect?: Maybe<Array<UserCustomerInfoWhereUniqueInput>>;
  set?: Maybe<Array<UserCustomerInfoWhereUniqueInput>>;
  update?: Maybe<Array<UserCustomerInfoUpdateWithWhereUniqueWithoutTagsInput>>;
  updateMany?: Maybe<Array<UserCustomerInfoUpdateManyWithWhereWithoutTagsInput>>;
  upsert?: Maybe<Array<UserCustomerInfoUpsertWithWhereUniqueWithoutTagsInput>>;
};

export type UserCustomerInfoUpdateOneRequiredWithoutOrdersInput = {
  connect?: Maybe<UserCustomerInfoWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCustomerInfoCreateOrConnectWithoutOrdersInput>;
  create?: Maybe<UserCustomerInfoCreateWithoutOrdersInput>;
  update?: Maybe<UserCustomerInfoUpdateWithoutOrdersInput>;
  upsert?: Maybe<UserCustomerInfoUpsertWithoutOrdersInput>;
};

export type UserCustomerInfoUpdateOneRequiredWithoutTilledPaymentMethodsInput = {
  connect?: Maybe<UserCustomerInfoWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCustomerInfoCreateOrConnectWithoutTilledPaymentMethodsInput>;
  create?: Maybe<UserCustomerInfoCreateWithoutTilledPaymentMethodsInput>;
  update?: Maybe<UserCustomerInfoUpdateWithoutTilledPaymentMethodsInput>;
  upsert?: Maybe<UserCustomerInfoUpsertWithoutTilledPaymentMethodsInput>;
};

export type UserCustomerInfoUpdateOneWithoutUserInput = {
  connect?: Maybe<UserCustomerInfoWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCustomerInfoCreateOrConnectWithoutUserInput>;
  create?: Maybe<UserCustomerInfoCreateWithoutUserInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<UserCustomerInfoUpdateWithoutUserInput>;
  upsert?: Maybe<UserCustomerInfoUpsertWithoutUserInput>;
};

export type UserCustomerInfoUpdateWithWhereUniqueWithoutCartInput = {
  data: UserCustomerInfoUpdateWithoutCartInput;
  where: UserCustomerInfoWhereUniqueInput;
};

export type UserCustomerInfoUpdateWithWhereUniqueWithoutTagsInput = {
  data: UserCustomerInfoUpdateWithoutTagsInput;
  where: UserCustomerInfoWhereUniqueInput;
};

export type UserCustomerInfoUpdateWithoutCartInput = {
  braintreeCustomerId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  businessName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  businessType?: Maybe<NullableEnumBusinessTypeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customerNotes?: Maybe<UserCustomerInfoUpdatecustomerNotesInput>;
  defaultAddressId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  imgUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  internalNotes?: Maybe<UserCustomerInfoUpdateinternalNotesInput>;
  isAllowedToPayByInvoice?: Maybe<BoolFieldUpdateOperationsInput>;
  isEmployee?: Maybe<BoolFieldUpdateOperationsInput>;
  orders?: Maybe<OrderUpdateManyWithoutCustomerInput>;
  ordersCount?: Maybe<IntFieldUpdateOperationsInput>;
  ratings?: Maybe<CustomerRatingUpdateManyWithoutCustomerInput>;
  shouldDisplayRating?: Maybe<BoolFieldUpdateOperationsInput>;
  tags?: Maybe<TagUpdateManyWithoutCustomersInput>;
  tilledCustomerId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  tilledPaymentMethods?: Maybe<TilledPaymentMethodUpdateManyWithoutCustomerInfoInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneWithoutCustomerInfoInput>;
  vip?: Maybe<BoolFieldUpdateOperationsInput>;
};

export type UserCustomerInfoUpdateWithoutOrdersInput = {
  braintreeCustomerId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  businessName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  businessType?: Maybe<NullableEnumBusinessTypeFieldUpdateOperationsInput>;
  cart?: Maybe<CartUpdateOneRequiredWithoutUserCustomerInfoInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customerNotes?: Maybe<UserCustomerInfoUpdatecustomerNotesInput>;
  defaultAddressId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  imgUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  internalNotes?: Maybe<UserCustomerInfoUpdateinternalNotesInput>;
  isAllowedToPayByInvoice?: Maybe<BoolFieldUpdateOperationsInput>;
  isEmployee?: Maybe<BoolFieldUpdateOperationsInput>;
  ordersCount?: Maybe<IntFieldUpdateOperationsInput>;
  ratings?: Maybe<CustomerRatingUpdateManyWithoutCustomerInput>;
  shouldDisplayRating?: Maybe<BoolFieldUpdateOperationsInput>;
  tags?: Maybe<TagUpdateManyWithoutCustomersInput>;
  tilledCustomerId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  tilledPaymentMethods?: Maybe<TilledPaymentMethodUpdateManyWithoutCustomerInfoInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneWithoutCustomerInfoInput>;
  vip?: Maybe<BoolFieldUpdateOperationsInput>;
};

export type UserCustomerInfoUpdateWithoutTagsInput = {
  braintreeCustomerId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  businessName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  businessType?: Maybe<NullableEnumBusinessTypeFieldUpdateOperationsInput>;
  cart?: Maybe<CartUpdateOneRequiredWithoutUserCustomerInfoInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customerNotes?: Maybe<UserCustomerInfoUpdatecustomerNotesInput>;
  defaultAddressId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  imgUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  internalNotes?: Maybe<UserCustomerInfoUpdateinternalNotesInput>;
  isAllowedToPayByInvoice?: Maybe<BoolFieldUpdateOperationsInput>;
  isEmployee?: Maybe<BoolFieldUpdateOperationsInput>;
  orders?: Maybe<OrderUpdateManyWithoutCustomerInput>;
  ordersCount?: Maybe<IntFieldUpdateOperationsInput>;
  ratings?: Maybe<CustomerRatingUpdateManyWithoutCustomerInput>;
  shouldDisplayRating?: Maybe<BoolFieldUpdateOperationsInput>;
  tilledCustomerId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  tilledPaymentMethods?: Maybe<TilledPaymentMethodUpdateManyWithoutCustomerInfoInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneWithoutCustomerInfoInput>;
  vip?: Maybe<BoolFieldUpdateOperationsInput>;
};

export type UserCustomerInfoUpdateWithoutTilledPaymentMethodsInput = {
  braintreeCustomerId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  businessName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  businessType?: Maybe<NullableEnumBusinessTypeFieldUpdateOperationsInput>;
  cart?: Maybe<CartUpdateOneRequiredWithoutUserCustomerInfoInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customerNotes?: Maybe<UserCustomerInfoUpdatecustomerNotesInput>;
  defaultAddressId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  imgUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  internalNotes?: Maybe<UserCustomerInfoUpdateinternalNotesInput>;
  isAllowedToPayByInvoice?: Maybe<BoolFieldUpdateOperationsInput>;
  isEmployee?: Maybe<BoolFieldUpdateOperationsInput>;
  orders?: Maybe<OrderUpdateManyWithoutCustomerInput>;
  ordersCount?: Maybe<IntFieldUpdateOperationsInput>;
  ratings?: Maybe<CustomerRatingUpdateManyWithoutCustomerInput>;
  shouldDisplayRating?: Maybe<BoolFieldUpdateOperationsInput>;
  tags?: Maybe<TagUpdateManyWithoutCustomersInput>;
  tilledCustomerId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneWithoutCustomerInfoInput>;
  vip?: Maybe<BoolFieldUpdateOperationsInput>;
};

export type UserCustomerInfoUpdateWithoutUserInput = {
  braintreeCustomerId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  businessName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  businessType?: Maybe<NullableEnumBusinessTypeFieldUpdateOperationsInput>;
  cart?: Maybe<CartUpdateOneRequiredWithoutUserCustomerInfoInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customerNotes?: Maybe<UserCustomerInfoUpdatecustomerNotesInput>;
  defaultAddressId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  imgUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  internalNotes?: Maybe<UserCustomerInfoUpdateinternalNotesInput>;
  isAllowedToPayByInvoice?: Maybe<BoolFieldUpdateOperationsInput>;
  isEmployee?: Maybe<BoolFieldUpdateOperationsInput>;
  orders?: Maybe<OrderUpdateManyWithoutCustomerInput>;
  ordersCount?: Maybe<IntFieldUpdateOperationsInput>;
  ratings?: Maybe<CustomerRatingUpdateManyWithoutCustomerInput>;
  shouldDisplayRating?: Maybe<BoolFieldUpdateOperationsInput>;
  tags?: Maybe<TagUpdateManyWithoutCustomersInput>;
  tilledCustomerId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  tilledPaymentMethods?: Maybe<TilledPaymentMethodUpdateManyWithoutCustomerInfoInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  vip?: Maybe<BoolFieldUpdateOperationsInput>;
};

export type UserCustomerInfoUpdatecustomerNotesInput = {
  push?: Maybe<Scalars['String']>;
  set?: Maybe<Array<Scalars['String']>>;
};

export type UserCustomerInfoUpdateinternalNotesInput = {
  push?: Maybe<Scalars['String']>;
  set?: Maybe<Array<Scalars['String']>>;
};

export type UserCustomerInfoUpsertWithWhereUniqueWithoutCartInput = {
  create: UserCustomerInfoCreateWithoutCartInput;
  update: UserCustomerInfoUpdateWithoutCartInput;
  where: UserCustomerInfoWhereUniqueInput;
};

export type UserCustomerInfoUpsertWithWhereUniqueWithoutTagsInput = {
  create: UserCustomerInfoCreateWithoutTagsInput;
  update: UserCustomerInfoUpdateWithoutTagsInput;
  where: UserCustomerInfoWhereUniqueInput;
};

export type UserCustomerInfoUpsertWithoutOrdersInput = {
  create: UserCustomerInfoCreateWithoutOrdersInput;
  update: UserCustomerInfoUpdateWithoutOrdersInput;
};

export type UserCustomerInfoUpsertWithoutTilledPaymentMethodsInput = {
  create: UserCustomerInfoCreateWithoutTilledPaymentMethodsInput;
  update: UserCustomerInfoUpdateWithoutTilledPaymentMethodsInput;
};

export type UserCustomerInfoUpsertWithoutUserInput = {
  create: UserCustomerInfoCreateWithoutUserInput;
  update: UserCustomerInfoUpdateWithoutUserInput;
};

export type UserCustomerInfoWhereInput = {
  AND?: Maybe<Array<UserCustomerInfoWhereInput>>;
  NOT?: Maybe<Array<UserCustomerInfoWhereInput>>;
  OR?: Maybe<Array<UserCustomerInfoWhereInput>>;
  braintreeCustomerId?: Maybe<StringNullableFilter>;
  businessName?: Maybe<StringNullableFilter>;
  businessType?: Maybe<EnumBusinessTypeNullableFilter>;
  cart?: Maybe<CartWhereInput>;
  cartId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  customerNotes?: Maybe<StringNullableListFilter>;
  defaultAddressId?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  imgUrl?: Maybe<StringNullableFilter>;
  internalNotes?: Maybe<StringNullableListFilter>;
  isAllowedToPayByInvoice?: Maybe<BoolFilter>;
  isEmployee?: Maybe<BoolFilter>;
  orders?: Maybe<OrderListRelationFilter>;
  ordersCount?: Maybe<IntFilter>;
  ratings?: Maybe<CustomerRatingListRelationFilter>;
  shouldDisplayRating?: Maybe<BoolFilter>;
  tags?: Maybe<TagListRelationFilter>;
  tilledCustomerId?: Maybe<StringNullableFilter>;
  tilledPaymentMethods?: Maybe<TilledPaymentMethodListRelationFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  user?: Maybe<UserWhereInput>;
  userId?: Maybe<StringNullableFilter>;
  vip?: Maybe<BoolFilter>;
};

export type UserCustomerInfoWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type UserInviteInput = {
  /** IDs of selected brands */
  brandIds: Array<Scalars['String']>;
  /** Email of new user */
  email: Scalars['String'];
  /** IDs of selected locations */
  locationIds: Array<Scalars['String']>;
  /** Name of new user */
  name: Scalars['String'];
  /** Role of new user */
  role: UserValidationRolesEnum;
  /** IDs of selected stations */
  stationIds: Array<Scalars['String']>;
  /** IDs of selected workplaces */
  workplaceIds: Array<Scalars['String']>;
};

export type UserListRelationFilter = {
  every?: Maybe<UserWhereInput>;
  none?: Maybe<UserWhereInput>;
  some?: Maybe<UserWhereInput>;
};

export type UserOperationsUpdateInput = {
  /** IDs of selected brands */
  brandIds: Array<Scalars['String']>;
  /** ID of the user */
  id: Scalars['ID'];
  /** IDs of selected locations */
  locationIds: Array<Scalars['String']>;
  /** IDs of selected stations */
  stationIds: Array<Scalars['String']>;
  /** IDs of selected workplaces */
  workplaceIds: Array<Scalars['String']>;
};

export type UserOrderByArg = {
  column: UserOrderByEnum;
  order: SortOrderArg;
};

export enum UserOrderByEnum {
  CreatedAt = 'createdAt',
  Name = 'name',
  Roles = 'roles'
}

export type UserProfileUpdateInput = {
  /** ID of user */
  id: Scalars['ID'];
  /** Name of user */
  name: Scalars['String'];
};

export enum UserRole {
  CateringCustomer = 'CATERING_CUSTOMER',
  CorporateAdmin = 'CORPORATE_ADMIN',
  CraveEmployee = 'CRAVE_EMPLOYEE',
  Customer = 'CUSTOMER',
  CustomerSupport = 'CUSTOMER_SUPPORT',
  EmployeeFoh = 'EMPLOYEE_FOH',
  EmployeeOrderStatus = 'EMPLOYEE_ORDER_STATUS',
  FacilityAdmin = 'FACILITY_ADMIN',
  KioskCustomer = 'KIOSK_CUSTOMER',
  RestaurantEmployee = 'RESTAURANT_EMPLOYEE',
  RestaurantOwner = 'RESTAURANT_OWNER'
}

export type UserScalarWhereInput = {
  AND?: Maybe<Array<UserScalarWhereInput>>;
  NOT?: Maybe<Array<UserScalarWhereInput>>;
  OR?: Maybe<Array<UserScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  email?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  isDeleted?: Maybe<BoolFilter>;
  isGuest?: Maybe<BoolFilter>;
  name?: Maybe<StringNullableFilter>;
  phone?: Maybe<StringNullableFilter>;
  roles?: Maybe<EnumUserRoleNullableListFilter>;
  tenantId?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type UserUpdateInput = {
  businessName?: Maybe<Scalars['String']>;
  businessType?: Maybe<BusinessType>;
  id: Scalars['ID'];
  imgUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type UserUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isDeleted?: Maybe<BoolFieldUpdateOperationsInput>;
  isGuest?: Maybe<BoolFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  roles?: Maybe<UserUpdaterolesInput>;
  tenantId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateManyWithWhereWithoutFacilitiesInput = {
  data: UserUpdateManyMutationInput;
  where: UserScalarWhereInput;
};

export type UserUpdateManyWithWhereWithoutKitchensInput = {
  data: UserUpdateManyMutationInput;
  where: UserScalarWhereInput;
};

export type UserUpdateManyWithoutFacilitiesInput = {
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutFacilitiesInput>>;
  create?: Maybe<Array<UserCreateWithoutFacilitiesInput>>;
  delete?: Maybe<Array<UserWhereUniqueInput>>;
  deleteMany?: Maybe<Array<UserScalarWhereInput>>;
  disconnect?: Maybe<Array<UserWhereUniqueInput>>;
  set?: Maybe<Array<UserWhereUniqueInput>>;
  update?: Maybe<Array<UserUpdateWithWhereUniqueWithoutFacilitiesInput>>;
  updateMany?: Maybe<Array<UserUpdateManyWithWhereWithoutFacilitiesInput>>;
  upsert?: Maybe<Array<UserUpsertWithWhereUniqueWithoutFacilitiesInput>>;
};

export type UserUpdateManyWithoutKitchensInput = {
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutKitchensInput>>;
  create?: Maybe<Array<UserCreateWithoutKitchensInput>>;
  delete?: Maybe<Array<UserWhereUniqueInput>>;
  deleteMany?: Maybe<Array<UserScalarWhereInput>>;
  disconnect?: Maybe<Array<UserWhereUniqueInput>>;
  set?: Maybe<Array<UserWhereUniqueInput>>;
  update?: Maybe<Array<UserUpdateWithWhereUniqueWithoutKitchensInput>>;
  updateMany?: Maybe<Array<UserUpdateManyWithWhereWithoutKitchensInput>>;
  upsert?: Maybe<Array<UserUpsertWithWhereUniqueWithoutKitchensInput>>;
};

export type UserUpdateOneRequiredWithoutPaymentInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutPaymentInput>;
  create?: Maybe<UserCreateWithoutPaymentInput>;
  update?: Maybe<UserUpdateWithoutPaymentInput>;
  upsert?: Maybe<UserUpsertWithoutPaymentInput>;
};

export type UserUpdateOneWithoutCustomerInfoInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutCustomerInfoInput>;
  create?: Maybe<UserCreateWithoutCustomerInfoInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<UserUpdateWithoutCustomerInfoInput>;
  upsert?: Maybe<UserUpsertWithoutCustomerInfoInput>;
};

export type UserUpdateWithWhereUniqueWithoutFacilitiesInput = {
  data: UserUpdateWithoutFacilitiesInput;
  where: UserWhereUniqueInput;
};

export type UserUpdateWithWhereUniqueWithoutKitchensInput = {
  data: UserUpdateWithoutKitchensInput;
  where: UserWhereUniqueInput;
};

export type UserUpdateWithoutCustomerInfoInput = {
  Payment?: Maybe<PaymentUpdateManyWithoutCustomerInput>;
  PayrocCustomerCard?: Maybe<PayrocCustomerCardUpdateManyWithoutCustomerInput>;
  addresses?: Maybe<CustomerDeliveryAddressUpdateManyWithoutUserInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  devices?: Maybe<DeviceUpdateManyWithoutUserInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  facilities?: Maybe<FacilityUpdateManyWithoutUsersInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isDeleted?: Maybe<BoolFieldUpdateOperationsInput>;
  isGuest?: Maybe<BoolFieldUpdateOperationsInput>;
  kitchens?: Maybe<KitchenUpdateManyWithoutUsersInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  roles?: Maybe<UserUpdaterolesInput>;
  tenantId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutFacilitiesInput = {
  Payment?: Maybe<PaymentUpdateManyWithoutCustomerInput>;
  PayrocCustomerCard?: Maybe<PayrocCustomerCardUpdateManyWithoutCustomerInput>;
  addresses?: Maybe<CustomerDeliveryAddressUpdateManyWithoutUserInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customerInfo?: Maybe<UserCustomerInfoUpdateOneWithoutUserInput>;
  devices?: Maybe<DeviceUpdateManyWithoutUserInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isDeleted?: Maybe<BoolFieldUpdateOperationsInput>;
  isGuest?: Maybe<BoolFieldUpdateOperationsInput>;
  kitchens?: Maybe<KitchenUpdateManyWithoutUsersInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  roles?: Maybe<UserUpdaterolesInput>;
  tenantId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutKitchensInput = {
  Payment?: Maybe<PaymentUpdateManyWithoutCustomerInput>;
  PayrocCustomerCard?: Maybe<PayrocCustomerCardUpdateManyWithoutCustomerInput>;
  addresses?: Maybe<CustomerDeliveryAddressUpdateManyWithoutUserInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customerInfo?: Maybe<UserCustomerInfoUpdateOneWithoutUserInput>;
  devices?: Maybe<DeviceUpdateManyWithoutUserInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  facilities?: Maybe<FacilityUpdateManyWithoutUsersInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isDeleted?: Maybe<BoolFieldUpdateOperationsInput>;
  isGuest?: Maybe<BoolFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  roles?: Maybe<UserUpdaterolesInput>;
  tenantId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutPaymentInput = {
  PayrocCustomerCard?: Maybe<PayrocCustomerCardUpdateManyWithoutCustomerInput>;
  addresses?: Maybe<CustomerDeliveryAddressUpdateManyWithoutUserInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customerInfo?: Maybe<UserCustomerInfoUpdateOneWithoutUserInput>;
  devices?: Maybe<DeviceUpdateManyWithoutUserInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  facilities?: Maybe<FacilityUpdateManyWithoutUsersInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isDeleted?: Maybe<BoolFieldUpdateOperationsInput>;
  isGuest?: Maybe<BoolFieldUpdateOperationsInput>;
  kitchens?: Maybe<KitchenUpdateManyWithoutUsersInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  roles?: Maybe<UserUpdaterolesInput>;
  tenantId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserUpdaterolesInput = {
  push?: Maybe<UserRole>;
  set?: Maybe<Array<UserRole>>;
};

export type UserUpsertWithWhereUniqueWithoutFacilitiesInput = {
  create: UserCreateWithoutFacilitiesInput;
  update: UserUpdateWithoutFacilitiesInput;
  where: UserWhereUniqueInput;
};

export type UserUpsertWithWhereUniqueWithoutKitchensInput = {
  create: UserCreateWithoutKitchensInput;
  update: UserUpdateWithoutKitchensInput;
  where: UserWhereUniqueInput;
};

export type UserUpsertWithoutCustomerInfoInput = {
  create: UserCreateWithoutCustomerInfoInput;
  update: UserUpdateWithoutCustomerInfoInput;
};

export type UserUpsertWithoutPaymentInput = {
  create: UserCreateWithoutPaymentInput;
  update: UserUpdateWithoutPaymentInput;
};

export enum UserValidationRolesEnum {
  CraveEmployee = 'CRAVE_EMPLOYEE',
  CustomerSupport = 'CUSTOMER_SUPPORT',
  EmployeeFoh = 'EMPLOYEE_FOH',
  EmployeeOrderStatus = 'EMPLOYEE_ORDER_STATUS',
  FacilityAdmin = 'FACILITY_ADMIN',
  KioskCustomer = 'KIOSK_CUSTOMER',
  RestaurantEmployee = 'RESTAURANT_EMPLOYEE',
  RestaurantOwner = 'RESTAURANT_OWNER'
}

export type UserWhereInput = {
  AND?: Maybe<Array<UserWhereInput>>;
  NOT?: Maybe<Array<UserWhereInput>>;
  OR?: Maybe<Array<UserWhereInput>>;
  Payment?: Maybe<PaymentListRelationFilter>;
  PayrocCustomerCard?: Maybe<PayrocCustomerCardListRelationFilter>;
  addresses?: Maybe<CustomerDeliveryAddressListRelationFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  customerInfo?: Maybe<UserCustomerInfoWhereInput>;
  devices?: Maybe<DeviceListRelationFilter>;
  email?: Maybe<StringNullableFilter>;
  facilities?: Maybe<FacilityListRelationFilter>;
  id?: Maybe<StringFilter>;
  isDeleted?: Maybe<BoolFilter>;
  isGuest?: Maybe<BoolFilter>;
  kitchens?: Maybe<KitchenListRelationFilter>;
  name?: Maybe<StringNullableFilter>;
  phone?: Maybe<StringNullableFilter>;
  roles?: Maybe<EnumUserRoleNullableListFilter>;
  tenantId?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type UserWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type Workplace = {
  __typename?: 'Workplace';
  id: Scalars['String'];
  index: Scalars['Int'];
  type: WorkplaceType;
};

export type WorkplaceCreateManyFacilityInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  type: WorkplaceType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type WorkplaceCreateManyFacilityInputEnvelope = {
  data?: Maybe<Array<WorkplaceCreateManyFacilityInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type WorkplaceCreateNestedManyWithoutFacilityInput = {
  connect?: Maybe<Array<WorkplaceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<WorkplaceCreateOrConnectWithoutFacilityInput>>;
  create?: Maybe<Array<WorkplaceCreateWithoutFacilityInput>>;
  createMany?: Maybe<WorkplaceCreateManyFacilityInputEnvelope>;
};

export type WorkplaceCreateNestedOneWithoutExpoInput = {
  connect?: Maybe<WorkplaceWhereUniqueInput>;
  connectOrCreate?: Maybe<WorkplaceCreateOrConnectWithoutExpoInput>;
  create?: Maybe<WorkplaceCreateWithoutExpoInput>;
};

export type WorkplaceCreateNestedOneWithoutKitchenInput = {
  connect?: Maybe<WorkplaceWhereUniqueInput>;
  connectOrCreate?: Maybe<WorkplaceCreateOrConnectWithoutKitchenInput>;
  create?: Maybe<WorkplaceCreateWithoutKitchenInput>;
};

export type WorkplaceCreateNestedOneWithoutPackagerInput = {
  connect?: Maybe<WorkplaceWhereUniqueInput>;
  connectOrCreate?: Maybe<WorkplaceCreateOrConnectWithoutPackagerInput>;
  create?: Maybe<WorkplaceCreateWithoutPackagerInput>;
};

export type WorkplaceCreateNestedOneWithoutPickupInput = {
  connect?: Maybe<WorkplaceWhereUniqueInput>;
  connectOrCreate?: Maybe<WorkplaceCreateOrConnectWithoutPickupInput>;
  create?: Maybe<WorkplaceCreateWithoutPickupInput>;
};

export type WorkplaceCreateNestedOneWithoutRunnerInput = {
  connect?: Maybe<WorkplaceWhereUniqueInput>;
  connectOrCreate?: Maybe<WorkplaceCreateOrConnectWithoutRunnerInput>;
  create?: Maybe<WorkplaceCreateWithoutRunnerInput>;
};

export type WorkplaceCreateOrConnectWithoutExpoInput = {
  create: WorkplaceCreateWithoutExpoInput;
  where: WorkplaceWhereUniqueInput;
};

export type WorkplaceCreateOrConnectWithoutFacilityInput = {
  create: WorkplaceCreateWithoutFacilityInput;
  where: WorkplaceWhereUniqueInput;
};

export type WorkplaceCreateOrConnectWithoutKitchenInput = {
  create: WorkplaceCreateWithoutKitchenInput;
  where: WorkplaceWhereUniqueInput;
};

export type WorkplaceCreateOrConnectWithoutPackagerInput = {
  create: WorkplaceCreateWithoutPackagerInput;
  where: WorkplaceWhereUniqueInput;
};

export type WorkplaceCreateOrConnectWithoutPickupInput = {
  create: WorkplaceCreateWithoutPickupInput;
  where: WorkplaceWhereUniqueInput;
};

export type WorkplaceCreateOrConnectWithoutRunnerInput = {
  create: WorkplaceCreateWithoutRunnerInput;
  where: WorkplaceWhereUniqueInput;
};

export type WorkplaceCreateWithoutExpoInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  facility: FacilityCreateNestedOneWithoutWorkplacesInput;
  id?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  kitchen?: Maybe<KitchenCreateNestedOneWithoutWorkplaceInput>;
  packager?: Maybe<OrderCreateNestedManyWithoutPackagerInput>;
  pickup?: Maybe<OrderCreateNestedManyWithoutPickupInput>;
  runner?: Maybe<OrderCreateNestedManyWithoutRunnerInput>;
  type: WorkplaceType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type WorkplaceCreateWithoutFacilityInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  expo?: Maybe<OrderCreateNestedManyWithoutExpoInput>;
  id?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  kitchen?: Maybe<KitchenCreateNestedOneWithoutWorkplaceInput>;
  packager?: Maybe<OrderCreateNestedManyWithoutPackagerInput>;
  pickup?: Maybe<OrderCreateNestedManyWithoutPickupInput>;
  runner?: Maybe<OrderCreateNestedManyWithoutRunnerInput>;
  type: WorkplaceType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type WorkplaceCreateWithoutKitchenInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  expo?: Maybe<OrderCreateNestedManyWithoutExpoInput>;
  facility: FacilityCreateNestedOneWithoutWorkplacesInput;
  id?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  packager?: Maybe<OrderCreateNestedManyWithoutPackagerInput>;
  pickup?: Maybe<OrderCreateNestedManyWithoutPickupInput>;
  runner?: Maybe<OrderCreateNestedManyWithoutRunnerInput>;
  type: WorkplaceType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type WorkplaceCreateWithoutPackagerInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  expo?: Maybe<OrderCreateNestedManyWithoutExpoInput>;
  facility: FacilityCreateNestedOneWithoutWorkplacesInput;
  id?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  kitchen?: Maybe<KitchenCreateNestedOneWithoutWorkplaceInput>;
  pickup?: Maybe<OrderCreateNestedManyWithoutPickupInput>;
  runner?: Maybe<OrderCreateNestedManyWithoutRunnerInput>;
  type: WorkplaceType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type WorkplaceCreateWithoutPickupInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  expo?: Maybe<OrderCreateNestedManyWithoutExpoInput>;
  facility: FacilityCreateNestedOneWithoutWorkplacesInput;
  id?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  kitchen?: Maybe<KitchenCreateNestedOneWithoutWorkplaceInput>;
  packager?: Maybe<OrderCreateNestedManyWithoutPackagerInput>;
  runner?: Maybe<OrderCreateNestedManyWithoutRunnerInput>;
  type: WorkplaceType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type WorkplaceCreateWithoutRunnerInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  expo?: Maybe<OrderCreateNestedManyWithoutExpoInput>;
  facility: FacilityCreateNestedOneWithoutWorkplacesInput;
  id?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  kitchen?: Maybe<KitchenCreateNestedOneWithoutWorkplaceInput>;
  packager?: Maybe<OrderCreateNestedManyWithoutPackagerInput>;
  pickup?: Maybe<OrderCreateNestedManyWithoutPickupInput>;
  type: WorkplaceType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type WorkplaceListRelationFilter = {
  every?: Maybe<WorkplaceWhereInput>;
  none?: Maybe<WorkplaceWhereInput>;
  some?: Maybe<WorkplaceWhereInput>;
};

export type WorkplaceScalarWhereInput = {
  AND?: Maybe<Array<WorkplaceScalarWhereInput>>;
  NOT?: Maybe<Array<WorkplaceScalarWhereInput>>;
  OR?: Maybe<Array<WorkplaceScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  facilityId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  index?: Maybe<IntFilter>;
  type?: Maybe<EnumWorkplaceTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export enum WorkplaceType {
  Expo = 'EXPO',
  Kitchen = 'KITCHEN',
  Packaging = 'PACKAGING',
  Pickup = 'PICKUP',
  Runner = 'RUNNER'
}

export type WorkplaceTypeIndexFacilityIdCompoundUniqueInput = {
  facilityId: Scalars['String'];
  index: Scalars['Int'];
  type: WorkplaceType;
};

export type WorkplaceUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  index?: Maybe<IntFieldUpdateOperationsInput>;
  type?: Maybe<EnumWorkplaceTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type WorkplaceUpdateManyWithWhereWithoutFacilityInput = {
  data: WorkplaceUpdateManyMutationInput;
  where: WorkplaceScalarWhereInput;
};

export type WorkplaceUpdateManyWithoutFacilityInput = {
  connect?: Maybe<Array<WorkplaceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<WorkplaceCreateOrConnectWithoutFacilityInput>>;
  create?: Maybe<Array<WorkplaceCreateWithoutFacilityInput>>;
  createMany?: Maybe<WorkplaceCreateManyFacilityInputEnvelope>;
  delete?: Maybe<Array<WorkplaceWhereUniqueInput>>;
  deleteMany?: Maybe<Array<WorkplaceScalarWhereInput>>;
  disconnect?: Maybe<Array<WorkplaceWhereUniqueInput>>;
  set?: Maybe<Array<WorkplaceWhereUniqueInput>>;
  update?: Maybe<Array<WorkplaceUpdateWithWhereUniqueWithoutFacilityInput>>;
  updateMany?: Maybe<Array<WorkplaceUpdateManyWithWhereWithoutFacilityInput>>;
  upsert?: Maybe<Array<WorkplaceUpsertWithWhereUniqueWithoutFacilityInput>>;
};

export type WorkplaceUpdateOneWithoutExpoInput = {
  connect?: Maybe<WorkplaceWhereUniqueInput>;
  connectOrCreate?: Maybe<WorkplaceCreateOrConnectWithoutExpoInput>;
  create?: Maybe<WorkplaceCreateWithoutExpoInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<WorkplaceUpdateWithoutExpoInput>;
  upsert?: Maybe<WorkplaceUpsertWithoutExpoInput>;
};

export type WorkplaceUpdateOneWithoutKitchenInput = {
  connect?: Maybe<WorkplaceWhereUniqueInput>;
  connectOrCreate?: Maybe<WorkplaceCreateOrConnectWithoutKitchenInput>;
  create?: Maybe<WorkplaceCreateWithoutKitchenInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<WorkplaceUpdateWithoutKitchenInput>;
  upsert?: Maybe<WorkplaceUpsertWithoutKitchenInput>;
};

export type WorkplaceUpdateOneWithoutPackagerInput = {
  connect?: Maybe<WorkplaceWhereUniqueInput>;
  connectOrCreate?: Maybe<WorkplaceCreateOrConnectWithoutPackagerInput>;
  create?: Maybe<WorkplaceCreateWithoutPackagerInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<WorkplaceUpdateWithoutPackagerInput>;
  upsert?: Maybe<WorkplaceUpsertWithoutPackagerInput>;
};

export type WorkplaceUpdateOneWithoutPickupInput = {
  connect?: Maybe<WorkplaceWhereUniqueInput>;
  connectOrCreate?: Maybe<WorkplaceCreateOrConnectWithoutPickupInput>;
  create?: Maybe<WorkplaceCreateWithoutPickupInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<WorkplaceUpdateWithoutPickupInput>;
  upsert?: Maybe<WorkplaceUpsertWithoutPickupInput>;
};

export type WorkplaceUpdateOneWithoutRunnerInput = {
  connect?: Maybe<WorkplaceWhereUniqueInput>;
  connectOrCreate?: Maybe<WorkplaceCreateOrConnectWithoutRunnerInput>;
  create?: Maybe<WorkplaceCreateWithoutRunnerInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<WorkplaceUpdateWithoutRunnerInput>;
  upsert?: Maybe<WorkplaceUpsertWithoutRunnerInput>;
};

export type WorkplaceUpdateWithWhereUniqueWithoutFacilityInput = {
  data: WorkplaceUpdateWithoutFacilityInput;
  where: WorkplaceWhereUniqueInput;
};

export type WorkplaceUpdateWithoutExpoInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  facility?: Maybe<FacilityUpdateOneRequiredWithoutWorkplacesInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  index?: Maybe<IntFieldUpdateOperationsInput>;
  kitchen?: Maybe<KitchenUpdateOneWithoutWorkplaceInput>;
  packager?: Maybe<OrderUpdateManyWithoutPackagerInput>;
  pickup?: Maybe<OrderUpdateManyWithoutPickupInput>;
  runner?: Maybe<OrderUpdateManyWithoutRunnerInput>;
  type?: Maybe<EnumWorkplaceTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type WorkplaceUpdateWithoutFacilityInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  expo?: Maybe<OrderUpdateManyWithoutExpoInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  index?: Maybe<IntFieldUpdateOperationsInput>;
  kitchen?: Maybe<KitchenUpdateOneWithoutWorkplaceInput>;
  packager?: Maybe<OrderUpdateManyWithoutPackagerInput>;
  pickup?: Maybe<OrderUpdateManyWithoutPickupInput>;
  runner?: Maybe<OrderUpdateManyWithoutRunnerInput>;
  type?: Maybe<EnumWorkplaceTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type WorkplaceUpdateWithoutKitchenInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  expo?: Maybe<OrderUpdateManyWithoutExpoInput>;
  facility?: Maybe<FacilityUpdateOneRequiredWithoutWorkplacesInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  index?: Maybe<IntFieldUpdateOperationsInput>;
  packager?: Maybe<OrderUpdateManyWithoutPackagerInput>;
  pickup?: Maybe<OrderUpdateManyWithoutPickupInput>;
  runner?: Maybe<OrderUpdateManyWithoutRunnerInput>;
  type?: Maybe<EnumWorkplaceTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type WorkplaceUpdateWithoutPackagerInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  expo?: Maybe<OrderUpdateManyWithoutExpoInput>;
  facility?: Maybe<FacilityUpdateOneRequiredWithoutWorkplacesInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  index?: Maybe<IntFieldUpdateOperationsInput>;
  kitchen?: Maybe<KitchenUpdateOneWithoutWorkplaceInput>;
  pickup?: Maybe<OrderUpdateManyWithoutPickupInput>;
  runner?: Maybe<OrderUpdateManyWithoutRunnerInput>;
  type?: Maybe<EnumWorkplaceTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type WorkplaceUpdateWithoutPickupInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  expo?: Maybe<OrderUpdateManyWithoutExpoInput>;
  facility?: Maybe<FacilityUpdateOneRequiredWithoutWorkplacesInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  index?: Maybe<IntFieldUpdateOperationsInput>;
  kitchen?: Maybe<KitchenUpdateOneWithoutWorkplaceInput>;
  packager?: Maybe<OrderUpdateManyWithoutPackagerInput>;
  runner?: Maybe<OrderUpdateManyWithoutRunnerInput>;
  type?: Maybe<EnumWorkplaceTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type WorkplaceUpdateWithoutRunnerInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  expo?: Maybe<OrderUpdateManyWithoutExpoInput>;
  facility?: Maybe<FacilityUpdateOneRequiredWithoutWorkplacesInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  index?: Maybe<IntFieldUpdateOperationsInput>;
  kitchen?: Maybe<KitchenUpdateOneWithoutWorkplaceInput>;
  packager?: Maybe<OrderUpdateManyWithoutPackagerInput>;
  pickup?: Maybe<OrderUpdateManyWithoutPickupInput>;
  type?: Maybe<EnumWorkplaceTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type WorkplaceUpsertWithWhereUniqueWithoutFacilityInput = {
  create: WorkplaceCreateWithoutFacilityInput;
  update: WorkplaceUpdateWithoutFacilityInput;
  where: WorkplaceWhereUniqueInput;
};

export type WorkplaceUpsertWithoutExpoInput = {
  create: WorkplaceCreateWithoutExpoInput;
  update: WorkplaceUpdateWithoutExpoInput;
};

export type WorkplaceUpsertWithoutKitchenInput = {
  create: WorkplaceCreateWithoutKitchenInput;
  update: WorkplaceUpdateWithoutKitchenInput;
};

export type WorkplaceUpsertWithoutPackagerInput = {
  create: WorkplaceCreateWithoutPackagerInput;
  update: WorkplaceUpdateWithoutPackagerInput;
};

export type WorkplaceUpsertWithoutPickupInput = {
  create: WorkplaceCreateWithoutPickupInput;
  update: WorkplaceUpdateWithoutPickupInput;
};

export type WorkplaceUpsertWithoutRunnerInput = {
  create: WorkplaceCreateWithoutRunnerInput;
  update: WorkplaceUpdateWithoutRunnerInput;
};

export type WorkplaceWhereInput = {
  AND?: Maybe<Array<WorkplaceWhereInput>>;
  NOT?: Maybe<Array<WorkplaceWhereInput>>;
  OR?: Maybe<Array<WorkplaceWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  expo?: Maybe<OrderListRelationFilter>;
  facility?: Maybe<FacilityWhereInput>;
  facilityId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  index?: Maybe<IntFilter>;
  kitchen?: Maybe<KitchenWhereInput>;
  packager?: Maybe<OrderListRelationFilter>;
  pickup?: Maybe<OrderListRelationFilter>;
  runner?: Maybe<OrderListRelationFilter>;
  type?: Maybe<EnumWorkplaceTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type WorkplaceWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  type_index_facilityId?: Maybe<WorkplaceTypeIndexFacilityIdCompoundUniqueInput>;
};

export type ConfirmGenericCosts = {
  subtotal?: Maybe<Scalars['Int']>;
  tax?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export type PlatformUserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'email' | 'name' | 'roles'>
);

export type UserCustomerInfoFragment = (
  { __typename?: 'User' }
  & { kitchens: Array<(
    { __typename?: 'Kitchen' }
    & Pick<Kitchen, 'id' | 'name'>
  )>, facilities: Array<(
    { __typename?: 'Facility' }
    & Pick<Facility, 'id' | 'name'>
    & { stations: Array<(
      { __typename?: 'Station' }
      & Pick<Station, 'id' | 'kitchen' | 'name'>
    )>, workplaces: Array<(
      { __typename?: 'Workplace' }
      & Pick<Workplace, 'id' | 'index' | 'type'>
    )> }
  )> }
  & UserInfoFragment
);

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'adminDeletePortalUser'>
);

export type UpdateUserProfileMutationVariables = Exact<{
  input: UserProfileUpdateInput;
}>;


export type UpdateUserProfileMutation = (
  { __typename?: 'Mutation' }
  & { userProfileUpdate?: Maybe<(
    { __typename?: 'User' }
    & UserCustomerInfoFragment
  )> }
);

export type UpdateUserOperationsMutationVariables = Exact<{
  input: UserOperationsUpdateInput;
}>;


export type UpdateUserOperationsMutation = (
  { __typename?: 'Mutation' }
  & { userOperationsUpdate?: Maybe<(
    { __typename?: 'User' }
    & UserCustomerInfoFragment
  )> }
);

export type UserManagementListQueryVariables = Exact<{
  orderBy: AdminPortalUserOrderByArg;
  nameFilter?: Maybe<Scalars['String']>;
  pagination: PaginationArg;
}>;


export type UserManagementListQuery = (
  { __typename?: 'Query' }
  & { platformUsers: (
    { __typename?: 'PlatformUsers' }
    & Pick<PlatformUsers, 'total'>
    & { adminUsers: Array<(
      { __typename?: 'User' }
      & PlatformUserFragment
    )> }
  ) }
);

export type UserDetailsQueryVariables = Exact<{
  customerId: Scalars['ID'];
}>;


export type UserDetailsQuery = (
  { __typename?: 'Query' }
  & { adminPortalUser?: Maybe<(
    { __typename?: 'User' }
    & UserCustomerInfoFragment
  )> }
);

export type FacilityFragment = (
  { __typename?: 'Facility' }
  & Pick<Facility, 'id' | 'name' | 'kdsType' | 'timeZone' | 'operationHours' | 'enableCatering' | 'enableKiosk' | 'kioskLogoUrl' | 'logoUrl' | 'heroImageUrl' | 'formattedAddress' | 'phone' | 'email' | 'isFOHPrinterAvailable' | 'isGiftCardEnabled' | 'isCmsEnabled' | 'isPromoEnabled' | 'readonly'>
  & FacilityTaxFragment
);

export type FacilityTaxFragment = (
  { __typename?: 'Facility' }
  & Pick<Facility, 'id' | 'isCateringDeliveryFeeTaxable' | 'isCateringServiceFeeTaxable' | 'isConsumerDeliveryFeeTaxable' | 'isConsumerServiceFeeTaxable' | 'cateringDeliveryFee' | 'cateringDeliveryFeeTaxRate' | 'cateringServiceFee' | 'cateringServiceFeeTaxRate' | 'consumerDeliveryFee' | 'consumerDeliveryFeeTaxRate' | 'consumerServiceFee' | 'consumerServiceFeeTaxRate' | 'consumerServiceFeeType' | 'cateringServiceFeeType' | 'taxRate'>
);

export type KitchenMenuItemShortInfoFragment = (
  { __typename?: 'KitchenMenuItem' }
  & Pick<KitchenMenuItem, 'id' | 'name' | 'status' | 'inventoryCount' | 'inventoryStatus' | 'enableInventoryCount'>
);

export type MealPackItemInfoFragment = (
  { __typename?: 'KitchenMenuItem' }
  & Pick<KitchenMenuItem, 'id' | 'name' | 'description' | 'price' | 'status' | 'imgUrl' | 'dishTypes' | 'inventoryStatus' | 'enableInventoryCount' | 'availableFrom' | 'availableTo' | 'availability' | 'sortKitchenIds' | 'labels' | 'type'>
);

export type MenuCategoryInfoFragment = (
  { __typename?: 'MenuCategory' }
  & Pick<MenuCategory, 'id' | 'name' | 'menuItemType' | 'categoryType'>
);

export type ExtraItemInfoFragment = (
  { __typename?: 'ExtraItem' }
  & Pick<ExtraItem, 'id' | 'name'>
);

export type SearchMenuItemInfoFragment = (
  { __typename?: 'KitchenMenuItem' }
  & Pick<KitchenMenuItem, 'id' | 'name' | 'price' | 'imgUrl' | 'sortId'>
  & { kitchen: (
    { __typename?: 'Kitchen' }
    & Pick<Kitchen, 'id' | 'name'>
  ) }
);

export type OrderShortInfoFragment = (
  { __typename?: 'Order' }
  & Pick<Order, 'id' | 'shortId' | 'submittedAt' | 'status' | 'isCatering' | 'isEmployee' | 'method'>
);

export type OrderItemInfoFragment = (
  { __typename?: 'OrderItem' }
  & Pick<OrderItem, 'id' | 'quantity' | 'price' | 'name' | 'type' | 'orderMealPackId'>
);

export type OrderExtraItemInfoFragment = (
  { __typename?: 'OrderItemExtra' }
  & Pick<OrderItemExtra, 'id' | 'price' | 'quantity' | 'name' | 'extraSortId' | 'extraItemSortId'>
);

export type CustomerOrderInfoFragment = (
  { __typename?: 'Order' }
  & Pick<Order, 'id' | 'shortId' | 'submittedAt' | 'status' | 'method' | 'canceledAt' | 'deliveredAt' | 'fulfillmentAt' | 'subtotal' | 'total' | 'locality' | 'postalCode' | 'street' | 'placeId' | 'addressNote' | 'isCatering' | 'isOrderNow' | 'formattedAddress' | 'payedAt' | 'orderName' | 'platform'>
);

export type OrderInfoFragment = (
  { __typename?: 'Order' }
  & Pick<Order, 'id' | 'shortId' | 'submittedAt' | 'status' | 'method' | 'canceledAt' | 'delivery' | 'deliveredAt' | 'deliveryEstimation' | 'subtotal' | 'taxFees' | 'tax' | 'fee' | 'total' | 'tip' | 'promoCodes' | 'giftCardCodes' | 'giftCardDiscounts' | 'promoDiscount' | 'giftCardsDiscount' | 'locality' | 'postalCode' | 'street' | 'placeId' | 'utensilsCount' | 'deliveryOption' | 'addressNote' | 'isCatering' | 'isVip' | 'isOrderNow' | 'fulfillmentAt' | 'fulfillmentBy' | 'preparingUntil' | 'deliveryTimeInSeconds' | 'refireDelay' | 'formattedAddress' | 'payedAt' | 'orderName' | 'orderPhoneNumber' | 'orderEmail' | 'platform' | 'note' | 'source'>
  & { customer: (
    { __typename?: 'UserCustomerInfo' }
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name' | 'phone' | 'email' | 'isGuest'>
    )> }
  ), braintreeTransactions: Array<(
    { __typename?: 'BraintreeTransaction' }
    & Pick<BraintreeTransaction, 'id' | 'paymentMethod' | 'paymentMethodInfo' | 'status'>
  )>, facility: (
    { __typename?: 'Facility' }
    & Pick<Facility, 'id' | 'formattedAddress' | 'logoUrl' | 'name'>
  ) }
);

export type RefundsFragment = (
  { __typename?: 'OrderRefund' }
  & Pick<OrderRefund, 'reason' | 'type' | 'createdAt'>
  & { calculations: (
    { __typename?: 'OrderRefundCalculations' }
    & Pick<OrderRefundCalculations, 'creditCardRefund' | 'giftCardRefund' | 'deliveryFee' | 'gratuity' | 'serviceFee' | 'subtotal' | 'tax' | 'total'>
  ), items: Array<(
    { __typename?: 'RefundItem' }
    & Pick<RefundItem, 'id' | 'quantity'>
    & { orderItem: (
      { __typename?: 'OrderItem' }
      & Pick<OrderItem, 'id' | 'price' | 'name' | 'type' | 'quantity'>
      & { menuItem: (
        { __typename?: 'KitchenMenuItem' }
        & { kitchen: (
          { __typename?: 'Kitchen' }
          & Pick<Kitchen, 'id' | 'name'>
        ) }
        & KitchenMenuItemShortInfoFragment
      ), extraItems: Array<(
        { __typename?: 'OrderItemExtra' }
        & Pick<OrderItemExtra, 'id' | 'price' | 'quantity' | 'name' | 'extraSortId' | 'extraItemSortId'>
      )>, mealPackOrderItems: Array<(
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'id' | 'price' | 'quantity' | 'name' | 'type'>
      )> }
    ) }
  )> }
);

export type MultiBrandOrderInfoFragment = (
  { __typename?: 'Order' }
  & { kitchenTickets: Array<(
    { __typename?: 'KitchenTicket' }
    & Pick<KitchenTicket, 'id' | 'status'>
    & { kitchen?: Maybe<(
      { __typename?: 'Kitchen' }
      & Pick<Kitchen, 'id' | 'name'>
    )>, station?: Maybe<(
      { __typename?: 'Station' }
      & Pick<Station, 'id' | 'name'>
    )>, items: Array<(
      { __typename?: 'FacilityItem' }
      & Pick<FacilityItem, 'id' | 'quantity'>
    )> }
  )>, retailItems: Array<(
    { __typename?: 'OrderItem' }
    & Pick<OrderItem, 'id' | 'quantity'>
  )> }
);

export type OrderBundleInfoFragment = (
  { __typename?: 'OrderBundle' }
  & Pick<OrderBundle, 'id' | 'shortId' | 'sortedOrderIds' | 'isLocked'>
);

export type AddressInfoFragment = (
  { __typename?: 'Address' }
  & Pick<Address, 'id' | 'locality' | 'region' | 'postalCode' | 'street' | 'nickname' | 'placeId' | 'isDefault' | 'formattedAddress'>
);

export type CustomerInfoFragment = (
  { __typename?: 'UserCustomerInfo' }
  & Pick<UserCustomerInfo, 'id' | 'customerNotes' | 'internalNotes' | 'vip' | 'isEmployee' | 'ordersCount'>
);

export type UserInfoFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'name' | 'email' | 'phone' | 'createdAt' | 'roles'>
);

export type TagInfoFragment = (
  { __typename?: 'Tag' }
  & Pick<Tag, 'id' | 'name'>
);

export type KitchenTicketFragment = (
  { __typename?: 'KitchenTicket' }
  & Pick<KitchenTicket, 'id' | 'shortId' | 'preparingFrom' | 'preparingUntil' | 'status' | 'refiredAt' | 'createdAt' | 'refireReason' | 'refiredFromId' | 'delayTime' | 'cookTime' | 'isPrinted'>
  & { order: (
    { __typename?: 'Order' }
    & Pick<Order, 'platform'>
    & { refunds: Array<(
      { __typename?: 'OrderRefund' }
      & OrderRefundFragment
    )>, refundableItems: Array<(
      { __typename?: 'OrderItem' }
      & RefundableItemsFragment
    )> }
    & MultiBrandOrderInfoFragment
    & OrderInfoFragment
    & CustomerTagsFragment
  ), items: Array<(
    { __typename?: 'FacilityItem' }
    & FacilityItemInfoFragment
  )> }
);

export type BasicOrderFragment = (
  { __typename?: 'Order' }
  & Pick<Order, 'id' | 'shortId' | 'refireDelay'>
);

export type KitchenTicketsRecentlyDoneFragment = (
  { __typename?: 'KitchenTicket' }
  & Pick<KitchenTicket, 'id' | 'preparedAt'>
  & { order: (
    { __typename?: 'Order' }
    & Pick<Order, 'id' | 'shortId'>
    & { refundableItems: Array<(
      { __typename?: 'OrderItem' }
      & RefundableItemsFragment
    )> }
  ) }
);

export type RefundableItemsFragment = (
  { __typename?: 'OrderItem' }
  & Pick<OrderItem, 'id' | 'name' | 'quantity' | 'price'>
  & { extraItems: Array<(
    { __typename?: 'OrderItemExtra' }
    & Pick<OrderItemExtra, 'id' | 'name' | 'price' | 'quantity'>
  )> }
);

export type KdsExpoOrderFragment = (
  { __typename?: 'Order' }
  & Pick<Order, 'id' | 'status' | 'shortId' | 'refired' | 'method' | 'utensilsCount' | 'isVip' | 'isCatering' | 'kitchenPreparingUntil' | 'platform' | 'orderName' | 'orderPhoneNumber' | 'source' | 'fulfillmentAt' | 'fulfillmentBy' | 'isOrderNow' | 'preparingUntil' | 'note'>
  & { packager?: Maybe<(
    { __typename?: 'Workplace' }
    & Pick<Workplace, 'id' | 'index'>
  )>, kitchenTickets: Array<(
    { __typename?: 'KitchenTicket' }
    & ExpoKitchenTicketFragment
  )>, refunds: Array<(
    { __typename?: 'OrderRefund' }
    & OrderRefundFragment
  )>, refundableItems: Array<(
    { __typename?: 'OrderItem' }
    & RefundableItemsFragment
  )> }
  & TicketInfoWithStatusFragment
  & CustomerTagsFragment
);

export type KdsRunnerOrderFragment = (
  { __typename?: 'Order' }
  & Pick<Order, 'id' | 'method'>
  & { bundle?: Maybe<(
    { __typename?: 'OrderBundle' }
    & BundleShortInfoFragment
  )> }
  & CustomerTagsFragment
);

export type TicketInfoWithStatusFragment = (
  { __typename?: 'Order' }
  & Pick<Order, 'refireDelay' | 'isEmployee'>
  & { retailItems: Array<(
    { __typename?: 'OrderItem' }
    & Pick<OrderItem, 'id' | 'name' | 'quantity'>
    & { extraItems: Array<(
      { __typename?: 'OrderItemExtra' }
      & Pick<OrderItemExtra, 'id' | 'price' | 'quantity' | 'name' | 'extraSortId' | 'extraItemSortId'>
    )> }
  )> }
);

export type ExpoKitchenTicketFragment = (
  { __typename?: 'KitchenTicket' }
  & Pick<KitchenTicket, 'id' | 'preparingUntil' | 'status' | 'refireReason' | 'refiredFromId' | 'delayTime'>
  & { kitchen?: Maybe<(
    { __typename?: 'Kitchen' }
    & Pick<Kitchen, 'id' | 'name'>
  )>, station?: Maybe<(
    { __typename?: 'Station' }
    & Pick<Station, 'id' | 'name'>
  )>, items: Array<(
    { __typename?: 'FacilityItem' }
    & FacilityItemInfoFragment
  )> }
);

export type FacilityItemInfoFragment = (
  { __typename?: 'FacilityItem' }
  & Pick<FacilityItem, 'id' | 'refired' | 'quantity'>
  & { menuItem: (
    { __typename?: 'KitchenMenuItem' }
    & Pick<KitchenMenuItem, 'id' | 'name'>
    & { kitchen: (
      { __typename?: 'Kitchen' }
      & Pick<Kitchen, 'id' | 'name'>
    ) }
  ), orderItem: (
    { __typename?: 'OrderItem' }
    & Pick<OrderItem, 'id' | 'name' | 'orderMealPackId'>
    & { extraItems: Array<(
      { __typename?: 'OrderItemExtra' }
      & Pick<OrderItemExtra, 'id' | 'name' | 'quantity' | 'extraSortId' | 'extraItemSortId'>
    )>, orderMealPack?: Maybe<(
      { __typename?: 'OrderItem' }
      & Pick<OrderItem, 'id' | 'name'>
    )> }
  ) }
);

export type KdsExpoRecentOrderFragment = (
  { __typename?: 'Order' }
  & Pick<Order, 'id' | 'shortId' | 'utensilsCount' | 'platform'>
);

export type KdsOrderAssignmentOrderFragment = (
  { __typename?: 'Order' }
  & Pick<Order, 'id' | 'shortId' | 'status'>
  & { packager?: Maybe<(
    { __typename?: 'Workplace' }
    & Pick<Workplace, 'id' | 'index'>
  )> }
);

export type BundleShortInfoFragment = (
  { __typename?: 'OrderBundle' }
  & Pick<OrderBundle, 'id' | 'shortId' | 'status' | 'deliveryStart'>
);

export type CustomerTagsFragment = (
  { __typename?: 'Order' }
  & Pick<Order, 'id' | 'isCatering' | 'isVip' | 'source'>
  & { customer: (
    { __typename?: 'UserCustomerInfo' }
    & Pick<UserCustomerInfo, 'id' | 'ordersCount' | 'isEmployee'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name' | 'phone'>
    )>, tags: Array<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'name'>
    )> }
  ) }
);

export type KdsPickupOrderFragment = (
  { __typename?: 'Order' }
  & Pick<Order, 'id' | 'shortId' | 'formattedAddress' | 'refireDelay' | 'status' | 'preparingUntil' | 'isEmployee' | 'isOrderNow' | 'fulfillmentAt' | 'isCatering' | 'isVip' | 'method' | 'source' | 'orderName' | 'orderPhoneNumber' | 'platform'>
  & { customer: (
    { __typename?: 'UserCustomerInfo' }
    & Pick<UserCustomerInfo, 'id' | 'ordersCount'>
    & { tags: Array<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'name'>
    )>, user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name' | 'phone'>
    )> }
  ), items: Array<(
    { __typename?: 'OrderItem' }
    & Pick<OrderItem, 'id' | 'quantity' | 'name' | 'type' | 'orderMealPackId'>
    & { menuItem: (
      { __typename?: 'KitchenMenuItem' }
      & Pick<KitchenMenuItem, 'id' | 'name'>
    ), orderMealPack?: Maybe<(
      { __typename?: 'OrderItem' }
      & Pick<OrderItem, 'id' | 'name'>
    )> }
  )>, pickup?: Maybe<(
    { __typename?: 'Workplace' }
    & Pick<Workplace, 'id' | 'index'>
  )> }
);

export type KdsOrderItemFragment = (
  { __typename?: 'OrderItem' }
  & Pick<OrderItem, 'id' | 'quantity' | 'name'>
  & { menuItem: (
    { __typename?: 'KitchenMenuItem' }
    & Pick<KitchenMenuItem, 'id' | 'isRetail' | 'name'>
    & { kitchen: (
      { __typename?: 'Kitchen' }
      & Pick<Kitchen, 'id' | 'name'>
    ) }
  ), extraItems: Array<(
    { __typename?: 'OrderItemExtra' }
    & Pick<OrderItemExtra, 'id' | 'price' | 'quantity' | 'name' | 'extraSortId' | 'extraItemSortId'>
  )> }
);

export type AssignmentTicketFragment = (
  { __typename?: 'Order' }
  & Pick<Order, 'id' | 'status' | 'shortId' | 'refired' | 'method' | 'kitchenPreparingUntil' | 'orderPhoneNumber' | 'preparingUntil'>
  & { packager?: Maybe<(
    { __typename?: 'Workplace' }
    & Pick<Workplace, 'id' | 'index'>
  )>, tags: Array<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'id' | 'name'>
  )>, kitchenTickets: Array<(
    { __typename?: 'KitchenTicket' }
    & Pick<KitchenTicket, 'id' | 'status'>
    & { items: Array<(
      { __typename?: 'FacilityItem' }
      & Pick<FacilityItem, 'id' | 'quantity'>
    )> }
  )> }
);

export type PromoListInfoFragment = (
  { __typename?: 'Promo' }
  & Pick<Promo, 'id' | 'code' | 'isAllKitchensAllFacilities' | 'method' | 'orderCount' | 'type' | 'typeDefinition' | 'requirements' | 'appliesTo' | 'eligibility' | 'usageLimit' | 'validSince' | 'validTill' | 'status'>
  & { promoFacilityRules: Array<(
    { __typename?: 'PromoFacilityRule' }
    & Pick<PromoFacilityRule, 'id' | 'facilityId' | 'isAllKitchens'>
    & { kitchens: Array<(
      { __typename?: 'Kitchen' }
      & Pick<Kitchen, 'id' | 'name' | 'facilityId'>
    )> }
  )> }
);

export type PromoDetailFragment = (
  { __typename?: 'Promo' }
  & Pick<Promo, 'id' | 'code' | 'isAllKitchensAllFacilities' | 'method' | 'orderCount' | 'type' | 'typeDefinition' | 'requirements' | 'requirementsDefinition' | 'appliesTo' | 'appliesToDefinition' | 'eligibility' | 'eligibilityDefinition' | 'usageLimit' | 'usageLimitDefinition' | 'validSince' | 'validTill' | 'status'>
);

export type PromoBannerFragment = (
  { __typename?: 'PromoBanner' }
  & Pick<PromoBanner, 'id' | 'title' | 'color' | 'textColor' | 'description' | 'active'>
);

export type UserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'name' | 'email' | 'roles'>
);

export type KitchenMenuItemInfoFragment = (
  { __typename?: 'KitchenMenuItem' }
  & Pick<KitchenMenuItem, 'id' | 'name' | 'description' | 'longDescription' | 'price' | 'labels' | 'status' | 'sortId' | 'globalSortId' | 'imgUrl' | 'inventoryCount' | 'cookTime' | 'inventoryStatus' | 'enableInventoryCount' | 'inventoryPreSale' | 'availableFrom' | 'availableTo' | 'availability' | 'dishTypes' | 'isRetail' | 'allergies' | 'sortKitchenIds' | 'type' | 'stationId'>
  & { upsellMenuItems: Array<(
    { __typename?: 'UpsellMenuItem' }
    & Pick<UpsellMenuItem, 'upsellItemId' | 'id'>
    & { upsellItem: (
      { __typename?: 'KitchenMenuItem' }
      & SearchMenuItemInfoFragment
    ) }
  )>, extras: Array<(
    { __typename?: 'Extra' }
    & Pick<Extra, 'id' | 'name'>
  )> }
);

export type KitchenShortInfoFragment = (
  { __typename?: 'Kitchen' }
  & Pick<Kitchen, 'id' | 'name' | 'type' | 'status' | 'cookTime' | 'operationHours' | 'color' | 'facilityId'>
);

export type KitchenInfoFragment = (
  { __typename?: 'Kitchen' }
  & Pick<Kitchen, 'id' | 'status' | 'name' | 'originalLocation' | 'phone' | 'email' | 'story' | 'logoUrl' | 'brandWebUrl' | 'headerImgUrl' | 'description' | 'cuisine' | 'type' | 'cookTime' | 'enableChowly'>
);

export type KitchenListInfoFragment = (
  { __typename?: 'Kitchen' }
  & Pick<Kitchen, 'id' | 'status' | 'name' | 'logoUrl' | 'cookTime' | 'menuItemCount' | 'sortId' | 'facilityId'>
);

export type ModifierInfoFragment = (
  { __typename?: 'Extra' }
  & Pick<Extra, 'id' | 'name' | 'minimum' | 'maximum' | 'sortId' | 'defaultItemId'>
);

export type OrderRefundItemFragment = (
  { __typename?: 'OrderItem' }
  & Pick<OrderItem, 'id' | 'price' | 'name' | 'type' | 'quantity'>
  & { menuItem: (
    { __typename?: 'KitchenMenuItem' }
    & { kitchen: (
      { __typename?: 'Kitchen' }
      & Pick<Kitchen, 'id' | 'name'>
    ) }
    & KitchenMenuItemShortInfoFragment
  ), extraItems: Array<(
    { __typename?: 'OrderItemExtra' }
    & Pick<OrderItemExtra, 'id' | 'price' | 'quantity' | 'name' | 'extraSortId' | 'extraItemSortId'>
  )>, mealPackOrderItems: Array<(
    { __typename?: 'OrderItem' }
    & Pick<OrderItem, 'id' | 'price' | 'quantity' | 'name' | 'type'>
  )> }
);

export type OrderRefundFragment = (
  { __typename?: 'OrderRefund' }
  & Pick<OrderRefund, 'createdAt' | 'type' | 'reason'>
  & { items: Array<(
    { __typename?: 'RefundItem' }
    & Pick<RefundItem, 'id' | 'quantity'>
    & { orderItem: (
      { __typename?: 'OrderItem' }
      & OrderRefundItemFragment
    ) }
  )>, calculations: (
    { __typename?: 'OrderRefundCalculations' }
    & Pick<OrderRefundCalculations, 'creditCardRefund' | 'giftCardRefund' | 'total' | 'subtotal' | 'tax' | 'gratuity' | 'deliveryFee' | 'serviceFee'>
  ) }
);

export type OrderRefundPreviewItemFragment = (
  { __typename?: 'OrderRefundPreviewItem' }
  & Pick<OrderRefundPreviewItem, 'name' | 'orderItemId' | 'quantity' | 'price'>
);

export type OrderRefundPreviewFragment = (
  { __typename?: 'OrderRefundPreview' }
  & Pick<OrderRefundPreview, 'createdAt' | 'type' | 'reason'>
  & { items: Array<(
    { __typename?: 'OrderRefundPreviewItem' }
    & OrderRefundPreviewItemFragment
  )>, calculations: (
    { __typename?: 'OrderRefundCalculations' }
    & Pick<OrderRefundCalculations, 'creditCardRefund' | 'giftCardRefund' | 'total' | 'subtotal' | 'tax' | 'gratuity' | 'deliveryFee' | 'serviceFee'>
  ) }
);

export type UpdateKitchenOperationHoursMutationVariables = Exact<{
  input: OperationHoursUpdateInput;
}>;


export type UpdateKitchenOperationHoursMutation = (
  { __typename?: 'Mutation' }
  & { adminUpdateKitchenOperationHours: (
    { __typename?: 'Kitchen' }
    & Pick<Kitchen, 'id' | 'operationHours'>
  ) }
);

export type CreateKitchenMenuItemMutationVariables = Exact<{
  data: KitchenMenuItemInput;
}>;


export type CreateKitchenMenuItemMutation = (
  { __typename?: 'Mutation' }
  & { adminCreateOneKitchenMenuItem: (
    { __typename?: 'KitchenMenuItem' }
    & { extras: Array<(
      { __typename?: 'Extra' }
      & Pick<Extra, 'id' | 'name'>
    )>, kitchen: (
      { __typename?: 'Kitchen' }
      & Pick<Kitchen, 'id'>
    ) }
    & KitchenMenuItemInfoFragment
  ) }
);

export type UpdateKitchenMenuItemMutationVariables = Exact<{
  data: KitchenMenuItemInput;
  id: Scalars['String'];
}>;


export type UpdateKitchenMenuItemMutation = (
  { __typename?: 'Mutation' }
  & { adminUpdateOneKitchenMenuItem: (
    { __typename?: 'KitchenMenuItem' }
    & { kitchen: (
      { __typename?: 'Kitchen' }
      & Pick<Kitchen, 'id' | 'name'>
    ), extras: Array<(
      { __typename?: 'Extra' }
      & Pick<Extra, 'id' | 'name'>
    )>, mealPackItems: Array<(
      { __typename?: 'KitchenMenuItem' }
      & { kitchen: (
        { __typename?: 'Kitchen' }
        & Pick<Kitchen, 'id' | 'name'>
      ) }
      & KitchenMenuItemShortInfoFragment
    )>, menuCategories: Array<(
      { __typename?: 'MenuCategory' }
      & MenuCategoryInfoFragment
    )> }
    & KitchenMenuItemInfoFragment
  ) }
);

export type UpdateKitchenMenuItemStatusMutationVariables = Exact<{
  id: Scalars['ID'];
  status: KitchenMenuItemStatus;
}>;


export type UpdateKitchenMenuItemStatusMutation = (
  { __typename?: 'Mutation' }
  & { adminUpdateKitchenMenuItemStatus: (
    { __typename?: 'KitchenMenuItem' }
    & Pick<KitchenMenuItem, 'id' | 'status' | 'mealPackStatus'>
  ) }
);

export type UpdateKitchenMenuItemInventoryMutationVariables = Exact<{
  id: Scalars['ID'];
  inventoryCount: Scalars['Int'];
}>;


export type UpdateKitchenMenuItemInventoryMutation = (
  { __typename?: 'Mutation' }
  & { adminUpdateKitchenMenuItemInventory: (
    { __typename?: 'KitchenMenuItem' }
    & Pick<KitchenMenuItem, 'id' | 'inventoryCount' | 'status'>
  ) }
);

export type DeleteKitchenMenuItemMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteKitchenMenuItemMutation = (
  { __typename?: 'Mutation' }
  & { adminDeleteOneKitchenMenuItem: (
    { __typename?: 'KitchenMenuItem' }
    & Pick<KitchenMenuItem, 'id' | 'status'>
  ) }
);

export type UpsertKitchenMutationVariables = Exact<{
  input: KitchenUpsertInput;
}>;


export type UpsertKitchenMutation = (
  { __typename?: 'Mutation' }
  & { adminUpsertKitchen: (
    { __typename?: 'Kitchen' }
    & KitchenInfoFragment
  ) }
);

export type DeleteKitchenMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteKitchenMutation = (
  { __typename?: 'Mutation' }
  & { adminDeleteKitchen: (
    { __typename?: 'Kitchen' }
    & Pick<Kitchen, 'id'>
  ) }
);

export type SortKitchenItemsMutationVariables = Exact<{
  input: Array<Scalars['String']> | Scalars['String'];
}>;


export type SortKitchenItemsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'adminUpdateSort'>
);

export type SortCraveMenuItemsMutationVariables = Exact<{
  input: Array<Scalars['String']> | Scalars['String'];
}>;


export type SortCraveMenuItemsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'adminUpdateGlobalSort'>
);

export type UpsertModifierMutationVariables = Exact<{
  input: ExtraUpsertInput;
}>;


export type UpsertModifierMutation = (
  { __typename?: 'Mutation' }
  & { adminUpsertModifier: (
    { __typename?: 'Extra' }
    & ModifierInfoFragment
  ) }
);

export type SortModifierGroupsMutationVariables = Exact<{
  input: Array<Scalars['String']> | Scalars['String'];
}>;


export type SortModifierGroupsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'adminSortModifierGroups'>
);

export type UpdateDefaultChoiceMutationVariables = Exact<{
  id: Scalars['ID'];
  defaultItemId: Scalars['String'];
}>;


export type UpdateDefaultChoiceMutation = (
  { __typename?: 'Mutation' }
  & { adminUpdateModifierDefaultChoice: (
    { __typename?: 'Extra' }
    & ModifierInfoFragment
  ) }
);

export type SortModifierChoicesMutationVariables = Exact<{
  input: Array<Scalars['String']> | Scalars['String'];
}>;


export type SortModifierChoicesMutation = (
  { __typename?: 'Mutation' }
  & { adminSortModifierChoices: Array<(
    { __typename?: 'ExtraItem' }
    & Pick<ExtraItem, 'id' | 'name' | 'sortId' | 'createdAt' | 'isActive' | 'price'>
  )> }
);

export type UpdateChoiceStatusMutationVariables = Exact<{
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
}>;


export type UpdateChoiceStatusMutation = (
  { __typename?: 'Mutation' }
  & { adminUpdateChoiceStatus: (
    { __typename?: 'ExtraItem' }
    & Pick<ExtraItem, 'id' | 'isActive'>
  ) }
);

export type DeleteModifierGroupMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteModifierGroupMutation = (
  { __typename?: 'Mutation' }
  & { adminDeleteModifierGroup: (
    { __typename?: 'Extra' }
    & Pick<Extra, 'id'>
  ) }
);

export type DeleteModifierChoiceMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteModifierChoiceMutation = (
  { __typename?: 'Mutation' }
  & { adminDeleteModifierChoice: (
    { __typename?: 'ExtraItem' }
    & Pick<ExtraItem, 'id'>
  ) }
);

export type MenuCategoryCreateMutationVariables = Exact<{
  input: MenuCategoryCreateInput;
}>;


export type MenuCategoryCreateMutation = (
  { __typename?: 'Mutation' }
  & { adminCreateMenuCategory: (
    { __typename?: 'MenuCategory' }
    & MenuCategoryInfoFragment
  ) }
);

export type ReorderBrandsMutationVariables = Exact<{
  input: AdminReorderKitchenInput;
}>;


export type ReorderBrandsMutation = (
  { __typename?: 'Mutation' }
  & { adminReorderKitchen?: Maybe<(
    { __typename?: 'Kitchen' }
    & Pick<Kitchen, 'id' | 'sortId'>
  )> }
);

export type OrderRefundCreateMutationVariables = Exact<{
  request: RefundInput;
}>;


export type OrderRefundCreateMutation = (
  { __typename?: 'Mutation' }
  & { orderRefundCreate: (
    { __typename?: 'OrderRefund' }
    & OrderRefundFragment
  ) }
);

export type KitchenMenuItemQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type KitchenMenuItemQuery = (
  { __typename?: 'Query' }
  & { adminKitchenMenuItem: (
    { __typename?: 'KitchenMenuItem' }
    & { station?: Maybe<(
      { __typename?: 'Station' }
      & Pick<Station, 'id' | 'name'>
    )>, menuCategories: Array<(
      { __typename?: 'MenuCategory' }
      & MenuCategoryInfoFragment
    )>, extras: Array<(
      { __typename?: 'Extra' }
      & Pick<Extra, 'id' | 'name'>
    )> }
    & KitchenMenuItemInfoFragment
  ) }
);

export type KitchensQueryVariables = Exact<{ [key: string]: never; }>;


export type KitchensQuery = (
  { __typename?: 'Query' }
  & { adminKitchens: Array<(
    { __typename?: 'Kitchen' }
    & { kitchenMenuItems: Array<(
      { __typename?: 'KitchenMenuItem' }
      & { menuCategories: Array<(
        { __typename?: 'MenuCategory' }
        & Pick<MenuCategory, 'id' | 'name' | 'categoryType'>
      )> }
      & KitchenMenuItemInfoFragment
    )> }
    & KitchenInfoFragment
  )> }
);

export type KitchenListQueryVariables = Exact<{ [key: string]: never; }>;


export type KitchenListQuery = (
  { __typename?: 'Query' }
  & { adminKitchens: Array<(
    { __typename?: 'Kitchen' }
    & KitchenListInfoFragment
  )> }
);

export type FacilityListQueryVariables = Exact<{ [key: string]: never; }>;


export type FacilityListQuery = (
  { __typename?: 'Query' }
  & { adminFacilities: Array<(
    { __typename?: 'Facility' }
    & Pick<Facility, 'id' | 'name'>
  )> }
);

export type KitchensShortQueryVariables = Exact<{
  activeKitchens?: Maybe<Scalars['Boolean']>;
}>;


export type KitchensShortQuery = (
  { __typename?: 'Query' }
  & { adminKitchens: Array<(
    { __typename?: 'Kitchen' }
    & KitchenShortInfoFragment
  )> }
);

export type KitchenOptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type KitchenOptionsQuery = (
  { __typename?: 'Query' }
  & { adminKitchens: Array<(
    { __typename?: 'Kitchen' }
    & Pick<Kitchen, 'id' | 'name' | 'status' | 'operationHours'>
  )> }
);

export type KitchenQueryVariables = Exact<{
  kitchenId: Scalars['ID'];
}>;


export type KitchenQuery = (
  { __typename?: 'Query' }
  & { adminKitchen: (
    { __typename?: 'Kitchen' }
    & { kitchenMenuItems: Array<(
      { __typename?: 'KitchenMenuItem' }
      & { menuCategories: Array<(
        { __typename?: 'MenuCategory' }
        & Pick<MenuCategory, 'id' | 'name' | 'categoryType'>
      )> }
      & KitchenMenuItemInfoFragment
    )>, workplace?: Maybe<(
      { __typename?: 'Workplace' }
      & Pick<Workplace, 'id'>
    )>, owners: Array<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name'>
    )> }
    & KitchenInfoFragment
  ) }
);

export type KitchenShortQueryVariables = Exact<{
  kitchenId: Scalars['ID'];
}>;


export type KitchenShortQuery = (
  { __typename?: 'Query' }
  & { adminKitchen: (
    { __typename?: 'Kitchen' }
    & KitchenShortInfoFragment
  ) }
);

export type AllKitchensQueryVariables = Exact<{ [key: string]: never; }>;


export type AllKitchensQuery = (
  { __typename?: 'Query' }
  & { adminAllKitchens: Array<(
    { __typename?: 'Kitchen' }
    & KitchenShortInfoFragment
  )> }
);

export type KitchenModifiersQueryVariables = Exact<{
  kitchenId: Scalars['ID'];
}>;


export type KitchenModifiersQuery = (
  { __typename?: 'Query' }
  & { adminExtras: Array<(
    { __typename?: 'Extra' }
    & Pick<Extra, 'id' | 'name' | 'required' | 'singleOption' | 'minimum' | 'maximum' | 'sortId' | 'defaultItemId' | 'createdAt'>
    & { kitchenMenuItems: Array<(
      { __typename?: 'KitchenMenuItem' }
      & Pick<KitchenMenuItem, 'id' | 'name' | 'status'>
    )>, items: Array<(
      { __typename?: 'ExtraItem' }
      & Pick<ExtraItem, 'id' | 'name' | 'price' | 'isActive' | 'sortId' | 'createdAt'>
    )> }
  )> }
);

export type KitchenModifierQueryVariables = Exact<{
  modifierId: Scalars['ID'];
}>;


export type KitchenModifierQuery = (
  { __typename?: 'Query' }
  & { adminKitchenModifier: (
    { __typename?: 'Extra' }
    & Pick<Extra, 'id' | 'name' | 'required' | 'singleOption' | 'minimum' | 'maximum' | 'sortId' | 'defaultItemId' | 'createdAt'>
    & { kitchenMenuItems: Array<(
      { __typename?: 'KitchenMenuItem' }
      & Pick<KitchenMenuItem, 'id' | 'name' | 'status'>
    )>, items: Array<(
      { __typename?: 'ExtraItem' }
      & Pick<ExtraItem, 'id' | 'name' | 'price' | 'isActive' | 'sortId' | 'createdAt'>
    )> }
  ) }
);

export type OrderRefundPreviewQueryVariables = Exact<{
  request: RefundInput;
}>;


export type OrderRefundPreviewQuery = (
  { __typename?: 'Query' }
  & { orderRefundPreview: (
    { __typename?: 'OrderRefundPreview' }
    & OrderRefundPreviewFragment
  ) }
);

export type OrderItemsRefundableQueryVariables = Exact<{
  orderId: Scalars['ID'];
  ticketId?: Maybe<Scalars['ID']>;
}>;


export type OrderItemsRefundableQuery = (
  { __typename?: 'Query' }
  & { orderItemsRefundable: Array<(
    { __typename?: 'OrderItem' }
    & RefundableItemsFragment
  )> }
);

export type MenuScheduleCategoryFragment = (
  { __typename?: 'MenuScheduleCategory' }
  & Pick<MenuScheduleCategory, 'id' | 'sortedMenuItemIds'>
  & { category: (
    { __typename?: 'MenuCategory' }
    & Pick<MenuCategory, 'id' | 'name' | 'categoryType'>
  ), menuItems: Array<(
    { __typename?: 'KitchenMenuItem' }
    & Pick<KitchenMenuItem, 'id' | 'name' | 'availability' | 'status' | 'inventoryStatus' | 'enableInventoryCount'>
  )> }
);

export type MenuScheduleItemInfoFragment = (
  { __typename?: 'MenuSchedule' }
  & Pick<MenuSchedule, 'id' | 'name' | 'start' | 'end' | 'isActive' | 'isNonStop' | 'repeatOnDays' | 'sortedCategoryIds'>
);

export type MenuScheduleKitchenInfoFragment = (
  { __typename?: 'Kitchen' }
  & Pick<Kitchen, 'id' | 'name' | 'color' | 'status' | 'operationHours' | 'enableChowly'>
);

export type ScheduleCategoryInfoFragment = (
  { __typename?: 'MenuScheduleCategory' }
  & Pick<MenuScheduleCategory, 'id' | 'sortedMenuItemIds'>
);

export type ScheduleCategoryMenuItemsFragment = (
  { __typename?: 'KitchenMenuItem' }
  & Pick<KitchenMenuItem, 'id' | 'name' | 'availability' | 'price' | 'status'>
);

export type OrderMethodOverrideInfoFragment = (
  { __typename?: 'OrderMethodOverride' }
  & Pick<OrderMethodOverride, 'id' | 'name' | 'start' | 'end' | 'repeatOnDays' | 'disabledOrderMethods' | 'isActive' | 'isNonStop'>
);

export type MenuScheduleSpecialInfoFragment = (
  { __typename?: 'MenuSchedule' }
  & Pick<MenuSchedule, 'id' | 'name' | 'start' | 'end' | 'repeatOnDays' | 'isActive' | 'isNonStop' | 'sortedCategoryIds'>
);

export type UpsertMenuScheduleMutationVariables = Exact<{
  input: MenuScheduleUpsertInput;
}>;


export type UpsertMenuScheduleMutation = (
  { __typename?: 'Mutation' }
  & { adminUpsertMenuSchedule: (
    { __typename?: 'MenuSchedule' }
    & { kitchen?: Maybe<(
      { __typename?: 'Kitchen' }
      & MenuScheduleKitchenInfoFragment
    )>, scheduleCategories: Array<(
      { __typename?: 'MenuScheduleCategory' }
      & { category: (
        { __typename?: 'MenuCategory' }
        & Pick<MenuCategory, 'id' | 'name'>
      ), menuItems: Array<(
        { __typename?: 'KitchenMenuItem' }
        & { menuCategories: Array<(
          { __typename?: 'MenuCategory' }
          & Pick<MenuCategory, 'id' | 'name'>
        )> }
        & ScheduleCategoryMenuItemsFragment
      )> }
      & ScheduleCategoryInfoFragment
    )> }
    & MenuScheduleItemInfoFragment
  ) }
);

export type DeleteMenuScheduleMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteMenuScheduleMutation = (
  { __typename?: 'Mutation' }
  & { adminDeleteMenuSchedule: (
    { __typename?: 'MenuSchedule' }
    & Pick<MenuSchedule, 'id'>
  ) }
);

export type UpdateMenuScheduleStatusMutationVariables = Exact<{
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
}>;


export type UpdateMenuScheduleStatusMutation = (
  { __typename?: 'Mutation' }
  & { adminUpdateMenuScheduleStatus: (
    { __typename?: 'MenuSchedule' }
    & Pick<MenuSchedule, 'id' | 'isActive'>
  ) }
);

export type UpsertOrderMethodOverrideMutationVariables = Exact<{
  input: OrderMethodOverrideUpsertInput;
}>;


export type UpsertOrderMethodOverrideMutation = (
  { __typename?: 'Mutation' }
  & { adminUpsertOrderMethodOverride: (
    { __typename?: 'OrderMethodOverride' }
    & OrderMethodOverrideInfoFragment
  ) }
);

export type AdminDeleteOrderMethodOverrideMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type AdminDeleteOrderMethodOverrideMutation = (
  { __typename?: 'Mutation' }
  & { adminDeleteOrderMethodOverride: (
    { __typename?: 'OrderMethodOverride' }
    & Pick<OrderMethodOverride, 'id'>
  ) }
);

export type UpdateOrderMethodOverrideStatusMutationVariables = Exact<{
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
}>;


export type UpdateOrderMethodOverrideStatusMutation = (
  { __typename?: 'Mutation' }
  & { adminUpdateOrderMethodOverrideStatus: (
    { __typename?: 'OrderMethodOverride' }
    & Pick<OrderMethodOverride, 'id'>
  ) }
);

export type UpsertSpecialMenuScheduleMutationVariables = Exact<{
  input: SpecialMenuScheduleUpsertInput;
}>;


export type UpsertSpecialMenuScheduleMutation = (
  { __typename?: 'Mutation' }
  & { adminUpsertSpecialMenuSchedule: (
    { __typename?: 'MenuSchedule' }
    & { kitchen?: Maybe<(
      { __typename?: 'Kitchen' }
      & MenuScheduleKitchenInfoFragment
    )>, scheduleCategories: Array<(
      { __typename?: 'MenuScheduleCategory' }
      & { category: (
        { __typename?: 'MenuCategory' }
        & Pick<MenuCategory, 'id' | 'name'>
      ), menuItems: Array<(
        { __typename?: 'KitchenMenuItem' }
        & { menuCategories: Array<(
          { __typename?: 'MenuCategory' }
          & Pick<MenuCategory, 'id' | 'name'>
        )> }
        & ScheduleCategoryMenuItemsFragment
      )> }
      & ScheduleCategoryInfoFragment
    )> }
    & MenuScheduleItemInfoFragment
  ) }
);

export type UpdateKitchenColorMutationVariables = Exact<{
  id: Scalars['ID'];
  color: Scalars['String'];
}>;


export type UpdateKitchenColorMutation = (
  { __typename?: 'Mutation' }
  & { adminUpdateKitchenColor: (
    { __typename?: 'Kitchen' }
    & MenuScheduleKitchenInfoFragment
  ) }
);

export type AdminAllMenuSchedulesQueryVariables = Exact<{
  kitchenIds: Array<Scalars['ID']> | Scalars['ID'];
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
}>;


export type AdminAllMenuSchedulesQuery = (
  { __typename?: 'Query' }
  & { adminAllMenuSchedules: Array<(
    { __typename?: 'MenuSchedule' }
    & Pick<MenuSchedule, 'id' | 'start' | 'end' | 'isActive' | 'repeatOnDays' | 'sortedCategoryIds'>
    & { kitchen?: Maybe<(
      { __typename?: 'Kitchen' }
      & MenuScheduleKitchenInfoFragment
    )>, scheduleCategories: Array<(
      { __typename?: 'MenuScheduleCategory' }
      & MenuScheduleCategoryFragment
    )> }
  )> }
);

export type AdminAllMenuSchedulesOverviewQueryVariables = Exact<{
  kitchenIds: Array<Scalars['ID']> | Scalars['ID'];
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
}>;


export type AdminAllMenuSchedulesOverviewQuery = (
  { __typename?: 'Query' }
  & { adminAllMenuSchedules: Array<(
    { __typename?: 'MenuSchedule' }
    & { kitchen?: Maybe<(
      { __typename?: 'Kitchen' }
      & MenuScheduleKitchenInfoFragment
    )>, scheduleCategories: Array<(
      { __typename?: 'MenuScheduleCategory' }
      & MenuScheduleCategoryFragment
    )> }
    & MenuScheduleItemInfoFragment
  )> }
);

export type AdminMenuSchedulesQueryVariables = Exact<{
  kitchenId: Scalars['ID'];
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
}>;


export type AdminMenuSchedulesQuery = (
  { __typename?: 'Query' }
  & { adminMenuSchedules: Array<(
    { __typename?: 'MenuSchedule' }
    & { kitchen?: Maybe<(
      { __typename?: 'Kitchen' }
      & MenuScheduleKitchenInfoFragment
    )>, scheduleCategories: Array<(
      { __typename?: 'MenuScheduleCategory' }
      & MenuScheduleCategoryFragment
    )> }
    & MenuScheduleItemInfoFragment
  )> }
);

export type AdminMenuScheduleQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type AdminMenuScheduleQuery = (
  { __typename?: 'Query' }
  & { adminMenuSchedule?: Maybe<(
    { __typename?: 'MenuSchedule' }
    & { kitchen?: Maybe<(
      { __typename?: 'Kitchen' }
      & MenuScheduleKitchenInfoFragment
    )> }
    & MenuScheduleItemInfoFragment
  )> }
);

export type AdminMenuScheduleCategoriesQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type AdminMenuScheduleCategoriesQuery = (
  { __typename?: 'Query' }
  & { adminMenuSchedule?: Maybe<(
    { __typename?: 'MenuSchedule' }
    & Pick<MenuSchedule, 'id'>
    & { scheduleCategories: Array<(
      { __typename?: 'MenuScheduleCategory' }
      & { category: (
        { __typename?: 'MenuCategory' }
        & Pick<MenuCategory, 'id' | 'name' | 'categoryType'>
      ), menuItems: Array<(
        { __typename?: 'KitchenMenuItem' }
        & ScheduleCategoryMenuItemsFragment
      )> }
      & ScheduleCategoryInfoFragment
    )> }
  )> }
);

export type OrderMethodOverrideQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type OrderMethodOverrideQuery = (
  { __typename?: 'Query' }
  & { adminOrderMethodOverride?: Maybe<(
    { __typename?: 'OrderMethodOverride' }
    & OrderMethodOverrideInfoFragment
  )> }
);

export type OrderMethodOverridesQueryVariables = Exact<{
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
}>;


export type OrderMethodOverridesQuery = (
  { __typename?: 'Query' }
  & { adminOrderMethodOverrides: Array<(
    { __typename?: 'OrderMethodOverride' }
    & OrderMethodOverrideInfoFragment
  )> }
);

export type AllSpecialMenuItemsQueryVariables = Exact<{ [key: string]: never; }>;


export type AllSpecialMenuItemsQuery = (
  { __typename?: 'Query' }
  & { adminKitchenMenuItems: Array<(
    { __typename?: 'KitchenMenuItem' }
    & Pick<KitchenMenuItem, 'status'>
    & { menuCategories: Array<(
      { __typename?: 'MenuCategory' }
      & Pick<MenuCategory, 'id' | 'name'>
    )>, kitchen: (
      { __typename?: 'Kitchen' }
      & Pick<Kitchen, 'id' | 'name' | 'status'>
    ) }
    & KitchenMenuItemInfoFragment
  )> }
);

export type MenuSchedulesSpecialsQueryVariables = Exact<{
  end: Scalars['DateTime'];
  start: Scalars['DateTime'];
}>;


export type MenuSchedulesSpecialsQuery = (
  { __typename?: 'Query' }
  & { adminSpecialMenuSchedules: Array<(
    { __typename?: 'MenuSchedule' }
    & { scheduleCategories: Array<(
      { __typename?: 'MenuScheduleCategory' }
      & { menuItems: Array<(
        { __typename?: 'KitchenMenuItem' }
        & { menuCategories: Array<(
          { __typename?: 'MenuCategory' }
          & Pick<MenuCategory, 'id' | 'name'>
        )> }
        & ScheduleCategoryMenuItemsFragment
      )> }
      & MenuScheduleCategoryFragment
    )> }
    & MenuScheduleSpecialInfoFragment
  )> }
);

export type UpdateFacilityOperationHoursMutationVariables = Exact<{
  input: OperationHoursUpdateInput;
}>;


export type UpdateFacilityOperationHoursMutation = (
  { __typename?: 'Mutation' }
  & { adminUpdateFacilityOperationHours: (
    { __typename?: 'Facility' }
    & Pick<Facility, 'id' | 'operationHours'>
  ) }
);

export type UpdateFacilityKioskLogoMutationVariables = Exact<{
  id: Scalars['ID'];
  kioskLogoUrl?: Maybe<Scalars['String']>;
}>;


export type UpdateFacilityKioskLogoMutation = (
  { __typename?: 'Mutation' }
  & { adminUpdateFacilityKioskLogo: (
    { __typename?: 'Facility' }
    & Pick<Facility, 'id' | 'kioskLogoUrl'>
  ) }
);

export type UpdateFacilityLogoMutationVariables = Exact<{
  id: Scalars['ID'];
  logoUrl?: Maybe<Scalars['String']>;
}>;


export type UpdateFacilityLogoMutation = (
  { __typename?: 'Mutation' }
  & { adminUpdateFacilityLogo: (
    { __typename?: 'Facility' }
    & Pick<Facility, 'id' | 'logoUrl'>
  ) }
);

export type UpdateFacilityHeroImageMutationVariables = Exact<{
  id: Scalars['ID'];
  heroImageUrl?: Maybe<Scalars['String']>;
}>;


export type UpdateFacilityHeroImageMutation = (
  { __typename?: 'Mutation' }
  & { adminUpdateFacilityHeroImage: (
    { __typename?: 'Facility' }
    & Pick<Facility, 'id' | 'heroImageUrl'>
  ) }
);

export type UpdateFacilityContactMutationVariables = Exact<{
  id: Scalars['ID'];
  phone: Scalars['String'];
  email: Scalars['String'];
}>;


export type UpdateFacilityContactMutation = (
  { __typename?: 'Mutation' }
  & { adminUpdateFacilityContact: (
    { __typename?: 'Facility' }
    & Pick<Facility, 'id' | 'phone' | 'email'>
  ) }
);

export type UpdateFacilityMutationVariables = Exact<{
  input: FacilityUpdateInput;
}>;


export type UpdateFacilityMutation = (
  { __typename?: 'Mutation' }
  & { adminUpdateFacility: (
    { __typename?: 'Facility' }
    & FacilityFragment
  ) }
);

export type UpdateOrderStatusMutationVariables = Exact<{
  input: OrderStatusInput;
}>;


export type UpdateOrderStatusMutation = (
  { __typename?: 'Mutation' }
  & { adminUpdateOrderStatus: (
    { __typename?: 'Order' }
    & Pick<Order, 'id' | 'status'>
  ) }
);

export type UpdateDeliveryTimeMutationVariables = Exact<{
  input: UpdateDeliveryInput;
}>;


export type UpdateDeliveryTimeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'adminUpdateOrderDeliveryTime'>
);

export type UpdateOrderPreparingUntilMutationVariables = Exact<{
  input: UpdateOrderPreparingUntilInput;
}>;


export type UpdateOrderPreparingUntilMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'adminUpdateOrderPreparingUntil'>
);

export type CreateOrderBundlesMutationVariables = Exact<{
  date?: Maybe<Scalars['DateTime']>;
}>;


export type CreateOrderBundlesMutation = (
  { __typename?: 'Mutation' }
  & { createOrderBundles: Array<(
    { __typename?: 'OrderBundle' }
    & OrderBundleInfoFragment
  )> }
);

export type CreateOrderBundleManuallyMutationVariables = Exact<{
  orderId: Scalars['ID'];
}>;


export type CreateOrderBundleManuallyMutation = (
  { __typename?: 'Mutation' }
  & { adminCreateOrderBundleManually: (
    { __typename?: 'OrderBundle' }
    & OrderBundleInfoFragment
  ) }
);

export type UpdateOrderPaidAtMutationVariables = Exact<{
  orderId: Scalars['ID'];
  isPaid: Scalars['Boolean'];
}>;


export type UpdateOrderPaidAtMutation = (
  { __typename?: 'Mutation' }
  & { adminUpdateOrderPaidAt: (
    { __typename?: 'Order' }
    & Pick<Order, 'payedAt'>
    & { items: Array<(
      { __typename?: 'OrderItem' }
      & OrderItemInfoFragment
    )> }
    & OrderInfoFragment
  ) }
);

export type UpdateUserInfoMutationVariables = Exact<{
  input: AdminUserUpdateInput;
}>;


export type UpdateUserInfoMutation = (
  { __typename?: 'Mutation' }
  & { adminUpdateUser: (
    { __typename?: 'User' }
    & { customerInfo?: Maybe<(
      { __typename?: 'UserCustomerInfo' }
      & Pick<UserCustomerInfo, 'id' | 'vip' | 'isEmployee'>
    )> }
    & UserInfoFragment
  ) }
);

export type UpdateUserTagsMutationVariables = Exact<{
  customerId: Scalars['ID'];
  tag: TagCreateOrConnectInput;
}>;


export type UpdateUserTagsMutation = (
  { __typename?: 'Mutation' }
  & { adminAddCustomerTag: (
    { __typename?: 'UserCustomerInfo' }
    & Pick<UserCustomerInfo, 'id'>
    & { tags: Array<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'name'>
    )> }
  ) }
);

export type RemoveCustomerTagMutationVariables = Exact<{
  customerId: Scalars['ID'];
  tagId: Scalars['ID'];
}>;


export type RemoveCustomerTagMutation = (
  { __typename?: 'Mutation' }
  & { adminRemoveCustomerTag: (
    { __typename?: 'UserCustomerInfo' }
    & Pick<UserCustomerInfo, 'id'>
    & { tags: Array<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'name'>
    )> }
  ) }
);

export type ResetPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'customerResetPassword'>
);

export type UpdateAddressMutationVariables = Exact<{
  input: AdminAddressUpdateInput;
}>;


export type UpdateAddressMutation = (
  { __typename?: 'Mutation' }
  & { adminUpdateUserAddress: (
    { __typename?: 'Address' }
    & AddressInfoFragment
  ) }
);

export type UpdatePasswordMutationVariables = Exact<{
  input: PasswordUpdateInput;
}>;


export type UpdatePasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'adminUpdatePassword'>
);

export type UpdateEmailMutationVariables = Exact<{
  input: EmailUpdateInput;
}>;


export type UpdateEmailMutation = (
  { __typename?: 'Mutation' }
  & { adminUpdateEmail: (
    { __typename?: 'EmailUpdate' }
    & Pick<EmailUpdate, 'email'>
  ) }
);

export type KitchenTicketStartPreparingMutationVariables = Exact<{
  ticketId: Scalars['ID'];
}>;


export type KitchenTicketStartPreparingMutation = (
  { __typename?: 'Mutation' }
  & { kdsKitchenTicketStartPreparing: (
    { __typename?: 'KitchenTicket' }
    & KitchenTicketFragment
  ) }
);

export type KdsDelayKitchenTicketMutationVariables = Exact<{
  ticketId: Scalars['String'];
  delayedAt: Scalars['DateTime'];
  delayTime: Scalars['Int'];
  delayReason: KitchenTicketDelayReason;
}>;


export type KdsDelayKitchenTicketMutation = (
  { __typename?: 'Mutation' }
  & { adminUpdateOneKitchenTicket?: Maybe<(
    { __typename?: 'KitchenTicket' }
    & KitchenTicketFragment
  )> }
);

export type RefireOrderMutationVariables = Exact<{
  input: RefireOrderInput;
}>;


export type RefireOrderMutation = (
  { __typename?: 'Mutation' }
  & { kdsRefireOrder: (
    { __typename?: 'Order' }
    & KdsExpoOrderFragment
  ) }
);

export type RefireOrderItemMutationVariables = Exact<{
  input: RefireOrderItemInput;
}>;


export type RefireOrderItemMutation = (
  { __typename?: 'Mutation' }
  & { kdsRefireOrderItem: (
    { __typename?: 'Order' }
    & KdsExpoOrderFragment
  ) }
);

export type RefireKitchenTicketMutationVariables = Exact<{
  input: RefireKitchenTicketInput;
}>;


export type RefireKitchenTicketMutation = (
  { __typename?: 'Mutation' }
  & { kdsRefireKitchenTicket: (
    { __typename?: 'Order' }
    & KdsExpoOrderFragment
  ) }
);

export type UndoKitchenTicketMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type UndoKitchenTicketMutation = (
  { __typename?: 'Mutation' }
  & { kdsUndoTicket: (
    { __typename?: 'KitchenTicket' }
    & Pick<KitchenTicket, 'id'>
  ) }
);

export type UndoOrderMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type UndoOrderMutation = (
  { __typename?: 'Mutation' }
  & { kdsUndoOrder: (
    { __typename?: 'Order' }
    & Pick<Order, 'id'>
  ) }
);

export type SetOrderWorkplaceMutationVariables = Exact<{
  orderId: Scalars['ID'];
  index: Scalars['Int'];
}>;


export type SetOrderWorkplaceMutation = (
  { __typename?: 'Mutation' }
  & { kdsSetOrderWorkplace: (
    { __typename?: 'Order' }
    & Pick<Order, 'id'>
    & { packager?: Maybe<(
      { __typename?: 'Workplace' }
      & Pick<Workplace, 'id' | 'index'>
    )> }
  ) }
);

export type UpdatePickupOrderStationMutationVariables = Exact<{
  orderId: Scalars['ID'];
  stationIndex: Scalars['Int'];
}>;


export type UpdatePickupOrderStationMutation = (
  { __typename?: 'Mutation' }
  & { kdsSetPickupStation: (
    { __typename?: 'Order' }
    & KdsPickupOrderFragment
  ) }
);

export type CompletePickupOrderMutationVariables = Exact<{
  orderId: Scalars['ID'];
}>;


export type CompletePickupOrderMutation = (
  { __typename?: 'Mutation' }
  & { kdsOrderPickupComplete: (
    { __typename?: 'Order' }
    & KdsPickupOrderFragment
  ) }
);

export type KdsExpoCompleteKitchenTicketMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type KdsExpoCompleteKitchenTicketMutation = (
  { __typename?: 'Mutation' }
  & { kdsCompleteKitchenTicket: (
    { __typename?: 'KitchenTicket' }
    & { items: Array<(
      { __typename?: 'FacilityItem' }
      & FacilityItemInfoFragment
    )> }
    & ExpoKitchenTicketFragment
  ) }
);

export type KdsExpoKitchenTicketPrintedMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type KdsExpoKitchenTicketPrintedMutation = (
  { __typename?: 'Mutation' }
  & { kdsKitchenTicketPrinted: (
    { __typename?: 'KitchenTicket' }
    & ExpoKitchenTicketFragment
  ) }
);

export type UpsertPromoMutationVariables = Exact<{
  input: PromoUpsertInput;
}>;


export type UpsertPromoMutation = (
  { __typename?: 'Mutation' }
  & { adminUpsertPromo: (
    { __typename?: 'Promo' }
    & PromoDetailFragment
  ) }
);

export type DisablePromoMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DisablePromoMutation = (
  { __typename?: 'Mutation' }
  & { adminDisablePromo: (
    { __typename?: 'Promo' }
    & PromoDetailFragment
  ) }
);

export type DeleteOnePromoRuleMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteOnePromoRuleMutation = (
  { __typename?: 'Mutation' }
  & { adminDeleteOnePromoRule: (
    { __typename?: 'PromoFacilityRule' }
    & Pick<PromoFacilityRule, 'id'>
  ) }
);

export type UpdatePromoBannerMutationVariables = Exact<{
  data: PromoBannerUpdateInput;
  where: PromoBannerWhereUniqueInput;
}>;


export type UpdatePromoBannerMutation = (
  { __typename?: 'Mutation' }
  & { adminUpdatePromoBanner?: Maybe<(
    { __typename?: 'PromoBanner' }
    & PromoBannerFragment
  )> }
);

export type SendNotificationsMutationVariables = Exact<{
  message: Scalars['String'];
  title: Scalars['String'];
  send: Scalars['Boolean'];
}>;


export type SendNotificationsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'adminSendMessageToAll'>
);

export type ClientLogMutationVariables = Exact<{
  message: Scalars['String'];
  meta?: Maybe<Scalars['String']>;
}>;


export type ClientLogMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'clientLog'>
);

export type InviteUserMutationVariables = Exact<{
  input: UserInviteInput;
}>;


export type InviteUserMutation = (
  { __typename?: 'Mutation' }
  & { adminUserInvite?: Maybe<(
    { __typename?: 'User' }
    & UserFragment
  )> }
);

export type AddUpdateCartUpsellItemsMutationVariables = Exact<{
  data: Array<UpsellMenuItemCreateInput> | UpsellMenuItemCreateInput;
  platform: ClientPlatformInput;
}>;


export type AddUpdateCartUpsellItemsMutation = (
  { __typename?: 'Mutation' }
  & { adminUpdateCartUpsellItem: Array<(
    { __typename?: 'CartUpsellItem' }
    & Pick<CartUpsellItem, 'id' | 'sortId'>
  )> }
);

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name' | 'roles'>
    & { kitchens: Array<(
      { __typename?: 'Kitchen' }
      & Pick<Kitchen, 'id' | 'name'>
    )>, facilities: Array<(
      { __typename?: 'Facility' }
      & FacilityFragment
    )> }
  )> }
);

export type AddressSuggestionQueryVariables = Exact<{
  address: Scalars['String'];
}>;


export type AddressSuggestionQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'addressAutocompleteOptions'>
);

export type OrderMonitoringQueryVariables = Exact<{
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
}>;


export type OrderMonitoringQuery = (
  { __typename?: 'Query' }
  & { adminOrders: Array<(
    { __typename?: 'Order' }
    & OrderInfoFragment
  )> }
);

export type OrderQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type OrderQuery = (
  { __typename?: 'Query' }
  & { adminOrder: (
    { __typename?: 'Order' }
    & { items: Array<(
      { __typename?: 'OrderItem' }
      & { menuItem: (
        { __typename?: 'KitchenMenuItem' }
        & { kitchen: (
          { __typename?: 'Kitchen' }
          & Pick<Kitchen, 'id' | 'name'>
        ) }
        & KitchenMenuItemInfoFragment
      ), mealPackOrderItems: Array<(
        { __typename?: 'OrderItem' }
        & { extraItems: Array<(
          { __typename?: 'OrderItemExtra' }
          & OrderExtraItemInfoFragment
        )> }
        & OrderItemInfoFragment
      )>, extraItems: Array<(
        { __typename?: 'OrderItemExtra' }
        & OrderExtraItemInfoFragment
      )> }
      & OrderItemInfoFragment
    )>, customer: (
      { __typename?: 'UserCustomerInfo' }
      & { tags: Array<(
        { __typename?: 'Tag' }
        & TagInfoFragment
      )>, user?: Maybe<(
        { __typename?: 'User' }
        & UserInfoFragment
      )> }
      & CustomerInfoFragment
    ), refundableItems: Array<(
      { __typename?: 'OrderItem' }
      & RefundableItemsFragment
    )>, refunds: Array<(
      { __typename?: 'OrderRefund' }
      & RefundsFragment
    )> }
    & OrderInfoFragment
  ) }
);

export type OrderBundlesQueryVariables = Exact<{
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
}>;


export type OrderBundlesQuery = (
  { __typename?: 'Query' }
  & { adminOrderBundles: Array<(
    { __typename?: 'OrderBundle' }
    & { orders: Array<(
      { __typename?: 'Order' }
      & { customer: (
        { __typename?: 'UserCustomerInfo' }
        & { user?: Maybe<(
          { __typename?: 'User' }
          & UserInfoFragment
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & TagInfoFragment
        )> }
        & CustomerInfoFragment
      ) }
      & OrderInfoFragment
    )> }
    & OrderBundleInfoFragment
  )> }
);

export type UnbundledOrdersQueryVariables = Exact<{
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
}>;


export type UnbundledOrdersQuery = (
  { __typename?: 'Query' }
  & { adminOrders: Array<(
    { __typename?: 'Order' }
    & { customer: (
      { __typename?: 'UserCustomerInfo' }
      & { user?: Maybe<(
        { __typename?: 'User' }
        & UserInfoFragment
      )>, tags: Array<(
        { __typename?: 'Tag' }
        & TagInfoFragment
      )> }
      & CustomerInfoFragment
    ) }
    & OrderInfoFragment
  )> }
);

export type StationsQueryVariables = Exact<{
  where?: Maybe<StationWhereInput>;
}>;


export type StationsQuery = (
  { __typename?: 'Query' }
  & { adminStations: Array<(
    { __typename?: 'Station' }
    & Pick<Station, 'id' | 'name'>
  )> }
);

export type MenuCategoriesQueryVariables = Exact<{
  type?: Maybe<MenuCategoryType>;
}>;


export type MenuCategoriesQuery = (
  { __typename?: 'Query' }
  & { adminMenuCategories: Array<(
    { __typename?: 'MenuCategory' }
    & MenuCategoryInfoFragment
  )> }
);

export type StationWorkplaceQueryVariables = Exact<{
  stationId: Scalars['ID'];
}>;


export type StationWorkplaceQuery = (
  { __typename?: 'Query' }
  & { adminStation: (
    { __typename?: 'Station' }
    & Pick<Station, 'id' | 'name'>
  ) }
);

export type KdsStationTicketsQueryVariables = Exact<{
  stationId: Scalars['ID'];
  recentlyDoneLimit: Scalars['Int'];
}>;


export type KdsStationTicketsQuery = (
  { __typename?: 'Query' }
  & { kdsStationTickets: Array<(
    { __typename?: 'KitchenTicket' }
    & KitchenTicketFragment
  )>, kdsDoneStationTickets: Array<(
    { __typename?: 'KitchenTicket' }
    & Pick<KitchenTicket, 'id'>
    & { order: (
      { __typename?: 'Order' }
      & Pick<Order, 'id' | 'shortId' | 'platform'>
      & { refundableItems: Array<(
        { __typename?: 'OrderItem' }
        & RefundableItemsFragment
      )> }
    ) }
  )> }
);

export type FutureKdsStationTicketsQueryVariables = Exact<{
  stationId: Scalars['ID'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
}>;


export type FutureKdsStationTicketsQuery = (
  { __typename?: 'Query' }
  & { kdsStationTickets: Array<(
    { __typename?: 'KitchenTicket' }
    & KitchenTicketFragment
  )> }
);

export type MealPackItemQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type MealPackItemQuery = (
  { __typename?: 'Query' }
  & { adminKitchenMenuItem: (
    { __typename?: 'KitchenMenuItem' }
    & { kitchen: (
      { __typename?: 'Kitchen' }
      & Pick<Kitchen, 'id' | 'name'>
    ), extras: Array<(
      { __typename?: 'Extra' }
      & Pick<Extra, 'id' | 'name'>
    )>, mealPackItems: Array<(
      { __typename?: 'KitchenMenuItem' }
      & { kitchen: (
        { __typename?: 'Kitchen' }
        & Pick<Kitchen, 'id' | 'name'>
      ) }
      & KitchenMenuItemShortInfoFragment
    )>, menuCategories: Array<(
      { __typename?: 'MenuCategory' }
      & MenuCategoryInfoFragment
    )> }
    & KitchenMenuItemInfoFragment
  ) }
);

export type MenuItemSelectOptionsQueryVariables = Exact<{
  kitchenId: Scalars['ID'];
}>;


export type MenuItemSelectOptionsQuery = (
  { __typename?: 'Query' }
  & { adminExtras: Array<(
    { __typename?: 'Extra' }
    & Pick<Extra, 'id' | 'name'>
    & { items: Array<(
      { __typename?: 'ExtraItem' }
      & Pick<ExtraItem, 'id' | 'name' | 'isActive' | 'sortId' | 'createdAt'>
    )> }
  )>, adminMenuCategories: Array<(
    { __typename?: 'MenuCategory' }
    & MenuCategoryInfoFragment
  )> }
);

export type KitchenMenuItemsQueryVariables = Exact<{
  kitchenIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type KitchenMenuItemsQuery = (
  { __typename?: 'Query' }
  & { adminKitchenMenuItems: Array<(
    { __typename?: 'KitchenMenuItem' }
    & Pick<KitchenMenuItem, 'id' | 'name'>
    & { kitchen: (
      { __typename?: 'Kitchen' }
      & Pick<Kitchen, 'id' | 'name'>
    ), station?: Maybe<(
      { __typename?: 'Station' }
      & Pick<Station, 'id' | 'name'>
    )> }
    & KitchenMenuItemInfoFragment
  )> }
);

export type SearchMenuItemsQueryVariables = Exact<{
  searchTerm: Scalars['String'];
  parentUpsellItemId?: Maybe<Scalars['ID']>;
  kitchenIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  availability?: Maybe<Array<MenuItemAvailability> | MenuItemAvailability>;
}>;


export type SearchMenuItemsQuery = (
  { __typename?: 'Query' }
  & { adminKitchenMenuItems: Array<(
    { __typename?: 'KitchenMenuItem' }
    & SearchMenuItemInfoFragment
  )> }
);

export type SingleKitchenMenuItemsQueryVariables = Exact<{
  kitchenIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type SingleKitchenMenuItemsQuery = (
  { __typename?: 'Query' }
  & { adminKitchenMenuItems: Array<(
    { __typename?: 'KitchenMenuItem' }
    & { kitchen: (
      { __typename?: 'Kitchen' }
      & Pick<Kitchen, 'id' | 'name'>
    ), extras: Array<(
      { __typename?: 'Extra' }
      & Pick<Extra, 'id' | 'name'>
      & { items: Array<(
        { __typename?: 'ExtraItem' }
        & Pick<ExtraItem, 'id' | 'name'>
      )> }
    )>, mealPackItems: Array<(
      { __typename?: 'KitchenMenuItem' }
      & { kitchen: (
        { __typename?: 'Kitchen' }
        & Pick<Kitchen, 'id' | 'name'>
      ) }
      & KitchenMenuItemShortInfoFragment
    )> }
    & KitchenMenuItemInfoFragment
  )> }
);

export type AllKitchenMenuItemsQueryVariables = Exact<{
  activeItems: Scalars['Boolean'];
}>;


export type AllKitchenMenuItemsQuery = (
  { __typename?: 'Query' }
  & { adminKitchenMenuItems: Array<(
    { __typename?: 'KitchenMenuItem' }
    & Pick<KitchenMenuItem, 'mealPackStatus'>
    & { kitchen: (
      { __typename?: 'Kitchen' }
      & Pick<Kitchen, 'id' | 'name'>
    ), mealPackItems: Array<(
      { __typename?: 'KitchenMenuItem' }
      & { kitchen: (
        { __typename?: 'Kitchen' }
        & Pick<Kitchen, 'id' | 'name'>
      ) }
      & KitchenMenuItemShortInfoFragment
    )>, extras: Array<(
      { __typename?: 'Extra' }
      & Pick<Extra, 'id' | 'name'>
      & { items: Array<(
        { __typename?: 'ExtraItem' }
        & Pick<ExtraItem, 'id' | 'name'>
      )> }
    )> }
    & KitchenMenuItemInfoFragment
  )> }
);

export type WorkplacesQueryVariables = Exact<{
  where?: Maybe<WorkplaceWhereInput>;
}>;


export type WorkplacesQuery = (
  { __typename?: 'Query' }
  & { adminWorkplaces: Array<(
    { __typename?: 'Workplace' }
    & Pick<Workplace, 'id' | 'type' | 'index'>
  )> }
);

export type KitchenWorkplaceQueryVariables = Exact<{
  kitchenId: Scalars['ID'];
}>;


export type KitchenWorkplaceQuery = (
  { __typename?: 'Query' }
  & { adminKitchen: (
    { __typename?: 'Kitchen' }
    & KitchenShortInfoFragment
  ) }
);

export type RestaurantOwnersQueryVariables = Exact<{ [key: string]: never; }>;


export type RestaurantOwnersQuery = (
  { __typename?: 'Query' }
  & { adminRestaurantOwners: Array<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name'>
  )> }
);

export type MealPackListQueryVariables = Exact<{ [key: string]: never; }>;


export type MealPackListQuery = (
  { __typename?: 'Query' }
  & { adminKitchens: Array<(
    { __typename?: 'Kitchen' }
    & Pick<Kitchen, 'id' | 'type'>
    & { kitchenMenuItems: Array<(
      { __typename?: 'KitchenMenuItem' }
      & Pick<KitchenMenuItem, 'mealPackStatus'>
      & { extras: Array<(
        { __typename?: 'Extra' }
        & Pick<Extra, 'id' | 'name'>
        & { items: Array<(
          { __typename?: 'ExtraItem' }
          & Pick<ExtraItem, 'id' | 'name'>
        )> }
      )>, kitchen: (
        { __typename?: 'Kitchen' }
        & Pick<Kitchen, 'id' | 'name'>
      ), mealPackItems: Array<(
        { __typename?: 'KitchenMenuItem' }
        & { kitchen: (
          { __typename?: 'Kitchen' }
          & Pick<Kitchen, 'id' | 'name' | 'status'>
        ) }
        & KitchenMenuItemShortInfoFragment
      )> }
      & KitchenMenuItemInfoFragment
    )> }
  )> }
);

export type CustomerPageQueryVariables = Exact<{
  customerId: Scalars['ID'];
}>;


export type CustomerPageQuery = (
  { __typename?: 'Query' }
  & { customer: (
    { __typename?: 'User' }
    & { customerInfo?: Maybe<(
      { __typename?: 'UserCustomerInfo' }
      & { tags: Array<(
        { __typename?: 'Tag' }
        & TagInfoFragment
      )>, addresses: Array<(
        { __typename?: 'Address' }
        & AddressInfoFragment
      )>, orders: Array<(
        { __typename?: 'Order' }
        & { promos: Array<(
          { __typename?: 'Promo' }
          & Pick<Promo, 'id' | 'code'>
        )>, items: Array<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id'>
        )> }
        & CustomerOrderInfoFragment
      )> }
      & CustomerInfoFragment
    )> }
    & UserInfoFragment
  ) }
);

export type CustomersQueryVariables = Exact<{
  orderBy: UserOrderByArg;
  nameFilter: Scalars['String'];
  pagination: PaginationArg;
}>;


export type CustomersQuery = (
  { __typename?: 'Query' }
  & { customers: (
    { __typename?: 'CustomersReturnType' }
    & Pick<CustomersReturnType, 'total'>
    & { users: Array<(
      { __typename?: 'User' }
      & { customerInfo?: Maybe<(
        { __typename?: 'UserCustomerInfo' }
        & CustomerInfoFragment
      )> }
      & UserInfoFragment
    )> }
  ) }
);

export type KdsKitchenTicketsQueryVariables = Exact<{
  kitchenId: Scalars['ID'];
  recentlyDoneLimit: Scalars['Int'];
}>;


export type KdsKitchenTicketsQuery = (
  { __typename?: 'Query' }
  & { kdsKitchenTickets: Array<(
    { __typename?: 'KitchenTicket' }
    & KitchenTicketFragment
  )>, kdsDoneKitchenTickets: Array<(
    { __typename?: 'KitchenTicket' }
    & Pick<KitchenTicket, 'id'>
    & { order: (
      { __typename?: 'Order' }
      & Pick<Order, 'id' | 'shortId' | 'platform'>
      & { refundableItems: Array<(
        { __typename?: 'OrderItem' }
        & RefundableItemsFragment
      )> }
    ) }
  )> }
);

export type KdsFutureKitchenTicketsQueryVariables = Exact<{
  kitchenId: Scalars['ID'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
}>;


export type KdsFutureKitchenTicketsQuery = (
  { __typename?: 'Query' }
  & { kdsKitchenTickets: Array<(
    { __typename?: 'KitchenTicket' }
    & KitchenTicketFragment
  )> }
);

export type KdsKitchenSingleTicketQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type KdsKitchenSingleTicketQuery = (
  { __typename?: 'Query' }
  & { kdsKitchenTicket: (
    { __typename?: 'KitchenTicket' }
    & { items: Array<(
      { __typename?: 'FacilityItem' }
      & FacilityItemInfoFragment
    )>, order: (
      { __typename?: 'Order' }
      & Pick<Order, 'platform'>
      & { refunds: Array<(
        { __typename?: 'OrderRefund' }
        & RefundsFragment
      )> }
    ) }
    & KitchenTicketsRecentlyDoneFragment
  ) }
);

export type KdsExpoOrdersQueryVariables = Exact<{ [key: string]: never; }>;


export type KdsExpoOrdersQuery = (
  { __typename?: 'Query' }
  & { kdsOrders: Array<(
    { __typename?: 'Order' }
    & KdsExpoOrderFragment
  )>, kdsDoneOrders: Array<(
    { __typename?: 'Order' }
    & Pick<Order, 'id' | 'shortId' | 'platform'>
    & { refundableItems: Array<(
      { __typename?: 'OrderItem' }
      & RefundableItemsFragment
    )> }
  )> }
);

export type KdsExpoStatisticsQueryVariables = Exact<{ [key: string]: never; }>;


export type KdsExpoStatisticsQuery = (
  { __typename?: 'Query' }
  & { kdsTicketStatistics: (
    { __typename?: 'TicketStatistics' }
    & Pick<TicketStatistics, 'kitchen'>
  ) }
);

export type KdsExpoRecentOrdersQueryVariables = Exact<{ [key: string]: never; }>;


export type KdsExpoRecentOrdersQuery = (
  { __typename?: 'Query' }
  & { kdsDoneOrders: Array<(
    { __typename?: 'Order' }
    & { items: Array<(
      { __typename?: 'OrderItem' }
      & KdsOrderItemFragment
    )>, refundableItems: Array<(
      { __typename?: 'OrderItem' }
      & RefundableItemsFragment
    )> }
    & KdsExpoRecentOrderFragment
  )> }
);

export type KdsStationRecentOrdersQueryVariables = Exact<{ [key: string]: never; }>;


export type KdsStationRecentOrdersQuery = (
  { __typename?: 'Query' }
  & { kdsDoneOrders: Array<(
    { __typename?: 'Order' }
    & { items: Array<(
      { __typename?: 'OrderItem' }
      & KdsOrderItemFragment
    )>, refundableItems: Array<(
      { __typename?: 'OrderItem' }
      & RefundableItemsFragment
    )> }
    & KdsExpoRecentOrderFragment
  )> }
);

export type KdsExpoSingleRecentOrderQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type KdsExpoSingleRecentOrderQuery = (
  { __typename?: 'Query' }
  & { adminOrder: (
    { __typename?: 'Order' }
    & { items: Array<(
      { __typename?: 'OrderItem' }
      & KdsOrderItemFragment
    )> }
    & KdsExpoRecentOrderFragment
  ) }
);

export type GetKdsStationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetKdsStationsQuery = (
  { __typename?: 'Query' }
  & { getKdsStations: Array<(
    { __typename?: 'Station' }
    & Pick<Station, 'id' | 'kitchen' | 'name'>
  )> }
);

export type KdsFohStationOrdersQueryVariables = Exact<{ [key: string]: never; }>;


export type KdsFohStationOrdersQuery = (
  { __typename?: 'Query' }
  & { kdsOrders: Array<(
    { __typename?: 'Order' }
    & KdsExpoOrderFragment
  )>, kdsDoneOrders: Array<(
    { __typename?: 'Order' }
    & Pick<Order, 'id' | 'shortId' | 'platform'>
    & { refundableItems: Array<(
      { __typename?: 'OrderItem' }
      & RefundableItemsFragment
    )> }
  )> }
);

export type KdsPackagingOrdersQueryVariables = Exact<{
  workplace: Scalars['Int'];
}>;


export type KdsPackagingOrdersQuery = (
  { __typename?: 'Query' }
  & { kdsOrders: Array<(
    { __typename?: 'Order' }
    & KdsExpoOrderFragment
  )>, kdsDoneOrders: Array<(
    { __typename?: 'Order' }
    & { refundableItems: Array<(
      { __typename?: 'OrderItem' }
      & RefundableItemsFragment
    )> }
    & KdsExpoOrderFragment
  )> }
);

export type KdsTableAssignQueryVariables = Exact<{ [key: string]: never; }>;


export type KdsTableAssignQuery = (
  { __typename?: 'Query' }
  & { kdsOrders: Array<(
    { __typename?: 'Order' }
    & AssignmentTicketFragment
    & CustomerTagsFragment
  )>, kdsDoneOrders: Array<(
    { __typename?: 'Order' }
    & Pick<Order, 'id' | 'shortId' | 'platform'>
    & { refundableItems: Array<(
      { __typename?: 'OrderItem' }
      & RefundableItemsFragment
    )> }
  )> }
);

export type KdsOrderAssignmentsQueryVariables = Exact<{ [key: string]: never; }>;


export type KdsOrderAssignmentsQuery = (
  { __typename?: 'Query' }
  & { kdsOrders: Array<(
    { __typename?: 'Order' }
    & KdsOrderAssignmentOrderFragment
  )> }
);

export type KdsInProgressOrdersQueryVariables = Exact<{ [key: string]: never; }>;


export type KdsInProgressOrdersQuery = (
  { __typename?: 'Query' }
  & { kdsOrders: Array<(
    { __typename?: 'Order' }
    & KdsExpoOrderFragment
  )> }
);

export type KdsPackagingStatisticsQueryVariables = Exact<{ [key: string]: never; }>;


export type KdsPackagingStatisticsQuery = (
  { __typename?: 'Query' }
  & { kdsTicketStatistics: (
    { __typename?: 'TicketStatistics' }
    & Pick<TicketStatistics, 'kitchen'>
  ), kdsOrderStatistics: (
    { __typename?: 'OrderStatistics' }
    & Pick<OrderStatistics, 'expo'>
  ) }
);

export type KdsRunnerBundlesQueryVariables = Exact<{ [key: string]: never; }>;


export type KdsRunnerBundlesQuery = (
  { __typename?: 'Query' }
  & { kdsOrderBundles: Array<(
    { __typename?: 'OrderBundle' }
    & { orders: Array<(
      { __typename?: 'Order' }
      & { customer: (
        { __typename?: 'UserCustomerInfo' }
        & Pick<UserCustomerInfo, 'id' | 'isEmployee' | 'ordersCount'>
        & { tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'id' | 'name'>
        )> }
      ) }
      & OrderInfoFragment
    )> }
    & BundleShortInfoFragment
  )> }
);

export type KdsRunnerStatisticsQueryVariables = Exact<{ [key: string]: never; }>;


export type KdsRunnerStatisticsQuery = (
  { __typename?: 'Query' }
  & { kdsOrderStatistics: (
    { __typename?: 'OrderStatistics' }
    & Pick<OrderStatistics, 'packaging'>
  ) }
);

export type KdsPickupOrdersQueryVariables = Exact<{ [key: string]: never; }>;


export type KdsPickupOrdersQuery = (
  { __typename?: 'Query' }
  & { kdsOrders: Array<(
    { __typename?: 'Order' }
    & KdsPickupOrderFragment
  )> }
);

export type KdsPickupStatisticsQueryVariables = Exact<{ [key: string]: never; }>;


export type KdsPickupStatisticsQuery = (
  { __typename?: 'Query' }
  & { kdsOrderStatistics: (
    { __typename?: 'OrderStatistics' }
    & Pick<OrderStatistics, 'expo' | 'packaging'>
  ) }
);

export type KdsPickupOrdersSearchQueryVariables = Exact<{
  shortId?: Maybe<Scalars['String']>;
}>;


export type KdsPickupOrdersSearchQuery = (
  { __typename?: 'Query' }
  & { kdsFacilityOrders: Array<(
    { __typename?: 'Order' }
    & KdsPickupOrderFragment
  )> }
);

export type KdsKitchensQueryVariables = Exact<{ [key: string]: never; }>;


export type KdsKitchensQuery = (
  { __typename?: 'Query' }
  & { adminKitchens: Array<(
    { __typename?: 'Kitchen' }
    & Pick<Kitchen, 'id' | 'name' | 'status' | 'logoUrl'>
  )>, me?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { kitchens: Array<(
      { __typename?: 'Kitchen' }
      & Pick<Kitchen, 'id'>
    )> }
  )> }
);

export type DriversQueryVariables = Exact<{ [key: string]: never; }>;


export type DriversQuery = (
  { __typename?: 'Query' }
  & { adminDrivers: Array<(
    { __typename?: 'Driver' }
    & Pick<Driver, 'id' | 'name'>
  )> }
);

export type PromoQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type PromoQuery = (
  { __typename?: 'Query' }
  & { adminPromo?: Maybe<(
    { __typename?: 'Promo' }
    & { promoFacilityRules: Array<(
      { __typename?: 'PromoFacilityRule' }
      & Pick<PromoFacilityRule, 'id' | 'facilityId' | 'isAllKitchens'>
      & { kitchens: Array<(
        { __typename?: 'Kitchen' }
        & Pick<Kitchen, 'id' | 'name' | 'facilityId'>
      )> }
    )> }
    & PromoDetailFragment
  )> }
);

export type PromosQueryVariables = Exact<{
  input: PromosInput;
}>;


export type PromosQuery = (
  { __typename?: 'Query' }
  & { adminPromos: (
    { __typename?: 'PromosList' }
    & Pick<PromosList, 'total'>
    & { promos: Array<(
      { __typename?: 'Promo' }
      & PromoListInfoFragment
    )> }
  ) }
);

export type SegmentsQueryVariables = Exact<{ [key: string]: never; }>;


export type SegmentsQuery = (
  { __typename?: 'Query' }
  & { adminSegments: Array<(
    { __typename?: 'Segment' }
    & Pick<Segment, 'id' | 'name'>
  )> }
);

export type PromoUsersQueryVariables = Exact<{
  query: Scalars['String'];
  pagination: PaginationArg;
  isEmployee: Scalars['Boolean'];
  selectedUsers: Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>;
}>;


export type PromoUsersQuery = (
  { __typename?: 'Query' }
  & { adminPromoUsers: (
    { __typename?: 'PromoUsers' }
    & { selectedUsers: Array<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name'>
    )>, otherUsers: Array<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name'>
    )> }
  ) }
);

export type PromoBannerQueryVariables = Exact<{ [key: string]: never; }>;


export type PromoBannerQuery = (
  { __typename?: 'Query' }
  & { adminPromoBanner?: Maybe<(
    { __typename?: 'PromoBanner' }
    & PromoBannerFragment
  )> }
);

export type AllAvailableTagsQueryVariables = Exact<{
  query: Scalars['String'];
  pagination: PaginationArg;
}>;


export type AllAvailableTagsQuery = (
  { __typename?: 'Query' }
  & { adminTags: Array<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'id' | 'name'>
  )> }
);

export type AdminCartUpsellItemsQueryVariables = Exact<{
  facilityId: Scalars['String'];
  platform: ClientPlatformInput;
}>;


export type AdminCartUpsellItemsQuery = (
  { __typename?: 'Query' }
  & { adminGetCartUpsellItems: Array<(
    { __typename?: 'CartUpsellItem' }
    & Pick<CartUpsellItem, 'id'>
    & { upsellItem: (
      { __typename?: 'KitchenMenuItem' }
      & SearchMenuItemInfoFragment
    ) }
  )> }
);

export type CreatePowerBiReportMutationVariables = Exact<{
  data: PowerBiReportCreateInput;
}>;


export type CreatePowerBiReportMutation = (
  { __typename?: 'Mutation' }
  & { adminCreatePowerBiReport: (
    { __typename?: 'PowerBiReport' }
    & Pick<PowerBiReport, 'id'>
  ) }
);

export type DeletePowerBiReportMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeletePowerBiReportMutation = (
  { __typename?: 'Mutation' }
  & { adminDeletePowerBiReport?: Maybe<(
    { __typename?: 'PowerBiReport' }
    & Pick<PowerBiReport, 'id'>
  )> }
);

export type SortPowerBiReportsMutationVariables = Exact<{
  input: Array<Scalars['String']> | Scalars['String'];
}>;


export type SortPowerBiReportsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'adminUpdateReportSort'>
);

export type PowerBiReportsQueryVariables = Exact<{ [key: string]: never; }>;


export type PowerBiReportsQuery = (
  { __typename?: 'Query' }
  & { adminPowerBiReportsAndToken: (
    { __typename?: 'PowerBiReportsAndToken' }
    & Pick<PowerBiReportsAndToken, 'embedToken'>
    & { reports: Array<(
      { __typename?: 'PowerBiReport' }
      & Pick<PowerBiReport, 'id' | 'title' | 'url' | 'reportId'>
    )> }
  ) }
);

export type PowerBiReportsAllQueryVariables = Exact<{ [key: string]: never; }>;


export type PowerBiReportsAllQuery = (
  { __typename?: 'Query' }
  & { adminPowerBiReportsAll: Array<(
    { __typename?: 'PowerBiReport' }
    & Pick<PowerBiReport, 'id' | 'title' | 'url' | 'availableToRoles'>
    & { availableToConcepts: Array<(
      { __typename?: 'Kitchen' }
      & Pick<Kitchen, 'id' | 'name'>
    )>, availableToFacilities: Array<(
      { __typename?: 'Facility' }
      & Pick<Facility, 'id' | 'name'>
    )> }
  )> }
);

export type AvailablePowerBiWorkspacesQueryVariables = Exact<{ [key: string]: never; }>;


export type AvailablePowerBiWorkspacesQuery = (
  { __typename?: 'Query' }
  & { adminAvailablePowerBiWorkspaces: Array<(
    { __typename?: 'AvailablePowerBiWorkspace' }
    & Pick<AvailablePowerBiWorkspace, 'id' | 'name'>
  )> }
);

export type AvailablePowerBiReportsQueryVariables = Exact<{
  workspaceId: Scalars['String'];
}>;


export type AvailablePowerBiReportsQuery = (
  { __typename?: 'Query' }
  & { adminAvailablePowerBiReports: Array<(
    { __typename?: 'AvailablePowerBiReport' }
    & Pick<AvailablePowerBiReport, 'id' | 'name' | 'embedUrl' | 'datasetId'>
  )> }
);

export type StandardizedPlatformMixReportQueryVariables = Exact<{
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  role?: Maybe<UserRole>;
  facilityId?: Maybe<Scalars['String']>;
  facilityIds: Array<Scalars['ID']> | Scalars['ID'];
  kitchenIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type StandardizedPlatformMixReportQuery = (
  { __typename?: 'Query' }
  & { adminStandardizedPlatformMixReport: Array<(
    { __typename?: 'StandardizedPlatformMixReport' }
    & Pick<StandardizedPlatformMixReport, 'orderDate' | 'orderingPlatformName' | 'totalOrders' | 'totalSales'>
  )> }
);

export type StandardizedBrandRemittanceReportQueryVariables = Exact<{
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  role?: Maybe<UserRole>;
  facilityId?: Maybe<Scalars['String']>;
  facilityIds: Array<Scalars['ID']> | Scalars['ID'];
  kitchenIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type StandardizedBrandRemittanceReportQuery = (
  { __typename?: 'Query' }
  & { adminStandardizedBrandRemittanceReport: Array<(
    { __typename?: 'StandardizedBrandRemittanceReport' }
    & Pick<StandardizedBrandRemittanceReport, 'lastUpdateDate' | 'kitchenId' | 'kitchenName' | 'accountingDateRange' | 'grossRestaurantSales' | 'discounts' | 'netSalesPretax' | 'serviceFees' | 'foodSalesTax' | 'serviceFeeTax' | 'deliveryFeeTax' | 'deliveryFees' | 'deliveryTips' | 'pickupTips' | 'cartTotal' | 'deliveryAggregatorExpense' | 'deliveryAggregatorExpenseRemainder' | 'craveProcessingFees' | 'partnerAgreements' | 'brandAgreements' | 'cardIncomeAfterFees' | 'adjustmentsRefunds' | 'adjustmentsChargebacks' | 'adjustmentOther' | 'finalPayout' | 'payoutId' | 'payoutDescriptor' | 'payoutDate'>
  )> }
);

export type StandardizedBrandSalesReportQueryVariables = Exact<{
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  role?: Maybe<UserRole>;
  facilityId?: Maybe<Scalars['String']>;
  facilityIds: Array<Scalars['ID']> | Scalars['ID'];
  kitchenIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type StandardizedBrandSalesReportQuery = (
  { __typename?: 'Query' }
  & { adminStandardizedBrandSalesReport: Array<(
    { __typename?: 'StandardizedBrandSalesReport' }
    & Pick<StandardizedBrandSalesReport, 'brandName' | 'totalOrders' | 'totalSales' | 'AOV'>
  )> }
);

export type StandardizedDailySalesReportQueryVariables = Exact<{
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  role?: Maybe<UserRole>;
  facilityId?: Maybe<Scalars['String']>;
  facilityIds: Array<Scalars['ID']> | Scalars['ID'];
  kitchenIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type StandardizedDailySalesReportQuery = (
  { __typename?: 'Query' }
  & { adminStandardizedDailySalesReport: Array<(
    { __typename?: 'StandardizedDailySalesReport' }
    & Pick<StandardizedDailySalesReport, 'date' | 'dailyAIO' | 'dailyAOV' | 'dailyAvgBrandsPerOrder' | 'dayOfWeek' | 'totalItems' | 'totalSales' | 'totalOrders'>
  )> }
);

export type StandardizedGiftCardReportQueryVariables = Exact<{
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  role?: Maybe<UserRole>;
  facilityId?: Maybe<Scalars['String']>;
  facilityIds: Array<Scalars['ID']> | Scalars['ID'];
  kitchenIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type StandardizedGiftCardReportQuery = (
  { __typename?: 'Query' }
  & { adminStandardizedGiftCardReport: Array<(
    { __typename?: 'StandardizedGiftCardReport' }
    & Pick<StandardizedGiftCardReport, 'codeName' | 'status' | 'beginningBalance' | 'remainingBalance' | 'lastActivity'>
  )> }
);

export type StandardizedModifierSalesReportQueryVariables = Exact<{
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  role?: Maybe<UserRole>;
  facilityId?: Maybe<Scalars['String']>;
  facilityIds: Array<Scalars['ID']> | Scalars['ID'];
  kitchenIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type StandardizedModifierSalesReportQuery = (
  { __typename?: 'Query' }
  & { adminStandardizedModifierSalesReport: Array<(
    { __typename?: 'StandardizedModifierSalesReport' }
    & Pick<StandardizedModifierSalesReport, 'orderDate' | 'brandName' | 'menuItemName' | 'modifierName' | 'modifiersSold' | 'modifierPrice' | 'modifierSales'>
  )> }
);

export type StandardizedTransactionsReportQueryVariables = Exact<{
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  role?: Maybe<UserRole>;
  facilityId?: Maybe<Scalars['String']>;
  facilityIds: Array<Scalars['ID']> | Scalars['ID'];
  kitchenIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type StandardizedTransactionsReportQuery = (
  { __typename?: 'Query' }
  & { adminStandardizedTransactionsReport: Array<(
    { __typename?: 'StandardizedTransactionsReport' }
    & Pick<StandardizedTransactionsReport, 'brandFee' | 'brands' | 'combinedCartTotal' | 'creditCardProcessingFee' | 'customerEmail' | 'customerName' | 'customerPhone' | 'deliveryCharge' | 'deliveryFee' | 'deliveryTax' | 'foodSalesTax' | 'fulfillmentType' | 'giftCardAmount' | 'giftCode' | 'netSales' | 'numberOfOrderedItems' | 'numberOfRestaurants' | 'orderDate' | 'orderTime' | 'orderTotal' | 'orderType' | 'orderingProvider' | 'partnerFee' | 'platform' | 'promoAmount' | 'promoCode' | 'serviceFee' | 'serviceFeeTax' | 'shortId' | 'subtotal' | 'taxAmount' | 'tipAmount' | 'transactionId' | 'transactionStatus' | 'transactionType'>
  )> }
);

export type StandardizedOperationsDetailReportQueryVariables = Exact<{
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  role?: Maybe<UserRole>;
  facilityId?: Maybe<Scalars['String']>;
  facilityIds: Array<Scalars['ID']> | Scalars['ID'];
  kitchenIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type StandardizedOperationsDetailReportQuery = (
  { __typename?: 'Query' }
  & { adminStandardizedOperationsDetailedReport: Array<(
    { __typename?: 'StandardizedOperationsDetailedReport' }
    & Pick<StandardizedOperationsDetailedReport, 'datePlaced' | 'deliveryEndTime' | 'deliveryStartTime' | 'shortId' | 'numberOfItems' | 'numberOfRefiredItems' | 'orderMethod' | 'startPreparingTime' | 'timeAwaitingPickup' | 'timeDelayedInKitchen' | 'timeInExpo' | 'timeInKitchen' | 'timeInPackaging' | 'timePlaced' | 'timeWanted' | 'totalTimeToCompletion'>
  )> }
);

export type StandardizedItemSalesReportQueryVariables = Exact<{
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  role?: Maybe<UserRole>;
  facilityId?: Maybe<Scalars['String']>;
  facilityIds: Array<Scalars['ID']> | Scalars['ID'];
  kitchenIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type StandardizedItemSalesReportQuery = (
  { __typename?: 'Query' }
  & { adminStandardizedItemSalesReport: Array<(
    { __typename?: 'StandardizedItemSalesReport' }
    & Pick<StandardizedItemSalesReport, 'brandName' | 'menuItemName' | 'sold' | 'sales' | 'avgMenuItemPrice' | 'modifierNames' | 'modifierSold' | 'modifierSales' | 'avgModifierPrice' | 'totalSales'>
  )> }
);

export type StandardizedItemTransactionsReportQueryVariables = Exact<{
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  role?: Maybe<UserRole>;
  facilityId?: Maybe<Scalars['String']>;
  facilityIds: Array<Scalars['ID']> | Scalars['ID'];
  kitchenIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type StandardizedItemTransactionsReportQuery = (
  { __typename?: 'Query' }
  & { adminStandardizedItemTransactionsReport: Array<(
    { __typename?: 'StandardizedItemTransactionsReport' }
    & Pick<StandardizedItemTransactionsReport, 'brand' | 'brandAgreements' | 'brandMenuItemName' | 'customerEmail' | 'customerName' | 'customerPhone' | 'deliveryFee' | 'deliveryFeeTax' | 'foodSalesTax' | 'giftCardAmount' | 'giftCode' | 'netSales' | 'numberOfOrderedItems' | 'numberOfBrands' | 'orderDate' | 'orderTime' | 'orderTotal' | 'orderType' | 'orderingProvider' | 'partnerAgreements' | 'platform' | 'promoAmount' | 'promoCode' | 'serviceFee' | 'serviceFeeTax' | 'shortId' | 'subtotal' | 'tipAmount' | 'transactionId' | 'transactionStatus' | 'transactionType'>
  )> }
);

export type StandardizedPromoReportQueryVariables = Exact<{
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  role?: Maybe<UserRole>;
  facilityId?: Maybe<Scalars['String']>;
  facilityIds: Array<Scalars['ID']> | Scalars['ID'];
  kitchenIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type StandardizedPromoReportQuery = (
  { __typename?: 'Query' }
  & { adminStandardizedPromoReport: Array<(
    { __typename?: 'StandardizedPromoReport' }
    & Pick<StandardizedPromoReport, 'promoCodeName' | 'promoCodeType' | 'numberOfOrders' | 'promoValueRedeemed' | 'status'>
  )> }
);

export type StandardizedTimeOfDayBreakdownReportQueryVariables = Exact<{
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  role?: Maybe<UserRole>;
  facilityId?: Maybe<Scalars['String']>;
  facilityIds: Array<Scalars['ID']> | Scalars['ID'];
  kitchenIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type StandardizedTimeOfDayBreakdownReportQuery = (
  { __typename?: 'Query' }
  & { adminStandardizedTimeOfDayBreakdownReport: Array<(
    { __typename?: 'StandardizedTimeOfDayBreakdownReport' }
    & Pick<StandardizedTimeOfDayBreakdownReport, 'date' | 'brandName' | 'timeSlot' | 'numberOfOrdersPerBrand' | 'totalSalesPerTimeSlot'>
  )> }
);

export type StandardizedPartnerRemittanceReportQueryVariables = Exact<{
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  role?: Maybe<UserRole>;
  facilityId?: Maybe<Scalars['String']>;
  facilityIds: Array<Scalars['ID']> | Scalars['ID'];
  kitchenIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type StandardizedPartnerRemittanceReportQuery = (
  { __typename?: 'Query' }
  & { adminStandardizedPartnerRemittanceReport: Array<(
    { __typename?: 'StandardizedPartnerRemittanceReport' }
    & Pick<StandardizedPartnerRemittanceReport, 'lastUpdateDate' | 'tenantId' | 'kitchenId' | 'kitchenName' | 'accountingDateRange' | 'grossRestaurantSales' | 'discounts' | 'giftCardAmount' | 'netSalesPretax' | 'serviceFees' | 'foodSalesTax' | 'serviceFeeTax' | 'deliveryFees' | 'deliveryTips' | 'pickupTips' | 'cartTotal' | 'otherTender' | 'deliveryAggregatorExpense' | 'deliveryAggregatorExpenseRemainder' | 'craveProcessingFees' | 'partnerAgreements' | 'brandAgreements' | 'cardIncomeAfterFees' | 'adjustmentsRefunds' | 'adjustmentsChargebacks' | 'adjustmentOther' | 'finalPayout' | 'payoutId' | 'payoutDescriptor' | 'payoutDate'>
  )> }
);

export type OnTicketChangedSubscriptionVariables = Exact<{
  kitchenId?: Maybe<Scalars['ID']>;
  screenId?: Maybe<Scalars['String']>;
  screenType?: Maybe<ScreenType>;
}>;


export type OnTicketChangedSubscription = (
  { __typename?: 'Subscription' }
  & { ticketChanged?: Maybe<(
    { __typename?: 'KitchenTicketSubscription' }
    & Pick<KitchenTicketSubscription, 'operation'>
    & { data: (
      { __typename?: 'KitchenTicket' }
      & KitchenTicketFragment
    ) }
  )> }
);

export type OnStationTicketChangedSubscriptionVariables = Exact<{
  stationId?: Maybe<Scalars['ID']>;
  screenId?: Maybe<Scalars['String']>;
  screenType?: Maybe<ScreenType>;
}>;


export type OnStationTicketChangedSubscription = (
  { __typename?: 'Subscription' }
  & { stationTicketChanged?: Maybe<(
    { __typename?: 'KitchenTicketSubscription' }
    & Pick<KitchenTicketSubscription, 'operation'>
    & { data: (
      { __typename?: 'KitchenTicket' }
      & KitchenTicketFragment
    ) }
  )> }
);

export type OnOrderChangedMonitoringSubscriptionVariables = Exact<{
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
}>;


export type OnOrderChangedMonitoringSubscription = (
  { __typename?: 'Subscription' }
  & { orderChanged?: Maybe<(
    { __typename?: 'OrderSubscription' }
    & Pick<OrderSubscription, 'operation'>
    & { data: (
      { __typename?: 'Order' }
      & OrderInfoFragment
    ) }
  )> }
);

export type OnOrderChangedKitchenSubscriptionVariables = Exact<{
  screenId?: Maybe<Scalars['String']>;
  statuses?: Maybe<Array<Maybe<OrderStatus>> | Maybe<OrderStatus>>;
}>;


export type OnOrderChangedKitchenSubscription = (
  { __typename?: 'Subscription' }
  & { orderChanged?: Maybe<(
    { __typename?: 'OrderSubscription' }
    & Pick<OrderSubscription, 'operation'>
    & { data: (
      { __typename?: 'Order' }
      & { kitchenTickets: Array<(
        { __typename?: 'KitchenTicket' }
        & { kitchen?: Maybe<(
          { __typename?: 'Kitchen' }
          & Pick<Kitchen, 'id'>
        )> }
        & KitchenTicketFragment
      )> }
      & OrderShortInfoFragment
    ) }
  )> }
);

export type OnOrderChangedStationSubscriptionVariables = Exact<{
  screenId?: Maybe<Scalars['String']>;
  statuses?: Maybe<Array<Maybe<OrderStatus>> | Maybe<OrderStatus>>;
}>;


export type OnOrderChangedStationSubscription = (
  { __typename?: 'Subscription' }
  & { orderChanged?: Maybe<(
    { __typename?: 'OrderSubscription' }
    & Pick<OrderSubscription, 'operation'>
    & { data: (
      { __typename?: 'Order' }
      & { kitchenTickets: Array<(
        { __typename?: 'KitchenTicket' }
        & { station?: Maybe<(
          { __typename?: 'Station' }
          & Pick<Station, 'id'>
        )> }
        & KitchenTicketFragment
      )> }
      & OrderShortInfoFragment
    ) }
  )> }
);

export type OnOrderChangedExpoSubscriptionVariables = Exact<{
  screenId?: Maybe<Scalars['String']>;
  statuses?: Maybe<Array<Maybe<OrderStatus>> | Maybe<OrderStatus>>;
}>;


export type OnOrderChangedExpoSubscription = (
  { __typename?: 'Subscription' }
  & { orderChanged?: Maybe<(
    { __typename?: 'OrderSubscription' }
    & Pick<OrderSubscription, 'operation'>
    & { data: (
      { __typename?: 'Order' }
      & KdsExpoOrderFragment
    ) }
  )> }
);

export type OnOrderChangedPackagingSubscriptionVariables = Exact<{
  screenId?: Maybe<Scalars['String']>;
  workplace?: Maybe<Scalars['Int']>;
  statuses?: Maybe<Array<Maybe<OrderStatus>> | Maybe<OrderStatus>>;
}>;


export type OnOrderChangedPackagingSubscription = (
  { __typename?: 'Subscription' }
  & { orderChanged?: Maybe<(
    { __typename?: 'OrderSubscription' }
    & Pick<OrderSubscription, 'operation'>
    & { data: (
      { __typename?: 'Order' }
      & KdsExpoOrderFragment
    ) }
  )> }
);

export type OnOrderChangedFohSubscriptionVariables = Exact<{
  screenId?: Maybe<Scalars['String']>;
  statuses?: Maybe<Array<Maybe<OrderStatus>> | Maybe<OrderStatus>>;
}>;


export type OnOrderChangedFohSubscription = (
  { __typename?: 'Subscription' }
  & { orderChanged?: Maybe<(
    { __typename?: 'OrderSubscription' }
    & Pick<OrderSubscription, 'operation'>
    & { data: (
      { __typename?: 'Order' }
      & KdsExpoOrderFragment
    ) }
  )> }
);

export type OnOrderChangedOrderStatusSubscriptionVariables = Exact<{
  screenId?: Maybe<Scalars['String']>;
  statuses?: Maybe<Array<Maybe<OrderStatus>> | Maybe<OrderStatus>>;
}>;


export type OnOrderChangedOrderStatusSubscription = (
  { __typename?: 'Subscription' }
  & { orderChanged?: Maybe<(
    { __typename?: 'OrderSubscription' }
    & Pick<OrderSubscription, 'operation'>
    & { data: (
      { __typename?: 'Order' }
      & KdsExpoOrderFragment
    ) }
  )> }
);

export type OnOrderChangedRunnerSubscriptionVariables = Exact<{
  screenId?: Maybe<Scalars['String']>;
  statuses?: Maybe<Array<Maybe<OrderStatus>> | Maybe<OrderStatus>>;
}>;


export type OnOrderChangedRunnerSubscription = (
  { __typename?: 'Subscription' }
  & { orderChanged?: Maybe<(
    { __typename?: 'OrderSubscription' }
    & Pick<OrderSubscription, 'operation'>
    & { data: (
      { __typename?: 'Order' }
      & KdsRunnerOrderFragment
    ) }
  )> }
);

export type OnOrderChangedOrderAssignmentSubscriptionVariables = Exact<{
  screenId?: Maybe<Scalars['String']>;
  statuses?: Maybe<Array<Maybe<OrderStatus>> | Maybe<OrderStatus>>;
}>;


export type OnOrderChangedOrderAssignmentSubscription = (
  { __typename?: 'Subscription' }
  & { orderChanged?: Maybe<(
    { __typename?: 'OrderSubscription' }
    & Pick<OrderSubscription, 'operation'>
    & { data: (
      { __typename?: 'Order' }
      & KdsOrderAssignmentOrderFragment
    ) }
  )> }
);

export type OnOrderChangedTableAssignmentSubscriptionVariables = Exact<{
  screenId?: Maybe<Scalars['String']>;
  statuses?: Maybe<Array<Maybe<OrderStatus>> | Maybe<OrderStatus>>;
}>;


export type OnOrderChangedTableAssignmentSubscription = (
  { __typename?: 'Subscription' }
  & { orderChanged?: Maybe<(
    { __typename?: 'OrderSubscription' }
    & Pick<OrderSubscription, 'operation'>
    & { data: (
      { __typename?: 'Order' }
      & AssignmentTicketFragment
      & CustomerTagsFragment
    ) }
  )> }
);

export type OnOrderChangedPickupSubscriptionVariables = Exact<{
  screenId?: Maybe<Scalars['String']>;
  statuses?: Maybe<Array<Maybe<OrderStatus>> | Maybe<OrderStatus>>;
}>;


export type OnOrderChangedPickupSubscription = (
  { __typename?: 'Subscription' }
  & { orderChanged?: Maybe<(
    { __typename?: 'OrderSubscription' }
    & Pick<OrderSubscription, 'operation'>
    & { data: (
      { __typename?: 'Order' }
      & Pick<Order, 'status'>
      & KdsPickupOrderFragment
    ) }
  )> }
);

export const PlatformUserFragmentDoc = gql`
    fragment PlatformUser on User {
  id
  email
  name
  roles
}
    `;
export const UserInfoFragmentDoc = gql`
    fragment UserInfo on User {
  id
  name
  email
  phone
  createdAt
  roles
}
    `;
export const UserCustomerInfoFragmentDoc = gql`
    fragment UserCustomerInfo on User {
  ...UserInfo
  kitchens {
    id
    name
  }
  facilities {
    id
    name
    stations {
      id
      kitchen
      name
    }
    workplaces {
      id
      index
      type
    }
  }
}
    ${UserInfoFragmentDoc}`;
export const FacilityTaxFragmentDoc = gql`
    fragment FacilityTax on Facility {
  id
  isCateringDeliveryFeeTaxable
  isCateringServiceFeeTaxable
  isConsumerDeliveryFeeTaxable
  isConsumerServiceFeeTaxable
  cateringDeliveryFee
  cateringDeliveryFeeTaxRate
  cateringServiceFee
  cateringServiceFeeTaxRate
  consumerDeliveryFee
  consumerDeliveryFeeTaxRate
  consumerServiceFee
  consumerServiceFeeTaxRate
  consumerServiceFeeType
  cateringServiceFeeType
  taxRate
}
    `;
export const FacilityFragmentDoc = gql`
    fragment Facility on Facility {
  id
  name
  kdsType
  timeZone
  operationHours
  enableCatering
  enableKiosk
  kioskLogoUrl
  logoUrl
  heroImageUrl
  formattedAddress
  phone
  email
  isFOHPrinterAvailable
  isGiftCardEnabled
  isCmsEnabled
  isPromoEnabled
  readonly
  ...FacilityTax
}
    ${FacilityTaxFragmentDoc}`;
export const MealPackItemInfoFragmentDoc = gql`
    fragment MealPackItemInfo on KitchenMenuItem {
  id
  name
  description
  price
  status
  imgUrl
  dishTypes
  inventoryStatus
  enableInventoryCount
  availableFrom
  availableTo
  availability
  sortKitchenIds
  labels
  type
}
    `;
export const MenuCategoryInfoFragmentDoc = gql`
    fragment MenuCategoryInfo on MenuCategory {
  id
  name
  menuItemType
  categoryType
}
    `;
export const ExtraItemInfoFragmentDoc = gql`
    fragment ExtraItemInfo on ExtraItem {
  id
  name
}
    `;
export const OrderShortInfoFragmentDoc = gql`
    fragment OrderShortInfo on Order {
  id
  shortId
  submittedAt
  status
  isCatering
  isEmployee
  method
}
    `;
export const OrderItemInfoFragmentDoc = gql`
    fragment OrderItemInfo on OrderItem {
  id
  quantity
  price
  name
  type
  orderMealPackId
}
    `;
export const OrderExtraItemInfoFragmentDoc = gql`
    fragment OrderExtraItemInfo on OrderItemExtra {
  id
  price
  quantity
  name
  extraSortId
  extraItemSortId
}
    `;
export const CustomerOrderInfoFragmentDoc = gql`
    fragment CustomerOrderInfo on Order {
  id
  shortId
  submittedAt
  status
  method
  canceledAt
  deliveredAt
  fulfillmentAt
  subtotal
  total
  locality
  postalCode
  street
  placeId
  addressNote
  isCatering
  isOrderNow
  formattedAddress
  payedAt
  orderName
  platform
}
    `;
export const KitchenMenuItemShortInfoFragmentDoc = gql`
    fragment KitchenMenuItemShortInfo on KitchenMenuItem {
  id
  name
  status
  inventoryCount
  inventoryStatus
  enableInventoryCount
}
    `;
export const RefundsFragmentDoc = gql`
    fragment Refunds on OrderRefund {
  reason
  type
  createdAt
  calculations {
    creditCardRefund
    giftCardRefund
    deliveryFee
    gratuity
    serviceFee
    subtotal
    tax
    total
  }
  items {
    id
    quantity
    orderItem {
      id
      price
      name
      type
      quantity
      menuItem {
        ...KitchenMenuItemShortInfo
        kitchen {
          id
          name
        }
      }
      extraItems {
        id
        price
        quantity
        name
        extraSortId
        extraItemSortId
      }
      mealPackOrderItems {
        id
        price
        quantity
        name
        type
      }
    }
  }
}
    ${KitchenMenuItemShortInfoFragmentDoc}`;
export const OrderBundleInfoFragmentDoc = gql`
    fragment OrderBundleInfo on OrderBundle {
  id
  shortId
  sortedOrderIds
  isLocked
}
    `;
export const AddressInfoFragmentDoc = gql`
    fragment AddressInfo on Address {
  id
  locality
  region
  postalCode
  street
  nickname
  placeId
  isDefault
  formattedAddress
}
    `;
export const CustomerInfoFragmentDoc = gql`
    fragment CustomerInfo on UserCustomerInfo {
  id
  customerNotes
  internalNotes
  vip
  isEmployee
  ordersCount
}
    `;
export const TagInfoFragmentDoc = gql`
    fragment TagInfo on Tag {
  id
  name
}
    `;
export const MultiBrandOrderInfoFragmentDoc = gql`
    fragment MultiBrandOrderInfo on Order {
  kitchenTickets {
    id
    status
    kitchen {
      id
      name
    }
    station {
      id
      name
    }
    items {
      id
      quantity
    }
  }
  retailItems {
    id
    quantity
  }
}
    `;
export const OrderInfoFragmentDoc = gql`
    fragment OrderInfo on Order {
  id
  shortId
  submittedAt
  status
  method
  canceledAt
  delivery
  deliveredAt
  deliveryEstimation
  subtotal
  delivery
  taxFees
  tax
  fee
  total
  tip
  promoCodes
  giftCardCodes
  giftCardDiscounts
  promoDiscount
  giftCardsDiscount
  locality
  postalCode
  street
  placeId
  utensilsCount
  deliveryOption
  addressNote
  isCatering
  isVip
  isOrderNow
  fulfillmentAt
  fulfillmentBy
  preparingUntil
  deliveryTimeInSeconds
  refireDelay
  formattedAddress
  payedAt
  orderName
  orderPhoneNumber
  orderEmail
  platform
  note
  source
  customer {
    user {
      id
      name
      phone
      email
      isGuest
    }
  }
  braintreeTransactions {
    id
    paymentMethod
    paymentMethodInfo
    status
  }
  facility {
    id
    formattedAddress
    logoUrl
    name
  }
}
    `;
export const CustomerTagsFragmentDoc = gql`
    fragment CustomerTags on Order {
  id
  isCatering
  isVip
  source
  customer {
    id
    ordersCount
    isEmployee
    user {
      id
      name
      phone
    }
    tags {
      id
      name
    }
  }
}
    `;
export const OrderRefundItemFragmentDoc = gql`
    fragment OrderRefundItem on OrderItem {
  id
  price
  name
  type
  quantity
  menuItem {
    ...KitchenMenuItemShortInfo
    kitchen {
      id
      name
    }
  }
  extraItems {
    id
    price
    quantity
    name
    extraSortId
    extraItemSortId
  }
  mealPackOrderItems {
    id
    price
    quantity
    name
    type
  }
}
    ${KitchenMenuItemShortInfoFragmentDoc}`;
export const OrderRefundFragmentDoc = gql`
    fragment OrderRefund on OrderRefund {
  createdAt
  type
  reason
  items {
    id
    quantity
    orderItem {
      ...OrderRefundItem
    }
  }
  calculations {
    creditCardRefund
    giftCardRefund
    total
    subtotal
    tax
    gratuity
    deliveryFee
    serviceFee
  }
}
    ${OrderRefundItemFragmentDoc}`;
export const RefundableItemsFragmentDoc = gql`
    fragment RefundableItems on OrderItem {
  id
  name
  quantity
  price
  extraItems {
    id
    name
    price
    quantity
  }
}
    `;
export const FacilityItemInfoFragmentDoc = gql`
    fragment FacilityItemInfo on FacilityItem {
  id
  refired
  quantity
  menuItem {
    id
    name
    kitchen {
      id
      name
    }
  }
  orderItem {
    id
    name
    extraItems {
      id
      name
      quantity
      extraSortId
      extraItemSortId
    }
    orderMealPack {
      id
      name
    }
    orderMealPackId
  }
  menuItem {
    kitchen {
      id
      name
    }
  }
}
    `;
export const KitchenTicketFragmentDoc = gql`
    fragment KitchenTicket on KitchenTicket {
  id
  shortId
  preparingFrom
  preparingUntil
  status
  shortId
  refiredAt
  createdAt
  refireReason
  refiredFromId
  delayTime
  cookTime
  isPrinted
  order {
    ...MultiBrandOrderInfo
    ...OrderInfo
    ...CustomerTags
    refunds {
      ...OrderRefund
    }
    refundableItems {
      ...RefundableItems
    }
    platform
  }
  items {
    ...FacilityItemInfo
  }
}
    ${MultiBrandOrderInfoFragmentDoc}
${OrderInfoFragmentDoc}
${CustomerTagsFragmentDoc}
${OrderRefundFragmentDoc}
${RefundableItemsFragmentDoc}
${FacilityItemInfoFragmentDoc}`;
export const BasicOrderFragmentDoc = gql`
    fragment BasicOrder on Order {
  id
  shortId
  refireDelay
}
    `;
export const KitchenTicketsRecentlyDoneFragmentDoc = gql`
    fragment KitchenTicketsRecentlyDone on KitchenTicket {
  id
  preparedAt
  order {
    id
    shortId
    refundableItems {
      ...RefundableItems
    }
  }
}
    ${RefundableItemsFragmentDoc}`;
export const TicketInfoWithStatusFragmentDoc = gql`
    fragment TicketInfoWithStatus on Order {
  refireDelay
  isEmployee
  retailItems {
    id
    name
    quantity
    extraItems {
      id
      price
      quantity
      name
      extraSortId
      extraItemSortId
    }
  }
}
    `;
export const ExpoKitchenTicketFragmentDoc = gql`
    fragment ExpoKitchenTicket on KitchenTicket {
  id
  preparingUntil
  status
  refireReason
  refiredFromId
  delayTime
  kitchen {
    id
    name
  }
  station {
    id
    name
  }
  items {
    ...FacilityItemInfo
  }
}
    ${FacilityItemInfoFragmentDoc}`;
export const KdsExpoOrderFragmentDoc = gql`
    fragment KdsExpoOrder on Order {
  id
  status
  shortId
  refired
  method
  utensilsCount
  isVip
  isCatering
  kitchenPreparingUntil
  platform
  orderName
  orderPhoneNumber
  source
  fulfillmentAt
  fulfillmentBy
  isOrderNow
  preparingUntil
  note
  packager {
    id
    index
  }
  ...TicketInfoWithStatus
  ...CustomerTags
  kitchenTickets {
    ...ExpoKitchenTicket
  }
  refunds {
    ...OrderRefund
  }
  refundableItems {
    ...RefundableItems
  }
  platform
}
    ${TicketInfoWithStatusFragmentDoc}
${CustomerTagsFragmentDoc}
${ExpoKitchenTicketFragmentDoc}
${OrderRefundFragmentDoc}
${RefundableItemsFragmentDoc}`;
export const BundleShortInfoFragmentDoc = gql`
    fragment BundleShortInfo on OrderBundle {
  id
  shortId
  status
  deliveryStart
}
    `;
export const KdsRunnerOrderFragmentDoc = gql`
    fragment KdsRunnerOrder on Order {
  id
  method
  ...CustomerTags
  bundle {
    ...BundleShortInfo
  }
}
    ${CustomerTagsFragmentDoc}
${BundleShortInfoFragmentDoc}`;
export const KdsExpoRecentOrderFragmentDoc = gql`
    fragment KdsExpoRecentOrder on Order {
  id
  shortId
  utensilsCount
  platform
}
    `;
export const KdsOrderAssignmentOrderFragmentDoc = gql`
    fragment KdsOrderAssignmentOrder on Order {
  id
  shortId
  status
  packager {
    id
    index
  }
}
    `;
export const KdsPickupOrderFragmentDoc = gql`
    fragment KdsPickupOrder on Order {
  id
  shortId
  formattedAddress
  refireDelay
  status
  preparingUntil
  isEmployee
  isOrderNow
  fulfillmentAt
  isCatering
  isVip
  method
  source
  orderName
  orderPhoneNumber
  platform
  customer {
    id
    ordersCount
    tags {
      id
      name
    }
    user {
      id
      name
      phone
    }
  }
  items {
    id
    quantity
    name
    type
    orderMealPackId
    menuItem {
      id
      name
    }
    orderMealPack {
      id
      name
    }
  }
  pickup {
    id
    index
  }
}
    `;
export const KdsOrderItemFragmentDoc = gql`
    fragment KdsOrderItem on OrderItem {
  id
  quantity
  name
  menuItem {
    id
    isRetail
    name
    kitchen {
      id
      name
    }
  }
  extraItems {
    id
    price
    quantity
    name
    extraSortId
    extraItemSortId
  }
}
    `;
export const AssignmentTicketFragmentDoc = gql`
    fragment AssignmentTicket on Order {
  id
  status
  shortId
  refired
  method
  kitchenPreparingUntil
  orderPhoneNumber
  preparingUntil
  packager {
    id
    index
  }
  tags {
    id
    name
  }
  kitchenTickets {
    id
    status
    items {
      id
      quantity
    }
  }
}
    `;
export const PromoListInfoFragmentDoc = gql`
    fragment PromoListInfo on Promo {
  id
  code
  isAllKitchensAllFacilities
  method
  orderCount
  type
  typeDefinition
  requirements
  appliesTo
  eligibility
  usageLimit
  validSince
  validTill
  status
  promoFacilityRules {
    id
    facilityId
    isAllKitchens
    kitchens {
      id
      name
      facilityId
    }
  }
}
    `;
export const PromoDetailFragmentDoc = gql`
    fragment PromoDetail on Promo {
  id
  code
  isAllKitchensAllFacilities
  method
  orderCount
  type
  typeDefinition
  requirements
  requirementsDefinition
  appliesTo
  appliesToDefinition
  eligibility
  eligibilityDefinition
  usageLimit
  usageLimitDefinition
  validSince
  validTill
  status
}
    `;
export const PromoBannerFragmentDoc = gql`
    fragment PromoBanner on PromoBanner {
  id
  title
  color
  textColor
  description
  active
}
    `;
export const UserFragmentDoc = gql`
    fragment User on User {
  id
  name
  email
  roles
}
    `;
export const SearchMenuItemInfoFragmentDoc = gql`
    fragment SearchMenuItemInfo on KitchenMenuItem {
  id
  name
  price
  imgUrl
  sortId
  kitchen {
    id
    name
  }
}
    `;
export const KitchenMenuItemInfoFragmentDoc = gql`
    fragment KitchenMenuItemInfo on KitchenMenuItem {
  id
  name
  description
  longDescription
  price
  labels
  status
  sortId
  globalSortId
  imgUrl
  inventoryCount
  cookTime
  inventoryStatus
  enableInventoryCount
  inventoryPreSale
  availableFrom
  availableTo
  availability
  dishTypes
  isRetail
  allergies
  sortKitchenIds
  type
  stationId
  upsellMenuItems {
    upsellItem {
      ...SearchMenuItemInfo
    }
    upsellItemId
    id
  }
  extras {
    id
    name
  }
}
    ${SearchMenuItemInfoFragmentDoc}`;
export const KitchenShortInfoFragmentDoc = gql`
    fragment KitchenShortInfo on Kitchen {
  id
  name
  type
  status
  cookTime
  operationHours
  color
  facilityId
}
    `;
export const KitchenInfoFragmentDoc = gql`
    fragment KitchenInfo on Kitchen {
  id
  status
  name
  originalLocation
  phone
  email
  story
  logoUrl
  brandWebUrl
  headerImgUrl
  description
  cuisine
  type
  cookTime
  enableChowly
}
    `;
export const KitchenListInfoFragmentDoc = gql`
    fragment KitchenListInfo on Kitchen {
  id
  status
  name
  logoUrl
  cookTime
  menuItemCount
  sortId
  facilityId
}
    `;
export const ModifierInfoFragmentDoc = gql`
    fragment ModifierInfo on Extra {
  id
  name
  minimum
  maximum
  sortId
  defaultItemId
}
    `;
export const OrderRefundPreviewItemFragmentDoc = gql`
    fragment OrderRefundPreviewItem on OrderRefundPreviewItem {
  name
  orderItemId
  quantity
  price
}
    `;
export const OrderRefundPreviewFragmentDoc = gql`
    fragment OrderRefundPreview on OrderRefundPreview {
  createdAt
  type
  reason
  items {
    ...OrderRefundPreviewItem
  }
  calculations {
    creditCardRefund
    giftCardRefund
    total
    subtotal
    tax
    gratuity
    deliveryFee
    serviceFee
  }
}
    ${OrderRefundPreviewItemFragmentDoc}`;
export const MenuScheduleCategoryFragmentDoc = gql`
    fragment MenuScheduleCategory on MenuScheduleCategory {
  id
  category {
    id
    name
    categoryType
  }
  menuItems {
    id
    name
    availability
    status
    inventoryStatus
    enableInventoryCount
  }
  sortedMenuItemIds
}
    `;
export const MenuScheduleItemInfoFragmentDoc = gql`
    fragment MenuScheduleItemInfo on MenuSchedule {
  id
  name
  start
  end
  isActive
  isNonStop
  repeatOnDays
  sortedCategoryIds
}
    `;
export const MenuScheduleKitchenInfoFragmentDoc = gql`
    fragment MenuScheduleKitchenInfo on Kitchen {
  id
  name
  color
  status
  operationHours
  enableChowly
}
    `;
export const ScheduleCategoryInfoFragmentDoc = gql`
    fragment ScheduleCategoryInfo on MenuScheduleCategory {
  id
  sortedMenuItemIds
}
    `;
export const ScheduleCategoryMenuItemsFragmentDoc = gql`
    fragment ScheduleCategoryMenuItems on KitchenMenuItem {
  id
  name
  availability
  price
  status
}
    `;
export const OrderMethodOverrideInfoFragmentDoc = gql`
    fragment OrderMethodOverrideInfo on OrderMethodOverride {
  id
  name
  start
  end
  repeatOnDays
  disabledOrderMethods
  isActive
  isNonStop
}
    `;
export const MenuScheduleSpecialInfoFragmentDoc = gql`
    fragment MenuScheduleSpecialInfo on MenuSchedule {
  id
  name
  start
  end
  repeatOnDays
  isActive
  isNonStop
  sortedCategoryIds
}
    `;
export const DeleteUserDocument = gql`
    mutation DeleteUser($id: ID!) {
  adminDeletePortalUser(id: $id)
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const UpdateUserProfileDocument = gql`
    mutation UpdateUserProfile($input: UserProfileUpdateInput!) {
  userProfileUpdate(input: $input) {
    ...UserCustomerInfo
  }
}
    ${UserCustomerInfoFragmentDoc}`;
export type UpdateUserProfileMutationFn = Apollo.MutationFunction<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>;

/**
 * __useUpdateUserProfileMutation__
 *
 * To run a mutation, you first call `useUpdateUserProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserProfileMutation, { data, loading, error }] = useUpdateUserProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>(UpdateUserProfileDocument, options);
      }
export type UpdateUserProfileMutationHookResult = ReturnType<typeof useUpdateUserProfileMutation>;
export type UpdateUserProfileMutationResult = Apollo.MutationResult<UpdateUserProfileMutation>;
export type UpdateUserProfileMutationOptions = Apollo.BaseMutationOptions<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>;
export const UpdateUserOperationsDocument = gql`
    mutation UpdateUserOperations($input: UserOperationsUpdateInput!) {
  userOperationsUpdate(input: $input) {
    ...UserCustomerInfo
  }
}
    ${UserCustomerInfoFragmentDoc}`;
export type UpdateUserOperationsMutationFn = Apollo.MutationFunction<UpdateUserOperationsMutation, UpdateUserOperationsMutationVariables>;

/**
 * __useUpdateUserOperationsMutation__
 *
 * To run a mutation, you first call `useUpdateUserOperationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserOperationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserOperationsMutation, { data, loading, error }] = useUpdateUserOperationsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserOperationsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserOperationsMutation, UpdateUserOperationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserOperationsMutation, UpdateUserOperationsMutationVariables>(UpdateUserOperationsDocument, options);
      }
export type UpdateUserOperationsMutationHookResult = ReturnType<typeof useUpdateUserOperationsMutation>;
export type UpdateUserOperationsMutationResult = Apollo.MutationResult<UpdateUserOperationsMutation>;
export type UpdateUserOperationsMutationOptions = Apollo.BaseMutationOptions<UpdateUserOperationsMutation, UpdateUserOperationsMutationVariables>;
export const UserManagementListDocument = gql`
    query UserManagementList($orderBy: AdminPortalUserOrderByArg!, $nameFilter: String, $pagination: PaginationArg!) {
  platformUsers(
    orderBy: $orderBy
    nameFilter: $nameFilter
    pagination: $pagination
  ) {
    total
    adminUsers {
      ...PlatformUser
    }
  }
}
    ${PlatformUserFragmentDoc}`;

/**
 * __useUserManagementListQuery__
 *
 * To run a query within a React component, call `useUserManagementListQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserManagementListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserManagementListQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      nameFilter: // value for 'nameFilter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useUserManagementListQuery(baseOptions: Apollo.QueryHookOptions<UserManagementListQuery, UserManagementListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserManagementListQuery, UserManagementListQueryVariables>(UserManagementListDocument, options);
      }
export function useUserManagementListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserManagementListQuery, UserManagementListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserManagementListQuery, UserManagementListQueryVariables>(UserManagementListDocument, options);
        }
export type UserManagementListQueryHookResult = ReturnType<typeof useUserManagementListQuery>;
export type UserManagementListLazyQueryHookResult = ReturnType<typeof useUserManagementListLazyQuery>;
export type UserManagementListQueryResult = Apollo.QueryResult<UserManagementListQuery, UserManagementListQueryVariables>;
export const UserDetailsDocument = gql`
    query UserDetails($customerId: ID!) {
  adminPortalUser(id: $customerId) {
    ...UserCustomerInfo
  }
}
    ${UserCustomerInfoFragmentDoc}`;

/**
 * __useUserDetailsQuery__
 *
 * To run a query within a React component, call `useUserDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserDetailsQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useUserDetailsQuery(baseOptions: Apollo.QueryHookOptions<UserDetailsQuery, UserDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserDetailsQuery, UserDetailsQueryVariables>(UserDetailsDocument, options);
      }
export function useUserDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserDetailsQuery, UserDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserDetailsQuery, UserDetailsQueryVariables>(UserDetailsDocument, options);
        }
export type UserDetailsQueryHookResult = ReturnType<typeof useUserDetailsQuery>;
export type UserDetailsLazyQueryHookResult = ReturnType<typeof useUserDetailsLazyQuery>;
export type UserDetailsQueryResult = Apollo.QueryResult<UserDetailsQuery, UserDetailsQueryVariables>;
export const UpdateKitchenOperationHoursDocument = gql`
    mutation UpdateKitchenOperationHours($input: OperationHoursUpdateInput!) {
  adminUpdateKitchenOperationHours(input: $input) {
    id
    operationHours
  }
}
    `;
export type UpdateKitchenOperationHoursMutationFn = Apollo.MutationFunction<UpdateKitchenOperationHoursMutation, UpdateKitchenOperationHoursMutationVariables>;

/**
 * __useUpdateKitchenOperationHoursMutation__
 *
 * To run a mutation, you first call `useUpdateKitchenOperationHoursMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateKitchenOperationHoursMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateKitchenOperationHoursMutation, { data, loading, error }] = useUpdateKitchenOperationHoursMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateKitchenOperationHoursMutation(baseOptions?: Apollo.MutationHookOptions<UpdateKitchenOperationHoursMutation, UpdateKitchenOperationHoursMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateKitchenOperationHoursMutation, UpdateKitchenOperationHoursMutationVariables>(UpdateKitchenOperationHoursDocument, options);
      }
export type UpdateKitchenOperationHoursMutationHookResult = ReturnType<typeof useUpdateKitchenOperationHoursMutation>;
export type UpdateKitchenOperationHoursMutationResult = Apollo.MutationResult<UpdateKitchenOperationHoursMutation>;
export type UpdateKitchenOperationHoursMutationOptions = Apollo.BaseMutationOptions<UpdateKitchenOperationHoursMutation, UpdateKitchenOperationHoursMutationVariables>;
export const CreateKitchenMenuItemDocument = gql`
    mutation CreateKitchenMenuItem($data: KitchenMenuItemInput!) {
  adminCreateOneKitchenMenuItem(data: $data) {
    ...KitchenMenuItemInfo
    extras {
      id
      name
    }
    kitchen {
      id
    }
  }
}
    ${KitchenMenuItemInfoFragmentDoc}`;
export type CreateKitchenMenuItemMutationFn = Apollo.MutationFunction<CreateKitchenMenuItemMutation, CreateKitchenMenuItemMutationVariables>;

/**
 * __useCreateKitchenMenuItemMutation__
 *
 * To run a mutation, you first call `useCreateKitchenMenuItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateKitchenMenuItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createKitchenMenuItemMutation, { data, loading, error }] = useCreateKitchenMenuItemMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateKitchenMenuItemMutation(baseOptions?: Apollo.MutationHookOptions<CreateKitchenMenuItemMutation, CreateKitchenMenuItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateKitchenMenuItemMutation, CreateKitchenMenuItemMutationVariables>(CreateKitchenMenuItemDocument, options);
      }
export type CreateKitchenMenuItemMutationHookResult = ReturnType<typeof useCreateKitchenMenuItemMutation>;
export type CreateKitchenMenuItemMutationResult = Apollo.MutationResult<CreateKitchenMenuItemMutation>;
export type CreateKitchenMenuItemMutationOptions = Apollo.BaseMutationOptions<CreateKitchenMenuItemMutation, CreateKitchenMenuItemMutationVariables>;
export const UpdateKitchenMenuItemDocument = gql`
    mutation UpdateKitchenMenuItem($data: KitchenMenuItemInput!, $id: String!) {
  adminUpdateOneKitchenMenuItem(data: $data, id: $id) {
    ...KitchenMenuItemInfo
    kitchen {
      id
      name
    }
    extras {
      id
      name
    }
    mealPackItems {
      ...KitchenMenuItemShortInfo
      kitchen {
        id
        name
      }
    }
    menuCategories {
      ...MenuCategoryInfo
    }
  }
}
    ${KitchenMenuItemInfoFragmentDoc}
${KitchenMenuItemShortInfoFragmentDoc}
${MenuCategoryInfoFragmentDoc}`;
export type UpdateKitchenMenuItemMutationFn = Apollo.MutationFunction<UpdateKitchenMenuItemMutation, UpdateKitchenMenuItemMutationVariables>;

/**
 * __useUpdateKitchenMenuItemMutation__
 *
 * To run a mutation, you first call `useUpdateKitchenMenuItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateKitchenMenuItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateKitchenMenuItemMutation, { data, loading, error }] = useUpdateKitchenMenuItemMutation({
 *   variables: {
 *      data: // value for 'data'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateKitchenMenuItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateKitchenMenuItemMutation, UpdateKitchenMenuItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateKitchenMenuItemMutation, UpdateKitchenMenuItemMutationVariables>(UpdateKitchenMenuItemDocument, options);
      }
export type UpdateKitchenMenuItemMutationHookResult = ReturnType<typeof useUpdateKitchenMenuItemMutation>;
export type UpdateKitchenMenuItemMutationResult = Apollo.MutationResult<UpdateKitchenMenuItemMutation>;
export type UpdateKitchenMenuItemMutationOptions = Apollo.BaseMutationOptions<UpdateKitchenMenuItemMutation, UpdateKitchenMenuItemMutationVariables>;
export const UpdateKitchenMenuItemStatusDocument = gql`
    mutation UpdateKitchenMenuItemStatus($id: ID!, $status: KitchenMenuItemStatus!) {
  adminUpdateKitchenMenuItemStatus(id: $id, status: $status) {
    id
    status
    mealPackStatus
  }
}
    `;
export type UpdateKitchenMenuItemStatusMutationFn = Apollo.MutationFunction<UpdateKitchenMenuItemStatusMutation, UpdateKitchenMenuItemStatusMutationVariables>;

/**
 * __useUpdateKitchenMenuItemStatusMutation__
 *
 * To run a mutation, you first call `useUpdateKitchenMenuItemStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateKitchenMenuItemStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateKitchenMenuItemStatusMutation, { data, loading, error }] = useUpdateKitchenMenuItemStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateKitchenMenuItemStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateKitchenMenuItemStatusMutation, UpdateKitchenMenuItemStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateKitchenMenuItemStatusMutation, UpdateKitchenMenuItemStatusMutationVariables>(UpdateKitchenMenuItemStatusDocument, options);
      }
export type UpdateKitchenMenuItemStatusMutationHookResult = ReturnType<typeof useUpdateKitchenMenuItemStatusMutation>;
export type UpdateKitchenMenuItemStatusMutationResult = Apollo.MutationResult<UpdateKitchenMenuItemStatusMutation>;
export type UpdateKitchenMenuItemStatusMutationOptions = Apollo.BaseMutationOptions<UpdateKitchenMenuItemStatusMutation, UpdateKitchenMenuItemStatusMutationVariables>;
export const UpdateKitchenMenuItemInventoryDocument = gql`
    mutation UpdateKitchenMenuItemInventory($id: ID!, $inventoryCount: Int!) {
  adminUpdateKitchenMenuItemInventory(id: $id, inventoryCount: $inventoryCount) {
    id
    inventoryCount
    status
  }
}
    `;
export type UpdateKitchenMenuItemInventoryMutationFn = Apollo.MutationFunction<UpdateKitchenMenuItemInventoryMutation, UpdateKitchenMenuItemInventoryMutationVariables>;

/**
 * __useUpdateKitchenMenuItemInventoryMutation__
 *
 * To run a mutation, you first call `useUpdateKitchenMenuItemInventoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateKitchenMenuItemInventoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateKitchenMenuItemInventoryMutation, { data, loading, error }] = useUpdateKitchenMenuItemInventoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      inventoryCount: // value for 'inventoryCount'
 *   },
 * });
 */
export function useUpdateKitchenMenuItemInventoryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateKitchenMenuItemInventoryMutation, UpdateKitchenMenuItemInventoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateKitchenMenuItemInventoryMutation, UpdateKitchenMenuItemInventoryMutationVariables>(UpdateKitchenMenuItemInventoryDocument, options);
      }
export type UpdateKitchenMenuItemInventoryMutationHookResult = ReturnType<typeof useUpdateKitchenMenuItemInventoryMutation>;
export type UpdateKitchenMenuItemInventoryMutationResult = Apollo.MutationResult<UpdateKitchenMenuItemInventoryMutation>;
export type UpdateKitchenMenuItemInventoryMutationOptions = Apollo.BaseMutationOptions<UpdateKitchenMenuItemInventoryMutation, UpdateKitchenMenuItemInventoryMutationVariables>;
export const DeleteKitchenMenuItemDocument = gql`
    mutation DeleteKitchenMenuItem($id: ID!) {
  adminDeleteOneKitchenMenuItem(id: $id) {
    id
    status
  }
}
    `;
export type DeleteKitchenMenuItemMutationFn = Apollo.MutationFunction<DeleteKitchenMenuItemMutation, DeleteKitchenMenuItemMutationVariables>;

/**
 * __useDeleteKitchenMenuItemMutation__
 *
 * To run a mutation, you first call `useDeleteKitchenMenuItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteKitchenMenuItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteKitchenMenuItemMutation, { data, loading, error }] = useDeleteKitchenMenuItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteKitchenMenuItemMutation(baseOptions?: Apollo.MutationHookOptions<DeleteKitchenMenuItemMutation, DeleteKitchenMenuItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteKitchenMenuItemMutation, DeleteKitchenMenuItemMutationVariables>(DeleteKitchenMenuItemDocument, options);
      }
export type DeleteKitchenMenuItemMutationHookResult = ReturnType<typeof useDeleteKitchenMenuItemMutation>;
export type DeleteKitchenMenuItemMutationResult = Apollo.MutationResult<DeleteKitchenMenuItemMutation>;
export type DeleteKitchenMenuItemMutationOptions = Apollo.BaseMutationOptions<DeleteKitchenMenuItemMutation, DeleteKitchenMenuItemMutationVariables>;
export const UpsertKitchenDocument = gql`
    mutation UpsertKitchen($input: KitchenUpsertInput!) {
  adminUpsertKitchen(input: $input) {
    ...KitchenInfo
  }
}
    ${KitchenInfoFragmentDoc}`;
export type UpsertKitchenMutationFn = Apollo.MutationFunction<UpsertKitchenMutation, UpsertKitchenMutationVariables>;

/**
 * __useUpsertKitchenMutation__
 *
 * To run a mutation, you first call `useUpsertKitchenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertKitchenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertKitchenMutation, { data, loading, error }] = useUpsertKitchenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertKitchenMutation(baseOptions?: Apollo.MutationHookOptions<UpsertKitchenMutation, UpsertKitchenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertKitchenMutation, UpsertKitchenMutationVariables>(UpsertKitchenDocument, options);
      }
export type UpsertKitchenMutationHookResult = ReturnType<typeof useUpsertKitchenMutation>;
export type UpsertKitchenMutationResult = Apollo.MutationResult<UpsertKitchenMutation>;
export type UpsertKitchenMutationOptions = Apollo.BaseMutationOptions<UpsertKitchenMutation, UpsertKitchenMutationVariables>;
export const DeleteKitchenDocument = gql`
    mutation DeleteKitchen($id: ID!) {
  adminDeleteKitchen(id: $id) {
    id
  }
}
    `;
export type DeleteKitchenMutationFn = Apollo.MutationFunction<DeleteKitchenMutation, DeleteKitchenMutationVariables>;

/**
 * __useDeleteKitchenMutation__
 *
 * To run a mutation, you first call `useDeleteKitchenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteKitchenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteKitchenMutation, { data, loading, error }] = useDeleteKitchenMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteKitchenMutation(baseOptions?: Apollo.MutationHookOptions<DeleteKitchenMutation, DeleteKitchenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteKitchenMutation, DeleteKitchenMutationVariables>(DeleteKitchenDocument, options);
      }
export type DeleteKitchenMutationHookResult = ReturnType<typeof useDeleteKitchenMutation>;
export type DeleteKitchenMutationResult = Apollo.MutationResult<DeleteKitchenMutation>;
export type DeleteKitchenMutationOptions = Apollo.BaseMutationOptions<DeleteKitchenMutation, DeleteKitchenMutationVariables>;
export const SortKitchenItemsDocument = gql`
    mutation SortKitchenItems($input: [String!]!) {
  adminUpdateSort(input: $input)
}
    `;
export type SortKitchenItemsMutationFn = Apollo.MutationFunction<SortKitchenItemsMutation, SortKitchenItemsMutationVariables>;

/**
 * __useSortKitchenItemsMutation__
 *
 * To run a mutation, you first call `useSortKitchenItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSortKitchenItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sortKitchenItemsMutation, { data, loading, error }] = useSortKitchenItemsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSortKitchenItemsMutation(baseOptions?: Apollo.MutationHookOptions<SortKitchenItemsMutation, SortKitchenItemsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SortKitchenItemsMutation, SortKitchenItemsMutationVariables>(SortKitchenItemsDocument, options);
      }
export type SortKitchenItemsMutationHookResult = ReturnType<typeof useSortKitchenItemsMutation>;
export type SortKitchenItemsMutationResult = Apollo.MutationResult<SortKitchenItemsMutation>;
export type SortKitchenItemsMutationOptions = Apollo.BaseMutationOptions<SortKitchenItemsMutation, SortKitchenItemsMutationVariables>;
export const SortCraveMenuItemsDocument = gql`
    mutation SortCraveMenuItems($input: [String!]!) {
  adminUpdateGlobalSort(input: $input)
}
    `;
export type SortCraveMenuItemsMutationFn = Apollo.MutationFunction<SortCraveMenuItemsMutation, SortCraveMenuItemsMutationVariables>;

/**
 * __useSortCraveMenuItemsMutation__
 *
 * To run a mutation, you first call `useSortCraveMenuItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSortCraveMenuItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sortCraveMenuItemsMutation, { data, loading, error }] = useSortCraveMenuItemsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSortCraveMenuItemsMutation(baseOptions?: Apollo.MutationHookOptions<SortCraveMenuItemsMutation, SortCraveMenuItemsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SortCraveMenuItemsMutation, SortCraveMenuItemsMutationVariables>(SortCraveMenuItemsDocument, options);
      }
export type SortCraveMenuItemsMutationHookResult = ReturnType<typeof useSortCraveMenuItemsMutation>;
export type SortCraveMenuItemsMutationResult = Apollo.MutationResult<SortCraveMenuItemsMutation>;
export type SortCraveMenuItemsMutationOptions = Apollo.BaseMutationOptions<SortCraveMenuItemsMutation, SortCraveMenuItemsMutationVariables>;
export const UpsertModifierDocument = gql`
    mutation UpsertModifier($input: ExtraUpsertInput!) {
  adminUpsertModifier(input: $input) {
    ...ModifierInfo
  }
}
    ${ModifierInfoFragmentDoc}`;
export type UpsertModifierMutationFn = Apollo.MutationFunction<UpsertModifierMutation, UpsertModifierMutationVariables>;

/**
 * __useUpsertModifierMutation__
 *
 * To run a mutation, you first call `useUpsertModifierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertModifierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertModifierMutation, { data, loading, error }] = useUpsertModifierMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertModifierMutation(baseOptions?: Apollo.MutationHookOptions<UpsertModifierMutation, UpsertModifierMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertModifierMutation, UpsertModifierMutationVariables>(UpsertModifierDocument, options);
      }
export type UpsertModifierMutationHookResult = ReturnType<typeof useUpsertModifierMutation>;
export type UpsertModifierMutationResult = Apollo.MutationResult<UpsertModifierMutation>;
export type UpsertModifierMutationOptions = Apollo.BaseMutationOptions<UpsertModifierMutation, UpsertModifierMutationVariables>;
export const SortModifierGroupsDocument = gql`
    mutation SortModifierGroups($input: [String!]!) {
  adminSortModifierGroups(input: $input)
}
    `;
export type SortModifierGroupsMutationFn = Apollo.MutationFunction<SortModifierGroupsMutation, SortModifierGroupsMutationVariables>;

/**
 * __useSortModifierGroupsMutation__
 *
 * To run a mutation, you first call `useSortModifierGroupsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSortModifierGroupsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sortModifierGroupsMutation, { data, loading, error }] = useSortModifierGroupsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSortModifierGroupsMutation(baseOptions?: Apollo.MutationHookOptions<SortModifierGroupsMutation, SortModifierGroupsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SortModifierGroupsMutation, SortModifierGroupsMutationVariables>(SortModifierGroupsDocument, options);
      }
export type SortModifierGroupsMutationHookResult = ReturnType<typeof useSortModifierGroupsMutation>;
export type SortModifierGroupsMutationResult = Apollo.MutationResult<SortModifierGroupsMutation>;
export type SortModifierGroupsMutationOptions = Apollo.BaseMutationOptions<SortModifierGroupsMutation, SortModifierGroupsMutationVariables>;
export const UpdateDefaultChoiceDocument = gql`
    mutation UpdateDefaultChoice($id: ID!, $defaultItemId: String!) {
  adminUpdateModifierDefaultChoice(id: $id, defaultItemId: $defaultItemId) {
    ...ModifierInfo
  }
}
    ${ModifierInfoFragmentDoc}`;
export type UpdateDefaultChoiceMutationFn = Apollo.MutationFunction<UpdateDefaultChoiceMutation, UpdateDefaultChoiceMutationVariables>;

/**
 * __useUpdateDefaultChoiceMutation__
 *
 * To run a mutation, you first call `useUpdateDefaultChoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDefaultChoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDefaultChoiceMutation, { data, loading, error }] = useUpdateDefaultChoiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      defaultItemId: // value for 'defaultItemId'
 *   },
 * });
 */
export function useUpdateDefaultChoiceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDefaultChoiceMutation, UpdateDefaultChoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDefaultChoiceMutation, UpdateDefaultChoiceMutationVariables>(UpdateDefaultChoiceDocument, options);
      }
export type UpdateDefaultChoiceMutationHookResult = ReturnType<typeof useUpdateDefaultChoiceMutation>;
export type UpdateDefaultChoiceMutationResult = Apollo.MutationResult<UpdateDefaultChoiceMutation>;
export type UpdateDefaultChoiceMutationOptions = Apollo.BaseMutationOptions<UpdateDefaultChoiceMutation, UpdateDefaultChoiceMutationVariables>;
export const SortModifierChoicesDocument = gql`
    mutation SortModifierChoices($input: [String!]!) {
  adminSortModifierChoices(input: $input) {
    id
    name
    sortId
    createdAt
    isActive
    price
  }
}
    `;
export type SortModifierChoicesMutationFn = Apollo.MutationFunction<SortModifierChoicesMutation, SortModifierChoicesMutationVariables>;

/**
 * __useSortModifierChoicesMutation__
 *
 * To run a mutation, you first call `useSortModifierChoicesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSortModifierChoicesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sortModifierChoicesMutation, { data, loading, error }] = useSortModifierChoicesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSortModifierChoicesMutation(baseOptions?: Apollo.MutationHookOptions<SortModifierChoicesMutation, SortModifierChoicesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SortModifierChoicesMutation, SortModifierChoicesMutationVariables>(SortModifierChoicesDocument, options);
      }
export type SortModifierChoicesMutationHookResult = ReturnType<typeof useSortModifierChoicesMutation>;
export type SortModifierChoicesMutationResult = Apollo.MutationResult<SortModifierChoicesMutation>;
export type SortModifierChoicesMutationOptions = Apollo.BaseMutationOptions<SortModifierChoicesMutation, SortModifierChoicesMutationVariables>;
export const UpdateChoiceStatusDocument = gql`
    mutation UpdateChoiceStatus($id: ID!, $isActive: Boolean!) {
  adminUpdateChoiceStatus(id: $id, isActive: $isActive) {
    id
    isActive
  }
}
    `;
export type UpdateChoiceStatusMutationFn = Apollo.MutationFunction<UpdateChoiceStatusMutation, UpdateChoiceStatusMutationVariables>;

/**
 * __useUpdateChoiceStatusMutation__
 *
 * To run a mutation, you first call `useUpdateChoiceStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChoiceStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChoiceStatusMutation, { data, loading, error }] = useUpdateChoiceStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isActive: // value for 'isActive'
 *   },
 * });
 */
export function useUpdateChoiceStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateChoiceStatusMutation, UpdateChoiceStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateChoiceStatusMutation, UpdateChoiceStatusMutationVariables>(UpdateChoiceStatusDocument, options);
      }
export type UpdateChoiceStatusMutationHookResult = ReturnType<typeof useUpdateChoiceStatusMutation>;
export type UpdateChoiceStatusMutationResult = Apollo.MutationResult<UpdateChoiceStatusMutation>;
export type UpdateChoiceStatusMutationOptions = Apollo.BaseMutationOptions<UpdateChoiceStatusMutation, UpdateChoiceStatusMutationVariables>;
export const DeleteModifierGroupDocument = gql`
    mutation DeleteModifierGroup($id: ID!) {
  adminDeleteModifierGroup(id: $id) {
    id
  }
}
    `;
export type DeleteModifierGroupMutationFn = Apollo.MutationFunction<DeleteModifierGroupMutation, DeleteModifierGroupMutationVariables>;

/**
 * __useDeleteModifierGroupMutation__
 *
 * To run a mutation, you first call `useDeleteModifierGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteModifierGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteModifierGroupMutation, { data, loading, error }] = useDeleteModifierGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteModifierGroupMutation(baseOptions?: Apollo.MutationHookOptions<DeleteModifierGroupMutation, DeleteModifierGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteModifierGroupMutation, DeleteModifierGroupMutationVariables>(DeleteModifierGroupDocument, options);
      }
export type DeleteModifierGroupMutationHookResult = ReturnType<typeof useDeleteModifierGroupMutation>;
export type DeleteModifierGroupMutationResult = Apollo.MutationResult<DeleteModifierGroupMutation>;
export type DeleteModifierGroupMutationOptions = Apollo.BaseMutationOptions<DeleteModifierGroupMutation, DeleteModifierGroupMutationVariables>;
export const DeleteModifierChoiceDocument = gql`
    mutation DeleteModifierChoice($id: ID!) {
  adminDeleteModifierChoice(id: $id) {
    id
  }
}
    `;
export type DeleteModifierChoiceMutationFn = Apollo.MutationFunction<DeleteModifierChoiceMutation, DeleteModifierChoiceMutationVariables>;

/**
 * __useDeleteModifierChoiceMutation__
 *
 * To run a mutation, you first call `useDeleteModifierChoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteModifierChoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteModifierChoiceMutation, { data, loading, error }] = useDeleteModifierChoiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteModifierChoiceMutation(baseOptions?: Apollo.MutationHookOptions<DeleteModifierChoiceMutation, DeleteModifierChoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteModifierChoiceMutation, DeleteModifierChoiceMutationVariables>(DeleteModifierChoiceDocument, options);
      }
export type DeleteModifierChoiceMutationHookResult = ReturnType<typeof useDeleteModifierChoiceMutation>;
export type DeleteModifierChoiceMutationResult = Apollo.MutationResult<DeleteModifierChoiceMutation>;
export type DeleteModifierChoiceMutationOptions = Apollo.BaseMutationOptions<DeleteModifierChoiceMutation, DeleteModifierChoiceMutationVariables>;
export const MenuCategoryCreateDocument = gql`
    mutation MenuCategoryCreate($input: MenuCategoryCreateInput!) {
  adminCreateMenuCategory(input: $input) {
    ...MenuCategoryInfo
  }
}
    ${MenuCategoryInfoFragmentDoc}`;
export type MenuCategoryCreateMutationFn = Apollo.MutationFunction<MenuCategoryCreateMutation, MenuCategoryCreateMutationVariables>;

/**
 * __useMenuCategoryCreateMutation__
 *
 * To run a mutation, you first call `useMenuCategoryCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMenuCategoryCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [menuCategoryCreateMutation, { data, loading, error }] = useMenuCategoryCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMenuCategoryCreateMutation(baseOptions?: Apollo.MutationHookOptions<MenuCategoryCreateMutation, MenuCategoryCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MenuCategoryCreateMutation, MenuCategoryCreateMutationVariables>(MenuCategoryCreateDocument, options);
      }
export type MenuCategoryCreateMutationHookResult = ReturnType<typeof useMenuCategoryCreateMutation>;
export type MenuCategoryCreateMutationResult = Apollo.MutationResult<MenuCategoryCreateMutation>;
export type MenuCategoryCreateMutationOptions = Apollo.BaseMutationOptions<MenuCategoryCreateMutation, MenuCategoryCreateMutationVariables>;
export const ReorderBrandsDocument = gql`
    mutation ReorderBrands($input: AdminReorderKitchenInput!) {
  adminReorderKitchen(input: $input) {
    id
    sortId
  }
}
    `;
export type ReorderBrandsMutationFn = Apollo.MutationFunction<ReorderBrandsMutation, ReorderBrandsMutationVariables>;

/**
 * __useReorderBrandsMutation__
 *
 * To run a mutation, you first call `useReorderBrandsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReorderBrandsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reorderBrandsMutation, { data, loading, error }] = useReorderBrandsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReorderBrandsMutation(baseOptions?: Apollo.MutationHookOptions<ReorderBrandsMutation, ReorderBrandsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReorderBrandsMutation, ReorderBrandsMutationVariables>(ReorderBrandsDocument, options);
      }
export type ReorderBrandsMutationHookResult = ReturnType<typeof useReorderBrandsMutation>;
export type ReorderBrandsMutationResult = Apollo.MutationResult<ReorderBrandsMutation>;
export type ReorderBrandsMutationOptions = Apollo.BaseMutationOptions<ReorderBrandsMutation, ReorderBrandsMutationVariables>;
export const OrderRefundCreateDocument = gql`
    mutation OrderRefundCreate($request: RefundInput!) {
  orderRefundCreate(request: $request) {
    ...OrderRefund
  }
}
    ${OrderRefundFragmentDoc}`;
export type OrderRefundCreateMutationFn = Apollo.MutationFunction<OrderRefundCreateMutation, OrderRefundCreateMutationVariables>;

/**
 * __useOrderRefundCreateMutation__
 *
 * To run a mutation, you first call `useOrderRefundCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderRefundCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderRefundCreateMutation, { data, loading, error }] = useOrderRefundCreateMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useOrderRefundCreateMutation(baseOptions?: Apollo.MutationHookOptions<OrderRefundCreateMutation, OrderRefundCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrderRefundCreateMutation, OrderRefundCreateMutationVariables>(OrderRefundCreateDocument, options);
      }
export type OrderRefundCreateMutationHookResult = ReturnType<typeof useOrderRefundCreateMutation>;
export type OrderRefundCreateMutationResult = Apollo.MutationResult<OrderRefundCreateMutation>;
export type OrderRefundCreateMutationOptions = Apollo.BaseMutationOptions<OrderRefundCreateMutation, OrderRefundCreateMutationVariables>;
export const KitchenMenuItemDocument = gql`
    query KitchenMenuItem($id: ID!) {
  adminKitchenMenuItem(id: $id) {
    ...KitchenMenuItemInfo
    station {
      id
      name
    }
    menuCategories {
      ...MenuCategoryInfo
    }
    extras {
      id
      name
    }
  }
}
    ${KitchenMenuItemInfoFragmentDoc}
${MenuCategoryInfoFragmentDoc}`;

/**
 * __useKitchenMenuItemQuery__
 *
 * To run a query within a React component, call `useKitchenMenuItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useKitchenMenuItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKitchenMenuItemQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useKitchenMenuItemQuery(baseOptions: Apollo.QueryHookOptions<KitchenMenuItemQuery, KitchenMenuItemQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<KitchenMenuItemQuery, KitchenMenuItemQueryVariables>(KitchenMenuItemDocument, options);
      }
export function useKitchenMenuItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KitchenMenuItemQuery, KitchenMenuItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<KitchenMenuItemQuery, KitchenMenuItemQueryVariables>(KitchenMenuItemDocument, options);
        }
export type KitchenMenuItemQueryHookResult = ReturnType<typeof useKitchenMenuItemQuery>;
export type KitchenMenuItemLazyQueryHookResult = ReturnType<typeof useKitchenMenuItemLazyQuery>;
export type KitchenMenuItemQueryResult = Apollo.QueryResult<KitchenMenuItemQuery, KitchenMenuItemQueryVariables>;
export const KitchensDocument = gql`
    query Kitchens {
  adminKitchens {
    ...KitchenInfo
    kitchenMenuItems {
      ...KitchenMenuItemInfo
      menuCategories {
        id
        name
        categoryType
      }
    }
  }
}
    ${KitchenInfoFragmentDoc}
${KitchenMenuItemInfoFragmentDoc}`;

/**
 * __useKitchensQuery__
 *
 * To run a query within a React component, call `useKitchensQuery` and pass it any options that fit your needs.
 * When your component renders, `useKitchensQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKitchensQuery({
 *   variables: {
 *   },
 * });
 */
export function useKitchensQuery(baseOptions?: Apollo.QueryHookOptions<KitchensQuery, KitchensQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<KitchensQuery, KitchensQueryVariables>(KitchensDocument, options);
      }
export function useKitchensLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KitchensQuery, KitchensQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<KitchensQuery, KitchensQueryVariables>(KitchensDocument, options);
        }
export type KitchensQueryHookResult = ReturnType<typeof useKitchensQuery>;
export type KitchensLazyQueryHookResult = ReturnType<typeof useKitchensLazyQuery>;
export type KitchensQueryResult = Apollo.QueryResult<KitchensQuery, KitchensQueryVariables>;
export const KitchenListDocument = gql`
    query KitchenList {
  adminKitchens {
    ...KitchenListInfo
  }
}
    ${KitchenListInfoFragmentDoc}`;

/**
 * __useKitchenListQuery__
 *
 * To run a query within a React component, call `useKitchenListQuery` and pass it any options that fit your needs.
 * When your component renders, `useKitchenListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKitchenListQuery({
 *   variables: {
 *   },
 * });
 */
export function useKitchenListQuery(baseOptions?: Apollo.QueryHookOptions<KitchenListQuery, KitchenListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<KitchenListQuery, KitchenListQueryVariables>(KitchenListDocument, options);
      }
export function useKitchenListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KitchenListQuery, KitchenListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<KitchenListQuery, KitchenListQueryVariables>(KitchenListDocument, options);
        }
export type KitchenListQueryHookResult = ReturnType<typeof useKitchenListQuery>;
export type KitchenListLazyQueryHookResult = ReturnType<typeof useKitchenListLazyQuery>;
export type KitchenListQueryResult = Apollo.QueryResult<KitchenListQuery, KitchenListQueryVariables>;
export const FacilityListDocument = gql`
    query FacilityList {
  adminFacilities {
    id
    name
  }
}
    `;

/**
 * __useFacilityListQuery__
 *
 * To run a query within a React component, call `useFacilityListQuery` and pass it any options that fit your needs.
 * When your component renders, `useFacilityListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFacilityListQuery({
 *   variables: {
 *   },
 * });
 */
export function useFacilityListQuery(baseOptions?: Apollo.QueryHookOptions<FacilityListQuery, FacilityListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FacilityListQuery, FacilityListQueryVariables>(FacilityListDocument, options);
      }
export function useFacilityListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FacilityListQuery, FacilityListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FacilityListQuery, FacilityListQueryVariables>(FacilityListDocument, options);
        }
export type FacilityListQueryHookResult = ReturnType<typeof useFacilityListQuery>;
export type FacilityListLazyQueryHookResult = ReturnType<typeof useFacilityListLazyQuery>;
export type FacilityListQueryResult = Apollo.QueryResult<FacilityListQuery, FacilityListQueryVariables>;
export const KitchensShortDocument = gql`
    query KitchensShort($activeKitchens: Boolean) {
  adminKitchens(activeKitchens: $activeKitchens) {
    ...KitchenShortInfo
  }
}
    ${KitchenShortInfoFragmentDoc}`;

/**
 * __useKitchensShortQuery__
 *
 * To run a query within a React component, call `useKitchensShortQuery` and pass it any options that fit your needs.
 * When your component renders, `useKitchensShortQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKitchensShortQuery({
 *   variables: {
 *      activeKitchens: // value for 'activeKitchens'
 *   },
 * });
 */
export function useKitchensShortQuery(baseOptions?: Apollo.QueryHookOptions<KitchensShortQuery, KitchensShortQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<KitchensShortQuery, KitchensShortQueryVariables>(KitchensShortDocument, options);
      }
export function useKitchensShortLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KitchensShortQuery, KitchensShortQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<KitchensShortQuery, KitchensShortQueryVariables>(KitchensShortDocument, options);
        }
export type KitchensShortQueryHookResult = ReturnType<typeof useKitchensShortQuery>;
export type KitchensShortLazyQueryHookResult = ReturnType<typeof useKitchensShortLazyQuery>;
export type KitchensShortQueryResult = Apollo.QueryResult<KitchensShortQuery, KitchensShortQueryVariables>;
export const KitchenOptionsDocument = gql`
    query KitchenOptions {
  adminKitchens {
    id
    name
    status
    operationHours
  }
}
    `;

/**
 * __useKitchenOptionsQuery__
 *
 * To run a query within a React component, call `useKitchenOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useKitchenOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKitchenOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useKitchenOptionsQuery(baseOptions?: Apollo.QueryHookOptions<KitchenOptionsQuery, KitchenOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<KitchenOptionsQuery, KitchenOptionsQueryVariables>(KitchenOptionsDocument, options);
      }
export function useKitchenOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KitchenOptionsQuery, KitchenOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<KitchenOptionsQuery, KitchenOptionsQueryVariables>(KitchenOptionsDocument, options);
        }
export type KitchenOptionsQueryHookResult = ReturnType<typeof useKitchenOptionsQuery>;
export type KitchenOptionsLazyQueryHookResult = ReturnType<typeof useKitchenOptionsLazyQuery>;
export type KitchenOptionsQueryResult = Apollo.QueryResult<KitchenOptionsQuery, KitchenOptionsQueryVariables>;
export const KitchenDocument = gql`
    query Kitchen($kitchenId: ID!) {
  adminKitchen(kitchenId: $kitchenId) {
    ...KitchenInfo
    kitchenMenuItems {
      ...KitchenMenuItemInfo
      menuCategories {
        id
        name
        categoryType
      }
    }
    workplace {
      id
    }
    owners {
      id
      name
    }
  }
}
    ${KitchenInfoFragmentDoc}
${KitchenMenuItemInfoFragmentDoc}`;

/**
 * __useKitchenQuery__
 *
 * To run a query within a React component, call `useKitchenQuery` and pass it any options that fit your needs.
 * When your component renders, `useKitchenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKitchenQuery({
 *   variables: {
 *      kitchenId: // value for 'kitchenId'
 *   },
 * });
 */
export function useKitchenQuery(baseOptions: Apollo.QueryHookOptions<KitchenQuery, KitchenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<KitchenQuery, KitchenQueryVariables>(KitchenDocument, options);
      }
export function useKitchenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KitchenQuery, KitchenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<KitchenQuery, KitchenQueryVariables>(KitchenDocument, options);
        }
export type KitchenQueryHookResult = ReturnType<typeof useKitchenQuery>;
export type KitchenLazyQueryHookResult = ReturnType<typeof useKitchenLazyQuery>;
export type KitchenQueryResult = Apollo.QueryResult<KitchenQuery, KitchenQueryVariables>;
export const KitchenShortDocument = gql`
    query KitchenShort($kitchenId: ID!) {
  adminKitchen(kitchenId: $kitchenId) {
    ...KitchenShortInfo
  }
}
    ${KitchenShortInfoFragmentDoc}`;

/**
 * __useKitchenShortQuery__
 *
 * To run a query within a React component, call `useKitchenShortQuery` and pass it any options that fit your needs.
 * When your component renders, `useKitchenShortQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKitchenShortQuery({
 *   variables: {
 *      kitchenId: // value for 'kitchenId'
 *   },
 * });
 */
export function useKitchenShortQuery(baseOptions: Apollo.QueryHookOptions<KitchenShortQuery, KitchenShortQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<KitchenShortQuery, KitchenShortQueryVariables>(KitchenShortDocument, options);
      }
export function useKitchenShortLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KitchenShortQuery, KitchenShortQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<KitchenShortQuery, KitchenShortQueryVariables>(KitchenShortDocument, options);
        }
export type KitchenShortQueryHookResult = ReturnType<typeof useKitchenShortQuery>;
export type KitchenShortLazyQueryHookResult = ReturnType<typeof useKitchenShortLazyQuery>;
export type KitchenShortQueryResult = Apollo.QueryResult<KitchenShortQuery, KitchenShortQueryVariables>;
export const AllKitchensDocument = gql`
    query AllKitchens {
  adminAllKitchens {
    ...KitchenShortInfo
  }
}
    ${KitchenShortInfoFragmentDoc}`;

/**
 * __useAllKitchensQuery__
 *
 * To run a query within a React component, call `useAllKitchensQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllKitchensQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllKitchensQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllKitchensQuery(baseOptions?: Apollo.QueryHookOptions<AllKitchensQuery, AllKitchensQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllKitchensQuery, AllKitchensQueryVariables>(AllKitchensDocument, options);
      }
export function useAllKitchensLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllKitchensQuery, AllKitchensQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllKitchensQuery, AllKitchensQueryVariables>(AllKitchensDocument, options);
        }
export type AllKitchensQueryHookResult = ReturnType<typeof useAllKitchensQuery>;
export type AllKitchensLazyQueryHookResult = ReturnType<typeof useAllKitchensLazyQuery>;
export type AllKitchensQueryResult = Apollo.QueryResult<AllKitchensQuery, AllKitchensQueryVariables>;
export const KitchenModifiersDocument = gql`
    query KitchenModifiers($kitchenId: ID!) {
  adminExtras(kitchenId: $kitchenId) {
    id
    name
    required
    singleOption
    minimum
    maximum
    sortId
    defaultItemId
    createdAt
    kitchenMenuItems {
      id
      name
      status
    }
    items {
      id
      name
      price
      isActive
      sortId
      createdAt
    }
  }
}
    `;

/**
 * __useKitchenModifiersQuery__
 *
 * To run a query within a React component, call `useKitchenModifiersQuery` and pass it any options that fit your needs.
 * When your component renders, `useKitchenModifiersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKitchenModifiersQuery({
 *   variables: {
 *      kitchenId: // value for 'kitchenId'
 *   },
 * });
 */
export function useKitchenModifiersQuery(baseOptions: Apollo.QueryHookOptions<KitchenModifiersQuery, KitchenModifiersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<KitchenModifiersQuery, KitchenModifiersQueryVariables>(KitchenModifiersDocument, options);
      }
export function useKitchenModifiersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KitchenModifiersQuery, KitchenModifiersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<KitchenModifiersQuery, KitchenModifiersQueryVariables>(KitchenModifiersDocument, options);
        }
export type KitchenModifiersQueryHookResult = ReturnType<typeof useKitchenModifiersQuery>;
export type KitchenModifiersLazyQueryHookResult = ReturnType<typeof useKitchenModifiersLazyQuery>;
export type KitchenModifiersQueryResult = Apollo.QueryResult<KitchenModifiersQuery, KitchenModifiersQueryVariables>;
export const KitchenModifierDocument = gql`
    query KitchenModifier($modifierId: ID!) {
  adminKitchenModifier(id: $modifierId) {
    id
    name
    required
    singleOption
    minimum
    maximum
    sortId
    defaultItemId
    createdAt
    kitchenMenuItems {
      id
      name
      status
    }
    items {
      id
      name
      price
      isActive
      sortId
      createdAt
    }
  }
}
    `;

/**
 * __useKitchenModifierQuery__
 *
 * To run a query within a React component, call `useKitchenModifierQuery` and pass it any options that fit your needs.
 * When your component renders, `useKitchenModifierQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKitchenModifierQuery({
 *   variables: {
 *      modifierId: // value for 'modifierId'
 *   },
 * });
 */
export function useKitchenModifierQuery(baseOptions: Apollo.QueryHookOptions<KitchenModifierQuery, KitchenModifierQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<KitchenModifierQuery, KitchenModifierQueryVariables>(KitchenModifierDocument, options);
      }
export function useKitchenModifierLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KitchenModifierQuery, KitchenModifierQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<KitchenModifierQuery, KitchenModifierQueryVariables>(KitchenModifierDocument, options);
        }
export type KitchenModifierQueryHookResult = ReturnType<typeof useKitchenModifierQuery>;
export type KitchenModifierLazyQueryHookResult = ReturnType<typeof useKitchenModifierLazyQuery>;
export type KitchenModifierQueryResult = Apollo.QueryResult<KitchenModifierQuery, KitchenModifierQueryVariables>;
export const OrderRefundPreviewDocument = gql`
    query OrderRefundPreview($request: RefundInput!) {
  orderRefundPreview(request: $request) {
    ...OrderRefundPreview
  }
}
    ${OrderRefundPreviewFragmentDoc}`;

/**
 * __useOrderRefundPreviewQuery__
 *
 * To run a query within a React component, call `useOrderRefundPreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderRefundPreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderRefundPreviewQuery({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useOrderRefundPreviewQuery(baseOptions: Apollo.QueryHookOptions<OrderRefundPreviewQuery, OrderRefundPreviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrderRefundPreviewQuery, OrderRefundPreviewQueryVariables>(OrderRefundPreviewDocument, options);
      }
export function useOrderRefundPreviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderRefundPreviewQuery, OrderRefundPreviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrderRefundPreviewQuery, OrderRefundPreviewQueryVariables>(OrderRefundPreviewDocument, options);
        }
export type OrderRefundPreviewQueryHookResult = ReturnType<typeof useOrderRefundPreviewQuery>;
export type OrderRefundPreviewLazyQueryHookResult = ReturnType<typeof useOrderRefundPreviewLazyQuery>;
export type OrderRefundPreviewQueryResult = Apollo.QueryResult<OrderRefundPreviewQuery, OrderRefundPreviewQueryVariables>;
export const OrderItemsRefundableDocument = gql`
    query OrderItemsRefundable($orderId: ID!, $ticketId: ID) {
  orderItemsRefundable(orderId: $orderId, ticketId: $ticketId) {
    ...RefundableItems
  }
}
    ${RefundableItemsFragmentDoc}`;

/**
 * __useOrderItemsRefundableQuery__
 *
 * To run a query within a React component, call `useOrderItemsRefundableQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderItemsRefundableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderItemsRefundableQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      ticketId: // value for 'ticketId'
 *   },
 * });
 */
export function useOrderItemsRefundableQuery(baseOptions: Apollo.QueryHookOptions<OrderItemsRefundableQuery, OrderItemsRefundableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrderItemsRefundableQuery, OrderItemsRefundableQueryVariables>(OrderItemsRefundableDocument, options);
      }
export function useOrderItemsRefundableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderItemsRefundableQuery, OrderItemsRefundableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrderItemsRefundableQuery, OrderItemsRefundableQueryVariables>(OrderItemsRefundableDocument, options);
        }
export type OrderItemsRefundableQueryHookResult = ReturnType<typeof useOrderItemsRefundableQuery>;
export type OrderItemsRefundableLazyQueryHookResult = ReturnType<typeof useOrderItemsRefundableLazyQuery>;
export type OrderItemsRefundableQueryResult = Apollo.QueryResult<OrderItemsRefundableQuery, OrderItemsRefundableQueryVariables>;
export const UpsertMenuScheduleDocument = gql`
    mutation UpsertMenuSchedule($input: MenuScheduleUpsertInput!) {
  adminUpsertMenuSchedule(input: $input) {
    ...MenuScheduleItemInfo
    kitchen {
      ...MenuScheduleKitchenInfo
    }
    scheduleCategories {
      ...ScheduleCategoryInfo
      category {
        id
        name
      }
      menuItems {
        ...ScheduleCategoryMenuItems
        menuCategories {
          id
          name
        }
      }
    }
  }
}
    ${MenuScheduleItemInfoFragmentDoc}
${MenuScheduleKitchenInfoFragmentDoc}
${ScheduleCategoryInfoFragmentDoc}
${ScheduleCategoryMenuItemsFragmentDoc}`;
export type UpsertMenuScheduleMutationFn = Apollo.MutationFunction<UpsertMenuScheduleMutation, UpsertMenuScheduleMutationVariables>;

/**
 * __useUpsertMenuScheduleMutation__
 *
 * To run a mutation, you first call `useUpsertMenuScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertMenuScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertMenuScheduleMutation, { data, loading, error }] = useUpsertMenuScheduleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertMenuScheduleMutation(baseOptions?: Apollo.MutationHookOptions<UpsertMenuScheduleMutation, UpsertMenuScheduleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertMenuScheduleMutation, UpsertMenuScheduleMutationVariables>(UpsertMenuScheduleDocument, options);
      }
export type UpsertMenuScheduleMutationHookResult = ReturnType<typeof useUpsertMenuScheduleMutation>;
export type UpsertMenuScheduleMutationResult = Apollo.MutationResult<UpsertMenuScheduleMutation>;
export type UpsertMenuScheduleMutationOptions = Apollo.BaseMutationOptions<UpsertMenuScheduleMutation, UpsertMenuScheduleMutationVariables>;
export const DeleteMenuScheduleDocument = gql`
    mutation DeleteMenuSchedule($id: ID!) {
  adminDeleteMenuSchedule(id: $id) {
    id
  }
}
    `;
export type DeleteMenuScheduleMutationFn = Apollo.MutationFunction<DeleteMenuScheduleMutation, DeleteMenuScheduleMutationVariables>;

/**
 * __useDeleteMenuScheduleMutation__
 *
 * To run a mutation, you first call `useDeleteMenuScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMenuScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMenuScheduleMutation, { data, loading, error }] = useDeleteMenuScheduleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMenuScheduleMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMenuScheduleMutation, DeleteMenuScheduleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMenuScheduleMutation, DeleteMenuScheduleMutationVariables>(DeleteMenuScheduleDocument, options);
      }
export type DeleteMenuScheduleMutationHookResult = ReturnType<typeof useDeleteMenuScheduleMutation>;
export type DeleteMenuScheduleMutationResult = Apollo.MutationResult<DeleteMenuScheduleMutation>;
export type DeleteMenuScheduleMutationOptions = Apollo.BaseMutationOptions<DeleteMenuScheduleMutation, DeleteMenuScheduleMutationVariables>;
export const UpdateMenuScheduleStatusDocument = gql`
    mutation UpdateMenuScheduleStatus($id: ID!, $isActive: Boolean!) {
  adminUpdateMenuScheduleStatus(id: $id, isActive: $isActive) {
    id
    isActive
  }
}
    `;
export type UpdateMenuScheduleStatusMutationFn = Apollo.MutationFunction<UpdateMenuScheduleStatusMutation, UpdateMenuScheduleStatusMutationVariables>;

/**
 * __useUpdateMenuScheduleStatusMutation__
 *
 * To run a mutation, you first call `useUpdateMenuScheduleStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMenuScheduleStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMenuScheduleStatusMutation, { data, loading, error }] = useUpdateMenuScheduleStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isActive: // value for 'isActive'
 *   },
 * });
 */
export function useUpdateMenuScheduleStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMenuScheduleStatusMutation, UpdateMenuScheduleStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMenuScheduleStatusMutation, UpdateMenuScheduleStatusMutationVariables>(UpdateMenuScheduleStatusDocument, options);
      }
export type UpdateMenuScheduleStatusMutationHookResult = ReturnType<typeof useUpdateMenuScheduleStatusMutation>;
export type UpdateMenuScheduleStatusMutationResult = Apollo.MutationResult<UpdateMenuScheduleStatusMutation>;
export type UpdateMenuScheduleStatusMutationOptions = Apollo.BaseMutationOptions<UpdateMenuScheduleStatusMutation, UpdateMenuScheduleStatusMutationVariables>;
export const UpsertOrderMethodOverrideDocument = gql`
    mutation UpsertOrderMethodOverride($input: OrderMethodOverrideUpsertInput!) {
  adminUpsertOrderMethodOverride(input: $input) {
    ...OrderMethodOverrideInfo
  }
}
    ${OrderMethodOverrideInfoFragmentDoc}`;
export type UpsertOrderMethodOverrideMutationFn = Apollo.MutationFunction<UpsertOrderMethodOverrideMutation, UpsertOrderMethodOverrideMutationVariables>;

/**
 * __useUpsertOrderMethodOverrideMutation__
 *
 * To run a mutation, you first call `useUpsertOrderMethodOverrideMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertOrderMethodOverrideMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertOrderMethodOverrideMutation, { data, loading, error }] = useUpsertOrderMethodOverrideMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertOrderMethodOverrideMutation(baseOptions?: Apollo.MutationHookOptions<UpsertOrderMethodOverrideMutation, UpsertOrderMethodOverrideMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertOrderMethodOverrideMutation, UpsertOrderMethodOverrideMutationVariables>(UpsertOrderMethodOverrideDocument, options);
      }
export type UpsertOrderMethodOverrideMutationHookResult = ReturnType<typeof useUpsertOrderMethodOverrideMutation>;
export type UpsertOrderMethodOverrideMutationResult = Apollo.MutationResult<UpsertOrderMethodOverrideMutation>;
export type UpsertOrderMethodOverrideMutationOptions = Apollo.BaseMutationOptions<UpsertOrderMethodOverrideMutation, UpsertOrderMethodOverrideMutationVariables>;
export const AdminDeleteOrderMethodOverrideDocument = gql`
    mutation AdminDeleteOrderMethodOverride($id: ID!) {
  adminDeleteOrderMethodOverride(id: $id) {
    id
  }
}
    `;
export type AdminDeleteOrderMethodOverrideMutationFn = Apollo.MutationFunction<AdminDeleteOrderMethodOverrideMutation, AdminDeleteOrderMethodOverrideMutationVariables>;

/**
 * __useAdminDeleteOrderMethodOverrideMutation__
 *
 * To run a mutation, you first call `useAdminDeleteOrderMethodOverrideMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminDeleteOrderMethodOverrideMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminDeleteOrderMethodOverrideMutation, { data, loading, error }] = useAdminDeleteOrderMethodOverrideMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminDeleteOrderMethodOverrideMutation(baseOptions?: Apollo.MutationHookOptions<AdminDeleteOrderMethodOverrideMutation, AdminDeleteOrderMethodOverrideMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminDeleteOrderMethodOverrideMutation, AdminDeleteOrderMethodOverrideMutationVariables>(AdminDeleteOrderMethodOverrideDocument, options);
      }
export type AdminDeleteOrderMethodOverrideMutationHookResult = ReturnType<typeof useAdminDeleteOrderMethodOverrideMutation>;
export type AdminDeleteOrderMethodOverrideMutationResult = Apollo.MutationResult<AdminDeleteOrderMethodOverrideMutation>;
export type AdminDeleteOrderMethodOverrideMutationOptions = Apollo.BaseMutationOptions<AdminDeleteOrderMethodOverrideMutation, AdminDeleteOrderMethodOverrideMutationVariables>;
export const UpdateOrderMethodOverrideStatusDocument = gql`
    mutation UpdateOrderMethodOverrideStatus($id: ID!, $isActive: Boolean!) {
  adminUpdateOrderMethodOverrideStatus(id: $id, isActive: $isActive) {
    id
  }
}
    `;
export type UpdateOrderMethodOverrideStatusMutationFn = Apollo.MutationFunction<UpdateOrderMethodOverrideStatusMutation, UpdateOrderMethodOverrideStatusMutationVariables>;

/**
 * __useUpdateOrderMethodOverrideStatusMutation__
 *
 * To run a mutation, you first call `useUpdateOrderMethodOverrideStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderMethodOverrideStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderMethodOverrideStatusMutation, { data, loading, error }] = useUpdateOrderMethodOverrideStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isActive: // value for 'isActive'
 *   },
 * });
 */
export function useUpdateOrderMethodOverrideStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrderMethodOverrideStatusMutation, UpdateOrderMethodOverrideStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrderMethodOverrideStatusMutation, UpdateOrderMethodOverrideStatusMutationVariables>(UpdateOrderMethodOverrideStatusDocument, options);
      }
export type UpdateOrderMethodOverrideStatusMutationHookResult = ReturnType<typeof useUpdateOrderMethodOverrideStatusMutation>;
export type UpdateOrderMethodOverrideStatusMutationResult = Apollo.MutationResult<UpdateOrderMethodOverrideStatusMutation>;
export type UpdateOrderMethodOverrideStatusMutationOptions = Apollo.BaseMutationOptions<UpdateOrderMethodOverrideStatusMutation, UpdateOrderMethodOverrideStatusMutationVariables>;
export const UpsertSpecialMenuScheduleDocument = gql`
    mutation UpsertSpecialMenuSchedule($input: SpecialMenuScheduleUpsertInput!) {
  adminUpsertSpecialMenuSchedule(input: $input) {
    ...MenuScheduleItemInfo
    kitchen {
      ...MenuScheduleKitchenInfo
    }
    scheduleCategories {
      ...ScheduleCategoryInfo
      category {
        id
        name
      }
      menuItems {
        ...ScheduleCategoryMenuItems
        menuCategories {
          id
          name
        }
      }
    }
  }
}
    ${MenuScheduleItemInfoFragmentDoc}
${MenuScheduleKitchenInfoFragmentDoc}
${ScheduleCategoryInfoFragmentDoc}
${ScheduleCategoryMenuItemsFragmentDoc}`;
export type UpsertSpecialMenuScheduleMutationFn = Apollo.MutationFunction<UpsertSpecialMenuScheduleMutation, UpsertSpecialMenuScheduleMutationVariables>;

/**
 * __useUpsertSpecialMenuScheduleMutation__
 *
 * To run a mutation, you first call `useUpsertSpecialMenuScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertSpecialMenuScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertSpecialMenuScheduleMutation, { data, loading, error }] = useUpsertSpecialMenuScheduleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertSpecialMenuScheduleMutation(baseOptions?: Apollo.MutationHookOptions<UpsertSpecialMenuScheduleMutation, UpsertSpecialMenuScheduleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertSpecialMenuScheduleMutation, UpsertSpecialMenuScheduleMutationVariables>(UpsertSpecialMenuScheduleDocument, options);
      }
export type UpsertSpecialMenuScheduleMutationHookResult = ReturnType<typeof useUpsertSpecialMenuScheduleMutation>;
export type UpsertSpecialMenuScheduleMutationResult = Apollo.MutationResult<UpsertSpecialMenuScheduleMutation>;
export type UpsertSpecialMenuScheduleMutationOptions = Apollo.BaseMutationOptions<UpsertSpecialMenuScheduleMutation, UpsertSpecialMenuScheduleMutationVariables>;
export const UpdateKitchenColorDocument = gql`
    mutation UpdateKitchenColor($id: ID!, $color: String!) {
  adminUpdateKitchenColor(id: $id, color: $color) {
    ...MenuScheduleKitchenInfo
  }
}
    ${MenuScheduleKitchenInfoFragmentDoc}`;
export type UpdateKitchenColorMutationFn = Apollo.MutationFunction<UpdateKitchenColorMutation, UpdateKitchenColorMutationVariables>;

/**
 * __useUpdateKitchenColorMutation__
 *
 * To run a mutation, you first call `useUpdateKitchenColorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateKitchenColorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateKitchenColorMutation, { data, loading, error }] = useUpdateKitchenColorMutation({
 *   variables: {
 *      id: // value for 'id'
 *      color: // value for 'color'
 *   },
 * });
 */
export function useUpdateKitchenColorMutation(baseOptions?: Apollo.MutationHookOptions<UpdateKitchenColorMutation, UpdateKitchenColorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateKitchenColorMutation, UpdateKitchenColorMutationVariables>(UpdateKitchenColorDocument, options);
      }
export type UpdateKitchenColorMutationHookResult = ReturnType<typeof useUpdateKitchenColorMutation>;
export type UpdateKitchenColorMutationResult = Apollo.MutationResult<UpdateKitchenColorMutation>;
export type UpdateKitchenColorMutationOptions = Apollo.BaseMutationOptions<UpdateKitchenColorMutation, UpdateKitchenColorMutationVariables>;
export const AdminAllMenuSchedulesDocument = gql`
    query AdminAllMenuSchedules($kitchenIds: [ID!]!, $start: DateTime!, $end: DateTime!) {
  adminAllMenuSchedules(kitchenIds: $kitchenIds, start: $start, end: $end) {
    id
    start
    end
    isActive
    repeatOnDays
    kitchen {
      ...MenuScheduleKitchenInfo
    }
    scheduleCategories {
      ...MenuScheduleCategory
    }
    sortedCategoryIds
  }
}
    ${MenuScheduleKitchenInfoFragmentDoc}
${MenuScheduleCategoryFragmentDoc}`;

/**
 * __useAdminAllMenuSchedulesQuery__
 *
 * To run a query within a React component, call `useAdminAllMenuSchedulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminAllMenuSchedulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminAllMenuSchedulesQuery({
 *   variables: {
 *      kitchenIds: // value for 'kitchenIds'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useAdminAllMenuSchedulesQuery(baseOptions: Apollo.QueryHookOptions<AdminAllMenuSchedulesQuery, AdminAllMenuSchedulesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminAllMenuSchedulesQuery, AdminAllMenuSchedulesQueryVariables>(AdminAllMenuSchedulesDocument, options);
      }
export function useAdminAllMenuSchedulesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminAllMenuSchedulesQuery, AdminAllMenuSchedulesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminAllMenuSchedulesQuery, AdminAllMenuSchedulesQueryVariables>(AdminAllMenuSchedulesDocument, options);
        }
export type AdminAllMenuSchedulesQueryHookResult = ReturnType<typeof useAdminAllMenuSchedulesQuery>;
export type AdminAllMenuSchedulesLazyQueryHookResult = ReturnType<typeof useAdminAllMenuSchedulesLazyQuery>;
export type AdminAllMenuSchedulesQueryResult = Apollo.QueryResult<AdminAllMenuSchedulesQuery, AdminAllMenuSchedulesQueryVariables>;
export const AdminAllMenuSchedulesOverviewDocument = gql`
    query AdminAllMenuSchedulesOverview($kitchenIds: [ID!]!, $start: DateTime!, $end: DateTime!) {
  adminAllMenuSchedules(kitchenIds: $kitchenIds, start: $start, end: $end) {
    ...MenuScheduleItemInfo
    kitchen {
      ...MenuScheduleKitchenInfo
    }
    scheduleCategories {
      ...MenuScheduleCategory
    }
  }
}
    ${MenuScheduleItemInfoFragmentDoc}
${MenuScheduleKitchenInfoFragmentDoc}
${MenuScheduleCategoryFragmentDoc}`;

/**
 * __useAdminAllMenuSchedulesOverviewQuery__
 *
 * To run a query within a React component, call `useAdminAllMenuSchedulesOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminAllMenuSchedulesOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminAllMenuSchedulesOverviewQuery({
 *   variables: {
 *      kitchenIds: // value for 'kitchenIds'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useAdminAllMenuSchedulesOverviewQuery(baseOptions: Apollo.QueryHookOptions<AdminAllMenuSchedulesOverviewQuery, AdminAllMenuSchedulesOverviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminAllMenuSchedulesOverviewQuery, AdminAllMenuSchedulesOverviewQueryVariables>(AdminAllMenuSchedulesOverviewDocument, options);
      }
export function useAdminAllMenuSchedulesOverviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminAllMenuSchedulesOverviewQuery, AdminAllMenuSchedulesOverviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminAllMenuSchedulesOverviewQuery, AdminAllMenuSchedulesOverviewQueryVariables>(AdminAllMenuSchedulesOverviewDocument, options);
        }
export type AdminAllMenuSchedulesOverviewQueryHookResult = ReturnType<typeof useAdminAllMenuSchedulesOverviewQuery>;
export type AdminAllMenuSchedulesOverviewLazyQueryHookResult = ReturnType<typeof useAdminAllMenuSchedulesOverviewLazyQuery>;
export type AdminAllMenuSchedulesOverviewQueryResult = Apollo.QueryResult<AdminAllMenuSchedulesOverviewQuery, AdminAllMenuSchedulesOverviewQueryVariables>;
export const AdminMenuSchedulesDocument = gql`
    query AdminMenuSchedules($kitchenId: ID!, $start: DateTime!, $end: DateTime!) {
  adminMenuSchedules(kitchenId: $kitchenId, start: $start, end: $end) {
    ...MenuScheduleItemInfo
    kitchen {
      ...MenuScheduleKitchenInfo
    }
    scheduleCategories {
      ...MenuScheduleCategory
    }
  }
}
    ${MenuScheduleItemInfoFragmentDoc}
${MenuScheduleKitchenInfoFragmentDoc}
${MenuScheduleCategoryFragmentDoc}`;

/**
 * __useAdminMenuSchedulesQuery__
 *
 * To run a query within a React component, call `useAdminMenuSchedulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminMenuSchedulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminMenuSchedulesQuery({
 *   variables: {
 *      kitchenId: // value for 'kitchenId'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useAdminMenuSchedulesQuery(baseOptions: Apollo.QueryHookOptions<AdminMenuSchedulesQuery, AdminMenuSchedulesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminMenuSchedulesQuery, AdminMenuSchedulesQueryVariables>(AdminMenuSchedulesDocument, options);
      }
export function useAdminMenuSchedulesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminMenuSchedulesQuery, AdminMenuSchedulesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminMenuSchedulesQuery, AdminMenuSchedulesQueryVariables>(AdminMenuSchedulesDocument, options);
        }
export type AdminMenuSchedulesQueryHookResult = ReturnType<typeof useAdminMenuSchedulesQuery>;
export type AdminMenuSchedulesLazyQueryHookResult = ReturnType<typeof useAdminMenuSchedulesLazyQuery>;
export type AdminMenuSchedulesQueryResult = Apollo.QueryResult<AdminMenuSchedulesQuery, AdminMenuSchedulesQueryVariables>;
export const AdminMenuScheduleDocument = gql`
    query AdminMenuSchedule($id: ID!) {
  adminMenuSchedule(id: $id) {
    ...MenuScheduleItemInfo
    kitchen {
      ...MenuScheduleKitchenInfo
    }
  }
}
    ${MenuScheduleItemInfoFragmentDoc}
${MenuScheduleKitchenInfoFragmentDoc}`;

/**
 * __useAdminMenuScheduleQuery__
 *
 * To run a query within a React component, call `useAdminMenuScheduleQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminMenuScheduleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminMenuScheduleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminMenuScheduleQuery(baseOptions: Apollo.QueryHookOptions<AdminMenuScheduleQuery, AdminMenuScheduleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminMenuScheduleQuery, AdminMenuScheduleQueryVariables>(AdminMenuScheduleDocument, options);
      }
export function useAdminMenuScheduleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminMenuScheduleQuery, AdminMenuScheduleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminMenuScheduleQuery, AdminMenuScheduleQueryVariables>(AdminMenuScheduleDocument, options);
        }
export type AdminMenuScheduleQueryHookResult = ReturnType<typeof useAdminMenuScheduleQuery>;
export type AdminMenuScheduleLazyQueryHookResult = ReturnType<typeof useAdminMenuScheduleLazyQuery>;
export type AdminMenuScheduleQueryResult = Apollo.QueryResult<AdminMenuScheduleQuery, AdminMenuScheduleQueryVariables>;
export const AdminMenuScheduleCategoriesDocument = gql`
    query AdminMenuScheduleCategories($id: ID!) {
  adminMenuSchedule(id: $id) {
    id
    scheduleCategories {
      ...ScheduleCategoryInfo
      category {
        id
        name
        categoryType
      }
      menuItems {
        ...ScheduleCategoryMenuItems
      }
    }
  }
}
    ${ScheduleCategoryInfoFragmentDoc}
${ScheduleCategoryMenuItemsFragmentDoc}`;

/**
 * __useAdminMenuScheduleCategoriesQuery__
 *
 * To run a query within a React component, call `useAdminMenuScheduleCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminMenuScheduleCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminMenuScheduleCategoriesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminMenuScheduleCategoriesQuery(baseOptions: Apollo.QueryHookOptions<AdminMenuScheduleCategoriesQuery, AdminMenuScheduleCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminMenuScheduleCategoriesQuery, AdminMenuScheduleCategoriesQueryVariables>(AdminMenuScheduleCategoriesDocument, options);
      }
export function useAdminMenuScheduleCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminMenuScheduleCategoriesQuery, AdminMenuScheduleCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminMenuScheduleCategoriesQuery, AdminMenuScheduleCategoriesQueryVariables>(AdminMenuScheduleCategoriesDocument, options);
        }
export type AdminMenuScheduleCategoriesQueryHookResult = ReturnType<typeof useAdminMenuScheduleCategoriesQuery>;
export type AdminMenuScheduleCategoriesLazyQueryHookResult = ReturnType<typeof useAdminMenuScheduleCategoriesLazyQuery>;
export type AdminMenuScheduleCategoriesQueryResult = Apollo.QueryResult<AdminMenuScheduleCategoriesQuery, AdminMenuScheduleCategoriesQueryVariables>;
export const OrderMethodOverrideDocument = gql`
    query OrderMethodOverride($id: ID!) {
  adminOrderMethodOverride(id: $id) {
    ...OrderMethodOverrideInfo
  }
}
    ${OrderMethodOverrideInfoFragmentDoc}`;

/**
 * __useOrderMethodOverrideQuery__
 *
 * To run a query within a React component, call `useOrderMethodOverrideQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderMethodOverrideQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderMethodOverrideQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrderMethodOverrideQuery(baseOptions: Apollo.QueryHookOptions<OrderMethodOverrideQuery, OrderMethodOverrideQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrderMethodOverrideQuery, OrderMethodOverrideQueryVariables>(OrderMethodOverrideDocument, options);
      }
export function useOrderMethodOverrideLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderMethodOverrideQuery, OrderMethodOverrideQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrderMethodOverrideQuery, OrderMethodOverrideQueryVariables>(OrderMethodOverrideDocument, options);
        }
export type OrderMethodOverrideQueryHookResult = ReturnType<typeof useOrderMethodOverrideQuery>;
export type OrderMethodOverrideLazyQueryHookResult = ReturnType<typeof useOrderMethodOverrideLazyQuery>;
export type OrderMethodOverrideQueryResult = Apollo.QueryResult<OrderMethodOverrideQuery, OrderMethodOverrideQueryVariables>;
export const OrderMethodOverridesDocument = gql`
    query OrderMethodOverrides($start: DateTime!, $end: DateTime!) {
  adminOrderMethodOverrides(start: $start, end: $end) {
    ...OrderMethodOverrideInfo
  }
}
    ${OrderMethodOverrideInfoFragmentDoc}`;

/**
 * __useOrderMethodOverridesQuery__
 *
 * To run a query within a React component, call `useOrderMethodOverridesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderMethodOverridesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderMethodOverridesQuery({
 *   variables: {
 *      start: // value for 'start'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useOrderMethodOverridesQuery(baseOptions: Apollo.QueryHookOptions<OrderMethodOverridesQuery, OrderMethodOverridesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrderMethodOverridesQuery, OrderMethodOverridesQueryVariables>(OrderMethodOverridesDocument, options);
      }
export function useOrderMethodOverridesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderMethodOverridesQuery, OrderMethodOverridesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrderMethodOverridesQuery, OrderMethodOverridesQueryVariables>(OrderMethodOverridesDocument, options);
        }
export type OrderMethodOverridesQueryHookResult = ReturnType<typeof useOrderMethodOverridesQuery>;
export type OrderMethodOverridesLazyQueryHookResult = ReturnType<typeof useOrderMethodOverridesLazyQuery>;
export type OrderMethodOverridesQueryResult = Apollo.QueryResult<OrderMethodOverridesQuery, OrderMethodOverridesQueryVariables>;
export const AllSpecialMenuItemsDocument = gql`
    query AllSpecialMenuItems {
  adminKitchenMenuItems(activeItems: true, menuCategoryType: SPECIAL) {
    ...KitchenMenuItemInfo
    menuCategories {
      id
      name
    }
    kitchen {
      id
      name
      status
    }
    status
  }
}
    ${KitchenMenuItemInfoFragmentDoc}`;

/**
 * __useAllSpecialMenuItemsQuery__
 *
 * To run a query within a React component, call `useAllSpecialMenuItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllSpecialMenuItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllSpecialMenuItemsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllSpecialMenuItemsQuery(baseOptions?: Apollo.QueryHookOptions<AllSpecialMenuItemsQuery, AllSpecialMenuItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllSpecialMenuItemsQuery, AllSpecialMenuItemsQueryVariables>(AllSpecialMenuItemsDocument, options);
      }
export function useAllSpecialMenuItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllSpecialMenuItemsQuery, AllSpecialMenuItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllSpecialMenuItemsQuery, AllSpecialMenuItemsQueryVariables>(AllSpecialMenuItemsDocument, options);
        }
export type AllSpecialMenuItemsQueryHookResult = ReturnType<typeof useAllSpecialMenuItemsQuery>;
export type AllSpecialMenuItemsLazyQueryHookResult = ReturnType<typeof useAllSpecialMenuItemsLazyQuery>;
export type AllSpecialMenuItemsQueryResult = Apollo.QueryResult<AllSpecialMenuItemsQuery, AllSpecialMenuItemsQueryVariables>;
export const MenuSchedulesSpecialsDocument = gql`
    query MenuSchedulesSpecials($end: DateTime!, $start: DateTime!) {
  adminSpecialMenuSchedules(start: $start, end: $end) {
    ...MenuScheduleSpecialInfo
    scheduleCategories {
      ...MenuScheduleCategory
      menuItems {
        ...ScheduleCategoryMenuItems
        menuCategories {
          id
          name
        }
      }
    }
  }
}
    ${MenuScheduleSpecialInfoFragmentDoc}
${MenuScheduleCategoryFragmentDoc}
${ScheduleCategoryMenuItemsFragmentDoc}`;

/**
 * __useMenuSchedulesSpecialsQuery__
 *
 * To run a query within a React component, call `useMenuSchedulesSpecialsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMenuSchedulesSpecialsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMenuSchedulesSpecialsQuery({
 *   variables: {
 *      end: // value for 'end'
 *      start: // value for 'start'
 *   },
 * });
 */
export function useMenuSchedulesSpecialsQuery(baseOptions: Apollo.QueryHookOptions<MenuSchedulesSpecialsQuery, MenuSchedulesSpecialsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MenuSchedulesSpecialsQuery, MenuSchedulesSpecialsQueryVariables>(MenuSchedulesSpecialsDocument, options);
      }
export function useMenuSchedulesSpecialsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MenuSchedulesSpecialsQuery, MenuSchedulesSpecialsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MenuSchedulesSpecialsQuery, MenuSchedulesSpecialsQueryVariables>(MenuSchedulesSpecialsDocument, options);
        }
export type MenuSchedulesSpecialsQueryHookResult = ReturnType<typeof useMenuSchedulesSpecialsQuery>;
export type MenuSchedulesSpecialsLazyQueryHookResult = ReturnType<typeof useMenuSchedulesSpecialsLazyQuery>;
export type MenuSchedulesSpecialsQueryResult = Apollo.QueryResult<MenuSchedulesSpecialsQuery, MenuSchedulesSpecialsQueryVariables>;
export const UpdateFacilityOperationHoursDocument = gql`
    mutation UpdateFacilityOperationHours($input: OperationHoursUpdateInput!) {
  adminUpdateFacilityOperationHours(input: $input) {
    id
    operationHours
  }
}
    `;
export type UpdateFacilityOperationHoursMutationFn = Apollo.MutationFunction<UpdateFacilityOperationHoursMutation, UpdateFacilityOperationHoursMutationVariables>;

/**
 * __useUpdateFacilityOperationHoursMutation__
 *
 * To run a mutation, you first call `useUpdateFacilityOperationHoursMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFacilityOperationHoursMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFacilityOperationHoursMutation, { data, loading, error }] = useUpdateFacilityOperationHoursMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFacilityOperationHoursMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFacilityOperationHoursMutation, UpdateFacilityOperationHoursMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFacilityOperationHoursMutation, UpdateFacilityOperationHoursMutationVariables>(UpdateFacilityOperationHoursDocument, options);
      }
export type UpdateFacilityOperationHoursMutationHookResult = ReturnType<typeof useUpdateFacilityOperationHoursMutation>;
export type UpdateFacilityOperationHoursMutationResult = Apollo.MutationResult<UpdateFacilityOperationHoursMutation>;
export type UpdateFacilityOperationHoursMutationOptions = Apollo.BaseMutationOptions<UpdateFacilityOperationHoursMutation, UpdateFacilityOperationHoursMutationVariables>;
export const UpdateFacilityKioskLogoDocument = gql`
    mutation UpdateFacilityKioskLogo($id: ID!, $kioskLogoUrl: String) {
  adminUpdateFacilityKioskLogo(id: $id, kioskLogoUrl: $kioskLogoUrl) {
    id
    kioskLogoUrl
  }
}
    `;
export type UpdateFacilityKioskLogoMutationFn = Apollo.MutationFunction<UpdateFacilityKioskLogoMutation, UpdateFacilityKioskLogoMutationVariables>;

/**
 * __useUpdateFacilityKioskLogoMutation__
 *
 * To run a mutation, you first call `useUpdateFacilityKioskLogoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFacilityKioskLogoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFacilityKioskLogoMutation, { data, loading, error }] = useUpdateFacilityKioskLogoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      kioskLogoUrl: // value for 'kioskLogoUrl'
 *   },
 * });
 */
export function useUpdateFacilityKioskLogoMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFacilityKioskLogoMutation, UpdateFacilityKioskLogoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFacilityKioskLogoMutation, UpdateFacilityKioskLogoMutationVariables>(UpdateFacilityKioskLogoDocument, options);
      }
export type UpdateFacilityKioskLogoMutationHookResult = ReturnType<typeof useUpdateFacilityKioskLogoMutation>;
export type UpdateFacilityKioskLogoMutationResult = Apollo.MutationResult<UpdateFacilityKioskLogoMutation>;
export type UpdateFacilityKioskLogoMutationOptions = Apollo.BaseMutationOptions<UpdateFacilityKioskLogoMutation, UpdateFacilityKioskLogoMutationVariables>;
export const UpdateFacilityLogoDocument = gql`
    mutation UpdateFacilityLogo($id: ID!, $logoUrl: String) {
  adminUpdateFacilityLogo(id: $id, logoUrl: $logoUrl) {
    id
    logoUrl
  }
}
    `;
export type UpdateFacilityLogoMutationFn = Apollo.MutationFunction<UpdateFacilityLogoMutation, UpdateFacilityLogoMutationVariables>;

/**
 * __useUpdateFacilityLogoMutation__
 *
 * To run a mutation, you first call `useUpdateFacilityLogoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFacilityLogoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFacilityLogoMutation, { data, loading, error }] = useUpdateFacilityLogoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      logoUrl: // value for 'logoUrl'
 *   },
 * });
 */
export function useUpdateFacilityLogoMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFacilityLogoMutation, UpdateFacilityLogoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFacilityLogoMutation, UpdateFacilityLogoMutationVariables>(UpdateFacilityLogoDocument, options);
      }
export type UpdateFacilityLogoMutationHookResult = ReturnType<typeof useUpdateFacilityLogoMutation>;
export type UpdateFacilityLogoMutationResult = Apollo.MutationResult<UpdateFacilityLogoMutation>;
export type UpdateFacilityLogoMutationOptions = Apollo.BaseMutationOptions<UpdateFacilityLogoMutation, UpdateFacilityLogoMutationVariables>;
export const UpdateFacilityHeroImageDocument = gql`
    mutation UpdateFacilityHeroImage($id: ID!, $heroImageUrl: String) {
  adminUpdateFacilityHeroImage(id: $id, heroImageUrl: $heroImageUrl) {
    id
    heroImageUrl
  }
}
    `;
export type UpdateFacilityHeroImageMutationFn = Apollo.MutationFunction<UpdateFacilityHeroImageMutation, UpdateFacilityHeroImageMutationVariables>;

/**
 * __useUpdateFacilityHeroImageMutation__
 *
 * To run a mutation, you first call `useUpdateFacilityHeroImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFacilityHeroImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFacilityHeroImageMutation, { data, loading, error }] = useUpdateFacilityHeroImageMutation({
 *   variables: {
 *      id: // value for 'id'
 *      heroImageUrl: // value for 'heroImageUrl'
 *   },
 * });
 */
export function useUpdateFacilityHeroImageMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFacilityHeroImageMutation, UpdateFacilityHeroImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFacilityHeroImageMutation, UpdateFacilityHeroImageMutationVariables>(UpdateFacilityHeroImageDocument, options);
      }
export type UpdateFacilityHeroImageMutationHookResult = ReturnType<typeof useUpdateFacilityHeroImageMutation>;
export type UpdateFacilityHeroImageMutationResult = Apollo.MutationResult<UpdateFacilityHeroImageMutation>;
export type UpdateFacilityHeroImageMutationOptions = Apollo.BaseMutationOptions<UpdateFacilityHeroImageMutation, UpdateFacilityHeroImageMutationVariables>;
export const UpdateFacilityContactDocument = gql`
    mutation UpdateFacilityContact($id: ID!, $phone: String!, $email: String!) {
  adminUpdateFacilityContact(id: $id, phone: $phone, email: $email) {
    id
    phone
    email
  }
}
    `;
export type UpdateFacilityContactMutationFn = Apollo.MutationFunction<UpdateFacilityContactMutation, UpdateFacilityContactMutationVariables>;

/**
 * __useUpdateFacilityContactMutation__
 *
 * To run a mutation, you first call `useUpdateFacilityContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFacilityContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFacilityContactMutation, { data, loading, error }] = useUpdateFacilityContactMutation({
 *   variables: {
 *      id: // value for 'id'
 *      phone: // value for 'phone'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useUpdateFacilityContactMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFacilityContactMutation, UpdateFacilityContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFacilityContactMutation, UpdateFacilityContactMutationVariables>(UpdateFacilityContactDocument, options);
      }
export type UpdateFacilityContactMutationHookResult = ReturnType<typeof useUpdateFacilityContactMutation>;
export type UpdateFacilityContactMutationResult = Apollo.MutationResult<UpdateFacilityContactMutation>;
export type UpdateFacilityContactMutationOptions = Apollo.BaseMutationOptions<UpdateFacilityContactMutation, UpdateFacilityContactMutationVariables>;
export const UpdateFacilityDocument = gql`
    mutation UpdateFacility($input: FacilityUpdateInput!) {
  adminUpdateFacility(input: $input) {
    ...Facility
  }
}
    ${FacilityFragmentDoc}`;
export type UpdateFacilityMutationFn = Apollo.MutationFunction<UpdateFacilityMutation, UpdateFacilityMutationVariables>;

/**
 * __useUpdateFacilityMutation__
 *
 * To run a mutation, you first call `useUpdateFacilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFacilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFacilityMutation, { data, loading, error }] = useUpdateFacilityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFacilityMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFacilityMutation, UpdateFacilityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFacilityMutation, UpdateFacilityMutationVariables>(UpdateFacilityDocument, options);
      }
export type UpdateFacilityMutationHookResult = ReturnType<typeof useUpdateFacilityMutation>;
export type UpdateFacilityMutationResult = Apollo.MutationResult<UpdateFacilityMutation>;
export type UpdateFacilityMutationOptions = Apollo.BaseMutationOptions<UpdateFacilityMutation, UpdateFacilityMutationVariables>;
export const UpdateOrderStatusDocument = gql`
    mutation UpdateOrderStatus($input: OrderStatusInput!) {
  adminUpdateOrderStatus(input: $input) {
    id
    status
  }
}
    `;
export type UpdateOrderStatusMutationFn = Apollo.MutationFunction<UpdateOrderStatusMutation, UpdateOrderStatusMutationVariables>;

/**
 * __useUpdateOrderStatusMutation__
 *
 * To run a mutation, you first call `useUpdateOrderStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderStatusMutation, { data, loading, error }] = useUpdateOrderStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrderStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrderStatusMutation, UpdateOrderStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrderStatusMutation, UpdateOrderStatusMutationVariables>(UpdateOrderStatusDocument, options);
      }
export type UpdateOrderStatusMutationHookResult = ReturnType<typeof useUpdateOrderStatusMutation>;
export type UpdateOrderStatusMutationResult = Apollo.MutationResult<UpdateOrderStatusMutation>;
export type UpdateOrderStatusMutationOptions = Apollo.BaseMutationOptions<UpdateOrderStatusMutation, UpdateOrderStatusMutationVariables>;
export const UpdateDeliveryTimeDocument = gql`
    mutation UpdateDeliveryTime($input: UpdateDeliveryInput!) {
  adminUpdateOrderDeliveryTime(input: $input)
}
    `;
export type UpdateDeliveryTimeMutationFn = Apollo.MutationFunction<UpdateDeliveryTimeMutation, UpdateDeliveryTimeMutationVariables>;

/**
 * __useUpdateDeliveryTimeMutation__
 *
 * To run a mutation, you first call `useUpdateDeliveryTimeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDeliveryTimeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDeliveryTimeMutation, { data, loading, error }] = useUpdateDeliveryTimeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDeliveryTimeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDeliveryTimeMutation, UpdateDeliveryTimeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDeliveryTimeMutation, UpdateDeliveryTimeMutationVariables>(UpdateDeliveryTimeDocument, options);
      }
export type UpdateDeliveryTimeMutationHookResult = ReturnType<typeof useUpdateDeliveryTimeMutation>;
export type UpdateDeliveryTimeMutationResult = Apollo.MutationResult<UpdateDeliveryTimeMutation>;
export type UpdateDeliveryTimeMutationOptions = Apollo.BaseMutationOptions<UpdateDeliveryTimeMutation, UpdateDeliveryTimeMutationVariables>;
export const UpdateOrderPreparingUntilDocument = gql`
    mutation UpdateOrderPreparingUntil($input: UpdateOrderPreparingUntilInput!) {
  adminUpdateOrderPreparingUntil(input: $input)
}
    `;
export type UpdateOrderPreparingUntilMutationFn = Apollo.MutationFunction<UpdateOrderPreparingUntilMutation, UpdateOrderPreparingUntilMutationVariables>;

/**
 * __useUpdateOrderPreparingUntilMutation__
 *
 * To run a mutation, you first call `useUpdateOrderPreparingUntilMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderPreparingUntilMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderPreparingUntilMutation, { data, loading, error }] = useUpdateOrderPreparingUntilMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrderPreparingUntilMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrderPreparingUntilMutation, UpdateOrderPreparingUntilMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrderPreparingUntilMutation, UpdateOrderPreparingUntilMutationVariables>(UpdateOrderPreparingUntilDocument, options);
      }
export type UpdateOrderPreparingUntilMutationHookResult = ReturnType<typeof useUpdateOrderPreparingUntilMutation>;
export type UpdateOrderPreparingUntilMutationResult = Apollo.MutationResult<UpdateOrderPreparingUntilMutation>;
export type UpdateOrderPreparingUntilMutationOptions = Apollo.BaseMutationOptions<UpdateOrderPreparingUntilMutation, UpdateOrderPreparingUntilMutationVariables>;
export const CreateOrderBundlesDocument = gql`
    mutation CreateOrderBundles($date: DateTime) {
  createOrderBundles(date: $date) {
    ...OrderBundleInfo
  }
}
    ${OrderBundleInfoFragmentDoc}`;
export type CreateOrderBundlesMutationFn = Apollo.MutationFunction<CreateOrderBundlesMutation, CreateOrderBundlesMutationVariables>;

/**
 * __useCreateOrderBundlesMutation__
 *
 * To run a mutation, you first call `useCreateOrderBundlesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrderBundlesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrderBundlesMutation, { data, loading, error }] = useCreateOrderBundlesMutation({
 *   variables: {
 *      date: // value for 'date'
 *   },
 * });
 */
export function useCreateOrderBundlesMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrderBundlesMutation, CreateOrderBundlesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrderBundlesMutation, CreateOrderBundlesMutationVariables>(CreateOrderBundlesDocument, options);
      }
export type CreateOrderBundlesMutationHookResult = ReturnType<typeof useCreateOrderBundlesMutation>;
export type CreateOrderBundlesMutationResult = Apollo.MutationResult<CreateOrderBundlesMutation>;
export type CreateOrderBundlesMutationOptions = Apollo.BaseMutationOptions<CreateOrderBundlesMutation, CreateOrderBundlesMutationVariables>;
export const CreateOrderBundleManuallyDocument = gql`
    mutation CreateOrderBundleManually($orderId: ID!) {
  adminCreateOrderBundleManually(orderId: $orderId) {
    ...OrderBundleInfo
  }
}
    ${OrderBundleInfoFragmentDoc}`;
export type CreateOrderBundleManuallyMutationFn = Apollo.MutationFunction<CreateOrderBundleManuallyMutation, CreateOrderBundleManuallyMutationVariables>;

/**
 * __useCreateOrderBundleManuallyMutation__
 *
 * To run a mutation, you first call `useCreateOrderBundleManuallyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrderBundleManuallyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrderBundleManuallyMutation, { data, loading, error }] = useCreateOrderBundleManuallyMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useCreateOrderBundleManuallyMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrderBundleManuallyMutation, CreateOrderBundleManuallyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrderBundleManuallyMutation, CreateOrderBundleManuallyMutationVariables>(CreateOrderBundleManuallyDocument, options);
      }
export type CreateOrderBundleManuallyMutationHookResult = ReturnType<typeof useCreateOrderBundleManuallyMutation>;
export type CreateOrderBundleManuallyMutationResult = Apollo.MutationResult<CreateOrderBundleManuallyMutation>;
export type CreateOrderBundleManuallyMutationOptions = Apollo.BaseMutationOptions<CreateOrderBundleManuallyMutation, CreateOrderBundleManuallyMutationVariables>;
export const UpdateOrderPaidAtDocument = gql`
    mutation UpdateOrderPaidAt($orderId: ID!, $isPaid: Boolean!) {
  adminUpdateOrderPaidAt(orderId: $orderId, isPaid: $isPaid) {
    ...OrderInfo
    items {
      ...OrderItemInfo
    }
    payedAt
  }
}
    ${OrderInfoFragmentDoc}
${OrderItemInfoFragmentDoc}`;
export type UpdateOrderPaidAtMutationFn = Apollo.MutationFunction<UpdateOrderPaidAtMutation, UpdateOrderPaidAtMutationVariables>;

/**
 * __useUpdateOrderPaidAtMutation__
 *
 * To run a mutation, you first call `useUpdateOrderPaidAtMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderPaidAtMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderPaidAtMutation, { data, loading, error }] = useUpdateOrderPaidAtMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      isPaid: // value for 'isPaid'
 *   },
 * });
 */
export function useUpdateOrderPaidAtMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrderPaidAtMutation, UpdateOrderPaidAtMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrderPaidAtMutation, UpdateOrderPaidAtMutationVariables>(UpdateOrderPaidAtDocument, options);
      }
export type UpdateOrderPaidAtMutationHookResult = ReturnType<typeof useUpdateOrderPaidAtMutation>;
export type UpdateOrderPaidAtMutationResult = Apollo.MutationResult<UpdateOrderPaidAtMutation>;
export type UpdateOrderPaidAtMutationOptions = Apollo.BaseMutationOptions<UpdateOrderPaidAtMutation, UpdateOrderPaidAtMutationVariables>;
export const UpdateUserInfoDocument = gql`
    mutation UpdateUserInfo($input: AdminUserUpdateInput!) {
  adminUpdateUser(input: $input) {
    ...UserInfo
    customerInfo {
      id
      vip
      isEmployee
    }
  }
}
    ${UserInfoFragmentDoc}`;
export type UpdateUserInfoMutationFn = Apollo.MutationFunction<UpdateUserInfoMutation, UpdateUserInfoMutationVariables>;

/**
 * __useUpdateUserInfoMutation__
 *
 * To run a mutation, you first call `useUpdateUserInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserInfoMutation, { data, loading, error }] = useUpdateUserInfoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserInfoMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserInfoMutation, UpdateUserInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserInfoMutation, UpdateUserInfoMutationVariables>(UpdateUserInfoDocument, options);
      }
export type UpdateUserInfoMutationHookResult = ReturnType<typeof useUpdateUserInfoMutation>;
export type UpdateUserInfoMutationResult = Apollo.MutationResult<UpdateUserInfoMutation>;
export type UpdateUserInfoMutationOptions = Apollo.BaseMutationOptions<UpdateUserInfoMutation, UpdateUserInfoMutationVariables>;
export const UpdateUserTagsDocument = gql`
    mutation UpdateUserTags($customerId: ID!, $tag: TagCreateOrConnectInput!) {
  adminAddCustomerTag(customerId: $customerId, tag: $tag) {
    id
    tags {
      id
      name
    }
  }
}
    `;
export type UpdateUserTagsMutationFn = Apollo.MutationFunction<UpdateUserTagsMutation, UpdateUserTagsMutationVariables>;

/**
 * __useUpdateUserTagsMutation__
 *
 * To run a mutation, you first call `useUpdateUserTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserTagsMutation, { data, loading, error }] = useUpdateUserTagsMutation({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      tag: // value for 'tag'
 *   },
 * });
 */
export function useUpdateUserTagsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserTagsMutation, UpdateUserTagsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserTagsMutation, UpdateUserTagsMutationVariables>(UpdateUserTagsDocument, options);
      }
export type UpdateUserTagsMutationHookResult = ReturnType<typeof useUpdateUserTagsMutation>;
export type UpdateUserTagsMutationResult = Apollo.MutationResult<UpdateUserTagsMutation>;
export type UpdateUserTagsMutationOptions = Apollo.BaseMutationOptions<UpdateUserTagsMutation, UpdateUserTagsMutationVariables>;
export const RemoveCustomerTagDocument = gql`
    mutation RemoveCustomerTag($customerId: ID!, $tagId: ID!) {
  adminRemoveCustomerTag(customerId: $customerId, tagId: $tagId) {
    id
    tags {
      id
      name
    }
  }
}
    `;
export type RemoveCustomerTagMutationFn = Apollo.MutationFunction<RemoveCustomerTagMutation, RemoveCustomerTagMutationVariables>;

/**
 * __useRemoveCustomerTagMutation__
 *
 * To run a mutation, you first call `useRemoveCustomerTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCustomerTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCustomerTagMutation, { data, loading, error }] = useRemoveCustomerTagMutation({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      tagId: // value for 'tagId'
 *   },
 * });
 */
export function useRemoveCustomerTagMutation(baseOptions?: Apollo.MutationHookOptions<RemoveCustomerTagMutation, RemoveCustomerTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveCustomerTagMutation, RemoveCustomerTagMutationVariables>(RemoveCustomerTagDocument, options);
      }
export type RemoveCustomerTagMutationHookResult = ReturnType<typeof useRemoveCustomerTagMutation>;
export type RemoveCustomerTagMutationResult = Apollo.MutationResult<RemoveCustomerTagMutation>;
export type RemoveCustomerTagMutationOptions = Apollo.BaseMutationOptions<RemoveCustomerTagMutation, RemoveCustomerTagMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($email: String!) {
  customerResetPassword(email: $email)
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const UpdateAddressDocument = gql`
    mutation UpdateAddress($input: AdminAddressUpdateInput!) {
  adminUpdateUserAddress(input: $input) {
    ...AddressInfo
  }
}
    ${AddressInfoFragmentDoc}`;
export type UpdateAddressMutationFn = Apollo.MutationFunction<UpdateAddressMutation, UpdateAddressMutationVariables>;

/**
 * __useUpdateAddressMutation__
 *
 * To run a mutation, you first call `useUpdateAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAddressMutation, { data, loading, error }] = useUpdateAddressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAddressMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAddressMutation, UpdateAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAddressMutation, UpdateAddressMutationVariables>(UpdateAddressDocument, options);
      }
export type UpdateAddressMutationHookResult = ReturnType<typeof useUpdateAddressMutation>;
export type UpdateAddressMutationResult = Apollo.MutationResult<UpdateAddressMutation>;
export type UpdateAddressMutationOptions = Apollo.BaseMutationOptions<UpdateAddressMutation, UpdateAddressMutationVariables>;
export const UpdatePasswordDocument = gql`
    mutation UpdatePassword($input: PasswordUpdateInput!) {
  adminUpdatePassword(input: $input)
}
    `;
export type UpdatePasswordMutationFn = Apollo.MutationFunction<UpdatePasswordMutation, UpdatePasswordMutationVariables>;

/**
 * __useUpdatePasswordMutation__
 *
 * To run a mutation, you first call `useUpdatePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePasswordMutation, { data, loading, error }] = useUpdatePasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePasswordMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePasswordMutation, UpdatePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePasswordMutation, UpdatePasswordMutationVariables>(UpdatePasswordDocument, options);
      }
export type UpdatePasswordMutationHookResult = ReturnType<typeof useUpdatePasswordMutation>;
export type UpdatePasswordMutationResult = Apollo.MutationResult<UpdatePasswordMutation>;
export type UpdatePasswordMutationOptions = Apollo.BaseMutationOptions<UpdatePasswordMutation, UpdatePasswordMutationVariables>;
export const UpdateEmailDocument = gql`
    mutation UpdateEmail($input: EmailUpdateInput!) {
  adminUpdateEmail(input: $input) {
    email
  }
}
    `;
export type UpdateEmailMutationFn = Apollo.MutationFunction<UpdateEmailMutation, UpdateEmailMutationVariables>;

/**
 * __useUpdateEmailMutation__
 *
 * To run a mutation, you first call `useUpdateEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmailMutation, { data, loading, error }] = useUpdateEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEmailMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEmailMutation, UpdateEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEmailMutation, UpdateEmailMutationVariables>(UpdateEmailDocument, options);
      }
export type UpdateEmailMutationHookResult = ReturnType<typeof useUpdateEmailMutation>;
export type UpdateEmailMutationResult = Apollo.MutationResult<UpdateEmailMutation>;
export type UpdateEmailMutationOptions = Apollo.BaseMutationOptions<UpdateEmailMutation, UpdateEmailMutationVariables>;
export const KitchenTicketStartPreparingDocument = gql`
    mutation KitchenTicketStartPreparing($ticketId: ID!) {
  kdsKitchenTicketStartPreparing(ticketId: $ticketId) {
    ...KitchenTicket
  }
}
    ${KitchenTicketFragmentDoc}`;
export type KitchenTicketStartPreparingMutationFn = Apollo.MutationFunction<KitchenTicketStartPreparingMutation, KitchenTicketStartPreparingMutationVariables>;

/**
 * __useKitchenTicketStartPreparingMutation__
 *
 * To run a mutation, you first call `useKitchenTicketStartPreparingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useKitchenTicketStartPreparingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [kitchenTicketStartPreparingMutation, { data, loading, error }] = useKitchenTicketStartPreparingMutation({
 *   variables: {
 *      ticketId: // value for 'ticketId'
 *   },
 * });
 */
export function useKitchenTicketStartPreparingMutation(baseOptions?: Apollo.MutationHookOptions<KitchenTicketStartPreparingMutation, KitchenTicketStartPreparingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<KitchenTicketStartPreparingMutation, KitchenTicketStartPreparingMutationVariables>(KitchenTicketStartPreparingDocument, options);
      }
export type KitchenTicketStartPreparingMutationHookResult = ReturnType<typeof useKitchenTicketStartPreparingMutation>;
export type KitchenTicketStartPreparingMutationResult = Apollo.MutationResult<KitchenTicketStartPreparingMutation>;
export type KitchenTicketStartPreparingMutationOptions = Apollo.BaseMutationOptions<KitchenTicketStartPreparingMutation, KitchenTicketStartPreparingMutationVariables>;
export const KdsDelayKitchenTicketDocument = gql`
    mutation KdsDelayKitchenTicket($ticketId: String!, $delayedAt: DateTime!, $delayTime: Int!, $delayReason: KitchenTicketDelayReason!) {
  adminUpdateOneKitchenTicket(
    where: {id: $ticketId}
    data: {delayedAt: {set: $delayedAt}, delayTime: {set: $delayTime}, delayReason: {set: $delayReason}}
  ) {
    ...KitchenTicket
  }
}
    ${KitchenTicketFragmentDoc}`;
export type KdsDelayKitchenTicketMutationFn = Apollo.MutationFunction<KdsDelayKitchenTicketMutation, KdsDelayKitchenTicketMutationVariables>;

/**
 * __useKdsDelayKitchenTicketMutation__
 *
 * To run a mutation, you first call `useKdsDelayKitchenTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useKdsDelayKitchenTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [kdsDelayKitchenTicketMutation, { data, loading, error }] = useKdsDelayKitchenTicketMutation({
 *   variables: {
 *      ticketId: // value for 'ticketId'
 *      delayedAt: // value for 'delayedAt'
 *      delayTime: // value for 'delayTime'
 *      delayReason: // value for 'delayReason'
 *   },
 * });
 */
export function useKdsDelayKitchenTicketMutation(baseOptions?: Apollo.MutationHookOptions<KdsDelayKitchenTicketMutation, KdsDelayKitchenTicketMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<KdsDelayKitchenTicketMutation, KdsDelayKitchenTicketMutationVariables>(KdsDelayKitchenTicketDocument, options);
      }
export type KdsDelayKitchenTicketMutationHookResult = ReturnType<typeof useKdsDelayKitchenTicketMutation>;
export type KdsDelayKitchenTicketMutationResult = Apollo.MutationResult<KdsDelayKitchenTicketMutation>;
export type KdsDelayKitchenTicketMutationOptions = Apollo.BaseMutationOptions<KdsDelayKitchenTicketMutation, KdsDelayKitchenTicketMutationVariables>;
export const RefireOrderDocument = gql`
    mutation RefireOrder($input: RefireOrderInput!) {
  kdsRefireOrder(input: $input) {
    ...KdsExpoOrder
  }
}
    ${KdsExpoOrderFragmentDoc}`;
export type RefireOrderMutationFn = Apollo.MutationFunction<RefireOrderMutation, RefireOrderMutationVariables>;

/**
 * __useRefireOrderMutation__
 *
 * To run a mutation, you first call `useRefireOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefireOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refireOrderMutation, { data, loading, error }] = useRefireOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRefireOrderMutation(baseOptions?: Apollo.MutationHookOptions<RefireOrderMutation, RefireOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefireOrderMutation, RefireOrderMutationVariables>(RefireOrderDocument, options);
      }
export type RefireOrderMutationHookResult = ReturnType<typeof useRefireOrderMutation>;
export type RefireOrderMutationResult = Apollo.MutationResult<RefireOrderMutation>;
export type RefireOrderMutationOptions = Apollo.BaseMutationOptions<RefireOrderMutation, RefireOrderMutationVariables>;
export const RefireOrderItemDocument = gql`
    mutation RefireOrderItem($input: RefireOrderItemInput!) {
  kdsRefireOrderItem(input: $input) {
    ...KdsExpoOrder
  }
}
    ${KdsExpoOrderFragmentDoc}`;
export type RefireOrderItemMutationFn = Apollo.MutationFunction<RefireOrderItemMutation, RefireOrderItemMutationVariables>;

/**
 * __useRefireOrderItemMutation__
 *
 * To run a mutation, you first call `useRefireOrderItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefireOrderItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refireOrderItemMutation, { data, loading, error }] = useRefireOrderItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRefireOrderItemMutation(baseOptions?: Apollo.MutationHookOptions<RefireOrderItemMutation, RefireOrderItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefireOrderItemMutation, RefireOrderItemMutationVariables>(RefireOrderItemDocument, options);
      }
export type RefireOrderItemMutationHookResult = ReturnType<typeof useRefireOrderItemMutation>;
export type RefireOrderItemMutationResult = Apollo.MutationResult<RefireOrderItemMutation>;
export type RefireOrderItemMutationOptions = Apollo.BaseMutationOptions<RefireOrderItemMutation, RefireOrderItemMutationVariables>;
export const RefireKitchenTicketDocument = gql`
    mutation RefireKitchenTicket($input: RefireKitchenTicketInput!) {
  kdsRefireKitchenTicket(input: $input) {
    ...KdsExpoOrder
  }
}
    ${KdsExpoOrderFragmentDoc}`;
export type RefireKitchenTicketMutationFn = Apollo.MutationFunction<RefireKitchenTicketMutation, RefireKitchenTicketMutationVariables>;

/**
 * __useRefireKitchenTicketMutation__
 *
 * To run a mutation, you first call `useRefireKitchenTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefireKitchenTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refireKitchenTicketMutation, { data, loading, error }] = useRefireKitchenTicketMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRefireKitchenTicketMutation(baseOptions?: Apollo.MutationHookOptions<RefireKitchenTicketMutation, RefireKitchenTicketMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefireKitchenTicketMutation, RefireKitchenTicketMutationVariables>(RefireKitchenTicketDocument, options);
      }
export type RefireKitchenTicketMutationHookResult = ReturnType<typeof useRefireKitchenTicketMutation>;
export type RefireKitchenTicketMutationResult = Apollo.MutationResult<RefireKitchenTicketMutation>;
export type RefireKitchenTicketMutationOptions = Apollo.BaseMutationOptions<RefireKitchenTicketMutation, RefireKitchenTicketMutationVariables>;
export const UndoKitchenTicketDocument = gql`
    mutation UndoKitchenTicket($id: String!) {
  kdsUndoTicket(input: {ticketId: $id}) {
    id
  }
}
    `;
export type UndoKitchenTicketMutationFn = Apollo.MutationFunction<UndoKitchenTicketMutation, UndoKitchenTicketMutationVariables>;

/**
 * __useUndoKitchenTicketMutation__
 *
 * To run a mutation, you first call `useUndoKitchenTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUndoKitchenTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [undoKitchenTicketMutation, { data, loading, error }] = useUndoKitchenTicketMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUndoKitchenTicketMutation(baseOptions?: Apollo.MutationHookOptions<UndoKitchenTicketMutation, UndoKitchenTicketMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UndoKitchenTicketMutation, UndoKitchenTicketMutationVariables>(UndoKitchenTicketDocument, options);
      }
export type UndoKitchenTicketMutationHookResult = ReturnType<typeof useUndoKitchenTicketMutation>;
export type UndoKitchenTicketMutationResult = Apollo.MutationResult<UndoKitchenTicketMutation>;
export type UndoKitchenTicketMutationOptions = Apollo.BaseMutationOptions<UndoKitchenTicketMutation, UndoKitchenTicketMutationVariables>;
export const UndoOrderDocument = gql`
    mutation UndoOrder($id: String!) {
  kdsUndoOrder(input: {orderId: $id}) {
    id
  }
}
    `;
export type UndoOrderMutationFn = Apollo.MutationFunction<UndoOrderMutation, UndoOrderMutationVariables>;

/**
 * __useUndoOrderMutation__
 *
 * To run a mutation, you first call `useUndoOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUndoOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [undoOrderMutation, { data, loading, error }] = useUndoOrderMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUndoOrderMutation(baseOptions?: Apollo.MutationHookOptions<UndoOrderMutation, UndoOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UndoOrderMutation, UndoOrderMutationVariables>(UndoOrderDocument, options);
      }
export type UndoOrderMutationHookResult = ReturnType<typeof useUndoOrderMutation>;
export type UndoOrderMutationResult = Apollo.MutationResult<UndoOrderMutation>;
export type UndoOrderMutationOptions = Apollo.BaseMutationOptions<UndoOrderMutation, UndoOrderMutationVariables>;
export const SetOrderWorkplaceDocument = gql`
    mutation SetOrderWorkplace($orderId: ID!, $index: Int!) {
  kdsSetOrderWorkplace(orderId: $orderId, type: PACKAGING, index: $index) {
    id
    packager {
      id
      index
    }
  }
}
    `;
export type SetOrderWorkplaceMutationFn = Apollo.MutationFunction<SetOrderWorkplaceMutation, SetOrderWorkplaceMutationVariables>;

/**
 * __useSetOrderWorkplaceMutation__
 *
 * To run a mutation, you first call `useSetOrderWorkplaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetOrderWorkplaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setOrderWorkplaceMutation, { data, loading, error }] = useSetOrderWorkplaceMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      index: // value for 'index'
 *   },
 * });
 */
export function useSetOrderWorkplaceMutation(baseOptions?: Apollo.MutationHookOptions<SetOrderWorkplaceMutation, SetOrderWorkplaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetOrderWorkplaceMutation, SetOrderWorkplaceMutationVariables>(SetOrderWorkplaceDocument, options);
      }
export type SetOrderWorkplaceMutationHookResult = ReturnType<typeof useSetOrderWorkplaceMutation>;
export type SetOrderWorkplaceMutationResult = Apollo.MutationResult<SetOrderWorkplaceMutation>;
export type SetOrderWorkplaceMutationOptions = Apollo.BaseMutationOptions<SetOrderWorkplaceMutation, SetOrderWorkplaceMutationVariables>;
export const UpdatePickupOrderStationDocument = gql`
    mutation UpdatePickupOrderStation($orderId: ID!, $stationIndex: Int!) {
  kdsSetPickupStation(orderId: $orderId, stationIndex: $stationIndex) {
    ...KdsPickupOrder
  }
}
    ${KdsPickupOrderFragmentDoc}`;
export type UpdatePickupOrderStationMutationFn = Apollo.MutationFunction<UpdatePickupOrderStationMutation, UpdatePickupOrderStationMutationVariables>;

/**
 * __useUpdatePickupOrderStationMutation__
 *
 * To run a mutation, you first call `useUpdatePickupOrderStationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePickupOrderStationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePickupOrderStationMutation, { data, loading, error }] = useUpdatePickupOrderStationMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      stationIndex: // value for 'stationIndex'
 *   },
 * });
 */
export function useUpdatePickupOrderStationMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePickupOrderStationMutation, UpdatePickupOrderStationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePickupOrderStationMutation, UpdatePickupOrderStationMutationVariables>(UpdatePickupOrderStationDocument, options);
      }
export type UpdatePickupOrderStationMutationHookResult = ReturnType<typeof useUpdatePickupOrderStationMutation>;
export type UpdatePickupOrderStationMutationResult = Apollo.MutationResult<UpdatePickupOrderStationMutation>;
export type UpdatePickupOrderStationMutationOptions = Apollo.BaseMutationOptions<UpdatePickupOrderStationMutation, UpdatePickupOrderStationMutationVariables>;
export const CompletePickupOrderDocument = gql`
    mutation CompletePickupOrder($orderId: ID!) {
  kdsOrderPickupComplete(orderId: $orderId) {
    ...KdsPickupOrder
  }
}
    ${KdsPickupOrderFragmentDoc}`;
export type CompletePickupOrderMutationFn = Apollo.MutationFunction<CompletePickupOrderMutation, CompletePickupOrderMutationVariables>;

/**
 * __useCompletePickupOrderMutation__
 *
 * To run a mutation, you first call `useCompletePickupOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompletePickupOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completePickupOrderMutation, { data, loading, error }] = useCompletePickupOrderMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useCompletePickupOrderMutation(baseOptions?: Apollo.MutationHookOptions<CompletePickupOrderMutation, CompletePickupOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompletePickupOrderMutation, CompletePickupOrderMutationVariables>(CompletePickupOrderDocument, options);
      }
export type CompletePickupOrderMutationHookResult = ReturnType<typeof useCompletePickupOrderMutation>;
export type CompletePickupOrderMutationResult = Apollo.MutationResult<CompletePickupOrderMutation>;
export type CompletePickupOrderMutationOptions = Apollo.BaseMutationOptions<CompletePickupOrderMutation, CompletePickupOrderMutationVariables>;
export const KdsExpoCompleteKitchenTicketDocument = gql`
    mutation KDSExpoCompleteKitchenTicket($id: ID!) {
  kdsCompleteKitchenTicket(id: $id) {
    ...ExpoKitchenTicket
    items {
      ...FacilityItemInfo
    }
  }
}
    ${ExpoKitchenTicketFragmentDoc}
${FacilityItemInfoFragmentDoc}`;
export type KdsExpoCompleteKitchenTicketMutationFn = Apollo.MutationFunction<KdsExpoCompleteKitchenTicketMutation, KdsExpoCompleteKitchenTicketMutationVariables>;

/**
 * __useKdsExpoCompleteKitchenTicketMutation__
 *
 * To run a mutation, you first call `useKdsExpoCompleteKitchenTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useKdsExpoCompleteKitchenTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [kdsExpoCompleteKitchenTicketMutation, { data, loading, error }] = useKdsExpoCompleteKitchenTicketMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useKdsExpoCompleteKitchenTicketMutation(baseOptions?: Apollo.MutationHookOptions<KdsExpoCompleteKitchenTicketMutation, KdsExpoCompleteKitchenTicketMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<KdsExpoCompleteKitchenTicketMutation, KdsExpoCompleteKitchenTicketMutationVariables>(KdsExpoCompleteKitchenTicketDocument, options);
      }
export type KdsExpoCompleteKitchenTicketMutationHookResult = ReturnType<typeof useKdsExpoCompleteKitchenTicketMutation>;
export type KdsExpoCompleteKitchenTicketMutationResult = Apollo.MutationResult<KdsExpoCompleteKitchenTicketMutation>;
export type KdsExpoCompleteKitchenTicketMutationOptions = Apollo.BaseMutationOptions<KdsExpoCompleteKitchenTicketMutation, KdsExpoCompleteKitchenTicketMutationVariables>;
export const KdsExpoKitchenTicketPrintedDocument = gql`
    mutation KDSExpoKitchenTicketPrinted($id: ID!) {
  kdsKitchenTicketPrinted(id: $id) {
    ...ExpoKitchenTicket
  }
}
    ${ExpoKitchenTicketFragmentDoc}`;
export type KdsExpoKitchenTicketPrintedMutationFn = Apollo.MutationFunction<KdsExpoKitchenTicketPrintedMutation, KdsExpoKitchenTicketPrintedMutationVariables>;

/**
 * __useKdsExpoKitchenTicketPrintedMutation__
 *
 * To run a mutation, you first call `useKdsExpoKitchenTicketPrintedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useKdsExpoKitchenTicketPrintedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [kdsExpoKitchenTicketPrintedMutation, { data, loading, error }] = useKdsExpoKitchenTicketPrintedMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useKdsExpoKitchenTicketPrintedMutation(baseOptions?: Apollo.MutationHookOptions<KdsExpoKitchenTicketPrintedMutation, KdsExpoKitchenTicketPrintedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<KdsExpoKitchenTicketPrintedMutation, KdsExpoKitchenTicketPrintedMutationVariables>(KdsExpoKitchenTicketPrintedDocument, options);
      }
export type KdsExpoKitchenTicketPrintedMutationHookResult = ReturnType<typeof useKdsExpoKitchenTicketPrintedMutation>;
export type KdsExpoKitchenTicketPrintedMutationResult = Apollo.MutationResult<KdsExpoKitchenTicketPrintedMutation>;
export type KdsExpoKitchenTicketPrintedMutationOptions = Apollo.BaseMutationOptions<KdsExpoKitchenTicketPrintedMutation, KdsExpoKitchenTicketPrintedMutationVariables>;
export const UpsertPromoDocument = gql`
    mutation UpsertPromo($input: PromoUpsertInput!) {
  adminUpsertPromo(input: $input) {
    ...PromoDetail
  }
}
    ${PromoDetailFragmentDoc}`;
export type UpsertPromoMutationFn = Apollo.MutationFunction<UpsertPromoMutation, UpsertPromoMutationVariables>;

/**
 * __useUpsertPromoMutation__
 *
 * To run a mutation, you first call `useUpsertPromoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertPromoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertPromoMutation, { data, loading, error }] = useUpsertPromoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertPromoMutation(baseOptions?: Apollo.MutationHookOptions<UpsertPromoMutation, UpsertPromoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertPromoMutation, UpsertPromoMutationVariables>(UpsertPromoDocument, options);
      }
export type UpsertPromoMutationHookResult = ReturnType<typeof useUpsertPromoMutation>;
export type UpsertPromoMutationResult = Apollo.MutationResult<UpsertPromoMutation>;
export type UpsertPromoMutationOptions = Apollo.BaseMutationOptions<UpsertPromoMutation, UpsertPromoMutationVariables>;
export const DisablePromoDocument = gql`
    mutation DisablePromo($id: ID!) {
  adminDisablePromo(id: $id) {
    ...PromoDetail
  }
}
    ${PromoDetailFragmentDoc}`;
export type DisablePromoMutationFn = Apollo.MutationFunction<DisablePromoMutation, DisablePromoMutationVariables>;

/**
 * __useDisablePromoMutation__
 *
 * To run a mutation, you first call `useDisablePromoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisablePromoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disablePromoMutation, { data, loading, error }] = useDisablePromoMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDisablePromoMutation(baseOptions?: Apollo.MutationHookOptions<DisablePromoMutation, DisablePromoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DisablePromoMutation, DisablePromoMutationVariables>(DisablePromoDocument, options);
      }
export type DisablePromoMutationHookResult = ReturnType<typeof useDisablePromoMutation>;
export type DisablePromoMutationResult = Apollo.MutationResult<DisablePromoMutation>;
export type DisablePromoMutationOptions = Apollo.BaseMutationOptions<DisablePromoMutation, DisablePromoMutationVariables>;
export const DeleteOnePromoRuleDocument = gql`
    mutation DeleteOnePromoRule($id: ID!) {
  adminDeleteOnePromoRule(id: $id) {
    id
  }
}
    `;
export type DeleteOnePromoRuleMutationFn = Apollo.MutationFunction<DeleteOnePromoRuleMutation, DeleteOnePromoRuleMutationVariables>;

/**
 * __useDeleteOnePromoRuleMutation__
 *
 * To run a mutation, you first call `useDeleteOnePromoRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOnePromoRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOnePromoRuleMutation, { data, loading, error }] = useDeleteOnePromoRuleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteOnePromoRuleMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOnePromoRuleMutation, DeleteOnePromoRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOnePromoRuleMutation, DeleteOnePromoRuleMutationVariables>(DeleteOnePromoRuleDocument, options);
      }
export type DeleteOnePromoRuleMutationHookResult = ReturnType<typeof useDeleteOnePromoRuleMutation>;
export type DeleteOnePromoRuleMutationResult = Apollo.MutationResult<DeleteOnePromoRuleMutation>;
export type DeleteOnePromoRuleMutationOptions = Apollo.BaseMutationOptions<DeleteOnePromoRuleMutation, DeleteOnePromoRuleMutationVariables>;
export const UpdatePromoBannerDocument = gql`
    mutation UpdatePromoBanner($data: PromoBannerUpdateInput!, $where: PromoBannerWhereUniqueInput!) {
  adminUpdatePromoBanner(data: $data, where: $where) {
    ...PromoBanner
  }
}
    ${PromoBannerFragmentDoc}`;
export type UpdatePromoBannerMutationFn = Apollo.MutationFunction<UpdatePromoBannerMutation, UpdatePromoBannerMutationVariables>;

/**
 * __useUpdatePromoBannerMutation__
 *
 * To run a mutation, you first call `useUpdatePromoBannerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePromoBannerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePromoBannerMutation, { data, loading, error }] = useUpdatePromoBannerMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdatePromoBannerMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePromoBannerMutation, UpdatePromoBannerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePromoBannerMutation, UpdatePromoBannerMutationVariables>(UpdatePromoBannerDocument, options);
      }
export type UpdatePromoBannerMutationHookResult = ReturnType<typeof useUpdatePromoBannerMutation>;
export type UpdatePromoBannerMutationResult = Apollo.MutationResult<UpdatePromoBannerMutation>;
export type UpdatePromoBannerMutationOptions = Apollo.BaseMutationOptions<UpdatePromoBannerMutation, UpdatePromoBannerMutationVariables>;
export const SendNotificationsDocument = gql`
    mutation SendNotifications($message: String!, $title: String!, $send: Boolean!) {
  adminSendMessageToAll(message: $message, title: $title, send: $send)
}
    `;
export type SendNotificationsMutationFn = Apollo.MutationFunction<SendNotificationsMutation, SendNotificationsMutationVariables>;

/**
 * __useSendNotificationsMutation__
 *
 * To run a mutation, you first call `useSendNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendNotificationsMutation, { data, loading, error }] = useSendNotificationsMutation({
 *   variables: {
 *      message: // value for 'message'
 *      title: // value for 'title'
 *      send: // value for 'send'
 *   },
 * });
 */
export function useSendNotificationsMutation(baseOptions?: Apollo.MutationHookOptions<SendNotificationsMutation, SendNotificationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendNotificationsMutation, SendNotificationsMutationVariables>(SendNotificationsDocument, options);
      }
export type SendNotificationsMutationHookResult = ReturnType<typeof useSendNotificationsMutation>;
export type SendNotificationsMutationResult = Apollo.MutationResult<SendNotificationsMutation>;
export type SendNotificationsMutationOptions = Apollo.BaseMutationOptions<SendNotificationsMutation, SendNotificationsMutationVariables>;
export const ClientLogDocument = gql`
    mutation ClientLog($message: String!, $meta: String) {
  clientLog(message: $message, meta: $meta)
}
    `;
export type ClientLogMutationFn = Apollo.MutationFunction<ClientLogMutation, ClientLogMutationVariables>;

/**
 * __useClientLogMutation__
 *
 * To run a mutation, you first call `useClientLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClientLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clientLogMutation, { data, loading, error }] = useClientLogMutation({
 *   variables: {
 *      message: // value for 'message'
 *      meta: // value for 'meta'
 *   },
 * });
 */
export function useClientLogMutation(baseOptions?: Apollo.MutationHookOptions<ClientLogMutation, ClientLogMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ClientLogMutation, ClientLogMutationVariables>(ClientLogDocument, options);
      }
export type ClientLogMutationHookResult = ReturnType<typeof useClientLogMutation>;
export type ClientLogMutationResult = Apollo.MutationResult<ClientLogMutation>;
export type ClientLogMutationOptions = Apollo.BaseMutationOptions<ClientLogMutation, ClientLogMutationVariables>;
export const InviteUserDocument = gql`
    mutation InviteUser($input: UserInviteInput!) {
  adminUserInvite(input: $input) {
    ...User
  }
}
    ${UserFragmentDoc}`;
export type InviteUserMutationFn = Apollo.MutationFunction<InviteUserMutation, InviteUserMutationVariables>;

/**
 * __useInviteUserMutation__
 *
 * To run a mutation, you first call `useInviteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteUserMutation, { data, loading, error }] = useInviteUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInviteUserMutation(baseOptions?: Apollo.MutationHookOptions<InviteUserMutation, InviteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InviteUserMutation, InviteUserMutationVariables>(InviteUserDocument, options);
      }
export type InviteUserMutationHookResult = ReturnType<typeof useInviteUserMutation>;
export type InviteUserMutationResult = Apollo.MutationResult<InviteUserMutation>;
export type InviteUserMutationOptions = Apollo.BaseMutationOptions<InviteUserMutation, InviteUserMutationVariables>;
export const AddUpdateCartUpsellItemsDocument = gql`
    mutation addUpdateCartUpsellItems($data: [UpsellMenuItemCreateInput!]!, $platform: ClientPlatformInput!) {
  adminUpdateCartUpsellItem(input: {data: $data, platform: $platform}) {
    id
    sortId
  }
}
    `;
export type AddUpdateCartUpsellItemsMutationFn = Apollo.MutationFunction<AddUpdateCartUpsellItemsMutation, AddUpdateCartUpsellItemsMutationVariables>;

/**
 * __useAddUpdateCartUpsellItemsMutation__
 *
 * To run a mutation, you first call `useAddUpdateCartUpsellItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUpdateCartUpsellItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUpdateCartUpsellItemsMutation, { data, loading, error }] = useAddUpdateCartUpsellItemsMutation({
 *   variables: {
 *      data: // value for 'data'
 *      platform: // value for 'platform'
 *   },
 * });
 */
export function useAddUpdateCartUpsellItemsMutation(baseOptions?: Apollo.MutationHookOptions<AddUpdateCartUpsellItemsMutation, AddUpdateCartUpsellItemsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddUpdateCartUpsellItemsMutation, AddUpdateCartUpsellItemsMutationVariables>(AddUpdateCartUpsellItemsDocument, options);
      }
export type AddUpdateCartUpsellItemsMutationHookResult = ReturnType<typeof useAddUpdateCartUpsellItemsMutation>;
export type AddUpdateCartUpsellItemsMutationResult = Apollo.MutationResult<AddUpdateCartUpsellItemsMutation>;
export type AddUpdateCartUpsellItemsMutationOptions = Apollo.BaseMutationOptions<AddUpdateCartUpsellItemsMutation, AddUpdateCartUpsellItemsMutationVariables>;
export const MeDocument = gql`
    query Me {
  me {
    id
    name
    roles
    kitchens {
      id
      name
    }
    facilities {
      ...Facility
    }
  }
}
    ${FacilityFragmentDoc}`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const AddressSuggestionDocument = gql`
    query AddressSuggestion($address: String!) {
  addressAutocompleteOptions(input: $address)
}
    `;

/**
 * __useAddressSuggestionQuery__
 *
 * To run a query within a React component, call `useAddressSuggestionQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddressSuggestionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddressSuggestionQuery({
 *   variables: {
 *      address: // value for 'address'
 *   },
 * });
 */
export function useAddressSuggestionQuery(baseOptions: Apollo.QueryHookOptions<AddressSuggestionQuery, AddressSuggestionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddressSuggestionQuery, AddressSuggestionQueryVariables>(AddressSuggestionDocument, options);
      }
export function useAddressSuggestionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddressSuggestionQuery, AddressSuggestionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddressSuggestionQuery, AddressSuggestionQueryVariables>(AddressSuggestionDocument, options);
        }
export type AddressSuggestionQueryHookResult = ReturnType<typeof useAddressSuggestionQuery>;
export type AddressSuggestionLazyQueryHookResult = ReturnType<typeof useAddressSuggestionLazyQuery>;
export type AddressSuggestionQueryResult = Apollo.QueryResult<AddressSuggestionQuery, AddressSuggestionQueryVariables>;
export const OrderMonitoringDocument = gql`
    query OrderMonitoring($start: DateTime!, $end: DateTime!) {
  adminOrders(start: $start, end: $end) {
    ...OrderInfo
  }
}
    ${OrderInfoFragmentDoc}`;

/**
 * __useOrderMonitoringQuery__
 *
 * To run a query within a React component, call `useOrderMonitoringQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderMonitoringQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderMonitoringQuery({
 *   variables: {
 *      start: // value for 'start'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useOrderMonitoringQuery(baseOptions: Apollo.QueryHookOptions<OrderMonitoringQuery, OrderMonitoringQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrderMonitoringQuery, OrderMonitoringQueryVariables>(OrderMonitoringDocument, options);
      }
export function useOrderMonitoringLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderMonitoringQuery, OrderMonitoringQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrderMonitoringQuery, OrderMonitoringQueryVariables>(OrderMonitoringDocument, options);
        }
export type OrderMonitoringQueryHookResult = ReturnType<typeof useOrderMonitoringQuery>;
export type OrderMonitoringLazyQueryHookResult = ReturnType<typeof useOrderMonitoringLazyQuery>;
export type OrderMonitoringQueryResult = Apollo.QueryResult<OrderMonitoringQuery, OrderMonitoringQueryVariables>;
export const OrderDocument = gql`
    query Order($id: ID!) {
  adminOrder(id: $id) {
    ...OrderInfo
    items {
      ...OrderItemInfo
      menuItem {
        ...KitchenMenuItemInfo
        kitchen {
          id
          name
        }
      }
      mealPackOrderItems {
        ...OrderItemInfo
        extraItems {
          ...OrderExtraItemInfo
        }
      }
      extraItems {
        ...OrderExtraItemInfo
      }
    }
    customer {
      ...CustomerInfo
      tags {
        ...TagInfo
      }
      user {
        ...UserInfo
      }
    }
    refundableItems {
      ...RefundableItems
    }
    refunds {
      ...Refunds
    }
  }
}
    ${OrderInfoFragmentDoc}
${OrderItemInfoFragmentDoc}
${KitchenMenuItemInfoFragmentDoc}
${OrderExtraItemInfoFragmentDoc}
${CustomerInfoFragmentDoc}
${TagInfoFragmentDoc}
${UserInfoFragmentDoc}
${RefundableItemsFragmentDoc}
${RefundsFragmentDoc}`;

/**
 * __useOrderQuery__
 *
 * To run a query within a React component, call `useOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrderQuery(baseOptions: Apollo.QueryHookOptions<OrderQuery, OrderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrderQuery, OrderQueryVariables>(OrderDocument, options);
      }
export function useOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderQuery, OrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrderQuery, OrderQueryVariables>(OrderDocument, options);
        }
export type OrderQueryHookResult = ReturnType<typeof useOrderQuery>;
export type OrderLazyQueryHookResult = ReturnType<typeof useOrderLazyQuery>;
export type OrderQueryResult = Apollo.QueryResult<OrderQuery, OrderQueryVariables>;
export const OrderBundlesDocument = gql`
    query OrderBundles($start: DateTime!, $end: DateTime!) {
  adminOrderBundles(start: $start, end: $end) {
    ...OrderBundleInfo
    orders {
      ...OrderInfo
      customer {
        ...CustomerInfo
        user {
          ...UserInfo
        }
        tags {
          ...TagInfo
        }
      }
    }
  }
}
    ${OrderBundleInfoFragmentDoc}
${OrderInfoFragmentDoc}
${CustomerInfoFragmentDoc}
${UserInfoFragmentDoc}
${TagInfoFragmentDoc}`;

/**
 * __useOrderBundlesQuery__
 *
 * To run a query within a React component, call `useOrderBundlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderBundlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderBundlesQuery({
 *   variables: {
 *      start: // value for 'start'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useOrderBundlesQuery(baseOptions: Apollo.QueryHookOptions<OrderBundlesQuery, OrderBundlesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrderBundlesQuery, OrderBundlesQueryVariables>(OrderBundlesDocument, options);
      }
export function useOrderBundlesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderBundlesQuery, OrderBundlesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrderBundlesQuery, OrderBundlesQueryVariables>(OrderBundlesDocument, options);
        }
export type OrderBundlesQueryHookResult = ReturnType<typeof useOrderBundlesQuery>;
export type OrderBundlesLazyQueryHookResult = ReturnType<typeof useOrderBundlesLazyQuery>;
export type OrderBundlesQueryResult = Apollo.QueryResult<OrderBundlesQuery, OrderBundlesQueryVariables>;
export const UnbundledOrdersDocument = gql`
    query UnbundledOrders($start: DateTime!, $end: DateTime!) {
  adminOrders(
    start: $start
    end: $end
    bundled: true
    method: DELIVERY
    isCatering: false
  ) {
    ...OrderInfo
    customer {
      ...CustomerInfo
      user {
        ...UserInfo
      }
      tags {
        ...TagInfo
      }
    }
  }
}
    ${OrderInfoFragmentDoc}
${CustomerInfoFragmentDoc}
${UserInfoFragmentDoc}
${TagInfoFragmentDoc}`;

/**
 * __useUnbundledOrdersQuery__
 *
 * To run a query within a React component, call `useUnbundledOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnbundledOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnbundledOrdersQuery({
 *   variables: {
 *      start: // value for 'start'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useUnbundledOrdersQuery(baseOptions: Apollo.QueryHookOptions<UnbundledOrdersQuery, UnbundledOrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UnbundledOrdersQuery, UnbundledOrdersQueryVariables>(UnbundledOrdersDocument, options);
      }
export function useUnbundledOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UnbundledOrdersQuery, UnbundledOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UnbundledOrdersQuery, UnbundledOrdersQueryVariables>(UnbundledOrdersDocument, options);
        }
export type UnbundledOrdersQueryHookResult = ReturnType<typeof useUnbundledOrdersQuery>;
export type UnbundledOrdersLazyQueryHookResult = ReturnType<typeof useUnbundledOrdersLazyQuery>;
export type UnbundledOrdersQueryResult = Apollo.QueryResult<UnbundledOrdersQuery, UnbundledOrdersQueryVariables>;
export const StationsDocument = gql`
    query Stations($where: StationWhereInput) {
  adminStations(where: $where) {
    id
    name
  }
}
    `;

/**
 * __useStationsQuery__
 *
 * To run a query within a React component, call `useStationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStationsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useStationsQuery(baseOptions?: Apollo.QueryHookOptions<StationsQuery, StationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StationsQuery, StationsQueryVariables>(StationsDocument, options);
      }
export function useStationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StationsQuery, StationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StationsQuery, StationsQueryVariables>(StationsDocument, options);
        }
export type StationsQueryHookResult = ReturnType<typeof useStationsQuery>;
export type StationsLazyQueryHookResult = ReturnType<typeof useStationsLazyQuery>;
export type StationsQueryResult = Apollo.QueryResult<StationsQuery, StationsQueryVariables>;
export const MenuCategoriesDocument = gql`
    query MenuCategories($type: MenuCategoryType) {
  adminMenuCategories(type: $type) {
    ...MenuCategoryInfo
  }
}
    ${MenuCategoryInfoFragmentDoc}`;

/**
 * __useMenuCategoriesQuery__
 *
 * To run a query within a React component, call `useMenuCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMenuCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMenuCategoriesQuery({
 *   variables: {
 *      type: // value for 'type'
 *   },
 * });
 */
export function useMenuCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<MenuCategoriesQuery, MenuCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MenuCategoriesQuery, MenuCategoriesQueryVariables>(MenuCategoriesDocument, options);
      }
export function useMenuCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MenuCategoriesQuery, MenuCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MenuCategoriesQuery, MenuCategoriesQueryVariables>(MenuCategoriesDocument, options);
        }
export type MenuCategoriesQueryHookResult = ReturnType<typeof useMenuCategoriesQuery>;
export type MenuCategoriesLazyQueryHookResult = ReturnType<typeof useMenuCategoriesLazyQuery>;
export type MenuCategoriesQueryResult = Apollo.QueryResult<MenuCategoriesQuery, MenuCategoriesQueryVariables>;
export const StationWorkplaceDocument = gql`
    query StationWorkplace($stationId: ID!) {
  adminStation(id: $stationId) {
    id
    name
  }
}
    `;

/**
 * __useStationWorkplaceQuery__
 *
 * To run a query within a React component, call `useStationWorkplaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useStationWorkplaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStationWorkplaceQuery({
 *   variables: {
 *      stationId: // value for 'stationId'
 *   },
 * });
 */
export function useStationWorkplaceQuery(baseOptions: Apollo.QueryHookOptions<StationWorkplaceQuery, StationWorkplaceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StationWorkplaceQuery, StationWorkplaceQueryVariables>(StationWorkplaceDocument, options);
      }
export function useStationWorkplaceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StationWorkplaceQuery, StationWorkplaceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StationWorkplaceQuery, StationWorkplaceQueryVariables>(StationWorkplaceDocument, options);
        }
export type StationWorkplaceQueryHookResult = ReturnType<typeof useStationWorkplaceQuery>;
export type StationWorkplaceLazyQueryHookResult = ReturnType<typeof useStationWorkplaceLazyQuery>;
export type StationWorkplaceQueryResult = Apollo.QueryResult<StationWorkplaceQuery, StationWorkplaceQueryVariables>;
export const KdsStationTicketsDocument = gql`
    query KdsStationTickets($stationId: ID!, $recentlyDoneLimit: Int!) {
  kdsStationTickets(stationId: $stationId) {
    ...KitchenTicket
  }
  kdsDoneStationTickets(stationId: $stationId, limit: $recentlyDoneLimit) {
    id
    order {
      id
      shortId
      refundableItems {
        ...RefundableItems
      }
      platform
    }
  }
}
    ${KitchenTicketFragmentDoc}
${RefundableItemsFragmentDoc}`;

/**
 * __useKdsStationTicketsQuery__
 *
 * To run a query within a React component, call `useKdsStationTicketsQuery` and pass it any options that fit your needs.
 * When your component renders, `useKdsStationTicketsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKdsStationTicketsQuery({
 *   variables: {
 *      stationId: // value for 'stationId'
 *      recentlyDoneLimit: // value for 'recentlyDoneLimit'
 *   },
 * });
 */
export function useKdsStationTicketsQuery(baseOptions: Apollo.QueryHookOptions<KdsStationTicketsQuery, KdsStationTicketsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<KdsStationTicketsQuery, KdsStationTicketsQueryVariables>(KdsStationTicketsDocument, options);
      }
export function useKdsStationTicketsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KdsStationTicketsQuery, KdsStationTicketsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<KdsStationTicketsQuery, KdsStationTicketsQueryVariables>(KdsStationTicketsDocument, options);
        }
export type KdsStationTicketsQueryHookResult = ReturnType<typeof useKdsStationTicketsQuery>;
export type KdsStationTicketsLazyQueryHookResult = ReturnType<typeof useKdsStationTicketsLazyQuery>;
export type KdsStationTicketsQueryResult = Apollo.QueryResult<KdsStationTicketsQuery, KdsStationTicketsQueryVariables>;
export const FutureKdsStationTicketsDocument = gql`
    query futureKdsStationTickets($stationId: ID!, $startDate: DateTime!, $endDate: DateTime!) {
  kdsStationTickets(
    stationId: $stationId
    startDate: $startDate
    endDate: $endDate
  ) {
    ...KitchenTicket
  }
}
    ${KitchenTicketFragmentDoc}`;

/**
 * __useFutureKdsStationTicketsQuery__
 *
 * To run a query within a React component, call `useFutureKdsStationTicketsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFutureKdsStationTicketsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFutureKdsStationTicketsQuery({
 *   variables: {
 *      stationId: // value for 'stationId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useFutureKdsStationTicketsQuery(baseOptions: Apollo.QueryHookOptions<FutureKdsStationTicketsQuery, FutureKdsStationTicketsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FutureKdsStationTicketsQuery, FutureKdsStationTicketsQueryVariables>(FutureKdsStationTicketsDocument, options);
      }
export function useFutureKdsStationTicketsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FutureKdsStationTicketsQuery, FutureKdsStationTicketsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FutureKdsStationTicketsQuery, FutureKdsStationTicketsQueryVariables>(FutureKdsStationTicketsDocument, options);
        }
export type FutureKdsStationTicketsQueryHookResult = ReturnType<typeof useFutureKdsStationTicketsQuery>;
export type FutureKdsStationTicketsLazyQueryHookResult = ReturnType<typeof useFutureKdsStationTicketsLazyQuery>;
export type FutureKdsStationTicketsQueryResult = Apollo.QueryResult<FutureKdsStationTicketsQuery, FutureKdsStationTicketsQueryVariables>;
export const MealPackItemDocument = gql`
    query MealPackItem($id: ID!) {
  adminKitchenMenuItem(id: $id) {
    ...KitchenMenuItemInfo
    kitchen {
      id
      name
    }
    extras {
      id
      name
    }
    mealPackItems {
      ...KitchenMenuItemShortInfo
      kitchen {
        id
        name
      }
    }
    menuCategories {
      ...MenuCategoryInfo
    }
  }
}
    ${KitchenMenuItemInfoFragmentDoc}
${KitchenMenuItemShortInfoFragmentDoc}
${MenuCategoryInfoFragmentDoc}`;

/**
 * __useMealPackItemQuery__
 *
 * To run a query within a React component, call `useMealPackItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useMealPackItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMealPackItemQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMealPackItemQuery(baseOptions: Apollo.QueryHookOptions<MealPackItemQuery, MealPackItemQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MealPackItemQuery, MealPackItemQueryVariables>(MealPackItemDocument, options);
      }
export function useMealPackItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MealPackItemQuery, MealPackItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MealPackItemQuery, MealPackItemQueryVariables>(MealPackItemDocument, options);
        }
export type MealPackItemQueryHookResult = ReturnType<typeof useMealPackItemQuery>;
export type MealPackItemLazyQueryHookResult = ReturnType<typeof useMealPackItemLazyQuery>;
export type MealPackItemQueryResult = Apollo.QueryResult<MealPackItemQuery, MealPackItemQueryVariables>;
export const MenuItemSelectOptionsDocument = gql`
    query MenuItemSelectOptions($kitchenId: ID!) {
  adminExtras(kitchenId: $kitchenId) {
    id
    name
    items {
      id
      name
      isActive
      sortId
      createdAt
    }
  }
  adminMenuCategories {
    ...MenuCategoryInfo
  }
}
    ${MenuCategoryInfoFragmentDoc}`;

/**
 * __useMenuItemSelectOptionsQuery__
 *
 * To run a query within a React component, call `useMenuItemSelectOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMenuItemSelectOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMenuItemSelectOptionsQuery({
 *   variables: {
 *      kitchenId: // value for 'kitchenId'
 *   },
 * });
 */
export function useMenuItemSelectOptionsQuery(baseOptions: Apollo.QueryHookOptions<MenuItemSelectOptionsQuery, MenuItemSelectOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MenuItemSelectOptionsQuery, MenuItemSelectOptionsQueryVariables>(MenuItemSelectOptionsDocument, options);
      }
export function useMenuItemSelectOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MenuItemSelectOptionsQuery, MenuItemSelectOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MenuItemSelectOptionsQuery, MenuItemSelectOptionsQueryVariables>(MenuItemSelectOptionsDocument, options);
        }
export type MenuItemSelectOptionsQueryHookResult = ReturnType<typeof useMenuItemSelectOptionsQuery>;
export type MenuItemSelectOptionsLazyQueryHookResult = ReturnType<typeof useMenuItemSelectOptionsLazyQuery>;
export type MenuItemSelectOptionsQueryResult = Apollo.QueryResult<MenuItemSelectOptionsQuery, MenuItemSelectOptionsQueryVariables>;
export const KitchenMenuItemsDocument = gql`
    query KitchenMenuItems($kitchenIds: [ID!]) {
  adminKitchenMenuItems(kitchenIds: $kitchenIds) {
    id
    name
    ...KitchenMenuItemInfo
    kitchen {
      id
      name
    }
    station {
      id
      name
    }
  }
}
    ${KitchenMenuItemInfoFragmentDoc}`;

/**
 * __useKitchenMenuItemsQuery__
 *
 * To run a query within a React component, call `useKitchenMenuItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useKitchenMenuItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKitchenMenuItemsQuery({
 *   variables: {
 *      kitchenIds: // value for 'kitchenIds'
 *   },
 * });
 */
export function useKitchenMenuItemsQuery(baseOptions?: Apollo.QueryHookOptions<KitchenMenuItemsQuery, KitchenMenuItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<KitchenMenuItemsQuery, KitchenMenuItemsQueryVariables>(KitchenMenuItemsDocument, options);
      }
export function useKitchenMenuItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KitchenMenuItemsQuery, KitchenMenuItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<KitchenMenuItemsQuery, KitchenMenuItemsQueryVariables>(KitchenMenuItemsDocument, options);
        }
export type KitchenMenuItemsQueryHookResult = ReturnType<typeof useKitchenMenuItemsQuery>;
export type KitchenMenuItemsLazyQueryHookResult = ReturnType<typeof useKitchenMenuItemsLazyQuery>;
export type KitchenMenuItemsQueryResult = Apollo.QueryResult<KitchenMenuItemsQuery, KitchenMenuItemsQueryVariables>;
export const SearchMenuItemsDocument = gql`
    query SearchMenuItems($searchTerm: String!, $parentUpsellItemId: ID, $kitchenIds: [ID!], $availability: [MenuItemAvailability!]) {
  adminKitchenMenuItems(
    searchTerm: $searchTerm
    parentUpsellItemId: $parentUpsellItemId
    kitchenIds: $kitchenIds
    availability: $availability
  ) {
    ...SearchMenuItemInfo
  }
}
    ${SearchMenuItemInfoFragmentDoc}`;

/**
 * __useSearchMenuItemsQuery__
 *
 * To run a query within a React component, call `useSearchMenuItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchMenuItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchMenuItemsQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *      parentUpsellItemId: // value for 'parentUpsellItemId'
 *      kitchenIds: // value for 'kitchenIds'
 *      availability: // value for 'availability'
 *   },
 * });
 */
export function useSearchMenuItemsQuery(baseOptions: Apollo.QueryHookOptions<SearchMenuItemsQuery, SearchMenuItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchMenuItemsQuery, SearchMenuItemsQueryVariables>(SearchMenuItemsDocument, options);
      }
export function useSearchMenuItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchMenuItemsQuery, SearchMenuItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchMenuItemsQuery, SearchMenuItemsQueryVariables>(SearchMenuItemsDocument, options);
        }
export type SearchMenuItemsQueryHookResult = ReturnType<typeof useSearchMenuItemsQuery>;
export type SearchMenuItemsLazyQueryHookResult = ReturnType<typeof useSearchMenuItemsLazyQuery>;
export type SearchMenuItemsQueryResult = Apollo.QueryResult<SearchMenuItemsQuery, SearchMenuItemsQueryVariables>;
export const SingleKitchenMenuItemsDocument = gql`
    query SingleKitchenMenuItems($kitchenIds: [ID!]) {
  adminKitchenMenuItems(kitchenIds: $kitchenIds) {
    ...KitchenMenuItemInfo
    kitchen {
      id
      name
    }
    extras {
      id
      name
      items {
        id
        name
      }
    }
    mealPackItems {
      ...KitchenMenuItemShortInfo
      kitchen {
        id
        name
      }
    }
  }
}
    ${KitchenMenuItemInfoFragmentDoc}
${KitchenMenuItemShortInfoFragmentDoc}`;

/**
 * __useSingleKitchenMenuItemsQuery__
 *
 * To run a query within a React component, call `useSingleKitchenMenuItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSingleKitchenMenuItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSingleKitchenMenuItemsQuery({
 *   variables: {
 *      kitchenIds: // value for 'kitchenIds'
 *   },
 * });
 */
export function useSingleKitchenMenuItemsQuery(baseOptions?: Apollo.QueryHookOptions<SingleKitchenMenuItemsQuery, SingleKitchenMenuItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SingleKitchenMenuItemsQuery, SingleKitchenMenuItemsQueryVariables>(SingleKitchenMenuItemsDocument, options);
      }
export function useSingleKitchenMenuItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SingleKitchenMenuItemsQuery, SingleKitchenMenuItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SingleKitchenMenuItemsQuery, SingleKitchenMenuItemsQueryVariables>(SingleKitchenMenuItemsDocument, options);
        }
export type SingleKitchenMenuItemsQueryHookResult = ReturnType<typeof useSingleKitchenMenuItemsQuery>;
export type SingleKitchenMenuItemsLazyQueryHookResult = ReturnType<typeof useSingleKitchenMenuItemsLazyQuery>;
export type SingleKitchenMenuItemsQueryResult = Apollo.QueryResult<SingleKitchenMenuItemsQuery, SingleKitchenMenuItemsQueryVariables>;
export const AllKitchenMenuItemsDocument = gql`
    query AllKitchenMenuItems($activeItems: Boolean!) {
  adminKitchenMenuItems(activeItems: $activeItems) {
    ...KitchenMenuItemInfo
    mealPackStatus
    kitchen {
      id
      name
    }
    mealPackItems {
      ...KitchenMenuItemShortInfo
      kitchen {
        id
        name
      }
    }
    extras {
      id
      name
      items {
        id
        name
      }
    }
  }
}
    ${KitchenMenuItemInfoFragmentDoc}
${KitchenMenuItemShortInfoFragmentDoc}`;

/**
 * __useAllKitchenMenuItemsQuery__
 *
 * To run a query within a React component, call `useAllKitchenMenuItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllKitchenMenuItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllKitchenMenuItemsQuery({
 *   variables: {
 *      activeItems: // value for 'activeItems'
 *   },
 * });
 */
export function useAllKitchenMenuItemsQuery(baseOptions: Apollo.QueryHookOptions<AllKitchenMenuItemsQuery, AllKitchenMenuItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllKitchenMenuItemsQuery, AllKitchenMenuItemsQueryVariables>(AllKitchenMenuItemsDocument, options);
      }
export function useAllKitchenMenuItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllKitchenMenuItemsQuery, AllKitchenMenuItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllKitchenMenuItemsQuery, AllKitchenMenuItemsQueryVariables>(AllKitchenMenuItemsDocument, options);
        }
export type AllKitchenMenuItemsQueryHookResult = ReturnType<typeof useAllKitchenMenuItemsQuery>;
export type AllKitchenMenuItemsLazyQueryHookResult = ReturnType<typeof useAllKitchenMenuItemsLazyQuery>;
export type AllKitchenMenuItemsQueryResult = Apollo.QueryResult<AllKitchenMenuItemsQuery, AllKitchenMenuItemsQueryVariables>;
export const WorkplacesDocument = gql`
    query Workplaces($where: WorkplaceWhereInput) {
  adminWorkplaces(where: $where) {
    id
    type
    index
  }
}
    `;

/**
 * __useWorkplacesQuery__
 *
 * To run a query within a React component, call `useWorkplacesQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkplacesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkplacesQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useWorkplacesQuery(baseOptions?: Apollo.QueryHookOptions<WorkplacesQuery, WorkplacesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkplacesQuery, WorkplacesQueryVariables>(WorkplacesDocument, options);
      }
export function useWorkplacesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkplacesQuery, WorkplacesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkplacesQuery, WorkplacesQueryVariables>(WorkplacesDocument, options);
        }
export type WorkplacesQueryHookResult = ReturnType<typeof useWorkplacesQuery>;
export type WorkplacesLazyQueryHookResult = ReturnType<typeof useWorkplacesLazyQuery>;
export type WorkplacesQueryResult = Apollo.QueryResult<WorkplacesQuery, WorkplacesQueryVariables>;
export const KitchenWorkplaceDocument = gql`
    query KitchenWorkplace($kitchenId: ID!) {
  adminKitchen(kitchenId: $kitchenId) {
    ...KitchenShortInfo
  }
}
    ${KitchenShortInfoFragmentDoc}`;

/**
 * __useKitchenWorkplaceQuery__
 *
 * To run a query within a React component, call `useKitchenWorkplaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useKitchenWorkplaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKitchenWorkplaceQuery({
 *   variables: {
 *      kitchenId: // value for 'kitchenId'
 *   },
 * });
 */
export function useKitchenWorkplaceQuery(baseOptions: Apollo.QueryHookOptions<KitchenWorkplaceQuery, KitchenWorkplaceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<KitchenWorkplaceQuery, KitchenWorkplaceQueryVariables>(KitchenWorkplaceDocument, options);
      }
export function useKitchenWorkplaceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KitchenWorkplaceQuery, KitchenWorkplaceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<KitchenWorkplaceQuery, KitchenWorkplaceQueryVariables>(KitchenWorkplaceDocument, options);
        }
export type KitchenWorkplaceQueryHookResult = ReturnType<typeof useKitchenWorkplaceQuery>;
export type KitchenWorkplaceLazyQueryHookResult = ReturnType<typeof useKitchenWorkplaceLazyQuery>;
export type KitchenWorkplaceQueryResult = Apollo.QueryResult<KitchenWorkplaceQuery, KitchenWorkplaceQueryVariables>;
export const RestaurantOwnersDocument = gql`
    query RestaurantOwners {
  adminRestaurantOwners {
    id
    name
  }
}
    `;

/**
 * __useRestaurantOwnersQuery__
 *
 * To run a query within a React component, call `useRestaurantOwnersQuery` and pass it any options that fit your needs.
 * When your component renders, `useRestaurantOwnersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRestaurantOwnersQuery({
 *   variables: {
 *   },
 * });
 */
export function useRestaurantOwnersQuery(baseOptions?: Apollo.QueryHookOptions<RestaurantOwnersQuery, RestaurantOwnersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RestaurantOwnersQuery, RestaurantOwnersQueryVariables>(RestaurantOwnersDocument, options);
      }
export function useRestaurantOwnersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RestaurantOwnersQuery, RestaurantOwnersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RestaurantOwnersQuery, RestaurantOwnersQueryVariables>(RestaurantOwnersDocument, options);
        }
export type RestaurantOwnersQueryHookResult = ReturnType<typeof useRestaurantOwnersQuery>;
export type RestaurantOwnersLazyQueryHookResult = ReturnType<typeof useRestaurantOwnersLazyQuery>;
export type RestaurantOwnersQueryResult = Apollo.QueryResult<RestaurantOwnersQuery, RestaurantOwnersQueryVariables>;
export const MealPackListDocument = gql`
    query MealPackList {
  adminKitchens {
    id
    type
    kitchenMenuItems {
      ...KitchenMenuItemInfo
      mealPackStatus
      extras {
        id
        name
        items {
          id
          name
        }
      }
      kitchen {
        id
        name
      }
      mealPackItems {
        ...KitchenMenuItemShortInfo
        kitchen {
          id
          name
          status
        }
      }
    }
  }
}
    ${KitchenMenuItemInfoFragmentDoc}
${KitchenMenuItemShortInfoFragmentDoc}`;

/**
 * __useMealPackListQuery__
 *
 * To run a query within a React component, call `useMealPackListQuery` and pass it any options that fit your needs.
 * When your component renders, `useMealPackListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMealPackListQuery({
 *   variables: {
 *   },
 * });
 */
export function useMealPackListQuery(baseOptions?: Apollo.QueryHookOptions<MealPackListQuery, MealPackListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MealPackListQuery, MealPackListQueryVariables>(MealPackListDocument, options);
      }
export function useMealPackListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MealPackListQuery, MealPackListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MealPackListQuery, MealPackListQueryVariables>(MealPackListDocument, options);
        }
export type MealPackListQueryHookResult = ReturnType<typeof useMealPackListQuery>;
export type MealPackListLazyQueryHookResult = ReturnType<typeof useMealPackListLazyQuery>;
export type MealPackListQueryResult = Apollo.QueryResult<MealPackListQuery, MealPackListQueryVariables>;
export const CustomerPageDocument = gql`
    query CustomerPage($customerId: ID!) {
  customer(id: $customerId) {
    ...UserInfo
    customerInfo {
      ...CustomerInfo
      tags {
        ...TagInfo
      }
      addresses {
        ...AddressInfo
      }
      orders {
        ...CustomerOrderInfo
        promos {
          id
          code
        }
        items {
          id
        }
      }
    }
  }
}
    ${UserInfoFragmentDoc}
${CustomerInfoFragmentDoc}
${TagInfoFragmentDoc}
${AddressInfoFragmentDoc}
${CustomerOrderInfoFragmentDoc}`;

/**
 * __useCustomerPageQuery__
 *
 * To run a query within a React component, call `useCustomerPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerPageQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useCustomerPageQuery(baseOptions: Apollo.QueryHookOptions<CustomerPageQuery, CustomerPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomerPageQuery, CustomerPageQueryVariables>(CustomerPageDocument, options);
      }
export function useCustomerPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomerPageQuery, CustomerPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomerPageQuery, CustomerPageQueryVariables>(CustomerPageDocument, options);
        }
export type CustomerPageQueryHookResult = ReturnType<typeof useCustomerPageQuery>;
export type CustomerPageLazyQueryHookResult = ReturnType<typeof useCustomerPageLazyQuery>;
export type CustomerPageQueryResult = Apollo.QueryResult<CustomerPageQuery, CustomerPageQueryVariables>;
export const CustomersDocument = gql`
    query Customers($orderBy: UserOrderByArg!, $nameFilter: String!, $pagination: PaginationArg!) {
  customers(orderBy: $orderBy, nameFilter: $nameFilter, pagination: $pagination) {
    total
    users {
      ...UserInfo
      customerInfo {
        ...CustomerInfo
      }
    }
  }
}
    ${UserInfoFragmentDoc}
${CustomerInfoFragmentDoc}`;

/**
 * __useCustomersQuery__
 *
 * To run a query within a React component, call `useCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomersQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      nameFilter: // value for 'nameFilter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useCustomersQuery(baseOptions: Apollo.QueryHookOptions<CustomersQuery, CustomersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomersQuery, CustomersQueryVariables>(CustomersDocument, options);
      }
export function useCustomersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomersQuery, CustomersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomersQuery, CustomersQueryVariables>(CustomersDocument, options);
        }
export type CustomersQueryHookResult = ReturnType<typeof useCustomersQuery>;
export type CustomersLazyQueryHookResult = ReturnType<typeof useCustomersLazyQuery>;
export type CustomersQueryResult = Apollo.QueryResult<CustomersQuery, CustomersQueryVariables>;
export const KdsKitchenTicketsDocument = gql`
    query KdsKitchenTickets($kitchenId: ID!, $recentlyDoneLimit: Int!) {
  kdsKitchenTickets(kitchenId: $kitchenId) {
    ...KitchenTicket
  }
  kdsDoneKitchenTickets(kitchenId: $kitchenId, limit: $recentlyDoneLimit) {
    id
    order {
      id
      shortId
      refundableItems {
        ...RefundableItems
      }
      platform
    }
  }
}
    ${KitchenTicketFragmentDoc}
${RefundableItemsFragmentDoc}`;

/**
 * __useKdsKitchenTicketsQuery__
 *
 * To run a query within a React component, call `useKdsKitchenTicketsQuery` and pass it any options that fit your needs.
 * When your component renders, `useKdsKitchenTicketsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKdsKitchenTicketsQuery({
 *   variables: {
 *      kitchenId: // value for 'kitchenId'
 *      recentlyDoneLimit: // value for 'recentlyDoneLimit'
 *   },
 * });
 */
export function useKdsKitchenTicketsQuery(baseOptions: Apollo.QueryHookOptions<KdsKitchenTicketsQuery, KdsKitchenTicketsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<KdsKitchenTicketsQuery, KdsKitchenTicketsQueryVariables>(KdsKitchenTicketsDocument, options);
      }
export function useKdsKitchenTicketsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KdsKitchenTicketsQuery, KdsKitchenTicketsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<KdsKitchenTicketsQuery, KdsKitchenTicketsQueryVariables>(KdsKitchenTicketsDocument, options);
        }
export type KdsKitchenTicketsQueryHookResult = ReturnType<typeof useKdsKitchenTicketsQuery>;
export type KdsKitchenTicketsLazyQueryHookResult = ReturnType<typeof useKdsKitchenTicketsLazyQuery>;
export type KdsKitchenTicketsQueryResult = Apollo.QueryResult<KdsKitchenTicketsQuery, KdsKitchenTicketsQueryVariables>;
export const KdsFutureKitchenTicketsDocument = gql`
    query KdsFutureKitchenTickets($kitchenId: ID!, $startDate: DateTime!, $endDate: DateTime!) {
  kdsKitchenTickets(
    kitchenId: $kitchenId
    startDate: $startDate
    endDate: $endDate
  ) {
    ...KitchenTicket
  }
}
    ${KitchenTicketFragmentDoc}`;

/**
 * __useKdsFutureKitchenTicketsQuery__
 *
 * To run a query within a React component, call `useKdsFutureKitchenTicketsQuery` and pass it any options that fit your needs.
 * When your component renders, `useKdsFutureKitchenTicketsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKdsFutureKitchenTicketsQuery({
 *   variables: {
 *      kitchenId: // value for 'kitchenId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useKdsFutureKitchenTicketsQuery(baseOptions: Apollo.QueryHookOptions<KdsFutureKitchenTicketsQuery, KdsFutureKitchenTicketsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<KdsFutureKitchenTicketsQuery, KdsFutureKitchenTicketsQueryVariables>(KdsFutureKitchenTicketsDocument, options);
      }
export function useKdsFutureKitchenTicketsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KdsFutureKitchenTicketsQuery, KdsFutureKitchenTicketsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<KdsFutureKitchenTicketsQuery, KdsFutureKitchenTicketsQueryVariables>(KdsFutureKitchenTicketsDocument, options);
        }
export type KdsFutureKitchenTicketsQueryHookResult = ReturnType<typeof useKdsFutureKitchenTicketsQuery>;
export type KdsFutureKitchenTicketsLazyQueryHookResult = ReturnType<typeof useKdsFutureKitchenTicketsLazyQuery>;
export type KdsFutureKitchenTicketsQueryResult = Apollo.QueryResult<KdsFutureKitchenTicketsQuery, KdsFutureKitchenTicketsQueryVariables>;
export const KdsKitchenSingleTicketDocument = gql`
    query KdsKitchenSingleTicket($id: ID!) {
  kdsKitchenTicket(id: $id) {
    ...KitchenTicketsRecentlyDone
    items {
      ...FacilityItemInfo
    }
    order {
      refunds {
        ...Refunds
      }
      platform
    }
  }
}
    ${KitchenTicketsRecentlyDoneFragmentDoc}
${FacilityItemInfoFragmentDoc}
${RefundsFragmentDoc}`;

/**
 * __useKdsKitchenSingleTicketQuery__
 *
 * To run a query within a React component, call `useKdsKitchenSingleTicketQuery` and pass it any options that fit your needs.
 * When your component renders, `useKdsKitchenSingleTicketQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKdsKitchenSingleTicketQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useKdsKitchenSingleTicketQuery(baseOptions: Apollo.QueryHookOptions<KdsKitchenSingleTicketQuery, KdsKitchenSingleTicketQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<KdsKitchenSingleTicketQuery, KdsKitchenSingleTicketQueryVariables>(KdsKitchenSingleTicketDocument, options);
      }
export function useKdsKitchenSingleTicketLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KdsKitchenSingleTicketQuery, KdsKitchenSingleTicketQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<KdsKitchenSingleTicketQuery, KdsKitchenSingleTicketQueryVariables>(KdsKitchenSingleTicketDocument, options);
        }
export type KdsKitchenSingleTicketQueryHookResult = ReturnType<typeof useKdsKitchenSingleTicketQuery>;
export type KdsKitchenSingleTicketLazyQueryHookResult = ReturnType<typeof useKdsKitchenSingleTicketLazyQuery>;
export type KdsKitchenSingleTicketQueryResult = Apollo.QueryResult<KdsKitchenSingleTicketQuery, KdsKitchenSingleTicketQueryVariables>;
export const KdsExpoOrdersDocument = gql`
    query KdsExpoOrders {
  kdsOrders(type: EXPO) {
    ...KdsExpoOrder
  }
  kdsDoneOrders(limit: 5, type: EXPO) {
    id
    shortId
    refundableItems {
      ...RefundableItems
    }
    platform
  }
}
    ${KdsExpoOrderFragmentDoc}
${RefundableItemsFragmentDoc}`;

/**
 * __useKdsExpoOrdersQuery__
 *
 * To run a query within a React component, call `useKdsExpoOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useKdsExpoOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKdsExpoOrdersQuery({
 *   variables: {
 *   },
 * });
 */
export function useKdsExpoOrdersQuery(baseOptions?: Apollo.QueryHookOptions<KdsExpoOrdersQuery, KdsExpoOrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<KdsExpoOrdersQuery, KdsExpoOrdersQueryVariables>(KdsExpoOrdersDocument, options);
      }
export function useKdsExpoOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KdsExpoOrdersQuery, KdsExpoOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<KdsExpoOrdersQuery, KdsExpoOrdersQueryVariables>(KdsExpoOrdersDocument, options);
        }
export type KdsExpoOrdersQueryHookResult = ReturnType<typeof useKdsExpoOrdersQuery>;
export type KdsExpoOrdersLazyQueryHookResult = ReturnType<typeof useKdsExpoOrdersLazyQuery>;
export type KdsExpoOrdersQueryResult = Apollo.QueryResult<KdsExpoOrdersQuery, KdsExpoOrdersQueryVariables>;
export const KdsExpoStatisticsDocument = gql`
    query KdsExpoStatistics {
  kdsTicketStatistics {
    kitchen
  }
}
    `;

/**
 * __useKdsExpoStatisticsQuery__
 *
 * To run a query within a React component, call `useKdsExpoStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useKdsExpoStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKdsExpoStatisticsQuery({
 *   variables: {
 *   },
 * });
 */
export function useKdsExpoStatisticsQuery(baseOptions?: Apollo.QueryHookOptions<KdsExpoStatisticsQuery, KdsExpoStatisticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<KdsExpoStatisticsQuery, KdsExpoStatisticsQueryVariables>(KdsExpoStatisticsDocument, options);
      }
export function useKdsExpoStatisticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KdsExpoStatisticsQuery, KdsExpoStatisticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<KdsExpoStatisticsQuery, KdsExpoStatisticsQueryVariables>(KdsExpoStatisticsDocument, options);
        }
export type KdsExpoStatisticsQueryHookResult = ReturnType<typeof useKdsExpoStatisticsQuery>;
export type KdsExpoStatisticsLazyQueryHookResult = ReturnType<typeof useKdsExpoStatisticsLazyQuery>;
export type KdsExpoStatisticsQueryResult = Apollo.QueryResult<KdsExpoStatisticsQuery, KdsExpoStatisticsQueryVariables>;
export const KdsExpoRecentOrdersDocument = gql`
    query KdsExpoRecentOrders {
  kdsDoneOrders(type: EXPO) {
    ...KdsExpoRecentOrder
    items {
      ...KdsOrderItem
    }
    refundableItems {
      ...RefundableItems
    }
  }
}
    ${KdsExpoRecentOrderFragmentDoc}
${KdsOrderItemFragmentDoc}
${RefundableItemsFragmentDoc}`;

/**
 * __useKdsExpoRecentOrdersQuery__
 *
 * To run a query within a React component, call `useKdsExpoRecentOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useKdsExpoRecentOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKdsExpoRecentOrdersQuery({
 *   variables: {
 *   },
 * });
 */
export function useKdsExpoRecentOrdersQuery(baseOptions?: Apollo.QueryHookOptions<KdsExpoRecentOrdersQuery, KdsExpoRecentOrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<KdsExpoRecentOrdersQuery, KdsExpoRecentOrdersQueryVariables>(KdsExpoRecentOrdersDocument, options);
      }
export function useKdsExpoRecentOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KdsExpoRecentOrdersQuery, KdsExpoRecentOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<KdsExpoRecentOrdersQuery, KdsExpoRecentOrdersQueryVariables>(KdsExpoRecentOrdersDocument, options);
        }
export type KdsExpoRecentOrdersQueryHookResult = ReturnType<typeof useKdsExpoRecentOrdersQuery>;
export type KdsExpoRecentOrdersLazyQueryHookResult = ReturnType<typeof useKdsExpoRecentOrdersLazyQuery>;
export type KdsExpoRecentOrdersQueryResult = Apollo.QueryResult<KdsExpoRecentOrdersQuery, KdsExpoRecentOrdersQueryVariables>;
export const KdsStationRecentOrdersDocument = gql`
    query KdsStationRecentOrders {
  kdsDoneOrders(type: FOH) {
    ...KdsExpoRecentOrder
    items {
      ...KdsOrderItem
    }
    refundableItems {
      ...RefundableItems
    }
  }
}
    ${KdsExpoRecentOrderFragmentDoc}
${KdsOrderItemFragmentDoc}
${RefundableItemsFragmentDoc}`;

/**
 * __useKdsStationRecentOrdersQuery__
 *
 * To run a query within a React component, call `useKdsStationRecentOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useKdsStationRecentOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKdsStationRecentOrdersQuery({
 *   variables: {
 *   },
 * });
 */
export function useKdsStationRecentOrdersQuery(baseOptions?: Apollo.QueryHookOptions<KdsStationRecentOrdersQuery, KdsStationRecentOrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<KdsStationRecentOrdersQuery, KdsStationRecentOrdersQueryVariables>(KdsStationRecentOrdersDocument, options);
      }
export function useKdsStationRecentOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KdsStationRecentOrdersQuery, KdsStationRecentOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<KdsStationRecentOrdersQuery, KdsStationRecentOrdersQueryVariables>(KdsStationRecentOrdersDocument, options);
        }
export type KdsStationRecentOrdersQueryHookResult = ReturnType<typeof useKdsStationRecentOrdersQuery>;
export type KdsStationRecentOrdersLazyQueryHookResult = ReturnType<typeof useKdsStationRecentOrdersLazyQuery>;
export type KdsStationRecentOrdersQueryResult = Apollo.QueryResult<KdsStationRecentOrdersQuery, KdsStationRecentOrdersQueryVariables>;
export const KdsExpoSingleRecentOrderDocument = gql`
    query KdsExpoSingleRecentOrder($id: ID!) {
  adminOrder(id: $id) {
    ...KdsExpoRecentOrder
    items {
      ...KdsOrderItem
    }
  }
}
    ${KdsExpoRecentOrderFragmentDoc}
${KdsOrderItemFragmentDoc}`;

/**
 * __useKdsExpoSingleRecentOrderQuery__
 *
 * To run a query within a React component, call `useKdsExpoSingleRecentOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useKdsExpoSingleRecentOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKdsExpoSingleRecentOrderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useKdsExpoSingleRecentOrderQuery(baseOptions: Apollo.QueryHookOptions<KdsExpoSingleRecentOrderQuery, KdsExpoSingleRecentOrderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<KdsExpoSingleRecentOrderQuery, KdsExpoSingleRecentOrderQueryVariables>(KdsExpoSingleRecentOrderDocument, options);
      }
export function useKdsExpoSingleRecentOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KdsExpoSingleRecentOrderQuery, KdsExpoSingleRecentOrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<KdsExpoSingleRecentOrderQuery, KdsExpoSingleRecentOrderQueryVariables>(KdsExpoSingleRecentOrderDocument, options);
        }
export type KdsExpoSingleRecentOrderQueryHookResult = ReturnType<typeof useKdsExpoSingleRecentOrderQuery>;
export type KdsExpoSingleRecentOrderLazyQueryHookResult = ReturnType<typeof useKdsExpoSingleRecentOrderLazyQuery>;
export type KdsExpoSingleRecentOrderQueryResult = Apollo.QueryResult<KdsExpoSingleRecentOrderQuery, KdsExpoSingleRecentOrderQueryVariables>;
export const GetKdsStationsDocument = gql`
    query getKdsStations {
  getKdsStations {
    id
    kitchen
    name
  }
}
    `;

/**
 * __useGetKdsStationsQuery__
 *
 * To run a query within a React component, call `useGetKdsStationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKdsStationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKdsStationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetKdsStationsQuery(baseOptions?: Apollo.QueryHookOptions<GetKdsStationsQuery, GetKdsStationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetKdsStationsQuery, GetKdsStationsQueryVariables>(GetKdsStationsDocument, options);
      }
export function useGetKdsStationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetKdsStationsQuery, GetKdsStationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetKdsStationsQuery, GetKdsStationsQueryVariables>(GetKdsStationsDocument, options);
        }
export type GetKdsStationsQueryHookResult = ReturnType<typeof useGetKdsStationsQuery>;
export type GetKdsStationsLazyQueryHookResult = ReturnType<typeof useGetKdsStationsLazyQuery>;
export type GetKdsStationsQueryResult = Apollo.QueryResult<GetKdsStationsQuery, GetKdsStationsQueryVariables>;
export const KdsFohStationOrdersDocument = gql`
    query KdsFohStationOrders {
  kdsOrders(type: FOH) {
    ...KdsExpoOrder
  }
  kdsDoneOrders(limit: 5, type: FOH) {
    id
    shortId
    refundableItems {
      ...RefundableItems
    }
    platform
  }
}
    ${KdsExpoOrderFragmentDoc}
${RefundableItemsFragmentDoc}`;

/**
 * __useKdsFohStationOrdersQuery__
 *
 * To run a query within a React component, call `useKdsFohStationOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useKdsFohStationOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKdsFohStationOrdersQuery({
 *   variables: {
 *   },
 * });
 */
export function useKdsFohStationOrdersQuery(baseOptions?: Apollo.QueryHookOptions<KdsFohStationOrdersQuery, KdsFohStationOrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<KdsFohStationOrdersQuery, KdsFohStationOrdersQueryVariables>(KdsFohStationOrdersDocument, options);
      }
export function useKdsFohStationOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KdsFohStationOrdersQuery, KdsFohStationOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<KdsFohStationOrdersQuery, KdsFohStationOrdersQueryVariables>(KdsFohStationOrdersDocument, options);
        }
export type KdsFohStationOrdersQueryHookResult = ReturnType<typeof useKdsFohStationOrdersQuery>;
export type KdsFohStationOrdersLazyQueryHookResult = ReturnType<typeof useKdsFohStationOrdersLazyQuery>;
export type KdsFohStationOrdersQueryResult = Apollo.QueryResult<KdsFohStationOrdersQuery, KdsFohStationOrdersQueryVariables>;
export const KdsPackagingOrdersDocument = gql`
    query KdsPackagingOrders($workplace: Int!) {
  kdsOrders(type: PACKAGING, workplace: $workplace) {
    ...KdsExpoOrder
  }
  kdsDoneOrders(limit: 5, type: PACKAGING) {
    ...KdsExpoOrder
    refundableItems {
      ...RefundableItems
    }
  }
}
    ${KdsExpoOrderFragmentDoc}
${RefundableItemsFragmentDoc}`;

/**
 * __useKdsPackagingOrdersQuery__
 *
 * To run a query within a React component, call `useKdsPackagingOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useKdsPackagingOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKdsPackagingOrdersQuery({
 *   variables: {
 *      workplace: // value for 'workplace'
 *   },
 * });
 */
export function useKdsPackagingOrdersQuery(baseOptions: Apollo.QueryHookOptions<KdsPackagingOrdersQuery, KdsPackagingOrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<KdsPackagingOrdersQuery, KdsPackagingOrdersQueryVariables>(KdsPackagingOrdersDocument, options);
      }
export function useKdsPackagingOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KdsPackagingOrdersQuery, KdsPackagingOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<KdsPackagingOrdersQuery, KdsPackagingOrdersQueryVariables>(KdsPackagingOrdersDocument, options);
        }
export type KdsPackagingOrdersQueryHookResult = ReturnType<typeof useKdsPackagingOrdersQuery>;
export type KdsPackagingOrdersLazyQueryHookResult = ReturnType<typeof useKdsPackagingOrdersLazyQuery>;
export type KdsPackagingOrdersQueryResult = Apollo.QueryResult<KdsPackagingOrdersQuery, KdsPackagingOrdersQueryVariables>;
export const KdsTableAssignDocument = gql`
    query KdsTableAssign {
  kdsOrders(type: EXPO) {
    ...AssignmentTicket
    ...CustomerTags
  }
  kdsDoneOrders(limit: 5, type: EXPO) {
    id
    shortId
    refundableItems {
      ...RefundableItems
    }
    platform
  }
}
    ${AssignmentTicketFragmentDoc}
${CustomerTagsFragmentDoc}
${RefundableItemsFragmentDoc}`;

/**
 * __useKdsTableAssignQuery__
 *
 * To run a query within a React component, call `useKdsTableAssignQuery` and pass it any options that fit your needs.
 * When your component renders, `useKdsTableAssignQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKdsTableAssignQuery({
 *   variables: {
 *   },
 * });
 */
export function useKdsTableAssignQuery(baseOptions?: Apollo.QueryHookOptions<KdsTableAssignQuery, KdsTableAssignQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<KdsTableAssignQuery, KdsTableAssignQueryVariables>(KdsTableAssignDocument, options);
      }
export function useKdsTableAssignLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KdsTableAssignQuery, KdsTableAssignQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<KdsTableAssignQuery, KdsTableAssignQueryVariables>(KdsTableAssignDocument, options);
        }
export type KdsTableAssignQueryHookResult = ReturnType<typeof useKdsTableAssignQuery>;
export type KdsTableAssignLazyQueryHookResult = ReturnType<typeof useKdsTableAssignLazyQuery>;
export type KdsTableAssignQueryResult = Apollo.QueryResult<KdsTableAssignQuery, KdsTableAssignQueryVariables>;
export const KdsOrderAssignmentsDocument = gql`
    query KdsOrderAssignments {
  kdsOrders(type: PACKAGING) {
    ...KdsOrderAssignmentOrder
  }
}
    ${KdsOrderAssignmentOrderFragmentDoc}`;

/**
 * __useKdsOrderAssignmentsQuery__
 *
 * To run a query within a React component, call `useKdsOrderAssignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useKdsOrderAssignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKdsOrderAssignmentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useKdsOrderAssignmentsQuery(baseOptions?: Apollo.QueryHookOptions<KdsOrderAssignmentsQuery, KdsOrderAssignmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<KdsOrderAssignmentsQuery, KdsOrderAssignmentsQueryVariables>(KdsOrderAssignmentsDocument, options);
      }
export function useKdsOrderAssignmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KdsOrderAssignmentsQuery, KdsOrderAssignmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<KdsOrderAssignmentsQuery, KdsOrderAssignmentsQueryVariables>(KdsOrderAssignmentsDocument, options);
        }
export type KdsOrderAssignmentsQueryHookResult = ReturnType<typeof useKdsOrderAssignmentsQuery>;
export type KdsOrderAssignmentsLazyQueryHookResult = ReturnType<typeof useKdsOrderAssignmentsLazyQuery>;
export type KdsOrderAssignmentsQueryResult = Apollo.QueryResult<KdsOrderAssignmentsQuery, KdsOrderAssignmentsQueryVariables>;
export const KdsInProgressOrdersDocument = gql`
    query KdsInProgressOrders {
  kdsOrders(type: ORDER_STATUS) {
    ...KdsExpoOrder
  }
}
    ${KdsExpoOrderFragmentDoc}`;

/**
 * __useKdsInProgressOrdersQuery__
 *
 * To run a query within a React component, call `useKdsInProgressOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useKdsInProgressOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKdsInProgressOrdersQuery({
 *   variables: {
 *   },
 * });
 */
export function useKdsInProgressOrdersQuery(baseOptions?: Apollo.QueryHookOptions<KdsInProgressOrdersQuery, KdsInProgressOrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<KdsInProgressOrdersQuery, KdsInProgressOrdersQueryVariables>(KdsInProgressOrdersDocument, options);
      }
export function useKdsInProgressOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KdsInProgressOrdersQuery, KdsInProgressOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<KdsInProgressOrdersQuery, KdsInProgressOrdersQueryVariables>(KdsInProgressOrdersDocument, options);
        }
export type KdsInProgressOrdersQueryHookResult = ReturnType<typeof useKdsInProgressOrdersQuery>;
export type KdsInProgressOrdersLazyQueryHookResult = ReturnType<typeof useKdsInProgressOrdersLazyQuery>;
export type KdsInProgressOrdersQueryResult = Apollo.QueryResult<KdsInProgressOrdersQuery, KdsInProgressOrdersQueryVariables>;
export const KdsPackagingStatisticsDocument = gql`
    query KdsPackagingStatistics {
  kdsTicketStatistics {
    kitchen
  }
  kdsOrderStatistics {
    expo
  }
}
    `;

/**
 * __useKdsPackagingStatisticsQuery__
 *
 * To run a query within a React component, call `useKdsPackagingStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useKdsPackagingStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKdsPackagingStatisticsQuery({
 *   variables: {
 *   },
 * });
 */
export function useKdsPackagingStatisticsQuery(baseOptions?: Apollo.QueryHookOptions<KdsPackagingStatisticsQuery, KdsPackagingStatisticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<KdsPackagingStatisticsQuery, KdsPackagingStatisticsQueryVariables>(KdsPackagingStatisticsDocument, options);
      }
export function useKdsPackagingStatisticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KdsPackagingStatisticsQuery, KdsPackagingStatisticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<KdsPackagingStatisticsQuery, KdsPackagingStatisticsQueryVariables>(KdsPackagingStatisticsDocument, options);
        }
export type KdsPackagingStatisticsQueryHookResult = ReturnType<typeof useKdsPackagingStatisticsQuery>;
export type KdsPackagingStatisticsLazyQueryHookResult = ReturnType<typeof useKdsPackagingStatisticsLazyQuery>;
export type KdsPackagingStatisticsQueryResult = Apollo.QueryResult<KdsPackagingStatisticsQuery, KdsPackagingStatisticsQueryVariables>;
export const KdsRunnerBundlesDocument = gql`
    query KdsRunnerBundles {
  kdsOrderBundles {
    ...BundleShortInfo
    orders {
      ...OrderInfo
      customer {
        id
        isEmployee
        tags {
          id
          name
        }
        ordersCount
      }
    }
  }
}
    ${BundleShortInfoFragmentDoc}
${OrderInfoFragmentDoc}`;

/**
 * __useKdsRunnerBundlesQuery__
 *
 * To run a query within a React component, call `useKdsRunnerBundlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useKdsRunnerBundlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKdsRunnerBundlesQuery({
 *   variables: {
 *   },
 * });
 */
export function useKdsRunnerBundlesQuery(baseOptions?: Apollo.QueryHookOptions<KdsRunnerBundlesQuery, KdsRunnerBundlesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<KdsRunnerBundlesQuery, KdsRunnerBundlesQueryVariables>(KdsRunnerBundlesDocument, options);
      }
export function useKdsRunnerBundlesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KdsRunnerBundlesQuery, KdsRunnerBundlesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<KdsRunnerBundlesQuery, KdsRunnerBundlesQueryVariables>(KdsRunnerBundlesDocument, options);
        }
export type KdsRunnerBundlesQueryHookResult = ReturnType<typeof useKdsRunnerBundlesQuery>;
export type KdsRunnerBundlesLazyQueryHookResult = ReturnType<typeof useKdsRunnerBundlesLazyQuery>;
export type KdsRunnerBundlesQueryResult = Apollo.QueryResult<KdsRunnerBundlesQuery, KdsRunnerBundlesQueryVariables>;
export const KdsRunnerStatisticsDocument = gql`
    query KdsRunnerStatistics {
  kdsOrderStatistics {
    packaging
  }
}
    `;

/**
 * __useKdsRunnerStatisticsQuery__
 *
 * To run a query within a React component, call `useKdsRunnerStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useKdsRunnerStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKdsRunnerStatisticsQuery({
 *   variables: {
 *   },
 * });
 */
export function useKdsRunnerStatisticsQuery(baseOptions?: Apollo.QueryHookOptions<KdsRunnerStatisticsQuery, KdsRunnerStatisticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<KdsRunnerStatisticsQuery, KdsRunnerStatisticsQueryVariables>(KdsRunnerStatisticsDocument, options);
      }
export function useKdsRunnerStatisticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KdsRunnerStatisticsQuery, KdsRunnerStatisticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<KdsRunnerStatisticsQuery, KdsRunnerStatisticsQueryVariables>(KdsRunnerStatisticsDocument, options);
        }
export type KdsRunnerStatisticsQueryHookResult = ReturnType<typeof useKdsRunnerStatisticsQuery>;
export type KdsRunnerStatisticsLazyQueryHookResult = ReturnType<typeof useKdsRunnerStatisticsLazyQuery>;
export type KdsRunnerStatisticsQueryResult = Apollo.QueryResult<KdsRunnerStatisticsQuery, KdsRunnerStatisticsQueryVariables>;
export const KdsPickupOrdersDocument = gql`
    query KdsPickupOrders {
  kdsOrders(type: PICKUP) {
    ...KdsPickupOrder
  }
}
    ${KdsPickupOrderFragmentDoc}`;

/**
 * __useKdsPickupOrdersQuery__
 *
 * To run a query within a React component, call `useKdsPickupOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useKdsPickupOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKdsPickupOrdersQuery({
 *   variables: {
 *   },
 * });
 */
export function useKdsPickupOrdersQuery(baseOptions?: Apollo.QueryHookOptions<KdsPickupOrdersQuery, KdsPickupOrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<KdsPickupOrdersQuery, KdsPickupOrdersQueryVariables>(KdsPickupOrdersDocument, options);
      }
export function useKdsPickupOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KdsPickupOrdersQuery, KdsPickupOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<KdsPickupOrdersQuery, KdsPickupOrdersQueryVariables>(KdsPickupOrdersDocument, options);
        }
export type KdsPickupOrdersQueryHookResult = ReturnType<typeof useKdsPickupOrdersQuery>;
export type KdsPickupOrdersLazyQueryHookResult = ReturnType<typeof useKdsPickupOrdersLazyQuery>;
export type KdsPickupOrdersQueryResult = Apollo.QueryResult<KdsPickupOrdersQuery, KdsPickupOrdersQueryVariables>;
export const KdsPickupStatisticsDocument = gql`
    query KdsPickupStatistics {
  kdsOrderStatistics {
    expo
    packaging
  }
}
    `;

/**
 * __useKdsPickupStatisticsQuery__
 *
 * To run a query within a React component, call `useKdsPickupStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useKdsPickupStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKdsPickupStatisticsQuery({
 *   variables: {
 *   },
 * });
 */
export function useKdsPickupStatisticsQuery(baseOptions?: Apollo.QueryHookOptions<KdsPickupStatisticsQuery, KdsPickupStatisticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<KdsPickupStatisticsQuery, KdsPickupStatisticsQueryVariables>(KdsPickupStatisticsDocument, options);
      }
export function useKdsPickupStatisticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KdsPickupStatisticsQuery, KdsPickupStatisticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<KdsPickupStatisticsQuery, KdsPickupStatisticsQueryVariables>(KdsPickupStatisticsDocument, options);
        }
export type KdsPickupStatisticsQueryHookResult = ReturnType<typeof useKdsPickupStatisticsQuery>;
export type KdsPickupStatisticsLazyQueryHookResult = ReturnType<typeof useKdsPickupStatisticsLazyQuery>;
export type KdsPickupStatisticsQueryResult = Apollo.QueryResult<KdsPickupStatisticsQuery, KdsPickupStatisticsQueryVariables>;
export const KdsPickupOrdersSearchDocument = gql`
    query KdsPickupOrdersSearch($shortId: String) {
  kdsFacilityOrders(shortId: $shortId) {
    ...KdsPickupOrder
  }
}
    ${KdsPickupOrderFragmentDoc}`;

/**
 * __useKdsPickupOrdersSearchQuery__
 *
 * To run a query within a React component, call `useKdsPickupOrdersSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useKdsPickupOrdersSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKdsPickupOrdersSearchQuery({
 *   variables: {
 *      shortId: // value for 'shortId'
 *   },
 * });
 */
export function useKdsPickupOrdersSearchQuery(baseOptions?: Apollo.QueryHookOptions<KdsPickupOrdersSearchQuery, KdsPickupOrdersSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<KdsPickupOrdersSearchQuery, KdsPickupOrdersSearchQueryVariables>(KdsPickupOrdersSearchDocument, options);
      }
export function useKdsPickupOrdersSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KdsPickupOrdersSearchQuery, KdsPickupOrdersSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<KdsPickupOrdersSearchQuery, KdsPickupOrdersSearchQueryVariables>(KdsPickupOrdersSearchDocument, options);
        }
export type KdsPickupOrdersSearchQueryHookResult = ReturnType<typeof useKdsPickupOrdersSearchQuery>;
export type KdsPickupOrdersSearchLazyQueryHookResult = ReturnType<typeof useKdsPickupOrdersSearchLazyQuery>;
export type KdsPickupOrdersSearchQueryResult = Apollo.QueryResult<KdsPickupOrdersSearchQuery, KdsPickupOrdersSearchQueryVariables>;
export const KdsKitchensDocument = gql`
    query KdsKitchens {
  adminKitchens {
    id
    name
    status
    logoUrl
  }
  me {
    id
    kitchens {
      id
    }
  }
}
    `;

/**
 * __useKdsKitchensQuery__
 *
 * To run a query within a React component, call `useKdsKitchensQuery` and pass it any options that fit your needs.
 * When your component renders, `useKdsKitchensQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKdsKitchensQuery({
 *   variables: {
 *   },
 * });
 */
export function useKdsKitchensQuery(baseOptions?: Apollo.QueryHookOptions<KdsKitchensQuery, KdsKitchensQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<KdsKitchensQuery, KdsKitchensQueryVariables>(KdsKitchensDocument, options);
      }
export function useKdsKitchensLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KdsKitchensQuery, KdsKitchensQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<KdsKitchensQuery, KdsKitchensQueryVariables>(KdsKitchensDocument, options);
        }
export type KdsKitchensQueryHookResult = ReturnType<typeof useKdsKitchensQuery>;
export type KdsKitchensLazyQueryHookResult = ReturnType<typeof useKdsKitchensLazyQuery>;
export type KdsKitchensQueryResult = Apollo.QueryResult<KdsKitchensQuery, KdsKitchensQueryVariables>;
export const DriversDocument = gql`
    query Drivers {
  adminDrivers {
    id
    name
  }
}
    `;

/**
 * __useDriversQuery__
 *
 * To run a query within a React component, call `useDriversQuery` and pass it any options that fit your needs.
 * When your component renders, `useDriversQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDriversQuery({
 *   variables: {
 *   },
 * });
 */
export function useDriversQuery(baseOptions?: Apollo.QueryHookOptions<DriversQuery, DriversQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DriversQuery, DriversQueryVariables>(DriversDocument, options);
      }
export function useDriversLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DriversQuery, DriversQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DriversQuery, DriversQueryVariables>(DriversDocument, options);
        }
export type DriversQueryHookResult = ReturnType<typeof useDriversQuery>;
export type DriversLazyQueryHookResult = ReturnType<typeof useDriversLazyQuery>;
export type DriversQueryResult = Apollo.QueryResult<DriversQuery, DriversQueryVariables>;
export const PromoDocument = gql`
    query Promo($id: String!) {
  adminPromo(where: {id: $id}) {
    ...PromoDetail
    promoFacilityRules {
      id
      facilityId
      isAllKitchens
      kitchens {
        id
        name
        facilityId
      }
    }
  }
}
    ${PromoDetailFragmentDoc}`;

/**
 * __usePromoQuery__
 *
 * To run a query within a React component, call `usePromoQuery` and pass it any options that fit your needs.
 * When your component renders, `usePromoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePromoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePromoQuery(baseOptions: Apollo.QueryHookOptions<PromoQuery, PromoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PromoQuery, PromoQueryVariables>(PromoDocument, options);
      }
export function usePromoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PromoQuery, PromoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PromoQuery, PromoQueryVariables>(PromoDocument, options);
        }
export type PromoQueryHookResult = ReturnType<typeof usePromoQuery>;
export type PromoLazyQueryHookResult = ReturnType<typeof usePromoLazyQuery>;
export type PromoQueryResult = Apollo.QueryResult<PromoQuery, PromoQueryVariables>;
export const PromosDocument = gql`
    query Promos($input: PromosInput!) {
  adminPromos(input: $input) {
    total
    promos {
      ...PromoListInfo
    }
  }
}
    ${PromoListInfoFragmentDoc}`;

/**
 * __usePromosQuery__
 *
 * To run a query within a React component, call `usePromosQuery` and pass it any options that fit your needs.
 * When your component renders, `usePromosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePromosQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePromosQuery(baseOptions: Apollo.QueryHookOptions<PromosQuery, PromosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PromosQuery, PromosQueryVariables>(PromosDocument, options);
      }
export function usePromosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PromosQuery, PromosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PromosQuery, PromosQueryVariables>(PromosDocument, options);
        }
export type PromosQueryHookResult = ReturnType<typeof usePromosQuery>;
export type PromosLazyQueryHookResult = ReturnType<typeof usePromosLazyQuery>;
export type PromosQueryResult = Apollo.QueryResult<PromosQuery, PromosQueryVariables>;
export const SegmentsDocument = gql`
    query Segments {
  adminSegments {
    id
    name
  }
}
    `;

/**
 * __useSegmentsQuery__
 *
 * To run a query within a React component, call `useSegmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSegmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSegmentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSegmentsQuery(baseOptions?: Apollo.QueryHookOptions<SegmentsQuery, SegmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SegmentsQuery, SegmentsQueryVariables>(SegmentsDocument, options);
      }
export function useSegmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SegmentsQuery, SegmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SegmentsQuery, SegmentsQueryVariables>(SegmentsDocument, options);
        }
export type SegmentsQueryHookResult = ReturnType<typeof useSegmentsQuery>;
export type SegmentsLazyQueryHookResult = ReturnType<typeof useSegmentsLazyQuery>;
export type SegmentsQueryResult = Apollo.QueryResult<SegmentsQuery, SegmentsQueryVariables>;
export const PromoUsersDocument = gql`
    query PromoUsers($query: String!, $pagination: PaginationArg!, $isEmployee: Boolean!, $selectedUsers: [ID]!) {
  adminPromoUsers(
    query: $query
    pagination: $pagination
    isEmployee: $isEmployee
    selectedUsers: $selectedUsers
  ) {
    selectedUsers {
      id
      name
    }
    otherUsers {
      id
      name
    }
  }
}
    `;

/**
 * __usePromoUsersQuery__
 *
 * To run a query within a React component, call `usePromoUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `usePromoUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePromoUsersQuery({
 *   variables: {
 *      query: // value for 'query'
 *      pagination: // value for 'pagination'
 *      isEmployee: // value for 'isEmployee'
 *      selectedUsers: // value for 'selectedUsers'
 *   },
 * });
 */
export function usePromoUsersQuery(baseOptions: Apollo.QueryHookOptions<PromoUsersQuery, PromoUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PromoUsersQuery, PromoUsersQueryVariables>(PromoUsersDocument, options);
      }
export function usePromoUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PromoUsersQuery, PromoUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PromoUsersQuery, PromoUsersQueryVariables>(PromoUsersDocument, options);
        }
export type PromoUsersQueryHookResult = ReturnType<typeof usePromoUsersQuery>;
export type PromoUsersLazyQueryHookResult = ReturnType<typeof usePromoUsersLazyQuery>;
export type PromoUsersQueryResult = Apollo.QueryResult<PromoUsersQuery, PromoUsersQueryVariables>;
export const PromoBannerDocument = gql`
    query PromoBanner {
  adminPromoBanner {
    ...PromoBanner
  }
}
    ${PromoBannerFragmentDoc}`;

/**
 * __usePromoBannerQuery__
 *
 * To run a query within a React component, call `usePromoBannerQuery` and pass it any options that fit your needs.
 * When your component renders, `usePromoBannerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePromoBannerQuery({
 *   variables: {
 *   },
 * });
 */
export function usePromoBannerQuery(baseOptions?: Apollo.QueryHookOptions<PromoBannerQuery, PromoBannerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PromoBannerQuery, PromoBannerQueryVariables>(PromoBannerDocument, options);
      }
export function usePromoBannerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PromoBannerQuery, PromoBannerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PromoBannerQuery, PromoBannerQueryVariables>(PromoBannerDocument, options);
        }
export type PromoBannerQueryHookResult = ReturnType<typeof usePromoBannerQuery>;
export type PromoBannerLazyQueryHookResult = ReturnType<typeof usePromoBannerLazyQuery>;
export type PromoBannerQueryResult = Apollo.QueryResult<PromoBannerQuery, PromoBannerQueryVariables>;
export const AllAvailableTagsDocument = gql`
    query AllAvailableTags($query: String!, $pagination: PaginationArg!) {
  adminTags(query: $query, pagination: $pagination) {
    id
    name
  }
}
    `;

/**
 * __useAllAvailableTagsQuery__
 *
 * To run a query within a React component, call `useAllAvailableTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllAvailableTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllAvailableTagsQuery({
 *   variables: {
 *      query: // value for 'query'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useAllAvailableTagsQuery(baseOptions: Apollo.QueryHookOptions<AllAvailableTagsQuery, AllAvailableTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllAvailableTagsQuery, AllAvailableTagsQueryVariables>(AllAvailableTagsDocument, options);
      }
export function useAllAvailableTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllAvailableTagsQuery, AllAvailableTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllAvailableTagsQuery, AllAvailableTagsQueryVariables>(AllAvailableTagsDocument, options);
        }
export type AllAvailableTagsQueryHookResult = ReturnType<typeof useAllAvailableTagsQuery>;
export type AllAvailableTagsLazyQueryHookResult = ReturnType<typeof useAllAvailableTagsLazyQuery>;
export type AllAvailableTagsQueryResult = Apollo.QueryResult<AllAvailableTagsQuery, AllAvailableTagsQueryVariables>;
export const AdminCartUpsellItemsDocument = gql`
    query AdminCartUpsellItems($facilityId: String!, $platform: ClientPlatformInput!) {
  adminGetCartUpsellItems(facilityId: $facilityId, platform: $platform) {
    id
    upsellItem {
      ...SearchMenuItemInfo
    }
  }
}
    ${SearchMenuItemInfoFragmentDoc}`;

/**
 * __useAdminCartUpsellItemsQuery__
 *
 * To run a query within a React component, call `useAdminCartUpsellItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminCartUpsellItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminCartUpsellItemsQuery({
 *   variables: {
 *      facilityId: // value for 'facilityId'
 *      platform: // value for 'platform'
 *   },
 * });
 */
export function useAdminCartUpsellItemsQuery(baseOptions: Apollo.QueryHookOptions<AdminCartUpsellItemsQuery, AdminCartUpsellItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminCartUpsellItemsQuery, AdminCartUpsellItemsQueryVariables>(AdminCartUpsellItemsDocument, options);
      }
export function useAdminCartUpsellItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminCartUpsellItemsQuery, AdminCartUpsellItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminCartUpsellItemsQuery, AdminCartUpsellItemsQueryVariables>(AdminCartUpsellItemsDocument, options);
        }
export type AdminCartUpsellItemsQueryHookResult = ReturnType<typeof useAdminCartUpsellItemsQuery>;
export type AdminCartUpsellItemsLazyQueryHookResult = ReturnType<typeof useAdminCartUpsellItemsLazyQuery>;
export type AdminCartUpsellItemsQueryResult = Apollo.QueryResult<AdminCartUpsellItemsQuery, AdminCartUpsellItemsQueryVariables>;
export const CreatePowerBiReportDocument = gql`
    mutation CreatePowerBiReport($data: PowerBiReportCreateInput!) {
  adminCreatePowerBiReport(data: $data) {
    id
  }
}
    `;
export type CreatePowerBiReportMutationFn = Apollo.MutationFunction<CreatePowerBiReportMutation, CreatePowerBiReportMutationVariables>;

/**
 * __useCreatePowerBiReportMutation__
 *
 * To run a mutation, you first call `useCreatePowerBiReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePowerBiReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPowerBiReportMutation, { data, loading, error }] = useCreatePowerBiReportMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreatePowerBiReportMutation(baseOptions?: Apollo.MutationHookOptions<CreatePowerBiReportMutation, CreatePowerBiReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePowerBiReportMutation, CreatePowerBiReportMutationVariables>(CreatePowerBiReportDocument, options);
      }
export type CreatePowerBiReportMutationHookResult = ReturnType<typeof useCreatePowerBiReportMutation>;
export type CreatePowerBiReportMutationResult = Apollo.MutationResult<CreatePowerBiReportMutation>;
export type CreatePowerBiReportMutationOptions = Apollo.BaseMutationOptions<CreatePowerBiReportMutation, CreatePowerBiReportMutationVariables>;
export const DeletePowerBiReportDocument = gql`
    mutation DeletePowerBiReport($id: String!) {
  adminDeletePowerBiReport(where: {id: $id}) {
    id
  }
}
    `;
export type DeletePowerBiReportMutationFn = Apollo.MutationFunction<DeletePowerBiReportMutation, DeletePowerBiReportMutationVariables>;

/**
 * __useDeletePowerBiReportMutation__
 *
 * To run a mutation, you first call `useDeletePowerBiReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePowerBiReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePowerBiReportMutation, { data, loading, error }] = useDeletePowerBiReportMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePowerBiReportMutation(baseOptions?: Apollo.MutationHookOptions<DeletePowerBiReportMutation, DeletePowerBiReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePowerBiReportMutation, DeletePowerBiReportMutationVariables>(DeletePowerBiReportDocument, options);
      }
export type DeletePowerBiReportMutationHookResult = ReturnType<typeof useDeletePowerBiReportMutation>;
export type DeletePowerBiReportMutationResult = Apollo.MutationResult<DeletePowerBiReportMutation>;
export type DeletePowerBiReportMutationOptions = Apollo.BaseMutationOptions<DeletePowerBiReportMutation, DeletePowerBiReportMutationVariables>;
export const SortPowerBiReportsDocument = gql`
    mutation SortPowerBiReports($input: [String!]!) {
  adminUpdateReportSort(input: $input)
}
    `;
export type SortPowerBiReportsMutationFn = Apollo.MutationFunction<SortPowerBiReportsMutation, SortPowerBiReportsMutationVariables>;

/**
 * __useSortPowerBiReportsMutation__
 *
 * To run a mutation, you first call `useSortPowerBiReportsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSortPowerBiReportsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sortPowerBiReportsMutation, { data, loading, error }] = useSortPowerBiReportsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSortPowerBiReportsMutation(baseOptions?: Apollo.MutationHookOptions<SortPowerBiReportsMutation, SortPowerBiReportsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SortPowerBiReportsMutation, SortPowerBiReportsMutationVariables>(SortPowerBiReportsDocument, options);
      }
export type SortPowerBiReportsMutationHookResult = ReturnType<typeof useSortPowerBiReportsMutation>;
export type SortPowerBiReportsMutationResult = Apollo.MutationResult<SortPowerBiReportsMutation>;
export type SortPowerBiReportsMutationOptions = Apollo.BaseMutationOptions<SortPowerBiReportsMutation, SortPowerBiReportsMutationVariables>;
export const PowerBiReportsDocument = gql`
    query PowerBiReports {
  adminPowerBiReportsAndToken {
    embedToken
    reports {
      id
      title
      url
      reportId
    }
  }
}
    `;

/**
 * __usePowerBiReportsQuery__
 *
 * To run a query within a React component, call `usePowerBiReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePowerBiReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePowerBiReportsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePowerBiReportsQuery(baseOptions?: Apollo.QueryHookOptions<PowerBiReportsQuery, PowerBiReportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PowerBiReportsQuery, PowerBiReportsQueryVariables>(PowerBiReportsDocument, options);
      }
export function usePowerBiReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PowerBiReportsQuery, PowerBiReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PowerBiReportsQuery, PowerBiReportsQueryVariables>(PowerBiReportsDocument, options);
        }
export type PowerBiReportsQueryHookResult = ReturnType<typeof usePowerBiReportsQuery>;
export type PowerBiReportsLazyQueryHookResult = ReturnType<typeof usePowerBiReportsLazyQuery>;
export type PowerBiReportsQueryResult = Apollo.QueryResult<PowerBiReportsQuery, PowerBiReportsQueryVariables>;
export const PowerBiReportsAllDocument = gql`
    query PowerBiReportsAll {
  adminPowerBiReportsAll(orderBy: {sortId: asc}) {
    id
    title
    url
    availableToRoles
    availableToConcepts {
      id
      name
    }
    availableToFacilities {
      id
      name
    }
  }
}
    `;

/**
 * __usePowerBiReportsAllQuery__
 *
 * To run a query within a React component, call `usePowerBiReportsAllQuery` and pass it any options that fit your needs.
 * When your component renders, `usePowerBiReportsAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePowerBiReportsAllQuery({
 *   variables: {
 *   },
 * });
 */
export function usePowerBiReportsAllQuery(baseOptions?: Apollo.QueryHookOptions<PowerBiReportsAllQuery, PowerBiReportsAllQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PowerBiReportsAllQuery, PowerBiReportsAllQueryVariables>(PowerBiReportsAllDocument, options);
      }
export function usePowerBiReportsAllLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PowerBiReportsAllQuery, PowerBiReportsAllQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PowerBiReportsAllQuery, PowerBiReportsAllQueryVariables>(PowerBiReportsAllDocument, options);
        }
export type PowerBiReportsAllQueryHookResult = ReturnType<typeof usePowerBiReportsAllQuery>;
export type PowerBiReportsAllLazyQueryHookResult = ReturnType<typeof usePowerBiReportsAllLazyQuery>;
export type PowerBiReportsAllQueryResult = Apollo.QueryResult<PowerBiReportsAllQuery, PowerBiReportsAllQueryVariables>;
export const AvailablePowerBiWorkspacesDocument = gql`
    query AvailablePowerBiWorkspaces {
  adminAvailablePowerBiWorkspaces {
    id
    name
  }
}
    `;

/**
 * __useAvailablePowerBiWorkspacesQuery__
 *
 * To run a query within a React component, call `useAvailablePowerBiWorkspacesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailablePowerBiWorkspacesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailablePowerBiWorkspacesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAvailablePowerBiWorkspacesQuery(baseOptions?: Apollo.QueryHookOptions<AvailablePowerBiWorkspacesQuery, AvailablePowerBiWorkspacesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AvailablePowerBiWorkspacesQuery, AvailablePowerBiWorkspacesQueryVariables>(AvailablePowerBiWorkspacesDocument, options);
      }
export function useAvailablePowerBiWorkspacesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AvailablePowerBiWorkspacesQuery, AvailablePowerBiWorkspacesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AvailablePowerBiWorkspacesQuery, AvailablePowerBiWorkspacesQueryVariables>(AvailablePowerBiWorkspacesDocument, options);
        }
export type AvailablePowerBiWorkspacesQueryHookResult = ReturnType<typeof useAvailablePowerBiWorkspacesQuery>;
export type AvailablePowerBiWorkspacesLazyQueryHookResult = ReturnType<typeof useAvailablePowerBiWorkspacesLazyQuery>;
export type AvailablePowerBiWorkspacesQueryResult = Apollo.QueryResult<AvailablePowerBiWorkspacesQuery, AvailablePowerBiWorkspacesQueryVariables>;
export const AvailablePowerBiReportsDocument = gql`
    query AvailablePowerBiReports($workspaceId: String!) {
  adminAvailablePowerBiReports(workspaceId: $workspaceId) {
    id
    name
    embedUrl
    datasetId
  }
}
    `;

/**
 * __useAvailablePowerBiReportsQuery__
 *
 * To run a query within a React component, call `useAvailablePowerBiReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailablePowerBiReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailablePowerBiReportsQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useAvailablePowerBiReportsQuery(baseOptions: Apollo.QueryHookOptions<AvailablePowerBiReportsQuery, AvailablePowerBiReportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AvailablePowerBiReportsQuery, AvailablePowerBiReportsQueryVariables>(AvailablePowerBiReportsDocument, options);
      }
export function useAvailablePowerBiReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AvailablePowerBiReportsQuery, AvailablePowerBiReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AvailablePowerBiReportsQuery, AvailablePowerBiReportsQueryVariables>(AvailablePowerBiReportsDocument, options);
        }
export type AvailablePowerBiReportsQueryHookResult = ReturnType<typeof useAvailablePowerBiReportsQuery>;
export type AvailablePowerBiReportsLazyQueryHookResult = ReturnType<typeof useAvailablePowerBiReportsLazyQuery>;
export type AvailablePowerBiReportsQueryResult = Apollo.QueryResult<AvailablePowerBiReportsQuery, AvailablePowerBiReportsQueryVariables>;
export const StandardizedPlatformMixReportDocument = gql`
    query StandardizedPlatformMixReport($startDate: DateTime, $endDate: DateTime, $role: UserRole, $facilityId: String, $facilityIds: [ID!]!, $kitchenIds: [ID!]!) {
  adminStandardizedPlatformMixReport(
    startDate: $startDate
    endDate: $endDate
    role: $role
    facilityId: $facilityId
    facilityIds: $facilityIds
    kitchenIds: $kitchenIds
  ) {
    orderDate
    orderingPlatformName
    totalOrders
    totalSales
  }
}
    `;

/**
 * __useStandardizedPlatformMixReportQuery__
 *
 * To run a query within a React component, call `useStandardizedPlatformMixReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useStandardizedPlatformMixReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStandardizedPlatformMixReportQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      role: // value for 'role'
 *      facilityId: // value for 'facilityId'
 *      facilityIds: // value for 'facilityIds'
 *      kitchenIds: // value for 'kitchenIds'
 *   },
 * });
 */
export function useStandardizedPlatformMixReportQuery(baseOptions: Apollo.QueryHookOptions<StandardizedPlatformMixReportQuery, StandardizedPlatformMixReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StandardizedPlatformMixReportQuery, StandardizedPlatformMixReportQueryVariables>(StandardizedPlatformMixReportDocument, options);
      }
export function useStandardizedPlatformMixReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StandardizedPlatformMixReportQuery, StandardizedPlatformMixReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StandardizedPlatformMixReportQuery, StandardizedPlatformMixReportQueryVariables>(StandardizedPlatformMixReportDocument, options);
        }
export type StandardizedPlatformMixReportQueryHookResult = ReturnType<typeof useStandardizedPlatformMixReportQuery>;
export type StandardizedPlatformMixReportLazyQueryHookResult = ReturnType<typeof useStandardizedPlatformMixReportLazyQuery>;
export type StandardizedPlatformMixReportQueryResult = Apollo.QueryResult<StandardizedPlatformMixReportQuery, StandardizedPlatformMixReportQueryVariables>;
export const StandardizedBrandRemittanceReportDocument = gql`
    query StandardizedBrandRemittanceReport($startDate: DateTime, $endDate: DateTime, $role: UserRole, $facilityId: String, $facilityIds: [ID!]!, $kitchenIds: [ID!]!) {
  adminStandardizedBrandRemittanceReport(
    startDate: $startDate
    endDate: $endDate
    role: $role
    facilityId: $facilityId
    facilityIds: $facilityIds
    kitchenIds: $kitchenIds
  ) {
    lastUpdateDate
    kitchenId
    kitchenName
    accountingDateRange
    grossRestaurantSales
    discounts
    discounts
    netSalesPretax
    serviceFees
    foodSalesTax
    serviceFeeTax
    deliveryFeeTax
    deliveryFees
    deliveryTips
    pickupTips
    cartTotal
    deliveryAggregatorExpense
    deliveryAggregatorExpenseRemainder
    craveProcessingFees
    partnerAgreements
    brandAgreements
    cardIncomeAfterFees
    adjustmentsRefunds
    adjustmentsChargebacks
    adjustmentOther
    finalPayout
    payoutId
    payoutDescriptor
    payoutDate
  }
}
    `;

/**
 * __useStandardizedBrandRemittanceReportQuery__
 *
 * To run a query within a React component, call `useStandardizedBrandRemittanceReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useStandardizedBrandRemittanceReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStandardizedBrandRemittanceReportQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      role: // value for 'role'
 *      facilityId: // value for 'facilityId'
 *      facilityIds: // value for 'facilityIds'
 *      kitchenIds: // value for 'kitchenIds'
 *   },
 * });
 */
export function useStandardizedBrandRemittanceReportQuery(baseOptions: Apollo.QueryHookOptions<StandardizedBrandRemittanceReportQuery, StandardizedBrandRemittanceReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StandardizedBrandRemittanceReportQuery, StandardizedBrandRemittanceReportQueryVariables>(StandardizedBrandRemittanceReportDocument, options);
      }
export function useStandardizedBrandRemittanceReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StandardizedBrandRemittanceReportQuery, StandardizedBrandRemittanceReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StandardizedBrandRemittanceReportQuery, StandardizedBrandRemittanceReportQueryVariables>(StandardizedBrandRemittanceReportDocument, options);
        }
export type StandardizedBrandRemittanceReportQueryHookResult = ReturnType<typeof useStandardizedBrandRemittanceReportQuery>;
export type StandardizedBrandRemittanceReportLazyQueryHookResult = ReturnType<typeof useStandardizedBrandRemittanceReportLazyQuery>;
export type StandardizedBrandRemittanceReportQueryResult = Apollo.QueryResult<StandardizedBrandRemittanceReportQuery, StandardizedBrandRemittanceReportQueryVariables>;
export const StandardizedBrandSalesReportDocument = gql`
    query StandardizedBrandSalesReport($startDate: DateTime, $endDate: DateTime, $role: UserRole, $facilityId: String, $facilityIds: [ID!]!, $kitchenIds: [ID!]!) {
  adminStandardizedBrandSalesReport(
    startDate: $startDate
    endDate: $endDate
    role: $role
    facilityId: $facilityId
    facilityIds: $facilityIds
    kitchenIds: $kitchenIds
  ) {
    brandName
    totalOrders
    totalSales
    AOV
  }
}
    `;

/**
 * __useStandardizedBrandSalesReportQuery__
 *
 * To run a query within a React component, call `useStandardizedBrandSalesReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useStandardizedBrandSalesReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStandardizedBrandSalesReportQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      role: // value for 'role'
 *      facilityId: // value for 'facilityId'
 *      facilityIds: // value for 'facilityIds'
 *      kitchenIds: // value for 'kitchenIds'
 *   },
 * });
 */
export function useStandardizedBrandSalesReportQuery(baseOptions: Apollo.QueryHookOptions<StandardizedBrandSalesReportQuery, StandardizedBrandSalesReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StandardizedBrandSalesReportQuery, StandardizedBrandSalesReportQueryVariables>(StandardizedBrandSalesReportDocument, options);
      }
export function useStandardizedBrandSalesReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StandardizedBrandSalesReportQuery, StandardizedBrandSalesReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StandardizedBrandSalesReportQuery, StandardizedBrandSalesReportQueryVariables>(StandardizedBrandSalesReportDocument, options);
        }
export type StandardizedBrandSalesReportQueryHookResult = ReturnType<typeof useStandardizedBrandSalesReportQuery>;
export type StandardizedBrandSalesReportLazyQueryHookResult = ReturnType<typeof useStandardizedBrandSalesReportLazyQuery>;
export type StandardizedBrandSalesReportQueryResult = Apollo.QueryResult<StandardizedBrandSalesReportQuery, StandardizedBrandSalesReportQueryVariables>;
export const StandardizedDailySalesReportDocument = gql`
    query StandardizedDailySalesReport($startDate: DateTime, $endDate: DateTime, $role: UserRole, $facilityId: String, $facilityIds: [ID!]!, $kitchenIds: [ID!]!) {
  adminStandardizedDailySalesReport(
    startDate: $startDate
    endDate: $endDate
    role: $role
    facilityId: $facilityId
    facilityIds: $facilityIds
    kitchenIds: $kitchenIds
  ) {
    date
    dailyAIO
    dailyAOV
    dailyAvgBrandsPerOrder
    dayOfWeek
    totalItems
    totalSales
    totalOrders
  }
}
    `;

/**
 * __useStandardizedDailySalesReportQuery__
 *
 * To run a query within a React component, call `useStandardizedDailySalesReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useStandardizedDailySalesReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStandardizedDailySalesReportQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      role: // value for 'role'
 *      facilityId: // value for 'facilityId'
 *      facilityIds: // value for 'facilityIds'
 *      kitchenIds: // value for 'kitchenIds'
 *   },
 * });
 */
export function useStandardizedDailySalesReportQuery(baseOptions: Apollo.QueryHookOptions<StandardizedDailySalesReportQuery, StandardizedDailySalesReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StandardizedDailySalesReportQuery, StandardizedDailySalesReportQueryVariables>(StandardizedDailySalesReportDocument, options);
      }
export function useStandardizedDailySalesReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StandardizedDailySalesReportQuery, StandardizedDailySalesReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StandardizedDailySalesReportQuery, StandardizedDailySalesReportQueryVariables>(StandardizedDailySalesReportDocument, options);
        }
export type StandardizedDailySalesReportQueryHookResult = ReturnType<typeof useStandardizedDailySalesReportQuery>;
export type StandardizedDailySalesReportLazyQueryHookResult = ReturnType<typeof useStandardizedDailySalesReportLazyQuery>;
export type StandardizedDailySalesReportQueryResult = Apollo.QueryResult<StandardizedDailySalesReportQuery, StandardizedDailySalesReportQueryVariables>;
export const StandardizedGiftCardReportDocument = gql`
    query StandardizedGiftCardReport($startDate: DateTime, $endDate: DateTime, $role: UserRole, $facilityId: String, $facilityIds: [ID!]!, $kitchenIds: [ID!]!) {
  adminStandardizedGiftCardReport(
    startDate: $startDate
    endDate: $endDate
    role: $role
    facilityId: $facilityId
    facilityIds: $facilityIds
    kitchenIds: $kitchenIds
  ) {
    codeName
    status
    beginningBalance
    remainingBalance
    lastActivity
  }
}
    `;

/**
 * __useStandardizedGiftCardReportQuery__
 *
 * To run a query within a React component, call `useStandardizedGiftCardReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useStandardizedGiftCardReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStandardizedGiftCardReportQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      role: // value for 'role'
 *      facilityId: // value for 'facilityId'
 *      facilityIds: // value for 'facilityIds'
 *      kitchenIds: // value for 'kitchenIds'
 *   },
 * });
 */
export function useStandardizedGiftCardReportQuery(baseOptions: Apollo.QueryHookOptions<StandardizedGiftCardReportQuery, StandardizedGiftCardReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StandardizedGiftCardReportQuery, StandardizedGiftCardReportQueryVariables>(StandardizedGiftCardReportDocument, options);
      }
export function useStandardizedGiftCardReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StandardizedGiftCardReportQuery, StandardizedGiftCardReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StandardizedGiftCardReportQuery, StandardizedGiftCardReportQueryVariables>(StandardizedGiftCardReportDocument, options);
        }
export type StandardizedGiftCardReportQueryHookResult = ReturnType<typeof useStandardizedGiftCardReportQuery>;
export type StandardizedGiftCardReportLazyQueryHookResult = ReturnType<typeof useStandardizedGiftCardReportLazyQuery>;
export type StandardizedGiftCardReportQueryResult = Apollo.QueryResult<StandardizedGiftCardReportQuery, StandardizedGiftCardReportQueryVariables>;
export const StandardizedModifierSalesReportDocument = gql`
    query StandardizedModifierSalesReport($startDate: DateTime, $endDate: DateTime, $role: UserRole, $facilityId: String, $facilityIds: [ID!]!, $kitchenIds: [ID!]!) {
  adminStandardizedModifierSalesReport(
    startDate: $startDate
    endDate: $endDate
    role: $role
    facilityId: $facilityId
    facilityIds: $facilityIds
    kitchenIds: $kitchenIds
  ) {
    orderDate
    brandName
    menuItemName
    modifierName
    modifiersSold
    modifierPrice
    modifierSales
  }
}
    `;

/**
 * __useStandardizedModifierSalesReportQuery__
 *
 * To run a query within a React component, call `useStandardizedModifierSalesReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useStandardizedModifierSalesReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStandardizedModifierSalesReportQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      role: // value for 'role'
 *      facilityId: // value for 'facilityId'
 *      facilityIds: // value for 'facilityIds'
 *      kitchenIds: // value for 'kitchenIds'
 *   },
 * });
 */
export function useStandardizedModifierSalesReportQuery(baseOptions: Apollo.QueryHookOptions<StandardizedModifierSalesReportQuery, StandardizedModifierSalesReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StandardizedModifierSalesReportQuery, StandardizedModifierSalesReportQueryVariables>(StandardizedModifierSalesReportDocument, options);
      }
export function useStandardizedModifierSalesReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StandardizedModifierSalesReportQuery, StandardizedModifierSalesReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StandardizedModifierSalesReportQuery, StandardizedModifierSalesReportQueryVariables>(StandardizedModifierSalesReportDocument, options);
        }
export type StandardizedModifierSalesReportQueryHookResult = ReturnType<typeof useStandardizedModifierSalesReportQuery>;
export type StandardizedModifierSalesReportLazyQueryHookResult = ReturnType<typeof useStandardizedModifierSalesReportLazyQuery>;
export type StandardizedModifierSalesReportQueryResult = Apollo.QueryResult<StandardizedModifierSalesReportQuery, StandardizedModifierSalesReportQueryVariables>;
export const StandardizedTransactionsReportDocument = gql`
    query StandardizedTransactionsReport($startDate: DateTime, $endDate: DateTime, $role: UserRole, $facilityId: String, $facilityIds: [ID!]!, $kitchenIds: [ID!]!) {
  adminStandardizedTransactionsReport(
    startDate: $startDate
    endDate: $endDate
    role: $role
    facilityId: $facilityId
    facilityIds: $facilityIds
    kitchenIds: $kitchenIds
  ) {
    brandFee
    brands
    combinedCartTotal
    creditCardProcessingFee
    customerEmail
    customerName
    customerPhone
    deliveryCharge
    deliveryFee
    deliveryTax
    foodSalesTax
    fulfillmentType
    giftCardAmount
    giftCode
    netSales
    numberOfOrderedItems
    numberOfRestaurants
    orderDate
    orderTime
    orderTotal
    orderType
    orderingProvider
    partnerFee
    platform
    promoAmount
    promoCode
    serviceFee
    serviceFeeTax
    shortId
    subtotal
    taxAmount
    tipAmount
    transactionId
    transactionStatus
    transactionType
  }
}
    `;

/**
 * __useStandardizedTransactionsReportQuery__
 *
 * To run a query within a React component, call `useStandardizedTransactionsReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useStandardizedTransactionsReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStandardizedTransactionsReportQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      role: // value for 'role'
 *      facilityId: // value for 'facilityId'
 *      facilityIds: // value for 'facilityIds'
 *      kitchenIds: // value for 'kitchenIds'
 *   },
 * });
 */
export function useStandardizedTransactionsReportQuery(baseOptions: Apollo.QueryHookOptions<StandardizedTransactionsReportQuery, StandardizedTransactionsReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StandardizedTransactionsReportQuery, StandardizedTransactionsReportQueryVariables>(StandardizedTransactionsReportDocument, options);
      }
export function useStandardizedTransactionsReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StandardizedTransactionsReportQuery, StandardizedTransactionsReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StandardizedTransactionsReportQuery, StandardizedTransactionsReportQueryVariables>(StandardizedTransactionsReportDocument, options);
        }
export type StandardizedTransactionsReportQueryHookResult = ReturnType<typeof useStandardizedTransactionsReportQuery>;
export type StandardizedTransactionsReportLazyQueryHookResult = ReturnType<typeof useStandardizedTransactionsReportLazyQuery>;
export type StandardizedTransactionsReportQueryResult = Apollo.QueryResult<StandardizedTransactionsReportQuery, StandardizedTransactionsReportQueryVariables>;
export const StandardizedOperationsDetailReportDocument = gql`
    query StandardizedOperationsDetailReport($startDate: DateTime, $endDate: DateTime, $role: UserRole, $facilityId: String, $facilityIds: [ID!]!, $kitchenIds: [ID!]!) {
  adminStandardizedOperationsDetailedReport(
    startDate: $startDate
    endDate: $endDate
    role: $role
    facilityId: $facilityId
    facilityIds: $facilityIds
    kitchenIds: $kitchenIds
  ) {
    datePlaced
    deliveryEndTime
    deliveryStartTime
    shortId
    numberOfItems
    numberOfRefiredItems
    orderMethod
    startPreparingTime
    timeAwaitingPickup
    timeDelayedInKitchen
    timeInExpo
    timeInKitchen
    timeInPackaging
    timePlaced
    timeWanted
    totalTimeToCompletion
  }
}
    `;

/**
 * __useStandardizedOperationsDetailReportQuery__
 *
 * To run a query within a React component, call `useStandardizedOperationsDetailReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useStandardizedOperationsDetailReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStandardizedOperationsDetailReportQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      role: // value for 'role'
 *      facilityId: // value for 'facilityId'
 *      facilityIds: // value for 'facilityIds'
 *      kitchenIds: // value for 'kitchenIds'
 *   },
 * });
 */
export function useStandardizedOperationsDetailReportQuery(baseOptions: Apollo.QueryHookOptions<StandardizedOperationsDetailReportQuery, StandardizedOperationsDetailReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StandardizedOperationsDetailReportQuery, StandardizedOperationsDetailReportQueryVariables>(StandardizedOperationsDetailReportDocument, options);
      }
export function useStandardizedOperationsDetailReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StandardizedOperationsDetailReportQuery, StandardizedOperationsDetailReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StandardizedOperationsDetailReportQuery, StandardizedOperationsDetailReportQueryVariables>(StandardizedOperationsDetailReportDocument, options);
        }
export type StandardizedOperationsDetailReportQueryHookResult = ReturnType<typeof useStandardizedOperationsDetailReportQuery>;
export type StandardizedOperationsDetailReportLazyQueryHookResult = ReturnType<typeof useStandardizedOperationsDetailReportLazyQuery>;
export type StandardizedOperationsDetailReportQueryResult = Apollo.QueryResult<StandardizedOperationsDetailReportQuery, StandardizedOperationsDetailReportQueryVariables>;
export const StandardizedItemSalesReportDocument = gql`
    query StandardizedItemSalesReport($startDate: DateTime, $endDate: DateTime, $role: UserRole, $facilityId: String, $facilityIds: [ID!]!, $kitchenIds: [ID!]!) {
  adminStandardizedItemSalesReport(
    startDate: $startDate
    endDate: $endDate
    role: $role
    facilityId: $facilityId
    facilityIds: $facilityIds
    kitchenIds: $kitchenIds
  ) {
    brandName
    menuItemName
    sold
    sales
    avgMenuItemPrice
    modifierNames
    modifierSold
    modifierSales
    avgModifierPrice
    totalSales
  }
}
    `;

/**
 * __useStandardizedItemSalesReportQuery__
 *
 * To run a query within a React component, call `useStandardizedItemSalesReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useStandardizedItemSalesReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStandardizedItemSalesReportQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      role: // value for 'role'
 *      facilityId: // value for 'facilityId'
 *      facilityIds: // value for 'facilityIds'
 *      kitchenIds: // value for 'kitchenIds'
 *   },
 * });
 */
export function useStandardizedItemSalesReportQuery(baseOptions: Apollo.QueryHookOptions<StandardizedItemSalesReportQuery, StandardizedItemSalesReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StandardizedItemSalesReportQuery, StandardizedItemSalesReportQueryVariables>(StandardizedItemSalesReportDocument, options);
      }
export function useStandardizedItemSalesReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StandardizedItemSalesReportQuery, StandardizedItemSalesReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StandardizedItemSalesReportQuery, StandardizedItemSalesReportQueryVariables>(StandardizedItemSalesReportDocument, options);
        }
export type StandardizedItemSalesReportQueryHookResult = ReturnType<typeof useStandardizedItemSalesReportQuery>;
export type StandardizedItemSalesReportLazyQueryHookResult = ReturnType<typeof useStandardizedItemSalesReportLazyQuery>;
export type StandardizedItemSalesReportQueryResult = Apollo.QueryResult<StandardizedItemSalesReportQuery, StandardizedItemSalesReportQueryVariables>;
export const StandardizedItemTransactionsReportDocument = gql`
    query StandardizedItemTransactionsReport($startDate: DateTime, $endDate: DateTime, $role: UserRole, $facilityId: String, $facilityIds: [ID!]!, $kitchenIds: [ID!]!) {
  adminStandardizedItemTransactionsReport(
    startDate: $startDate
    endDate: $endDate
    role: $role
    facilityId: $facilityId
    facilityIds: $facilityIds
    kitchenIds: $kitchenIds
  ) {
    brand
    brandAgreements
    brandMenuItemName
    customerEmail
    customerName
    customerPhone
    deliveryFee
    deliveryFeeTax
    foodSalesTax
    giftCardAmount
    giftCode
    netSales
    numberOfOrderedItems
    numberOfBrands
    orderDate
    orderTime
    orderTotal
    orderType
    orderingProvider
    partnerAgreements
    platform
    promoAmount
    promoCode
    serviceFee
    serviceFeeTax
    shortId
    subtotal
    tipAmount
    transactionId
    transactionStatus
    transactionType
  }
}
    `;

/**
 * __useStandardizedItemTransactionsReportQuery__
 *
 * To run a query within a React component, call `useStandardizedItemTransactionsReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useStandardizedItemTransactionsReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStandardizedItemTransactionsReportQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      role: // value for 'role'
 *      facilityId: // value for 'facilityId'
 *      facilityIds: // value for 'facilityIds'
 *      kitchenIds: // value for 'kitchenIds'
 *   },
 * });
 */
export function useStandardizedItemTransactionsReportQuery(baseOptions: Apollo.QueryHookOptions<StandardizedItemTransactionsReportQuery, StandardizedItemTransactionsReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StandardizedItemTransactionsReportQuery, StandardizedItemTransactionsReportQueryVariables>(StandardizedItemTransactionsReportDocument, options);
      }
export function useStandardizedItemTransactionsReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StandardizedItemTransactionsReportQuery, StandardizedItemTransactionsReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StandardizedItemTransactionsReportQuery, StandardizedItemTransactionsReportQueryVariables>(StandardizedItemTransactionsReportDocument, options);
        }
export type StandardizedItemTransactionsReportQueryHookResult = ReturnType<typeof useStandardizedItemTransactionsReportQuery>;
export type StandardizedItemTransactionsReportLazyQueryHookResult = ReturnType<typeof useStandardizedItemTransactionsReportLazyQuery>;
export type StandardizedItemTransactionsReportQueryResult = Apollo.QueryResult<StandardizedItemTransactionsReportQuery, StandardizedItemTransactionsReportQueryVariables>;
export const StandardizedPromoReportDocument = gql`
    query StandardizedPromoReport($startDate: DateTime, $endDate: DateTime, $role: UserRole, $facilityId: String, $facilityIds: [ID!]!, $kitchenIds: [ID!]!) {
  adminStandardizedPromoReport(
    startDate: $startDate
    endDate: $endDate
    role: $role
    facilityId: $facilityId
    facilityIds: $facilityIds
    kitchenIds: $kitchenIds
  ) {
    promoCodeName
    promoCodeType
    numberOfOrders
    promoValueRedeemed
    status
  }
}
    `;

/**
 * __useStandardizedPromoReportQuery__
 *
 * To run a query within a React component, call `useStandardizedPromoReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useStandardizedPromoReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStandardizedPromoReportQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      role: // value for 'role'
 *      facilityId: // value for 'facilityId'
 *      facilityIds: // value for 'facilityIds'
 *      kitchenIds: // value for 'kitchenIds'
 *   },
 * });
 */
export function useStandardizedPromoReportQuery(baseOptions: Apollo.QueryHookOptions<StandardizedPromoReportQuery, StandardizedPromoReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StandardizedPromoReportQuery, StandardizedPromoReportQueryVariables>(StandardizedPromoReportDocument, options);
      }
export function useStandardizedPromoReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StandardizedPromoReportQuery, StandardizedPromoReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StandardizedPromoReportQuery, StandardizedPromoReportQueryVariables>(StandardizedPromoReportDocument, options);
        }
export type StandardizedPromoReportQueryHookResult = ReturnType<typeof useStandardizedPromoReportQuery>;
export type StandardizedPromoReportLazyQueryHookResult = ReturnType<typeof useStandardizedPromoReportLazyQuery>;
export type StandardizedPromoReportQueryResult = Apollo.QueryResult<StandardizedPromoReportQuery, StandardizedPromoReportQueryVariables>;
export const StandardizedTimeOfDayBreakdownReportDocument = gql`
    query StandardizedTimeOfDayBreakdownReport($startDate: DateTime, $endDate: DateTime, $role: UserRole, $facilityId: String, $facilityIds: [ID!]!, $kitchenIds: [ID!]!) {
  adminStandardizedTimeOfDayBreakdownReport(
    startDate: $startDate
    endDate: $endDate
    role: $role
    facilityId: $facilityId
    facilityIds: $facilityIds
    kitchenIds: $kitchenIds
  ) {
    date
    brandName
    timeSlot
    numberOfOrdersPerBrand
    totalSalesPerTimeSlot
  }
}
    `;

/**
 * __useStandardizedTimeOfDayBreakdownReportQuery__
 *
 * To run a query within a React component, call `useStandardizedTimeOfDayBreakdownReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useStandardizedTimeOfDayBreakdownReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStandardizedTimeOfDayBreakdownReportQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      role: // value for 'role'
 *      facilityId: // value for 'facilityId'
 *      facilityIds: // value for 'facilityIds'
 *      kitchenIds: // value for 'kitchenIds'
 *   },
 * });
 */
export function useStandardizedTimeOfDayBreakdownReportQuery(baseOptions: Apollo.QueryHookOptions<StandardizedTimeOfDayBreakdownReportQuery, StandardizedTimeOfDayBreakdownReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StandardizedTimeOfDayBreakdownReportQuery, StandardizedTimeOfDayBreakdownReportQueryVariables>(StandardizedTimeOfDayBreakdownReportDocument, options);
      }
export function useStandardizedTimeOfDayBreakdownReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StandardizedTimeOfDayBreakdownReportQuery, StandardizedTimeOfDayBreakdownReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StandardizedTimeOfDayBreakdownReportQuery, StandardizedTimeOfDayBreakdownReportQueryVariables>(StandardizedTimeOfDayBreakdownReportDocument, options);
        }
export type StandardizedTimeOfDayBreakdownReportQueryHookResult = ReturnType<typeof useStandardizedTimeOfDayBreakdownReportQuery>;
export type StandardizedTimeOfDayBreakdownReportLazyQueryHookResult = ReturnType<typeof useStandardizedTimeOfDayBreakdownReportLazyQuery>;
export type StandardizedTimeOfDayBreakdownReportQueryResult = Apollo.QueryResult<StandardizedTimeOfDayBreakdownReportQuery, StandardizedTimeOfDayBreakdownReportQueryVariables>;
export const StandardizedPartnerRemittanceReportDocument = gql`
    query StandardizedPartnerRemittanceReport($startDate: DateTime, $endDate: DateTime, $role: UserRole, $facilityId: String, $facilityIds: [ID!]!, $kitchenIds: [ID!]!) {
  adminStandardizedPartnerRemittanceReport(
    startDate: $startDate
    endDate: $endDate
    role: $role
    facilityId: $facilityId
    facilityIds: $facilityIds
    kitchenIds: $kitchenIds
  ) {
    lastUpdateDate
    tenantId
    kitchenId
    kitchenName
    accountingDateRange
    grossRestaurantSales
    discounts
    giftCardAmount
    netSalesPretax
    serviceFees
    foodSalesTax
    serviceFeeTax
    deliveryFees
    deliveryTips
    pickupTips
    cartTotal
    otherTender
    deliveryAggregatorExpense
    deliveryAggregatorExpenseRemainder
    craveProcessingFees
    partnerAgreements
    brandAgreements
    cardIncomeAfterFees
    adjustmentsRefunds
    adjustmentsChargebacks
    adjustmentOther
    cardIncomeAfterFees
    finalPayout
    payoutId
    payoutDescriptor
    payoutDate
  }
}
    `;

/**
 * __useStandardizedPartnerRemittanceReportQuery__
 *
 * To run a query within a React component, call `useStandardizedPartnerRemittanceReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useStandardizedPartnerRemittanceReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStandardizedPartnerRemittanceReportQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      role: // value for 'role'
 *      facilityId: // value for 'facilityId'
 *      facilityIds: // value for 'facilityIds'
 *      kitchenIds: // value for 'kitchenIds'
 *   },
 * });
 */
export function useStandardizedPartnerRemittanceReportQuery(baseOptions: Apollo.QueryHookOptions<StandardizedPartnerRemittanceReportQuery, StandardizedPartnerRemittanceReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StandardizedPartnerRemittanceReportQuery, StandardizedPartnerRemittanceReportQueryVariables>(StandardizedPartnerRemittanceReportDocument, options);
      }
export function useStandardizedPartnerRemittanceReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StandardizedPartnerRemittanceReportQuery, StandardizedPartnerRemittanceReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StandardizedPartnerRemittanceReportQuery, StandardizedPartnerRemittanceReportQueryVariables>(StandardizedPartnerRemittanceReportDocument, options);
        }
export type StandardizedPartnerRemittanceReportQueryHookResult = ReturnType<typeof useStandardizedPartnerRemittanceReportQuery>;
export type StandardizedPartnerRemittanceReportLazyQueryHookResult = ReturnType<typeof useStandardizedPartnerRemittanceReportLazyQuery>;
export type StandardizedPartnerRemittanceReportQueryResult = Apollo.QueryResult<StandardizedPartnerRemittanceReportQuery, StandardizedPartnerRemittanceReportQueryVariables>;
export const OnTicketChangedDocument = gql`
    subscription onTicketChanged($kitchenId: ID, $screenId: String, $screenType: ScreenType) {
  ticketChanged(
    screenType: $screenType
    kitchenId: $kitchenId
    screenId: $screenId
  ) {
    operation
    data {
      ...KitchenTicket
    }
  }
}
    ${KitchenTicketFragmentDoc}`;

/**
 * __useOnTicketChangedSubscription__
 *
 * To run a query within a React component, call `useOnTicketChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnTicketChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnTicketChangedSubscription({
 *   variables: {
 *      kitchenId: // value for 'kitchenId'
 *      screenId: // value for 'screenId'
 *      screenType: // value for 'screenType'
 *   },
 * });
 */
export function useOnTicketChangedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnTicketChangedSubscription, OnTicketChangedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnTicketChangedSubscription, OnTicketChangedSubscriptionVariables>(OnTicketChangedDocument, options);
      }
export type OnTicketChangedSubscriptionHookResult = ReturnType<typeof useOnTicketChangedSubscription>;
export type OnTicketChangedSubscriptionResult = Apollo.SubscriptionResult<OnTicketChangedSubscription>;
export const OnStationTicketChangedDocument = gql`
    subscription onStationTicketChanged($stationId: ID, $screenId: String, $screenType: ScreenType) {
  stationTicketChanged(
    screenType: $screenType
    stationId: $stationId
    screenId: $screenId
  ) {
    operation
    data {
      ...KitchenTicket
    }
  }
}
    ${KitchenTicketFragmentDoc}`;

/**
 * __useOnStationTicketChangedSubscription__
 *
 * To run a query within a React component, call `useOnStationTicketChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnStationTicketChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnStationTicketChangedSubscription({
 *   variables: {
 *      stationId: // value for 'stationId'
 *      screenId: // value for 'screenId'
 *      screenType: // value for 'screenType'
 *   },
 * });
 */
export function useOnStationTicketChangedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnStationTicketChangedSubscription, OnStationTicketChangedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnStationTicketChangedSubscription, OnStationTicketChangedSubscriptionVariables>(OnStationTicketChangedDocument, options);
      }
export type OnStationTicketChangedSubscriptionHookResult = ReturnType<typeof useOnStationTicketChangedSubscription>;
export type OnStationTicketChangedSubscriptionResult = Apollo.SubscriptionResult<OnStationTicketChangedSubscription>;
export const OnOrderChangedMonitoringDocument = gql`
    subscription onOrderChangedMonitoring($startDate: DateTime!, $endDate: DateTime!) {
  orderChanged(startDate: $startDate, endDate: $endDate) {
    operation
    data {
      ...OrderInfo
    }
  }
}
    ${OrderInfoFragmentDoc}`;

/**
 * __useOnOrderChangedMonitoringSubscription__
 *
 * To run a query within a React component, call `useOnOrderChangedMonitoringSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnOrderChangedMonitoringSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnOrderChangedMonitoringSubscription({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useOnOrderChangedMonitoringSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnOrderChangedMonitoringSubscription, OnOrderChangedMonitoringSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnOrderChangedMonitoringSubscription, OnOrderChangedMonitoringSubscriptionVariables>(OnOrderChangedMonitoringDocument, options);
      }
export type OnOrderChangedMonitoringSubscriptionHookResult = ReturnType<typeof useOnOrderChangedMonitoringSubscription>;
export type OnOrderChangedMonitoringSubscriptionResult = Apollo.SubscriptionResult<OnOrderChangedMonitoringSubscription>;
export const OnOrderChangedKitchenDocument = gql`
    subscription onOrderChangedKitchen($screenId: String, $statuses: [OrderStatus]) {
  orderChanged(screenType: KITCHEN, statuses: $statuses, screenId: $screenId) {
    operation
    data {
      ...OrderShortInfo
      kitchenTickets {
        kitchen {
          id
        }
        ...KitchenTicket
      }
    }
  }
}
    ${OrderShortInfoFragmentDoc}
${KitchenTicketFragmentDoc}`;

/**
 * __useOnOrderChangedKitchenSubscription__
 *
 * To run a query within a React component, call `useOnOrderChangedKitchenSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnOrderChangedKitchenSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnOrderChangedKitchenSubscription({
 *   variables: {
 *      screenId: // value for 'screenId'
 *      statuses: // value for 'statuses'
 *   },
 * });
 */
export function useOnOrderChangedKitchenSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnOrderChangedKitchenSubscription, OnOrderChangedKitchenSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnOrderChangedKitchenSubscription, OnOrderChangedKitchenSubscriptionVariables>(OnOrderChangedKitchenDocument, options);
      }
export type OnOrderChangedKitchenSubscriptionHookResult = ReturnType<typeof useOnOrderChangedKitchenSubscription>;
export type OnOrderChangedKitchenSubscriptionResult = Apollo.SubscriptionResult<OnOrderChangedKitchenSubscription>;
export const OnOrderChangedStationDocument = gql`
    subscription onOrderChangedStation($screenId: String, $statuses: [OrderStatus]) {
  orderChanged(screenType: KITCHEN, statuses: $statuses, screenId: $screenId) {
    operation
    data {
      ...OrderShortInfo
      kitchenTickets {
        station {
          id
        }
        ...KitchenTicket
      }
    }
  }
}
    ${OrderShortInfoFragmentDoc}
${KitchenTicketFragmentDoc}`;

/**
 * __useOnOrderChangedStationSubscription__
 *
 * To run a query within a React component, call `useOnOrderChangedStationSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnOrderChangedStationSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnOrderChangedStationSubscription({
 *   variables: {
 *      screenId: // value for 'screenId'
 *      statuses: // value for 'statuses'
 *   },
 * });
 */
export function useOnOrderChangedStationSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnOrderChangedStationSubscription, OnOrderChangedStationSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnOrderChangedStationSubscription, OnOrderChangedStationSubscriptionVariables>(OnOrderChangedStationDocument, options);
      }
export type OnOrderChangedStationSubscriptionHookResult = ReturnType<typeof useOnOrderChangedStationSubscription>;
export type OnOrderChangedStationSubscriptionResult = Apollo.SubscriptionResult<OnOrderChangedStationSubscription>;
export const OnOrderChangedExpoDocument = gql`
    subscription onOrderChangedExpo($screenId: String, $statuses: [OrderStatus]) {
  orderChanged(screenType: EXPO, statuses: $statuses, screenId: $screenId) {
    operation
    data {
      ...KdsExpoOrder
    }
  }
}
    ${KdsExpoOrderFragmentDoc}`;

/**
 * __useOnOrderChangedExpoSubscription__
 *
 * To run a query within a React component, call `useOnOrderChangedExpoSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnOrderChangedExpoSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnOrderChangedExpoSubscription({
 *   variables: {
 *      screenId: // value for 'screenId'
 *      statuses: // value for 'statuses'
 *   },
 * });
 */
export function useOnOrderChangedExpoSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnOrderChangedExpoSubscription, OnOrderChangedExpoSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnOrderChangedExpoSubscription, OnOrderChangedExpoSubscriptionVariables>(OnOrderChangedExpoDocument, options);
      }
export type OnOrderChangedExpoSubscriptionHookResult = ReturnType<typeof useOnOrderChangedExpoSubscription>;
export type OnOrderChangedExpoSubscriptionResult = Apollo.SubscriptionResult<OnOrderChangedExpoSubscription>;
export const OnOrderChangedPackagingDocument = gql`
    subscription onOrderChangedPackaging($screenId: String, $workplace: Int, $statuses: [OrderStatus]) {
  orderChanged(
    screenType: PACKAGER
    workplace: $workplace
    statuses: $statuses
    screenId: $screenId
  ) {
    operation
    data {
      ...KdsExpoOrder
    }
  }
}
    ${KdsExpoOrderFragmentDoc}`;

/**
 * __useOnOrderChangedPackagingSubscription__
 *
 * To run a query within a React component, call `useOnOrderChangedPackagingSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnOrderChangedPackagingSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnOrderChangedPackagingSubscription({
 *   variables: {
 *      screenId: // value for 'screenId'
 *      workplace: // value for 'workplace'
 *      statuses: // value for 'statuses'
 *   },
 * });
 */
export function useOnOrderChangedPackagingSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnOrderChangedPackagingSubscription, OnOrderChangedPackagingSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnOrderChangedPackagingSubscription, OnOrderChangedPackagingSubscriptionVariables>(OnOrderChangedPackagingDocument, options);
      }
export type OnOrderChangedPackagingSubscriptionHookResult = ReturnType<typeof useOnOrderChangedPackagingSubscription>;
export type OnOrderChangedPackagingSubscriptionResult = Apollo.SubscriptionResult<OnOrderChangedPackagingSubscription>;
export const OnOrderChangedFohDocument = gql`
    subscription onOrderChangedFoh($screenId: String, $statuses: [OrderStatus]) {
  orderChanged(screenType: FOH, statuses: $statuses, screenId: $screenId) {
    operation
    data {
      ...KdsExpoOrder
    }
  }
}
    ${KdsExpoOrderFragmentDoc}`;

/**
 * __useOnOrderChangedFohSubscription__
 *
 * To run a query within a React component, call `useOnOrderChangedFohSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnOrderChangedFohSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnOrderChangedFohSubscription({
 *   variables: {
 *      screenId: // value for 'screenId'
 *      statuses: // value for 'statuses'
 *   },
 * });
 */
export function useOnOrderChangedFohSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnOrderChangedFohSubscription, OnOrderChangedFohSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnOrderChangedFohSubscription, OnOrderChangedFohSubscriptionVariables>(OnOrderChangedFohDocument, options);
      }
export type OnOrderChangedFohSubscriptionHookResult = ReturnType<typeof useOnOrderChangedFohSubscription>;
export type OnOrderChangedFohSubscriptionResult = Apollo.SubscriptionResult<OnOrderChangedFohSubscription>;
export const OnOrderChangedOrderStatusDocument = gql`
    subscription onOrderChangedOrderStatus($screenId: String, $statuses: [OrderStatus]) {
  orderChanged(screenType: ORDER_STATUS, statuses: $statuses, screenId: $screenId) {
    operation
    data {
      ...KdsExpoOrder
    }
  }
}
    ${KdsExpoOrderFragmentDoc}`;

/**
 * __useOnOrderChangedOrderStatusSubscription__
 *
 * To run a query within a React component, call `useOnOrderChangedOrderStatusSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnOrderChangedOrderStatusSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnOrderChangedOrderStatusSubscription({
 *   variables: {
 *      screenId: // value for 'screenId'
 *      statuses: // value for 'statuses'
 *   },
 * });
 */
export function useOnOrderChangedOrderStatusSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnOrderChangedOrderStatusSubscription, OnOrderChangedOrderStatusSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnOrderChangedOrderStatusSubscription, OnOrderChangedOrderStatusSubscriptionVariables>(OnOrderChangedOrderStatusDocument, options);
      }
export type OnOrderChangedOrderStatusSubscriptionHookResult = ReturnType<typeof useOnOrderChangedOrderStatusSubscription>;
export type OnOrderChangedOrderStatusSubscriptionResult = Apollo.SubscriptionResult<OnOrderChangedOrderStatusSubscription>;
export const OnOrderChangedRunnerDocument = gql`
    subscription onOrderChangedRunner($screenId: String, $statuses: [OrderStatus]) {
  orderChanged(screenType: RUNNER, statuses: $statuses, screenId: $screenId) {
    operation
    data {
      ...KdsRunnerOrder
    }
  }
}
    ${KdsRunnerOrderFragmentDoc}`;

/**
 * __useOnOrderChangedRunnerSubscription__
 *
 * To run a query within a React component, call `useOnOrderChangedRunnerSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnOrderChangedRunnerSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnOrderChangedRunnerSubscription({
 *   variables: {
 *      screenId: // value for 'screenId'
 *      statuses: // value for 'statuses'
 *   },
 * });
 */
export function useOnOrderChangedRunnerSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnOrderChangedRunnerSubscription, OnOrderChangedRunnerSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnOrderChangedRunnerSubscription, OnOrderChangedRunnerSubscriptionVariables>(OnOrderChangedRunnerDocument, options);
      }
export type OnOrderChangedRunnerSubscriptionHookResult = ReturnType<typeof useOnOrderChangedRunnerSubscription>;
export type OnOrderChangedRunnerSubscriptionResult = Apollo.SubscriptionResult<OnOrderChangedRunnerSubscription>;
export const OnOrderChangedOrderAssignmentDocument = gql`
    subscription onOrderChangedOrderAssignment($screenId: String, $statuses: [OrderStatus]) {
  orderChanged(
    screenType: ORDER_ASSIGNMENT
    statuses: $statuses
    screenId: $screenId
  ) {
    operation
    data {
      ...KdsOrderAssignmentOrder
    }
  }
}
    ${KdsOrderAssignmentOrderFragmentDoc}`;

/**
 * __useOnOrderChangedOrderAssignmentSubscription__
 *
 * To run a query within a React component, call `useOnOrderChangedOrderAssignmentSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnOrderChangedOrderAssignmentSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnOrderChangedOrderAssignmentSubscription({
 *   variables: {
 *      screenId: // value for 'screenId'
 *      statuses: // value for 'statuses'
 *   },
 * });
 */
export function useOnOrderChangedOrderAssignmentSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnOrderChangedOrderAssignmentSubscription, OnOrderChangedOrderAssignmentSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnOrderChangedOrderAssignmentSubscription, OnOrderChangedOrderAssignmentSubscriptionVariables>(OnOrderChangedOrderAssignmentDocument, options);
      }
export type OnOrderChangedOrderAssignmentSubscriptionHookResult = ReturnType<typeof useOnOrderChangedOrderAssignmentSubscription>;
export type OnOrderChangedOrderAssignmentSubscriptionResult = Apollo.SubscriptionResult<OnOrderChangedOrderAssignmentSubscription>;
export const OnOrderChangedTableAssignmentDocument = gql`
    subscription onOrderChangedTableAssignment($screenId: String, $statuses: [OrderStatus]) {
  orderChanged(
    screenType: TABLE_ASSIGNMENT
    statuses: $statuses
    screenId: $screenId
  ) {
    operation
    data {
      ...AssignmentTicket
      ...CustomerTags
    }
  }
}
    ${AssignmentTicketFragmentDoc}
${CustomerTagsFragmentDoc}`;

/**
 * __useOnOrderChangedTableAssignmentSubscription__
 *
 * To run a query within a React component, call `useOnOrderChangedTableAssignmentSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnOrderChangedTableAssignmentSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnOrderChangedTableAssignmentSubscription({
 *   variables: {
 *      screenId: // value for 'screenId'
 *      statuses: // value for 'statuses'
 *   },
 * });
 */
export function useOnOrderChangedTableAssignmentSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnOrderChangedTableAssignmentSubscription, OnOrderChangedTableAssignmentSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnOrderChangedTableAssignmentSubscription, OnOrderChangedTableAssignmentSubscriptionVariables>(OnOrderChangedTableAssignmentDocument, options);
      }
export type OnOrderChangedTableAssignmentSubscriptionHookResult = ReturnType<typeof useOnOrderChangedTableAssignmentSubscription>;
export type OnOrderChangedTableAssignmentSubscriptionResult = Apollo.SubscriptionResult<OnOrderChangedTableAssignmentSubscription>;
export const OnOrderChangedPickupDocument = gql`
    subscription onOrderChangedPickup($screenId: String, $statuses: [OrderStatus]) {
  orderChanged(screenType: PICKUP, statuses: $statuses, screenId: $screenId) {
    operation
    data {
      status
      ...KdsPickupOrder
    }
  }
}
    ${KdsPickupOrderFragmentDoc}`;

/**
 * __useOnOrderChangedPickupSubscription__
 *
 * To run a query within a React component, call `useOnOrderChangedPickupSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnOrderChangedPickupSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnOrderChangedPickupSubscription({
 *   variables: {
 *      screenId: // value for 'screenId'
 *      statuses: // value for 'statuses'
 *   },
 * });
 */
export function useOnOrderChangedPickupSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnOrderChangedPickupSubscription, OnOrderChangedPickupSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnOrderChangedPickupSubscription, OnOrderChangedPickupSubscriptionVariables>(OnOrderChangedPickupDocument, options);
      }
export type OnOrderChangedPickupSubscriptionHookResult = ReturnType<typeof useOnOrderChangedPickupSubscription>;
export type OnOrderChangedPickupSubscriptionResult = Apollo.SubscriptionResult<OnOrderChangedPickupSubscription>;