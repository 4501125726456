import React, { useState, useRef, useCallback, FC } from 'react';

import { Icon, IconSize } from 'common/components/Icon';
import { Row } from 'common/components/Layout';
import { Text } from 'common/components/Text';
import { themeColors } from 'common/theme/constants';
import { useOutsideClick } from 'common/utils/hooks';

import {
  Wrapper,
  Selected,
  Options,
  Option as OptionElement,
  ElementPos,
} from './styles';

export interface Option {
  value: any;
  label: string;
  clearOption?: boolean;
}

interface Props {
  options?: Option[];
  placeholder?: string;
  defaultValue?: string;
  hasBackground?: boolean;
  callback?: (option: Option) => void;
  dropdownClick?: (isOpen: boolean) => void;
  closeOnSelect?: boolean;
  closeOnClickOutside?: boolean;
  arrowPlacement?: number;
  disabled?: boolean;
  colorIndicator?: string | null;
  dropdownHeight?: string;
  offSet?: number;
}

// TODO: React throws warning about "change in the order of Hooks" - let's take a look during refactoring
//       (if we won't go for using library)
export const Dropdown: FC<Props> = ({
  defaultValue,
  options,
  placeholder,
  callback,
  dropdownClick,
  hasBackground,
  children,
  closeOnSelect = true,
  closeOnClickOutside = true,
  arrowPlacement,
  disabled = false,
  offSet = 0,
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [isOpen, setStatus] = useState(false);
  const [pos, setPos] = useState<Omit<ElementPos, 'isOpen'>>({
    top: 0,
    left: 0,
    width: 0,
  });
  const [selectedValue, setSelect] = useState(defaultValue);
  const selectedOption: Option | undefined = options?.find(
    (option) => option.value === selectedValue,
  );

  useOutsideClick(ref, () => {
    if (isOpen && closeOnClickOutside) {
      setStatus(false);
    }
  });

  const toggleSelect = useCallback(() => {
    if (ref.current && !isOpen) {
      const { width, height, top, left } = ref.current.getBoundingClientRect();
      const { scrollY, scrollX } = window;
      setPos({ top: top + height + scrollY, left: left + scrollX, width });
    }

    if (dropdownClick) dropdownClick(isOpen);

    setStatus(!isOpen);
  }, [dropdownClick, isOpen]);

  const optionClick = (option: Option) => () => {
    if (callback) callback(option);

    setSelect(option.value);

    if (closeOnSelect) setStatus(false);
  };

  return (
    <Row direction="column">
      <Wrapper
        isOpen={isOpen}
        hasBackground={hasBackground}
        arrowPlacement={arrowPlacement}
        onClick={disabled ? () => {} : toggleSelect}
        ref={ref}
        aria-label="Dropdown"
      >
        <Selected>
          <Text truncateText>
            {!selectedValue ? placeholder : selectedOption?.label}
          </Text>

          <Icon
            iconColor={themeColors.primaryText}
            size={IconSize.NORMAL}
            name="arrow_drop_down"
          />
        </Selected>

        {isOpen && (
          <div>
            <Options isOpen={isOpen} {...pos} offSet={offSet}>
              {!!options
                ? options.map((option) => (
                    <OptionElement
                      key={option.value}
                      onClick={optionClick(option)}
                      isSelected={selectedValue === option.value}
                      hasBackground={hasBackground}
                    >
                      <Text fontWeight={option.clearOption ? 'bold' : 'normal'}>
                        {option.label}
                      </Text>
                    </OptionElement>
                  ))
                : children}
            </Options>
          </div>
        )}
      </Wrapper>
    </Row>
  );
};
